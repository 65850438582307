import React from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { Button, Col, Modal, ProgressBar, Row } from "react-bootstrap";
import ReactSelect from "react-select";
import moment from "moment";
import * as alasql from 'alasql';
import * as XLSX from 'xlsx';

import LoadingIndicator from "./LoadingIndicator";
import { Locale } from "../utilities/localization/CustomLocalization";
import { PermissionAccess, CapitalizeJsonKeys, CheckBoolean, CheckNullValue, CheckNumber, CheckObjectBoolean, CheckObjectNullValue, CheckObjectNumber, CheckObjectStringEmpty, CheckStringEmpty, Delay, DelayUntil, DoNothing, RoomFormatDate, FormatList_QuestionSet, FormatTime, GetDurationText, GetGroupData, GetPropIds, GetSubjectData, PagingComponents, PopulateRoomData, PopulateRoomDataModal, DecapitalizeJsonKeys, PreviewCategoryListItemComponents, UTCtoLocalDateTimeString, GetPostParams } from "../utilities/GlobalFunctions";
import { GlobalSetting, LayoutScreen, PermissionAccessType, RoomTypeOptions } from "../utilities/GlobalSetting";
import { useGlobal } from "../utilities/GlobalVariables";
import { useAppService } from "../services/AppService";

import { child, ref, remove, set } from "firebase/database";
import { collection, doc, getDocs, limit, query, setDoc, where } from "firebase/firestore";
import { dbLiveQuiz, firestore } from "../utilities/Firebase";
import { AlertMode } from "./AlertComponent";

import RoomResultComponent from "./RoomResultComponent";
import PreviewQuestionSetComponent from "./PreviewQuestionSetComponent";
import { RoomDataInput, FileExtOptions, RoomDataInitValue, RoomSearchBy, Room_SearchQuery_DefaultModal } from "./Enums/EnumManageRoom";

alasql.setXLSX(XLSX);

const settingTitle = 'Room';
const yearStart = moment(moment.utc().format('YYYY-01-01T00:00')).format('YYYY-MM-DDTHH:mm');
const yearEnd = moment(yearStart, 'YYYY-MM-DDTHH:mm').add(1, 'year').add(-1, 'minute').format('YYYY-MM-DDTHH:mm');

//2025.03.25
const SettingInput = {
    CategoryList: 'CategoryList',
    Group: 'Group',
    Subject: 'Subject',
    Date: 'Date',
    DateStart: 'DateStart',
    DateEnd: 'DateEnd',
    Time: 'Time',
    TimeStart: 'TimeStart',
    TimeEnd: 'TimeEnd',
    Duration: 'Duration',
    RandomQuestionMode: 'RandomQuestionMode',
    CheckedItem: 'checkedItem',
    None: 'none',

    OrganizerId: 'OrganizerId',
    Id: 'Id',
};
//2025.03.25
const BulkSetting = {
    CategoryList: 'CategoryList',
    Group: 'Group',
    Subject: 'Subject',
    Date: 'Date',
    DateStart: 'DateStart',
    DateEnd: 'DateEnd',
    Time: 'Time',
    TimeStart: 'TimeStart',
    TimeEnd: 'TimeEnd',
    Duration: 'Duration',
    RandomQuestionMode: 'RandomQuestionMode',
};

export default class ManageRoomScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.

        //Edit Room.
        this.EDR_FromTime = React.createRef();
        this.EDR_ToTime = React.createRef();
        this.EDR_FromDate = React.createRef();
        this.EDR_ToDate = React.createRef();
        this.EDR_Duration_Hour = React.createRef();
        this.EDR_Duration_Min = React.createRef();
        this.EDR_Duration_Sec = React.createRef();
        this.EDR_CHK_RandomQuestionMode = React.createRef();                //2023.10.06
        this.EDR_CHK_RestrictAccessToTimeRangeOnly = React.createRef();     //2023.10.18
        this.EDR_CHK_ForceRetrictedAccess = React.createRef();              //2023.10.18
        this.EDR_CHK_QuizEnded = React.createRef();                         //2023.10.30
        this.EDR_CHK_EnableStatisticReport = React.createRef();             //2023.11.03
        this.EDR_CHK_ExcludedFromStatisticReport = React.createRef();       //2024.04.16

        //2025.03.25
        //Bulk Edit.
        this.BE_Duration_Hour = React.createRef();
        this.BE_Duration_Min = React.createRef();
        this.BE_Duration_Sec = React.createRef();

        //Room Result.
        this.Ref_RoomResultComponent = React.createRef();       //2024.04.22

        //Preview Question Set.
        this.Ref_PreviewQuestionSetComponent = React.createRef();       //2025.01.04
    }

    // firestore = getFirestore(app);
    // dbLiveQuiz = getDatabase(app_rtdb_dbLiveQuiz);
    // dbLogs = getDatabase(app_rtdb_Logs);

    getInitState = () => ({
        isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        redirect: false,
        redirectLink: '/',
        isLoading: false,

        PA_View: false,
        PA_Search: false,
        PA_Create: false,
        PA_Update: false,
        PA_Delete: false,
        PA_Upload: false,
        PA_Download: false,
        IsAdmin: false,     //2025.01.21

        //List.
        List: [],
        IsListLoaded: false,
        hasTotalFileQty: false,
        PageIndex: 0,
        PageSize: GlobalSetting.PageSize,
        TotalRows: 0,

        CategoryOptions: [],    //2025.02.27
        CreatorOptions: [],     //2025.03.20

        //2025.01.16
        SearchRoomQueryModal: JSON.parse(JSON.stringify(Room_SearchQuery_DefaultModal)),
        SearchRoomQueryModal_Toggle: false,
        SearchRoomQueryModal_Processing: false,

        //Search.
        // SelectedRoomTypeForQuery: 0,
        ShowSearchRoomByRoomCodeModal: false,
        SearchRoomByRoomCode_Processing: false,
        SearchRoomByRoomCode_RoomCode: '',
        //Search Options.
        SearchQsSet_ByGroup: null,          //standard
        SearchQsSet_BySubject: null,        //subject
        SearchQsSet_ByName: '',             //name
        SearchQsSet_MaxQtyShow: 5,          //max result

        //New or Edit.
        ShowCreateEditRoomModal: false,
        Mode_Create_RoomDataModal: false,
        RoomData: null,
        Cached_RoomData: null,
        EditRoom_QuestionSet: null,
        Cached_EditRoom_QuestionSet: null,
        EditRoom_Group: null,
        Cached_EditRoom_Group: null,
        EditRoom_Subject: null,
        Cached_EditRoom_Subject: null,
        SearchByCondition_QuestionSet: RoomSearchBy.Group,
        //New.
        RoomData_RoomCode_isValid: false,
        ClassroomOptions: [],
        SubjectOptions: [],

        //Room's Question Set related.
        IsSearchQsSetSelected: false,
        SearchQsSet_QuestionSet_Selected: null,
        SearchQsSet_SelectQuestionSetId: '',
        SearchQsSet_Processing: false,
        QuestionSetList: [],
        QuestionSet_selected: null,

        //Event related.
        EventList: [],
        TargetEventModal: [],
        ShowDeleteRoomModal: false,
        ShowCreateRoomModal: false,
        ShowSearchQuestionSetModal: false,
        ShowSelectQuestionSetModal: false,

        IsChild: false,

        //BulkEdit === 2025.03.25
        BulkEdit_Setting: Object.keys(BulkSetting).map((data) => { return { key: data, value: null }; }),
        BulkEdit_Setting_checked: Object.keys(BulkSetting).map(() => { return false; }),
        BulkEdit_Toggle_EditSettingModal: false,
        BulkEdit_Toggle_RemoveSettingModal: false,
        BulkEdit_CheckedItems: [],
        BulkEdit_IsUpdating: false,
        BulkEdit_Setting_CategoryItems: [],
    });

    componentWillUnmount = () => { }

    componentDidMount = async () => {
        //#region init.
        window.scrollTo(0, 0);
        useGlobal.getState().setScreen(LayoutScreen.ManageRoom);
        // const gv = useGlobal.getState();
        // const { uid, organizerId } = GetPropIds(gv.user);
        // this.setState({
        //     PA_View: PermissionAccess(LayoutScreen.ManageRoom, PermissionAccessType.View),
        //     PA_Search: PermissionAccess(LayoutScreen.ManageRoom, PermissionAccessType.Search),
        //     PA_Create: PermissionAccess(LayoutScreen.ManageRoom, PermissionAccessType.Create),
        //     PA_Update: PermissionAccess(LayoutScreen.ManageRoom, PermissionAccessType.Update),
        //     PA_Delete: PermissionAccess(LayoutScreen.ManageRoom, PermissionAccessType.Delete),
        //     PA_Upload: PermissionAccess(LayoutScreen.ManageRoom, PermissionAccessType.Upload),
        //     PA_Download: PermissionAccess(LayoutScreen.ManageRoom, PermissionAccessType.Download),

        //     PageSize: CheckNumber(localStorage.getItem(`ManageRoom_PageSize_${uid}_${organizerId}`), GlobalSetting.PageSize),
        // });
        // await useAppService.getState().getGroups(true);
        // await useAppService.getState().getSubjects(true);
        // await useAppService.getState().getClassrooms(true);
        await Delay(0);
        this.LoadList_ViaApi();
        useGlobal.getState().setRefreshListCallbackFn(this.LoadList_ViaApi);
        // console.log(`ManageRoomScreen (componentDidMount) =\n ${JSON.stringify(gv.user['CustomPermissions'])}`);
        //#endregion
    }

    //#region === Room List ===
    //2024.07.24
    CheckPermissions = async () => {
        const { uid, organizerId } = GetPropIds(useGlobal.getState().user);
        this.setState({
            PA_View: PermissionAccess(LayoutScreen.ManageRoom, PermissionAccessType.View),
            PA_Search: PermissionAccess(LayoutScreen.ManageRoom, PermissionAccessType.Search),
            PA_Create: PermissionAccess(LayoutScreen.ManageRoom, PermissionAccessType.Create),
            PA_Update: PermissionAccess(LayoutScreen.ManageRoom, PermissionAccessType.Update),
            PA_Delete: PermissionAccess(LayoutScreen.ManageRoom, PermissionAccessType.Delete),
            PA_Upload: PermissionAccess(LayoutScreen.ManageRoom, PermissionAccessType.Upload),
            PA_Download: PermissionAccess(LayoutScreen.ManageRoom, PermissionAccessType.Download),

            PageSize: CheckNumber(localStorage.getItem(`ManageRoom_PageSize_${uid}_${organizerId}`), GlobalSetting.PageSize),

            IsAdmin: useGlobal.getState().isAdmin,  //2025.01.21
        });
        await Delay(0);
    }
    LoadList_ViaApi = async (resetPage = false) => {

        await this.CheckPermissions();    //2024.07.24

        //2025.01.27
        await useAppService.getState().getGroups(true);
        await useAppService.getState().getSubjects(true);
        // await useAppService.getState().getClassrooms(true);
        await this.ReloadSubjectOptions();
        await this.ReloadClassroomOptions();

        const fnName = 'LoadList_ViaApi';
        const params = GetPropIds(useGlobal.getState().user);
        const uid = CheckObjectStringEmpty(params, 'uid');
        const authorId = CheckObjectNumber(params, 'authorId');
        const organizerId = CheckObjectNumber(params, 'organizerId');

        if (authorId === 0 || organizerId === 0)
            return null;

        if (this.state.isDevMode)
            console.log(`${fnName} ${authorId} ${organizerId}`);

        let success = false;
        let messages = [];
        let list = [];
        let totalRows = 0;
        let _categoryOptions = [];  //2025.02.27
        let _creatorOptions = [];  //2025.03.20

        if (resetPage) {
            const pageSize = CheckNumber(localStorage.getItem(`ManageRoom_PageSize_${uid}_${organizerId}`), GlobalSetting.PageSize);
            this.setState({
                PageIndex: 0,
                PageSize: pageSize < GlobalSetting.PageSize ? GlobalSetting.PageSize : pageSize,
                SearchRoomQueryModal: JSON.parse(JSON.stringify(Room_SearchQuery_DefaultModal)),
            });
        }

        this.setState({
            IsListLoaded: false,
            List: [],
            hasTotalFileQty: false,
            // SelectedRoomTypeForQuery: 0,
            isLoading: true,
        });
        // await Delay(200);

        if (authorId > 0 && organizerId > 0) {
            let done = false;
            //Jay revamped to POST 2025.01.16
            const jsonData = JSON.stringify({
                OrganizerId: organizerId,
                AuthorId: authorId,
                RoomTypeId: this.state.SearchRoomQueryModal.RoomTypeId,
                RoomCode: this.state.SearchRoomQueryModal.RoomCode,
                RoomTitle: this.state.SearchRoomQueryModal.RoomTitle,
                GroupId: this.state.SearchRoomQueryModal.GroupId,
                SubjectId: this.state.SearchRoomQueryModal.SubjectId,
                CategoryId: this.state.SearchRoomQueryModal.CategoryId,             //2025.03.20
                CreatorAuthorId: this.state.SearchRoomQueryModal.CreatorAuthorId,   //2025.03.20
                OrderBy: 'RoomId',
                OrderType: 'DESC',
                PageIndex: this.state.PageIndex,
                PageSize: this.state.PageSize,
                // SearchRoomCode: '',
                // SearchRoomId: '',
            });
            if (this.state.isDevMode)
                console.log('LoadList_ViaApi (postData) =\n' + jsonData);
            await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Quiz/Room/List',
                // + organizerId + '/'
                // + authorId + '/'
                // + this.state.SelectedRoomTypeForQuery + '/'
                // + this.state.PageIndex + '/'
                // + this.state.PageSize,
                // // Api/LearningCentre/Quiz/Room/List/{organizerId}/{authorId}/{roomType}/{pageIndex}/{pageSize}
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: jsonData,
                })
                .then(res => res.json())
                .then(data => {
                    // if (this.state.isDevMode)
                    //     console.log(`${fnName} (source)`, JSON.stringify(data));

                    // success = CheckBoolean(data.success);
                    success = CheckObjectBoolean(data, 'success');
                    if (success) {
                        if (Array.isArray(data.data.list))
                            list = data.data.list;
                        totalRows = CheckObjectNumber(data.data, 'totalRows', list.length);

                        //2025.02.27
                        if (Array.isArray(data.data.categoryOptions)) {
                            _categoryOptions = DecapitalizeJsonKeys(data.data.categoryOptions);
                            _categoryOptions.sort((a, b) => a.id - b.id);
                            if (this.state.isDevMode)
                                console.log('CategoryOptions (From List) \n' + JSON.stringify(_categoryOptions));
                        }

                        //2025.03.20
                        if (Array.isArray(data.data.creatorOptions)) {
                            _creatorOptions = DecapitalizeJsonKeys(data.data.creatorOptions);
                            _creatorOptions.sort((a, b) => a.id - b.id);
                            if (this.state.isDevMode)
                                console.log('CreatorOptions (From List) \n' + JSON.stringify(_creatorOptions));
                        }

                    }
                    else {
                        if (CheckObjectNullValue(data, 'message') !== null)
                            messages.push(data.message);
                        if (this.state.isDevMode)
                            console.log('Error', 'api - room list (failed)\n' + JSON.stringify(data));
                    }
                    done = true;
                })
                .catch(error => {
                    messages.push(error.message);
                    if (this.state.isDevMode)
                        console.log('Error', 'api - room list (error)\n' + error.message);
                    done = true;
                });
            await DelayUntil(() => done === true);
        }

        // console.log(`${fnName} (source) \n ${JSON.stringify(list)}`);
        if (list.length > 0) {
            let _List = []
            //2024.07.25
            for (let i = 0; i < list.length; i++) {
                const t_room = await PopulateRoomData(list[i], list[i]['QuestionSet'] ?? this.state.QuestionSet);
                _List.push(t_room);
            }
            // list.map(async (data, key) => {
            //     const t_room = await PopulateRoomData(data, this.state.QuestionSet);
            //     return _List.push(t_room);
            // });
            list = _List;
            if (this.state.isDevMode) {
                console.log(`${fnName} (final)`, JSON.stringify(list));
            }
        }

        if (!success)
            useAppService.getState().setModal(Locale("error", this.state.locale), messages.join('<br />'));

        this.setState({
            CategoryOptions: _categoryOptions,  //2025.02.27
            CreatorOptions: _creatorOptions,    //2025.03.20
            TotalRows: totalRows,
            List: list,
            IsListLoaded: true,
            isLoading: false,
            BulkEdit_CheckedItems: Array.isArray(list) ? list.map((data, key) => { return false; }) : [],  //2025.03.25
        }, () => {
            //Document Room
            // if (this.state.SelectedRoomTypeForQuery === 1)
            if (this.state.SearchRoomQueryModal.RoomTypeId === 1)
                if (this.state.List.length > 0)
                    this.LoadRoomUploadedFileQty();
        });
        await Delay(0);
    }
    // //populate data fetched from list.
    // PopulateRoomData = (data = null) => {
    //     let roomData = null;
    //     if (data !== null) {

    //         const _groupId = CheckObjectNumber(data, 'GroupId');
    //         const _group = CheckObjectNullValue(data, 'Group');
    //         const { group, groupId } = GetGroupData(_group, _groupId);

    //         const _subjectId = CheckObjectNumber(data, 'SubjectId');
    //         const _subject = CheckObjectNullValue(data, 'Subject');
    //         const { subject, subjectId } = GetSubjectData(_subject, _subjectId);

    //         const { centerUserId, authorId, organizerId, organizerDiplayName, organizerIdentity } = GetPropIds(useGlobal.getState().user);

    //         let _Duration = CheckObjectNumber(data, 'Duration');
    //         let _DurationPerQuestion = CheckObjectNumber(data, 'DurationPerQuestion');
    //         let _QnQty = CheckObjectNumber(data, 'QnQty');
    //         let _qSet = this.state.QuestionSet;
    //         if (_qSet !== null) {
    //             _QnQty = CheckObjectNumber(_qSet, 'TotalQuestion');
    //             if (_QnQty > 0) {
    //                 if (_Duration > 0) {
    //                     _DurationPerQuestion = Math.round(_Duration / _QnQty);
    //                 }
    //             }
    //         }

    //         roomData = {
    //             AuthorId: CheckObjectNumber(data, 'AuthorId', authorId),
    //             CenterUserId: CheckObjectNumber(data, 'CenterUserId', centerUserId),

    //             Organizer: CheckObjectStringEmpty(data, 'Organizer', organizerDiplayName),
    //             OrganizerId: CheckObjectNumber(data, 'OrganizerId', organizerId),
    //             OrganizerIdentity: CheckObjectStringEmpty(data, 'OrganizerIdentity', organizerIdentity),
    //             EventCode: CheckObjectStringEmpty(data, 'EventCode'),

    //             RoomCode: CheckObjectNumber(data, 'RoomCode'),
    //             RoomId: CheckObjectStringEmpty(data, 'RoomId'),
    //             RoomTitle: CheckObjectStringEmpty(data, 'RoomTitle'),
    //             RoomType: CheckObjectNumber(data, 'RoomType'),        //0 = basic, 1 = document

    //             Date: CheckObjectStringEmpty(data, 'Date'),
    //             DateStart: CheckObjectStringEmpty(data, 'DateStart'),
    //             DateEnd: CheckObjectStringEmpty(data, 'DateEnd'),
    //             TimeStart: CheckObjectStringEmpty(data, 'TimeStart', '00:00:00'),
    //             TimeEnd: CheckObjectStringEmpty(data, 'TimeEnd', '23:59:00'),

    //             Duration: _Duration,
    //             DurationPerQuestion: _DurationPerQuestion,

    //             Subject: CheckObjectStringEmpty(subject, 'value'),
    //             SubjectId: subjectId,
    //             SubjectName: CheckObjectStringEmpty(subject, 'value'),

    //             Group: group,
    //             GroupId: groupId,
    //             Grade: groupId,

    //             QuestionSetUniqueId: CheckObjectStringEmpty(data, 'QuestionSetUniqueId'),
    //             QnQty: _QnQty,
    //             SupportedDocExt: CheckObjectNumber(data, 'RoomType') === 0 ? [] : ['.txt', '.rtf', '.doc', '.docx'],
    //             Remark: CheckObjectStringEmpty(data, 'Remark'),
    //             ExtraUrl: CheckObjectStringEmpty(data, 'ExtraUrl'),   //2021.12.10 for Flipbook

    //             RandomQuestionMode: CheckObjectBoolean(data, 'RandomQuestionMode'),
    //             RestrictAccessToTimeRangeOnly: CheckObjectBoolean(data, 'RestrictAccessToTimeRangeOnly'),
    //             ForceRetrictedAccess: CheckObjectBoolean(data, 'ForceRetrictedAccess'),
    //             EnableStatisticReport: CheckObjectBoolean(data, 'EnableStatisticReport'),
    //             ExcludedFromStatisticReport: CheckObjectBoolean(data, 'ExcludedFromStatisticReport'),
    //             // QuizEnded: CheckObjectBoolean(data, 'QuizEnded') || moment.utc() > moment(CheckObjectStringEmpty(data, 'DateEnd') + ' ' + CheckObjectStringEmpty(data, 'TimeEnd', '23:59:00')).utc(),
    //             QuizEnded: moment.utc() > moment(CheckObjectStringEmpty(data, 'DateEnd') + ' ' + CheckObjectStringEmpty(data, 'TimeEnd', '23:59:00')).utc(),
    //         };

    //         //RoomType (0 = normal, 1 = upload file)
    //         switch (roomData.RoomType) {
    //             case 1: roomData.QuestionSetUniqueId = ''; break;
    //             default: break;
    //         }
    //     }
    //     return roomData;
    // }
    // //2024.03.14 - new added.
    // //populate data to be post on Firebase or Api.
    // PopulateRoomDataModal = (_currentRoomId = '') => {

    //     if (CheckNullValue(_currentRoomId) === null)
    //         return null;

    //     const roomData = this.state.RoomData;
    //     const roomCode = CheckObjectStringEmpty(roomData, 'RoomCode');
    //     const { centerUserId, authorId, organizerId, organizerIdentity, organizerDiplayName } = GetPropIds(useGlobal.getState().user);

    //     //room DateStart.
    //     const m_date = moment(CheckObjectStringEmpty(roomData, 'DateStart', moment().format('YYYY-MM-DD')) + ' 00:00:00');
    //     // const ref_date = m_date.format('YYYYMMDD');
    //     const r_date = m_date.format('YYYY-MM-DD');
    //     const dateStart = CheckObjectStringEmpty(roomData, 'DateStart', r_date);
    //     const dateEnd = CheckObjectStringEmpty(roomData, 'DateEnd', r_date);
    //     const timeStart = CheckObjectStringEmpty(roomData, 'TimeStart', '00:00:00');
    //     const timeEnd = CheckObjectStringEmpty(roomData, 'TimeEnd', '23:59:00');

    //     // //RTDB ref.
    //     // const roomDetailRef = child(ref(dbLiveQuiz), 'pkquiz/' + ref_date + '/pkquiz-room-detail/' + _currentRoomId);
    //     // const roomCodeRef = child(ref(dbLiveQuiz), 'pkquiz/' + ref_date + '/pkquiz-room-code/' + roomCode);
    //     // const roomStateRef = child(ref(dbLiveQuiz), 'pkquiz/' + ref_date + '/pkquiz-live/' + _currentRoomId);

    //     //Values.
    //     const questionSet_obj = CheckObjectNullValue(roomData, 'QuestionSet');
    //     const duration = CheckObjectNumber(roomData, 'Duration');
    //     const roomType = CheckObjectNumber(roomData, 'RoomType');

    //     const _groupId = CheckObjectNumber(roomData, 'GroupId');
    //     const _group = CheckObjectNullValue(roomData, 'Group');
    //     const { group, groupId } = GetGroupData(_group, _groupId);

    //     const _subjectId = CheckObjectNumber(roomData, 'SubjectId');
    //     const _subject = CheckObjectNullValue(roomData, 'Subject');
    //     const { subject, subjectId } = GetSubjectData(_subject, _subjectId);

    //     //Room Details.
    //     let modal = {

    //         AuthorId: authorId,
    //         CenterUserId: centerUserId,

    //         Organizer: organizerDiplayName,
    //         OrganizerId: organizerId,
    //         OrganizerIdentity: organizerIdentity,
    //         EventCode: '',

    //         RoomCode: roomCode,
    //         RoomId: Number(_currentRoomId),
    //         RoomType: roomType,
    //         RoomTitle: CheckObjectStringEmpty(roomData, 'RoomTitle'),

    //         Date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),   //Created Date.
    //         DateStart: dateStart,
    //         DateEnd: dateEnd,
    //         TimeStart: timeStart,
    //         TimeEnd: timeEnd,

    //         Duration: duration,
    //         DurationPerQuestion:
    //             roomType === 0 ?
    //                 Math.round((duration / CheckObjectNumber(questionSet_obj, 'TotalQuestion', 1)))
    //                 : duration,

    //         Subject: CheckObjectStringEmpty(subject, 'value'), // subject,
    //         SubjectId: subjectId,

    //         Group: group,
    //         GroupId: groupId,
    //         Grade: roomType === 0 ? CheckObjectNumber(questionSet_obj, 'GroupId', groupId) : groupId,
    //         //std 1~6, N2, K1, K2 = ok, others = no dedicated qs set category.

    //         QuestionSetUniqueId: roomType === 0 ? CheckObjectStringEmpty(questionSet_obj, 'UniqueId') : '',
    //         QnQty: roomType === 0 ? CheckObjectNumber(questionSet_obj, 'TotalQuestion', 1) : 1,
    //         SupportedDocExt: CheckObjectNullValue(roomData, 'SupportedDocExt', []),
    //         Remark: CheckObjectStringEmpty(roomData, 'Remark'),
    //         ExtraUrl: CheckObjectStringEmpty(roomData, 'ExtraUrl'),

    //         RandomQuestionMode: CheckObjectBoolean(roomData, 'RandomQuestionMode'),
    //         RestrictAccessToTimeRangeOnly: CheckObjectBoolean(roomData, 'RestrictAccessToTimeRangeOnly'),
    //         ForceRetrictedAccess: CheckObjectBoolean(roomData, 'ForceRetrictedAccess'),
    //         // QuizEnded: CheckObjectBoolean(roomData, 'QuizEnded'),
    //         // QuizEnded: CheckObjectBoolean(roomData, 'QuizEnded') || moment() > moment(CheckObjectStringEmpty(roomData, 'DateEnd') + ' ' + CheckObjectStringEmpty(roomData, 'TimeEnd', '23:59:00')),
    //         // QuizEnded: CheckObjectBoolean(roomData, 'QuizEnded') || moment.utc() > moment(CheckObjectStringEmpty(roomData, 'DateEnd') + ' ' + CheckObjectStringEmpty(roomData, 'TimeEnd', '23:59:00')).utc(),
    //         QuizEnded: moment.utc() > moment(CheckObjectStringEmpty(roomData, 'DateEnd') + ' ' + CheckObjectStringEmpty(roomData, 'TimeEnd', '23:59:00')).utc(),
    //         EnableStatisticReport: CheckObjectBoolean(roomData, 'EnableStatisticReport'),
    //         ExcludedFromStatisticReport: CheckObjectBoolean(roomData, 'ExcludedFromStatisticReport'),
    //     };
    //     //RoomType (0 = normal, 1 = upload file)
    //     switch (modal.RoomType) {
    //         case 1: modal.QuestionSetUniqueId = ''; break;
    //         default: break;
    //     }
    //     return modal;
    // }
    // GetGroupData = (group = null, groupId = 0) => {
    //     const groupOptions = useAppService.getState().groupOptions;
    //     if (Array.isArray(groupOptions) && groupOptions.length > 0) {
    //         // let groupId = CheckObjectNumber(roomData, 'GroupId');
    //         // let group = CheckObjectNullValue(roomData, 'Group');
    //         if (group === null && groupId > 0) {
    //             const groupIndex = groupOptions.findIndex(x => x.id === groupId);
    //             if (groupIndex > -1)
    //                 group = groupOptions[groupIndex];
    //         }
    //         else if (groupId <= 0 && group !== null) {
    //             groupId = CheckObjectNumber(group, 'id');
    //         }
    //     }
    //     return { group, groupId };
    // }
    // GetSubjectData = (subject = null, subjectId = 0) => {
    //     const subjectOptions = useAppService.getState().subjectOptions;
    //     if (Array.isArray(subjectOptions) && subjectOptions.length > 0) {
    //         // let subjectId = CheckObjectNumber(roomData, 'SubjectId');
    //         // let subject = CheckObjectNullValue(roomData, 'Subject');
    //         if (subject === null && subjectId > 0) {
    //             const subjectIndex = subjectOptions.findIndex(x => x.id === subjectId);
    //             if (subjectIndex > -1)
    //                 subject = subjectOptions[subjectIndex];
    //         }
    //         else if (subjectId <= 0 && subject !== null) {
    //             subjectId = CheckObjectNumber(subject, 'id');
    //         }
    //     }
    //     return { subject, subjectId };
    // }
    GetPlaceholder_Group = () => {
        if (this.state.RoomData !== null) {
            const groupOptions = useAppService.getState().groupOptions;
            let _findIndex = groupOptions.findIndex(x => Number(x.id) === CheckObjectNumber(this.state.RoomData, 'GroupId'));
            if (_findIndex > -1) {
                // if (this.state.SearchQsSet_ByGroup === null)
                //     this.setState({ SearchQsSet_ByGroup: groupOptions[_findIndex], });
                return String(groupOptions[_findIndex].label);
            }
        }
        return Locale("not-specify-group", this.state.locale);
    }
    GetPlaceholder_Subject = () => {
        if (this.state.RoomData !== null) {
            const subjectOptions = useAppService.getState().subjectOptions;
            let _findIndex = subjectOptions.findIndex(x => Number(x.id) === CheckObjectNumber(this.state.RoomData, 'SubjectId'));
            if (_findIndex > -1) {
                // if (this.state.SearchQsSet_BySubject === null)
                //     this.setState({ SearchQsSet_BySubject: subjectOptions[_findIndex], });
                return String(subjectOptions[_findIndex].label);
            }
        }
        return Locale("not-specify-subject", this.state.locale);
    }
    //2025.03.17
    GetSelectedGroupItem = (target) => {
        if (target === null || target === undefined)
            return null;
        const groupOptions = useAppService.getState().groupOptions;
        let _findIndex = groupOptions.findIndex(x => Number(x.id) === CheckObjectNumber(this.state.RoomData, 'GroupId'));
        if (_findIndex > -1)
            return groupOptions[_findIndex];
        return null;
    }
    //2025.03.17
    GetSelectedSubjectItem = (target) => {
        if (target === null || target === undefined)
            return null;
        const subjectOptions = useAppService.getState().subjectOptions;
        let _findIndex = subjectOptions.findIndex(x => Number(x.id) === CheckObjectNumber(this.state.RoomData, 'SubjectId'));
        if (_findIndex > -1)
            return subjectOptions[_findIndex];
        return null;
    }
    ListComponents = () => {
        if (this.state.List.length > 0) {
            let listRows = [];
            this.state.List.map((data, key) => {
                const qSet = CheckObjectNullValue(data, 'QuestionSet');
                // const categoryOptions = this.state.CategoryOptions;
                // const categoryList = this.GetSelectedCategoryItems(data);
                const decap_data = DecapitalizeJsonKeys(JSON.parse(JSON.stringify(data)));
                const createdOnUtc = CheckObjectStringEmpty(decap_data, 'CreatedOnUtc');
                return listRows.push(
                    <tr key={'list-item-' + key}>
                        <td className="pointer setting-child-td" onClick={() => this.ToggleItemChecked(key)}>
                            {key + 1 + this.state.PageIndex}
                            <div className="no-td">
                                <input type='checkbox' className='pointer' checked={this.state.BulkEdit_CheckedItems[key]} readOnly={true}></input>
                            </div>
                        </td>
                        {/* <td>{key + 1 + this.state.PageIndex}</td> */}
                        <td className="icon-color">
                            {CheckObjectStringEmpty(data, 'RoomCode')}<br />
                            <button type="button" className={`btn-link ${CheckObjectStringEmpty(qSet, 'Remark') === '' ? 'gray' : 'blue'}`}
                                onClick={() => {
                                    let title = 'Room <' + CheckObjectStringEmpty(data, 'RoomCode') + '> :: Question Set Info';
                                    let content = null;

                                    if (qSet !== null) {
                                        content = <>
                                            <table>
                                                <tbody>
                                                    <tr hidden={true}>
                                                        <th>Question Set Unique Id</th>
                                                        <td>:</td>
                                                        <td>{CheckObjectStringEmpty(qSet, 'UniqueId', '-')}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Group</th>
                                                        <td>:</td>
                                                        <td>{CheckObjectStringEmpty(qSet, 'GroupName', '-')}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Subject</th>
                                                        <td>:</td>
                                                        <td>{CheckObjectStringEmpty(qSet, 'SubjectName', '-')}</td>
                                                    </tr>
                                                    <tr>
                                                        <th width={150}>Total Question</th>
                                                        <td width={15}>:</td>
                                                        <td>{CheckObjectStringEmpty(qSet, 'TotalQuestion', '-')}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div style={{ marginTop: 15, padding: 5, border: '1px solid gray', borderRadius: 5 }}>
                                                <table width='100%' cellPadding={7}>
                                                    <tbody>
                                                        <tr><th>Remark (Public)</th></tr>
                                                        <tr><td>{CheckObjectStringEmpty(qSet, 'Remark', '-')}</td></tr>
                                                        <tr><td><hr style={{ margin: '5px 0px 2px 0px' }} /></td></tr>
                                                        <tr><th>Remark (Hidden)</th></tr>
                                                        <tr><td>{CheckObjectStringEmpty(qSet, 'HiddenRemark', '-')}</td></tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>;
                                        useAppService.getState().setModal(title, content);
                                    }
                                }}
                                style={{ marginTop: 7 }}
                            ><i className="fa fa-info-circle" title='Question Set Info' style={{ fontSize: 25 }}></i></button>
                        </td>
                        <td className="left">{CheckObjectStringEmpty(data, 'RoomTitle')}
                            {
                                CheckNullValue(data, 'Group') === null ? null :
                                    <><br /><span style={{ color: 'gray', fontSize: 12, }}>{CheckObjectStringEmpty(data.Group, 'Name')}</span></>
                            }
                            {
                                CheckObjectNumber(data, 'RoomType') === 1 ?
                                    CheckNullValue(data, 'Remark') === null ? null :
                                        <><br /><span style={{ color: 'gray', fontSize: 12, }}>{'< ' + data.Remark + ' >'}</span></>
                                    : null
                            }
                        </td>
                        <td hidden={this.state.hasTotalFileQty === false}>{CheckObjectNumber(data, 'TotalFile')}</td>
                        <td style={{ padding: '5px 0px' }}>
                            {/* <table className="table tb-no-border-no-margin table-text-left"><tbody> {
                                Array.isArray(categoryOptions) && categoryOptions.length > 0
                                    && Array.isArray(categoryList) && categoryList.length > 0 ?
                                    categoryOptions.map((option, okey) => {
                                        const isChecked = categoryList.findIndex(x => x.id === option.id) > -1;
                                        return (<tr>
                                            <td width='42'><input type='checkbox' className="form-check form-check-input" readOnly={true} checked={isChecked} disabled={true} style={{ margin: 0 }}></input></td>
                                            <td>{option.label}</td>
                                        </tr>)
                                    })
                                    : <tr><td>-</td></tr>
                            }</tbody></table> */}
                            <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>{PreviewCategoryListItemComponents(decap_data, this.state.CategoryOptions, true)}</div>
                            {/* {PreviewCategoryListItemComponents(data, this.state.CategoryOptions, true)} */}
                        </td>
                        <td>{CheckObjectStringEmpty(data, 'GroupName')}</td>
                        <td>{CheckObjectStringEmpty(data, 'SubjectName')}</td>
                        <td>{CheckObjectStringEmpty(data, 'TimeStart') === '_none' ? '12:00 AM' : FormatTime(CheckObjectStringEmpty(data, 'TimeStart'))}</td>
                        <td>{CheckObjectStringEmpty(data, 'TimeEnd') === '_none' ? '11:59 PM' : FormatTime(CheckObjectStringEmpty(data, 'TimeEnd'))}</td>
                        <td>{RoomFormatDate(data)}</td>
                        <td hidden={!this.state.IsAdmin}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>{
                                data['AuthorUser'] === undefined || data['AuthorUser'] === null ? '-' :
                                    data['AuthorUser']['Id'] === 0 ?
                                        <i style={{ color: 'red', fontWeight: 500 }}>- User has been deleted -</i>
                                        :
                                        Object.keys(data['AuthorUser']).map((au, auKey) => {
                                            if (au.includes('Id'))
                                                return null;
                                            const value = CheckObjectStringEmpty(data['AuthorUser'], au, '-');
                                            return <span key={'au_' + au} title={`(${au}) ${value}`}>{value}</span>;
                                        })
                            }<span>{createdOnUtc === '' ? '' : '(' + UTCtoLocalDateTimeString(createdOnUtc) + ')'}</span></div>
                        </td>
                        <td style={{ padding: 5 }}>
                            {/* 2021.12.10 - new pattern. */}
                            {
                                this.state.PA_Update === false ? null :
                                    <Button
                                        variant='outline-primary'
                                        onClick={() => this.LoadSelectedRoom(data.RoomCode, data.RoomId)}
                                        style={{ width: '100%', }}
                                    >Edit</Button>
                            }
                            {
                                this.state.PA_Update === false ? null :
                                    <Button
                                        variant='outline-primary'
                                        // onClick={() => this.props.TogglePage(this.props.Toggle.RoomTypeFileDetail, data)}
                                        hidden={CheckObjectNumber(data, 'RoomType') === 1 ? false : true}
                                        style={{ width: '100%', marginTop: 5, }}
                                    >Detail</Button>
                            }
                            {
                                this.state.PA_Update === false ? null :
                                    <Button
                                        variant='outline-primary'
                                        // onClick={() => this.LoadResultFromSelectedRoom(data.RoomCode, data.RoomId)}
                                        onClick={() => this.Ref_RoomResultComponent.current.LoadResultFromSelectedRoom(data.RoomCode, data.RoomId, null, null, null, LayoutScreen.ManageRoom)}
                                        style={{ width: '100%', marginTop: 5, }}
                                    >Result</Button>
                            }
                        </td>
                    </tr >
                );
            });
            return listRows;
        }
        return this.state.IsListLoaded ?
            <tr><td colSpan='15' align='center'>list is empty</td></tr>
            : <tr><td colSpan='15' align='center'><LoadingIndicator /></td></tr>;
    }
    LoadRoomUploadedFileQty = async () => {
        if (this.state.List.length > 0) {
            let _roomList = this.state.List;
            await Promise.all(
                this.state.List.map(async (data, key) => {
                    if (_roomList[key].TotalFile === undefined) {
                        _roomList[key].TotalFile = '0';
                        await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Quiz/Room/File/RequestFileCount/'
                            + CheckStringEmpty(data, 'RoomCode') + '/'
                            + CheckStringEmpty(data, 'RoomId'),
                            // Api/LearningCentre/Quiz/Room/File/RequestFileCount/{roomcode}/{room_id}
                            {
                                method: 'GET',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                },
                            })
                            .then(res => res.json())
                            .then(data => {
                                if (this.state.isDevMode)
                                    console.log(JSON.stringify(data));

                                if (!data.success)
                                    if (this.state.isDevMode)
                                        console.log('Error', 'api - room total file (failed)\n' + JSON.stringify(data));

                                if (CheckObjectNullValue(data, 'data') !== null)
                                    _roomList[key].TotalFile = String(data.data);
                            })
                            .catch(error => {
                                if (this.state.isDevMode)
                                    console.log('Error', 'api - room total file (error)\n' + error.message);
                            });
                    }
                    return null;
                })
            );
            this.setState({
                List: _roomList,
                hasTotalFileQty: true,
            });
        }
    }
    //#region === Paging Components
    CallbackFunctionForPagingComponents_PageSize = (pageSize = GlobalSetting.PageSize) => {
        this.setState({
            PageSize: pageSize < GlobalSetting.PageSize ? GlobalSetting.PageSize : pageSize,
        }, () => {
            const { uid, organizerId } = GetPropIds(useGlobal.getState().user);
            localStorage.setItem(`ManageRoom_PageSize_${uid}_${organizerId}`, this.state.PageSize);
            setTimeout(() => {
                this.LoadList_ViaApi();
            }, 500);
        });
    }
    CallbackFunctionForPagingComponents_PageIndex = (pageIndex = 0) => {
        this.setState({
            PageIndex: pageIndex,
        }, () => {
            setTimeout(() => {
                this.LoadList_ViaApi();
            }, 500);
        });
    }
    //#endregion === Paging Components
    //#endregion

    //#region === Edit Room === start ===//
    ToggleCreateEditRoomModal = async () => {
        if (this.state.PA_Update === false)
            return null;
        this.setState({
            ShowCreateEditRoomModal: !this.state.ShowCreateEditRoomModal
        });
        await Delay(0);
        if (this.state.ShowCreateEditRoomModal) {
            // await this.ReloadSubjectOptions();
            // await this.ReloadClassroomOptions();
        }
        else {
            this.setState({ Mode_Create_RoomDataModal: false, });
            this.ResetEditRoomParams();
            await Delay(0);
        }
    }
    ReloadSubjectOptions = async (refresh = false) => {
        if (refresh)
            await useAppService.getState().getSubjects();
        // await useAppService.getState().getSubjects(true);
        this.setState({
            SubjectOptions: useAppService.getState().subjectOptions,
        });
        await Delay(0);
    }
    ReloadClassroomOptions = async () => {
        await useAppService.getState().getClassrooms(true, false);
        this.setState({
            ClassroomOptions: useAppService.getState().classroomOptions,
        });
        await Delay(0);
    }
    ResetEditRoomParams = () => {
        let roomData = { ...RoomDataInitValue };
        roomData.DateStart = moment().format('YYYY-MM-DD');
        roomData.DateEnd = moment().format('YYYY-MM-DD');
        this.setState({
            RoomData: roomData,
            RoomData_RoomCode_isValid: false,
            Cached_RoomData: null,
            EditRoom_Group: null,
            Cached_EditRoom_Group: null,
            EditRoom_QuestionSet: null,
            Cached_EditRoom_QuestionSet: null,
            SearchByCondition_QuestionSet: RoomSearchBy.Group,
        });
    }
    ResetEditedRoomData = async () => {
        const room = this.state.RoomData;
        this.ToggleCreateEditRoomModal();
        await Delay(500);
        this.LoadSelectedRoom(room.RoomCode, room.RoomId);
    }
    LoadSelectedRoom = async (roomCode = '', roomId = '') => {

        if (CheckNullValue(roomCode) === null || CheckNullValue(roomId) === null)
            return null;

        this.ResetEditRoomParams();
        useAppService.getState().setModal('', 'Loading Room <' + roomCode + '>...', null, AlertMode.Loading);
        await Delay(500);

        if (this.state.isDevMode)
            console.log('LoadSelectedRoom = ' + roomCode + ' / ' + roomId);

        let _roomData = null;
        const selectedRoomIndex = this.state.List.findIndex(x => CheckObjectStringEmpty(x, 'RoomCode') === String(roomCode) && CheckObjectStringEmpty(x, 'RoomId') === String(roomId));
        if (selectedRoomIndex > -1)
            _roomData = await PopulateRoomData(this.state.List[selectedRoomIndex], this.state.QuestionSet);

        if (_roomData !== null) {

            if (this.state.isDevMode)
                console.log('LoadSelectedRoom =\n' + JSON.stringify(_roomData));

            this.setState({
                RoomData: _roomData,
                Cached_RoomData: JSON.parse(JSON.stringify(_roomData)),
                EditRoom_QuestionSet: null,
                Cached_EditRoom_QuestionSet: null,
                RoomData_RoomCode_isValid: CheckObjectNumber(_roomData, 'RoomCode') > 0,
            }, async () => {
                useAppService.getState().setModal();
                this.ToggleCreateEditRoomModal();
                await Delay(0);
                this.Recalculate_Duration_EditRoom();
                this.Recalculate_DateTime_EditRoom();
                this.EDR_CHK_RandomQuestionMode.current.checked = CheckObjectBoolean(_roomData, 'RandomQuestionMode');
                this.EDR_CHK_RestrictAccessToTimeRangeOnly.current.checked = CheckObjectBoolean(_roomData, 'RestrictAccessToTimeRangeOnly');
                this.EDR_CHK_ForceRetrictedAccess.current.checked = CheckObjectBoolean(_roomData, 'ForceRetrictedAccess');
                this.EDR_CHK_QuizEnded.current.checked = CheckObjectBoolean(_roomData, 'QuizEnded');
                this.EDR_CHK_EnableStatisticReport.current.checked = CheckObjectBoolean(_roomData, 'EnableStatisticReport');
                this.EDR_CHK_ExcludedFromStatisticReport.current.checked = CheckObjectBoolean(_roomData, 'ExcludedFromStatisticReport');

                if (CheckObjectNullValue(_roomData, 'QuestionSetUniqueId') !== null) {
                    await this.LoadRoomQuestionSet_ViaApi();
                    this.Recalculate_Duration_EditRoom();
                }

                this.SaveDataInput();
            });
        }
        else {
            //     useAppService.getState().setModal('', '<b>Unable to load Room <' + roomCode + '>.</b><br /><br /><i>' + _error + '</i>');
            useAppService.getState().setModal('Error', 'Room <' + roomCode + '> not found.');
        }
    }
    // RecalculateDuration = () => {
    //     this.setState({
    //         NewRoom_Duration: 0,
    //     });
    //     //2021.09.29
    //     let _maxDuration = (24 * 60 * 60) - 60;
    //     let _duration = 0;
    //     if (this.state.NewRoom_UseCustomDuration) {
    //         _duration = (this.state.NewRoom_Duration_Hour * 3600)
    //             + (this.state.NewRoom_Duration_Min * 60) + this.state.NewRoom_Duration_Sec;
    //         let _secs = _duration >= _maxDuration ? 0 : this.state.NewRoom_Duration_Sec;
    //         _duration = _duration >= _maxDuration ? _maxDuration : _duration;
    //         this.setState({
    //             NewRoom_Duration: _duration,
    //             NewRoom_Duration_Sec: _secs,
    //             AllowToCreateRoom: _duration <= 0 ? false : true,    //2021.10.05
    //         }, () => {
    //             // console.log('Duration = ' + this.state.NewRoom_Duration);
    //             this.NR_Duration_Hour.current.value = this.state.NewRoom_Duration_Hour;
    //             this.NR_Duration_Min.current.value = this.state.NewRoom_Duration_Min;
    //             this.NR_Duration_Sec.current.value = this.state.NewRoom_Duration_Sec;
    //         });
    //     }
    //     else {
    //         if (this.state.NewRoom_AccessibleOnEntireDay) {
    //             // _duration = moment(moment().format('2021-09-30 23:59:00'))
    //             //         .diff(moment(moment().format('2021-09-30 00:00:00')), 'seconds');

    //             //2021.10.05
    //             if (moment(this.state.NewRoom_Date).toString().toLowerCase().includes('invalid'))
    //                 _duration = 0;
    //             else
    //                 _duration = moment(moment().format(this.state.NewRoom_Date + ' 23:59:00'))
    //                     .diff(moment(moment().format(this.state.NewRoom_Date + ' 00:00:00')), 'seconds');
    //         }
    //         else {
    //             let _start = this.state.NewRoom_TimeStart.length > 0 ? '2021-09-30 ' + this.state.NewRoom_TimeStart + ':00' : '';
    //             let _end = this.state.NewRoom_TimeEnd.length > 0 ? '2021-09-30 ' + this.state.NewRoom_TimeEnd + ':00' : '';
    //             _duration = _start.length > 0 && _end.length > 0 ? moment(_end).diff(moment(_start), 'seconds') : 0;

    //             //2021.10.05
    //             // if (_duration <= 0) {
    //             //     let startTime = moment(moment().format('YYYY-MM-DD ') + this.state.NewRoom_TimeStart + ':00');
    //             //     let endTime = moment(moment().format('YYYY-MM-DD ') + this.state.NewRoom_TimeEnd + ':00');
    //             //     _duration = endTime.diff(startTime, 'seconds');
    //             //     _duration = _duration < 0 ? 0 : _duration;
    //             // }
    //         }
    //         this.setState({
    //             NewRoom_Duration: _duration,
    //             AllowToCreateRoom: _duration <= 0 ? false : true,    //2021.10.05
    //         });
    //     }
    //     // console.log('Duration = ' + _duration);
    //     // this.NR_Duration_Hour.current.value = this.state.NewRoom_Duration_Hour;
    //     // this.NR_Duration_Min.current.value = this.state.NewRoom_Duration_Min;
    //     // this.NR_Duration_Sec.current.value = this.state.NewRoom_Duration_Sec;
    // }
    // GetDurationText = (_totalSeconds = 0, fullformat = false) => {
    //     var hours = Number((_totalSeconds / 3600).toFixed(3).split('.')[0]);
    //     var minutes = Number((_totalSeconds / 60).toFixed(3).split('.')[0]) - (hours * 60);
    //     var seconds = (_totalSeconds % 60);
    //     // return hours > 0 ?
    //     //     hours + ' hr ' + (minutes - (hours * 60)) + ' min ' + seconds + ' sec'
    //     //     : minutes + ' min ' + seconds + ' sec';
    //     if (fullformat) {
    //         return hours + ' hr' + (hours > 1 ? 's ' : ' ')
    //             + minutes + ' min' + (minutes > 1 ? 's ' : ' ')
    //             + seconds + ' sec' + (seconds > 1 ? 's' : '');
    //     }
    //     return (hours > 0 ? hours + ' hr' + (hours > 1 ? 's ' : ' ') : '')
    //         + (minutes > 0 ? minutes + ' min' + (minutes > 1 ? 's ' : ' ') : '')
    //         + (seconds > 0 ? seconds.toFixed(2) + ' sec' + (seconds > 1 ? 's' : '') : '');
    // }
    Recalculate_Duration_EditRoom = () => {
        if (this.state.ShowCreateEditRoomModal) {
            let _roomData = this.state.RoomData;

            let _hr = Number(this.EDR_Duration_Hour.current.value);
            let _min = Number(this.EDR_Duration_Min.current.value);
            let _sec = Number(this.EDR_Duration_Sec.current.value);

            let _qnQty = CheckObjectNumber(_roomData, 'QnQty');
            if (_qnQty === 0) {
                if (CheckObjectNullValue(_roomData, 'QuestionSet') !== null)
                    _qnQty = CheckObjectNumber(_roomData.QuestionSet, 'TotalQuestion');
            }

            let _duration = Number(_roomData.Duration);
            let _durationPerQuestion = Number(_roomData.DurationPerQuestion);
            // console.log('hr = ' + _hr + '\nmin = ' + _min + '\nsec = ' + _sec + '\nDuration = ' + _duration);

            if (_hr === 0 && _min === 0 && _sec === 0 && _duration > 0) {
                //Initial.
                _hr = Number((_duration / 3600).toFixed(3).split('.')[0]);
                _min = Number(((_duration - (_hr * 3600)) / 60).toFixed(3).split('.')[0]);
                _sec = _duration - (_hr * 3600) - (_min * 60);
                this.EDR_Duration_Hour.current.value = _hr;
                this.EDR_Duration_Min.current.value = _min;
                this.EDR_Duration_Sec.current.value = _sec;
                // console.log('hr = ' + _hr + '\nmin = ' + _min + '\nsec = ' + _sec);
            }
            else {
                //Edit Room Duration.
                if (_hr >= 6) {
                    _hr = 6;
                    _min = 0;
                    _sec = 0;
                    this.EDR_Duration_Hour.current.value = _hr;
                    this.EDR_Duration_Min.current.value = _min;
                    this.EDR_Duration_Sec.current.value = _sec;
                }
                if (_min > 59) {
                    _min = 59;
                    this.EDR_Duration_Min.current.value = _min;
                }
                if (_sec > 59) {
                    _sec = 59;
                    this.EDR_Duration_Sec.current.value = _sec;
                }
                _duration = (_hr * 3600) + (_min * 60) + _sec;
                _roomData['Duration'] = _duration;
                if (_qnQty > 0) {
                    _durationPerQuestion = Number((_duration / _qnQty).toFixed(0));
                    _roomData['DurationPerQuestion'] = _durationPerQuestion;
                }
                this.setState({
                    RoomData: _roomData,
                });
            }
        }
    }
    Recalculate_DateTime_EditRoom = () => {
        if (this.state.ShowCreateEditRoomModal) {
            let _roomData = this.state.RoomData;
            if (_roomData !== null) {
                this.EDR_FromDate.current.value = _roomData.DateStart;
                this.EDR_ToDate.current.value = _roomData.DateEnd;
                this.EDR_FromTime.current.value = _roomData.TimeStart;
                this.EDR_ToTime.current.value = _roomData.TimeEnd;
            }
        }
    }
    LoadRoomQuestionSet_ViaApi = async () => {
        if (this.state.RoomData !== null) {
            if (CheckObjectNullValue(this.state.RoomData, 'QuestionSetUniqueId') !== null) {
                const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
                //Fetch.
                let _questionSet = null;
                await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Quiz/QuestionSet/Get/'
                    + organizerId + '/'
                    + authorId + '/'
                    + CheckObjectStringEmpty(this.state.RoomData, 'QuestionSetUniqueId'),
                    // Api/LearningCentre/Quiz/QuestionSet/Get/{organizerId}/{authorId}/{uniqueId}
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                    })
                    .then(res => res.json())
                    .then(data => {
                        // if (this.state.isDevMode)
                        //     console.log('LoadRoomQuestionSet_ViaApi (source)', JSON.stringify(data));
                        if (data.success)
                            _questionSet = data.data;
                        else
                            if (this.state.isDevMode)
                                console.log('Error', 'api - question set (failed)\n' + JSON.stringify(data));
                    })
                    .catch(error => {
                        if (this.state.isDevMode)
                            console.log('Error', 'api - question set (error)\n' + error.message);
                    });

                if (_questionSet !== null) {
                    //Finalize json object.
                    _questionSet = CapitalizeJsonKeys(_questionSet);
                    // if (this.state.isDevMode)
                    //     console.log('LoadRoomQuestionSet_ViaApi (CapitalizeJsonKeys)', JSON.stringify(_questionSet));

                    let _List = [];
                    _List.push(_questionSet);
                    const groupOptions = useAppService.getState().groupOptions;
                    // const subjectOptions = useAppService.getState().subjectOptions;
                    const subjectOptions = this.state.SubjectOptions;
                    _List = FormatList_QuestionSet(_List, groupOptions, subjectOptions);
                    _questionSet = _List[0];
                    if (this.state.isDevMode)
                        console.log('LoadRoomQuestionSet_ViaApi (final)', JSON.stringify(_questionSet));

                    const { group } = GetGroupData(CheckObjectNullValue(_questionSet, 'Group'), CheckObjectNumber(_questionSet, 'GroupId'));

                    let roomData = this.state.RoomData;
                    roomData.QuestionSet = _questionSet;
                    let roomData_cached = this.state.Cached_RoomData;
                    roomData_cached.QuestionSet = _questionSet;

                    this.setState({
                        RoomData: roomData,
                        Cached_RoomData: roomData_cached,
                        EditRoom_QuestionSet: _questionSet,
                        Cached_EditRoom_QuestionSet: JSON.parse(JSON.stringify(_questionSet)),
                        EditRoom_Group: group,
                        Cached_EditRoom_Group: group === null ? null : JSON.parse(JSON.stringify(group)),
                    });
                }
            }
        }
    }
    SaveEditedRoom_ViaApi = async () => {
        if (this.state.RoomData === null || this.state.PA_Update === false)
            return null;

        const roomData = this.state.RoomData;
        // const cached_RoomData = this.state.Cached_RoomData;
        const roomCode = CheckObjectStringEmpty(roomData, 'RoomCode');
        const roomId = CheckObjectStringEmpty(roomData, 'RoomId');

        useAppService.getState().setModal('', 'Updating Room <' + roomCode + '>...', null, AlertMode.Loading);
        let success = false;
        let errorMessage = '';
        // let responseData = null;

        //data to update.
        try {
            //new api for create/upate room. 2024.04.30
            let dataToSave = await PopulateRoomDataModal(roomData, roomId);
            dataToSave['SupportedDocExt'] = Array.isArray(dataToSave['SupportedDocExt']) ? dataToSave['SupportedDocExt'].join(',') : '';
            dataToSave = CapitalizeJsonKeys(dataToSave);
            // dataToSave['CategoryList'] = Array.isArray(roomData['CategoryList']) ? CapitalizeJsonKeys(roomData['CategoryList']) : [];    //2025.02.27
            // dataToSave['Group'] = Array.isArray(roomData['Group']) ? CapitalizeJsonKeys(roomData['Group']) : [];    //2025.02.27
            const postData = JSON.stringify(dataToSave);
            if (this.state.isDevMode)
                console.log('SaveEditedRoom_ViaApi (postData) =\n' + postData);

            await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Quiz/Room/CreateOrUpdate',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: postData,
                })
                .then(res => res.json())
                .then(data => {
                    if (this.state.isDevMode)
                        console.log('SaveEditedRoom_ViaApi (response)', JSON.stringify(data));
                    success = CheckObjectBoolean(data, 'success');
                    if (success) {
                        // responseData = data.data;
                    }
                    else {
                        errorMessage = CheckObjectStringEmpty(data, 'message');
                        if (this.state.isDevMode)
                            console.log('Error', 'api - room - update (failed)\n' + JSON.stringify(data));
                    }
                })
                .catch(error => {
                    errorMessage = error.message;
                    if (this.state.isDevMode)
                        console.log('Error', 'api - room - update (error)\n' + error.message);
                });

            //#region === old codes ===

            // //updateData - full revamped 2023.10.18
            // let updated_Data_FS = {};
            // const fields = [
            //     'RoomTitle', 'DateStart', 'DateEnd', 'TimeStart', 'TimeEnd', 'QuestionSetUniqueId',
            //     'Duration', 'DurationPerQuestion', 'GroupId', 'Remark', 'SupportedDocExt', 'ExtraUrl', 'SubjectId', 'SubjectName', 'Organizer',
            //     'OrganizerIdentity', 'OrganizerId', 'RoomType', 'RandomQuestionMode', 'RestrictAccessToTimeRangeOnly',
            //     'ForceRetrictedAccess', 'QuizEnded', 'EnableStatisticReport', 'ExcludedFromStatisticReport'
            // ];
            // fields.map((data, key) => {
            //     return updated_Data_FS = CheckUpdateValueDiffer(updated_Data_FS, roomData, cached_RoomData, data);
            // });
            // updated_Data_FS['QuizEnded'] = CheckObjectBoolean(roomData, 'QuizEnded') || moment.utc() > moment(CheckObjectStringEmpty(roomData, 'DateEnd') + ' ' + CheckObjectStringEmpty(roomData, 'TimeEnd', '23:59:00')).utc();

            // //save in FS.
            // await updateDoc(doc(firestore, 'LiveQuiz_UniqueRoomCode', roomId), updated_Data_FS)
            //     .catch(error => {
            //         success = false;
            //         errorMessage.push(error);
            //         if (this.state.isDevMode)
            //             console.log('save room FS (error) = ' + error);
            //     });

            // const editRoomQsSet = this.state.EditRoom_QuestionSet;
            // const cachedEditRoomQsSet = this.state.Cached_EditRoom_QuestionSet;
            // let _qsSet = {};
            // if (JSON.stringify(editRoomQsSet) === JSON.stringify(cachedEditRoomQsSet))
            //     _qsSet = editRoomQsSet;
            // else
            //     _qsSet = cachedEditRoomQsSet;
            // if (editRoomQsSet === null && cachedEditRoomQsSet === null)
            //     _qsSet['TotalQuestion'] = 40;

            // let dataToSave_RTDB = await PopulateRoomDataModal(roomData, roomId);
            // dataToSave_RTDB['QuestionSetUniqueId'] = CheckObjectStringEmpty(_qsSet, 'UniqueId', CheckObjectStringEmpty(dataToSave_RTDB, 'QuestionSetUniqueId'));            //set to new question set unique id.

            // //RTDB ref.
            // const ref_date = moment(CheckObjectStringEmpty(roomData, 'DateStart') + ' 00:00:00').format('YYYYMMDD');
            // const roomDetailRef = ref(dbLiveQuiz, 'pkquiz/' + ref_date + '/pkquiz-room-detail/' + roomId);
            // // const roomCodeRef = child(ref(dbLiveQuiz), 'pkquiz/' + ref_date + '/pkquiz-room-code/' + roomCode);
            // // const roomStateRef = child(ref(dbLiveQuiz), 'pkquiz/' + ref_date + '/pkquiz-live/' + roomId);
            // await set(roomDetailRef, dataToSave_RTDB).then(() => {
            //     success = true;
            // }).catch(error => {
            //     if (this.state.isDevMode)
            //         console.log('set room detail (failed) =\n' + JSON.stringify(error));
            // });

            // //trigger CMS sync Room details.    //2021.11.10
            // await Delay(500);
            // if (success)
            //     await this.TriggerRoomRecordSync_ViaApi(roomId, this.state.Mode_Create_RoomDataModal);

            //#endregion === old codes ===

            //done
            if (success) {
                this.setState({
                    Cached_RoomData: JSON.parse(JSON.stringify(roomData)),
                    Cached_EditRoom_Group: JSON.parse(JSON.stringify(this.state.EditRoom_Group)),
                    Cached_EditRoom_QuestionSet: JSON.parse(JSON.stringify(this.state.EditRoom_QuestionSet)),
                });
                this.ToggleCreateEditRoomModal();
                this.ResetEditRoomParams();
                await this.LoadList_ViaApi();
                await Delay(500);
                await this.LoadSelectedRoom(roomCode, roomId);
                useAppService.getState().setModal('', 'Room <' + roomCode + '> has been updated.');
            }
            else {
                useAppService.getState().setModal('', 'Failed to update Room <' + roomCode + '>.<br /><br />Error:<br />' + CheckStringEmpty(errorMessage, '-'));
            }
        }
        catch (error) {
            useAppService.getState().setModal('', 'Failed to update Room <' + roomCode + '>.<br /><br />Error:<br />' + CheckStringEmpty(error.message, '-'));
            if (this.state.isDevMode)
                console.log('update room detail (failed) =\n' + error.message);
        }
    }
    SaveDataInput = (value, inputType = RoomDataInput.None) => {
        let roomData = this.state.RoomData;
        if (roomData === null)
            return null;

        let selected = null;
        switch (inputType) {
            case RoomDataInput.RoomTitle:
                roomData['RoomTitle'] = CheckStringEmpty(value);
                break;
            case RoomDataInput.DateStart:
                roomData['DateStart'] = CheckStringEmpty(value, moment().format('YYYY-MM-DD'));
                break;
            case RoomDataInput.DateEnd:
                roomData['DateEnd'] = CheckStringEmpty(value, moment().format('YYYY-MM-DD'));
                break;
            case RoomDataInput.TimeStart:
                roomData['TimeStart'] = CheckStringEmpty(value);
                break;
            case RoomDataInput.TimeEnd:
                roomData['TimeEnd'] = CheckStringEmpty(value);
                break;
            case RoomDataInput.RoomType:
                roomData['RoomType'] = CheckStringEmpty(value);
                if (CheckNumber(value) === 1)
                    roomData['QuestionSetUniqueId'] = '';
                break;
            case RoomDataInput.ExtraUrl:
                roomData['ExtraUrl'] = CheckStringEmpty(value);
                break;
            case RoomDataInput.RandomQuestionMode:
                roomData['RandomQuestionMode'] = CheckBoolean(value);
                break;
            case RoomDataInput.RestrictAccessToTimeRangeOnly:
                roomData['RestrictAccessToTimeRangeOnly'] = CheckBoolean(value);
                break;
            case RoomDataInput.QuizEnded:
                roomData['QuizEnded'] = CheckBoolean(value);
                break;
            case RoomDataInput.ForceRetrictedAccess:
                roomData['ForceRetrictedAccess'] = CheckBoolean(value);
                break;
            case RoomDataInput.EnableStatisticReport:
                roomData['EnableStatisticReport'] = CheckBoolean(value);
                break;
            case RoomDataInput.ExcludedFromStatisticReport:
                roomData['ExcludedFromStatisticReport'] = CheckBoolean(value);
                break;

            case RoomDataInput.Group:
                const groupOptions = useAppService.getState().groupOptions;
                const findIndex_go = groupOptions.findIndex(x => value.value === x.value && value.id === x.id);
                selected = findIndex_go < 0 ? null : groupOptions[findIndex_go];
                if (selected !== null) {
                    roomData['Group'] = selected;
                    roomData['GroupId'] = Number(selected.id);      //e.g. 25
                    roomData['GroupName'] = String(selected.value);
                    roomData['Grade'] = Number(selected.id);        //e.g. 25, not N2
                }
                break;
            case RoomDataInput.Subject:
                // const subjectOptions = useAppService.getState().subjectOptions;
                const subjectOptions = this.state.SubjectOptions;
                const findIndex_so = subjectOptions.findIndex(x => value.value === x.value && value.id === x.id);
                selected = findIndex_so < 0 ? null : subjectOptions[findIndex_so];
                if (selected !== null) {
                    roomData['Subject'] = selected;
                    roomData['SubjectId'] = Number(selected.id);      //e.g. 25
                    roomData['SubjectName'] = String(selected.value);
                }
                break;

            //2024.07.22
            case RoomDataInput.Classrooms:
                if (value === '') {
                    roomData['Classrooms'] = '';
                    roomData['ClassroomIds'] = [];
                    if (Array.isArray(this.state.ClassroomOptions)) {
                        this.state.ClassroomOptions.map((option, key) => {
                            const ele = document.getElementById(`checkbox-classroom-${key + 1}`);
                            if (ele !== null)
                                ele.checked = false;
                            return null;
                        });
                    }
                }
                else {
                    // let tmp_classrooms = CheckObjectStringEmpty(roomData, 'Classrooms');
                    // tmp_classrooms = tmp_classrooms === '' ? [] : tmp_classrooms.split(',').filter(x => CheckNullValue(x) !== null);
                    // if (Array.isArray(tmp_classrooms)) {
                    //     const index = tmp_classrooms.findIndex(x => String(x) === String(value.value));
                    //     if (index < 0)
                    //         tmp_classrooms.push(value.value);
                    //     else
                    //         tmp_classrooms.splice(index, 1);
                    //     tmp_classrooms.sort(function (a, b) {
                    //         if (a < b) { return -1; }
                    //         if (a > b) { return 1; }
                    //         return 0;
                    //     });
                    //     roomData['Classrooms'] = tmp_classrooms.join(',');
                    // }
                    //2025.01.24
                    if (Array.isArray(this.state.ClassroomOptions)) {
                        let tmp_classrooms_id = roomData['ClassroomIds'];
                        if (Array.isArray(tmp_classrooms_id)) {
                            let tmp_classrooms = [];
                            const findIndex_id = tmp_classrooms_id.findIndex(x => Number(x) === Number(value.id));
                            if (findIndex_id < 0)
                                tmp_classrooms_id.push(Number(value.id));
                            else
                                tmp_classrooms_id.splice(findIndex_id, 1);
                            tmp_classrooms_id.map((id, key) => {
                                const findIndex = this.state.ClassroomOptions.findIndex(x => Number(x.id) === Number(id));
                                if (findIndex > -1)
                                    tmp_classrooms.push(CheckObjectStringEmpty(this.state.ClassroomOptions[findIndex], 'value'));
                                return null;
                            });
                            tmp_classrooms_id.sort((a, b) => a - b);
                            tmp_classrooms = tmp_classrooms.filter(x => CheckNullValue(x) !== null);
                            tmp_classrooms.sort((a, b) => String(a).localeCompare(String(b)));
                            // tmp_classrooms.sort((a, b) => {
                            //     if (a < b) { return -1; }
                            //     if (a > b) { return 1; }
                            //     return 0;
                            // });
                            roomData['Classrooms'] = tmp_classrooms.join(',');
                            roomData['ClassroomIds'] = tmp_classrooms_id;
                        }
                        const selectAll_ele = document.getElementById('checkbox-classroom-select-all');
                        if (selectAll_ele !== null)
                            selectAll_ele.checked = this.state.ClassroomOptions.length === roomData['ClassroomIds'].length;
                    }
                }
                break;

            //2025.02.27
            case RoomDataInput.CategoryList:
                roomData['CategoryList'] = value === null ? [] : [{ id: value.id, label: value.label, value: value.value }];
                break;

            default: break;
        }
        if (this.state.isDevMode) {
            // console.log(`SaveDataInput ${Object.keys(RoomDataInput)[inputType]} ${JSON.stringify(value)} \n ${JSON.stringify(roomData)}`);
            console.log(`SaveDataInput ${inputType} ${JSON.stringify(value)} \n ${JSON.stringify(roomData)}`);
        }
        this.setState({
            RoomData: roomData,
            EditRoom_Group: CheckObjectNullValue(roomData, 'Group'),
            EditRoom_Subject: CheckObjectNullValue(roomData, 'Subject'),
        });
        // this.CheckOnUploadConditions();
    }
    SaveSearchOptions = (value, inputType = RoomDataInput.None) => {
        let selected = null;
        switch (inputType) {
            case RoomDataInput.SearchQsSet_ByGroup:
                const groupOptions = useAppService.getState().groupOptions;
                const findIndex_go = groupOptions.findIndex(x => value.value === x.value && value.id === x.id);
                selected = findIndex_go < 0 ? null : groupOptions[findIndex_go];
                this.setState({ SearchQsSet_ByGroup: selected });
                break;
            case RoomDataInput.SearchQsSet_BySubject:
                // const subjectOptions = useAppService.getState().subjectOptions;
                const subjectOptions = this.state.SubjectOptions;
                const findIndex_so = subjectOptions.findIndex(x => value.value === x.value && value.id === x.id);
                selected = findIndex_so < 0 ? null : subjectOptions[findIndex_so];
                this.setState({ SearchQsSet_BySubject: selected });
                break;
            case RoomDataInput.SearchQsSet_ByName:
                this.setState({ SearchQsSet_ByName: String(value) });
                break;
            case RoomDataInput.SearchQsSet_MaxQtyShow:
                this.setState({ SearchQsSet_MaxQtyShow: CheckNumber(value, 5) });
                break;
            default: break;
        }
        // this.Check_SearchQsSetCondition();
    }
    //2025.03.03
    GetSelectedCategoryItem = (roomData = null) => {
        if (roomData === null || roomData === undefined)
            return null;
        const categoryOptions = DecapitalizeJsonKeys(this.state.CategoryOptions);
        const categoryList = Array.isArray(roomData['CategoryList']) ? DecapitalizeJsonKeys(roomData['CategoryList']) : [];
        const categoryItem = categoryList.length > 0 ? categoryList[0] : null;
        if (categoryItem !== null) {
            const findIndex = categoryOptions.findIndex(x => Number(x.id) === Number(categoryItem.id));
            if (findIndex > -1)
                return categoryOptions[findIndex];
        }
        return null;
    }
    // //2025.02.27
    // GetSelectedCategoryItems = (roomData = null) => {
    //     if (roomData === null)
    //         return null;
    //     const categoryOptions = this.state.CategoryOptions;
    //     // const roomData = this.state.RoomData;
    //     const categoryList = Array.isArray(roomData['CategoryList']) ? roomData['CategoryList'] : [];
    //     if (Array.isArray(categoryList) && categoryList.length > 0) {
    //         let _selectedOptions = [];
    //         categoryList.forEach((data, key) => {
    //             const findIndex = categoryOptions.findIndex(x => Number(x.value) === Number(data.value));
    //             if (findIndex > -1)
    //                 return _selectedOptions.push(categoryOptions[findIndex]);
    //         });
    //         return _selectedOptions;
    //     }
    //     return [];
    // }
    //#region === Classroom Coomponents ===
    //2025.01.04
    GetClassroomOptions_Component = () => {
        let components = [];
        let roomData = this.state.RoomData;
        const classroomOptions = this.state.ClassroomOptions;
        if (Array.isArray(classroomOptions) && roomData !== null && roomData !== undefined) {

            //select all.
            components.push(<div className="form-check setting-checkbox"
                onChange={() => {
                    let roomData = this.state.RoomData;
                    const selectAll_ele = document.getElementById('checkbox-classroom-select-all');
                    const selectAll_checked = selectAll_ele === null ? false : selectAll_ele.checked;
                    let tmp_classrooms = [];
                    let tmp_classrooms_id = [];
                    classroomOptions.map((option, key) => {
                        const ele = document.getElementById(`checkbox-classroom-${key + 1}`);
                        if (ele !== null) {
                            ele.checked = selectAll_checked;
                            if (selectAll_checked) {
                                tmp_classrooms.push(String(option.value));
                                tmp_classrooms_id.push(Number(option.id));
                            }
                        }
                        return null;
                    });
                    tmp_classrooms_id.sort((a, b) => a - b);
                    tmp_classrooms = tmp_classrooms.filter(x => CheckNullValue(x) !== null);
                    tmp_classrooms.sort((a, b) => {
                        if (a < b) { return -1; }
                        if (a > b) { return 1; }
                        return 0;
                    });
                    roomData['Classrooms'] = tmp_classrooms.join(',');
                    roomData['ClassroomIds'] = tmp_classrooms_id;
                    this.setState({
                        RoomData: roomData,
                    }, () => {
                        console.log('(Select All) Classrooms =\n' + JSON.stringify(roomData))
                    });
                }}
            >
                <input className="form-check-input" type="checkbox" value="" id={`checkbox-classroom-select-all`}
                    readOnly={true}
                    // defaultChecked={this.state.RoomData === null || Array.isArray(this.state.ClassroomOptions) === false ? false :
                    //     String(this.state.RoomData['Classrooms']).split(',').filter(x => CheckNullValue(x) !== null).length === this.state.ClassroomOptions.length}
                    // checked={roomData === null || Array.isArray(roomData['ClassroomIds']) === false ? false :
                    //     classroomOptions.length === Array(roomData['ClassroomIds']).length}
                    checked={Array.isArray(roomData['ClassroomIds']) ? classroomOptions.length === roomData['ClassroomIds'].length : false}
                />
                <label className='form-check-label'
                    htmlFor={`checkbox-classroom-select-all`}
                    style={{ cursor: 'pointer' }}
                >Select All</label>
            </div>);

            //select by group.
            //#region 
            //2025.01.24
            let groupOptions = [];
            classroomOptions.map((option, key) => {
                if (groupOptions.findIndex(x => x.id === option.groupId) < 0) {
                    groupOptions.push({
                        id: CheckObjectNumber(option, 'groupId'),
                        group: CheckObjectStringEmpty(option, 'group'),
                        classroomId: CheckObjectNumber(option, 'id'),
                        classroom: CheckObjectStringEmpty(option, 'value'),
                    });
                }
                return null;
            });
            groupOptions.map((g_option, gkey) => {
                const group_id = CheckObjectNumber(g_option, 'id')
                const g_GroupClassrooms = classroomOptions.filter(x => CheckObjectNumber(x, 'groupId') === group_id);
                const g_totalGroupQty = Array.isArray(g_GroupClassrooms) ? g_GroupClassrooms.length : 0;
                // const g_classrooms = Array.isArray(roomData['Classrooms']) ? roomData['Classrooms'] : [];
                const classrooms_id = Array.isArray(roomData['ClassroomIds']) ? roomData['ClassroomIds'] : [];
                let g_selectedGroupQty = 0;
                classrooms_id.forEach(crId => {
                    if (classroomOptions.some(x => CheckObjectNumber(x, 'id') === Number(crId) && CheckObjectNumber(x, 'groupId') === group_id))
                        g_selectedGroupQty++;
                });

                components.push(<div className="form-check setting-checkbox"
                    onChange={() => {
                        let selectAll_ele = document.getElementById(`checkbox-classroom-select-by-groupId-${group_id}`);
                        const selectAll_checked = selectAll_ele === null ? false : selectAll_ele.checked;
                        selectAll_ele.checked = !selectAll_ele.checked;
                        let tmp_classrooms = [];
                        let tmp_classrooms_id = JSON.parse(JSON.stringify(classrooms_id));
                        if (Array.isArray(tmp_classrooms_id)) {
                            g_GroupClassrooms.map((g_c_option, gcKey) => {
                                const g_c_id = CheckObjectNumber(g_c_option, 'id');
                                if (selectAll_checked) {
                                    //add.
                                    const findIndex_id = tmp_classrooms_id.findIndex(x => Number(x) === g_c_id);
                                    if (findIndex_id < 0)
                                        tmp_classrooms_id.push(Number(g_c_option.id));
                                }
                                else {
                                    //remove.
                                    const findIndex_id = tmp_classrooms_id.findIndex(x => Number(x) === g_c_id);
                                    if (findIndex_id > -1)
                                        tmp_classrooms_id.splice(findIndex_id, 1);
                                }
                                return null;
                            });
                            classroomOptions.map((c_option, key) => {
                                const findIndex = tmp_classrooms_id.findIndex(x => Number(x) === CheckObjectNumber(c_option, 'id'));
                                if (findIndex > -1)
                                    tmp_classrooms.push(CheckObjectStringEmpty(c_option, 'value'));
                                return null;
                            });
                            // classroomOptions.map((c_option, key) => {
                            //     if (CheckObjectNumber(c_option, 'groupId') === CheckObjectNumber(g_option, 'id')) {
                            //         if (selectAll_checked) {
                            //             //add.
                            //             const findIndex_id = tmp_classrooms_id.findIndex(x => Number(x) === Number(c_option.id));
                            //             if (findIndex_id < 0)
                            //                 tmp_classrooms_id.push(Number(c_option.id));
                            //             const findIndex = tmp_classrooms.findIndex(x => String(x) === String(c_option.value));
                            //             if (findIndex < 0)
                            //                 tmp_classrooms.push(String(c_option.value));
                            //         }
                            //         else {
                            //             //remove.
                            //             const findIndex_id = tmp_classrooms_id.findIndex(x => Number(x) === Number(c_option.id));
                            //             if (findIndex_id > -1)
                            //                 tmp_classrooms_id.splice(findIndex_id, 1);
                            //             const findIndex = tmp_classrooms.findIndex(x => String(x) === String(c_option.value));
                            //             if (findIndex > -1)
                            //                 tmp_classrooms.splice(findIndex, 1);
                            //         }
                            //         // tmp_classrooms.push(String(c_option.value));
                            //         // tmp_classrooms_id.push(Number(c_option.id));
                            //     }
                            //     return null;
                            // });
                            tmp_classrooms_id.sort((a, b) => a - b);
                            tmp_classrooms.sort((a, b) => String(a).localeCompare(String(b)));
                            // tmp_classrooms.sort((a, b) => {
                            //     if (a < b) { return -1; }
                            //     if (a > b) { return 1; }
                            //     return 0;
                            // });
                            roomData['Classrooms'] = tmp_classrooms.join(',');
                            roomData['ClassroomIds'] = tmp_classrooms_id;
                            this.setState({
                                RoomData: roomData,
                            }, () => {
                                console.log(`(Select By Group (${g_option.group}) (${g_option.id})) Classrooms =\n ${JSON.stringify(roomData)}`)
                            });
                        }
                    }}
                >
                    <input className="form-check-input" type="checkbox" value="" id={`checkbox-classroom-select-by-groupId-${group_id}`}
                        readOnly={true}
                        checked={g_selectedGroupQty === g_totalGroupQty}
                    />
                    <label className='form-check-label'
                        htmlFor={`checkbox-classroom-select-by-groupId-${g_option.id}`}
                        style={{ cursor: 'pointer' }}
                    >Select All of <b>{g_option.group}</b> ({`${g_selectedGroupQty}/${g_totalGroupQty}`})</label>
                </div>);
                return null;
            });
            //#endregion

            components.push(<hr />);

            //classrooms.
            classroomOptions.map((option, key) => {
                return components.push(<div className="form-check setting-checkbox"
                    onChange={() => this.SaveDataInput(option, RoomDataInput.Classrooms)}
                >
                    <input className="form-check-input" type="checkbox" value="" id={`checkbox-classroom-${key + 1}`}
                        readOnly={true}
                        checked={this.state.RoomData === null ? false : String(this.state.RoomData['Classrooms']).split(',').includes(String(option.value))}
                    />
                    <label className='form-check-label'
                        htmlFor={`checkbox-classroom-${key + 1}`}
                        style={{ cursor: 'pointer' }}
                    >{CheckObjectStringEmpty(option, 'value', `-${key}-`)} ({CheckObjectStringEmpty(option, 'group')})</label>
                </div>);
            });
        }
        return (components);
    }
    //2025.01.27
    GetSelectedClassrooms_Component = () => {
        let components = [];
        // let prev_groupId = 0;
        const classroomOptions = this.state.ClassroomOptions;
        const classrooms = this.state.RoomData['Classrooms'].split(',');
        let classrooms_group = [];
        classroomOptions.forEach((data, key) => {
            const findIndex = classrooms.findIndex(x => String(x) === CheckObjectStringEmpty(data, 'value'));
            if (findIndex > -1) {
                classrooms_group.push({ name: CheckObjectStringEmpty(data, 'value'), groupId: CheckObjectNumber(data, 'groupId'), group: CheckObjectStringEmpty(data, 'group') });
            }
        });
        classrooms_group.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return a.groupId - b.groupId;
        });
        const filteredClassroomGroups = Array.from(new Map(classrooms_group.map(item => [item.groupId, item])).values());
        filteredClassroomGroups.forEach((cr_group, gKey) => {
            const classrooms_per_group = classrooms_group.filter(x => x.groupId === cr_group.groupId);
            components.push(<span style={{ color: 'black' }}>{cr_group.group + ': '}</span>);
            classrooms_per_group.forEach((cr, crKey) => {
                components.push(cr.name + (crKey < classrooms_per_group.length - 1 ? ', ' : ''));
            });
            if (gKey + 1 < filteredClassroomGroups.length)
                components.push(<br />);
        });
        // for (let key = 0; key < classrooms_group.length; key++) {
        //     const data = classrooms_group[key];
        //     // if (prev_groupId === 0 || (key + 1 < classrooms_group.length && CheckObjectNumber(classrooms_group[key + 1], 'groupId')) < CheckObjectNumber(data, 'groupId')) {
        //     //     components.push(CheckObjectStringEmpty(data, 'group') + ': ');
        //     // }
        //     components.push(data.name + (key < classrooms_group.length - 1 ? ', ' : ''));
        //     if (key + 1 < classrooms_group.length) {
        //         const groupId = CheckObjectNumber(classrooms_group[key + 1], 'groupId');
        //         if (prev_groupId === 0)
        //             prev_groupId = groupId;
        //         if (prev_groupId !== groupId) {
        //             prev_groupId = groupId;
        //             components.push(<br />);
        //         }
        //     }
        // }
        return (components);
    }
    //#endregion === Classroom Coomponents ===
    //#endregion === Edit Room === end ===//

    //#region === Create New Room === start ===//
    ToggleCreateRoomModal = () => {
        if (this.state.PA_Create === false)
            return null;

        this.setState({
            Mode_Create_RoomDataModal: !this.state.Mode_Create_RoomDataModal
        }, async () => {
            if (this.state.Mode_Create_RoomDataModal) {
                let roomData = { ...RoomDataInitValue };
                roomData.DateStart = moment().format('YYYY-MM-DD');
                roomData.DateEnd = moment().format('YYYY-MM-DD');
                this.setState({
                    RoomData: roomData,
                    RoomData_RoomCode_isValid: false,
                });
                await Delay(0);
                this.ToggleCreateEditRoomModal();
                this.GenerateRandomRoomCode();
            }
            else {
                //close modal.
                if (this.state.IsChild)
                    if (this.props.CancelCreateNewRoom_Callback !== undefined)
                        this.props.CancelCreateNewRoom_Callback();
            }
            // this.ResetNewRoomData();
            // if (this.state.Mode_Create_RoomDataModal) {
            //     this.GenerateRandomRoomCode();
            //     // this.CheckOnGroupList();
            // }
            // else {
            //     //close modal.
            //     if (this.state.IsChild)
            //         this.props.CancelCreateNewRoom_Callback();
            // }
        });
    }
    getRandomIntegerBetweenRange = (min, max) => {
        var x = parseInt(((Math.random() * ((max - min) + 1)) + min), 10);
        return x;
    }
    GenerateRandomRoomCode = async () => {
        let roomData = this.state.RoomData;
        roomData.RoomCode = 0;
        this.setState({ RoomData: roomData, RoomData_RoomCode_isValid: false });
        let _NewRoomCode_isValid = false;
        let _NewRoomCode = 0;
        let _currentLimitCount = 0;
        let _maxLimitCount = 100;   //200 retries = around 1 min or more.

        do {
            if (_currentLimitCount > _maxLimitCount) {
                _NewRoomCode = this.getRandomIntegerBetweenRange(100000, 999999);   //6 digits
            }
            else {
                _NewRoomCode = this.getRandomIntegerBetweenRange(10000, 99999);     //5 digits, default
                _currentLimitCount++;
            }
            _NewRoomCode_isValid = await this.CheckIfRoomCodeIsValid(_NewRoomCode);
            await Delay(300);
        } while (_NewRoomCode_isValid === false);

        if (this.state.isDevMode)
            console.log("Room Code : " + _NewRoomCode + "\nIsValid : " + _NewRoomCode_isValid);

        roomData.RoomCode = _NewRoomCode;
        this.setState({ RoomData: roomData, RoomData_RoomCode_isValid: _NewRoomCode_isValid });
    }
    CheckIfRoomCodeIsValid = async (roomCode) => {
        let _RoomCode_isValid = false;
        //check via FireStore.
        const roomInfo = await this.GetRoomDataViaRoomCode(roomCode);
        if (roomInfo === null || roomInfo === undefined)
            _RoomCode_isValid = true;
        else
            _RoomCode_isValid = false;
        //return.
        return _RoomCode_isValid;
    }
    GetRoomDataViaRoomCode = async (roomCode) => {
        let roomInfo = null;
        let done = false;
        let success = false;
        let errorMessage = '';
        let async_action = async () => {
            let data = [];
            await getDocs(query(collection(firestore, 'LiveQuiz_UniqueRoomCode'),
                where('RoomCode', '==', Number(roomCode)), limit(1)))
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        data.push(doc.data());
                    });
                    success = true;
                    done = true;
                }).catch(error => {
                    success = false;
                    errorMessage = error;
                    done = true;
                });
            await DelayUntil(() => done === true);
            if (Array.isArray(data) && data.length > 0 && data[0].hasOwnProperty('RoomId')) {
                roomInfo = data[0];
            }
            if (success) {
                if (this.state.isDevMode)
                    console.log("Room Code : " + roomCode + "\nModal : " + JSON.stringify(roomInfo));
            }
            else {
                if (this.state.isDevMode)
                    console.log('bad Internet connection - retrying...\n\n' + JSON.stringify(errorMessage));
            }

            //#region old code.
            // await this.props.firestore
            //     .collection("LiveQuiz_UniqueRoomCode")
            //     .where('RoomCode', '==', Number(_roomCode))
            //     .orderBy('DateStart', 'asc')
            //     .get()
            //     .then(querySnapshot => {
            //         let data = [];
            //         if (querySnapshot !== null) {
            //             querySnapshot.forEach((doc) => {
            //                 data.push(doc.data());
            //             });
            //             if (data.length > 0 || data.hasOwnProperty('RoomId'))
            //                 data = data[0];
            //             else
            //                 data = null;
            //         }
            //         if (data !== null && data.hasOwnProperty('RoomId')) {
            //             _roomInfo = data;
            //         }
            //         if (this.state.isDevMode)
            //             console.log("Room Code : " + _roomCode + "\nModal : " + JSON.stringify(_roomInfo));
            //         _success = true;
            //         done = true;
            //     })
            //     .catch(error => {
            //         _success = false;
            //         done = true;
            //         if (this.state.isDevMode)
            //             console.log('bad Internet connection - retrying...\n\n' + JSON.stringify(error));
            //     });
            // await DelayUntil(() => done === true);
            //#endregion
        }
        do {
            success = false;
            done = false;
            await async_action();
            // await DelayUntil(() => done === true);
        } while (success === false);
        return roomInfo;
    }
    ResetNewRoomData = () => {
        let roomData = { ...RoomDataInitValue };
        roomData.DateStart = moment().format('YYYY-MM-DD');
        roomData.DateEnd = moment().format('YYYY-MM-DD');
        this.setState({
            RoomData: roomData,
            Mode_Create_RoomDataModal: false,

            IsSearchQsSetSelected: false,
            SearchQsSet_QuestionSet_Selected: null,
            SearchQsSet_ByGroup: null,      //obj
            SearchQsSet_BySubject: null,    //obj
            SearchQsSet_MaxQtyShow: 5,
            SearchQsSet_SelectQuestionSetId: '',
        });
    }
    //2021.09.28
    CreateOrUpdateRoom_ViaApi = async () => {

        if (this.state.PA_Create === false)
            return null;

        let roomData = this.state.RoomData;
        const roomCode = CheckObjectStringEmpty(roomData, 'RoomCode');

        useAppService.getState().setModal('', 'Creating Room <' + roomCode + '>...', null, AlertMode.Loading);

        //room data validation.
        const result = this.RoomDataValidation();
        if (result.Success === false) {
            useAppService.getState().setModal('Validation Failed', '<b>Unable to create Room <' + roomCode + '>.</b><br /><br /><i>' + result.Messages + '</i>');
            return null;
        }

        //backUp set Date.
        if (CheckObjectStringEmpty(roomData, 'DateStart') === '' || CheckObjectStringEmpty(roomData, 'DateEnd') === '') {
            if (CheckObjectBoolean(roomData, 'AccessibleOnSingleDayOnly') && CheckObjectStringEmpty(roomData, 'Date') !== '') {
                roomData.DateStart = roomData.Date;
                roomData.DateEnd = roomData.Date;
                this.setState({
                    RoomData: roomData,
                });
            }
        }

        //current time.
        let _currentRoomId = (new Date()).getTime().toString();
        // let _current = moment();
        // let _currentRoomId = _current.valueOf();
        // let _lastUpdate = _current.format('YYYY-MM-DD HH:mm:ss');
        // let _lastUpdateUtc = _current.utc().format('YYYY-MM-DD HH:mm:ss');

        let roomModal = await PopulateRoomDataModal(roomData, _currentRoomId);
        roomModal['SupportedDocExt'] = Array.isArray(roomModal['SupportedDocExt']) ? roomModal['SupportedDocExt'].join(',') : '';
        roomModal['CategoryList'] = Array.isArray(roomData['CategoryList']) ? CapitalizeJsonKeys(roomData['CategoryList']) : [];    //2025.02.27
        if (this.state.isDevMode) {
            console.log('currentRoomId =\n' + _currentRoomId);
            console.log('roomData =\n' + JSON.stringify(roomData));
            console.log('roomModal =\n' + JSON.stringify(roomModal));
        }

        //new api for create/upate room. 2024.04.30
        let success = false;
        // let responseData = null;
        let errorMessage = '';
        let done = false;
        await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Quiz/Room/CreateOrUpdate',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(roomModal),
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('CreateNewRoom_ViaApi (source)', JSON.stringify(data));
                success = CheckObjectBoolean(data, 'success');
                if (success) {
                    // responseData = data.data;
                }
                else {
                    errorMessage = CheckObjectStringEmpty(data, 'message');
                    if (this.state.isDevMode)
                        console.log('Error', 'api - room - create (failed)\n' + JSON.stringify(data));
                }
                done = true;
            })
            .catch(error => {
                errorMessage = error.message;
                if (this.state.isDevMode)
                    console.log('Error', 'api - room - create (error)\n' + error.message);
                done = true;
            });
        await DelayUntil(() => done === true);

        //#region === old codes ===

        // //insert room details on RTDB.  // YYYYMMDD > pkquiz-room-detail
        // //insert room code on RTDB.     // YYYYMMDD > pkquiz-room-code
        // //insert room state on RTDB.    // YYYYMMDD > pkquiz-live
        // let success = await this.InsertRoomRecordOnRTDB(roomModal, _currentRoomId);

        // //insert room details on FS.
        // if (success)
        //     success = await this.InsertRoomRecordOnFS(roomModal, _currentRoomId);

        // //trigger CMS sync Room details.    //2021.11.10
        // await Delay(1500);
        // if (success)
        //     await this.TriggerRoomRecordSync_ViaApi(_currentRoomId, this.state.Mode_Create_RoomDataModal);

        //#endregion === old codes ===

        //final.
        const title = 'Room <' + roomCode + '>';
        if (success) {
            // useAppService.getState().setModal();            
            const msg = title + ' has been created.';
            useAppService.getState().setModal(title, msg);
            if (this.state.isDevMode)
                console.log(msg);

            this.ResetNewRoomData();
            this.ToggleCreateEditRoomModal();

            if (this.state.IsChild === false) {
                await this.LoadList_ViaApi();
                await Delay(0);
                await this.LoadSelectedRoom(roomCode, _currentRoomId);
            }
            else {
                //for ManageEvent > Create New Room when editing Event.
                if (this.props.CreateNewRoomIsCompleted_Callback !== undefined)
                    this.props.CreateNewRoomIsCompleted_Callback(roomCode);
            }
        }
        else {
            useAppService.getState().setModal(title, errorMessage);
        }
    }
    //2021.09.30
    RoomDataValidation = () => {
        let _messages = [];

        let roomData = this.state.RoomData;

        //room title.
        if (CheckObjectStringEmpty(roomData, 'RoomTitle') === '')
            _messages.push("Room's <u><b>Title</b></u> is still empty.");

        //group.
        if (CheckObjectNullValue(roomData, 'Group') === null)
            _messages.push("Room's <u><b>Group</b></u> is not yet picked.");

        //subject.
        if (CheckObjectNullValue(roomData, 'Subject') === null || CheckObjectNumber(roomData, 'SubjectId') === 0)
            _messages.push("Room's <u><b>Subject</b></u> is not yet picked.");

        //date.
        if (CheckObjectBoolean(roomData, 'AccessibleOnSingleDayOnly')) {
            if (CheckObjectStringEmpty(roomData, 'Date') === '')
                _messages.push("Room's <u><b>Date</b></u> is not yet picked.");
        }
        else {
            //start date.
            if (CheckObjectStringEmpty(roomData, 'DateStart') === '')
                _messages.push("Room's <u><b>Date (From)</b></u> is not yet picked.");
            //end date.
            if (CheckObjectStringEmpty(roomData, 'DateEnd') === '')
                _messages.push("Room's <u><b>Date (To)</b></u> is not yet picked.");
        }

        //start time.
        if (CheckObjectStringEmpty(roomData, 'TimeStart') === '')
            _messages.push("Room's <u><b>Time (From)</b></u> is not yet picked.");

        //end time.
        if (CheckObjectStringEmpty(roomData, 'TimeEnd') === '')
            _messages.push("Room's <u><b>Time (To)</b></u> is not yet picked.");

        //duration.
        if (CheckObjectBoolean(roomData, 'UseCustomDuration')) {
            if (
                CheckObjectNumber(roomData, 'Duration_Hour') === 0
                && CheckObjectNumber(roomData, 'Duration_Min') === 0
                && CheckObjectNumber(roomData, 'Duration_Sec') === 0
            ) {
                _messages.push("Please fill in the appropriate amount for <u><b>custom duration</b></u>'s fields.");
            }
        }

        //assign DateStart & DateEnd.
        if (CheckObjectNumber(roomData, 'Date') !== '' && CheckObjectNumber(roomData, 'DateStart') === '' && CheckObjectNumber(roomData, 'DateEnd') === '') {
            const _date = moment(CheckObjectNumber(roomData, 'Date')).format('YYYY-MM-DD');
            roomData.DateStart = _date;
            roomData.DateEnd = _date;
            this.setState({
                RoomData: roomData,
            });
        }

        //room type.
        if (CheckObjectNumber(roomData, 'RoomType') === 1) {
            //2021.11.08
            if (Array.isArray(roomData.SupportedDocExt) && roomData.SupportedDocExt.length === 0)
                _messages.push("Room's <u><b>Supported File Format</b></u> is not yet selected.");
        }
        else {
            //question set.
            if (CheckObjectNullValue(roomData, 'QuestionSet') === null)
                _messages.push("Room's <u><b>Question Set</b></u> is not yet selected.");
        }

        let _msg = '<ul style="list-style-type:disclosure-closed;margin:0px;line-height:2;">';
        _messages.map((data, key) => {
            return _msg += '<li>' + data + '</li>';
        });
        _msg += '</ul>';

        return {
            Success: (_messages.length === 0),
            // Messages: _messages.join('<br />'), 
            Messages: _msg,
        };
    }
    //2024.03.14 - revamped.
    InsertRoomRecordOnRTDB = async (_modal = null, _currentRoomId = '') => {
        let success = false;
        if (_modal !== null) {
            const roomData = _modal;
            const roomCode = CheckObjectStringEmpty(roomData, 'RoomCode');

            //room DateStart.
            const _date = moment(CheckObjectStringEmpty(roomData, 'DateStart', moment().format('YYYY-MM-DD')));
            const ref_date = _date.format('YYYYMMDD');
            const rd_date = _date.format('YYYY-MM-DD');
            const dateStart = CheckObjectStringEmpty(roomData, 'DateStart', rd_date);
            const dateEnd = CheckObjectStringEmpty(roomData, 'DateEnd', rd_date);
            const timeStart = CheckObjectStringEmpty(roomData, 'TimeStart', '00:00:00');
            const timeEnd = CheckObjectStringEmpty(roomData, 'TimeEnd', '23:59:00');

            //RTDB ref.
            const roomDetailRef = child(ref(dbLiveQuiz), 'pkquiz/' + ref_date + '/pkquiz-room-detail/' + _currentRoomId);
            const roomCodeRef = child(ref(dbLiveQuiz), 'pkquiz/' + ref_date + '/pkquiz-room-code/' + roomCode);
            const roomStateRef = child(ref(dbLiveQuiz), 'pkquiz/' + ref_date + '/pkquiz-live/' + _currentRoomId);

            //set RTDB - Room Details.
            success = false;
            await set(roomDetailRef, _modal).then(() => {
                success = true;
            }).catch(error => {
                if (this.state.isDevMode)
                    console.log('set room detail (failed) (' + roomCode + '/' + _currentRoomId + ') =\n' + JSON.stringify(error));
            });
            // await roomDetailRef
            //     .set(_roomDetails_DataToUpdate)
            //     .then(() => { success = true; })
            //     .catch((error) => { if (this.state.isDevMode) { console.log('set room detail (failed) =\n' + error.message); } });

            //set RTDB - Room Code.
            if (success) {
                success = false;
                await set(roomCodeRef, _currentRoomId).then(() => {
                    success = true;
                }).catch(error => {
                    if (this.state.isDevMode)
                        console.log('set room code (failed) (' + roomCode + '/' + _currentRoomId + ') =\n' + JSON.stringify(error));
                });
                // await roomCodeRef
                //     .set(_currentRoomId)
                //     .then(() => { success = true; })
                //     .catch((error) => { if (this.state.isDevMode) { console.log('set room code (failed) =\n' + error.message); } });
            }

            //set RTDB - Room State.
            const _roomState_DataToUpdate = {
                DateStart: dateStart,
                DateEnd: dateEnd,
                TimeStart: timeStart,
                TimeEnd: timeEnd,
                QuizState: 'started',
                RoomId: _currentRoomId,
                QuizStartDT: dateStart + ' ' + timeStart,
                QuizEndDT: dateEnd + ' ' + timeEnd,
            };
            if (success) {
                success = false;
                await set(roomStateRef, _roomState_DataToUpdate).then(() => {
                    success = true;
                }).catch(error => {
                    if (this.state.isDevMode)
                        console.log('set room state (failed) (' + roomCode + '/' + _currentRoomId + ') =\n' + JSON.stringify(error));
                });
                // await roomStateRef
                //     .set(_roomState_DataToUpdate)
                //     .then(() => { success = true; })
                //     .catch((error) => { if (this.state.isDevMode) { console.log('set room state (failed) =\n' + error.message); } });
            }
            if (success) {
                if (this.state.isDevMode)
                    console.log('All room records (RTDB) (' + roomCode + '/' + _currentRoomId + ') are saved.');
            }
            else {
                //remove posted records on any failed attempt, as roomId will be renew every time create room is issued.

                //remove room detail.
                await remove(roomDetailRef);
                // await roomDetailRef.remove();

                //remove room code.
                await remove(roomCodeRef);
                // await roomCodeRef.remove();

                //remove room state.
                await remove(roomStateRef);
                // await roomStateRef.remove();

                if (this.state.isDevMode)
                    console.log('(failed) (' + roomCode + '/' + _currentRoomId + ') Error occurred. All previously saved room records are Removed.');
            }
        }
        return success;
    }
    //2024.03.14 - revamped.
    InsertRoomRecordOnFS = async (_modal = null, _currentRoomId = '') => {
        let success = false;
        const roomCode = CheckObjectNumber(_modal, 'RoomCode');
        if (_modal !== null) {
            _modal['RoomCode'] = roomCode;  //set to Number type. important.
            await setDoc(doc(firestore, 'LiveQuiz_UniqueRoomCode', _currentRoomId), _modal)
                .then(() => {
                    success = true;
                })
                .catch(error => {
                    success = false;
                    if (this.state.isDevMode)
                        console.log('set room (FS) (failed) = ' + error);
                });
            // await this.props.firestore
            //     .collection("LiveQuiz_UniqueRoomCode")
            //     .doc(_currentRoomId)
            //     .set(_modal)
            //     .then(() => { success = true; })
            //     .catch((error) => { if (this.state.isDevMode) { console.log('set room (FS) (failed) =\n' + error.message); } });
        }
        if (this.state.isDevMode) {
            if (success)
                console.log('room data (FS) (' + roomCode + '/' + _currentRoomId + ') is saved.');
            else
                console.log('failed to save - room data (FS) (' + roomCode + '/' + _currentRoomId + ').');
        }
        return success;
    }
    //2021.11.10
    TriggerRoomRecordSync_ViaApi = async (_currentRoomId, _new = true) => {
        const roomData = this.state.RoomData;
        const roomCode = CheckObjectStringEmpty(roomData, 'RoomCode');
        const roomId = CheckObjectStringEmpty(roomData, 'RoomId', _currentRoomId);
        const { centerUserId, authorId } = GetPropIds(useGlobal.getState().user);

        await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Quiz/Room/Sync/'
            + centerUserId + '/'
            + authorId + '/'
            + roomCode + '/'
            + roomId,
            // Api/LearningCentre/Quiz/Room/Sync/{centerUserId}/{authorId}/{roomcode}/{room_id}
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (!data.success)
                    if (this.state.isDevMode)
                        console.log('Error', 'api - room sync (' + (_new ? 'new' : 'update') + ') (failed) (' + roomCode + '/' + roomId + ') \n' + JSON.stringify(data));
            })
            .catch(error => {
                if (this.state.isDevMode)
                    console.log('Error', 'api - room sync (' + (_new ? 'new' : 'update') + ') (error) (' + roomCode + '/' + roomId + ') \n' + error.message);
            });
    }
    Set_RoomData_FileExtOptions_Ref = (ref) => {
        this.RoomData_FileExtOptions_Ref.push(ref);
    }
    UpdateStateOnFileExtOptions = (index) => {
        let roomData = this.state.RoomData;
        let _supportedFileFormats = CheckObjectNullValue(roomData, 'SupportedDocExt', []);   //this.state.NewRoom_SupportedDocExt;
        this.RoomData_FileExtOptions_Ref.map((data, key) => {
            if (index === key) {
                let findIndex = _supportedFileFormats.findIndex(x => x === FileExtOptions[key].value);
                if (findIndex < 0) {
                    _supportedFileFormats.push(FileExtOptions[key].value);
                    data.checked = true;
                }
                else {
                    _supportedFileFormats.splice(findIndex, 1);
                    data.checked = false;
                }
            }
            return null;
        });
        roomData.SupportedDocExt = _supportedFileFormats;
        this.setState({
            RoomData: roomData,
        }, () => {
            if (this.state.isDevMode)
                console.log('SupportedDocExt =\n' + _supportedFileFormats.join(','));
        });
    }
    //#endregion === Create New Room === end ===//

    //#region === Delete Room === start ===//
    DeleteThisRoom_ViaApi = async () => {
        // useAppService.getState().setModal('', '<b>Delete Room currently not available.</b>', false);

        const roomData = this.state.RoomData;

        if (this.state.PA_Delete === false)
            return null;
        if (CheckNullValue(roomData) === null)
            return null;

        const roomCode = CheckObjectStringEmpty(roomData, 'RoomCode');
        const roomId = CheckObjectStringEmpty(roomData, 'RoomId');

        this.ToggleDeleteRoomModal();
        useAppService.getState().setModal('', 'Removing Room <' + roomCode + '>...', null, AlertMode.Loading);
        let success = false;
        let errorMessage = [];

        const { centerUserId, authorId } = GetPropIds(useGlobal.getState().user);

        //2021.11.17
        //update room records in CMS, MarkedAsDeleted/delete azure files/mappings etc.
        try {
            await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Quiz/Room/Remove/'
                + centerUserId + '/'
                + authorId + '/'
                + roomCode + '/'
                + roomId,
                // Api/LearningCentre/Quiz/Room/Remove/{centerUserId}/{authorId}/{roomcode}/{room_id}
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(res => res.json())
                .then(data => {
                    success = CheckObjectBoolean(data, 'success');
                    if (!success)
                        if (this.state.isDevMode)
                            console.log('Error', 'api - room delete (failed) (' + roomCode + '/' + roomId + ') \n' + JSON.stringify(data));
                })
                .catch(error => {
                    if (this.state.isDevMode)
                        console.log('Error', 'api - room delete (error) (' + roomCode + '/' + roomId + ') \n' + error.message);
                });
        }
        catch (error) {
            if (this.state.isDevMode)
                console.log('remove room (Api) (error) (' + roomCode + '/' + roomId + ') = ' + error);
        }

        //#region Firebase related.
        // try {
        //     //Delete from FS.
        //     await deleteDoc(doc(firestore, 'LiveQuiz_UniqueRoomCode', roomId))
        //         .catch(error => {
        //             success = false;
        //             errorMessage.push(error);
        //             if (this.state.isDevMode)
        //                 console.log('remove room FS (error) (' + roomCode + '/' + roomId + ') = ' + error);
        //         });
        //     // await firestore
        //     //     .collection('LiveQuiz_UniqueRoomCode')
        //     //     .doc(String(this.state.SelectedRoom.RoomId))
        //     //     .delete()
        //     //     .catch(error => {
        //     //         success = false;
        //     //         errorMessage.push(error);
        //     //         if (this.state.isDevMode)
        //     //             console.log('remove room FS (error) = ' + error);
        //     //     });

        //     //Delete from RTDB.
        //     const _date = moment(CheckObjectStringEmpty(roomData, 'DateStart') + ' 00:00:00').format('YYYYMMDD');
        //     await remove(child(ref(dbLiveQuiz), 'pkquiz/' + _date + '/pkquiz-room-detail/' + roomId))
        //         .catch((error) => {
        //             success = false;
        //             errorMessage.push(error);
        //             if (this.state.isDevMode)
        //                 console.log('remove room RTDB (room detail) (error) (' + roomCode + '/' + roomId + ') = ' + error);
        //         });
        //     // await dbLiveQuiz
        //     //     .ref('pkquiz/' + _date + '/pkquiz-room-detail/' + String(this.state.SelectedRoom.RoomId))
        //     //     .remove()
        //     //     .catch((error) => {
        //     //         success = false;
        //     //         errorMessage.push(error);
        //     //         if (this.state.isDevMode)
        //     //             console.log('remove room RTDB (room detail) (error) = ' + error);
        //     //     });
        //     await remove(child(ref(dbLiveQuiz), 'pkquiz/' + _date + '/pkquiz-room-code/' + roomCode))
        //         .catch((error) => {
        //             success = false;
        //             errorMessage.push(error);
        //             if (this.state.isDevMode)
        //                 console.log('remove room RTDB (room code) (error) (' + roomCode + '/' + roomId + ') = ' + error);
        //         });
        //     // await dbLiveQuiz
        //     //     .ref('pkquiz/' + _date + '/pkquiz-room-code/' + String(this.state.SelectedRoom.RoomCode))
        //     //     .remove()
        //     //     .catch((error) => {
        //     //         success = false;
        //     //         errorMessage.push(error);
        //     //         if (this.state.isDevMode)
        //     //             console.log('remove room RTDB (room code) (error) = ' + error);
        //     //     });
        //     await remove(child(ref(dbLiveQuiz), 'pkquiz/' + _date + '/pkquiz-live/' + roomId))
        //         .catch((error) => {
        //             success = false;
        //             errorMessage.push(error);
        //             if (this.state.isDevMode)
        //                 console.log('remove room RTDB (room state) (error) (' + roomCode + '/' + roomId + ')= ' + error);
        //         });
        //     // await dbLiveQuiz
        //     //     .ref('pkquiz/' + _date + '/pkquiz-live/' + String(this.state.SelectedRoom.RoomId))
        //     //     .remove()
        //     //     .catch((error) => {
        //     //         success = false;
        //     //         errorMessage.push(error);
        //     //         if (this.state.isDevMode)
        //     //             console.log('remove room RTDB (room state) (error) = ' + error);
        //     //     });
        // }
        // catch (error) {
        //     if (this.state.isDevMode)
        //         console.log('remove room (Firebase) (error) (' + roomCode + '/' + roomId + ') = ' + error);
        // }
        //#endregion

        //done
        if (success) {
            this.ToggleCreateEditRoomModal();
            useAppService.getState().setModal('', 'Room <' + roomCode + '> has been removed.');
            // this.LoadRoomList();
            this.LoadList_ViaApi();
            this.ResetEditRoomParams();
        }
        else {
            useAppService.getState().setModal('', 'Failed to remove Room <' + roomCode + '>.<br /><br />' + errorMessage.join('<br />'));
        }
    }
    ToggleDeleteRoomModal = () => {
        if (this.state.PA_Delete === false)
            return null;
        this.setState({ ShowDeleteRoomModal: !this.state.ShowDeleteRoomModal });
    }
    //#endregion === Delete Room === end ===//

    //#region === Search Room by Params (new) 2025.01.16 ===//
    ToggleSearchRoomQueryModal = () => {
        const toggle = !this.state.SearchRoomQueryModal_Toggle;
        this.setState({
            SearchRoomQueryModal_Toggle: toggle,
            SearchRoomQueryModal: JSON.parse(JSON.stringify(Room_SearchQuery_DefaultModal)),
        });
        // if (!toggle) {
        //     await Delay(500);
        //     this.setState({
        //         SearchRoomQueryModal: JSON.parse(JSON.stringify(SearchRoomQuery_DefaultModal)),
        //     });
        // }
    }
    SetSearchRoomQueryParam = (propertyName = '', value = null) => {
        let params = this.state.SearchRoomQueryModal;
        switch (propertyName) {
            case 'RoomCode': params[propertyName] = String(value).trim(); break;
            case 'RoomTitle': params[propertyName] = String(value).trim(); break;
            case 'RoomTypeId': params[propertyName] = Number(value); break;
            case 'GroupId': params[propertyName] = Number(value); break;
            case 'SubjectId': params[propertyName] = Number(value); break;
            case 'CategoryId': params[propertyName] = Number(value); break;         //2025.03.20
            case 'CreatorAuthorId': params[propertyName] = Number(value); break;    //2025.03.20
            default: break;
        }
        this.setState({
            SearchRoomQueryModal: params,
        });
    }
    //#endregion === Search Room by Params (new) 2025.01.16 ===//

    //#region === Search Room by Room Code === start ===//
    SearchRoomByRoomCode = async () => {

        this.setState({ SearchRoomByRoomCode_Processing: true, });

        let _room = null;
        let _roomList = this.state.List;
        let _findIndex = _roomList.findIndex(x => String(x.RoomCode) === this.state.SearchRoomByRoomCode_RoomCode);
        if (_findIndex > -1) {
            _room = _roomList[_findIndex];
            this.CheckOnSearchRoomResult(_room, _findIndex);
        }
        else {
            const roomCode = CheckStringEmpty(this.state.SearchRoomByRoomCode_RoomCode);
            const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);

            //2023.12.07 - revamped - search via Api - main.
            let errorMessage = '';
            let done = false;
            await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Quiz/Room/Get/'
                + organizerId + '/'
                + authorId + '/'
                + roomCode + '/0',
                // Api/LearningCentre/Quiz/Room/Get/{organizerId}/{authorId}/{roomCode}/{roomId}
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(res => res.json())
                .then(data => {
                    if (this.state.isDevMode)
                        console.log('SearchRoomByRoomCode (source)', JSON.stringify(data));
                    if (data.success)
                        _room = CapitalizeJsonKeys(data.data);
                    else {
                        errorMessage = CheckObjectStringEmpty(data, 'message');
                        if (this.state.isDevMode)
                            console.log('Error', 'api - room get (failed) (' + roomCode + ') \n' + JSON.stringify(data));
                    }
                    done = true;
                })
                .catch(error => {
                    done = true;
                    errorMessage = CheckStringEmpty(error.message);
                    if (this.state.isDevMode)
                        console.log('Error', 'api - room get (error) (' + roomCode + ') \n' + error.message);
                });
            await DelayUntil(() => done === true);

            if (CheckNullValue(_room) !== null)
                this.CheckOnSearchRoomResult(_room, null, errorMessage);
            else
                this.CheckOnSearchRoomResult(null, null, errorMessage);
        }
    }
    CheckOnSearchRoomResult = async (_room = null, _findIndex = -1, errorMessage = '') => {
        if (_room !== null) {

            //set room data.
            _room = await PopulateRoomData(_room, this.state.QuestionSet);
            if (this.state.isDevMode)
                console.log(JSON.stringify(_room));

            //update room list if not in list.
            let _roomList = this.state.List;
            if (_findIndex < 0) {
                _roomList.push(_room);
                this.setState({ List: _roomList, });
            }

            this.setState({
                RoomData: _room,
                Cached_RoomData: JSON.parse(JSON.stringify(_room)),
                EditRoom_QuestionSet: null,
                Cached_EditRoom_QuestionSet: null,
                RoomData_RoomCode_isValid: CheckObjectNullValue(_room, 'RoomCode') !== null,

                ShowSearchRoomByRoomCodeModal: false,
                SearchRoomByRoomCode_Processing: false,
            }, async () => {
                useAppService.getState().setModal();
                this.ToggleCreateEditRoomModal();
                await Delay(0);
                this.Recalculate_Duration_EditRoom();
                this.Recalculate_DateTime_EditRoom();

                if (_room.hasOwnProperty('QuestionSetUniqueId'))
                    this.LoadRoomQuestionSet_ViaApi();
            });
        }
        else {
            this.setState({
                ShowSearchRoomByRoomCodeModal: false,
            }, async () => {
                await Delay(200);
                this.setState({
                    SearchRoomByRoomCode_Processing: false,
                    SearchRoomByRoomCode_RoomCode: '',
                }, () => {
                    // useAppService.getState().setModal('', 'Room not found.' + (errorMessage === '' ? '' : '<br /><br />Error:<br />' + errorMessage));
                    useAppService.getState().setModal('', 'Room not found.');
                });
            });
        }
    }
    //#endregion === Search Room by Room Code === end ===//

    //#region === Search Question Set === start ===//
    Toggle_Search_QuestionSetModal = (resetCache = false) => {
        this.setState({
            ShowSearchQuestionSetModal: !this.state.ShowSearchQuestionSetModal,
            QuestionSet_selected: null,
        }, async () => {
            if (this.state.ShowSearchQuestionSetModal) {
                const { group, subject } = await this.GetCachedSearchByOptions(resetCache);     //do not remove.
                this.setState({
                    SearchQsSet_ByGroup: CheckNullValue(group),
                    SearchQsSet_BySubject: CheckNullValue(subject),
                    SearchQsSet_MaxQtyShow: 5,
                    SearchQsSet_QuestionSet_Selected: null,
                    IsSearchQsSetSelected: false,
                    QuestionSetList: [],
                    QuestionSet_selected: null,
                });   //reset
            }
        });
    }
    // GetGroupOptions = () => {
    //     let options = useAppService.getState().groupOptions;
    //     if (Array.isArray(options))
    //         options.unshift({ id: 0, label: 'Select Group', value: '0' });
    //     return options;
    // }
    // GetSubjectOptions = () => {
    //     let options = this.state.SubjectOptions;
    //     if (Array.isArray(options))
    //         options.unshift({ id: 0, label: 'Select Subject', value: '0' });
    //     return options;
    // }
    GetCachedSearchByOptions = async (resetCache = false) => {
        const groupOptions = useAppService.getState().groupOptions;
        // const subjectOptions = useAppService.getState().subjectOptions;
        const subjectOptions = this.state.SubjectOptions;

        let _group = groupOptions[1];
        let _subject = subjectOptions[0];
        // let _group = null;
        // let _subject = null;

        if (!resetCache) {
            const roomData = this.state.RoomData;
            const { group } = GetGroupData(CheckObjectNullValue(roomData, 'Group'), CheckObjectNumber(roomData, 'GroupId'));
            if (group !== null)
                _group = group;
            const { subject } = GetSubjectData(CheckObjectNullValue(roomData, 'Subject'), CheckObjectNumber(roomData, 'SubjectId'));
            if (subject !== null)
                _subject = subject;
        }
        return { group: _group, subject: _subject };
    }
    SearchQuestionSetByConditions_ViaApi = async () => {

        this.setState({ SearchQsSet_Processing: true });

        //init.
        let _List = [];
        let totalRows = 0;

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);

        //2025.03.20
        const postData = JSON.stringify({
            OrganizerId: organizerId,
            AuthorId: authorId,
            GroupId: CheckObjectNumber(this.state.SearchQsSet_ByGroup, 'id'),
            SubjectId: CheckObjectNumber(this.state.SearchQsSet_BySubject, 'id'),
            Name: CheckStringEmpty(this.state.SearchQsSet_ByName),
            PageIndex: this.state.PageIndex,    //0
            PageSize: this.state.PageSize,      //this.state.SearchQsSet_MaxQtyShow
        });
        if (this.state.isDevMode)
            console.log('LoadList_ViaApi (postData) \n' + postData);

        await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Quiz/QuestionSet/List',
            // Api/LearningCentre/Quiz/QuestionSet/List
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: postData,
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('SearchQuestionSetByConditions_ViaApi (source)', JSON.stringify(data));
                if (data.success) {
                    _List = data.data.list;
                    totalRows = CheckObjectNumber(data.data, 'totalRows', _List.length);     //2023.11.24
                }
                else {
                    if (this.state.isDevMode)
                        console.log('Error', 'api - qSet list (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                if (this.state.isDevMode)
                    console.log('Error', 'api - qSet list (error)\n' + error.message);
            });

        if (_List.length > 0) {
            //Finalize list.
            _List = CapitalizeJsonKeys(_List);
            const groupOptions = useAppService.getState().groupOptions;
            // const subjectOptions = useAppService.getState().subjectOptions;
            const subjectOptions = this.state.SubjectOptions;
            _List = FormatList_QuestionSet(_List, groupOptions, subjectOptions);
            // for (let i = 0; i < _List.length; i++) {
            //     _List[i]['Selected'] = false;
            // }
            if (this.state.isDevMode)
                console.log('SearchQuestionSetByConditions_ViaApi (final)', JSON.stringify(_List));
        }
        this.setState({
            SearchQsSet_Processing: false,
            QuestionSetList: _List,
        }, () => {
            if (totalRows > 0) {
                this.Toggle_Search_QuestionSetModal();  //close search modal.
                this.Toggle_Select_QuestionSetModal();  //open select modal.
            }
            else {
                useAppService.getState().setModal('', 'No question set found.');
            }
        });
    }
    //#endregion === Search Question Set === end ===//

    //#region === Select Question Set === start ===//
    Toggle_Select_QuestionSetModal = async (resetCache = false) => {
        const { group, subject } = await this.GetCachedSearchByOptions(resetCache);
        this.setState({
            ShowSelectQuestionSetModal: !this.state.ShowSelectQuestionSetModal,
            SearchQsSet_ByGroup: CheckNullValue(group),
            SearchQsSet_BySubject: CheckNullValue(subject),
        });
    }
    GetQuestionSetsResultList = () => {
        let rows = [];
        if (this.state.QuestionSetList.length > 0) {
            this.state.QuestionSetList.map((data, key) => {
                return rows.push(<tr onClick={() => this.SelectThisQuestionSet(data)} key={'qSet_' + key} style={{ cursor: 'pointer' }}>
                    <td>
                        <input
                            type='radio'
                            name='QSet'
                            value={data.UniqueId}
                            checked={
                                this.state.SearchQsSet_QuestionSet_Selected === null ? false :
                                    this.state.SearchQsSet_QuestionSet_Selected.UniqueId === data.UniqueId
                            }
                            readOnly={true}
                        ></input>
                    </td>
                    <td>{key + 1}</td>
                    <td align='left'>{data.Name}</td>
                    <td align='left'>{data.Group.Name}</td>
                    <td align='left'>{data.Subject.Name}</td>
                    <td>{data.TotalQuestion}</td>
                    <td align='left'>{data.Remark}</td>
                    {/* <td>{moment.utc(data.CreatedOnUtc).format('YYYY-MM-DD hh:mm:ss A')}</td> */}
                    <td>{moment.utc(data.CreatedOnUtc).local().format('lll')}</td>
                </tr>);
            });
        }
        else {
            rows.push(<tr key={'qSet_0'}><td colSpan='7' align='center'>list is empty.</td></tr>);
        }
        return rows;
    }
    SelectThisQuestionSet = async (data) => {
        this.setState({ SearchQsSet_QuestionSet_Selected: data, IsSearchQsSetSelected: true });
    }
    SearchAgain_SelectQuestionSet = () => {
        this.Toggle_Select_QuestionSetModal();
        setTimeout(() => {
            this.Toggle_Search_QuestionSetModal();
        }, 300);
    }
    Confirm_SelectOnThisQuestionSet = () => {
        if (this.state.SearchQsSet_QuestionSet_Selected === null)
            return null;

        // if (this.state.Mode_Create_RoomDataModal === false) {
        let roomData = this.state.RoomData;
        roomData['QuestionSetUniqueId'] = CheckObjectStringEmpty(this.state.SearchQsSet_QuestionSet_Selected, 'UniqueId');
        roomData['QuestionSet'] = this.state.SearchQsSet_QuestionSet_Selected;
        this.setState({
            RoomData: roomData,
            EditRoom_QuestionSet: this.state.SearchQsSet_QuestionSet_Selected,
        });
        // }
        // else {
        //     //2021.09.28
        //     let _group = this.state.NewRoom_Group;
        //     if (this.state.NewRoom_Group === null) {
        //         let _qSet = this.state.SearchQsSet_QuestionSet_Selected;
        //         this.props.GroupOptions.map((data, key) => {
        //             if (_qSet.Group.Name === data.value && _qSet.Group.Id === data.id)
        //                 _group = data;
        //             return null;
        //         });
        //     }
        //     //2022.02.14
        //     let _subject = this.state.NewRoom_Subject;
        //     if (this.state.NewRoom_Subject === null) {
        //         let _qSet = this.state.SearchQsSet_QuestionSet_Selected;
        //         this.props.SubjectOptions.map((data, key) => {
        //             if (String(_qSet.Subject.Name) === String(data.value) && Number(_qSet.Subject.Id) === Number(data.id))
        //                 _subject = data;
        //             return null;
        //         });
        //     }
        //     this.setState({
        //         NewRoom_QuestionSet: this.state.SearchQsSet_QuestionSet_Selected,
        //         NewRoom_Group: _group,
        //         NewRoom_Subject: _subject,
        //         NewRoom_SubjectName: _subject === null ? '' : String(_subject.value),
        //     });
        // }

        this.Toggle_Select_QuestionSetModal(true);
    }
    //#endregion === Select Question Set === end ===//

    //#region === bulk edit ===
    //2025.03.25
    ToggleItemChecked = (index, selectAll = null) => {
        if (selectAll !== null) {
            this.setState({
                BulkEdit_CheckedItems: this.state.List.map((data, key) => { return !selectAll; }),
            });
        }
        else {
            if (index < 0)
                return null;
            let checkedItems = this.state.BulkEdit_CheckedItems;
            checkedItems[index] = !checkedItems[index];
            this.setState({
                BulkEdit_CheckedItems: checkedItems,
            });
        }
    }
    BulkEdit_ToggleEditSettingModal = () => {
        if (this.state.PA_Update === false) {
            useAppService.getState().setModal(`Bulk Edit ${settingTitle}(s)`, 'Invalid permission.');
            return null;
        }
        const toggle = !this.state.BulkEdit_Toggle_EditSettingModal;
        this.setState({
            BulkEdit_Toggle_EditSettingModal: toggle,
        });
        this.BulkEdit_ResetSetting();
    }
    BulkEdit_SettingModalComponent = () => {
        let components = [];
        const setting = this.state.BulkEdit_Setting;
        const setting_checked = this.state.BulkEdit_Setting_checked;
        // const setting_categoryItems = this.state.BulkEdit_Setting_CategoryItems;

        //Category List.
        const categoryOptions = CheckNullValue(this.state.CategoryOptions, []);
        if (Array.isArray(categoryOptions) && categoryOptions.length > 0) {
            const categoryList_setting_index = Object.keys(BulkSetting).indexOf(BulkSetting.CategoryList);
            const categoryList_setting_checked = setting_checked[categoryList_setting_index];
            components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (categoryList_setting_checked ? 'bg-lightskyblue' : '')}`}>
                <div className="form-group width-max" style={{ padding: '10px 15px 15px' }}>
                    <label>Category</label>
                    <table className={`table tb-no-border-no-margin ${categoryList_setting_checked ? 'table-hover' : ''}`} style={{ userSelect: 'none' }}><tbody>
                        {
                            categoryOptions.map((option, key) => {
                                // const findIndex_categoryItem = setting_categoryItems.findIndex(x => x.categoryId === option.id);
                                const isChecked = Array.isArray(setting[categoryList_setting_index].value)
                                    // && key < setting[categoryList_setting_index].value.length
                                    ? setting[categoryList_setting_index].value.findIndex(x => x.categoryId === option.id) > -1 : false;
                                return <>
                                    <tr
                                        onClick={() => {
                                            if (categoryList_setting_checked === false)
                                                DoNothing();
                                            else
                                                this.BulkEdit_SetSetting(BulkSetting.CategoryList, option);
                                        }}
                                        style={categoryList_setting_checked ? { cursor: 'pointer' } : {}}
                                    >
                                        <td width='42'>
                                            <input type='checkbox' className="form-check form-check-input" readOnly={true} style={{ margin: 0 }}
                                                checked={isChecked} disabled={categoryList_setting_checked === false}></input>
                                        </td>
                                        <td><span style={categoryList_setting_checked ? {} : { color: 'lightgray' }}>{option.label}</span></td>
                                    </tr>
                                    {
                                        // isChecked ?
                                        //     <tr className="no-hover"><td></td><td style={{ padding: 0 }}>
                                        //         {this.BulkEdit_CategoryItem_EffectiveDate_Components(option.id, findIndex_categoryItem < 0 ? null : setting_categoryItems[findIndex_categoryItem])}
                                        //     </td></tr>
                                        //     : null
                                    }
                                </>;
                            })
                        }
                    </tbody></table>
                </div>
                <div className="select-checkbox">
                    <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, categoryList_setting_index)}>
                        <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : categoryList_setting_checked} readOnly={true} />
                    </div>
                </div>
            </div>);
        }

        //Group.
        const group_setting_index = Object.keys(BulkSetting).indexOf(BulkSetting.Group);
        const group_setting_checked = setting_checked[group_setting_index];
        const groupOptions = useAppService.getState().groupOptions;
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (group_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="select-setting form-group width-max pd-7-10">
                <label>Group</label>
                <ReactSelect
                    // className="basic-multi-select"
                    classNamePrefix='select'
                    // isMulti
                    // closeMenuOnSelect={false}
                    options={groupOptions}
                    placeholder='Select Group...'
                    onChange={(option) => this.BulkEdit_SetSetting(BulkSetting.Group, option)}
                    theme={theme => ({
                        ...theme,
                        width: 'max-content',
                        colors: {
                            ...theme.colors,
                            neutral50: 'gray',  // placeholder color
                        }
                    })}
                    isDisabled={group_setting_checked === false}
                />
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, group_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : group_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        //Subject.
        const subject_setting_index = Object.keys(BulkSetting).indexOf(BulkSetting.Subject);
        const subject_setting_checked = setting_checked[subject_setting_index];
        const subjectOptions = useAppService.getState().subjectOptions;
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (subject_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="select-setting form-group width-max pd-7-10">
                <label>Subject</label>
                <ReactSelect
                    // className="basic-multi-select"
                    classNamePrefix='select'
                    // isMulti
                    // closeMenuOnSelect={false}
                    options={subjectOptions}
                    placeholder='Select Subject...'
                    onChange={(option) => this.BulkEdit_SetSetting(BulkSetting.Subject, option)}
                    theme={theme => ({
                        ...theme,
                        width: 'max-content',
                        colors: {
                            ...theme.colors,
                            neutral50: 'gray',  // placeholder color
                        }
                    })}
                    isDisabled={subject_setting_checked === false}
                />
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, subject_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : subject_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        //Date (DateStart & DateEnd).
        const date_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.Date);
        const date_setting_checked = setting_checked[date_setting_index];
        const date_setting_index_start = Object.keys(BulkSetting).indexOf(BulkSetting.DateStart);
        const date_setting_index_end = Object.keys(BulkSetting).indexOf(BulkSetting.DateEnd);
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (date_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="form-group width-max" style={{ padding: '10px 15px 15px' }}>
                <label>Date</label>
                <div style={{ alignSelf: 'start', display: 'flex', flexDirection: 'row', border: '1px solid gray', borderRadius: 5, padding: 5, backgroundColor: 'white', width: 'fit-content' }}>
                    <table style={{ textAlign: 'center' }} cellPadding={2}>
                        <thead>
                            <tr><th><span style={date_setting_checked ? {} : { color: 'lightgray' }}>From</span></th><th></th><th><span style={date_setting_checked ? {} : { color: 'lightgray' }}>To</span></th></tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div style={{ flex: 2 }}><input type="date" className="form-control width-max"
                                        onChange={(e) => this.BulkEdit_SetSetting(BulkSetting.DateStart, e.target.value)}
                                        value={date_setting_index_start < 0 ? '' : setting[date_setting_index_start].value}
                                        style={{ borderColor: 'gray', color: date_setting_checked ? 'black' : 'gray' }}
                                        disabled={!date_setting_checked}
                                    /></div>
                                </td>
                                <td>~</td>
                                <td>
                                    <div style={{ flex: 2 }}><input type="date" className="form-control width-max"
                                        onChange={(e) => this.BulkEdit_SetSetting(BulkSetting.DateEnd, e.target.value)}
                                        value={date_setting_index_end < 0 ? '' : setting[date_setting_index_end].value}
                                        style={{ borderColor: 'gray', color: date_setting_checked ? 'black' : 'gray' }}
                                        disabled={!date_setting_checked}
                                    /></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, date_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : date_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        //Time (TimeStart & TimeEnd).
        const time_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.Time);
        const time_setting_checked = setting_checked[time_setting_index];
        const time_setting_index_start = Object.keys(BulkSetting).indexOf(BulkSetting.TimeStart);
        const time_setting_index_end = Object.keys(BulkSetting).indexOf(BulkSetting.TimeEnd);
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (time_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="form-group width-max" style={{ padding: '10px 15px 15px' }}>
                <label>Time</label>
                <div style={{ alignSelf: 'start', display: 'flex', flexDirection: 'row', border: '1px solid gray', borderRadius: 5, padding: 5, backgroundColor: 'white', width: 'fit-content' }}>
                    <table style={{ textAlign: 'center' }} cellPadding={2}>
                        <thead>
                            <tr><th><span style={time_setting_checked ? {} : { color: 'lightgray' }}>From</span></th><th></th><th><span style={time_setting_checked ? {} : { color: 'lightgray' }}>To</span></th></tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div style={{ flex: 2 }}><input type="time" className="form-control width-max"
                                        onChange={(e) => this.BulkEdit_SetSetting(BulkSetting.TimeStart, e.target.value)}
                                        onBlur={(e) => this.BulkEdit_SetSetting(BulkSetting.TimeStart, e.target.value)}
                                        value={time_setting_index_start < 0 ? '' : setting[time_setting_index_start].value}
                                        style={{ borderColor: 'gray', color: time_setting_checked ? 'black' : 'gray' }}
                                        disabled={!time_setting_checked}
                                    /></div>
                                </td>
                                <td>~</td>
                                <td>
                                    <div style={{ flex: 2 }}><input type="time" className="form-control width-max"
                                        onChange={(e) => this.BulkEdit_SetSetting(BulkSetting.TimeEnd, e.target.value)}
                                        onBlur={(e) => this.BulkEdit_SetSetting(BulkSetting.TimeEnd, e.target.value)}
                                        value={time_setting_index_end < 0 ? '' : setting[time_setting_index_end].value}
                                        style={{ borderColor: 'gray', color: time_setting_checked ? 'black' : 'gray' }}
                                        disabled={!time_setting_checked}
                                    /></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, time_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : time_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        //Duration.
        const duration_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.Duration);
        const duration_setting_checked = setting_checked[duration_setting_index];
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (duration_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="form-group width-max">
                <label style={{ marginLeft: 8, marginBottom: 2 }}>Duration</label>
                <div style={{ display: 'flex', gap: 20, padding: 5, marginLeft: 3, width: '90%' }}>
                    <div style={{ padding: '7px 15px 0px 15px', border: '1px solid green', borderRadius: 5, width: 'fit-content', textAlign: 'center', backgroundColor: duration_setting_checked ? 'white' : '#efefef' }}
                    >{GetDurationText(duration_setting_index < 0 ? 0 : CheckNumber(setting[duration_setting_index].value), true) + ' (max: 6 hours)'}</div>
                    <div style={{
                        display: 'flex', gap: 15, alignItems: 'center', border: '1px solid black', borderRadius: 5, padding: 5, paddingRight: 10, userSelect: 'none',
                        backgroundColor: duration_setting_checked ? 'white' : '#efefef', color: duration_setting_checked ? 'black' : 'gray',
                    }}>
                        <div>
                            <input type='number' min={0} max={6} defaultValue={0} ref={this.BE_Duration_Hour} style={{ width: 50 }}
                                disabled={!duration_setting_checked}
                                onChange={() => this.BulkEdit_Recalculate_Duration()}></input> hour
                        </div>
                        <div>
                            <input type='number' min={0} max={59} defaultValue={0} ref={this.BE_Duration_Min} style={{ width: 50 }}
                                disabled={!duration_setting_checked}
                                onChange={() => this.BulkEdit_Recalculate_Duration()}></input> min
                        </div>
                        <div>
                            <input type='number' min={0} max={59} defaultValue={0} ref={this.BE_Duration_Sec} style={{ width: 50 }}
                                disabled={!duration_setting_checked}
                                onChange={() => this.BulkEdit_Recalculate_Duration()}></input> sec
                        </div>
                    </div>
                </div>
                {/* <table cellPadding='10' cellSpacing='0' border='0' width='100%' style={{ borderTop: 0, marginBottom: 10, borderStyle: 'hidden' }}>
                    <tbody>
                        <tr>
                            <td>
                                <div style={{ padding: 5, paddingLeft: 15, paddingRight: 15, border: '1px solid green', borderRadius: 5, width: 'fit-content', textAlign: 'center', backgroundColor: 'white' }}
                                >{GetDurationText(duration_setting_index < 0 ? 0 : CheckNumber(setting[duration_setting_index].value), true) + ' (max: 6 hours)'}</div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ padding: 0 }}>
                                <table cellPadding='0' cellSpacing='0' border='0' width='60%' style={{ marginLeft: -5, marginBottom: 0, borderStyle: 'hidden' }}>
                                    <tbody>
                                        <tr>
                                            <td align='center'>
                                                <input type='number' min={0} max={6} defaultValue={0} ref={this.BE_Duration_Hour}
                                                    disabled={!duration_setting_checked}
                                                    onChange={() => this.BulkEdit_Recalculate_Duration()}></input> hour
                                            </td>
                                            <td align='center'>
                                                <input type='number' min={0} max={59} defaultValue={0} ref={this.BE_Duration_Min}
                                                    disabled={!duration_setting_checked}
                                                    onChange={() => this.BulkEdit_Recalculate_Duration()}></input> min
                                            </td>
                                            <td align='center'>
                                                <input type='number' min={0} max={59} defaultValue={0} ref={this.BE_Duration_Sec}
                                                    disabled={!duration_setting_checked}
                                                    onChange={() => this.BulkEdit_Recalculate_Duration()}></input> sec
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table> */}
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={async () => {
                    this.BulkEdit_SetSetting(SettingInput.CheckedItem, duration_setting_index);
                    await Delay(0);
                    this.BulkEdit_Recalculate_Duration();
                }}><input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : duration_setting_checked} readOnly={true} /></div>
            </div>
        </div>);

        //RandomQuestionMode.
        const rqm_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.RandomQuestionMode);
        const rqm_setting_checked = setting_checked[rqm_setting_index];
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (rqm_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="form-group width-max">
                <label>Random Question Mode</label>
                <input type="checkbox" className="form-check form-check-input"
                    onClick={(e) => this.BulkEdit_SetSetting(SettingInput.RandomQuestionMode, e.currentTarget.checked)}
                    checked={CheckBoolean(setting[rqm_setting_index].value)}
                    readOnly={true}
                    disabled={rqm_setting_checked === false}
                ></input>
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, rqm_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : rqm_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        return (components);
    }
    BulkEdit_SetSetting = (property = SettingInput.None, value = null) => {
        let setting_categoryItems = this.state.BulkEdit_Setting_CategoryItems;
        let setting = this.state.BulkEdit_Setting;
        let setting_checked = [...this.state.BulkEdit_Setting_checked];
        const setting_index = property === SettingInput.CheckedItem ? 999 : Object.values(SettingInput).indexOf(property);
        if (this.state.isDevMode)
            console.log(`BulkEdit_SetSetting (setting_index = ${setting_index}) | (property = ${property}) | (value = ${JSON.stringify(value)})`);
        if (property === SettingInput.None || setting === null || value === null || setting_index < 0)
            return null;

        switch (property) {
            case SettingInput.CategoryList:
                if (Array.isArray(setting[setting_index].value) === false)
                    setting[setting_index].value = [];
                const findIndex_category = setting[setting_index].value.findIndex(x => x.categoryId === value.id);
                if (findIndex_category < 0) {
                    //add.
                    setting[setting_index].value.push({ categoryId: value.id, name: value.label, effectiveDateStart: '', effectiveDateEnd: '' });
                }
                else {
                    //remove.
                    setting[setting_index].value.splice(findIndex_category, 1);
                    const findIndex_categoryItem = setting_categoryItems.findIndex(x => x.categoryId === value.id);
                    if (findIndex_categoryItem > -1)
                        setting_categoryItems.splice(findIndex_categoryItem, 1);
                }
                break;
            case SettingInput.Group: setting[setting_index].value = value; break;
            case SettingInput.Subject: setting[setting_index].value = value; break;
            case SettingInput.Date: setting[setting_index].value = CheckBoolean(value); break;
            case SettingInput.DateStart: setting[setting_index].value = CheckStringEmpty(value); break;
            case SettingInput.DateEnd: setting[setting_index].value = CheckStringEmpty(value); break;
            case SettingInput.Time: setting[setting_index].value = CheckBoolean(value); break;
            case SettingInput.TimeStart: setting[setting_index].value = CheckStringEmpty(value); break;
            case SettingInput.TimeEnd: setting[setting_index].value = CheckStringEmpty(value); break;
            case SettingInput.Duration: setting[setting_index].value = CheckNumber(value); break;
            case SettingInput.RandomQuestionMode: setting[setting_index].value = CheckBoolean(value); break;
            case SettingInput.CheckedItem:
                const checked = !setting_checked[value];
                setting_checked[value] = checked;
                if (value === Object.keys(BulkSetting).indexOf(BulkSetting.Date)) {
                    setting_checked[Object.keys(BulkSetting).indexOf(BulkSetting.DateStart)] = checked;
                    setting_checked[Object.keys(BulkSetting).indexOf(BulkSetting.DateEnd)] = checked;
                }
                else if (value === Object.keys(BulkSetting).indexOf(BulkSetting.Time)) {
                    setting_checked[Object.keys(BulkSetting).indexOf(BulkSetting.TimeStart)] = checked;
                    setting_checked[Object.keys(BulkSetting).indexOf(BulkSetting.TimeEnd)] = checked;
                }
                else if (value === Object.keys(BulkSetting).indexOf(BulkSetting.Duration)) {
                    setting[Object.values(BulkSetting).indexOf(BulkSetting.Duration)].value = 3600;
                }
                if (this.state.isDevMode)
                    console.log(`BulkEdit_SetSetting (checkedItem) (${Object.keys(BulkSetting)[value]}) (${value}) = ` + JSON.stringify(setting_checked));
                break;
            default: break;
        }
        this.setState({
            BulkEdit_Setting: setting,
            BulkEdit_Setting_checked: setting_checked,
        }, () => {
            if (this.state.isDevMode && property !== SettingInput.CheckedItem) {
                // console.log(`BulkEdit_SetSetting (${property}) = ` + JSON.stringify(value));
                console.log(`BulkEdit_SetSetting (setting) = ` + JSON.stringify(setting));
            }
        });
    }
    BulkEdit_ResetSetting = () => {
        this.setState({
            BulkEdit_Setting: [
                { key: BulkSetting.CategoryList, value: [] },
                { key: BulkSetting.Group, value: null },
                { key: BulkSetting.Subject, value: null },
                { key: BulkSetting.Date, value: null },
                { key: BulkSetting.DateStart, value: null },
                { key: BulkSetting.DateEnd, value: null },
                { key: BulkSetting.Time, value: null },
                { key: BulkSetting.TimeStart, value: null },
                { key: BulkSetting.TimeEnd, value: null },
                { key: BulkSetting.Duration, value: null },
                { key: BulkSetting.RandomQuestionMode, value: null },
            ],
            BulkEdit_Setting_checked: Object.keys(BulkSetting).map(() => { return false; }),
            BulkEdit_Setting_CategoryItems: [],
        });
    }
    BulkEdit_Recalculate_Duration = () => {
        const setting = this.state.BulkEdit_Setting;
        const duration_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.Duration);

        let _hr = Number(this.BE_Duration_Hour.current.value);
        let _min = Number(this.BE_Duration_Min.current.value);
        let _sec = Number(this.BE_Duration_Sec.current.value);
        let _duration = duration_setting_index < 0 ? 0 : CheckNumber(setting[duration_setting_index].value);

        if (_hr === 0 && _min === 0 && _sec === 0 && _duration > 0) {
            //Initial.
            _hr = Number((_duration / 3600).toFixed(3).split('.')[0]);
            _min = Number(((_duration - (_hr * 3600)) / 60).toFixed(3).split('.')[0]);
            _sec = _duration - (_hr * 3600) - (_min * 60);
            this.BE_Duration_Hour.current.value = _hr;
            this.BE_Duration_Min.current.value = _min;
            this.BE_Duration_Sec.current.value = _sec;
            // console.log('hr = ' + _hr + '\nmin = ' + _min + '\nsec = ' + _sec);
        }
        else {
            //Edit Duration.
            if (_hr >= 6) {
                _hr = 6;
                _min = 0;
                _sec = 0;
                this.BE_Duration_Hour.current.value = _hr;
                this.BE_Duration_Min.current.value = _min;
                this.BE_Duration_Sec.current.value = _sec;
            }
            if (_min > 59) {
                _min = 59;
                this.BE_Duration_Min.current.value = _min;
            }
            if (_sec > 59) {
                _sec = 59;
                this.BE_Duration_Sec.current.value = _sec;
            }
            _duration = (_hr * 3600) + (_min * 60) + _sec;
            // setting[duration_setting_index].value = _duration;
            // this.setState({
            //     BulkEdit_Setting: setting,
            // });
            this.BulkEdit_SetSetting(BulkSetting.Duration, _duration);
        }
    }
    BulkEdit_CategoryItem_EffectiveDate_Components = (categoryId = 0, categoryItem = null) => {
        const effectiveDateStart = CheckObjectStringEmpty(categoryItem, 'effectiveDateStart');
        const effectiveDateEnd = CheckObjectStringEmpty(categoryItem, 'effectiveDateEnd');
        return (<div style={{ alignSelf: 'start', display: 'flex', flexDirection: 'row', border: '1px solid gray', borderRadius: 5, padding: 5, marginLeft: 6, backgroundColor: 'white', width: 'fit-content' }}>
            <table style={{ textAlign: 'center' }} cellPadding={2}>
                <thead>
                    <tr><th>Effective Start Date/Time</th><th></th><th>Effective To Date/Time</th></tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div style={{ flex: 2 }}><input type="datetime-local" className="form-control width-max"
                                onChange={(e) => this.BulkEdit_CategoryItem_SetEffectiveDate(categoryId, 'effectiveDateStart', e.target.value)}
                                value={moment.utc(effectiveDateStart, 'YYYY-MM-DDTHH:mm').local().format('YYYY-MM-DDTHH:mm')}
                                style={{ borderColor: 'gray' }}
                            /></div>
                        </td>
                        <td>~</td>
                        <td>
                            <div style={{ flex: 2 }}><input type="datetime-local" className="form-control width-max"
                                onChange={(e) => this.BulkEdit_CategoryItem_SetEffectiveDate(categoryId, 'effectiveDateEnd', e.target.value)}
                                value={moment.utc(effectiveDateEnd, 'YYYY-MM-DDTHH:mm').local().format('YYYY-MM-DDTHH:mm')}
                                style={{ borderColor: 'gray' }}
                            /></div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={{ display: 'flex', width: 60, fontSize: 12, alignItems: 'center' }}>
                <button type="button" className="btn-link"
                    onClick={async () => {
                        this.BulkEdit_CategoryItem_SetEffectiveDate(categoryId, 'effectiveDateStart', yearStart);
                        await Delay(0);
                        this.BulkEdit_CategoryItem_SetEffectiveDate(categoryId, 'effectiveDateEnd', yearEnd);
                        await Delay(0);
                    }}
                >apply default date</button>
            </div>
        </div>);
    }
    BulkEdit_CategoryItem_SetEffectiveDate = (categoryId = 0, propertyName = '', value = null) => {
        let setting_categoryItems = JSON.parse(JSON.stringify(this.state.BulkEdit_Setting_CategoryItems));
        let findIndex_categoryItem = setting_categoryItems.findIndex(x => x.categoryId === categoryId);
        if (findIndex_categoryItem < 0) {
            //add.
            setting_categoryItems.push({ categoryId: categoryId, effectiveDateStart: '', effectiveDateEnd: '' });
            findIndex_categoryItem = setting_categoryItems.length - 1;
            setting_categoryItems[findIndex_categoryItem][propertyName] = moment(value, 'YYYY-MM-DDTHH:mm').utc().format('YYYY-MM-DDTHH:mm');
        }
        else {
            //update.
            let updatedItem = JSON.parse(JSON.stringify(setting_categoryItems[findIndex_categoryItem]));
            updatedItem[propertyName] = moment(value, 'YYYY-MM-DDTHH:mm').utc().format('YYYY-MM-DDTHH:mm');
            setting_categoryItems[findIndex_categoryItem] = updatedItem;
        }
        if (this.state.isDevMode)
            console.log(`BulkEdit_CategoryItem_SetEffectiveDate (${categoryId}) (${propertyName}) (${value}) \n ${JSON.stringify(setting_categoryItems)}`);
        this.setState({
            BulkEdit_Setting_CategoryItems: setting_categoryItems,
        });
    }
    BulkEdit_ToggleRemoveSettingModal = () => {
        this.setState({
            BulkEdit_Toggle_RemoveSettingModal: !this.state.BulkEdit_Toggle_RemoveSettingModal,
        });
    }
    BulkEdit_CUD_Setting_ViaApi = async (remove = false) => {

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const { textTitle, textBody, text, urlParam } = GetPostParams({ id: 999 }, remove);
        this.setState({
            BulkEdit_IsUpdating: true,
        });
        useAppService.getState().setModal('', `${textTitle} setting...`, null, AlertMode.Loading);

        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Organizer/Room/BulkEdit/${urlParam}`;
        // Api/LearningCentre/Organizer/Room/BulkEdit/{Update|Remove}

        let setting_params = [];
        const setting_keys = Object.values(BulkSetting);
        if (remove === false) {
            //Others.
            for (let i = 0; i < this.state.BulkEdit_Setting_checked.length; i++) {
                if (this.state.BulkEdit_Setting_checked[i])
                    setting_params.push({ key: setting_keys[i], value: this.state.BulkEdit_Setting[i].value });
                else
                    setting_params.push({ key: setting_keys[i], value: null });
            }
            //Category List.
            if (this.state.BulkEdit_Setting_checked[setting_keys.indexOf(BulkSetting.CategoryList)]) {
                const setting_categoryItems = this.state.BulkEdit_Setting_CategoryItems;
                if (Array.isArray(setting_categoryItems) && Array.isArray(setting_params[setting_keys.indexOf(BulkSetting.CategoryList)].value)) {
                    setting_categoryItems.forEach((item, key) => {
                        const findIndex = setting_params[setting_keys.indexOf(BulkSetting.CategoryList)].value.findIndex(x => x.categoryId === item.categoryId);
                        if (findIndex > -1) {
                            setting_params[setting_keys.indexOf(BulkSetting.CategoryList)].value[findIndex]['effectiveDateStart'] = item['effectiveDateStart'];
                            setting_params[setting_keys.indexOf(BulkSetting.CategoryList)].value[findIndex]['effectiveDateEnd'] = item['effectiveDateEnd'];
                        }
                    });
                }
            }
        }

        let itemIds = [];
        const list = this.state.List;
        const checkedItems = this.state.BulkEdit_CheckedItems;
        for (let n = 0; n < list.length; n++) {
            if (checkedItems[n])
                itemIds.push(CheckObjectNumber(list[n], SettingInput.Id));
        }

        const json = JSON.stringify({
            OrganizerId: organizerId,
            AuthorId: authorId,
            BulkItemIds: itemIds.join(','),

            Group: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.Group)].value,
            Subject: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.Subject)].value,
            DateStart: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.DateStart)].value,
            DateEnd: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.DateEnd)].value,
            TimeStart: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.TimeStart)].value,
            TimeEnd: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.TimeEnd)].value,
            Duration: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.Duration)].value,
            RandomQuestionMode: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.RandomQuestionMode)].value,

            CategoryList: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.CategoryList)].value,
            UpdateCategoryList: remove ? false : this.state.BulkEdit_Setting_checked[setting_keys.indexOf(BulkSetting.CategoryList)],

            Remove: remove,
        });
        if (this.state.isDevMode)
            console.log(`BulkEdit_CUD_Setting_ViaApi (${text}) (postData) =\n` + json);

        // //debug use.
        // this.setState({ BulkEdit_IsUpdating: false });
        // useAppService.getState().setModal('Bulk Edit', 'feature coming soon...');
        // return null;

        // let data = null;
        let success = false;
        let msg = '';
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: json,
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('BulkEdit_CUD_Setting_ViaApi =\n' + JSON.stringify(data));

                success = CheckObjectBoolean(data, 'success');
                // data = CheckObjectNullValue(data, 'data');

                if (!success)
                    msg = CheckObjectStringEmpty(data, 'message');
            })
            .catch(error => {
                msg = error.message;
                if (this.state.isDevMode)
                    console.log('Error', `api - ${text} (error)\n` + error.message);
            });

        if (success) {
            this.LoadList_ViaApi();
            this.BulkEdit_ToggleEditSettingModal();
            await Delay(500);
            useAppService.getState().setModal();
            await Delay(500);
            useAppService.getState().setModal('', `${settingTitle}(s) have been ${textBody}.`);
        }
        else {
            useAppService.getState().setModal('', `Failed to ${text} ${settingTitle.toLowerCase()}(s).<br /><br />` + msg);
        }
        this.setState({
            BulkEdit_IsUpdating: false,
        });
    }
    //#endregion === bulk edit ===

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        return (<div className="">
            <table className="table page-header">
                <tbody>
                    <tr>
                        <td className="left">
                            <h5>Room</h5>
                            {
                                this.state.PA_Update === false ? null :
                                    <Button variant="primary"
                                        onClick={() => this.BulkEdit_ToggleEditSettingModal()}
                                        disabled={this.state.isLoading || this.state.BulkEdit_CheckedItems.length === 0 ? true : (this.state.BulkEdit_CheckedItems.includes(true) ? false : true)}
                                    >Bulk Edit</Button>
                            }
                            {
                                this.state.PA_View === false ? null :
                                    <>
                                        {/* <Button
                                            variant='outline-primary'
                                            onClick={() => { }}
                                            hidden={true}
                                        >Export Room List</Button> */}
                                        <button
                                            type="button"
                                            className="btn-link"
                                            onClick={() => this.LoadList_ViaApi(true)}
                                            title="Refresh Room list"
                                        ><i className="fa fa-refresh" title="Refresh Room list"></i></button>
                                    </>
                            }
                        </td>
                        <td className="center"></td>
                        <td className="right">
                            <Button
                                variant='outline-primary'
                                // onClick={() => this.setState({ ShowSearchRoomByRoomCodeModal: true, SearchRoomByRoomCode_Processing: false, SearchRoomByRoomCode_RoomCode: '' })}
                                onClick={() => this.ToggleSearchRoomQueryModal()}
                                disabled={this.state.isLoading}
                            >Search Room</Button>
                            {
                                this.state.PA_Create === false ? null :
                                    <Button
                                        variant='outline-primary'
                                        onClick={() => this.ToggleCreateRoomModal()}
                                        hidden={this.props.roomTypeFile === true}
                                        disabled={this.state.isLoading}
                                    >New Room</Button>
                                // <button type="button" className="btn btn-outline-primary"
                                //     onClick={this.ToggleCreateRoomModal}
                                //     hidden={this.props.roomTypeFile === true}
                                // >New Room</button>
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='table table-hover table-bordered tbStyle' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
                <thead>
                    <tr>
                        <th width='50' className="pointer" onClick={() => this.state.isLoading || this.state.List.length === 0 ? DoNothing() : this.ToggleItemChecked(-1, this.state.BulkEdit_CheckedItems.findIndex(x => x === false) < 0)}>
                            <input type='checkbox' className={this.state.isLoading || this.state.List.length === 0 ? '' : 'pointer'}
                                checked={this.state.isLoading || this.state.List.length === 0 ? false : !(this.state.BulkEdit_CheckedItems.findIndex(x => x === false) > -1)}
                                readOnly={true} disabled={this.state.isLoading || this.state.List.length === 0}></input>
                        </th>
                        {/* <th width='50'>#</th> */}
                        <th width='100'>Room&nbsp;Code</th>
                        <th className="left">Room&nbsp;Title</th>
                        <th hidden={this.state.hasTotalFileQty === false} width='85'>Total&nbsp;Files</th>
                        <th width='245'>Category</th>
                        <th width='120'>Group</th>
                        <th width='120'>Subject</th>
                        <th width='90'>Time&nbsp;Start</th>
                        <th width='90'>Time&nbsp;End&nbsp;</th>
                        <th width='110'>Date&nbsp;/&nbsp;Period</th>
                        <th width='175' hidden={!this.state.IsAdmin}>Created By</th>
                        <th width='95'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.isLoading && !this.state.IsListLoaded ?
                            <tr><td colSpan='15' height={63}><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></td></tr>
                            : this.state.List.length > 0 ?
                                this.ListComponents()
                                : <tr><td colSpan='15' align='center'>list is empty</td></tr>
                    }
                    {
                        this.state.List.length === 0 ? null :
                            PagingComponents(15, this.state.TotalRows, this.state.PageIndex, this.state.PageSize, this.CallbackFunctionForPagingComponents_PageSize, this.CallbackFunctionForPagingComponents_PageIndex)
                    }
                </tbody>
            </table>

            {/* Room - Delete - Modal */}
            <Modal show={this.state.ShowDeleteRoomModal} onHide={() => this.ToggleDeleteRoomModal()} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Remove Room</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        Room Code: <b>{CheckObjectStringEmpty(this.state.RoomData, 'RoomCode')}</b>
                        <br />Do you sure you want to <b>remove</b> this room ?
                        <br /><b><i>The removal is not reversible.</i></b>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ToggleDeleteRoomModal()}>Cancel</Button>
                    <Button variant="primary" onClick={() => this.DeleteThisRoom_ViaApi()}>Confirm</Button>
                </Modal.Footer>
            </Modal>

            {/* Room - Edit Selected - Modal */}
            <Modal size='lg' show={this.state.ShowCreateEditRoomModal} onHide={() => this.ToggleCreateEditRoomModal()}>
                {
                    this.state.RoomData === null ?
                        <Modal.Body>empty room data.</Modal.Body>
                        :
                        <>
                            <Modal.Header closeButton>
                                <Modal.Title>{(this.state.Mode_Create_RoomDataModal ? 'New' : 'Edit') + ' Room ' + (CheckObjectNumber(this.state.RoomData, 'RoomCode') === 0 ? '' : '<' + CheckObjectNumber(this.state.RoomData, 'RoomCode') + '>')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ paddingTop: 0 }}>
                                <div style={{ width: '100%' }}>
                                    <table className='table tbStyle1' cellPadding='10' cellSpacing='10' border='0' style={{ marginBottom: 0, borderStyle: 'hidden' }}>
                                        <tbody>
                                            <tr>
                                                <td width='155'>Room Code</td>
                                                <td style={{ gap: 15, }}>
                                                    {
                                                        this.state.RoomData_RoomCode_isValid ?
                                                            this.state.Mode_Create_RoomDataModal ?
                                                                <>
                                                                    <b>{CheckObjectStringEmpty(this.state.RoomData, 'RoomCode')}</b>
                                                                    &nbsp;&nbsp;
                                                                    <button type="button" className="btn btn-outline btn1" title='Generate Room Code'
                                                                        onClick={() => this.GenerateRandomRoomCode()}
                                                                    ><i className="fa fa-refresh icon1"></i></button>
                                                                </>
                                                                :
                                                                // <b>{CheckObjectStringEmpty(this.state.RoomData, 'RoomCode')}</b>
                                                                <>
                                                                    <b>{CheckObjectStringEmpty(this.state.RoomData, 'RoomCode')}</b>
                                                                    <Button
                                                                        variant='primary'
                                                                        // onClick={() => this.LoadResultFromSelectedRoom(data.RoomCode, data.RoomId)}
                                                                        onClick={() => this.Ref_RoomResultComponent.current.LoadResultFromSelectedRoom(
                                                                            CheckObjectStringEmpty(this.state.RoomData, 'RoomCode'),
                                                                            CheckObjectStringEmpty(this.state.RoomData, 'RoomId'),
                                                                            null, null, null, LayoutScreen.ManageRoom, null
                                                                        )}
                                                                        style={{ marginLeft: 10, }}
                                                                    >View Result</Button>
                                                                    <Button
                                                                        variant='primary'
                                                                        onClick={async () => {
                                                                            //force to sync room result again.
                                                                            this.Ref_RoomResultComponent.current.LoadResultFromSelectedRoom(
                                                                                CheckObjectStringEmpty(this.state.RoomData, 'RoomCode'),
                                                                                CheckObjectStringEmpty(this.state.RoomData, 'RoomId'),
                                                                                true, null, null, LayoutScreen.ManageRoom, null
                                                                            );
                                                                            await Delay(500);
                                                                            //close modal ui.
                                                                            this.ToggleCreateEditRoomModal();
                                                                        }}
                                                                        style={{ marginLeft: 13, }}
                                                                    >Refresh (Last Sync: {CheckObjectStringEmpty(this.state.RoomData, 'LastSyncDone', '-')})</Button>
                                                                </>
                                                            : <ProgressBar animated now={100} className='progressbar1' />
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign='middle'>Room Title *</td>
                                                <td>
                                                    <input type="text" className="form-control" style={{ width: '100%' }}
                                                        onChange={(e) => { this.SaveDataInput(e.target.value, RoomDataInput.RoomTitle); }}
                                                        defaultValue={CheckObjectStringEmpty(this.state.RoomData, 'RoomTitle')}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign='middle'>Category</td>
                                                <td>
                                                    <ReactSelect
                                                        // className="basic-multi-select"
                                                        // classNamePrefix='select'
                                                        // isMulti
                                                        // closeMenuOnSelect={false}
                                                        isClearable={true}
                                                        options={this.state.CategoryOptions}
                                                        placeholder='Select Category...'
                                                        theme={theme => ({
                                                            ...theme,
                                                            width: 'max-content',
                                                            colors: {
                                                                ...theme.colors,
                                                                neutral50: 'gray',  // placeholder color
                                                            }
                                                        })}
                                                        onChange={(option) => {
                                                            if (this.state.isDevMode)
                                                                console.log(JSON.stringify(option));
                                                            this.SaveDataInput(option, RoomDataInput.CategoryList);
                                                        }}
                                                        // value={this.GetSelectedCategoryItems(this.state.RoomData)}
                                                        // value={GetSelectedCategoryListItems(this.state.RoomData, this.state.CategoryOptions)}
                                                        value={this.GetSelectedCategoryItem(this.state.RoomData)}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign='middle'>Group *</td>
                                                <td>
                                                    <ReactSelect
                                                        options={useAppService.getState().groupOptions}
                                                        // placeholder={this.GetPlaceholder_Group()}
                                                        placeholder='Select Group...'
                                                        theme={theme => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                neutral50: 'gray',  // placeholder color
                                                            },
                                                        })}
                                                        onChange={(option) => {
                                                            if (this.state.isDevMode)
                                                                console.log(JSON.stringify(option));
                                                            this.SaveDataInput(option, RoomDataInput.Group);
                                                        }}
                                                        value={this.GetSelectedGroupItem(this.state.RoomData)}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign='middle'>Subject *</td>
                                                <td>
                                                    <ReactSelect
                                                        // options={useAppService.getState().subjectOptions}
                                                        options={this.state.SubjectOptions}
                                                        // placeholder={this.GetPlaceholder_Subject()}
                                                        placeholder='Select Subject...'
                                                        theme={theme => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                neutral50: 'gray',  // placeholder color
                                                            },
                                                        })}
                                                        onChange={(option) => {
                                                            if (this.state.isDevMode)
                                                                console.log(JSON.stringify(option));
                                                            this.SaveDataInput(option, RoomDataInput.Subject);
                                                        }}
                                                        value={this.GetSelectedSubjectItem(this.state.RoomData)}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Date *</td>
                                                <td>
                                                    From <input type='date'
                                                        ref={this.EDR_FromDate}
                                                        className="pointer"
                                                        onChange={(e) => {
                                                            this.SaveDataInput(e.currentTarget.value, RoomDataInput.DateStart);
                                                        }}
                                                        defaultValue={CheckObjectStringEmpty(this.state.RoomData, 'DateStart')}
                                                        style={{ width: 145 }}
                                                    ></input>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    To <input type='date'
                                                        ref={this.EDR_ToDate}
                                                        className="pointer"
                                                        onChange={(e) => {
                                                            this.SaveDataInput(e.currentTarget.value, RoomDataInput.DateEnd);
                                                        }}
                                                        defaultValue={CheckObjectStringEmpty(this.state.RoomData, 'DateEnd')}
                                                        style={{ width: 145 }}
                                                    ></input>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Time *</td>
                                                <td>
                                                    From <input type='time'
                                                        ref={this.EDR_FromTime}
                                                        className="pointer"
                                                        onChange={(e) => {
                                                            this.SaveDataInput(e.currentTarget.value, RoomDataInput.TimeStart);
                                                        }}
                                                        defaultValue={CheckObjectStringEmpty(this.state.RoomData, 'TimeStart', '00:00:00')}
                                                        style={{ width: 145 }}
                                                    ></input>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    To <input type='time'
                                                        ref={this.EDR_ToTime}
                                                        className="pointer"
                                                        onChange={(e) => {
                                                            this.SaveDataInput(e.currentTarget.value, RoomDataInput.TimeEnd);
                                                        }}
                                                        defaultValue={CheckObjectStringEmpty(this.state.RoomData, 'TimeEnd', '23:59:00')}
                                                        style={{ width: 145 }}
                                                    ></input>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Duration *</td>
                                                <td style={{ padding: 0 }}>
                                                    <table cellPadding='10' cellSpacing='0' border='0' width='100%' style={{ borderTop: 0, marginBottom: 10, borderStyle: 'hidden' }}>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <div style={{ padding: 5, paddingLeft: 15, paddingRight: 15, border: '1px solid green', borderRadius: 5, width: 'fit-content', textAlign: 'center' }}
                                                                    >{GetDurationText(this.state.RoomData === null ? 0 : Number(this.state.RoomData.Duration), true) + ' (max: 6 hours)'}</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan='2' style={{ padding: 0 }}>
                                                                    <table cellPadding='0' cellSpacing='0' border='0' width='60%' style={{ marginBottom: 0, borderStyle: 'hidden' }}>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align='center'>
                                                                                    <input type='number' min={0} max={6} defaultValue={0} ref={this.EDR_Duration_Hour}
                                                                                        onChange={() => this.Recalculate_Duration_EditRoom()}></input> hour
                                                                                </td>
                                                                                <td align='center'>
                                                                                    <input type='number' min={0} max={59} defaultValue={0} ref={this.EDR_Duration_Min}
                                                                                        onChange={() => this.Recalculate_Duration_EditRoom()}></input> min
                                                                                </td>
                                                                                <td align='center'>
                                                                                    <input type='number' min={0} max={59} defaultValue={0} ref={this.EDR_Duration_Sec}
                                                                                        onChange={() => this.Recalculate_Duration_EditRoom()}></input> sec
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign='middle'>Room Type *</td>
                                                <td>
                                                    <ReactSelect
                                                        options={RoomTypeOptions}
                                                        isOptionDisabled={(option) => option.disabled === true} //2023.10.04
                                                        placeholder={
                                                            this.state.RoomData !== null ?
                                                                RoomTypeOptions[CheckObjectNumber(this.state.RoomData, 'RoomType')].label
                                                                : Locale("room-type", this.state.locale)
                                                        }
                                                        theme={theme => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                neutral50: 'black',  // placeholder color
                                                            },
                                                        })}
                                                        onChange={(option) => {
                                                            if (this.state.isDevMode)
                                                                console.log(JSON.stringify(option));
                                                            this.SaveDataInput(option.value, RoomDataInput.RoomType);
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr hidden={CheckObjectNumber(this.state.RoomData, 'RoomType') === 1}>
                                                <td>Question Set *</td>
                                                <td style={{ padding: 0, paddingLeft: 10 }}>
                                                    <table cellPadding='0' cellSpacing='0' border='0' width='100%' style={{ borderTop: 0, marginBottom: 0, borderStyle: 'hidden' }}>
                                                        <tbody>
                                                            <tr>
                                                                <td valign='middle'>
                                                                    {
                                                                        CheckObjectStringEmpty(this.state.RoomData, 'QuestionSetUniqueId') === '' || this.state.EditRoom_QuestionSet === null ?
                                                                            <span style={{ color: 'gray', }}><i>(no question set is selected)</i></span>
                                                                            :
                                                                            <>
                                                                                {this.state.EditRoom_QuestionSet.Name}
                                                                                <span style={{ fontSize: 12, color: 'gray' }}>
                                                                                    <br />{'Total Question : ' + CheckObjectStringEmpty(this.state.EditRoom_QuestionSet, 'TotalQuestion')}
                                                                                    <br />{'Group : ' + CheckObjectStringEmpty(this.state.EditRoom_QuestionSet['Group'], 'Name')}
                                                                                    <br />{'Subject : ' + CheckObjectStringEmpty(this.state.EditRoom_QuestionSet['Subject'], 'Label')}
                                                                                    <br />{CheckObjectStringEmpty(this.state.EditRoom_QuestionSet, 'UniqueId')}
                                                                                </span>
                                                                            </>
                                                                    }
                                                                </td>
                                                                <td align='right' style={{ verticalAlign: 'top', display: 'flex', flexDirection: 'column', gap: 5, padding: 8 }}>
                                                                    <Button onClick={() => this.Toggle_Search_QuestionSetModal()}>Select Question Set</Button>
                                                                    <Button
                                                                        variant="outline-primary"
                                                                        onClick={() => this.Ref_PreviewQuestionSetComponent.current.TogglePreviewQuestionSetModal(CheckObjectStringEmpty(this.state.EditRoom_QuestionSet, 'UniqueId'))}
                                                                        hidden={CheckObjectStringEmpty(this.state.EditRoom_QuestionSet, 'UniqueId') === ''}
                                                                    >Preview Question Set</Button>
                                                                    <Button
                                                                        variant="outline-secondary"
                                                                        onClick={() => useAppService.getState().setModal('Remark',
                                                                            <div className="qs-remarks">
                                                                                <div><span className="title">Public</span><br /><div className="content">{CheckObjectStringEmpty(this.state.EditRoom_QuestionSet, 'Remark', '-')}</div></div>
                                                                                <div><span className="title">Hidden</span><br /><div className="content">{CheckObjectStringEmpty(this.state.EditRoom_QuestionSet, 'HiddenRemark', '-')}</div></div>
                                                                            </div>
                                                                        )}
                                                                        hidden={CheckObjectStringEmpty(this.state.EditRoom_QuestionSet, 'UniqueId') === ''}
                                                                    >View Remark</Button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign='middle'>Extra Url (Flipbook)</td>
                                                <td>
                                                    <input type="text" className="form-control" style={{ width: '100%' }}
                                                        onChange={(e) => {
                                                            this.SaveDataInput(e.target.value, RoomDataInput.ExtraUrl);
                                                        }}
                                                        defaultValue={CheckObjectStringEmpty(this.state.RoomData, 'ExtraUrl')}
                                                    />
                                                </td>
                                            </tr>
                                            <tr hidden={true}>
                                                <td>Random Question Mode</td>
                                                <td>
                                                    <div className="form-check">
                                                        <input
                                                            id='formCheck_RandomQuestionMode'
                                                            className='form-check-input cursor-pointer'
                                                            type='checkbox'
                                                            ref={this.EDR_CHK_RandomQuestionMode}
                                                            defaultChecked={CheckObjectBoolean(this.state.RoomData, 'RandomQuestionMode')}
                                                            readOnly={true}
                                                            onClick={() => this.SaveDataInput(this.EDR_CHK_RandomQuestionMode.current.checked, RoomDataInput.RandomQuestionMode)}
                                                        ></input>
                                                        <label className='form-check-label cursor-pointer' htmlFor='formCheck_RandomQuestionMode'
                                                        > enable randomly arrange question when answering quiz.</label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr hidden={true}>
                                                <td>Restrict Access To Time Range Only</td>
                                                <td>
                                                    <div className="form-check">
                                                        <input
                                                            id='formCheck_RestrictAccessToTimeRangeOnly'
                                                            className='form-check-input cursor-pointer'
                                                            type='checkbox'
                                                            ref={this.EDR_CHK_RestrictAccessToTimeRangeOnly}
                                                            defaultChecked={CheckObjectBoolean(this.state.RoomData, 'RestrictAccessToTimeRangeOnly')}
                                                            readOnly={true}
                                                            onClick={() => this.SaveDataInput(this.EDR_CHK_RestrictAccessToTimeRangeOnly.current.checked, RoomDataInput.RestrictAccessToTimeRangeOnly)}
                                                        ></input>
                                                        <label className='form-check-label cursor-pointer' htmlFor='formCheck_RestrictAccessToTimeRangeOnly'
                                                        > restrict current quiz room only accessible within specified time period.</label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr hidden={true}>
                                                <td>Set Quiz Ended</td>
                                                <td>
                                                    <div className="form-check">
                                                        <input
                                                            id='formCheck_QuizEnded'
                                                            className='form-check-input cursor-pointer'
                                                            type='checkbox'
                                                            ref={this.EDR_CHK_QuizEnded}
                                                            defaultChecked={CheckObjectBoolean(this.state.RoomData, 'QuizEnded')}
                                                            readOnly={true}
                                                            onClick={() => this.SaveDataInput(this.EDR_CHK_QuizEnded.current.checked, RoomDataInput.QuizEnded)}
                                                        ></input>
                                                        <label className='form-check-label cursor-pointer' htmlFor='formCheck_QuizEnded'
                                                        > end this quiz & restrict access of any future entry regardless of End Date.</label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr hidden={true}>
                                                <td>Force Retricted Access</td>
                                                <td>
                                                    <div className="form-check">
                                                        <input
                                                            id='formCheck_ForceRetrictedAccess'
                                                            className='form-check-input cursor-pointer'
                                                            type='checkbox'
                                                            ref={this.EDR_CHK_ForceRetrictedAccess}
                                                            defaultChecked={CheckObjectBoolean(this.state.RoomData, 'ForceRetrictedAccess')}
                                                            readOnly={true}
                                                            onClick={() => this.SaveDataInput(this.EDR_CHK_ForceRetrictedAccess.current.checked, RoomDataInput.ForceRetrictedAccess)}
                                                        ></input>
                                                        <label className='form-check-label cursor-pointer' htmlFor='formCheck_ForceRetrictedAccess'
                                                        > restrict access immediately & kick all users back to home screen.<br />(For Emergency Purpose)</label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr hidden={true}>
                                                <td>Enable Statistic Report</td>
                                                <td>
                                                    <div className="form-check">
                                                        <input
                                                            id='formCheck_EnableStatisticReport'
                                                            className='form-check-input cursor-pointer'
                                                            type='checkbox'
                                                            ref={this.EDR_CHK_EnableStatisticReport}
                                                            defaultChecked={CheckObjectBoolean(this.state.RoomData, 'EnableStatisticReport')}
                                                            readOnly={true}
                                                            onClick={() => this.SaveDataInput(this.EDR_CHK_EnableStatisticReport.current.checked, RoomDataInput.EnableStatisticReport)}
                                                        ></input>
                                                        <label className='form-check-label cursor-pointer' htmlFor='formCheck_EnableStatisticReport'
                                                        > include this room as part of the statistic report.</label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr hidden={true}>
                                                <td>Exclude from Statistic Report</td>
                                                <td>
                                                    <div className="form-check">
                                                        <input
                                                            id='formCheck_ExcludedFromStatisticReport'
                                                            className='form-check-input cursor-pointer'
                                                            type='checkbox'
                                                            ref={this.EDR_CHK_ExcludedFromStatisticReport}
                                                            defaultChecked={CheckObjectBoolean(this.state.RoomData, 'ExcludedFromStatisticReport')}
                                                            readOnly={true}
                                                            onClick={() => this.SaveDataInput(this.EDR_CHK_ExcludedFromStatisticReport.current.checked, RoomDataInput.ExcludedFromStatisticReport)}
                                                        ></input>
                                                        <label className='form-check-label cursor-pointer' htmlFor='formCheck_ExcludedFromStatisticReport'
                                                        > exclude this room from part of the statistic report.</label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Classroom</td>
                                                <td>
                                                    {
                                                        useAppService.getState().classroomOptions.length === 0 ?
                                                            <span style={{ color: '#9d9d9d', fontStyle: 'italic' }}>- classroom setting incomplete -</span>
                                                            :
                                                            <>

                                                                <div className="row">
                                                                    <div className="col-11" style={{ paddingRight: 0 }}>
                                                                        if any classroom is selected, only the selected classroom(s)' students will be allowed to enter this room & showed in students' quiz room list.
                                                                        {
                                                                            CheckObjectStringEmpty(this.state.RoomData, 'Classrooms') === '' ? null :
                                                                                <div style={{
                                                                                    fontWeight: 'bold', fontStyle: 'italic',
                                                                                    margin: '10px 20px 5px', padding: '1px 7px 3px 7px',
                                                                                    border: '1px solid gray', borderRadius: 5, color: 'blue',
                                                                                    width: 555, overflowWrap: 'break-word',
                                                                                    // textAlign: 'center'
                                                                                }}>
                                                                                    {/* {this.state.RoomData['Classrooms']} */}
                                                                                    {this.GetSelectedClassrooms_Component()}
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                    <div className="col-1" style={{ padding: 0 }}>
                                                                        <Button variant="link" style={{ padding: 0 }}
                                                                            onClick={() => this.SaveDataInput('', RoomDataInput.Classrooms)}>clear</Button>
                                                                    </div>
                                                                </div>
                                                                <div className="setting-box-border" style={{ marginTop: 5, height: 200, overflowY: 'auto' }}>
                                                                    {
                                                                        //2025.01.04
                                                                        this.GetClassroomOptions_Component()
                                                                        // Array.isArray(this.state.ClassroomOptions) ?
                                                                        //     this.state.ClassroomOptions.map((option, key) => {
                                                                        //         return <div className="form-check setting-checkbox"
                                                                        //             onChange={() => this.SaveDataInput(option, DataInput.Classrooms)}
                                                                        //         >
                                                                        //             <input className="form-check-input" type="checkbox" value="" id={`checkbox-classroom-${key + 1}`}
                                                                        //                 readOnly={true}
                                                                        //                 defaultChecked={this.state.RoomData === null ? false :
                                                                        //                     String(this.state.RoomData['Classrooms']).includes(String(option.value))}
                                                                        //             />
                                                                        //             <label className='form-check-label'
                                                                        //                 htmlFor={`checkbox-classroom-${key + 1}`}
                                                                        //                 style={{ cursor: 'pointer' }}
                                                                        //             >{CheckObjectStringEmpty(option, 'value', `-${key}-`)}</label>
                                                                        //         </div>;
                                                                        //     })
                                                                        //     : null
                                                                    }
                                                                </div>
                                                            </>
                                                    }
                                                </td>
                                            </tr>
                                            {/* <tr hidden={this.state.isDevMode === false}><td colSpan='2'>{JSON.stringify(this.state.RoomData).replaceAll(',', ', ')}</td></tr> */}
                                        </tbody>
                                    </table>
                                </div>
                            </Modal.Body>
                            <Modal.Footer style={{ padding: '10px 0px', }}>
                                <Row style={{ width: '100%', margin: 0, }}>
                                    <Col>
                                        <Button variant="danger" onClick={() => this.ToggleDeleteRoomModal()} >Remove</Button>
                                    </Col>
                                    <Col style={{ textAlign: 'end' }}>
                                        <Button variant="secondary" onClick={() => this.ToggleCreateEditRoomModal()} >Cancel</Button>
                                        {/* &nbsp;&nbsp;
                                        <Button variant="secondary" onClick={() => this.ResetEditedRoomData()} >Reload</Button> */}
                                        &nbsp;&nbsp;
                                        <Button variant="primary"
                                            onClick={() => this.state.Mode_Create_RoomDataModal ? this.CreateOrUpdateRoom_ViaApi() : this.SaveEditedRoom_ViaApi()}
                                        >{this.state.Mode_Create_RoomDataModal ? 'Create New' : 'Save'}</Button>
                                    </Col>
                                </Row>
                            </Modal.Footer>
                        </>
                }
            </Modal>

            {/* Room - Search Question Set - Modal */}
            <Modal show={this.state.ShowSearchQuestionSetModal}
                onHide={() => {
                    if (this.state.SearchQsSet_Processing)
                        DoNothing();
                    else
                        this.Toggle_Search_QuestionSetModal(true);
                }}
                centered>
                <Modal.Header closeButton={this.state.SearchQsSet_Processing === false}>
                    <Modal.Title>{
                        this.state.SearchQsSet_Processing ? 'Searching...' : 'Search Question Set'
                    }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.SearchQsSet_Processing ?
                            <ProgressBar animated now={100} className='progressbar1' />
                            :
                            <span>
                                {/* Search for Question Set:<br /> */}
                                <table width='100%' cellPadding='5' cellSpacing='5' border='0' style={{ borderColor: 'grey', marginBottom: 0, }}>
                                    <tbody>
                                        <tr>
                                            <td>Name</td>
                                            <td>
                                                <input type="text" style={{ width: '100%' }}
                                                    defaultValue={this.state.SearchQsSet_ByName}
                                                    onChange={(e) => this.SaveSearchOptions(String(e.target.value), RoomDataInput.SearchQsSet_ByName)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width={90}>Group</td>
                                            <td>
                                                <ReactSelect
                                                    options={useAppService.getState().groupOptions}
                                                    // options={this.GetGroupOptions()}
                                                    placeholder={
                                                        CheckObjectStringEmpty(this.state.SearchQsSet_ByGroup, 'label',
                                                            Locale("not-specify-group", this.state.locale))
                                                    }
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            neutral50: 'black',  // placeholder color
                                                        },
                                                    })}
                                                    value={this.state.SearchQsSet_ByGroup !== null ? this.state.SearchQsSet_ByGroup.value : null}
                                                    onChange={(option) => this.SaveSearchOptions(option, RoomDataInput.SearchQsSet_ByGroup)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Subject</td>
                                            <td>
                                                <ReactSelect
                                                    // options={useAppService.getState().subjectOptions}
                                                    options={this.state.SubjectOptions}
                                                    // options={this.GetSubjectOptions()}
                                                    placeholder={
                                                        CheckObjectStringEmpty(this.state.SearchQsSet_BySubject, 'label',
                                                            Locale("not-specify-subject", this.state.locale))
                                                    }
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            neutral50: 'black',  // placeholder color
                                                        },
                                                    })}
                                                    value={this.state.SearchQsSet_BySubject !== null ? this.state.SearchQsSet_BySubject.value : null}
                                                    onChange={(option) => this.SaveSearchOptions(option, RoomDataInput.SearchQsSet_BySubject)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Max Result</td>
                                            <td>
                                                <input type="number" style={{ width: '100%' }}
                                                    defaultValue={this.state.SearchQsSet_MaxQtyShow}
                                                    onChange={(e) => this.SaveSearchOptions(Number(e.target.value), RoomDataInput.SearchQsSet_MaxQtyShow)}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </span>
                    }
                </Modal.Body>
                {
                    !this.state.SearchQsSet_Processing ?
                        <Modal.Footer className="modal-btns-gap">
                            <Button variant="secondary" onClick={() => this.Toggle_Search_QuestionSetModal(true)}>Cancel</Button>
                            {/* <Button variant="primary" onClick={this.SearchQuestionSetByConditions} disabled={this.state.SearchQsSet_ByGroup === null}>Search</Button> */}
                            <Button variant="primary" onClick={() => this.SearchQuestionSetByConditions_ViaApi()}
                                disabled={!(this.state.SearchQsSet_ByGroup !== null || this.state.SearchQsSet_BySubject !== null || this.state.SearchQsSet_ByName !== '')}>Search</Button>
                        </Modal.Footer>
                        : null
                }
            </Modal>

            {/* Room - ReactSelect Question Set - Modal */}
            <Modal size='xl' show={this.state.ShowSelectQuestionSetModal} onHide={() => this.Toggle_Select_QuestionSetModal(true)}
                centered
            // dialogClassName='alert-dialog-bordered'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Select Question Set</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        Select a Question Set from list:<br />
                        <table className='table table-hover tbStyle' width='100%' cellPadding='5' cellSpacing='5'
                            style={{
                                border: '1px solid gray', marginTop: 10, marginBottom: 0,
                                borderTop: 'inset', borderBottomStyle: 'groove',
                            }}>
                            <thead>
                                <tr className='hide-row-hover' key={0}>
                                    <th></th>
                                    <th>#</th>
                                    <th align='left'>Name</th>
                                    <th align='left'>Group</th>
                                    <th align='left'>Subject</th>
                                    <th>Total Question</th>
                                    <th align='left'>Remark</th>
                                    <th>Created</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.GetQuestionSetsResultList()}
                            </tbody>
                        </table>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.SearchAgain_SelectQuestionSet()}>Search Again</Button>
                    &nbsp;&nbsp;
                    <Button variant="secondary" onClick={() => this.Toggle_Select_QuestionSetModal(true)}>Cancel</Button>
                    &nbsp;&nbsp;
                    <Button variant="primary" onClick={() => this.Confirm_SelectOnThisQuestionSet()} disabled={this.state.SearchQsSet_QuestionSet_Selected === null}>Select</Button>
                </Modal.Footer>
            </Modal>

            {/* Room - Result - Component */}
            <RoomResultComponent ref={this.Ref_RoomResultComponent} List={this.state.List} RoomData={this.state.RoomData} />

            {/* Room - Search Room by Room Code - Modal */}
            <Modal show={this.state.ShowSearchRoomByRoomCodeModal}
                onHide={() => {
                    if (this.state.SearchRoomByRoomCode_Processing)
                        DoNothing();
                    else
                        this.setState({ ShowSearchRoomByRoomCodeModal: false, SearchRoomByRoomCode_RoomCode: '', })
                }}
                centered>
                <Modal.Header closeButton={this.state.SearchRoomByRoomCode_Processing === false}>
                    <Modal.Title>{
                        this.state.SearchRoomByRoomCode_Processing ? 'Searching...' : 'Search Room by Room Code'
                    }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.SearchRoomByRoomCode_Processing ?
                            <ProgressBar animated now={100} className='progressbar1' />
                            :
                            <table cellPadding={5} cellSpacing={0} width='100%'>
                                <tbody>
                                    <tr>
                                        <td align='right'><span>Search by Room Code</span></td>
                                        <td>
                                            <input className='form-control' type="number" style={{ width: '100%' }}
                                                placeholder='(enter room code here)'
                                                onChange={(e) => this.setState({ SearchRoomByRoomCode_RoomCode: String(e.target.value) })}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                    }
                </Modal.Body>
                {
                    !this.state.SearchRoomByRoomCode_Processing ?
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.setState({ ShowSearchRoomByRoomCodeModal: false, SearchRoomByRoomCode_RoomCode: '', })}>Cancel</Button>
                            &nbsp;&nbsp;
                            <Button variant="primary" onClick={() => this.SearchRoomByRoomCode()} disabled={isNaN(Number(this.state.SearchRoomByRoomCode_RoomCode)) === 'NaN'}>Search</Button>
                        </Modal.Footer>
                        : null
                }
            </Modal>

            {/* Room - Search Room by Params (new) 2025.01.16 - Modal */}
            <Modal show={this.state.SearchRoomQueryModal_Toggle}
                onHide={() => {
                    if (this.state.SearchRoomQueryModal_Processing)
                        DoNothing();
                    else
                        this.ToggleSearchRoomQueryModal()
                }}
                centered>
                <Modal.Header closeButton={this.state.SearchRoomQueryModal_Processing === false}>
                    <Modal.Title>{this.state.SearchRoomQueryModal_Processing ? 'Searching...' : 'Search Room'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.SearchRoomQueryModal_Processing ?
                            <ProgressBar animated now={100} className='progressbar1' />
                            :
                            <table cellPadding={5} cellSpacing={0} width='100%'>
                                <tbody>
                                    <tr>
                                        <td align='right'><span>Room Code</span></td>
                                        <td>
                                            <input className='form-control' type="number" style={{ width: '100%' }}
                                                placeholder='(enter room code here)'
                                                onChange={(e) => this.SetSearchRoomQueryParam('RoomCode', String(e.target.value))}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align='right'><span>Room Title</span></td>
                                        <td>
                                            <input className='form-control' type="text" style={{ width: '100%' }}
                                                placeholder='(enter room title here)'
                                                onChange={(e) => this.SetSearchRoomQueryParam('RoomTitle', String(e.target.value))}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align='right'><span>Group</span></td>
                                        <td>
                                            <ReactSelect
                                                options={useAppService.getState().groupOptions}
                                                placeholder={CheckObjectStringEmpty(useAppService.getState().groupOptions.find(x => Number(x.value) === Number(this.state.SearchRoomQueryModal.GroupId)), 'label', '(select group)')}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        neutral50: 'gray',  // placeholder color
                                                    },
                                                })}
                                                onChange={(option) => {
                                                    if (this.state.isDevMode)
                                                        console.log(JSON.stringify(option));
                                                    this.SetSearchRoomQueryParam('GroupId', Number(option.id))
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: 'right', verticalAlign: 'top', paddingTop: 11 }}><span>Subject</span></td>
                                        <td>
                                            <ReactSelect
                                                options={useAppService.getState().subjectOptions}
                                                placeholder={CheckObjectStringEmpty(useAppService.getState().subjectOptions.find(x => Number(x.value) === Number(this.state.SearchRoomQueryModal.SubjectId)), 'label', '(select subject)')}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        neutral50: 'gray',  // placeholder color
                                                    },
                                                })}
                                                onChange={(option) => {
                                                    if (this.state.isDevMode)
                                                        console.log(JSON.stringify(option));
                                                    this.SetSearchRoomQueryParam('SubjectId', Number(option.id))
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: 'right', verticalAlign: 'top', paddingTop: 11 }}><span>Category</span></td>
                                        <td>
                                            <ReactSelect
                                                options={this.state.CategoryOptions}
                                                placeholder={CheckObjectStringEmpty(this.state.CategoryOptions.find(x => Number(x.id) === Number(this.state.SearchRoomQueryModal.CategoryId)), 'label', '(select category)')}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        neutral50: 'gray',  // placeholder color
                                                    },
                                                })}
                                                onChange={(option) => {
                                                    if (this.state.isDevMode)
                                                        console.log(JSON.stringify(option));
                                                    this.SetSearchRoomQueryParam('CategoryId', Number(option.id))
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    {
                                        this.state.CreatorOptions.length > 0 ?
                                            <tr>
                                                <td style={{ textAlign: 'right', verticalAlign: 'top', paddingTop: 11 }}><span>Created By</span></td>
                                                <td>
                                                    <ReactSelect
                                                        options={this.state.CreatorOptions}
                                                        placeholder={CheckObjectStringEmpty(this.state.CreatorOptions.find(x => Number(x.id) === Number(this.state.SearchRoomQueryModal.CreatorAuthorId)), 'label', '(select author)')}
                                                        theme={theme => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                neutral50: 'gray',  // placeholder color
                                                            },
                                                        })}
                                                        onChange={(option) => {
                                                            if (this.state.isDevMode)
                                                                console.log(JSON.stringify(option));
                                                            this.SetSearchRoomQueryParam('CreatorAuthorId', Number(option.id))
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            : null
                                    }
                                    {
                                        this.state.SearchRoomQueryModal === null || CheckNullValue(this.state.SearchRoomQueryModal?.SubjectId) === null ? null :
                                            <tr hidden>
                                                <td align="right"><span>Subject Type</span></td>
                                                <td>
                                                    {/* <ReactSelect
                                                        options={useAppService.getState().subjectOptions}
                                                        placeholder={CheckObjectStringEmpty(useAppService.getState().subjectOptions.find(x => Number(x.value) === Number(this.state.SearchRoomQueryModal.SubjectId)), 'label', '(select subject)')}
                                                        theme={theme => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                neutral50: 'gray',  // placeholder color
                                                            },
                                                        })}
                                                        onChange={(option) => {
                                                            if (this.state.isDevMode)
                                                                console.log(JSON.stringify(option));
                                                            this.SetSearchRoomQueryParam('SubjectId', Number(option.id))
                                                        }}
                                                    /> */}
                                                </td>
                                            </tr>
                                    }

                                    <tr hidden={true}>
                                        <td align='right'><span>Room Type</span></td>
                                        <td>
                                            <ReactSelect
                                                options={RoomTypeOptions}
                                                isOptionDisabled={(option) => option.disabled === true}
                                                placeholder={RoomTypeOptions[CheckObjectNumber(this.state.SearchRoomQueryModal, 'RoomTypeId')].label}
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        neutral50: 'black',  // placeholder color
                                                    },
                                                })}
                                                onChange={(option) => {
                                                    if (this.state.isDevMode)
                                                        console.log(JSON.stringify(option));
                                                    this.SetSearchRoomQueryParam(Room_SearchQuery_DefaultModal.RoomTypeId.indexOf, Number(option.value))
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                    }
                </Modal.Body>
                {
                    !this.state.SearchRoomQueryModal_Processing ?
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.ToggleSearchRoomQueryModal()}>Cancel</Button>
                            &nbsp;&nbsp;
                            <Button variant="primary" onClick={() => {
                                this.LoadList_ViaApi();
                                this.setState({
                                    SearchRoomQueryModal_Toggle: false,
                                });
                            }}>Search</Button>
                        </Modal.Footer>
                        : null
                }
            </Modal>

            {/* Setting - (BULK) Edit / Update - Modal */}
            <Modal show={this.state.BulkEdit_Toggle_EditSettingModal}
                onHide={() => this.BulkEdit_ToggleEditSettingModal()}
                centered
                dialogClassName="modal-w750"
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Bulk Edit - {settingTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="setting-bulk-parent">{this.BulkEdit_SettingModalComponent()}</Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="danger"
                        onClick={() => this.BulkEdit_ToggleRemoveSettingModal()}
                        style={{ position: "absolute", left: 0, marginLeft: 15 }}
                        disabled={this.state.BulkEdit_IsUpdating || (this.state.isSuperAdmin ? false : this.state.PA_Delete === false)}
                    >Bulk Remove</Button> */}
                    <Button variant="secondary" onClick={() => this.BulkEdit_ToggleEditSettingModal()} disabled={this.state.BulkEdit_IsUpdating}>Cancel</Button>
                    <Button variant="secondary" onClick={async () => {
                        this.BulkEdit_ToggleEditSettingModal(); //close.
                        await Delay(200);
                        this.BulkEdit_ToggleEditSettingModal(); //open again.
                    }} disabled={this.state.BulkEdit_IsUpdating}>Reset</Button>
                    <Button variant="primary" onClick={() => this.BulkEdit_CUD_Setting_ViaApi()} disabled={this.state.BulkEdit_IsUpdating || this.state.BulkEdit_Setting_checked.indexOf(true) < 0}>Bulk Update</Button>
                </Modal.Footer>
            </Modal >

            {/* Preview Question Set - Component */}
            <PreviewQuestionSetComponent ref={this.Ref_PreviewQuestionSetComponent} />
        </div >);
    }
}