import { CapitalizeJsonKeys, CheckNumber, CheckObjectNullValue, CheckObjectStringEmpty, DelayUntil } from '../utilities/GlobalFunctions';
import { GlobalSetting, SecretKey } from '../utilities/GlobalSetting';

//Jay created 2024.01.08

const isDevMode = window.location.href.includes('localhost');

//Jay revamped 2025.02.26
export async function GetAllOrganizers_ViaAPI(authorId = 0, requestAuthorId = 0,
    hasSchoolCode = '',
    schoolCode = '', schoolName = '', nationalState = '', districtArea = '',
    orderBy = 'DisplayName', orderType = 'ASC', pageIndex = 0, pageSize = GlobalSetting.PageSize
) {
    let done = false;
    let success = false;
    let errorMessage = '';
    let _List = [];
    let _categoryOptions = [];      //2024.12.12
    let _totalCount = 0;

    const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/Organizer/List?secret=' + SecretKey.Admin
        + '&authorId=' + authorId
        + '&requestAuthorId=' + requestAuthorId
        + '&hasSchoolCode=' + hasSchoolCode
        + '&schoolCode=' + schoolCode
        + '&schoolName=' + schoolName
        + '&nationalState=' + nationalState
        + '&districtArea=' + districtArea
        + '&orderBy=' + orderBy
        + '&orderType=' + orderType
        + '&pageIndex=' + pageIndex
        + '&pageSize=' + pageSize;
    if (isDevMode)
        console.log('GetAllOrganizers_ViaAPI (url) =\n' + url);

    await fetch(url,
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                // 'Content-Type': 'application/json',
            }
        })
        .then(res => res.json())
        .then(data => {
            if (isDevMode)
                console.log('GetAllOrganizers_ViaAPI (response) \n' + JSON.stringify(data));
            success = data.success;
            if (success) {
                if (CheckObjectNullValue(data, 'data') !== null) {
                    _List = CapitalizeJsonKeys(data.data.list);
                    _categoryOptions = CapitalizeJsonKeys(data.data.categoryOptions);       //2024.12.12
                    _totalCount = CheckNumber(data.data.totalCount);
                }
                if (Array.isArray(_List) === false)
                    _List = [];
                if (Array.isArray(_categoryOptions) === false)      //2024.12.12
                    _categoryOptions = [];
                if (Array.isArray(_List) && _List.length === 0)
                    if (isDevMode)
                        console.log('Organizer list is empty.');
                // if (Array.isArray(data.data) && Array(data.data).length > 0) {
                //     _List = CapitalizeJsonKeys(JSON.parse(JSON.stringify()));
                // }
                // else {
                //     if (isDevMode)
                //         console.log('Organizer list is empty.');
                // }
            }
            else {
                errorMessage = CheckObjectStringEmpty(data, 'message');
                if (isDevMode)
                    console.log('Error', 'api-organizer-load-list (failed)\n' + JSON.stringify(data));
            }
            done = true;
        })
        .catch(error => {
            if (isDevMode)
                console.log('Error', 'api-organizer-load-list (error)\n' + error.message);
            errorMessage = error.message;
            done = true;
        });
    await DelayUntil(() => done === true);

    return { success, errorMessage, data: { list: _List, categoryOptions: _categoryOptions, totalCount: _totalCount } };
}

export async function UpdateOrganizer_ViaAPI(authorId, remove = false, modal = null) {
    let done = false;
    let success = false;
    let errorMessage = '';
    let t_data = null;

    if (modal === null || typeof (modal) !== 'object') {
        errorMessage = 'Invalid object.';
    }
    else {
        await fetch(GlobalSetting.ApiUrl
            + 'Api/LearningCentre/Organizer/Update',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Secret: SecretKey.Admin,
                    AuthorId: authorId,
                    Remove: remove,
                    Modal: modal,
                }),
            })
            .then(res => res.json())
            .then(data => {
                if (isDevMode)
                    console.log(JSON.stringify(data));
                success = data.success;
                if (success) {
                    t_data = CapitalizeJsonKeys(data.data);
                }
                else {
                    errorMessage = CheckObjectStringEmpty(data, 'message');
                    if (isDevMode)
                        console.log('Error', 'api-organizer-update (failed)\n' + JSON.stringify(data));
                }
                done = true;
            })
            .catch(error => {
                if (isDevMode)
                    console.log('Error', 'api-organizer-update (error)\n' + error.message);
                errorMessage = error.message;
                done = true;
            });
        await DelayUntil(() => done === true);
    }
    return { success, errorMessage, data: t_data };
}

export async function CreateOrganizer_ViaAPI(authorId, modal = null) {
    let done = false;
    let success = false;
    let errorMessage = '';
    let _data = [];

    if (modal === null || typeof (modal) !== 'object') {
        errorMessage = 'Invalid object.';
    }
    else {
        await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Organizer/New',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Secret: SecretKey.Admin,
                    AuthorId: authorId,
                    Modal: modal,
                }),
            })
            .then(res => res.json())
            .then(data => {
                if (isDevMode)
                    console.log(JSON.stringify(data));
                success = data.success;
                if (success) {
                    if (CheckObjectNullValue(data, 'data') !== null)
                        _data = CapitalizeJsonKeys(JSON.parse(JSON.stringify(data.data)));
                }
                else {
                    errorMessage = data.message;
                    if (isDevMode)
                        console.log('Error', 'api-organizer-create (failed)\n' + JSON.stringify(data));
                }
                done = true;
            })
            .catch(error => {
                if (isDevMode)
                    console.log('Error', 'api-organizer-create (error)\n' + error.message);
                errorMessage = error.message;
                done = true;
            });
        await DelayUntil(() => done === true);
    }
    return { success, errorMessage, data: _data };
}

export async function UploadPictureFileForOrganizer_ViaAPI(modal = null) {
    let done = false;
    let success = false;
    let message = '';
    let responseData = null;

    if (modal === null || typeof (modal) !== 'object') {
        message = 'Invalid object.';
    }
    else {
        await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Organizer/Logo/Upload',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(CapitalizeJsonKeys(modal)),
            })
            .then(res => res.json())
            .then(data => {
                if (isDevMode)
                    console.log(JSON.stringify(data));
                success = data.success;
                if (success) {
                    responseData = data.data;
                }
                else {
                    message = data.message;
                    if (isDevMode)
                        console.log('Error', 'api-organizer-logo-upload (failed)\n' + JSON.stringify(data));
                }
                done = true;
            })
            .catch(error => {
                if (isDevMode)
                    console.log('Error', 'api-organizer-logo-upload (error)\n' + error.message);
                message = error.message;
                done = true;
            });
        await DelayUntil(() => done === true);
    }
    return { success, message, data: responseData };
}