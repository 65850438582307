import React from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { Button, Col, Modal, OverlayTrigger, ProgressBar, Row, Tooltip } from "react-bootstrap";
import ReactSelect from "react-select";
import * as XLSX from "xlsx";
import { dbQuizBank } from "../utilities/Firebase";
import { off, onValue, ref, set } from "firebase/database";

import LoadingIndicator from "./LoadingIndicator";
import { Locale } from "../utilities/localization/CustomLocalization";
import { PermissionAccess, CapitalizeJsonKeys, CheckBoolean, CheckNullValue, CheckNumber, CheckObjectBoolean, CheckObjectNullValue, CheckObjectNumber, CheckObjectStringEmpty, Delay, DoNothing, FormatList_QuestionSet, GetPropIds, PagingComponents, UTCtoLocalDateTimeString, GetPostParams, CheckStringEmpty } from "../utilities/GlobalFunctions";
import { AtoZ, GlobalSetting, LayoutScreen, PermissionAccessType, Permissions, UploadState, getMenuLink } from "../utilities/GlobalSetting";
import { useGlobal } from "../utilities/GlobalVariables";
import { useAppService } from "../services/AppService";
import { AlertMode } from "./AlertComponent";
import PreviewQuestionSetComponent from "./PreviewQuestionSetComponent";    //2024.12.07
import QuestionSetComponent from "./QuestionSetComponent";

const settingTitle = 'Question Set';

const DataInput = {
    None: 0,
    Name: 1,
    Remark: 2,
    TotalQuestion: 3,
    IsPublic: 4,
    IsPrivateGroup: 5,
    Published: 6,
    DisplayOrder: 7,
    Group: 8,
    Subject: 9,
    SearchQsSet_ByGroup: 10,          //standard
    SearchQsSet_BySubject: 11,        //subject
    SearchQsSet_ByName: 12,           //name
    SearchQsSet_MaxQtyShow: 13,       //max result
    HiddenRemark: 14,   //2025.03.12
};

const QuestionSetInitValue = {
    OrganizerId: 0,
    CenterUserId: 0,
    AuthorId: 0,
    AuthorName: '',
    AuthorUserId: 0,
    AuthorRoleId: 0,
    Name: '',
    Remark: '',
    HiddenRemark: '',   //2025.03.12
    UniqueId: '',
    TotalQuestion: 0,
    GroupId: 0,
    IsPublic: false,
    IsPrivateGroup: false,
    Published: true,
    DisplayOrder: 0,
    SubjectId: 0,
    Id: 0,
    Subject: null,
    Group: null,
    // Subject: {
    //     id: 0,
    //     name: '',
    //     label: ''
    // },
    // Group: {
    //     id: 0,
    //     name: '',
    //     label: ''
    // }
};

//2025.02.14
const ItemProperty = {
    None: 'none',
    CheckedItem: 'checkedItem',

    Id: 'Id',
    Name: 'Name',
    Remark: 'Remark',
    HiddenRemark: 'HiddenRemark',   //2025.03.20

    Group: 'Group',
    GroupId: 'GroupId',
    GroupName: 'GroupName',

    Subject: 'Subject',
    SubjectId: 'SubjectId',
    SubjectName: 'SubjectName',

    // //etc.
    // OrganizerId: 'OrganizerId',
    // AlwaysOnTop: 'AlwaysOnTop',
    // DisplayOrder: 'DisplayOrder',
    // Active: 'Active',
    // Label: 'Label',
};
const BulkSetting = {
    Group: 'Group',
    Subject: 'Subject',
    Remark: 'Remark',
    HiddenRemark: 'HiddenRemark',   //2025.03.12
};

export default class ManageQuestionSetScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.

        this.Ref_PreviewQuestionSetComponent = React.createRef();   //2024.12.07
        this.Ref_QuestionSetComponent = React.createRef();   //2024.12.16
    }

    // dbQuizBank = getDatabase(app_rtdb_dbQuizBank);
    // unsubscribe_dbQuizBank = null;

    getInitState = () => ({

        isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        redirect: false,
        redirectLink: '/',
        isLoading: false,
        isSuperAdmin: false,

        PA_View: false,
        PA_Search: false,
        PA_Create: false,
        PA_Update: false,
        PA_Delete: false,
        PA_Upload: false,
        PA_Download: false,
        PA_Teacher: false,   //2024.07.23
        PA_Upload_ToMultipleOrganizer: false,   //2025.01.17
        PA_Clone_ToMultipleOrganizer: false,    //2025.02.14
        IsAdmin: false,     //2025.01.21
        OrganizerId: 0,     //2025.02.17

        //List.
        List: [],
        IsListLoaded: false,
        PageIndex: 0,
        PageSize: GlobalSetting.PageSize,
        TotalRows: 0,

        //Search.
        ShowSearchListModal: false,
        SearchList_Processing: false,
        IsSearchQsSetConditionsValid: false,
        SearchQsSet_Result_List: [],
        //Select from Search Result.
        ShowSelectQuestionSetModal: false,
        IsSearchQsSetSelected: false,
        SearchQsSet_QuestionSet_Selected: null,
        //Search Options.
        SearchQsSet_ByGroup: null,          //standard  aka groupId
        SearchQsSet_BySubject: null,        //subject   aka subjectId
        SearchQsSet_ByName: '',             //name
        SearchQsSet_MaxQtyShow: 5,          //max result
        SearchQsSet_KeepSearchParams: false,   //2025.01.07

        //New or Edit.
        QuestionSetModal: null,
        Cached_QuestionSetModal: null,
        Mode_Create_QuestionSetModal: false,
        Show_CreateEditUpload_QuestionSetModal: false,

        //Delete.
        TargetQuestionSetModal: null,
        Show_Delete_QuestionSetModal: false,

        //Upload.
        Show_ProcessUploaded_QuestionSetModal: false,
        uploadedFile: null,
        FileToJson_Questions: null,
        FileToJson_Comprehension: null,
        FileToJson_FillInTheBlanks: null,
        QuestionSet_UniqueId: '',
        IsUploadConditionsFullfilled: false,
        UploadStatus: UploadState.None,
        UploadStatusText: '',
        UploadStatusModalSize: '',

        //Upload to multiple organizers.
        Show_ToMultipleOrganizers_QuestionSetModal_Upload: false,
        SelectedOrganizers: [],
        OrganizerList: [],
        CategoryOptions: [],        //2024.12.07
        SelectedCategory: null,     //2024.12.07

        //Clone to multiple organizers.
        Show_ToMultipleOrganizers_QuestionSetModal_Clone: false,    //2025.02.14

        //Preview teacher list.
        QuestionSetUniqueId: '',    //2024.12.18

        //2025.02.14 === BulkEdit
        BulkEdit_Setting: Object.keys(BulkSetting).map((data, key) => { return { key: data, value: null }; }),
        BulkEdit_Setting_checked: Object.keys(BulkSetting).map(() => { return false; }),
        BulkEdit_Toggle_EditSettingModal: false,
        BulkEdit_Toggle_RemoveSettingModal: false,
        BulkEdit_CheckedItems: [],
        BulkEdit_IsUpdating: false,
    });

    componentWillUnmount = async () => {
        await this.Remove_ListenToStatus_Upload();
        await this.Remove_ListenToStatus_Clone();
    }

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        useGlobal.getState().setScreen(LayoutScreen.ManageQuestionSet);
        // useAppService.getState().getGroups(true);
        // useAppService.getState().getSubjects(true);
        // await Delay(0);
        this.LoadList_ViaApi();
        useGlobal.getState().setRefreshListCallbackFn(this.LoadList_ViaApi);
    }

    //#region === Question Set List === start ===
    //2024.07.24
    CheckPermissions = async () => {
        const gv = useGlobal.getState();
        const { uid, organizerId } = GetPropIds(gv.user);
        this.setState({
            PA_View: PermissionAccess(LayoutScreen.ManageQuestionSet, PermissionAccessType.View),
            PA_Search: PermissionAccess(LayoutScreen.ManageQuestionSet, PermissionAccessType.Search),
            PA_Create: PermissionAccess(LayoutScreen.ManageQuestionSet, PermissionAccessType.Create),
            PA_Update: PermissionAccess(LayoutScreen.ManageQuestionSet, PermissionAccessType.Update),
            PA_Delete: PermissionAccess(LayoutScreen.ManageQuestionSet, PermissionAccessType.Delete),
            PA_Upload: PermissionAccess(LayoutScreen.ManageQuestionSet, PermissionAccessType.Upload),
            PA_Download: PermissionAccess(LayoutScreen.ManageQuestionSet, PermissionAccessType.Download),

            PageSize: CheckNumber(localStorage.getItem(`ManageQuestionSet_PageSize_${uid}_${organizerId}`), GlobalSetting.PageSize),
            isSuperAdmin: useGlobal.getState().isSuperAdmin,

            PA_Upload_ToMultipleOrganizer: PermissionAccess(Permissions.UploadQuestionSetsToMultipleOrganizers, PermissionAccessType.Upload), //2025.01.17
            PA_Clone_ToMultipleOrganizer: PermissionAccess(Permissions.CloneQuestionSetsToMultipleOrganizers, PermissionAccessType.Upload), //2025.02.14

            OrganizerId: organizerId,
            OrganizerList: CheckObjectNullValue(gv.user, 'OrganizerInfoList', []),
            IsAdmin: useGlobal.getState().isAdmin,  //2025.01.21
        });
        await Delay(0);
    }
    LoadList_ViaApi = async (resetPage = false) => {

        await this.CheckPermissions();    //2024.07.24

        //2025.03.25
        await useAppService.getState().getGroups(true);
        await useAppService.getState().getSubjects(true);

        const fnName = 'LoadList_ViaApi';
        const params = GetPropIds(useGlobal.getState().user);
        const uid = CheckObjectStringEmpty(params, 'uid');
        const authorId = CheckObjectNumber(params, 'authorId');
        const organizerId = CheckObjectNumber(params, 'organizerId');

        if (this.state.isDevMode)
            console.log(`${fnName} ${organizerId} ${authorId}`);

        let success = false;
        let messages = [];
        let list = [];
        let totalRows = 0;
        let categoryOptions = [];      //2024.12.07

        // //2025.01.07
        // if (this.state.SearchQsSet_KeepSearchParams === false) {
        //     this.ResetSearchQsSetParams();
        // }

        if (resetPage) {
            const pageSize = CheckNumber(localStorage.getItem(`ManageQuestionSet_PageSize_${uid}_${organizerId}`), GlobalSetting.PageSize);
            this.setState({
                PageIndex: 0,
                PageSize: pageSize < GlobalSetting.PageSize ? GlobalSetting.PageSize : pageSize,
                // SearchQsSet_KeepSearchParams: false,    //2025.01.07
            });

            //2025.03.20
            // if (this.state.SearchQsSet_KeepSearchParams) {
            this.ResetSearchQsSetParams();
            this.setState({ SearchQsSet_KeepSearchParams: false });
            // }
        }

        this.setState({
            IsListLoaded: false,
            List: [],
            isLoading: true,
        });
        // await Delay(200);

        if (authorId > 0 && organizerId > 0) {

            //2025.03.20
            const postData = JSON.stringify({
                OrganizerId: organizerId,
                AuthorId: authorId,
                GroupId: CheckObjectNumber(this.state.SearchQsSet_ByGroup, 'id'),
                SubjectId: CheckObjectNumber(this.state.SearchQsSet_BySubject, 'id'),
                Name: CheckStringEmpty(this.state.SearchQsSet_ByName),
                PageIndex: this.state.PageIndex,
                PageSize: this.state.PageSize,
            });
            if (this.state.isDevMode)
                console.log('LoadList_ViaApi (postData) \n' + postData);

            await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Quiz/QuestionSet/List',
                // Api/LearningCentre/Quiz/QuestionSet/List
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: postData,
                })
                .then(res => res.json())
                .then(data => {
                    if (this.state.isDevMode)
                        console.log(`${fnName} (response)`, JSON.stringify(data));
                    if (data.success) {
                        // success = true;
                        if (Array.isArray(data.data.list))
                            list = CapitalizeJsonKeys(data.data.list);
                        if (Array.isArray(data.data.categoryOptions))
                            categoryOptions = data.data.categoryOptions;
                        totalRows = CheckObjectNumber(data.data, 'totalRows', list.length);
                    }
                    else {
                        if (CheckNullValue(data.message) !== null)
                            messages.push(data.message);
                        if (this.state.isDevMode)
                            console.log('Error', 'api - question set list (failed)\n' + JSON.stringify(data));
                    }
                    success = true;
                })
                .catch(error => {
                    messages.push(error.message);
                    if (this.state.isDevMode)
                        console.log('Error', 'api - question set list (error)\n' + error.message);
                });

            if (list.length > 0) {
                let _List = []
                list.map((data, key) => {
                    return _List.push(CapitalizeJsonKeys(data));
                });
                list = this.PopulateQuestionSetData(_List);
            }
            if (this.state.isDevMode)
                console.log(`${fnName} (final)`, JSON.stringify(list));

            if (!success)
                useAppService.getState().setModal(Locale("error", this.state.locale), messages.join('<br />'));
        }
        await Delay(200);
        if (Array.isArray(categoryOptions) === false || categoryOptions.length === 0) {
            categoryOptions = [];
            // categoryOptions.push({ id: 0, label: 'All', value: 0 });
            // categoryOptions.push({ id: 1, label: 'Live Quiz', value: 1 });
            // categoryOptions.push({ id: 2, label: 'Kosa Kata', value: 2 });
        }
        else {
            let t_categoryOptions = [];
            t_categoryOptions.push({ id: 0, label: 'All', value: 0, modal: { id: 0, name: 'All', description: '', displayOrder: 0 } });
            categoryOptions.map((data, key) => {
                return t_categoryOptions.push(data);
            });
            categoryOptions = t_categoryOptions;
        }
        this.setState({
            TotalRows: totalRows,
            List: list,
            CategoryOptions: categoryOptions,                       //2024.12.07
            SelectedCategory: { id: 0, label: 'All', value: 0 },    //2024.12.07
            IsListLoaded: true,
            isLoading: false,
            BulkEdit_CheckedItems: Array.isArray(list) ? list.map((data, key) => { return false; }) : [],  //2025.02.14
        });
    }
    PopulateQuestionSetData = (list = null) => {
        let questionSetData = [];
        if (list !== null && Array.isArray(list)) {
            const groupOptions = useAppService.getState().groupOptions;
            const subjectOptions = useAppService.getState().subjectOptions;
            questionSetData = FormatList_QuestionSet(list, groupOptions, subjectOptions);
        }
        return questionSetData;
    }
    ListComponents = () => {
        if (this.state.List.length > 0) {
            let listItems = [];
            this.state.List.map((data, key) => {
                const itemId = key + 1 + this.state.PageIndex;
                const createdOnUtc = CheckObjectStringEmpty(data, 'CreatedOnUtc');
                return listItems.push(
                    <tr key={'tbi_' + key} className={`setting-parant-tr ${this.state.BulkEdit_CheckedItems[key] ? 'tr-selected' : ''}`}>
                        <td className="pointer setting-child-td" onClick={() => this.ToggleItemChecked(key)}>
                            <div className="no-td">
                                {itemId}
                                <input type='checkbox' className='pointer' checked={this.state.BulkEdit_CheckedItems[key]} readOnly={true}></input>
                            </div>
                        </td>
                        {/* <td>{itemId}</td> */}
                        {/* <td align='left'>
                            <Button
                                variant='primary'
                                onClick={() => this.GotoEditQuestionSetPage(CheckObjectStringEmpty(data, 'UniqueId'))}
                                style={{ textAlign: 'justify' }}
                            >{CheckObjectStringEmpty(data, 'Name')}</Button>
                        </td> */}
                        <td className="left" style={{ textAlign: 'justify' }}>{CheckObjectStringEmpty(data, 'Name')}</td>
                        <td>{CheckNullValue(data, 'Group') === null ? '' : CheckObjectStringEmpty(data.Group, 'Name')}</td>
                        <td>{CheckNullValue(data, 'Subject') === null ? '' : CheckObjectStringEmpty(data.Subject, 'Name')}</td>
                        <td>{CheckObjectNumber(data, 'TotalQuestion')}</td>
                        <td>{
                            CheckObjectStringEmpty(data, 'Remark') === '' ? 'N/A' :
                                <Button
                                    variant='outline-primary'
                                    onClick={() => useAppService.getState().setModal('(' + itemId + ') ' + CheckObjectStringEmpty(data, 'Name'),
                                        '<div style="display:flex;flex-direction:column;gap:10px;"><b><u>Remark (Public)</u></b><span style="padding:0px 20px;">' + CheckObjectStringEmpty(data, 'Remark', '-') + '</span></div>')}
                                >View</Button>
                        }</td>
                        <td>{
                            CheckObjectStringEmpty(data, 'HiddenRemark') === '' ? 'N/A' :
                                <Button
                                    variant='outline-primary'
                                    onClick={() => useAppService.getState().setModal('(' + itemId + ') ' + CheckObjectStringEmpty(data, 'Name'),
                                        '<div style="display:flex;flex-direction:column;gap:10px;"><b><u>Remark (Hidden)</u></b><span style="padding:0px 20px;">' + CheckObjectStringEmpty(data, 'HiddenRemark', '-') + '</span></div>')}
                                >View</Button>
                        }</td>
                        {/* <td>{UTCtoLocalDateTimeString(CheckObjectStringEmpty(data, 'CreatedOnUtc'))}</td> */}
                        <td hidden={!this.state.IsAdmin}>
                            {/* <div style={{ display: 'flex', flexDirection: 'column' }}>{
                                data['AuthorUser'] === undefined || data['AuthorUser'] === null ? '-' :
                                    data['AuthorUser']['Id'] === 0 ?
                                        <i style={{ color: 'red', fontWeight: 500 }}>- User has been deleted -</i>
                                        :
                                        Object.keys(data['AuthorUser']).map((au, auKey) => {
                                            if (au.includes('Id'))
                                                return null;
                                            const value = CheckObjectStringEmpty(data['AuthorUser'], au, '-');
                                            return <span key={'au_' + au} title={`(${au}) ${value}`}>{value}</span>;
                                        })
                            }</div> */}
                            <div style={{ display: 'flex', flexDirection: 'column' }}>{
                                data['AuthorUser'] === undefined || data['AuthorUser'] === null ? '-' :
                                    data['AuthorUser']['Id'] === 0 ?
                                        <i style={{ color: 'red', fontWeight: 500 }}>- User has been deleted -</i>
                                        :
                                        Object.keys(data['AuthorUser']).map((au, auKey) => {
                                            if (au.includes('Id'))
                                                return null;
                                            const value = CheckObjectStringEmpty(data['AuthorUser'], au, '-');
                                            return <span key={'au_' + au} title={`(${au}) ${value}`}>{value}</span>;
                                        })
                            }<span>{createdOnUtc === '' ? '' : '(' + UTCtoLocalDateTimeString(createdOnUtc) + ')'}</span></div>
                        </td>
                        <td>
                            <div style={{ padding: 5, display: 'flex', gap: 8, justifyContent: 'center' }}>
                                {
                                    this.state.PA_View === false ? null :
                                        <Button
                                            variant='outline-primary'
                                            onClick={() => this.Ref_PreviewQuestionSetComponent.current.TogglePreviewQuestionSetModal(CheckObjectStringEmpty(data, 'UniqueId'))}
                                        // style={{ width: '100%', }}
                                        >Preview</Button>
                                }
                                {
                                    this.state.PA_View === false ? null :
                                        <Button
                                            variant='outline-primary'
                                            onClick={() => this.Ref_QuestionSetComponent.current.RemoteToggle_QuestionSetTeacherListAccessModal_Preview(CheckObjectStringEmpty(data, 'UniqueId'))}
                                        // style={{ width: '100%', }}
                                        >Teacher</Button>
                                }
                                {
                                    this.state.PA_Update === false ? null :
                                        <Button
                                            variant='outline-primary'
                                            onClick={() => this.GotoEditQuestionSetPage(CheckObjectStringEmpty(data, 'UniqueId'))}
                                        // style={{ width: '100%', }}
                                        >Edit</Button>
                                }
                            </div>
                        </td>
                    </tr>);
            });
            return listItems;
        }
        return this.state.IsListLoaded ?
            <tr><td colSpan='15' align='center'>list is empty</td></tr>
            : <tr><td colSpan='15' align='center'><LoadingIndicator /></td></tr>;
    }
    //#region === Paging Components
    CallbackFunctionForPagingComponents_PageSize = (pageSize = GlobalSetting.PageSize) => {
        this.setState({
            PageSize: pageSize < GlobalSetting.PageSize ? GlobalSetting.PageSize : pageSize,
        }, () => {
            const { uid, organizerId } = GetPropIds(useGlobal.getState().user);
            localStorage.setItem(`ManageQuestionSet_PageSize_${uid}_${organizerId}`, this.state.PageSize);
            setTimeout(() => {
                this.LoadList_ViaApi();
            }, 500);
        });
    }
    CallbackFunctionForPagingComponents_PageIndex = (pageIndex = 0) => {
        this.setState({
            PageIndex: pageIndex,
        }, () => {
            setTimeout(() => {
                this.LoadList_ViaApi();
            }, 500);
        });
    }
    //#endregion === Paging Components
    //#endregion === Question Set List === end ===

    //#region === Question Set === Search === start ===
    Toggle_Search_QuestionSetModal = (resetCache = true) => {
        if (this.state.PA_Search === false)
            return null;
        this.setState({
            ShowSearchListModal: !this.state.ShowSearchListModal,
        }, () => {
            if (this.state.ShowSearchListModal) {
                //reset
                this.setState({
                    SearchList_Processing: false,
                    IsSearchQsSetConditionsValid: false,
                    IsSearchConditionsSelected: false,
                    SearchQsSet_Result_List: [],
                    //Select from Search Result.
                    ShowSelectQuestionSetModal: false,
                    IsSearchQsSetSelected: false,
                    SearchQsSet_QuestionSet_Selected: null,
                });
                this.ResetSearchQsSetParams();
            }
            //reset by condition.
            if (resetCache) {
                this.ResetSearchQsSetParams();
            }
            //check.
            this.Check_SearchQsSetCondition();
        });
    }
    ResetSearchQsSetParams = () => {
        //Search Options.
        this.setState({
            SearchQsSet_ByGroup: null,              //standard
            SearchQsSet_BySubject: null,            //subject
            SearchQsSet_ByName: '',                 //name
            SearchQsSet_MaxQtyShow: 5,              //max result
        });
    }
    Check_SearchQsSetCondition = () => {
        this.setState({
            IsSearchQsSetConditionsValid: Number(this.state.SearchQsSet_MaxQtyShow) <= 0 ? false : true,
        });
    }
    SearchQuestionSetByConditions = async () => {
        if (this.state.PA_Search === false)
            return null;

        //2025.01.07 - skipped popup modal & select logic.
        // this.Toggle_Search_QuestionSetModal();
        this.setState({
            ShowSearchListModal: false,      //close search ui.
            SearchQsSet_KeepSearchParams: true,
        });
        await this.LoadList_ViaApi();
        return null;

        // this.setState({
        //     SearchList_Processing: true,
        //     SearchQsSet_Result_List: [],
        // });

        // //query.
        // let { success, list } = await this.SearchQuestionSet_ViaApi();
        // if (success)
        //     list = FormatList_QuestionSet(list);
        // else
        //     list = [];

        // this.setState({
        //     SearchList_Processing: false,
        //     SearchQsSet_Result_List: list,
        // }, () => {
        //     this.Toggle_Search_QuestionSetModal(false);
        //     this.Toggle_Select_QuestionSetModal();
        // });
    }
    //2025.01.07 - skipped popup modal & select logic.
    //#region disabled
    // SearchQuestionSet_ViaApi = async () => {
    //     if (this.state.PA_Search === false)
    //         return null;

    //     const fnName = 'SearchQuestionSet_ViaApi';
    //     const params = GetPropIds(useGlobal.getState().user);
    //     const authorId = CheckObjectNumber(params, 'authorId');
    //     const organizerId = CheckObjectNumber(params, 'organizerId');
    //     const centerUserId = CheckObjectNumber(params, 'centerUserId');

    //     if (this.state.isDevMode)
    //         console.log(`${fnName} ${authorId} ${organizerId} ${centerUserId}`);

    //     let success = false;
    //     let messages = [];
    //     let list = [];

    //     if (authorId > 0 && organizerId > 0) {
    //         const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/QuizBank/QuestionSet/SearchByName/'
    //             + organizerId + '/' + centerUserId + '/' + authorId + '/'
    //             + CheckObjectNumber(this.state.SearchQsSet_ByGroup, 'id') + '/'
    //             + CheckObjectNumber(this.state.SearchQsSet_BySubject, 'id') + '/'
    //             + this.state.SearchQsSet_MaxQtyShow + '/'
    //             + CheckNullValue(this.state.SearchQsSet_ByName);
    //         if (this.state.isDevMode)
    //             console.log(url);

    //         await fetch(url,
    //             // Api/LearningCentre/QuizBank/QuestionSet/SearchByName/{organizerId}/{centerUserId}/{authorId}/{name}/{groupId}/{subjectId}/{maxQty}
    //             {
    //                 method: 'GET',
    //                 headers: {
    //                     'Accept': 'application/json',
    //                     'Content-Type': 'application/json',
    //                 }
    //             })
    //             .then(res => res.json())
    //             .then(data => {
    //                 if (this.state.isDevMode)
    //                     console.log(`${fnName} ${JSON.stringify(data)}`);
    //                 if (data.success) {
    //                     success = true;
    //                     list = data.data;
    //                 }
    //                 else {
    //                     // messages.push('api search - question set - failed.\n' + JSON.stringify(data));
    //                     messages.push(data.message);
    //                 }
    //             })
    //             .catch(error => {
    //                 messages.push(error.message);
    //             });

    //         if (!success)
    //             useAppService.getState().setModal(Locale("error", this.state.locale), messages.join('<br />'));

    //         if (list.length > 0) {
    //             list = CapitalizeJsonKeys(list);
    //         }
    //     }
    //     return { success, messages, list };
    // }
    // Toggle_Select_QuestionSetModal = () => {
    //     this.setState({
    //         ShowSelectQuestionSetModal: !this.state.ShowSelectQuestionSetModal,
    //     }, () => {
    //         if (this.state.ShowSelectQuestionSetModal === false) {
    //             this.setState({
    //                 //reset
    //                 SearchQsSet_ByGroup: null,              //standard
    //                 SearchQsSet_BySubject: null,            //subject
    //                 SearchQsSet_MaxQtyShow: 5,              //max result
    //                 SearchQsSet_ByName: '',

    //                 SearchList_Processing: false,
    //                 IsSearchQsSetConditionsValid: false,
    //                 IsSearchConditionsSelected: false,
    //                 SearchQsSet_Result_List: [],
    //                 //Select from Search Result.
    //                 ShowSelectQuestionSetModal: false,
    //                 IsSearchQsSetSelected: false,
    //                 SearchQsSet_QuestionSet_Selected: null,
    //             });
    //         }
    //     });
    // }
    QuestionSetQueryResultListComponent = () => {
        let rows = [];
        //header.
        rows.push(<tr className='hide-row-hover' key={0}>
            <td width='50'></td>
            <td width='50'>#</td>
            <td className="left">Name</td>
            <td>Group</td>
            <td>Subject</td>
            <td width='135'>Total Question</td>
            <td>Remark</td>
            <td width='190'>Created Date</td>
        </tr>);
        //body.
        if (this.state.SearchQsSet_Result_List.length > 0) {
            this.state.SearchQsSet_Result_List.map((data, key) => {
                return rows.push(<tr onClick={() => this.SelectThisQuestionSet(data)} key={key + 1} style={{ cursor: 'pointer' }}>
                    <td>
                        <input type='radio' value={data.UniqueId} name='QSet'
                            checked={
                                this.state.SearchQsSet_QuestionSet_Selected === null ? false :
                                    this.state.SearchQsSet_QuestionSet_Selected.UniqueId === data.UniqueId
                            }
                            readOnly
                        ></input>
                    </td>
                    <td>{key + 1}</td>
                    <td className="left">{data.Name}</td>
                    <td>{data.Group.Name}</td>
                    <td>{data.Subject.Name}</td>
                    <td>{data.TotalQuestion}</td>
                    <td>{data.Remark}</td>
                    <td>{UTCtoLocalDateTimeString(data.CreatedOnUtc)}</td>
                </tr>);
            });
        }
        else {
            rows.push(<tr key={1}><td colSpan='8' align='center'>list is empty.</td></tr>);
        }
        return rows;
    }
    // SelectThisQuestionSet = (data) => {
    //     this.setState({ SearchQsSet_QuestionSet_Selected: data, IsSearchQsSetSelected: true });
    //     if (this.state.isDevMode)
    //         console.log('\nSelectThisQuestionSet = \n' + JSON.stringify(data));
    // }
    // SearchAgain_SelectQuestionSet = () => {
    //     this.Toggle_Select_QuestionSetModal();
    //     setTimeout(() => {
    //         this.Toggle_Search_QuestionSetModal(false);
    //     }, 300);
    // }
    // Confirm_SelectOnThisQuestionSet = () => {
    //     if (CheckNullValue(this.state.SearchQsSet_QuestionSet_Selected) !== null) {
    //         const uniqueId = CheckObjectStringEmpty(this.state.SearchQsSet_QuestionSet_Selected, 'UniqueId');
    //         if (uniqueId === '')
    //             useAppService.getState().setModal('Invalid Operation', 'Question Set not found.');
    //         else
    //             this.GotoEditQuestionSetPage(uniqueId);
    //     }
    // }
    //#endregion
    //#endregion === Question Set === Search === end ===

    //#region === Question Set === Delete === start ===
    DeleteThisQuestionSet = async () => {
        useAppService.getState().setModal('', '<b>Delete Question Set currently not available.</b>');
    }
    ToggleDeleteQuestionSetModal = () => {
        if (this.state.PA_Delete === false)
            return null;
        this.setState({ Show_Delete_QuestionSetModal: !this.state.Show_Delete_QuestionSetModal });
    }
    //#endregion === Question Set === Delete === end ===

    //#region === Question Set === Create / New === start ===
    ToggleCreateQuestionSetModal = () => {
        if (this.state.PA_Create === false)
            return null;
        this.setState({
            Mode_Create_QuestionSetModal: !this.state.Mode_Create_QuestionSetModal
        }, () => {
            if (this.state.Mode_Create_QuestionSetModal) {
                this.setState({
                    QuestionSetModal: { ...QuestionSetInitValue },
                });
                this.ToggleCreateEditUploadQuestionSetModal();
                // this.InitQuestionSetModal();
            }
        });
    }
    //Reset.
    ResetCreateQuestionSetModal = () => {
        this.ToggleCreateQuestionSetModal();
        setTimeout(() => {
            this.ToggleCreateQuestionSetModal();
        }, 300);
    }
    //Template.
    CreateQuestionSetTemplate = () => {
        if (this.state.PA_Upload === false)
            return null;

        this.setState({
            UploadStatus: UploadState.Converting,
        });
        this.ToggleProcessUploadedQuestionSetModal();

        //Creating Template based on settings.
        let _qsModal = [];
        const totalQuestions = CheckObjectNumber(this.state.QuestionSetModal, 'TotalQuestion');
        for (var i = 0; i < totalQuestions; i++) {
            _qsModal.push({
                QuizType: 'Objective',
                // No: (i + 1).toString(),
                Content: 'content',
                Answer: 'A',
                // Selection: 'A:-;B:-;C:-;D:-;',
                Tags: '',
                Hints: '',
                PictureUrl: '',
                A: '-',
                B: '-',
                C: '-',
                D: '-',
            });
        }

        //Continue.
        this.setState({
            FileToJson_Questions: _qsModal,
            FileToJson_Comprehension: null,
            FileToJson_FillInTheBlanks: null,
            UploadStatus: UploadState.Saving,
        }, () => {
            this.PopulatingJsonModalForFireStore();
            if (this.state.isDevMode)
                console.log(JSON.stringify(_qsModal));
        });
    }
    InitQuestionSetModal = () => {
        this.setState({
            // QuestionSetModal: { ...this.state.Cached_QuestionSetModal },
            QuestionSetModal: { ...QuestionSetInitValue },
            Cached_QuestionSetModal: null,
            uploadedFile: null,
            UploadStatus: UploadState.None,
            UploadStatusModalSize: '',
            IsUploadConditionsFullfilled: false,
        });
    }
    //#endregion === Question Set === Create / New === end ===

    //#region === Question Set === Upload === start ===
    ToggleCreateEditUploadQuestionSetModal = () => {
        if (this.state.PA_Upload === false)
            return null;
        this.setState({
            Show_CreateEditUpload_QuestionSetModal: !this.state.Show_CreateEditUpload_QuestionSetModal,
        }, async () => {
            await Delay(200);
            if (this.state.Show_CreateEditUpload_QuestionSetModal === false) {
                if (this.state.Mode_Create_QuestionSetModal) {
                    this.setState({ Mode_Create_QuestionSetModal: !this.state.Mode_Create_QuestionSetModal, });
                }
            }
            this.InitQuestionSetModal();
        });
    }
    //Reset. Create/Upload.
    ResetUploadQuestionSetModal = () => {
        this.ToggleCreateEditUploadQuestionSetModal();
        setTimeout(() => {
            this.ToggleCreateEditUploadQuestionSetModal();
        }, 300);
    }
    //Reset. Create/Edit.
    ResetCreateEditQuestionSetModal = () => {
        this.ToggleCreateEditUploadQuestionSetModal();
        setTimeout(() => {
            this.ToggleCreateQuestionSetModal();
        }, 300);
    }
    // On file select (from the pop up)
    onUploadFileChange = (event) => {
        if (this.state.PA_Upload === false)
            return null;
        // Update the state
        this.setState({ uploadedFile: event.target.files[0], }, () => this.CheckOnUploadConditions());
    };
    CheckOnUploadConditions = () => {
        const questionSet = this.state.QuestionSetModal;
        let isValid = false;
        if (this.state.Mode_Create_QuestionSetModal) {
            //Create New.
            if (
                CheckObjectNumber(questionSet, 'TotalQuestion') > 0 &&
                CheckObjectStringEmpty(questionSet, 'Name') !== '' &&
                CheckObjectNullValue(questionSet, 'Group') !== null &&
                CheckObjectNullValue(questionSet, 'Subject')
            ) {
                isValid = true;
            }
        }
        else {
            //Create & Upload.
            if (
                // CheckObjectNumber(questionSet, 'TotalQuestion') > 0 &&
                CheckObjectStringEmpty(questionSet, 'Name') !== '' &&
                CheckObjectNullValue(questionSet, 'Group') !== null &&
                CheckObjectNullValue(questionSet, 'Subject') &&
                this.state.uploadedFile !== null
            ) {
                isValid = true;
            }
        }
        this.setState({ IsUploadConditionsFullfilled: isValid, });
        // console.log(
        //     'TotalQuestion set = ' + String(Number(this.state.NewQSet_TotalQuestion) > 0) +
        //     '\nName set = ' + String(this.state.NewQSet_Name !== '') +
        //     '\nGroup set = ' + String(this.state.NewQSet_Group !== null) +
        //     '\nFile set = ' + String(this.state.uploadedFile !== null) +
        //     '\nFullfilled = ' + String(_fullfilled)
        // );
    }
    //#endregion === Question Set === Upload === end ===

    //#region === Question Set === Process Upload === start ===
    ToggleProcessUploadedQuestionSetModal = () => {
        this.setState({ Show_ProcessUploaded_QuestionSetModal: !this.state.Show_ProcessUploaded_QuestionSetModal });
    }
    ProcessQuestionSetModal = async () => {

        if (this.state.uploadedFile === null || this.state.uploadedFile === undefined) {
            useAppService.getState().setModal('Upload Failed', 'Please select a question set before upload.');
            return null;
        }

        this.setState({
            QuestionSet_UniqueId: '',
            UploadStatus: UploadState.Validation,
        });
        this.ToggleProcessUploadedQuestionSetModal();
        let jsonModal_Questions = null;
        let jsonModal_Comprehension = null;
        let jsonModal_FillInTheBlanks = null;
        let reader = new FileReader();
        reader.onload = (event) => {
            /* Parse data */
            let bstr = event.target.result;
            let wb = XLSX.read(bstr, { type: "binary" });

            //=== All Questions === start ===//
            /* Get first worksheet */
            let wsname = wb.SheetNames[0];
            // console.log(wsname);
            if (wsname === 'Questions') {
                let ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                let jsonData = XLSX.utils.sheet_to_json(ws);
                let jsonStrings = JSON.stringify(jsonData);
                // jsonStrings = jsonStrings.replace(new RegExp(/\\r\\n/, 'g'), '<br/>');
                // jsonStrings = jsonStrings.replace(new RegExp(/\\r\\n/, 'g'), '\\n');
                jsonStrings = jsonStrings.replaceAll('\\r\\n', '<br/>');
                if (this.state.isDevMode)
                    console.log('Questions =\n' + jsonStrings);
                // jsonModal_Questions = JSON.parse(jsonStrings, (key, value) => { return value !== undefined && value !== null && value !== '' ? value : null; });
                // jsonModal_Questions = JSON.parse(jsonStrings, (key, value) => { return CheckNullValue(value) !== null ? value : null; });    //2023.08.30

                //2024.07.02
                jsonModal_Questions = JSON.parse(jsonStrings, (key, value) => {

                    //2024.07.10
                    const findIndex = AtoZ.findIndex(x => x === String(key));
                    if (findIndex > -1) {
                        if (CheckNullValue(value) === '-')
                            return '-';
                    }

                    return CheckNullValue(value, '-') !== '-' ? value : null;
                });
                if (Array.isArray(jsonModal_Questions))
                    jsonModal_Questions = jsonModal_Questions.filter(x => x.Content !== null);

                if (this.state.isDevMode) {
                    console.log('Total Questions = ' + jsonModal_Questions.length);
                    console.log('Questions =\n' + JSON.stringify(jsonModal_Questions));
                }
            }
            //=== All Questions === end ===//

            //=== Comprehension, 2nd sheet === start ===//
            let wsname_comp = wb.SheetNames[1];
            // console.log(wsname_comp);
            if (wsname_comp === 'Comprehension') {
                let ws_comp = wb.Sheets[wsname_comp];
                /* Convert array of arrays */
                let jsonData_comp = XLSX.utils.sheet_to_json(ws_comp);
                let jsonStrings_comp = JSON.stringify(jsonData_comp);
                // jsonStrings_comp = jsonStrings_comp.replace(new RegExp(/\\r\\n/, 'g'), '<br/>');
                // jsonStrings_comp = jsonStrings_comp.replace(new RegExp(/\\r\\n/, 'g'), '\\n');
                jsonStrings_comp = jsonStrings_comp.replaceAll('\\r\\n', '<br/>');
                // if (this.state.isDevMode)
                //     console.log('Comprehension =\n' + jsonStrings_comp);
                // jsonModal_Comprehension = JSON.parse(jsonStrings_comp, (key, value) => { return value !== undefined && value !== null && value !== '' ? value : null });
                jsonModal_Comprehension = JSON.parse(jsonStrings_comp, (key, value) => { return CheckNullValue(value) !== null ? value : null });    //2023.08.30
                // if (this.state.isDevMode)
                //     console.log('Comprehension =\n' + JSON.stringify(jsonModal_Comprehension));
            }
            //=== Comprehension, 2nd sheet === end ===//

            //2021.07.21
            //=== FillInTheBlanks, 3rd sheet === start ===//
            let wsname_fitb = wb.SheetNames[2];
            // console.log(wsname_fitb);
            if (wsname_fitb === 'FillInTheBlanks') {
                let ws_fitb = wb.Sheets[wsname_fitb];
                /* Convert array of arrays */
                let jsonData_fitb = XLSX.utils.sheet_to_json(ws_fitb);
                let jsonStrings_fitb = JSON.stringify(jsonData_fitb);
                // jsonStrings_fitb = jsonStrings_fitb.replace(new RegExp(/\\r\\n/, 'g'), '<br/>');
                // jsonStrings_fitb = jsonStrings_fitb.replace(new RegExp(/\\r\\n/, 'g'), '\\n');
                jsonStrings_fitb = jsonStrings_fitb.replaceAll('\\r\\n', '<br/>');
                // if (this.state.isDevMode)
                //     console.log('FillInTheBlanks =\n' + jsonStrings_fitb);
                // jsonModal_FillInTheBlanks = JSON.parse(jsonStrings_fitb, (key, value) => { return value !== undefined && value !== null && value !== '' ? value : null });
                jsonModal_FillInTheBlanks = JSON.parse(jsonStrings_fitb, (key, value) => { return CheckNullValue(value) !== null ? value : null });  //2023.08.30
                // if (this.state.isDevMode)
                //     console.log('FillInTheBlanks =\n' + JSON.stringify(jsonModal_FillInTheBlanks));
            }
            //=== FillInTheBlanks, 3rd sheet === end ===//
        };
        reader.readAsArrayBuffer(this.state.uploadedFile);

        //2021.09.29 - moved to outer event func.
        await Delay(1000);
        if (jsonModal_Questions === null) {
            this.setState({
                //  fileConvertToJsonSuccess: false 
                UploadStatus: UploadState.ConvertFailed
            });
        }
        else {
            this.setState({
                FileToJson_Questions: jsonModal_Questions,
                // FileToJson_Comprehension: jsonModal_Comprehension,
                // FileToJson_FillInTheBlanks: jsonModal_FillInTheBlanks,
                FileToJson_Comprehension: jsonModal_Comprehension === null ? []
                    : jsonModal_Comprehension.length <= 0 ? []
                        : jsonModal_Comprehension[0].Id === null ? [] : jsonModal_Comprehension,
                FileToJson_FillInTheBlanks: jsonModal_FillInTheBlanks === null ? []
                    : jsonModal_FillInTheBlanks.length <= 0 ? []
                        : jsonModal_FillInTheBlanks[0].Id === null ? [] : jsonModal_FillInTheBlanks,
                // fileConvertToJsonSuccess: true
                // UploadStatus: UploadState.Uploading,
            }, () => {
                this.QuestionSetValidation(
                    this.state.FileToJson_Questions,
                    this.state.FileToJson_Comprehension,
                    this.state.FileToJson_FillInTheBlanks
                );
            });
        }
    }
    QuestionSetValidation = (questions, comprehension, fillInTheBlanks) => {
        // this.setState({
        //     UploadStatus: UploadState.Validation,
        // });

        let isValid = true;
        let messages = [];

        let hasComprehension = false;
        let hasFillInTheBlanks = false;

        let t_comprehensions = [];
        let t_fillInTheBlanks = [];

        if (this.state.isDevMode) {
            // console.log(this.state.uploadedFile !== null);
            console.log('questions =\n' + JSON.stringify(questions));
            console.log('comprehension =\n' + JSON.stringify(comprehension));
            console.log('fillInTheBlanks =\n' + JSON.stringify(fillInTheBlanks));
        }

        let _ignoreChecking = false;

        if (questions !== null) {
            //validating <Questions>.
            questions.map((data, key) => {
                if (CheckObjectNullValue(data, 'Id') === null)
                    return null;

                //re-init.
                _ignoreChecking = false;

                //found <Comprehension>.
                if (data.QuizType === 'Comprehension') {      //  'Comprehension;13,16;4;Content_13_16;'
                    t_comprehensions.push(key + 1);
                    if (hasComprehension === false)
                        hasComprehension = true;
                }
                //found <FillInTheBlanks>.
                if (data.QuizType === 'FillInTheBlanks') {      //  'FillInTheBlanks;17,20;4;Content_17_20;'
                    t_fillInTheBlanks.push(key + 1);
                    if (hasFillInTheBlanks === false)
                        hasFillInTheBlanks = true;

                    if (fillInTheBlanks.length > 0) {
                        fillInTheBlanks.map((setData, setKey) => {
                            if (_ignoreChecking === false)
                                if (setData.Start >= data.Id || setData.End <= data.Id)
                                    _ignoreChecking = true;
                            return null;
                        });
                    }
                }

                //for QuizType = Objective/Comprehension/Subjective.
                if (_ignoreChecking === false) {
                    //check Content.
                    if (data.Content === undefined) {
                        isValid = false;
                        messages.push('undefined content for qs #' + (key + 1) + '.');
                    }
                    //check Answer.
                    if (data.Answer === undefined) {
                        isValid = false;
                        messages.push('undefined answer for qs #' + (key + 1) + '.');
                    }
                    //loop & check answer options' content for reserved symbol. (e.g. ":" & ";")
                    [
                        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H',
                        'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P',
                        'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
                    ].map((opt, key) => {
                        if (data[opt] !== undefined) {
                            let text = String(data[opt]);
                            if (text.includes(':') || text.includes(';')) {
                                data[opt] = text.replaceAll(':', '：').replaceAll(';', '；');
                                // _messages.push('<b>invalid symbol found in answer option &lt;' + opt + '&gt; for qs #' + (key + 1) + '.</b>');
                            }
                        }
                        return null;
                    });
                }
                return null;
            });

            if (isValid) {
                //validating <Comprehension>.
                if (hasComprehension) {
                    if (comprehension !== null) {
                        if (comprehension.length > 0) {
                            comprehension.map((data, key) => {
                                if (comprehension.Passage === undefined) {
                                    isValid = false;
                                    messages.push('undefined passage in comprehension modal.');
                                }
                                if (t_comprehensions.findIndex(x => x === data.Start) < 0) {
                                    isValid = false;
                                    messages.push('Qs-No-Start &lt;' + data.Start + '&gt; is not found in comprehension modal.');
                                }
                                if (t_comprehensions.findIndex(x => x === data.End) < 0) {
                                    isValid = false;
                                    messages.push('Qs-No-End &lt;' + data.End + '&gt; is not found in comprehension modal.');
                                }
                                return null;
                            });
                        }
                    }
                    else {
                        isValid = false;
                        messages.push('invalid comprehensions array conversion.');
                    }
                }
                //validating <FillInTheBlanks>.
                if (hasFillInTheBlanks) {
                    if (fillInTheBlanks !== null) {
                        if (fillInTheBlanks.length > 0) {
                            fillInTheBlanks.map((data, key) => {
                                if (t_fillInTheBlanks.findIndex(x => x === data.Start) < 0) {
                                    isValid = false;
                                    messages.push('Qs-No-Start &lt;' + data.Start + '&gt; is not found in fillInTheBlanks modal.');
                                }
                                if (t_fillInTheBlanks.findIndex(x => x === data.End) < 0) {
                                    isValid = false;
                                    messages.push('Qs-No-End &lt;' + data.End + '&gt; is not found in fillInTheBlanks modal.');
                                }
                                return null;
                            });
                        }
                    }
                    else {
                        isValid = false;
                        messages.push('invalid fillInTheBlanks array conversion.');
                    }
                }
            }

            // if (this.state.isDevMode)
            //     console.log('isValid = ' + _isValid + '\nmessages = ' + _messages.join('\n'));
        }
        else {
            isValid = false;
            messages.push('invalid questions array conversion.');
        }
        if (isValid === false) {
            this.setState({
                UploadStatus: UploadState.ConvertFailed
            });
            useAppService.getState().setModal('File Validation Failed', messages.join('<br />'));
            this.ToggleProcessUploadedQuestionSetModal();
        }
        else {
            this.PopulatingJsonModalForFireStore();

            if (this.state.isDevMode)
                console.log('file conversion & validation success.\nisValid = ' + isValid + '\nerror messages = ' + messages.join('\n'));
        }
    }
    // ConvertToJson = (csv) => {
    //     var lines = csv.split("\n");
    //     var result = [];
    //     var headers = lines[0].split(",");
    //     for (var i = 1; i < lines.length; i++) {
    //         var obj = {};
    //         var currentline = lines[i].split(",");
    //         for (var j = 0; j < headers.length; j++) {
    //             obj[headers[j]] = currentline[j];
    //         }
    //         result.push(obj);
    //     }
    //     return result; //JavaScript object
    //     // return JSON.stringify(result); //JSON
    // }
    PopulatingJsonModalForFireStore = () => {
        let qsModal = [];
        //Convert to Keyed Json format.
        this.state.FileToJson_Questions.map((data, key) => {
            let _No = key + 1;
            qsModal.push({
                No: _No,
                Tags: CheckObjectStringEmpty(data, 'Tags'),
                Hints: CheckObjectStringEmpty(data, 'Hints'),
                QuizType: CheckObjectStringEmpty(data, 'QuizType', 'Objective'),
                Content: '',
                Answer: '',
                PictureUrl: '',
                Selection: '',
            });
            if (data.hasOwnProperty('QuizType')) {
                if (data.QuizType === 'Objective' || data.QuizType === 'Comprehension') {

                    qsModal[key].Content = CheckObjectStringEmpty(data, 'Content');
                    qsModal[key].Answer = CheckObjectStringEmpty(data, 'Answer');
                    qsModal[key].PictureUrl = CheckObjectStringEmpty(data, 'PictureUrl');
                    qsModal[key].Selection = this.PopulateSelections(data);

                    if (data.QuizType === 'Comprehension') {
                        let _index = this.state.FileToJson_Comprehension.findIndex(x => _No >= x.Start && _No <= x.End);
                        if (_index > -1) {
                            let com = this.state.FileToJson_Comprehension[_index];
                            qsModal[key].SpecialMode = 'Comprehension;' + com.Start + ',' + com.End + ';' + (com.End - com.Start + 1) + ';Content_' + com.Start + '_' + com.End + ';';
                        }
                    }
                }
                else if (data.QuizType === 'FillInTheBlanks') {
                    let _index = this.state.FileToJson_FillInTheBlanks.findIndex(x => _No >= x.Start && _No <= x.End);
                    if (_index > -1) {
                        let com = this.state.FileToJson_FillInTheBlanks[_index];
                        if (com.Start === _No) {
                            qsModal[key].Content = data.hasOwnProperty('Content') ? data.Content : '';
                            qsModal[key].Answer = data.hasOwnProperty('Answer') ? data.Answer : '';
                            qsModal[key].PictureUrl = data.hasOwnProperty('PictureUrl') ? data.PictureUrl : '';
                            qsModal[key].Selection = this.PopulateSelections(data);
                        }
                        qsModal[key].SpecialMode = 'FillInTheBlanks;' + com.Start + ',' + com.End + ';' + (com.End - com.Start + 1) + ';Content_' + com.Start + '_' + com.End + ';';
                    }
                }
            }
            return null;
        });
        this.SendQuestionSetModalToCMS_ViaApi(qsModal);
        // console.log(JSON.stringify(qsModal));
    }
    PopulateSelections = (array) => {
        let _selections = '';
        [
            'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H',
            'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P',
            'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
        ].map((data, key) => {
            //2023.10.19
            if (CheckObjectNullValue(array, data) !== null)
                _selections += data + ':' + CheckObjectStringEmpty(array, data) + ';';
            return null;
        });
        return _selections;
    }
    SendQuestionSetModalToCMS_ViaApi = async (qsModal) => {

        // console.log('qsModal =\n' + JSON.stringify(qsModal));
        // this.ToggleProcessUploadedQuestionSetModal();
        // return null;

        const user = useGlobal.getState().user;
        if (user === null)
            return null;

        this.setState({
            UploadStatus: UploadState.Uploading,
            UploadStatusText: '<span>File Conversion & Validation Success.<br /><br />Now Uploading & Saving...</span>',
        }, async () => {
            //UploadStatus.
            await this.Remove_ListenToStatus_Upload();
            set(ref(dbQuizBank, user.uid + '/UploadStatus'), this.state.UploadStatusText);
            // await dbQuizBank
            //     .ref(user.uid + '/UploadStatus')
            //     .set(this.state.UploadStatusText);
            this.ListenToStatus_Upload();
        });
        await Delay(500);

        const { uid, centerUserId, authorId, authorRoleId, organizerId } = GetPropIds(user);
        if (this.state.isDevMode)
            console.log(`SendQuestionSetModalToCMS ${centerUserId}, ${authorId}, ${authorRoleId}, ${organizerId}`);

        let success = false;
        let messages = [];
        let apiResponse = null;

        const questionSet = this.state.QuestionSetModal;
        const group = CheckObjectNullValue(questionSet, 'Group') === null ? { id: 0, label: '', value: '' } : questionSet.Group;
        const subject = CheckObjectNullValue(questionSet, 'Subject') === null ? { id: 0, label: '', value: '' } : questionSet.Subject;
        const totalQuestion = CheckObjectNumber(questionSet, 'TotalQuestion');

        const jsonModel = {
            OrganizerId: organizerId,
            CenterUserId: centerUserId,
            AuthorId: authorId,
            AuthorRoleId: authorRoleId,     //1 = admin, 4 = center, 11 = Author
            FirebaseUserId: uid,

            Name: CheckObjectStringEmpty(questionSet, 'Name'),
            Remark: CheckObjectStringEmpty(questionSet, 'Remark'),
            TotalQuestion: Array.isArray(qsModal) && qsModal.length > totalQuestion ? qsModal.length : totalQuestion,

            GroupId: group.id,          //small letter id. key from GroupOptions
            GroupName: group.value,     //small letter value.

            SubjectId: subject.id,
            SubjectName: subject.value,

            IsPublic: CheckObjectBoolean(questionSet, 'IsPublic'),
            IsPrivateGroup: CheckObjectBoolean(questionSet, 'IsPrivateGroup'),
            DisplayOrder: CheckObjectNumber(questionSet, 'DisplayOrder'),
            Published: CheckObjectBoolean(questionSet, 'Published'),

            QuestionModel: qsModal,
            ComprehensionModel: this.state.FileToJson_Comprehension,
            FillInTheBlanksModel: this.state.FileToJson_FillInTheBlanks,

            //2024.05.09
            UploadToMultipleOrganizers: this.state.SelectedOrganizers.length > 0,
            OrganizerIds: this.state.SelectedOrganizers,
        };
        if (this.state.isDevMode)
            console.log('jsonModel =\n' + JSON.stringify(jsonModel));

        await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/QuizBank/QuestionSet/Upload',
            {
                method: 'POST',                             // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(jsonModel), // body data type must match "Content-Type" header
            })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    success = true;
                    apiResponse = data;
                }
                else {
                    messages.push(data.message);
                    if (this.state.isDevMode)
                        console.log('api upload - question set - failed.\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                messages.push(error.message);
                if (this.state.isDevMode)
                    console.log('Error : ' + error.message);
            });

        if (success === false) {
            this.setState({ UploadStatus: UploadState.Failed, UploadStatusText: messages, });
        }
        else {
            this.setState({
                UploadStatus: UploadState.Success,
                QuestionSet_UniqueId: CheckObjectStringEmpty(apiResponse, 'data'),
            });
        }
        await this.Remove_ListenToStatus_Upload();
        if (this.state.isDevMode)
            console.log(messages.length > 0 ? messages : JSON.stringify(apiResponse));
    }
    UploadStatusMessage = () => {
        if (this.state.UploadStatus !== UploadState.None)
            switch (this.state.UploadStatus) {
                // case UploadState.Converting:
                //     return (<ProgressBar animated now={100} className='progressbar1' />);
                case UploadState.Validation:
                    return (<>
                        <span>Processing File Conversion & Validation...</span>
                        <br /><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 7 }} />
                    </>);
                case UploadState.Uploading:
                case UploadState.Cloning:
                    return (<>
                        {/* <span>File Conversion & Validation Success.<br /><br />Now Uploading & Saving...</span> */}
                        <div dangerouslySetInnerHTML={{ __html: this.state.UploadStatusText }} />
                        {/* <br /> */}
                        <ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 7 }} />
                    </>);
                case UploadState.Saving:
                    return (<>
                        <span>Now Saving...</span>
                        <br /><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 7 }} />
                    </>);
                case UploadState.ConvertFailed:
                    return (<span>File Conversion & Validation Failed.</span>);
                case UploadState.Success:
                    return (<>
                        {/* Upload Success. */}
                        <div dangerouslySetInnerHTML={{ __html: this.state.UploadStatusText }} />
                        {/* <br /> */}
                        <span style={{ fontSize: 12, color: 'gray' }}>{this.state.QuestionSet_UniqueId}</span>
                    </>);
                case UploadState.Failed:
                    return (<span>Upload Failed.<br /><br /><div dangerouslySetInnerHTML={{ __html: this.state.UploadStatusText }}></div></span>);
                default:
                    return null;
            }
        return null;
    }
    ReloadQuestionSetList = (refreshList = true) => {
        //close ui.
        this.ToggleProcessUploadedQuestionSetModal();
        // this.ToggleCreateEditUploadQuestionSetModal();
        // this.Toggle_QuestionSetModal_ToMultipleOrganizers_Upload();
        this.setState({
            Show_CreateEditUpload_QuestionSetModal: false,
            Mode_Create_QuestionSetModal: false,
            Show_ToMultipleOrganizers_QuestionSetModal_Upload: false,
        }, () => {
            this.InitQuestionSetModal();
        });
        //reload list.
        if (refreshList) {
            this.setState({
                List: []
            }, () => {
                this.LoadList_ViaApi();
            });
        }
    }
    ListenToStatus_Upload = () => {
        const user = useGlobal.getState().user;
        if (user !== null) {
            onValue(ref(dbQuizBank, user.uid + '/UploadStatus'), (snapshot) => {
                // handle read data.
                if (snapshot.exists()) {
                    const data = CheckNullValue(snapshot.val());
                    if (data !== null) {
                        this.setState({ UploadStatusText: String(data), });
                    }
                    if (this.state.isDevMode)
                        console.log('Upload Status = ' + String(data));
                }
            });

            // dbQuizBank.ref(user.uid + '/UploadStatus').on('value', snapshot => {
            //     // handle read data.
            //     if (snapshot.exists()) {
            //         const data = CheckNullValue(snapshot.val());
            //         if (data !== null) {
            //             this.setState({ UploadStatusText: String(data), });
            //         }
            //         if (this.state.isDevMode)
            //             console.log('Upload Status =\n' + String(data));
            //     }
            // });
        }
    }
    //2021.10.05
    Remove_ListenToStatus_Upload = async () => {
        const user = useGlobal.getState().user;
        if (user !== null) {
            off(ref(dbQuizBank, user.uid + '/UploadStatus'));              //Off listener.
            set(ref(dbQuizBank, user.uid + '/UploadStatus'), null);        //Remove status text.

            // //Off listener.
            // await dbQuizBank.ref(user.uid + '/UploadStatus').off();
            // //Remove status text.
            // await dbQuizBank.ref(user.uid + '/UploadStatus').remove();
        }
    }
    //entry point appeared after upload is success.
    GotoEditQuestionSetPage = (_UniqueId = '') => {
        // this.props.TogglePage(this.props.Toggle.EditQuestionSet, _UniqueId);
        useAppService.getState().setQuestionSetUniqueId(_UniqueId);
        // this.setState({ redirectLink: CheckObjectStringEmpty(getMenu[LayoutScreen.EditQuestionSet], 'Link', '/'), redirect: true, });
        const link = getMenuLink(LayoutScreen.EditQuestionSet);
        // if (this.state.isDevMode)
        //     console.log('GotoEditQuestionSetPage = ' + link);
        this.setState({ redirectLink: link, redirect: true, });
    }
    //#endregion === Question Set === Process Uploaded === end ===

    //#region === Question Set === Edit === start ===
    SaveDataInput = (value, inputType = DataInput.None) => {
        let questionSet = this.state.QuestionSetModal;
        if (questionSet !== null) {
            let selected = null;
            switch (inputType) {
                case DataInput.Name:
                    // questionSet['Name'] = CheckStringEmpty(value);
                    questionSet['Name'] = CheckNullValue(value) === null ? '' : String(value);
                    break;
                case DataInput.Remark:
                    // value = CheckStringEmpty(value);
                    value = CheckNullValue(value) === null ? '' : String(value);
                    if (value.length <= 250)
                        questionSet['Remark'] = value;
                    break;
                case DataInput.HiddenRemark:    //2025.03.12
                    questionSet['HiddenRemark'] = CheckNullValue(value) === null ? '' : String(value).trim();
                    break;
                case DataInput.TotalQuestion:
                    questionSet['TotalQuestion'] = CheckNumber(value);
                    break;
                case DataInput.DisplayOrder:
                    questionSet['DisplayOrder'] = CheckNumber(value);
                    break;
                case DataInput.IsPublic:
                    questionSet['IsPublic'] = CheckBoolean(value);
                    break;
                case DataInput.IsPrivateGroup:
                    questionSet['IsPrivateGroup'] = CheckBoolean(value);
                    break;
                case DataInput.Published:
                    questionSet['Published'] = CheckBoolean(value);
                    break;
                case DataInput.Group:
                    const groupOptions = useAppService.getState().groupOptions;
                    const findIndex_go = groupOptions.findIndex(x => value.value === x.value && value.id === x.id);
                    selected = findIndex_go < 0 ? null : groupOptions[findIndex_go];
                    questionSet['Group'] = selected;
                    break;
                case DataInput.Subject:
                    const subjectOptions = useAppService.getState().subjectOptions;
                    const findIndex_so = subjectOptions.findIndex(x => value.value === x.value && value.id === x.id);
                    selected = findIndex_so < 0 ? null : subjectOptions[findIndex_so];
                    questionSet['Subject'] = selected;
                    break;
                default: break;
            }
            if (this.state.isDevMode)
                console.log(`SaveDataInput ${Object.keys(DataInput)[inputType]} ${JSON.stringify(value)} \n ${JSON.stringify(this.state.QuestionSetModal)}`);

            this.setState({
                QuestionSetModal: questionSet,
            });
            this.CheckOnUploadConditions();
        }
    }
    SaveSearchOptions = (value, inputType = DataInput.None) => {
        let selected = null;
        switch (inputType) {
            case DataInput.SearchQsSet_ByGroup:
                const groupOptions = useAppService.getState().groupOptions;
                const findIndex_go = groupOptions.findIndex(x => String(value.value) === String(x.value) && Number(value.id) === Number(x.id));
                selected = findIndex_go < 0 ? null : groupOptions[findIndex_go];
                this.setState({ SearchQsSet_ByGroup: selected });
                break;
            case DataInput.SearchQsSet_BySubject:
                const subjectOptions = useAppService.getState().subjectOptions;
                const findIndex_so = subjectOptions.findIndex(x => String(value.value) === String(x.value) && Number(value.id) === Number(x.id));
                selected = findIndex_so < 0 ? null : subjectOptions[findIndex_so];
                this.setState({ SearchQsSet_BySubject: selected });
                break;
            case DataInput.SearchQsSet_ByName:
                this.setState({ SearchQsSet_ByName: String(value) });
                break;
            case DataInput.SearchQsSet_MaxQtyShow:
                this.setState({ SearchQsSet_MaxQtyShow: CheckNumber(value, 5) });
                break;
            default: break;
        }
        this.Check_SearchQsSetCondition();
        if (this.state.isDevMode)
            console.log(`SaveSearchOptions (${Object.keys(DataInput)[inputType]}) \n ${JSON.stringify(value)}`);
    }
    //#endregion === Question Set === Edit === end ===

    //#region === Question Set === Upload To Multiple Organizers === start ===
    Toggle_QuestionSetModal_ToMultipleOrganizers_Upload = async () => {
        if (this.state.PA_Upload === false)
            return null;
        const toggle = !this.state.Show_ToMultipleOrganizers_QuestionSetModal_Upload;
        //2024.12.13
        if (toggle) {
            useAppService.getState().setModal('', 'fetching latest organizer(s)...', null, AlertMode.Loading);
            this.setState({ OrganizerList: [] });
            const list = await useAppService.getState().getOrganizerList();
            await Delay(0);
            if (Array.isArray(list) && list.length > 0)
                this.setState({ OrganizerList: list });
            await Delay(1000);
            useAppService.getState().setModal();
        }
        this.setState({
            Show_ToMultipleOrganizers_QuestionSetModal_Upload: toggle,
        }, async () => {
            // if (this.state.Show_ToMultipleOrganizers_QuestionSetModal_Upload)
            //     this.InitQuestionSetModal();
            // else
            // await this.Reset_QuestionSetModal_ToMultipleOrganizers_Upload();
        });
        await this.Reset_QuestionSetModal_ToMultipleOrganizers_Upload();
    }
    Reset_QuestionSetModal_ToMultipleOrganizers_Upload = async () => {
        this.setState({
            SelectedOrganizers: [],
            SelectedCategory: null,
        });
        this.InitQuestionSetModal();
        await Delay(0);
    }
    //2024.12.07
    OrganizerCategoryComponent = () => {
        let components = [];
        const categoryOptions = CheckNullValue(this.state.CategoryOptions, []);
        if (Array.isArray(categoryOptions) && categoryOptions.length > 0) {
            const selectedCategory = this.state.SelectedCategory;
            components.push(<ReactSelect
                options={categoryOptions}
                placeholder={selectedCategory === null ? Locale("not-specify-category", this.state.locale) : selectedCategory.label}
                value={selectedCategory === null ? null : selectedCategory.value}
                onChange={(option) => this.setState({ SelectedCategory: option })}
            />);
        }
        return (components);
    }
    OrganizerListComponent = (excludeSelf = false) => {
        let components = [];

        // const selectedOrganizers = CheckNullValue(this.state.SelectedOrganizers, []);
        // const organizerList = CheckObjectNullValue(useGlobal.getState().user, 'OrganizerInfoList', []);
        // const organizerList = CheckNullValue(this.state.OrganizerList, []);
        const selectedOrganizers = Array.isArray(this.state.SelectedOrganizers) ? this.state.SelectedOrganizers : [];
        const organizerList = Array.isArray(this.state.OrganizerList) ? this.state.OrganizerList : [];

        // console.log(JSON.stringify(selectedOrganizers));
        // console.log(JSON.stringify(organizerList));

        if (Array.isArray(selectedOrganizers) && Array.isArray(organizerList) && organizerList.length > 0) {

            const selectedCategory = CheckNullValue(this.state.SelectedCategory, { id: 0, label: 'All', value: 0 });

            //2024.12.07 - Select All.
            //#region === Select All ===
            let isChecked_selectAll = false;
            if (selectedOrganizers.length === 0) {
                isChecked_selectAll = false;
            }
            else {
                //2025.02.17
                let t_organizerList = JSON.parse(JSON.stringify(organizerList));
                let t_selectedOrganizers = JSON.parse(JSON.stringify(selectedOrganizers));
                if (excludeSelf) {
                    const findIndex_so = t_selectedOrganizers.findIndex(x => x === this.state.OrganizerId);
                    if (findIndex_so > -1)
                        t_selectedOrganizers.splice(findIndex_so, 1);
                    const findIndex_o = t_organizerList.findIndex(x => Number(x.OrganizerId) === this.state.OrganizerId);
                    if (findIndex_o > -1)
                        t_organizerList.splice(findIndex_o, 1);
                }
                if (selectedCategory.id === 0) {
                    isChecked_selectAll = t_organizerList.length > 0 ? t_selectedOrganizers.length === t_organizerList.length : false;
                    // isChecked_selectAll = organizerList.length > 0 ? selectedOrganizers.length === organizerList.length : false;
                }
                else {
                    let organizerWithSameCategory = [];
                    let selectedOrganizerWithSameCategory = [];
                    for (let o = 0; o < t_organizerList.length; o++) {
                        const organizerId = t_organizerList[o].OrganizerId;
                        const categoryList = t_organizerList[o].CategoryList;
                        if (Array.isArray(categoryList) && categoryList.length > 0) {
                            if (categoryList.findIndex(x => x.CategoryId === selectedCategory.id) > -1)
                                organizerWithSameCategory.push(organizerId);
                            if (selectedOrganizers.findIndex(x => x === organizerId) > -1)
                                if (categoryList.findIndex(x => x.CategoryId === selectedCategory.id) > -1)
                                    selectedOrganizerWithSameCategory.push(organizerId);
                        }
                    }
                    if (organizerWithSameCategory.length === 0)
                        isChecked_selectAll = false;
                    else
                        isChecked_selectAll = organizerWithSameCategory.length === selectedOrganizerWithSameCategory.length;

                    // console.log('organizerList \n' + JSON.stringify(organizerList));
                    // console.log('organizerWithSameCategory vs selectedOrganizerWithSameCategory \n'
                    //     + organizerWithSameCategory.length + ' vs ' + selectedOrganizerWithSameCategory.length);
                }
            }
            // console.log(`OrganizerListComponent (isChecked_selectAll) = ${String(isChecked_selectAll)}`);
            // console.log(`OrganizerListComponent (selectedOrganizers.length) = ${String(selectedOrganizers.length)}`);
            // console.log(`OrganizerListComponent (organizerList.length) = ${String(organizerList.length)}`);
            // const selectAll_disabled = selectedCategory.id > 0;
            components.push(<tr
                key={'s-org-itm-selectAll'}
                onClick={() => {
                    let selectedOrganizers = CheckNullValue(this.state.SelectedOrganizers, []);
                    if (Array.isArray(selectedOrganizers) === false)
                        selectedOrganizers = [];
                    if (isChecked_selectAll) {
                        //unselect all.
                        if (selectedCategory.id === 0) {
                            selectedOrganizers = [];
                        }
                        else {
                            //unselect all by selected category.
                            let cache_selectedOrganizers = JSON.parse(JSON.stringify(selectedOrganizers));
                            let organizerWithSameCategory = [];
                            for (let o = 0; o < organizerList.length; o++) {
                                const organizerId = organizerList[o].OrganizerId;
                                const categoryList = organizerList[o].CategoryList;
                                if (Array.isArray(categoryList) && categoryList.length > 0) {
                                    if (categoryList.findIndex(x => x.CategoryId === selectedCategory.id) > -1)
                                        organizerWithSameCategory.push(organizerId);
                                }
                            }
                            organizerWithSameCategory.map((data, key) => {
                                const findIndex = cache_selectedOrganizers.findIndex(x => x === data);
                                if (findIndex > -1)
                                    cache_selectedOrganizers.splice(findIndex, 1);    //remove.
                                return null;
                            });
                            selectedOrganizers = cache_selectedOrganizers;
                        }
                    }
                    else {
                        //select all.
                        if (selectedCategory.id === 0) {
                            selectedOrganizers = organizerList.map((data, key) => { return CheckObjectNumber(data, 'OrganizerId'); });
                        }
                        else {
                            //select all by selected category.
                            let cache_selectedOrganizers = JSON.parse(JSON.stringify(selectedOrganizers));
                            organizerList.map((organizer, key) => {
                                if (Array.isArray(organizer.CategoryList)) {
                                    const findIndex = organizer.CategoryList.findIndex(x => x.CategoryId === selectedCategory.id);
                                    const findIndex_selectedOrganizer = selectedOrganizers.findIndex(x => x === organizer.OrganizerId);
                                    if (findIndex > -1 && findIndex_selectedOrganizer < 0)
                                        cache_selectedOrganizers.push(CheckObjectNumber(organizer, 'OrganizerId'));     //add.
                                }
                                return null;
                            });
                            selectedOrganizers = cache_selectedOrganizers;
                        }
                    }
                    //2025.02.17
                    if (excludeSelf) {
                        const findIndex = selectedOrganizers.findIndex(x => x === this.state.OrganizerId);
                        if (findIndex > -1)
                            selectedOrganizers.splice(findIndex, 1);
                    }
                    selectedOrganizers.sort((a, b) => a - b);
                    this.setState({
                        SelectedOrganizers: selectedOrganizers,
                    }, () => {
                        if (this.state.isDevMode)
                            console.log(`OrganizerListComponent (Select All) = ${String(isChecked_selectAll)} \n ${JSON.stringify(selectedOrganizers)}`);
                    });
                }}
                style={{ cursor: 'pointer' }}
            >
                <td width='42'>
                    <input type="checkbox" className="form-check form-check-input"
                        checked={isChecked_selectAll}
                        readOnly={true}
                        style={{ margin: 0 }}
                    />
                </td>
                <td>Select All {selectedCategory.id === 0 ? '' : `(${selectedCategory.label})`}</td>
            </tr>);
            //#endregion === Select All ===

            //option items.
            // let isSameCategory_count = 0;
            organizerList.map((data, key) => {
                const organizerId = CheckObjectNumber(data, 'OrganizerId');
                const isChecked = selectedOrganizers.findIndex(x => Number(x) === organizerId) > -1;
                const isSameCategory = Array.isArray(data.CategoryList) ?
                    data.CategoryList.findIndex(x => x.CategoryId === selectedCategory.id) > -1 : false;
                // if (isSameCategory)
                //     isSameCategory_count++;
                const disabled = excludeSelf ? organizerId === this.state.OrganizerId : false;
                return components.push(<tr
                    key={'s-org-itm-' + key}
                    onClick={() => disabled ? DoNothing() : this.SetSelectedOrganizers(key, !isChecked)}
                    style={{ cursor: 'pointer' }}
                    hidden={selectedCategory.id === 0 || isSameCategory ? false : true}
                >
                    <td width='42'>
                        <input type="checkbox" className="form-check form-check-input"
                            checked={isChecked}
                            readOnly={true}
                            style={{ margin: 0 }}
                            disabled={disabled}
                        />
                    </td>
                    {/* <td>({organizerId}) {CheckObjectStringEmpty(data, 'DisplayName')}</td> */}
                    <td><span style={disabled ? { color: 'gray' } : {}}>{CheckObjectStringEmpty(data, 'DisplayName')}</span></td>
                    {/* <td>
                        {CheckObjectStringEmpty(data, 'DisplayName')}<br />
                        {String(selectedCategory.id === 0)}<br />
                        {String(isSameCategory)}
                    </td> */}
                </tr>);
            });
            // let selectAll_ele = document.getElementById('');
            // if (selectAll_ele !== null) {
            //     if (isSameCategory_count === 0)
            //         selectAll_ele.style.pointerEvents = 'none';
            //     else
            //         selectAll_ele.style.pointerEvents = 'revert';
            // }
        }

        return (components);
    }
    SetSelectedOrganizers = async (index = -1, isChecked = null) => {
        if (this.state.isDevMode)
            console.log('SetSelectedOrganizers = ' + String(index) + ' / ' + String(isChecked));

        if (index < 0 || isChecked === null)
            return null;

        const _isChecked = CheckBoolean(isChecked);

        // let selectedOrganizers = CheckNullValue(this.state.SelectedOrganizers, []);
        let selectedOrganizers = [];
        const tmp_selectedOrganizers = CheckNullValue(this.state.SelectedOrganizers, []);
        if (Array.isArray(tmp_selectedOrganizers) && tmp_selectedOrganizers.length > 0)
            selectedOrganizers = tmp_selectedOrganizers;

        // const organizerList = CheckObjectNullValue(useGlobal.getState().user, 'OrganizerInfoList', []);
        let organizerList = [];
        const tmp_organizerList = CheckObjectNullValue(useGlobal.getState().user, 'OrganizerInfoList', []);
        if (Array.isArray(tmp_organizerList) && tmp_organizerList.length > 0)
            organizerList = tmp_organizerList;

        if (Array.isArray(selectedOrganizers) && Array.isArray(organizerList) && index < organizerList.length) {
            const organizerId = CheckObjectNumber(organizerList[index], 'OrganizerId');
            const findIndex = selectedOrganizers.findIndex(x => Number(x) === organizerId);
            if (findIndex < 0) {
                //not found & checked.
                if (_isChecked)
                    selectedOrganizers.push(organizerId);
            }
            else {
                //found & not checked anymore.
                if (_isChecked === false)
                    selectedOrganizers.splice(findIndex, 1);
            }
        }
        selectedOrganizers.sort((a, b) => a - b);
        this.setState({
            SelectedOrganizers: selectedOrganizers,
        });
        if (this.state.isDevMode) {
            // console.log('SetSelectedOrganizers (OrganizerId) = \n' + CheckObjectNumber(organizerList[index], 'OrganizerId'));
            // console.log('SetSelectedOrganizers (organizerList) = \n' + JSON.stringify(organizerList));
            console.log('SetSelectedOrganizers (selectedOrganizers) = \n' + JSON.stringify(selectedOrganizers));
        }
        await Delay(0);
    }
    //#endregion === Question Set === Upload To Multiple Organizers === end ===

    //#region === Question Set === Clone To Multiple Organizers === start ===
    //2025.02.14
    Toggle_QuestionSetModal_ToMultipleOrganizers_Clone = async () => {
        if (this.state.PA_Upload === false)
            return null;
        const toggle = !this.state.Show_ToMultipleOrganizers_QuestionSetModal_Clone;
        //2024.12.13
        if (toggle) {
            useAppService.getState().setModal('', 'fetching latest organizer(s)...', null, AlertMode.Loading);
            this.setState({ OrganizerList: [] });
            const list = await useAppService.getState().getOrganizerList();
            await Delay(0);
            if (Array.isArray(list) && list.length > 0)
                this.setState({ OrganizerList: list });
            await Delay(1000);
            useAppService.getState().setModal();
        }
        this.setState({
            Show_ToMultipleOrganizers_QuestionSetModal_Clone: toggle,
        });
        await this.Reset_QuestionSetModal_ToMultipleOrganizers_Clone();
    }
    Reset_QuestionSetModal_ToMultipleOrganizers_Clone = async () => {
        this.setState({
            SelectedOrganizers: [],
            SelectedCategory: null,
        });
        // this.InitQuestionSetModal();
        await Delay(0);
    }
    SelectedQuestionSetListComponent = () => {
        const selectedQuestionSets = [];
        this.state.List.forEach((data, index) => {
            if (this.state.BulkEdit_CheckedItems[index])
                selectedQuestionSets.push(data);
        });
        return selectedQuestionSets.map((data, index) => {
            return <tr>
                <td>{index + 1}</td>
                <td style={{ textAlign: 'left' }}>{CheckObjectStringEmpty(data, 'Name', '-')}</td>
                <td style={{ textWrap: 'nowrap' }}>{CheckObjectStringEmpty(data.Group, 'Name', '-')}</td>
                <td>{CheckObjectStringEmpty(data.Subject, 'Name', '-')}</td>
                <td>{CheckObjectNumber(data, 'TotalQuestion')}</td>
                <td>{
                    CheckObjectStringEmpty(data, 'Remark') === '' ? 'N/A' :
                        <Button
                            variant='outline-primary'
                            onClick={() => useAppService.getState().setModal('(' + (index + 1) + ') ' + CheckObjectStringEmpty(data, 'Name'),
                                '<div style="display:flex;flex-direction:column;gap:10px;"><b><u>Remark :</u></b><span style="padding:0px 20px;">' + CheckObjectStringEmpty(data, 'Remark', '-') + '</span></div>')}
                        >View</Button>
                }</td>
            </tr>;
        });
    }
    ListenToStatus_Clone = () => {
        const user = useGlobal.getState().user;
        if (user !== null) {
            onValue(ref(dbQuizBank, user.uid + '/CloneStatus'), (snapshot) => {
                // handle read data.
                if (snapshot.exists()) {
                    const data = CheckNullValue(snapshot.val());
                    if (data !== null) {
                        this.setState({ UploadStatusText: String(data), });
                    }
                    if (this.state.isDevMode)
                        console.log('Clone Status = ' + String(data));
                }
            });
        }
    }
    Remove_ListenToStatus_Clone = async () => {
        const user = useGlobal.getState().user;
        if (user !== null) {
            off(ref(dbQuizBank, user.uid + '/CloneStatus'));              //Off listener.
            set(ref(dbQuizBank, user.uid + '/CloneStatus'), null);        //Remove status text.
        }
    }
    CloneSelectedQuestionSetsToMultipleOrganizers_ViaAPI = async () => {

        const user = useGlobal.getState().user;
        if (user === null)
            return null;

        this.setState({
            UploadStatus: UploadState.Cloning,
            UploadStatusText: '<span>Now cloning...</span>',
        }, async () => {
            //CloneStatus.
            await this.Remove_ListenToStatus_Clone();
            set(ref(dbQuizBank, user.uid + '/CloneStatus'), this.state.UploadStatusText);
            this.ListenToStatus_Clone();
        });
        this.ToggleProcessUploadedQuestionSetModal();
        await Delay(500);

        const { authorId, organizerId } = GetPropIds(user);
        if (this.state.isDevMode)
            console.log(`CloneSelectedQuestionSetsToMultipleOrganizers_ViaAPI ${authorId} ${organizerId}`);

        let selectedQuestionSets = [];
        let itemIds = [];
        const list = this.state.List;
        const checkedItems = this.state.BulkEdit_CheckedItems;
        for (let n = 0; n < list.length; n++) {
            if (checkedItems[n]) {
                itemIds.push(CheckObjectNumber(list[n], ItemProperty.Id));
                selectedQuestionSets.push(list[n]);
            }
        }

        const selectedOrganizers = this.state.SelectedOrganizers;
        const json = JSON.stringify({
            OrganizerId: organizerId,
            AuthorId: authorId,
            BulkItemIds: itemIds.length > 0 ? itemIds.join(',') : '',
            CloneToMultipleOrganizers: selectedOrganizers.length > 0 && itemIds.length > 0,
            OrganizerIds: selectedOrganizers,

            // //etc.
            // Group: null,
            // Subject: null,
            // Remark: '',
            // Remove: false,
        });
        if (this.state.isDevMode)
            console.log(`CloneSelectedQuestionSetsToMultipleOrganizers_ViaAPI (postData) =\n` + json);

        let messages = [];
        if (itemIds.length === 0)
            messages.push('No question set has been selected.');
        if (selectedOrganizers.length === 0)
            messages.push('No organizer has been selected.');
        if (messages.length > 0) {
            await this.Remove_ListenToStatus_Clone();
            this.setState({
                UploadStatus: UploadState.Failed,
                UploadStatusText: 'Failed to clone selected question set(s) to organizer(s).<br /><br />' + messages.join('<br />'),
            });
            // useAppService.getState().setModal('Failed',
            //     'Failed to clone selected question set(s) to organizer(s).<br /><br />' + messages.join('<br />'));
            return null;
        }

        let success = false;
        let responseData = null;
        await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Organizer/QuestionSet/BulkCloneQuestionSetsToMultipleOrganizers',
            {
                method: 'POST',                             // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: json, // body data type must match "Content-Type" header
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('api clone - question set (response) \n' + JSON.stringify(data));
                if (data.success) {
                    success = true;
                    responseData = data.data;
                }
                else {
                    messages = data.message;
                }
            })
            .catch(error => {
                messages = error.message;
                if (this.state.isDevMode)
                    console.log('Error : ' + error.message);
            });

        if (success === false) {
            //failed.
            this.setState({
                UploadStatus: UploadState.Failed,
                UploadStatusText: 'Failed to clone selected question set(s) to organizer(s).<br /><br />'
                    + (Array.isArray(messages) ? messages.join('<br />') : messages),
            });
        }
        else {
            //success.
            let statusText = this.state.UploadStatusText + '<br /><br />';
            if (responseData !== null) {
                const organizerList = this.state.OrganizerList;
                const qSetIds = Object.keys(responseData);
                if (qSetIds.length > 0) {
                    statusText += '';
                    statusText += '<table class="table table-bordered" style="width:100%;"><tbody><tr><td style="min-width:10vw;"></td>';
                    qSetIds.forEach((data, key) => {
                        const findIndex = selectedQuestionSets.findIndex(x => Number(x.Id) === Number(data));
                        if (findIndex > -1)
                            statusText += `<td align='center'><span class="badge bg-primary" style="text-wrap-mode:wrap;">${CheckObjectStringEmpty(selectedQuestionSets[findIndex], 'Name', '-')}</span></td>`;
                    });
                    statusText += '</tr>';
                    selectedOrganizers.forEach((organizerId, okey) => {
                        const findIndex_organizer = Array.isArray(organizerList) ? organizerList.findIndex(x => Number(x.OrganizerId) === Number(organizerId)) : -1;
                        if (findIndex_organizer > -1) {
                            statusText += `<tr><td>${CheckObjectStringEmpty(organizerList[findIndex_organizer], 'Name', '-')}</td>`;
                            qSetIds.forEach((data, key) => {
                                const organizerIds = responseData[data];
                                if (Array.isArray(organizerIds))
                                    statusText += `<td align='center'><span class='icon-color'>${organizerIds.findIndex(x => Number(x) === Number(organizerId)) < 0 ?
                                        '<i class="fa fa-times-circle red"></i>' : '<i class="fa fa-check-circle blue"></i>'}</span></td>`;
                                else
                                    statusText = '<td>-</td>';
                            });
                            statusText += '</tr>';
                        }
                    });
                    statusText += '</tbody></table>';
                }
            }
            this.setState({
                UploadStatus: UploadState.Success,
                UploadStatusText: statusText,
                UploadStatusModalSize: 'xl',
                QuestionSet_UniqueId: '',
            });
            this.Toggle_QuestionSetModal_ToMultipleOrganizers_Clone();
        }
        await this.Remove_ListenToStatus_Clone();
    }
    CloseCloneModalAndStatusModal = () => {
        // this.Toggle_QuestionSetModal_ToMultipleOrganizers_Clone();
        this.ToggleProcessUploadedQuestionSetModal();
        this.setState({
            Show_CreateEditUpload_QuestionSetModal: false,
            Mode_Create_QuestionSetModal: false,
            Show_ToMultipleOrganizers_QuestionSetModal_Upload: false,
        }, () => {
            this.InitQuestionSetModal();
        });
    }
    //#endregion === Question Set === Clone To Multiple Organizers === end ===

    //#region === bulk edit ===
    //2025.02.14
    ToggleItemChecked = (index, selectAll = null) => {
        if (selectAll !== null) {
            this.setState({
                BulkEdit_CheckedItems: this.state.List.map((data, key) => { return !selectAll; }),
            });
        }
        else {
            if (index < 0)
                return null;
            let checkedItems = this.state.BulkEdit_CheckedItems;
            checkedItems[index] = !checkedItems[index];
            this.setState({
                BulkEdit_CheckedItems: checkedItems,
            });
        }
    }
    BulkEdit_ToggleEditSettingModal = () => {
        if (this.state.PA_Update === false) {
            useAppService.getState().setModal(`Bulk Edit ${settingTitle}(s)`, 'Invalid permission.');
            return null;
        }
        const toggle = !this.state.BulkEdit_Toggle_EditSettingModal;
        this.setState({
            BulkEdit_Toggle_EditSettingModal: toggle,
        });
        this.BulkEdit_ResetSetting();
    }
    BulkEdit_SettingModalComponent = () => {
        let components = [];
        const setting = this.state.BulkEdit_Setting;
        const setting_checked = this.state.BulkEdit_Setting_checked;

        //Group.
        const groups_setting_index = Object.keys(BulkSetting).indexOf(BulkSetting.Group);
        const groups_setting_checked = setting_checked[groups_setting_index];
        const groupOptions = useAppService.getState().groupOptions;
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (groups_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="select-setting form-group width-max pd-7-10">
                <label>Group</label>
                <ReactSelect
                    className="basic-multi-select"
                    classNamePrefix='select'
                    // isMulti
                    // closeMenuOnSelect={false}
                    options={groupOptions}
                    placeholder='Select Group...'
                    onChange={(option) => this.BulkEdit_SetSetting(BulkSetting.Group, option)}
                    theme={theme => ({
                        ...theme,
                        width: 'max-content',
                        colors: {
                            ...theme.colors,
                            neutral50: 'gray',  // placeholder color
                        }
                    })}
                    value={setting[BulkSetting.Group]}
                    isDisabled={groups_setting_checked === false}
                />
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(ItemProperty.CheckedItem, groups_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : groups_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        //Subject.
        const subject_setting_index = Object.keys(BulkSetting).indexOf(BulkSetting.Subject);
        const subject_setting_checked = setting_checked[subject_setting_index];
        const subjectOptions = useAppService.getState().subjectOptions;
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (subject_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="select-setting form-group width-max pd-7-10">
                <label>Subject</label>
                <ReactSelect
                    className="basic-multi-select"
                    classNamePrefix='select'
                    // isMulti
                    // closeMenuOnSelect={false}
                    options={subjectOptions}
                    placeholder='Select Subject...'
                    onChange={(option) => this.BulkEdit_SetSetting(BulkSetting.Subject, option)}
                    theme={theme => ({
                        ...theme,
                        width: 'max-content',
                        colors: {
                            ...theme.colors,
                            neutral50: 'gray',  // placeholder color
                        }
                    })}
                    value={setting[BulkSetting.Subject]}
                    isDisabled={subject_setting_checked === false}
                />
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(ItemProperty.CheckedItem, subject_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : subject_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        //Remark (Public).
        const remark_setting_index = Object.keys(BulkSetting).indexOf(BulkSetting.Remark);
        const remark_setting_checked = setting_checked[remark_setting_index];
        const remark_value = setting[remark_setting_index].value;
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (remark_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="form-group width-max">
                <label>Remark (Public)</label>
                {/* <input type="text" className="form-control" style={{ width: '100%' }}
                    value={setting[remark_setting_index].value}
                    onChange={(e) => this.BulkEdit_SetSetting(BulkSetting.Remark, e.target.value)}
                ></input> */}
                <textarea
                    rows="4" cols="50"
                    className="form-control"
                    placeholder={'(...remark...)'}
                    value={remark_value}
                    onChange={(e) => this.BulkEdit_SetSetting(BulkSetting.Remark, e.target.value)}
                    style={{ minHeight: 110 }}
                    disabled={remark_setting_checked === false}
                ></textarea>
                <Row>
                    <Col style={{ fontWeight: 'bold', fontSize: '13px', color: 'red' }}>{CheckStringEmpty(remark_value).length >= 250 ? '(Max charactors reached.)' : ''}</Col>
                    <Col style={{ textAlign: 'right', fontSize: '12px', color: 'gray' }}>{'(' + CheckStringEmpty(remark_value).length + '/250 charactors remaining.)'}</Col>
                </Row>
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(ItemProperty.CheckedItem, remark_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : remark_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        //Remark (Hidden).
        const remark_hidden_setting_index = Object.keys(BulkSetting).indexOf(BulkSetting.HiddenRemark);
        const remark_hidden_setting_checked = setting_checked[remark_hidden_setting_index];
        const remark_hidden_value = setting[remark_hidden_setting_index].value;
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (remark_hidden_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="form-group width-max">
                <label>Remark (Hidden)</label>
                <textarea
                    rows="4" cols="50"
                    className="form-control"
                    placeholder={'(...remark...)'}
                    value={remark_hidden_value}
                    onChange={(e) => this.BulkEdit_SetSetting(BulkSetting.HiddenRemark, e.target.value)}
                    style={{ minHeight: 110 }}
                    disabled={remark_hidden_setting_checked === false}
                ></textarea>
                <Row>
                    <Col style={{ fontWeight: 'bold', fontSize: '13px', color: 'red' }}>{CheckStringEmpty(remark_hidden_value).length >= 250 ? '(Max charactors reached.)' : ''}</Col>
                    <Col style={{ textAlign: 'right', fontSize: '12px', color: 'gray' }}>{'(' + CheckStringEmpty(remark_hidden_value).length + '/250 charactors remaining.)'}</Col>
                </Row>
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(ItemProperty.CheckedItem, remark_hidden_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : remark_hidden_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        return (components);
    }
    BulkEdit_SetSetting = (property = ItemProperty.None, value = null) => {
        let setting = this.state.BulkEdit_Setting;
        let setting_checked = this.state.BulkEdit_Setting_checked;
        const setting_index = property === ItemProperty.CheckedItem ? 999 : Object.keys(BulkSetting).indexOf(property);
        if (this.state.isDevMode)
            console.log(`BulkEdit_SetSetting (setting_index = ${setting_index}) | (property = ${property}) | (value = ${JSON.stringify(value)})`);
        if (property === ItemProperty.None || setting === null || value === null || setting_index < 0)
            return null;

        switch (property) {
            case ItemProperty.Group:
            case ItemProperty.Subject:
            case ItemProperty.Remark:
                setting[setting_index].value = value; break;
            case ItemProperty.HiddenRemark:
                setting[setting_index].value = value; break;    //2025.03.20

            case ItemProperty.CheckedItem:
                setting_checked[value] = !setting_checked[value];
                if (this.state.isDevMode)
                    console.log(`BulkEdit_SetSetting (checkedItem) = ` + JSON.stringify(setting_checked));
                break;
            default: break;
        }
        this.setState({
            BulkEdit_Setting: setting,
            BulkEdit_Setting_checked: setting_checked,
        }, () => {
            if (this.state.isDevMode && property !== ItemProperty.CheckedItem) {
                console.log(`BulkEdit_SetSetting (${property}) = ` + JSON.stringify(value));
                console.log(`BulkEdit_SetSetting (setting) = ` + JSON.stringify(setting));
            }
        });
    }
    BulkEdit_ResetSetting = () => {
        this.setState({
            BulkEdit_Setting: [
                { key: BulkSetting.Group, value: null },
                { key: BulkSetting.Subject, value: null },
                { key: BulkSetting.Remark, value: '' },
                { key: BulkSetting.HiddenRemark, value: '' },   //2025.03.20
            ],
            BulkEdit_Setting_checked: Object.keys(BulkSetting).map(() => { return false; }),
        });
    }
    BulkEdit_ToggleRemoveSettingModal = () => {
        this.setState({
            BulkEdit_Toggle_RemoveSettingModal: !this.state.BulkEdit_Toggle_RemoveSettingModal,
        });
    }
    BulkEdit_CUD_Setting_ViaApi = async (remove = false) => {

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const { textTitle, textBody, text, urlParam } = GetPostParams({ id: 999 }, remove);
        this.setState({
            BulkEdit_IsUpdating: true,
        });
        useAppService.getState().setModal('', `${textTitle} ${settingTitle}...`, null, AlertMode.Loading);

        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Organizer/QuestionSet/BulkEdit/${urlParam}`;
        // Api/LearningCentre/Organizer/QuestionSet/BulkEdit/{Update|Remove}

        let setting_params = [];
        const setting_keys = Object.values(BulkSetting);
        if (remove === false) {
            for (let i = 0; i < this.state.BulkEdit_Setting_checked.length; i++) {
                if (this.state.BulkEdit_Setting_checked[i])
                    setting_params.push({ key: setting_keys[i], value: this.state.BulkEdit_Setting[i].value });
                else
                    setting_params.push({ key: setting_keys[i], value: null });
            }
        }

        let itemIds = [];
        const list = this.state.List;
        const checkedItems = this.state.BulkEdit_CheckedItems;
        for (let n = 0; n < list.length; n++) {
            if (checkedItems[n])
                itemIds.push(CheckObjectNumber(list[n], ItemProperty.Id));
        }

        const json = JSON.stringify({
            OrganizerId: organizerId,
            AuthorId: authorId,

            BulkItemIds: itemIds.join(','),
            Group: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.Group)].value,
            Subject: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.Subject)].value,
            Remark: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.Remark)].value,
            HiddenRemark: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.HiddenRemark)].value,     //2025.03.20

            Remove: remove,
        });
        if (this.state.isDevMode)
            console.log(`BulkEdit_CUD_Setting_ViaApi (${text}) (postData) =\n` + json);

        // this.setState({ BulkEdit_IsUpdating: false });
        // useAppService.getState().setModal();
        // return null;

        // let data = null;
        let success = false;
        let msg = '';
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: json,
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('BulkEdit_CUD_Setting_ViaApi (response) =\n' + JSON.stringify(data));

                success = CheckObjectBoolean(data, 'success');
                // data = CheckObjectNullValue(data, 'data');

                if (!success)
                    msg = CheckObjectStringEmpty(data, 'message');
            })
            .catch(error => {
                msg = error.message;
                if (this.state.isDevMode)
                    console.log('Error', `api - ${text} (error)\n` + error.message);
            });

        if (success) {
            this.LoadList_ViaApi();
            this.BulkEdit_ToggleEditSettingModal();
            await Delay(500);
            useAppService.getState().setModal();
            await Delay(500);
            useAppService.getState().setModal('', `${settingTitle}(s) have been ${textBody}.`);
            if (remove)
                this.BulkEdit_ToggleRemoveSettingModal();
        }
        else {
            useAppService.getState().setModal('', `Failed to ${text} ${settingTitle.toLowerCase()}(s).<br /><br />` + msg);
        }
        this.setState({
            BulkEdit_IsUpdating: false,
        });
    }
    //#endregion === bulk edit ===

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        return (<div className="">
            <table className="table page-header">
                <tbody>
                    <tr>
                        <td className="left" style={{ flex: 2 }}>
                            <h5>Question Set</h5>
                            {
                                // this.state.PA_Clone_ToMultipleOrganizer === false ||
                                this.state.PA_Upload === false || this.state.PA_Teacher ||
                                    (this.state.OrganizerList.length > 0 && this.state.OrganizerList.length <= 1) ? null :
                                    <Button
                                        variant='outline-primary'
                                        onClick={() => this.Toggle_QuestionSetModal_ToMultipleOrganizers_Clone()}
                                        // disabled={this.state.OrganizerList.length === 0}
                                        disabled={this.state.isLoading || this.state.BulkEdit_CheckedItems.length === 0 ?
                                            true : (this.state.BulkEdit_CheckedItems.includes(true) ? false : true)}
                                    ><b>Clone</b> selected to Multiple Organizers</Button>
                            }
                            {
                                // this.state.PA_Upload_ToMultipleOrganizer === false ||
                                this.state.PA_Upload === false || this.state.PA_Teacher ||
                                    (this.state.OrganizerList.length > 0 && this.state.OrganizerList.length <= 1) ? null :
                                    <Button
                                        variant='outline-primary'
                                        onClick={() => this.Toggle_QuestionSetModal_ToMultipleOrganizers_Upload()}
                                        disabled={this.state.isLoading || this.state.OrganizerList.length === 0}
                                    ><b>Upload</b> new to Multiple Organizers</Button>
                            }
                            {
                                this.state.PA_Update === false ? null :
                                    <Button variant="primary"
                                        onClick={() => this.BulkEdit_ToggleEditSettingModal()}
                                        disabled={this.state.isLoading || this.state.BulkEdit_CheckedItems.length === 0 ? true : (this.state.BulkEdit_CheckedItems.includes(true) ? false : true)}
                                    >Bulk Edit</Button>
                            }
                            <button
                                type="button"
                                className="btn-link"
                                onClick={() => this.LoadList_ViaApi(true)}
                                title="Refresh Question Set list"
                            ><i className="fa fa-refresh" title="Refresh Question Set list"></i></button>
                        </td>
                        {/* <td className="center"></td> */}
                        <td className="right">
                            <Button
                                variant='outline-primary'
                                onClick={() => this.setState({ ShowSearchListModal: true, SearchList_Processing: false, SearchList_Result: null, })}
                                disabled={this.state.isLoading}
                            >Search Question Set</Button>
                            {
                                this.state.PA_Create === false ? null :
                                    <Button
                                        variant='outline-primary'
                                        onClick={() => this.ToggleCreateQuestionSetModal()}
                                        disabled={this.state.isLoading}
                                    >Create</Button>
                            }
                            {
                                this.state.PA_Upload === false ? null :
                                    <Button
                                        variant='outline-primary'
                                        onClick={() => this.ToggleCreateEditUploadQuestionSetModal()}
                                        disabled={this.state.isLoading}
                                    >Upload Question Set</Button>
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='table table-hover table-bordered tbStyle' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
                <thead>
                    <tr>
                        <th width='50' className="pointer" onClick={() => this.state.isLoading || this.state.List.length === 0 ? DoNothing() : this.ToggleItemChecked(-1, this.state.BulkEdit_CheckedItems.findIndex(x => x === false) < 0)}>
                            <input type='checkbox' className={this.state.isLoading || this.state.List.length === 0 ? '' : 'pointer'}
                                checked={this.state.isLoading || this.state.List.length === 0 ? false : !(this.state.BulkEdit_CheckedItems.findIndex(x => x === false) > -1)}
                                readOnly={true} disabled={this.state.isLoading || this.state.List.length === 0}></input>
                            {/* <br />
                            {String(this.state.isLoading)} |
                            {String(this.state.List.length === 0)} |
                            {String(!(this.state.BulkEdit_CheckedItems.findIndex(x => x === false) > -1))} */}
                        </th>
                        {/* <th width='50'>#</th> */}
                        <th className="left">Name</th>
                        <th width='135'>Group</th>
                        <th width='135'>Subject</th>
                        <th width='90'>Questions</th>
                        <th width='85'>Remark (Public)</th>
                        <th width='85'>Remark (Hidden)</th>
                        {/* <th width='170'>Created Date</th> */}
                        <th width='175' hidden={!this.state.IsAdmin}>Created By</th>
                        <th width='155'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.isLoading && !this.state.IsListLoaded ?
                            // <tr><td colSpan='15' align='center'><LoadingIndicator /></td></tr>
                            <tr><td colSpan='15' height={63}><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></td></tr>
                            : this.state.List.length > 0 ?
                                this.ListComponents()
                                : <tr><td colSpan='15' align='center'>list is empty</td></tr>
                    }
                    {
                        this.state.List.length === 0 ? null :
                            PagingComponents(15, this.state.TotalRows, this.state.PageIndex, this.state.PageSize, this.CallbackFunctionForPagingComponents_PageSize, this.CallbackFunctionForPagingComponents_PageIndex)
                    }
                </tbody>
            </table>

            {/* QuestionSet - Delete - Modal */}
            <Modal show={this.state.Show_Delete_QuestionSetModal} onHide={this.ToggleDeleteQuestionSetModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Question Set</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        Question Set Code: <b>{CheckObjectStringEmpty(this.state.TargetQuestionSetModal, 'QuestionSetCode')}</b>
                        <br />Are you sure you want to <b>delete</b> this question set ?
                        <br />The deletion is not reversible.
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ToggleDeleteQuestionSetModal()}>Cancel</Button>
                    &nbsp;&nbsp;
                    <Button variant="primary" onClick={() => this.DeleteThisQuestionSet()}>Confirm</Button>
                </Modal.Footer>
            </Modal>

            {/* QuestionSet - Create / Edit / Upload - Modal */}
            <Modal size='lg' show={this.state.Show_CreateEditUpload_QuestionSetModal} onHide={this.ToggleCreateEditUploadQuestionSetModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create {this.state.Mode_Create_QuestionSetModal ? 'New' : '& Upload'} Question Set</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table width='100%' cellPadding='5'>
                        <tbody>
                            <tr>
                                <td colSpan='3'>
                                    <table cellPadding='5' width='100%' border='0'>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '15%', textAlign: 'right' }}>Name *</td>
                                                <td style={{ width: '50%' }}>
                                                    <input type="text" className="form-control" style={{ width: '100%' }}
                                                        defaultValue={CheckObjectStringEmpty(this.state.QuestionSetModal, 'Name')}
                                                        onChange={(e) => this.SaveDataInput(e.target.value, DataInput.Name)}
                                                    />
                                                </td>
                                                <td rowSpan='2' style={
                                                    this.state.Mode_Create_QuestionSetModal === false ? { display: 'none' }
                                                        : { width: '35%', verticalAlign: 'top', paddingRight: '30px' }
                                                }>
                                                    <table cellPadding='5' width='100%' border='0'>
                                                        <tbody>
                                                            <tr hidden>
                                                                <td style={{ width: '50%', textAlign: 'right' }}>Is Public</td>
                                                                <td style={{ width: '25%' }}>
                                                                    <input type="checkbox" className="form-control"
                                                                        defaultChecked={CheckObjectBoolean(this.state.QuestionSetModal, 'IsPublic')}
                                                                        onChange={(e) => this.SaveDataInput(e.currentTarget.checked, DataInput.IsPublic)}
                                                                    />&nbsp;
                                                                    <OverlayTrigger overlay={<Tooltip><div style={{ textAlign: 'left' }}>Tick this checkbox to show this Question Set to the public or hide it from all, only author or admin(s) who belongs to the same Center are allow to edit.<p style={{ textAlign: 'right' }}>(default: uncheck)</p></div></Tooltip>}>
                                                                        <i className="fa fa-info-circle" style={{ color: 'blue' }}></i>
                                                                    </OverlayTrigger>
                                                                </td>
                                                            </tr>
                                                            <tr hidden>
                                                                <td style={{ width: '50%', textAlign: 'right' }}>Is Private Group</td>
                                                                <td style={{ width: '25%' }}>
                                                                    <input type="checkbox" className="form-control"
                                                                        defaultChecked={CheckObjectBoolean(this.state.QuestionSetModal, 'IsPrivateGroup')}
                                                                        onChange={(e) => this.SaveDataInput(e.currentTarget.checked, DataInput.IsPrivateGroup)}
                                                                    />&nbsp;
                                                                    <OverlayTrigger overlay={<Tooltip><div style={{ textAlign: 'left' }}>Tick this checkbox to continue to customize private group in next step.<p style={{ textAlign: 'right' }}>(default: uncheck)</p></div></Tooltip>}>
                                                                        <i className="fa fa-info-circle" style={{ color: 'blue' }}></i>
                                                                    </OverlayTrigger>
                                                                </td>
                                                            </tr>
                                                            <tr hidden>
                                                                <td style={{ width: '50%', textAlign: 'right' }}>Published</td>
                                                                <td style={{ width: '25%', }}>
                                                                    <input type="checkbox" className="form-control"
                                                                        defaultChecked={CheckObjectBoolean(this.state.QuestionSetModal, 'Published')}
                                                                        onChange={(e) => this.SaveDataInput(e.currentTarget.checked, DataInput.Published)}
                                                                    />&nbsp;
                                                                    <OverlayTrigger overlay={<Tooltip><div style={{ textAlign: 'left' }}>Tick this checkbox to publish this Question Set. Controls the accessability of this Question Set.<p style={{ textAlign: 'right' }}>(default: checked)</p></div></Tooltip>}>
                                                                        <i className="fa fa-info-circle" style={{ color: 'blue' }}></i>
                                                                    </OverlayTrigger>
                                                                </td>
                                                            </tr>
                                                            <tr hidden>
                                                                <td style={{ width: '50%', textAlign: 'right' }}>Display Order</td>
                                                                <td style={{ width: '25%' }}>
                                                                    <input type="number" className="form-control" style={{ width: '100%' }}
                                                                        defaultValue={CheckObjectNumber(this.state.QuestionSetModal, 'DisplayOrder')}
                                                                        onChange={(e) => this.SaveDataInput(e.target.value, DataInput.DisplayOrder)} />
                                                                </td>
                                                            </tr>
                                                            <tr hidden={this.state.Mode_Create_QuestionSetModal === false}>
                                                                <td style={{ width: '50%', textAlign: 'right' }}>Total Question *</td>
                                                                <td style={{ width: '25%' }}>
                                                                    <input type="number" className="form-control" style={{ width: '100%' }}
                                                                        defaultValue={CheckObjectNumber(this.state.QuestionSetModal, 'TotalQuestion')}
                                                                        onChange={(e) => this.SaveDataInput(e.target.value, DataInput.TotalQuestion)} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '15%', textAlign: 'right', verticalAlign: 'top', }}>Remark</td>
                                                <td style={{ width: '35%' }}>
                                                    <textarea
                                                        ref={this.NQSet_Remark}
                                                        rows="4" cols="50"
                                                        className="form-control"
                                                        placeholder={'...remark...'}
                                                        onChange={(e) => this.SaveDataInput(e.target.value, DataInput.Remark)}></textarea>
                                                    <Row>
                                                        <Col style={{ fontWeight: 'bold', fontSize: '13px', color: 'red' }}>{CheckObjectStringEmpty(this.state.QuestionSetModal, 'Remark').length >= 250 ? '(Max charactors reached.)' : ''}</Col>
                                                        <Col style={{ textAlign: 'right', fontSize: '12px', color: 'gray' }}>{'(' + CheckObjectStringEmpty(this.state.QuestionSetModal, 'Remark').length + '/250 charactors remaining.)'}</Col>
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '15%', textAlign: 'right' }}>Group *</td>
                                                <td style={{ width: '35%' }}>
                                                    <ReactSelect
                                                        options={useAppService.getState().groupOptions}
                                                        placeholder={
                                                            CheckObjectNullValue(this.state.QuestionSetModal, 'Group') !== null ?
                                                                CheckObjectStringEmpty(this.state.QuestionSetModal.Group, 'label')
                                                                : Locale("not-specify-group", this.state.locale)
                                                        }
                                                        value={
                                                            CheckObjectNullValue(this.state.QuestionSetModal, 'Group') === null ? null :
                                                                CheckObjectStringEmpty(this.state.QuestionSetModal.Group, 'value')
                                                        }
                                                        onChange={(option) => this.SaveDataInput(option, DataInput.Group)} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '15%', textAlign: 'right' }}>Subject *</td>
                                                <td style={{ width: '35%' }}>
                                                    <ReactSelect
                                                        options={useAppService.getState().subjectOptions}
                                                        placeholder={
                                                            CheckObjectNullValue(this.state.QuestionSetModal, 'Subject') !== null ?
                                                                CheckObjectStringEmpty(this.state.QuestionSetModal.Subject, 'label')
                                                                : Locale("not-specify-subject", this.state.locale)
                                                        }
                                                        value={
                                                            CheckObjectNullValue(this.state.QuestionSetModal, 'Subject') === null ? null :
                                                                CheckObjectStringEmpty(this.state.QuestionSetModal.Subject, 'value')
                                                        }
                                                        onChange={(option) => this.SaveDataInput(option, DataInput.Subject)} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            {
                                this.state.Mode_Create_QuestionSetModal ? null :
                                    <>
                                        <tr><td colSpan='3'><hr /></td></tr>
                                        <tr>
                                            <td colSpan='3'>
                                                <button
                                                    className='link-button'
                                                    onClick={() => {
                                                        let link40 = "window.open('https://ikeynew.blob.core.windows.net/ikeykidz/quizbank/TEMPLATE_QS_40.2.xlsx', '_new')";
                                                        let link100 = "window.open('https://ikeynew.blob.core.windows.net/ikeykidz/quizbank/TEMPLATE_QS_100.2.xlsx', '_new')";
                                                        let linkSample = "window.open('https://ikeynew.blob.core.windows.net/ikeykidz/quizbank/TEMPLATE_QS_SAMPLE.xlsx', '_new')";
                                                        let htmlText = '<table class="table table-bordered" cellpadding="5" width="100%" style="text-align:center;"><thead><tr><th>Question Amount</th><th>Spreadsheet Template</th></tr></thead><tbody>';
                                                        htmlText += '<tr><td>40</td><td><button class="link-button" onClick="' + link40 + '">download</button></td></tr>';
                                                        htmlText += '<tr><td>100</td><td><button class="link-button" onClick="' + link100 + '">download</button></td></tr>';
                                                        htmlText += '</tbody></table><br />';
                                                        htmlText += '<table class="table table-bordered" cellpadding="5" width="100%" style="text-align:center;"><thead><tr><th>Spreadsheet Implementation Sample</th></tr></thead><tbody>';
                                                        htmlText += '<tr><td><button class="link-button" onClick="' + linkSample + '">download sample file</button></td></tr>';
                                                        htmlText += '</tbody></table>';
                                                        htmlText += '<br /><ul style="list-style-type: circle"><li>for other question amounts, please download any file, then fill-in or remove the remaining questions if needed.</li></ul>'
                                                        useAppService.getState().setModal('Question Sets Template', htmlText);
                                                    }}
                                                >Download & use the provided spreadsheet template file</button> for upload purpose.
                                                <p>
                                                    Using other files with different column name or format may cause errors on data verification on the questions, or may further create unforseen bugs that may greatly affecting the usability or accessability of the quiz system on your account.
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>
                                                <input type="file" onChange={this.onUploadFileChange} style={{ width: '100%' }}
                                                    disabled={this.state.PA_Upload === false} />*
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>&nbsp;
                                                <span>Continue to upload this file ?</span>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </>
                            }
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ToggleCreateEditUploadQuestionSetModal()}>Cancel</Button>
                    &nbsp;&nbsp;
                    <Button variant="secondary" onClick={() => this.state.Mode_Create_QuestionSetModal ? this.ResetCreateEditQuestionSetModal() : this.ResetUploadQuestionSetModal()}>Reset</Button>
                    &nbsp;&nbsp;
                    {/* <Button variant="primary" onClick={this.UploadNewQuestionSet}>Upload</Button> */}
                    <Button
                        variant="primary"
                        onClick={() => this.state.Mode_Create_QuestionSetModal ? this.CreateQuestionSetTemplate() : this.ProcessQuestionSetModal()}
                        disabled={this.state.IsUploadConditionsFullfilled === false || this.state.PA_Upload === false || this.state.PA_Create === false}
                    >{this.state.Mode_Create_QuestionSetModal ? 'Create' : 'Upload'}</Button>
                </Modal.Footer>
            </Modal>

            {/* QuestionSet - Process Uploaded - Modal */}
            <Modal show={this.state.Show_ProcessUploaded_QuestionSetModal}
                onHide={
                    this.state.UploadStatus === UploadState.ConvertFailed ||
                        this.state.UploadStatus === UploadState.Success ||
                        this.state.UploadStatus === UploadState.Failed
                        ? this.ToggleProcessUploadedQuestionSetModal : DoNothing
                }
                centered
                backdrop='static'
                keyboard='false'
                dialogClassName='alert-dialog-bordered'
                size={this.state.UploadStatusModalSize}
            >
                <Modal.Header
                // closeButton={
                //     this.state.UploadStatus === UploadState.Success ||
                //     this.state.UploadStatus === UploadState.Failed
                // }
                >
                    <Modal.Title style={{ fontSize: 20 }}>{
                        this.state.UploadStatus === UploadState.ConvertFailed ||
                            this.state.UploadStatus === UploadState.Success ||
                            this.state.UploadStatus === UploadState.Failed
                            ? 'Result' : 'Processing...'
                    }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.UploadStatusMessage()}
                </Modal.Body>
                <Modal.Footer
                    style={{ display: 'flex', flexDirection: 'row', gap: 10 }}
                    hidden={
                        this.state.UploadStatus === UploadState.ConvertFailed ||
                            this.state.UploadStatus === UploadState.Success ||
                            this.state.UploadStatus === UploadState.Failed ? false : true
                    }>
                    {/* <Button variant="secondary" onClick={this.ToggleProcessUploadedQuestionSetModal}>Close</Button> */}
                    {/* &nbsp;&nbsp;
                        <Button variant="primary" onClick={this.ToggleProcessUploadedQuestionSetModal}>Confirm</Button> */}
                    {/* <Button variant="secondary" onClick={() => this.ReloadQuestionSetList()}>Close</Button> */}
                    <Button variant="secondary" onClick={() => {
                        this.ReloadQuestionSetList(!this.state.Show_ToMultipleOrganizers_QuestionSetModal_Clone);
                        // if (this.state.Show_ToMultipleOrganizers_QuestionSetModal_Clone)
                        //     this.CloseCloneModalAndStatusModal();
                        // else
                        //     this.ReloadQuestionSetList();
                    }}>Close</Button>
                    {
                        this.state.SelectedOrganizers.length > 0 ||
                            this.state.UploadStatus === UploadState.ConvertFailed ||
                            this.state.UploadStatus === UploadState.Failed ? null :
                            <Button
                                variant="primary"
                                onClick={() => this.GotoEditQuestionSetPage(this.state.QuestionSet_UniqueId)}
                                disabled={this.state.QuestionSet_UniqueId === ''}
                            // hidden={
                            //     this.state.SelectedOrganizers.length > 0 ||
                            //         this.state.UploadStatus === UploadState.ConvertFailed ||
                            //         this.state.UploadStatus === UploadState.Failed ? true : false
                            // }
                            >View & Edit</Button>
                    }
                </Modal.Footer>
            </Modal>

            {/* QuestionSet - Search Question Set - Modal */}
            <Modal
                show={this.state.ShowSearchListModal}
                onHide={() => this.state.SearchList_Processing ? DoNothing() : this.Toggle_Search_QuestionSetModal()}
                centered
            >
                <Modal.Header closeButton={this.state.SearchList_Processing === false}>
                    <Modal.Title>{this.state.SearchList_Processing ? 'Searching...' : 'Search Question Set'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.SearchList_Processing ?
                            <ProgressBar animated now={100} className='progressbar1' />
                            :
                            <>
                                {/* <p>Search for Question Set:</p> */}
                                <table width='100%' cellPadding='5' cellSpacing='5' border='0' style={{ borderColor: 'grey', marginBottom: 0, }}>
                                    <tbody>
                                        <tr>
                                            <td>Name</td>
                                            <td>
                                                <input type="text" className='form-control' style={{ width: '100%' }}
                                                    value={this.state.SearchQsSet_ByName}
                                                    onChange={(e) => this.SaveSearchOptions(e.target.value, DataInput.SearchQsSet_ByName)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Group</td>
                                            <td>
                                                <ReactSelect
                                                    options={useAppService.getState().groupOptions}
                                                    placeholder={
                                                        this.state.SearchQsSet_ByGroup !== null ?
                                                            this.state.SearchQsSet_ByGroup.label
                                                            : Locale("not-specify-group", this.props.Locale)
                                                    }
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            neutral50: 'gray',  // placeholder color
                                                        },
                                                    })}
                                                    // value={this.state.SearchQsSet_ByGroup !== null ? this.state.SearchQsSet_ByGroup.value : null}
                                                    onChange={(option) => this.SaveSearchOptions(option, DataInput.SearchQsSet_ByGroup)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Subject</td>
                                            <td>
                                                <ReactSelect
                                                    options={useAppService.getState().subjectOptions}
                                                    placeholder={
                                                        this.state.SearchQsSet_BySubject !== null ?
                                                            this.state.SearchQsSet_BySubject.label
                                                            : Locale("not-specify-subject", this.props.Locale)
                                                    }
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            neutral50: 'gray',  // placeholder color
                                                        },
                                                    })}
                                                    // value={this.state.SearchQsSet_BySubject !== null ? this.state.SearchQsSet_BySubject.value : null}
                                                    onChange={(option) => this.SaveSearchOptions(option, DataInput.SearchQsSet_BySubject)}
                                                />
                                            </td>
                                        </tr>
                                        <tr hidden={true}>
                                            <td>Max Result</td>
                                            <td>
                                                <input type="number" className="form-control" style={{ width: '100%' }}
                                                    defaultValue={this.state.SearchQsSet_MaxQtyShow}
                                                    onChange={(e) => this.SaveSearchOptions(Number(e.target.value), DataInput.SearchQsSet_MaxQtyShow)}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </>
                    }
                </Modal.Body>
                {
                    !this.state.SearchList_Processing ?
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.Toggle_Search_QuestionSetModal()}>Cancel</Button>
                            &nbsp;
                            <Button variant="secondary" onClick={() => this.ResetSearchQsSetParams()}>Reset</Button>
                            &nbsp;
                            <Button variant="primary" onClick={() => this.SearchQuestionSetByConditions()} disabled={this.state.IsSearchQsSetConditionsValid === false}>Search</Button>
                        </Modal.Footer>
                        : null
                }
            </Modal>

            {/* QuestionSet - Select Question Set - Modal */}
            <Modal
                size='xl'
                show={this.state.ShowSelectQuestionSetModal}
                onHide={() => this.Toggle_Select_QuestionSetModal()}
                centered
            // dialogClassName='alert-dialog-bordered'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Question Set from list below:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <p>Select a Question Set from list:</p> */}
                    <table className='table table-hover tbStyle' width='100%' cellPadding='5' cellSpacing='5'
                        style={{
                            border: '1px solid gray', marginTop: 0, marginBottom: 0,
                            borderTop: 'inset', borderBottomStyle: 'groove',
                        }}>
                        <tbody>
                            {this.QuestionSetQueryResultListComponent()}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.SearchAgain_SelectQuestionSet()}>Search Again</Button>
                    &nbsp;&nbsp;
                    <Button variant="secondary" onClick={() => this.Toggle_Select_QuestionSetModal()}>Cancel</Button>
                    &nbsp;&nbsp;
                    <Button variant="primary" onClick={() => this.Confirm_SelectOnThisQuestionSet()} disabled={this.state.SearchQsSet_QuestionSet_Selected === null}>ReactSelect</Button>
                </Modal.Footer>
            </Modal>

            {/* QuestionSet - Upload to Multiple Organizers - Modal */}
            <Modal size='xl' show={this.state.Show_ToMultipleOrganizers_QuestionSetModal_Upload} onHide={this.Toggle_QuestionSetModal_ToMultipleOrganizers_Upload} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Upload New Question Set to Multiple Organizers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table width='100%' cellPadding='5'>
                        <tbody>
                            <tr>
                                <td colSpan='3'>
                                    <div style={{ display: 'flex', gap: 15, }}>
                                        <div style={{ border: '1px solid lightgrey', borderRadius: 7, padding: 10, paddingTop: 5, }}>
                                            <table cellPadding='5' width='100%' border='0' style={{ height: 'fit-content', }}>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={15} style={{ textAlign: 'center' }} > Question Set (Setting)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: '15%', textAlign: 'right' }}>Name *</td>
                                                        <td style={{ width: '35%' }}>
                                                            <input type="text" className="form-control" style={{ width: '100%' }}
                                                                defaultValue={CheckObjectStringEmpty(this.state.QuestionSetModal, 'Name')}
                                                                onChange={(e) => this.SaveDataInput(e.target.value, DataInput.Name)}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '15%', textAlign: 'right', verticalAlign: 'top', }}>Remark (Public)</td>
                                                        <td style={{ width: '35%' }}>
                                                            <textarea
                                                                // ref={this.NQSet_Remark}
                                                                rows="4" cols="50"
                                                                className="form-control"
                                                                placeholder={'...remark...'}
                                                                defaultValue={CheckObjectStringEmpty(this.state.QuestionSetModal, 'Remark')}
                                                                onChange={(e) => this.SaveDataInput(e.target.value, DataInput.Remark)}></textarea>
                                                            <Row>
                                                                <Col style={{ fontWeight: 'bold', fontSize: '13px', color: 'red' }}>{CheckObjectStringEmpty(this.state.QuestionSetModal, 'Remark').length >= 250 ? '(Max charactors reached.)' : ''}</Col>
                                                                <Col style={{ textAlign: 'right', fontSize: '12px', color: 'gray' }}>{'(' + CheckObjectStringEmpty(this.state.QuestionSetModal, 'Remark').length + '/250 charactors remaining.)'}</Col>
                                                            </Row>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '15%', textAlign: 'right' }}>Group *</td>
                                                        <td style={{ width: '35%' }}>
                                                            <ReactSelect
                                                                options={useAppService.getState().groupOptions}
                                                                placeholder={
                                                                    this.state.QuestionSetModal === null || CheckObjectNullValue(this.state.QuestionSetModal, 'Group') === null ?
                                                                        Locale("not-specify-group", this.state.locale)
                                                                        :
                                                                        CheckObjectStringEmpty(this.state.QuestionSetModal['Group'], 'label')
                                                                }
                                                                value={
                                                                    this.state.QuestionSetModal === null || CheckObjectNullValue(this.state.QuestionSetModal, 'Group') === null ?
                                                                        null
                                                                        :
                                                                        CheckObjectStringEmpty(this.state.QuestionSetModal['Group'], 'value')
                                                                }
                                                                onChange={(option) => this.SaveDataInput(option, DataInput.Group)} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '15%', textAlign: 'right' }}>Subject *</td>
                                                        <td style={{ width: '35%' }}>
                                                            <ReactSelect
                                                                options={useAppService.getState().subjectOptions}
                                                                placeholder={
                                                                    this.state.QuestionSetModal === null || CheckObjectNullValue(this.state.QuestionSetModal, 'Subject') === null ?
                                                                        Locale("not-specify-subject", this.state.locale)
                                                                        :
                                                                        CheckObjectStringEmpty(this.state.QuestionSetModal['Subject'], 'label')
                                                                }
                                                                value={
                                                                    this.state.QuestionSetModal === null || CheckObjectNullValue(this.state.QuestionSetModal, 'Subject') === null ?
                                                                        null
                                                                        :
                                                                        CheckObjectStringEmpty(this.state.QuestionSetModal['Subject'], 'value')
                                                                }
                                                                onChange={(option) => this.SaveDataInput(option, DataInput.Subject)} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '15%', textAlign: 'right', verticalAlign: 'top', }}>Remark (Hidden)</td>
                                                        <td style={{ width: '35%' }}>
                                                            <textarea
                                                                // ref={this.NQSet_Remark}
                                                                rows="4" cols="50"
                                                                className="form-control"
                                                                placeholder={'...remark...'}
                                                                defaultValue={CheckObjectStringEmpty(this.state.QuestionSetModal, 'HiddenRemark')}
                                                                onChange={(e) => this.SaveDataInput(e.target.value, DataInput.HiddenRemark)}></textarea>
                                                            <Row>
                                                                <Col style={{ textAlign: 'right', fontSize: '12px', color: 'gray' }}>{'(' + CheckObjectStringEmpty(this.state.QuestionSetModal, 'HiddenRemark').length + ' charactors.)'}</Col>
                                                                {/* <Col style={{ fontWeight: 'bold', fontSize: '13px', color: 'red' }}>{CheckObjectStringEmpty(this.state.QuestionSetModal, 'HiddenRemark').length >= 250 ? '(Max charactors reached.)' : ''}</Col> */}
                                                                {/* <Col style={{ textAlign: 'right', fontSize: '12px', color: 'gray' }}>{'(' + CheckObjectStringEmpty(this.state.QuestionSetModal, 'HiddenRemark').length + '/250 charactors remaining.)'}</Col> */}
                                                            </Row>
                                                        </td>
                                                    </tr>

                                                    <tr hidden>
                                                        <td style={{ width: '15%', textAlign: 'right' }}>Is Public</td>
                                                        <td style={{ width: '35%' }}>
                                                            <input type="checkbox" className="form-control"
                                                                defaultChecked={CheckObjectBoolean(this.state.QuestionSetModal, 'IsPublic')}
                                                                readOnly={true}
                                                                onClick={(e) => this.SaveDataInput(e.currentTarget.checked, DataInput.IsPublic)}
                                                            />&nbsp;
                                                            <OverlayTrigger overlay={<Tooltip><div style={{ textAlign: 'left' }}>Tick this checkbox to show this Question Set to the public or hide it from all, only author or admin(s) who belongs to the same Center are allow to edit.<p style={{ textAlign: 'right' }}>(default: uncheck)</p></div></Tooltip>}>
                                                                <i className="fa fa-info-circle" style={{ color: 'blue' }}></i>
                                                            </OverlayTrigger>
                                                        </td>
                                                    </tr>
                                                    <tr hidden>
                                                        <td style={{ width: '15%', textAlign: 'right' }}>Is Private Group</td>
                                                        <td style={{ width: '35%' }}>
                                                            <input type="checkbox" className="form-control"
                                                                defaultChecked={CheckObjectBoolean(this.state.QuestionSetModal, 'IsPrivateGroup')}
                                                                readOnly={true}
                                                                onClick={(e) => this.SaveDataInput(e.currentTarget.checked, DataInput.IsPrivateGroup)}
                                                            />&nbsp;
                                                            <OverlayTrigger overlay={<Tooltip><div style={{ textAlign: 'left' }}>Tick this checkbox to continue to customize private group in next step.<p style={{ textAlign: 'right' }}>(default: uncheck)</p></div></Tooltip>}>
                                                                <i className="fa fa-info-circle" style={{ color: 'blue' }}></i>
                                                            </OverlayTrigger>
                                                        </td>
                                                    </tr>
                                                    <tr hidden>
                                                        <td style={{ width: '15%', textAlign: 'right' }}>Published</td>
                                                        <td style={{ width: '35%', }}>
                                                            <input type="checkbox" className="form-control"
                                                                defaultChecked={CheckObjectBoolean(this.state.QuestionSetModal, 'Published')}
                                                                readOnly={true}
                                                                onClick={(e) => this.SaveDataInput(e.currentTarget.checked, DataInput.Published)}
                                                            />&nbsp;
                                                            <OverlayTrigger overlay={<Tooltip><div style={{ textAlign: 'left' }}>Tick this checkbox to publish this Question Set. Controls the accessability of this Question Set.<p style={{ textAlign: 'right' }}>(default: checked)</p></div></Tooltip>}>
                                                                <i className="fa fa-info-circle" style={{ color: 'blue' }}></i>
                                                            </OverlayTrigger>
                                                        </td>
                                                    </tr>
                                                    <tr hidden>
                                                        <td style={{ width: '15%', textAlign: 'right' }}>Display Order</td>
                                                        <td style={{ width: '35%' }}>
                                                            <input type="number" className="form-control" style={{ width: '100%' }}
                                                                value={CheckObjectNumber(this.state.QuestionSetModal, 'DisplayOrder')}
                                                                onChange={(e) => this.SaveDataInput(e.target.value, DataInput.DisplayOrder)} />
                                                        </td>
                                                    </tr>
                                                    <tr hidden>
                                                        <td style={{ width: '15%', textAlign: 'right' }}>Total Question *</td>
                                                        <td style={{ width: '35%' }}>
                                                            <input type="number" className="form-control" style={{ width: '100%' }}
                                                                value={CheckObjectNumber(this.state.QuestionSetModal, 'TotalQuestion')}
                                                                onChange={(e) => this.SaveDataInput(e.target.value, DataInput.TotalQuestion)} />
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: 15, width: '50%' }}>
                                            {
                                                Array.isArray(this.state.CategoryOptions) && this.state.CategoryOptions.length > 0 ?
                                                    <div style={{ border: '1px solid lightgrey', borderRadius: 7, padding: 10, paddingTop: 5, }}>
                                                        <table className="table table-hover no-border-style" cellPadding='5' width='100%' border='0' style={{ margin: 0, borderStyle: 'hidden' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th width='42px' style={{ textAlign: 'center', color: 'green' }}>
                                                                        {
                                                                            this.state.SelectedCategory === null ? '' :
                                                                                this.state.SelectedCategory.id === 0 ?
                                                                                    (this.state.SelectedOrganizers.length > 0 ? this.state.SelectedOrganizers.length : '') :
                                                                                    Array.isArray(this.state.OrganizerList) === false ? '' :
                                                                                        // this.state.OrganizerList.select(x=>{ x.OrganizerId, x.CategoryList}).where(x=>x.CategoryId === this.state.SelectedCategory.id).where(x=>x.OrganizerId.match(this.state.SelectedOrganizers)).length
                                                                                        // this.state.OrganizerList.filter(x => x.CategoryList.some(category => category.CategoryId === this.state.SelectedCategory.id)).filter(x => this.state.SelectedOrganizers.includes(x.OrganizerId)).length
                                                                                        this.state.OrganizerList.reduce((count, organizer) => {
                                                                                            const hasCategory = organizer.CategoryList.some(category => category.CategoryId === this.state.SelectedCategory.id);
                                                                                            const isSelected = this.state.SelectedOrganizers.includes(organizer.OrganizerId);
                                                                                            return hasCategory && isSelected ? count + 1 : count;
                                                                                        }, 0)
                                                                        }
                                                                    </th>
                                                                    <th>Category</th>
                                                                    {/* <th style={{ paddingLeft: 50 }}>Category</th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={2}>{this.OrganizerCategoryComponent()}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    : null
                                            }
                                            <div style={{ border: '1px solid lightgrey', borderRadius: 7, padding: 10, paddingTop: 5, height: 450, overflowY: 'scroll' }}>
                                                <table className="table table-hover table-bordered no-border-style" cellPadding='5' width='100%' border='0' style={{ borderTopStyle: 'hidden' }}>
                                                    <thead>
                                                        <tr>
                                                            <th width='42px' style={{ textAlign: 'center', color: 'blue' }}>{this.state.SelectedOrganizers.length > 0 ? this.state.SelectedOrganizers.length : ''}</th>
                                                            <th>Organizer(s)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>{this.OrganizerListComponent()}</tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            {
                                this.state.Mode_Create_QuestionSetModal ? null :
                                    <>
                                        <tr><td colSpan='3'><hr /></td></tr>
                                        <tr>
                                            <td colSpan='3'>
                                                <button
                                                    className='link-button'
                                                    onClick={() => {
                                                        let link40 = "window.open('https://ikeynew.blob.core.windows.net/ikeykidz/quizbank/TEMPLATE_QS_40.2.xlsx', '_new')";
                                                        let link100 = "window.open('https://ikeynew.blob.core.windows.net/ikeykidz/quizbank/TEMPLATE_QS_100.2.xlsx', '_new')";
                                                        let linkSample = "window.open('https://ikeynew.blob.core.windows.net/ikeykidz/quizbank/TEMPLATE_QS_SAMPLE.xlsx', '_new')";
                                                        let htmlText = '<table class="table table-bordered" cellpadding="5" width="100%" style="text-align:center;"><thead><tr><th>Question Amount</th><th>Spreadsheet Template</th></tr></thead><tbody>';
                                                        htmlText += '<tr><td>40</td><td><button class="link-button" onClick="' + link40 + '">download</button></td></tr>';
                                                        htmlText += '<tr><td>100</td><td><button class="link-button" onClick="' + link100 + '">download</button></td></tr>';
                                                        htmlText += '</tbody></table><br />';
                                                        htmlText += '<table class="table table-bordered" cellpadding="5" width="100%" style="text-align:center;"><thead><tr><th>Spreadsheet Implementation Sample</th></tr></thead><tbody>';
                                                        htmlText += '<tr><td><button class="link-button" onClick="' + linkSample + '">download sample file</button></td></tr>';
                                                        htmlText += '</tbody></table>';
                                                        htmlText += '<br /><ul style="list-style-type: circle"><li>for other question amounts, please download any file, then fill-in or remove the remaining questions if needed.</li></ul>'
                                                        useAppService.getState().setModal('Question Sets Template', htmlText);
                                                    }}
                                                >Download & use the provided spreadsheet template file</button> for upload purpose.
                                                <p>
                                                    Using other files with different column name or format may cause errors on data verification on the questions, or may further create unforseen bugs that may greatly affecting the usability or accessability of the quiz system on your account.
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>
                                                <input type="file" onChange={this.onUploadFileChange} style={{ width: '100%' }}
                                                    disabled={this.state.PA_Upload === false} />*
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>&nbsp;
                                                <span>Continue to upload this file ?</span>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </>
                            }
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer style={{ gap: 5 }}>
                    <Button variant="secondary" onClick={() => this.Toggle_QuestionSetModal_ToMultipleOrganizers_Upload()}>Cancel</Button>
                    <Button variant="secondary" onClick={() => this.Reset_QuestionSetModal_ToMultipleOrganizers_Upload()}>Clear</Button>
                    <Button
                        variant="primary"
                        // onClick={() => this.Process_QuestionSetModal_UploadForMultipleOrganizers()}
                        onClick={() => this.ProcessQuestionSetModal()}
                        disabled={
                            this.state.IsUploadConditionsFullfilled === false
                            || this.state.PA_Upload === false
                            || this.state.PA_Create === false
                            || this.state.SelectedOrganizers.length === 0
                        }
                    >Upload</Button>
                </Modal.Footer>
            </Modal>

            {/* QuestionSet - Clone to Multiple Organizers - Modal */}
            <Modal size='lg' show={this.state.Show_ToMultipleOrganizers_QuestionSetModal_Clone} onHide={this.Toggle_QuestionSetModal_ToMultipleOrganizers_Clone} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Clone Selected Question Set(s) to Multiple Organizers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
                        <div style={{ border: '1px solid lightgrey', borderRadius: 7, padding: 10, paddingTop: 5, height: 250, overflowY: 'scroll' }}>
                            <table className="table table-hover table-bordered tbStyle no-border-style" cellPadding='5' width='100%' border='0' style={{ borderTopStyle: 'hidden' }}>
                                <thead>
                                    <tr>
                                        <th width={42}>#</th>
                                        <th style={{ textAlign: 'left' }}>Selected Question Set(s)</th>
                                        <th width={75}>Group</th>
                                        <th width={75}>Subject</th>
                                        <th width={75}>Questions</th>
                                        <th width={75}>Remark</th>
                                    </tr>
                                </thead>
                                <tbody>{this.SelectedQuestionSetListComponent()}</tbody>
                            </table>
                        </div>
                        {
                            Array.isArray(this.state.CategoryOptions) && this.state.CategoryOptions.length > 0 ?
                                <div style={{ border: '1px solid lightgrey', borderRadius: 7, padding: 10, paddingTop: 5, }}>
                                    <table className="table table-hover no-border-style" cellPadding='5' width='100%' border='0' style={{ margin: 0, borderStyle: 'hidden' }}>
                                        <thead>
                                            <tr>
                                                <th width='42px' style={{ textAlign: 'center', color: 'green' }}>
                                                    {
                                                        this.state.SelectedCategory === null ? '' :
                                                            this.state.SelectedCategory.id === 0 ?
                                                                (this.state.SelectedOrganizers.length > 0 ? this.state.SelectedOrganizers.length : '') :
                                                                Array.isArray(this.state.OrganizerList) === false ? '' :
                                                                    this.state.OrganizerList.reduce((count, organizer) => {
                                                                        const hasCategory = organizer.CategoryList.some(category => category.CategoryId === this.state.SelectedCategory.id);
                                                                        const isSelected = this.state.SelectedOrganizers.includes(organizer.OrganizerId);
                                                                        return hasCategory && isSelected ? count + 1 : count;
                                                                    }, 0)
                                                    }
                                                </th>
                                                <th>Category</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan={2}>{this.OrganizerCategoryComponent()}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                : null
                        }
                        <div style={{ border: '1px solid lightgrey', borderRadius: 7, padding: 10, paddingTop: 5, height: 450, overflowY: 'scroll' }}>
                            <table className="table table-hover table-bordered no-border-style" cellPadding='5' width='100%' border='0' style={{ borderTopStyle: 'hidden' }}>
                                <thead>
                                    <tr>
                                        <th width='42px' style={{ textAlign: 'center', color: 'blue' }}>{this.state.SelectedOrganizers.length > 0 ? this.state.SelectedOrganizers.length : ''}</th>
                                        <th>Organizer(s)</th>
                                    </tr>
                                </thead>
                                <tbody>{this.OrganizerListComponent(true)}</tbody>
                            </table>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ gap: 5 }}>
                    <Button variant="secondary" onClick={() => this.Toggle_QuestionSetModal_ToMultipleOrganizers_Clone()}>Cancel</Button>
                    <Button variant="secondary" onClick={() => this.Reset_QuestionSetModal_ToMultipleOrganizers_Clone()}>Clear</Button>
                    <Button
                        variant="primary"
                        // onClick={() => this.ProcessQuestionSetModal()}
                        onClick={() => this.CloneSelectedQuestionSetsToMultipleOrganizers_ViaAPI()}
                        disabled={
                            // this.state.IsUploadConditionsFullfilled === false ||
                            this.state.PA_Upload === false ||
                            this.state.PA_Create === false ||
                            this.state.SelectedOrganizers.length === 0
                        }
                    >Clone</Button>
                </Modal.Footer>
            </Modal>

            {/* Setting - (BULK) Edit / Update - Modal */}
            <Modal show={this.state.BulkEdit_Toggle_EditSettingModal}
                onHide={() => this.BulkEdit_ToggleEditSettingModal()}
                centered
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Bulk Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body className="setting-bulk-parent">
                    {this.BulkEdit_SettingModalComponent()}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger"
                        onClick={() => this.BulkEdit_ToggleRemoveSettingModal()}
                        style={{ position: "absolute", left: 0, marginLeft: 15 }}
                        disabled={this.state.BulkEdit_IsUpdating || (this.state.isSuperAdmin ? false : this.state.PA_Delete === false)}
                    >Bulk Remove</Button>
                    <Button variant="secondary" onClick={() => this.BulkEdit_ToggleEditSettingModal()} disabled={this.state.BulkEdit_IsUpdating}>Cancel</Button>
                    <Button variant="secondary" onClick={async () => {
                        this.BulkEdit_ToggleEditSettingModal(); //close.
                        await Delay(200);
                        this.BulkEdit_ToggleEditSettingModal(); //open again.
                    }} disabled={this.state.BulkEdit_IsUpdating}>Reset</Button>
                    <Button variant="primary" onClick={() => this.BulkEdit_CUD_Setting_ViaApi()} disabled={this.state.BulkEdit_IsUpdating || this.state.BulkEdit_Setting_checked.indexOf(true) < 0}>Bulk Update</Button>
                </Modal.Footer>
            </Modal >

            {/* Setting - (BULK) Remove (Confirm) - Modal */}
            <Modal show={this.state.BulkEdit_Toggle_RemoveSettingModal}
                onHide={() => this.BulkEdit_ToggleRemoveSettingModal()}
                centered
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Bulk Remove</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you sure you want to remove these items ?</p>
                    <p>If so, click confirm to continue.</p>
                    <p><i><b>Caution:</b> Once removed, they will not be able to recover back.</i></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.BulkEdit_ToggleRemoveSettingModal()}>Cancel</Button>
                    <Button variant="primary"
                        onClick={() => this.BulkEdit_CUD_Setting_ViaApi(true)}
                        disabled={this.state.BulkEdit_IsUpdating}
                    >Confirm</Button>
                </Modal.Footer>
            </Modal >

            <PreviewQuestionSetComponent ref={this.Ref_PreviewQuestionSetComponent} />
            <QuestionSetComponent ref={this.Ref_QuestionSetComponent} />
        </div >);
    }
}
