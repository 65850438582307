import React from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { Button, Modal, ProgressBar, Tab, Tabs } from "react-bootstrap";
import moment from "moment";
import ReactSelect from "react-select";
// import { collection, doc, onSnapshot, query, setDoc, where } from "firebase/firestore";

// import { firestore } from "../../utilities/Firebase";
import { GlobalSetting, LayoutScreen, PermissionAccessType, ReportStages, getMenuLink } from "../../utilities/GlobalSetting";
import { useGlobal } from "../../utilities/GlobalVariables";
import { useAppService } from "../../services/AppService";
import { PermissionAccess, CapitalizeJsonKeys, CheckBoolean, CheckNullValue, CheckNumber, CheckObjectBoolean, CheckObjectNullValue, CheckObjectNumber, CheckObjectStringEmpty, CheckStringEmpty, Delay, DoNothing, GetPropIds, PagingComponents, TriggerDownloadFile } from "../../utilities/GlobalFunctions";
import { AlertMode } from "../AlertComponent";

// let ProcessingMessage_listenToNode_StudentStatisticReport = null;

const SearchConditionOptions = {
    Title: 'Title',
    DateBegin: 'DateBegin',
    DateEnd: 'DateEnd',
    Groups: 'Groups',
    Classrooms: 'Classrooms',
};

const Default_SearchConditions = {
    Title: '',
    DateBegin: '',
    DateEnd: '',
    Groups: null,
    Classrooms: null,
};

const Default_SearchSettings = {
    GroupOptions: [],
    ClassroomOptions: [],
    SubjectOptions: [],
};

const Default_NewReportSettings = {
    GroupOptions: [],
    ClassroomOptions: [],
    SubjectOptions: [],   //new.
    ErrorMessage: '',
    Info: {
        // OrganizerId: 0,
        // AuthorId: 0,
        DateRangeBegin: '',
        DateRangeEnd: '',
        Title: '',
        Description: '',
        Remark: '',
    },
    Groups: null,
    Classrooms: null,
    Subjects: null,
};

// const ReportStages = {
//     Init: 'Init',
//     InfoTab: 'InfoTab',
//     GroupTab: 'GroupTab',
//     SubjectTab: 'SubjectTab',   //new.
//     ClassroomTab: 'ClassroomTab',
//     Processing: 'Processing',
//     Success: 'Success',
//     Error: 'Error',
// };

const ReportSettingPropertyNames = {
    OrganizerId: 'OrganizerId',
    AuthorId: 'AuthorId',
    DateRangeBegin: 'DateRangeBegin',
    DateRangeEnd: 'DateRangeEnd',
    Title: 'Title',
    Description: 'Description',
    Remark: 'Remark',
    Groups: 'Groups',
    Classrooms: 'Classrooms',
    Subjects: 'Subjects',   //new.
    //etc.
    Id: 'Id',
    CreatedDate: 'CreatedDate',
    File: 'File',
    // FileLocation: 'FileLocation',
    // FileName: 'FileName',
    // FileExt: 'FileExt',
    ReportGenerateSetting: 'ReportGenerateSetting',
};

export default class ReportStudentStatisticScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({

        isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        redirect: false,
        redirectLink: '/',
        isLoading: false,

        gv: null,
        PA_View: false,
        PA_Search: false,
        PA_Create: false,
        PA_Update: false,
        PA_Delete: false,
        PA_Upload: false,
        PA_Download: false,

        List: [],
        // TableColumn: 7,
        IsListLoaded: false,
        TotalRows: 0,
        PageIndex: 0,
        PageSize: 10,
        OrderBy: 'Id',
        OrderType: 'DESC',

        // SearchByGrade: '',
        // SearchByClassroom: '',
        SearchConditions: Default_SearchConditions,
        SearchUiModal_Toggle: false,
        SearchByCondition_Processing: false,
        SearchSettings: Default_SearchSettings,

        SelectedItemIndex_ViewContent: -1,
        SelectedItemIndex: -1,
        SelectedItem: null,

        //View.
        ViewReportUiModal_Toggle: false,
        ViewReport_IsLoading: false,
        ViewReport_IsLoadSuccess: false,
        ViewReport_IsLoaded: false,
        ViewReport_Components: null,

        //Delete.
        DeleteReportUiModal_Toggle: false,

        //Generate.
        NewReportUiModal_Toggle: false,
        NewReportStage: ReportStages.Init,
        NewReportSettings: Default_NewReportSettings,
        NewReportProgressionStatus: '',
        NewReport_Components: null,
    });

    componentWillUnmount = () => { }

    componentDidMount = async () => {
        //#region init.
        window.scrollTo(0, 0);
        useGlobal.getState().setScreen(LayoutScreen.ManageReportStudentStatistic);
        // await useAppService.getState().getGroups();
        // await useAppService.getState().getSubjects();
        // await Delay(0);
        this.LoadList_ViaApi();
        useGlobal.getState().setRefreshListCallbackFn(this.LoadList_ViaApi);
        //#endregion
    }

    //2024.07.24
    CheckPermissions = async () => {
        const gv = useGlobal.getState();
        const { uid, organizerId } = GetPropIds(gv.user);
        this.setState({
            PA_View: PermissionAccess(LayoutScreen.ManageReportStudentStatistic, PermissionAccessType.View),
            PA_Search: PermissionAccess(LayoutScreen.ManageReportStudentStatistic, PermissionAccessType.Search),
            PA_Create: PermissionAccess(LayoutScreen.ManageReportStudentStatistic, PermissionAccessType.Create),
            PA_Update: PermissionAccess(LayoutScreen.ManageReportStudentStatistic, PermissionAccessType.Update),
            PA_Delete: PermissionAccess(LayoutScreen.ManageReportStudentStatistic, PermissionAccessType.Delete),
            PA_Upload: PermissionAccess(LayoutScreen.ManageReportStudentStatistic, PermissionAccessType.Upload),
            PA_Download: PermissionAccess(LayoutScreen.ManageReportStudentStatistic, PermissionAccessType.Download),

            PageSize: CheckNumber(localStorage.getItem(`ManageReportStudentStatistic_List_PageSize_${uid}_${organizerId}`), GlobalSetting.PageSize),

            gv: useGlobal.getState(),
            IsSuperAdmin: useGlobal.getState().isSuperAdmin,
            IsMasterAdmin: useGlobal.getState().isMasterAdmin,
        });
        await Delay(0);
    }

    //#region === Listen State Progression Message ===
    // ProcessingMessage_Subscribe = (uid = '') => {
    //     if (CheckNullValue(uid) !== null) {
    //         if (this.state.isDevMode)
    //             console.log('ProcessingMessage (Subscribed)');
    //         ProcessingMessage_listenToNode_StudentStatisticReport = onSnapshot(
    //             query(
    //                 collection(firestore, 'ILE_Portal_Status_Logs'),
    //                 where('uid', '==', uid)
    //             ), (querySnapshot) => {
    //                 const statusLogs = [];
    //                 querySnapshot.forEach((doc) => {
    //                     statusLogs.push(doc.data().status);
    //                 });
    //                 this.setState({
    //                     NewReportProgressionStatus: statusLogs.join(''),
    //                 }, () => {
    //                     this.Populate_NewReportComponents();    //refresh ui.
    //                 });
    //                 if (this.state.isDevMode)
    //                     console.log('ProcessingMessage (Logs)\n' + statusLogs.join('\n'));
    //             });
    //     }
    // }
    // ProcessingMessage_Unsubscribe = () => {
    //     if (ProcessingMessage_listenToNode_StudentStatisticReport !== null) {
    //         if (typeof (ProcessingMessage_listenToNode_StudentStatisticReport) === 'function') {
    //             ProcessingMessage_listenToNode_StudentStatisticReport();
    //             this.setState({
    //                 NewReportProgressionStatus: '',
    //             });
    //         }
    //     }
    //     if (this.state.isDevMode)
    //         console.log('ProcessingMessage (Unsubscribed)');
    // }
    //#endregion === Listen State Progression Message ===

    //#region === Search Settings ===
    LoadSearchSettings_ViaApi = async () => {

        if (this.state.PA_View === false)
            return null;

        this.setState({
            SearchSettings: Default_SearchSettings,
        });
        const { Groups } = this.state.SearchConditions;
        const groupIds = Array.isArray(Groups) && Groups.length > 0 ? Groups.join(',') : '0';

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);

        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Report/Setting/Get/${organizerId}/${authorId}/${groupIds}`;
        if (this.state.isDevMode)
            console.log(`LoadSearchSettings_ViaApi \n${url}`);

        let _Data = [];

        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    // if (CheckNullValue(data.data) !== null)
                    _Data = CapitalizeJsonKeys(data.data);
                }
                else {
                    if (this.state.isDevMode)
                        console.log('Error', 'api - settings - load (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                if (this.state.isDevMode)
                    console.log('Error', 'api - settings - load (error)\n' + error.message);
            });

        //Refresh Options.
        let { GroupOptions, ClassroomOptions, SubjectOptions } = this.state.SearchSettings;
        if (Array.isArray(_Data['CustomSelectedGroups'])) {
            GroupOptions = [];
            const _GroupOptions = _Data['CustomSelectedGroups'];
            _GroupOptions.map((data, key) => {
                return GroupOptions.push({
                    value: CheckObjectNumber(data, 'Id'),
                    label: CheckObjectStringEmpty(data, 'Name'),
                });
            });
        }
        if (Array.isArray(_Data['CustomSelectedClassrooms'])) {
            ClassroomOptions = [];
            const _ClassroomOptions = _Data['CustomSelectedClassrooms'];
            _ClassroomOptions.map((data, key) => {
                return ClassroomOptions.push({
                    // value: CheckObjectNumber(data, 'Id'),
                    value: CheckObjectStringEmpty(data, 'Name'),
                    label: CheckObjectStringEmpty(data, 'Name'),
                });
            });
        }
        if (Array.isArray(_Data['CustomSelectedSubjects'])) {     //new.
            SubjectOptions = [];
            const _SubjectOptions = _Data['CustomSelectedSubjects'];
            _SubjectOptions.map((data, key) => {
                return SubjectOptions.push({
                    value: CheckObjectNumber(data, 'Id'),
                    label: CheckObjectStringEmpty(data, 'Name'),
                });
            });
        }

        this.setState({
            SearchSettings: {
                ...this.state.SearchSettings,
                GroupOptions: GroupOptions,
                ClassroomOptions: ClassroomOptions,
                SubjectOptions: SubjectOptions,     //new.
            },
        }, () => {
            if (this.state.isDevMode) {
                console.log('LoadSearchSettings_ViaApi (raw)\n' + JSON.stringify(_Data));
                console.log('LoadSearchSettings_ViaApi (options)\n' + JSON.stringify(this.state.SearchSettings));
            }
        });
    }
    ToggleSearchUiModal = (forceClose = false) => {
        this.setState({
            SearchUiModal_Toggle: forceClose ? false : !this.state.SearchUiModal_Toggle,
        }, () => {
            if (this.state.SearchUiModal_Toggle)
                this.ResetSearchParams(true);
        });
    }
    ResetSearchParams = async (toggleOn = false) => {
        this.setState({
            SearchConditions: Default_SearchConditions,
            SearchSettings: Default_SearchSettings,
            SearchUiModal_Toggle: false,
        });
        await Delay(0);
        await this.LoadSearchSettings_ViaApi();
        this.setState({
            // SearchConditions: Default_SearchConditions,
            SearchUiModal_Toggle: toggleOn,
        });
    }
    GetSelectedGroups = () => {
        const { GroupOptions } = this.state.SearchSettings;
        const { Groups } = this.state.SearchConditions;
        if (Array.isArray(Groups) && Groups.length > 0) {
            let _selectedOptions = [];
            Groups.map((data, key) => {
                return _selectedOptions.push(GroupOptions.find(x => Number(x.value) === Number(data)));
            });
            return _selectedOptions;
        }
        return [];
    }
    GetSelectedClassrooms = () => {
        const { ClassroomOptions } = this.state.SearchSettings;
        const { Classrooms } = this.state.SearchConditions;
        if (Array.isArray(Classrooms) && Classrooms.length > 0) {
            let _selectedOptions = [];
            Classrooms.map((data, key) => {
                return _selectedOptions.push(ClassroomOptions.find(x => String(x.label) === String(data)));
            });
            return _selectedOptions;
        }
        return [];
        // if (Array.isArray(Classrooms) && Classrooms.length > 0)
        //     return `Classroom(s) selected (${Classrooms.length})`;
        // return 'Select Classroom(s)';
    }
    GetSelectedSubjects = () => {     //new.
        const { SubjectOptions } = this.state.SearchSettings;
        const { Subjects } = this.state.SearchConditions;
        if (Array.isArray(Subjects) && Subjects.length > 0) {
            let _selectedOptions = [];
            Subjects.map((data, key) => {
                return _selectedOptions.push(SubjectOptions.find(x => Number(x.value) === Number(data)));
            });
            return _selectedOptions;
        }
        return [];
    }
    SetSearchConditions = (name = '', value = null) => {
        if (CheckNullValue(name) === null || value === null)
            return null;
        if (this.state.isDevMode)
            console.log('SetSearchConditions (value) \n' + JSON.stringify(value));
        let { Title, DateBegin, DateEnd, Groups, Classrooms } = this.state.SearchConditions;
        switch (name) {
            case SearchConditionOptions.Title:
                Title = CheckStringEmpty(value);
                if (this.state.isDevMode)
                    console.log('Title ', String(value));
                break;
            case SearchConditionOptions.DateBegin:
                DateBegin = CheckStringEmpty(value);
                if (this.state.isDevMode)
                    console.log('DateBegin ', String(value));
                break;
            case SearchConditionOptions.DateEnd:
                DateEnd = CheckStringEmpty(value);
                if (this.state.isDevMode)
                    console.log('DateEnd ', String(value));
                break;
            case SearchConditionOptions.Groups:
                let tmp_groups = [];
                if (Array.isArray(value)) {
                    value.sort((a, b) => a.value - b.value);
                    value.forEach(element => {
                        const g_value = CheckObjectNumber(element, 'value');
                        if (g_value > 0)
                            tmp_groups.push(g_value);
                    });
                    tmp_groups.sort((a, b) => a - b);
                }
                Groups = tmp_groups;
                if (this.state.isDevMode)
                    console.log('Groups \n' + JSON.stringify(tmp_groups));
                break;
            case SearchConditionOptions.Classrooms:
                let tmp_classrooms = [];
                if (Array.isArray(value)) {
                    value.sort((a, b) => a.value - b.value);
                    value.forEach(element => {
                        const c_value = CheckObjectStringEmpty(element, 'value');
                        if (c_value !== '') {
                            const index = tmp_classrooms.findIndex(x => String(x) === c_value);
                            if (index < 0)
                                tmp_classrooms.push(c_value);
                        }
                    });
                    tmp_classrooms.sort((a, b) => a - b);
                }
                Classrooms = tmp_classrooms;
                if (this.state.isDevMode)
                    console.log('Classrooms \n' + JSON.stringify(tmp_classrooms));
                break;
            default: break;
        }
        this.setState({
            SearchConditions: {
                Title: Title,
                DateBegin: DateBegin,
                DateEnd: DateEnd,
                Groups: Groups,
                Classrooms: Classrooms
            },
        }, async () => {
            switch (name) {
                case SearchConditionOptions.Groups:
                    await this.RefreshClassroomOptionsAndUiComponent();   //refresh Classrooms.
                    break;
                default: break;
            }
        });
    }
    RefreshGroupOptionsAndUiComponent = async (reloadSetting = true) => {
        const ele = document.getElementById('search-report-by-Groups-fg');
        if (ele !== null) {
            ele.style.display = 'none';
            this.setState({
                SearchConditions: {
                    ...this.state.SearchConditions,
                    Groups: null,
                },
            });
            await Delay(200);
            if (reloadSetting)
                await this.LoadSearchSettings_ViaApi();
            ele.style.display = 'initial';
        }
    }
    RefreshClassroomOptionsAndUiComponent = async (reloadSetting = true) => {
        const ele = document.getElementById('search-report-by-Classrooms-fg');
        const ele_loading = document.getElementById('search-report-by-Classrooms-fg-loading');
        if (ele !== null)
            ele.style.display = 'none';
        if (ele_loading !== null)
            ele_loading.style.display = 'initial';
        this.setState({
            SearchConditions: {
                ...this.state.SearchConditions,
                Classrooms: null,
            },
        });
        await Delay(0);
        if (reloadSetting) {
            await this.LoadSearchSettings_ViaApi();
            await Delay(0);
        }
        if (ele !== null)
            ele.style.display = 'initial';
        if (ele_loading !== null)
            ele_loading.style.display = 'none';
    }
    //#endregion

    //#region === List ===
    LoadList_ViaApi = async (newSearch = false) => {

        await this.CheckPermissions();    //2024.07.24

        if (this.state.PA_View === false)
            return null;

        this.setState({
            isLoading: true,
            List: [],
            IsListLoaded: false,
            PageIndex: newSearch ? 0 : this.state.PageIndex,
        });
        await Delay(0);
        window.scrollTo(0, 0);

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);

        const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/Organizer/Report/Student/Statistic/List';

        const searchJson = JSON.stringify({
            orderBy: this.state.OrderBy,
            orderType: this.state.OrderType,
            pageIndex: this.state.PageIndex,
            pageSize: this.state.PageSize,

            authorId: authorId,
            organizerId: organizerId,

            grade: this.state.SearchByGrade,
            classroom: this.state.SearchByClassroom,
            isTeacher: false,
        });

        if (this.state.isDevMode)
            console.log(`LoadList_ViaApi \n${url}\n${searchJson}`);

        let totalRows = 0;
        let _List = [];

        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: searchJson,
            })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    if (data.data !== undefined)
                        if (Array.isArray(data.data.data)) {
                            _List = data.data.data;
                            totalRows = CheckObjectNumber(data.data, 'totalCount', _List.length);     //2023.12.07
                        }
                        else {
                            if (this.state.isDevMode)
                                console.log('List is empty.');
                        }
                }
                else {
                    if (this.state.isDevMode)
                        console.log('Error', 'api - report - load list (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                if (this.state.isDevMode)
                    console.log('Error', 'api - report - load list (error)\n' + error.message);
            });

        this.setState({
            isLoading: false,
            List: CapitalizeJsonKeys(_List),
            TotalRows: totalRows,
            IsListLoaded: true,
        }, () => {
            if (this.state.isDevMode) {
                console.log('TotalRows', totalRows);
                console.log('List', JSON.stringify(_List));
            }
        });
    }
    ListComponents = () => {
        let components = [];

        if (this.state.IsListLoaded === false)
            return null;

        if (Array.isArray(this.state.List) === false || this.state.List.length === 0)
            return (<tr><td colSpan={15} align='center'>- list is empty -</td></tr>);

        this.state.List.map((data, key) => {
            components.push(<tr key={'tbi_' + key}>
                <td>{this.state.PageIndex + key + 1}</td>
                <td>{
                    CheckObjectNullValue(data, 'DateRangeBegin') === null || CheckObjectNullValue(data, 'DateRangeEnd') === null ? null :
                        moment(CheckObjectStringEmpty(data, 'DateRangeBegin')).format('ll')
                        + '~' + moment(CheckObjectStringEmpty(data, 'DateRangeEnd')).format('ll')
                }</td>
                <td className='left'>{CheckObjectStringEmpty(data, 'Title', '-')}</td>
                <td>
                    <button
                        type='button'
                        className='btn btn-outline-primary'
                        onClick={() => this.ViewItemContent(key, 'ReportGenerateSetting')}
                        disabled={!this.state.PA_View}
                        title='View Settings'
                    >View</button>
                </td>
                <td>
                    <button
                        type='button'
                        className='btn btn-outline-primary'
                        onClick={() => this.ViewItemContent(key, 'Description')}
                        disabled={!this.state.PA_View}
                        title='View Description'
                    >Description</button>
                </td>
                <td>
                    <button
                        type='button'
                        className='btn btn-outline-primary'
                        onClick={() => this.ViewItemContent(key, 'Remark')}
                        disabled={!this.state.PA_View}
                        title='View Remark'
                    >View</button>
                </td>
                <td>{CheckObjectStringEmpty(data, 'CreatedDate', '-')}</td>
                <td className="actions">
                    {
                        this.state.PA_Delete ?
                            <button
                                type='button'
                                className='btn btn-danger'
                                onClick={() => this.ToggleDeleteReportUiModal(key)}
                                disabled={!this.state.PA_Delete}
                                title='Remove Current Report'
                            >Remove</button>
                            : null
                    }
                    {
                        this.state.PA_Download ?
                            <button
                                type='button'
                                className='btn btn-info'
                                onClick={() => this.DownloadReportFile(key)}
                                disabled={!this.state.PA_Download}
                                title='Download Current Report'
                            >Download</button>
                            : null
                    }
                    {
                        this.state.PA_View ?
                            <button
                                type='button'
                                className='btn btn-primary'
                                onClick={() => this.ToggleViewReportUiModal(key)}
                                disabled={!this.state.PA_View}
                                title='View Detail of Current Report'
                            >Detail</button>
                            : null
                    }
                </td>
            </tr>);
            return null;
        });

        return (components);
    }
    ViewItemContent = (index = -1, title = '') => {
        this.setState({
            SelectedItemIndex_ViewContent: this.state.PA_View === false ? -1 : index,
        }, () => {
            if (Array.isArray(this.state.List) === false || this.state.SelectedItemIndex_ViewContent < 0)
                return null;
            const content = CheckObjectStringEmpty(this.state.List[this.state.SelectedItemIndex_ViewContent], title, '-n/a-');
            useAppService.getState().setModal(title, content);
        });
    }
    ViewItemSettingComponents = (index = -1) => {
        if (index < 0)
            return null;

        const list = this.state.List;
        if (CheckNullValue(list) === null)
            return null;

        const item = index < list.length ? list[index] : null;
        if (CheckNullValue(item) === null)
            return null;

        const {
            // Id, 
            DateRangeBegin, DateRangeEnd, CreatedDate,
            Title, Description, Remark,
            FileLocation, FileName, FileExt, ReportGenerateSetting
        } = item;

        let tabComs = [];

        tabComs.push(<div className="form-group">
            <label htmlFor={`item-report-${ReportSettingPropertyNames.CreatedDate}`}>Created Date</label>
            <input className='form-control' type="text" style={{ width: '100%' }}
                id={`item-report-${ReportSettingPropertyNames.CreatedDate}`}
                defaultValue={CreatedDate}
                readOnly
            />
        </div>);

        tabComs.push(<div className="form-group">
            <label htmlFor={`item-report-${ReportSettingPropertyNames.File}`}>File</label>
            <button type="button" className="btn btn-primary"
                id={`item-report-${ReportSettingPropertyNames.File}`}
                // onClick={() => this.DownloadReportFile(index)}
                onClick={() => TriggerDownloadFile(FileLocation, FileName, FileExt, this.state.locale)}
                disabled={!this.state.PA_Download}
                title="Download Current Report"
            >{FileName + FileExt}</button>
        </div>);

        tabComs.push(<hr />);

        tabComs.push(<div className="form-group">
            <label htmlFor={`item-report-${ReportSettingPropertyNames.Title}`}>Title</label>
            <input className='form-control' type="text" style={{ width: '100%' }}
                id={`item-report-${ReportSettingPropertyNames.Title}`}
                defaultValue={Title}
                readOnly
            />
        </div>);

        tabComs.push(<div className="form-group">
            <label htmlFor={`item-report-${ReportSettingPropertyNames.Description}`}>Description</label>
            <input className='form-control' type="text" style={{ width: '100%' }}
                id={`item-report-${ReportSettingPropertyNames.Description}`}
                defaultValue={Description}
                readOnly
            />
        </div>);

        tabComs.push(<div className="form-group">
            <label htmlFor={`item-report-${ReportSettingPropertyNames.Remark}`}>Remark</label>
            <input className='form-control' type="text" style={{ width: '100%' }}
                id={`item-report-${ReportSettingPropertyNames.Remark}`}
                defaultValue={Remark}
                readOnly
            />
        </div>);

        tabComs.push(<div className="form-group">
            <label htmlFor={`item-report-${ReportSettingPropertyNames.DateRangeBegin}`}>Date Begin</label>
            <input className='form-control' type="date" style={{ width: '100%' }}
                id={`item-report-${ReportSettingPropertyNames.DateRangeBegin}`}
                defaultValue={DateRangeBegin}
                readOnly
            />
        </div>);

        tabComs.push(<div className="form-group">
            <label htmlFor={`item-report-${ReportSettingPropertyNames.DateRangeEnd}`}>Date End</label>
            <input className='form-control' type="date" style={{ width: '100%' }}
                id={`item-report-${ReportSettingPropertyNames.DateRangeEnd}`}
                defaultValue={DateRangeEnd}
                readOnly
            />
        </div>);

        tabComs.push(<hr />);

        const {
            CustomSelectedGroups, CustomSelectedClassrooms
        } = ReportGenerateSetting ?? { CustomSelectedGroups: [], CustomSelectedClassrooms: [] };

        let components_Info_Groups = null;
        if (Array.isArray(CustomSelectedGroups)) {
            let tmp = [];
            CustomSelectedGroups.map((option, key) => {
                if (CheckObjectBoolean(option, 'Selected'))
                    tmp.push(`<tr><td>${CheckObjectStringEmpty(option, 'Name')}</td></tr>`);
                return null;
            });
            components_Info_Groups = `<table class='table table-hover tb-no-border-no-margin'><tbody>${tmp.join('')}</tbody></table>`;
        }
        let components_Info_Classrooms = null;
        if (Array.isArray(CustomSelectedClassrooms)) {
            let tmp = [];
            CustomSelectedClassrooms.map((option, key) => {
                if (CheckObjectBoolean(option, 'Selected'))
                    tmp.push(`<tr><td>${CheckObjectStringEmpty(option, 'Name')}</td></tr>`);
                return null;
            });
            components_Info_Classrooms = `<table class='table table-hover tb-no-border-no-margin'><tbody>${tmp.join('')}</tbody></table>`;
        }
        tabComs.push(<div className="form-group">
            <label htmlFor={`item-report-${ReportSettingPropertyNames.Groups}`}>Selected Groups</label>
            <div
                className='form-control'
                id={`item-report-${ReportSettingPropertyNames.Groups}`}
                dangerouslySetInnerHTML={{ __html: components_Info_Groups }}
                style={{ padding: 5, }}
            />
        </div>);
        tabComs.push(<div className="form-group">
            <label htmlFor={`item-report-${ReportSettingPropertyNames.Classrooms}`}>Selected Classrooms</label>
            <div
                className='form-control'
                id={`item-report-${ReportSettingPropertyNames.Classrooms}`}
                dangerouslySetInnerHTML={{ __html: components_Info_Classrooms }}
                style={{ padding: 5, }}
            />
        </div>);

        return (<div style={{ display: 'flex', flexDirection: 'column' }}>{tabComs}</div>);
    }
    DownloadReportFile = (index = -1) => {
        if (index < 0)
            return null;

        const list = this.state.List;
        if (CheckNullValue(list) === null)
            return null;

        const item = index < list.length ? list[index] : null;
        if (CheckNullValue(item) === null)
            return null;

        const url = CheckObjectStringEmpty(item, 'FileLocation');
        const fileName = CheckObjectStringEmpty(item, 'FileName');
        const fileExt = CheckObjectStringEmpty(item, 'FileExt');

        if (CheckNullValue(url) === null || CheckNullValue(fileName) === null || CheckNullValue(fileExt) === null)
            return null;

        TriggerDownloadFile(url, fileName, fileExt, this.state.locale);
    }
    //#region === Paging Components
    CallbackFunctionForPagingComponents_PageSize = (pageSize = GlobalSetting.PageSize) => {
        this.setState({
            PageSize: pageSize < GlobalSetting.PageSize ? GlobalSetting.PageSize : pageSize,
        }, () => {
            const { uid, organizerId } = GetPropIds(useGlobal.getState().user);
            localStorage.setItem(`ManageReportStudentStatistic_List_PageSize_${uid}_${organizerId}`, this.state.PageSize);
            setTimeout(() => {
                this.LoadList_ViaApi();
            }, 500);
        });
    }
    CallbackFunctionForPagingComponents_PageIndex = (pageIndex = 0) => {
        this.setState({
            PageIndex: pageIndex,
        }, () => {
            setTimeout(() => {
                this.LoadList_ViaApi();
            }, 500);
        });
    }
    //#endregion === Paging Components

    //#endregion

    //#region === Report - View ===
    ToggleViewReportUiModal = (index = -1) => {
        this.setState({
            SelectedItemIndex: this.state.PA_View === false ? -1 : index,
            SelectedItem: Array.isArray(this.state.List) && index > -1 ? this.state.List[index] : null,
            ViewReportUiModal_Toggle: this.state.PA_View === false || index < 0 ? false : !this.state.ViewReportUiModal_Toggle,
            ViewReport_IsLoading: false,
            ViewReport_IsLoadSuccess: false,
        }, () => {
            if (this.state.ViewReportUiModal_Toggle)
                this.LoadReportDetail_ViaApi();     //load report details.
        });
    }
    LoadReportDetail_ViaApi = async () => {
        if (this.state.PA_View === false)
            return null;
        if (this.state.SelectedItemIndex < 0 || this.state.SelectedItem === null)
            return null;

        this.setState({
            ViewReport_IsLoading: true,
            ViewReport_IsLoadSuccess: false,
        });

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const { Id } = this.state.SelectedItem;

        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Organizer/Report/Student/Statistic/Get/${organizerId}/${authorId}/${Id}`;
        if (this.state.isDevMode)
            console.log('LoadReportDetail_ViaApi', url);

        let success = false;
        let _data = this.state.SelectedItem;

        if (organizerId > 0 && authorId > 0 && Number(Id) > 0) {
            await fetch(url,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(res => res.json())
                .then(data => {
                    success = CheckObjectBoolean(data, 'success');
                    if (success) {
                        if (CheckObjectNullValue(data, 'data') !== null)
                            _data = CapitalizeJsonKeys(data.data);
                    }
                    else {
                        if (this.state.isDevMode)
                            console.log('Error', 'api - report - load detail (failed)\n' + JSON.stringify(data));
                    }
                })
                .catch(error => {
                    if (this.state.isDevMode)
                        console.log('Error', 'api - report - load detail (error)\n' + error.message);
                });
        }

        this.setState({
            ViewReport_IsLoading: true,
            ViewReport_IsLoadSuccess: success,
            SelectedItem: _data,
        }, () => {
            if (this.state.isDevMode) {
                console.log('Report Detail', JSON.stringify(_data));
            }
        });
    }
    // Populate_ViewReportComponents = () => {
    //     let components = [];
    //     const noDetail = <>- no detail is available -</>;

    //     if (this.state.SelectedItemIndex < 0 || this.state.SelectedItem === null) {
    //         components.push(noDetail);
    //     }
    //     else {
    //         const {
    //             // Id, 
    //             Title, Description, Remark,
    //             // FileLocation, 
    //             FileName, FileExt,
    //             DateRangeBegin, DateRangeEnd, CreatedDate, Settings
    //         } = this.state.SelectedItem;

    //         //Info.


    //         //Details.
    //         if (this.state.ViewReport_IsLoading) {
    //             components.push(<><p>&nbsp;</p>Fetching...<br /><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 7 }} /></>);
    //         }
    //         else {
    //             if (this.state.ViewReport_IsLoadSuccess === false) {
    //                 components.push(noDetail);
    //             }
    //             else {
    //                 //loaded success.
    //                 const { GroupFigures, ClassroomFigures, RoomFigures, StudentFigures } = this.state.SelectedItem;


    //             }
    //         }
    //     }
    //     //return.
    //     this.setState({
    //         ViewReport_Components: components,
    //     });
    // }
    Populating_ViewReport_Components = async (init = false) => {
        let components = [];
        let base_component = null;
        const noDetail = <>- no detail is available -</>;

        if (this.state.SelectedItemIndex < 0 || this.state.SelectedItem === null) {
            // components.push(noDetail);
            base_component = <span>{noDetail}</span>;
        }
        else {

            //Info.
            let components_Info = [];
            const {
                // Id, 
                Title, Description, Remark,
                // FileLocation, 
                FileName, FileExt,
                DateRangeBegin, DateRangeEnd, CreatedDate, Settings
            } = this.state.SelectedItem;
            const { CustomSelectedGroups, CustomSelectedClassrooms, CustomSelectedSubjects } = Settings ?? { CustomSelectedGroups: [], CustomSelectedClassrooms: [], CustomSelectedSubjects: [] };
            let components_Info_Groups = null;
            if (Array.isArray(CustomSelectedGroups)) {
                let tmp = [];
                CustomSelectedGroups.map((option, key) => {
                    if (CheckObjectBoolean(option, 'Selected'))
                        tmp.push(CheckObjectStringEmpty(option, 'Name'));
                    return null;
                });
                components_Info_Groups = tmp.join('<br />');
            }
            let components_Info_Classrooms = null;
            if (Array.isArray(CustomSelectedClassrooms)) {
                let tmp = [];
                CustomSelectedClassrooms.map((option, key) => {
                    if (CheckObjectBoolean(option, 'Selected'))
                        tmp.push(CheckObjectStringEmpty(option, 'Name'));
                    return null;
                });
                components_Info_Classrooms = tmp.join('<br />');
            }
            let components_Info_Subjects = null;    //new.
            if (Array.isArray(CustomSelectedSubjects)) {    //new.
                let tmp = [];
                CustomSelectedSubjects.map((option, key) => {
                    if (CheckObjectBoolean(option, 'Selected'))
                        tmp.push(CheckObjectStringEmpty(option, 'Name'));
                    return null;
                });
                components_Info_Subjects = tmp.join('<br />');
            }
            components_Info.push(<table className="table table-hover" border="1"><tbody>
                <tr><td>Title</td><td>{Title}</td></tr>
                <tr><td>Description</td><td>{Description}</td></tr>
                <tr><td>Remark</td><td>{Remark}</td></tr>
                <tr><td>Date Begin</td><td>{DateRangeBegin}</td></tr>
                <tr><td>Date End</td><td>{DateRangeEnd}</td></tr>
                <tr><td>Report</td><td>{FileName + FileExt}</td></tr>
                <tr><td>Created Date</td><td>{CreatedDate}</td></tr>
                {
                    init || components_Info_Groups === null ? null :
                        <>
                            <tr><td colSpan={2}>Selected Groups</td></tr>
                            <tr><td colSpan={2} style={{ paddingLeft: 25 }}><div dangerouslySetInnerHTML={{ __html: components_Info_Groups }} /></td></tr>
                        </>
                }
                {
                    init || components_Info_Subjects === null ? null :
                        <>
                            <tr><td colSpan={2}>Selected Subjects</td></tr>
                            <tr><td colSpan={2} style={{ paddingLeft: 25 }}><div dangerouslySetInnerHTML={{ __html: components_Info_Subjects }} /></td></tr>
                        </>
                }
                {
                    init || components_Info_Classrooms === null ? null :
                        <>
                            <tr><td colSpan={2}>Selected Classrooms</td></tr>
                            <tr><td colSpan={2} style={{ paddingLeft: 25 }}><div dangerouslySetInnerHTML={{ __html: components_Info_Classrooms }} /></td></tr>
                        </>
                }
            </tbody></table>);
            await Delay(0);

            //Details.
            if (this.state.ViewReport_IsLoadSuccess === false) {
                components.push(components_Info);
                components.push(<hr />);
                //loading.
                if (this.state.ViewReport_IsLoading)
                    components.push(<>Fetching report...<br /><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 7 }} /></>);
                else
                    components.push(noDetail);

                base_component = components;
            }
            else {
                if (init) {
                    components.push(components_Info);
                    components.push(<hr />);
                    components.push(<><b>Populating report... </b>(please wait patiently)<br /><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 7 }} /></>);
                    base_component = components;
                }
                else {

                    //Info tab.
                    components.push(<Tab eventKey={0} title='Info' className="base-tab tab-info">{components_Info}</Tab>);

                    //loaded success.
                    const { GroupFigures, ClassroomFigures, RoomFigures, StudentFigures } = this.state.SelectedItem;

                    //Group Tab.
                    let components_Groups = [];
                    if (Array.isArray(GroupFigures)) {
                        GroupFigures.map((data, gKey) => {
                            let student_components = [];
                            if (Array.isArray(data['StudentIds']) && Array.isArray(StudentFigures)) {
                                let students = [];
                                data['StudentIds'].map((id, key) => {
                                    const findIndex_student = StudentFigures.findIndex(x => Number(x.UserProfileId) === Number(id));
                                    if (findIndex_student > -1)
                                        students.push(StudentFigures[findIndex_student]);
                                    return null;
                                });
                                students.map((stu, key) => {
                                    return student_components.push(<tr>
                                        <td>{key + 1}</td>
                                        <td>{CheckObjectStringEmpty(stu, 'Classroom')}</td>
                                        <td>{CheckObjectStringEmpty(stu, 'Name')}</td>
                                        <td style={{ width: 'max-content' }}>{CheckObjectStringEmpty(stu, 'Email')}</td>
                                    </tr>);
                                });
                            }
                            return components_Groups.push(<Tab eventKey={gKey} title={CheckObjectStringEmpty(data, 'GroupName')} className="base-tab report-tab-detail-base tab-groups-detail">
                                <span style={{ paddingLeft: 5, }}><b>Total Student(s) : {CheckObjectNumber(data, 'TotalStudent')}</b></span>
                                {
                                    student_components === null ? null
                                        :
                                        <table className="table table-hover tb-no-border-style" border="1" style={{ marginTop: 10, }}>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Classroom</th>
                                                    <th>Name</th>
                                                    <th width={255}>Email</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {student_components}
                                            </tbody>
                                        </table>
                                }
                            </Tab>);
                        });
                    }
                    components.push(<Tab eventKey={1} title='Group(s)' className="base-tab report-tab-detail-base tab-groups">
                        <span style={{ fontWeight: 'bold', paddingLeft: 15, }}>Total Group(s) : {Array.isArray(GroupFigures) ? GroupFigures.length : '-'}</span>
                        <Tabs defaultActiveKey={0} id={'tab-groups-detail'} style={{ marginTop: 10, }}>{components_Groups}</Tabs>
                    </Tab>);
                    await Delay(0);

                    //Classroom Tab.
                    let components_Classrooms = [];
                    if (Array.isArray(ClassroomFigures)) {
                        ClassroomFigures.map((data, crKey) => {
                            let student_components = [];
                            if (Array.isArray(data['StudentIds']) && Array.isArray(StudentFigures)) {
                                let students = [];
                                data['StudentIds'].map((id, key) => {
                                    const findIndex_student = StudentFigures.findIndex(x => Number(x.UserProfileId) === Number(id));
                                    if (findIndex_student > -1)
                                        students.push(StudentFigures[findIndex_student]);
                                    return null;
                                });
                                students.map((stu, key) => {
                                    return student_components.push(<tr>
                                        <td>{key + 1}</td>
                                        <td>{CheckObjectStringEmpty(stu, 'Classroom')}</td>
                                        <td>{CheckObjectStringEmpty(stu, 'Name')}</td>
                                        <td style={{ width: 'max-content' }}>{CheckObjectStringEmpty(stu, 'Email')}</td>
                                    </tr>);
                                });
                            }
                            return components_Classrooms.push(<Tab eventKey={crKey} title={CheckObjectStringEmpty(data, 'Classroom')} className="base-tab report-tab-detail-base tab-classrooms-detail">
                                <span style={{ paddingLeft: 5, }}><b>Total Student(s) : {CheckObjectNumber(data, 'TotalStudent')}</b></span>
                                {
                                    student_components === null ? null
                                        :
                                        <table className="table table-hover tb-no-border-style" border="1" style={{ marginTop: 10, }}>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Classroom</th>
                                                    <th>Name</th>
                                                    <th width={255}>Email</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {student_components}
                                            </tbody>
                                        </table>
                                }
                            </Tab>);
                        });
                    }
                    components.push(<Tab eventKey={2} title='Classroom(s)' className="base-tab report-tab-detail-base tab-classrooms">
                        <span style={{ fontWeight: 'bold', paddingLeft: 15, }}>Total Classrooms : {Array.isArray(ClassroomFigures) ? ClassroomFigures.length : '-'}</span>
                        <Tabs defaultActiveKey={0} id={'tab-classrooms-detail'} style={{ marginTop: 10, }}>{components_Classrooms}</Tabs>
                    </Tab>);
                    await Delay(0);

                    //Quiz Room Tab.
                    let components_Rooms = [];
                    if (Array.isArray(RoomFigures)) {
                        RoomFigures.map((data, roomKey) => {
                            let student_components = [];
                            if (Array.isArray(data['StudentIds']) && Array.isArray(data['AbsentStudentIds']) && Array.isArray(StudentFigures)) {
                                let students = [];
                                data['StudentIds'].map((id, key) => {
                                    const findIndex_student = StudentFigures.findIndex(x => Number(x.UserProfileId) === Number(id));
                                    if (findIndex_student > -1)
                                        students.push(StudentFigures[findIndex_student]);
                                    return null;
                                });
                                students.map((stu, key) => {
                                    return student_components.push(<tr>
                                        <td>{key + 1}</td>
                                        <td>{CheckObjectStringEmpty(stu, 'Classroom')}</td>
                                        {/* <td>{data['AbsentStudentIds'].findIndex(x => Number(x) === Number(stu.UserProfileId)) < 0 ? '' : '✔'}</td> */}
                                        <td>{CheckObjectStringEmpty(stu, 'Name')}</td>
                                        <td style={{ width: 'max-content' }}>{CheckObjectStringEmpty(stu, 'Email')}</td>
                                    </tr>);
                                });
                            }
                            return components_Rooms.push(<Tab eventKey={roomKey} title={CheckObjectStringEmpty(data, 'RoomTitle')} className="base-tab report-tab-detail-base tab-rooms-detail">
                                <table cellPadding={5} cellSpacing={5} border="0">
                                    <tbody>
                                        <tr><td><b>Title</b></td><td width={10} align="center">:</td><td>{CheckObjectStringEmpty(data, 'RoomTitle')}</td></tr>
                                        <tr><td><b>Room Code</b></td><td width={10} align="center">:</td><td>{CheckObjectStringEmpty(data, 'RoomCode')}</td></tr>
                                        <tr><td><b>Subject</b></td><td width={10} align="center">:</td><td>{CheckObjectStringEmpty(data, 'SubjectName')}</td></tr>
                                        <tr><td><b>Group</b></td><td width={10} align="center">:</td><td>{CheckObjectStringEmpty(data, 'GroupName')}</td></tr>
                                        <tr><td><b>Total Student(s)</b></td><td width={10} align="center">:</td><td>{CheckObjectNumber(data, 'TotalStudent')}</td></tr>
                                    </tbody>
                                </table>
                                {
                                    student_components === null ? null
                                        :
                                        <table className="table table-hover tb-no-border-style" border="1" style={{ marginTop: 10, }}>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Classroom</th>
                                                    <th>Name</th>
                                                    <th width={255}>Email</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {student_components}
                                            </tbody>
                                        </table>
                                }
                            </Tab>);
                        });
                    }
                    components.push(<Tab eventKey={3} title='Room(s)' className="base-tab report-tab-detail-base tab-rooms">
                        <span style={{ fontWeight: 'bold', paddingLeft: 15, }}>Total Quiz Rooms : {Array.isArray(RoomFigures) ? RoomFigures.length : '-'}</span>
                        <Tabs defaultActiveKey={0} id={'tab-rooms-detail'} style={{ marginTop: 10, }}>{components_Rooms}</Tabs>
                    </Tab>);
                    await Delay(0);

                    //final.
                    base_component = <Tabs defaultActiveKey={0} id={'tabs-report-detail'}>{components}</Tabs>;
                }
            }
        }
        await Delay(0);
        this.setState({
            ViewReport_IsLoaded: !init,
        }, () => {
            this.setState({
                ViewReport_Components: base_component,
            });
        });
    }
    ViewReportFooterComponents = () => {
        let components = [];
        const loading = this.state.ViewReport_IsLoading;
        const success = this.state.ViewReport_IsLoadSuccess;
        if (loading) { }
        else if (loading === false) {
            components.push(<Button variant="secondary" onClick={() => this.ToggleViewReportUiModal()}>Close</Button>);
        }
        if (loading === false && success && this.state.ViewReport_IsLoaded) {
            components.push(<Button variant="primary"
                onClick={() => this.DownloadReportFile(this.state.SelectedItemIndex)}
                hidden={this.state.SelectedItem === null}
            >Download Report</Button>);
        }
        return (components);
    }
    //#endregion === Report - View ===

    //#region === Report - Delete ===
    ToggleDeleteReportUiModal = (index = -1) => {
        this.setState({
            SelectedItemIndex: this.state.PA_Delete === false ? -1 : index,
            SelectedItem: Array.isArray(this.state.List) && index > -1 ? this.state.List[index] : null,
            DeleteReportUiModal_Toggle: this.state.PA_Delete === false ? false : !this.state.DeleteReportUiModal_Toggle,
        });
    }
    ProcessDeleteReport_ViaApi = async () => {

        if (this.state.PA_Delete === false)
            return null;

        if (this.state.SelectedItemIndex < 0 || this.state.SelectedItem === null)
            return null;

        // this.setState({ DeleteReportUiModal_Toggle: false, });
        useAppService.getState().setModal('', 'Removing Report...', null, AlertMode.Loading);
        let success = false;
        let errorMessage = '';

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const { Id, Title } = this.state.SelectedItem;

        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Organizer/Report/Student/Statistic/Remove/${organizerId}/${authorId}/${Id}`
        if (this.state.isDevMode)
            console.log('ProcessDeleteReport_ViaApi', url);

        //remove report record in CMS, MarkedAsDeleted/delete azure files/mappings etc.
        if (organizerId > 0 && authorId > 0 && Id > 0) {
            await fetch(url,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        // 'Content-Type': 'application/json',
                    },
                })
                .then(res => res.json())
                .then(data => {
                    success = CheckObjectBoolean(data, 'success');
                    if (!success) {
                        errorMessage = CheckObjectStringEmpty(data, 'message');
                        if (this.state.isDevMode)
                            console.log('Error', 'api - report delete (failed) (' + organizerId + '/' + authorId + '/' + Id + ') \n <' + Title + '> \n' + JSON.stringify(data));
                    }
                })
                .catch(error => {
                    errorMessage = CheckObjectStringEmpty(error, 'message');
                    if (this.state.isDevMode)
                        console.log('Error', 'api - report delete (error) (' + organizerId + '/' + authorId + '/' + Id + ') \n <' + Title + '> \n' + error.message);
                });
        }

        //done
        this.ToggleDeleteReportUiModal();   //reset data.
        if (success) {
            this.LoadList_ViaApi();
            useAppService.getState().setModal('', 'Report &#60;' + Title + '&#62; has been removed.');
        }
        else {
            useAppService.getState().setModal('', 'Failed to remove Report &#60;' + Title + '&#62;.<br /><br />' + errorMessage);
        }
    }
    //#endregion === Report - Delete ===

    //#region === Report - New / Generate ===
    ToggleNewReportUiModal = async (forceClose = false) => {
        this.setState({
            NewReportUiModal_Toggle: forceClose ? false : !this.state.NewReportUiModal_Toggle,
            NewReportStage: ReportStages.Init,
            NewReportSettings: Default_NewReportSettings,
            NewReportProgressionStatus: '',
        }, async () => {
            if (this.state.NewReportUiModal_Toggle) {
                this.Populate_NewReportComponents();
                await this.LoadNewReportSettings_ViaApi();        //load report details.
                this.setState({
                    NewReportStage: ReportStages.InfoTab,
                });
                this.Populate_NewReportComponents();
            }
        });
    }
    ResetNewReportUiModal = async () => {
        await this.ToggleNewReportUiModal(true);
        await Delay(200);
        this.ToggleNewReportUiModal();
    }
    LoadNewReportSettings_ViaApi = async (fetchClassroom = false) => {

        if (this.state.PA_Create === false)
            return null;

        let groupIds = '0';
        if (fetchClassroom === false) {
            this.setState({
                NewReportSettings: Default_NewReportSettings,
            });
        }
        else {
            const { Groups } = this.state.NewReportSettings;
            groupIds = CheckStringEmpty(Groups, '0');
        }
        const { authorId, organizerId, uid } = GetPropIds(useGlobal.getState().user);

        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Report/Setting/Get/${organizerId}/${authorId}/${groupIds}`;
        if (this.state.isDevMode)
            console.log(`LoadNewReportSettings_ViaApi (${uid}) \n${url}`);

        let _Data = [];

        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    _Data = CapitalizeJsonKeys(data.data);
                }
                else {
                    if (this.state.isDevMode)
                        console.log('Error', 'api - settings - load (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                if (this.state.isDevMode)
                    console.log('Error', 'api - settings - load (error)\n' + error.message);
            });

        //Refresh Options.
        let { GroupOptions, ClassroomOptions, SubjectOptions } = this.state.NewReportSettings;
        if (Array.isArray(_Data['CustomSelectedGroups'])) {
            GroupOptions = [];
            const _GroupOptions = _Data['CustomSelectedGroups'];
            _GroupOptions.map((data, key) => {
                return GroupOptions.push({
                    value: CheckObjectNumber(data, 'Id'),
                    label: CheckObjectStringEmpty(data, 'Name'),
                });
            });
        }
        if (Array.isArray(_Data['CustomSelectedClassrooms'])) {
            ClassroomOptions = [];
            const _ClassroomOptions = _Data['CustomSelectedClassrooms'];
            _ClassroomOptions.map((data, key) => {
                return ClassroomOptions.push({
                    // value: CheckObjectNumber(data, 'Id'),
                    value: CheckObjectStringEmpty(data, 'Name'),
                    label: CheckObjectStringEmpty(data, 'Name'),
                });
            });
        }
        if (Array.isArray(_Data['CustomSelectedSubjects'])) {     //new.
            SubjectOptions = [];
            const _SubjectOptions = _Data['CustomSelectedSubjects'];
            _SubjectOptions.map((data, key) => {
                return SubjectOptions.push({
                    value: CheckObjectNumber(data, 'Id'),
                    label: CheckObjectStringEmpty(data, 'Name'),
                });
            });
        }

        this.setState({
            NewReportSettings: {
                ...this.state.NewReportSettings,
                GroupOptions: GroupOptions,
                ClassroomOptions: ClassroomOptions,
                SubjectOptions: SubjectOptions,     //new.
            },
        }, () => {
            if (this.state.isDevMode) {
                console.log('LoadNewReportSettings_ViaApi (raw)\n' + JSON.stringify(_Data));
                console.log('LoadNewReportSettings_ViaApi (settings)\n' + JSON.stringify(this.state.NewReportSettings));
            }
        });
    }
    Populate_NewReportComponents = () => {
        let components = [];
        if (this.state.NewReportUiModal_Toggle) {
            if (this.state.NewReportStage === ReportStages.Init) {
                components.push(<span>initializing...</span>);
                components.push(<ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} />);
            }
            else if (this.state.NewReportStage === ReportStages.Processing) {
                components.push(<><span>Generating report...</span><br /></>);
                components.push(<ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10, marginBottom: 10, }} />);
                components.push(<><span>{this.state.NewReportProgressionStatus}</span><br /></>);
            }
            else if (this.state.NewReportStage === ReportStages.Error) {
                components.push(<><span>Failed to generate report.</span><br /></>);
                components.push(<><span>{CheckObjectStringEmpty(this.state.NewReportSettings, 'ErrorMessage')}</span><br /></>);
            }
            else if (this.state.NewReportStage === ReportStages.InfoTab) {
                components.push(this.NewReportNaviComponents());
                components.push(<hr />);
                const {
                    // OrganizerId, AuthorId, 
                    DateRangeBegin, DateRangeEnd,
                    Title, Description, Remark
                } = this.state.NewReportSettings['Info'];
                let tabComs = [];

                tabComs.push(<div className="form-group">
                    <label htmlFor={`new-report-${ReportSettingPropertyNames.Title}`}>Title</label>
                    <input className='form-control' type="text" style={{ width: '100%' }}
                        id={`new-report-${ReportSettingPropertyNames.Title}`}
                        onChange={(e) => this.SetNewReportInfo(ReportSettingPropertyNames.Title, e.target.value)}
                        defaultValue={Title}
                        disabled={this.state.NewReportStage === ReportStages.Processing}
                    />
                </div>);

                tabComs.push(<div className="form-group">
                    <label htmlFor={`new-report-${ReportSettingPropertyNames.Description}`}>Description</label>
                    <input className='form-control' type="text" style={{ width: '100%' }}
                        id={`new-report-${ReportSettingPropertyNames.Description}`}
                        onChange={(e) => this.SetNewReportInfo(ReportSettingPropertyNames.Description, e.target.value)}
                        defaultValue={Description}
                        disabled={this.state.NewReportStage === ReportStages.Processing}
                    />
                </div>);

                tabComs.push(<div className="form-group">
                    <label htmlFor={`new-report-${ReportSettingPropertyNames.Remark}`}>Remark</label>
                    <input className='form-control' type="text" style={{ width: '100%' }}
                        id={`new-report-${ReportSettingPropertyNames.Remark}`}
                        onChange={(e) => this.SetNewReportInfo(ReportSettingPropertyNames.Remark, e.target.value)}
                        defaultValue={Remark}
                        disabled={this.state.NewReportStage === ReportStages.Processing}
                    />
                </div>);

                tabComs.push(<div className="form-group">
                    <label htmlFor={`new-report-${ReportSettingPropertyNames.DateRangeBegin}`}>Date Begin</label>
                    <input className='form-control' type="date" style={{ width: '100%' }}
                        id={`new-report-${ReportSettingPropertyNames.DateRangeBegin}`}
                        onChange={(e) => this.SetNewReportInfo(ReportSettingPropertyNames.DateRangeBegin, e.target.value)}
                        defaultValue={DateRangeBegin}
                        disabled={this.state.NewReportStage === ReportStages.Processing}
                    />
                </div>);

                tabComs.push(<div className="form-group">
                    <label htmlFor={`new-report-${ReportSettingPropertyNames.DateRangeEnd}`}>Date End</label>
                    <input className='form-control' type="date" style={{ width: '100%' }}
                        id={`new-report-${ReportSettingPropertyNames.DateRangeEnd}`}
                        onChange={(e) => this.SetNewReportInfo(ReportSettingPropertyNames.DateRangeEnd, e.target.value)}
                        defaultValue={DateRangeEnd}
                        disabled={this.state.NewReportStage === ReportStages.Processing}
                    />
                </div>);

                components.push(<div style={{ display: 'flex', flexDirection: 'column' }}>{tabComs}</div>);
            }
            else if (this.state.NewReportStage === ReportStages.GroupTab) {
                components.push(this.NewReportNaviComponents());
                components.push(<hr />);
                const { GroupOptions, Groups } = this.state.NewReportSettings;
                let tbodyComs = [];
                if (Array.isArray(GroupOptions)) {
                    const selectedAll_group = Array.isArray(Groups) ? Groups.length === GroupOptions.length : false;
                    tbodyComs.push(<tr
                        onClick={() => this.SetNewReportInfo(ReportSettingPropertyNames.Groups, 'all', !selectedAll_group)}
                        style={{ cursor: 'pointer' }}
                    >
                        <td valign="middle" className="select">
                            <input type="checkbox" className="form-check form-check-input"
                                checked={selectedAll_group}
                                readOnly
                            ></input>
                        </td>
                        <td valign="middle">Select All</td>
                    </tr>);
                    GroupOptions.map((group, key) => {
                        const findIndex = Array.isArray(Groups) ? Groups.findIndex(x => Number(x) === Number(group.value)) : -1;
                        return tbodyComs.push(<tr
                            onClick={() => this.SetNewReportInfo(ReportSettingPropertyNames.Groups, group.value)}
                            style={{ cursor: 'pointer' }}
                        >
                            <td valign="middle" className="select">
                                <input type="checkbox" className="form-check form-check-input"
                                    checked={findIndex > -1}
                                    readOnly
                                ></input>
                            </td>
                            <td valign="middle">{CheckObjectStringEmpty(group, 'label', '-')}</td>
                        </tr>);
                    });
                }
                const tableCom = <table className="table table-hover tbStyle-options" border="1" style={{ width: 'max-content' }}>
                    <thead>
                        <tr>
                            <th className="select">Select</th>
                            <th>Group</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tbodyComs}
                    </tbody>
                </table>;
                components.push(<div style={{ display: 'flex', justifyContent: 'center' }}>{tableCom}</div>);
            }
            else if (this.state.NewReportStage === ReportStages.ClassroomTab) {
                components.push(this.NewReportNaviComponents());
                components.push(<hr />);
                const { ClassroomOptions, Classrooms } = this.state.NewReportSettings;
                let tbodyComs = [];
                if (Array.isArray(ClassroomOptions)) {
                    if (ClassroomOptions.length > 0) {
                        const selectedAll_classroom = Array.isArray(Classrooms) ? Classrooms.length === ClassroomOptions.length : false;
                        tbodyComs.push(<tr
                            onClick={() => this.SetNewReportInfo(ReportSettingPropertyNames.Classrooms, 'all', !selectedAll_classroom)}
                            style={{ cursor: 'pointer' }}
                        >
                            <td valign="middle" className="select">
                                <input type="checkbox" className="form-check form-check-input"
                                    checked={selectedAll_classroom}
                                    readOnly
                                ></input>
                            </td>
                            <td valign="middle">Select All</td>
                        </tr>);
                        ClassroomOptions.map((classroom, key) => {
                            const findIndex = Array.isArray(Classrooms) ? Classrooms.findIndex(x => String(x) === String(classroom.value)) : -1;
                            return tbodyComs.push(<tr
                                onClick={() => this.SetNewReportInfo(ReportSettingPropertyNames.Classrooms, classroom.value)}
                                style={{ cursor: 'pointer' }}
                            >
                                <td valign="middle" className="select">
                                    <input type="checkbox" className="form-check form-check-input"
                                        checked={findIndex > -1}
                                        readOnly
                                    ></input>
                                </td>
                                <td valign="middle">{CheckObjectStringEmpty(classroom, 'label', '-')}</td>
                            </tr>);
                        });
                    }
                    else {
                        tbodyComs.push(<tr><td colSpan={2}>- no selected group(s) -</td></tr>);
                    }
                }
                else {
                    tbodyComs.push(<tr><td colSpan={2}>- no classroom found -</td></tr>);
                }
                const tableCom = <table className="table table-hover tbStyle-options" border="1" style={{ width: 'max-content' }}>
                    <thead>
                        <tr>
                            <th className="select">Select</th>
                            <th>Classroom</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tbodyComs}
                    </tbody>
                </table>;
                components.push(<div style={{ display: 'flex', justifyContent: 'center' }}>{tableCom}</div>);
            }
            else if (this.state.NewReportStage === ReportStages.SubjectTab) {   //new.
                components.push(this.NewReportNaviComponents());
                components.push(<hr />);
                const { SubjectOptions, Subjects } = this.state.NewReportSettings;
                let tbodyComs = [];
                if (Array.isArray(SubjectOptions)) {
                    const selectedAll_subject = Array.isArray(Subjects) ? Subjects.length === SubjectOptions.length : false;
                    tbodyComs.push(<tr
                        onClick={() => this.SetNewReportInfo(ReportSettingPropertyNames.Subjects, 'all', !selectedAll_subject)}
                        style={{ cursor: 'pointer' }}
                    >
                        <td valign="middle" className="select">
                            <input type="checkbox" className="form-check form-check-input"
                                checked={selectedAll_subject}
                                readOnly
                            ></input>
                        </td>
                        <td valign="middle">Select All</td>
                    </tr>);
                    SubjectOptions.map((subject, key) => {
                        const findIndex = Array.isArray(Subjects) ? Subjects.findIndex(x => Number(x) === Number(subject.value)) : -1;
                        return tbodyComs.push(<tr
                            onClick={() => this.SetNewReportInfo(ReportSettingPropertyNames.Subjects, subject.value)}
                            style={{ cursor: 'pointer' }}
                        >
                            <td valign="middle" className="select">
                                <input type="checkbox" className="form-check form-check-input"
                                    checked={findIndex > -1}
                                    readOnly
                                ></input>
                            </td>
                            <td valign="middle">{CheckObjectStringEmpty(subject, 'label', '-')}</td>
                        </tr>);
                    });
                }
                const tableCom = <table className="table table-hover tbStyle-options" border="1" style={{ width: 'max-content' }}>
                    <thead>
                        <tr>
                            <th className="select">Select</th>
                            <th>Subject</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tbodyComs}
                    </tbody>
                </table>;
                components.push(<div style={{ display: 'flex', justifyContent: 'center' }}>{tableCom}</div>);
            }
        }
        // return (components);
        this.setState({ NewReport_Components: components, });
    }
    NewReportFooterComponents = () => {
        let components = [];
        const stage = this.state.NewReportStage;
        if (stage === ReportStages.Init || stage === ReportStages.Processing) { }
        else if (stage === ReportStages.Success || stage === ReportStages.Error) {
            components.push(<Button variant="secondary" onClick={() => this.ToggleNewReportUiModal(true)}>Cancel</Button>);
        }
        else {
            components.push(<Button variant="secondary" onClick={() => this.ToggleNewReportUiModal(true)}>Cancel</Button>);
            components.push(<Button variant="secondary" onClick={() => this.ResetNewReportUiModal()}>Reset</Button>);
            components.push(<Button variant="primary"
                onClick={() => this.ProcessGenerateNewReport_ViaApi()}
            >Confirm</Button>);
        }
        return (components);
    }
    NewReportNaviComponents = () => {
        let components = [];
        const stage = this.state.NewReportStage;
        components.push(<button type="button" className={"btn " + (stage === ReportStages.InfoTab ? 'btn-primary' : 'btn-outline-primary')} onClick={() => this.SetNewReportNaviStage(ReportStages.InfoTab)}>Info</button>);
        components.push(<button type="button" className={"btn " + (stage === ReportStages.GroupTab ? 'btn-primary' : 'btn-outline-primary')} onClick={() => this.SetNewReportNaviStage(ReportStages.GroupTab)}>Group</button>);
        components.push(<button type="button" className={"btn " + (stage === ReportStages.SubjectTab ? 'btn-primary' : 'btn-outline-primary')} onClick={() => this.SetNewReportNaviStage(ReportStages.SubjectTab)}>Subject</button>); //new.
        components.push(<button type="button" className={"btn " + (stage === ReportStages.ClassroomTab ? 'btn-primary' : 'btn-outline-primary')} onClick={() => this.SetNewReportNaviStage(ReportStages.ClassroomTab)}>Classroom</button>);
        return (<div style={{ display: 'flex', flexDirection: 'row', gap: 20, width: '100%', justifyContent: 'center', alignItems: 'baseline' }}>{components}</div>);
    }
    SetNewReportNaviStage = async (name = ReportStages.Init) => {
        switch (name) {
            case ReportStages.InfoTab:
                break;
            case ReportStages.GroupTab:
                break;
            case ReportStages.ClassroomTab:
                useAppService.getState().setModal('Loading', 'Fetching classrooms...', null, AlertMode.Loading);
                await this.LoadNewReportSettings_ViaApi(true);
                await Delay(1000);
                useAppService.getState().setModal();
                break;
            case ReportStages.SubjectTab:   //new.
                break;
            default: break;
        }
        this.setState({ NewReportStage: name, }, () => this.Populate_NewReportComponents());
    }
    SetNewReportInfo = (name = '', value = null, selectAll = null) => {
        if (CheckNullValue(name) === null || value === null)
            return null;
        if (this.state.isDevMode)
            console.log('SetNewReportInfo (value) \n' + JSON.stringify(value));
        let { Title, Description, Remark, DateRangeBegin, DateRangeEnd, } = this.state.NewReportSettings['Info'];
        let { Groups, Classrooms, Subjects, GroupOptions, ClassroomOptions, SubjectOptions } = this.state.NewReportSettings;
        switch (name) {
            case ReportSettingPropertyNames.Title:
                Title = CheckStringEmpty(value);
                if (this.state.isDevMode)
                    console.log(ReportSettingPropertyNames.Title + '\n' + String(value));
                break;
            case ReportSettingPropertyNames.Description:
                Description = CheckStringEmpty(value);
                if (this.state.isDevMode)
                    console.log(ReportSettingPropertyNames.Description + '\n' + String(value));
                break;
            case ReportSettingPropertyNames.Remark:
                Remark = CheckStringEmpty(value);
                if (this.state.isDevMode)
                    console.log(ReportSettingPropertyNames.Remark + '\n' + String(value));
                break;
            case ReportSettingPropertyNames.DateRangeBegin:
                DateRangeBegin = CheckStringEmpty(value);
                if (this.state.isDevMode)
                    console.log(ReportSettingPropertyNames.DateRangeBegin + '\n' + String(value));
                break;
            case ReportSettingPropertyNames.DateRangeEnd:
                DateRangeEnd = CheckStringEmpty(value);
                if (this.state.isDevMode)
                    console.log(ReportSettingPropertyNames.DateRangeEnd + '\n' + String(value));
                break;
            case ReportSettingPropertyNames.Groups:
                let tmp_groups = [];
                if (selectAll !== null) {
                    if (CheckBoolean(selectAll)) {
                        if (Array.isArray(GroupOptions)) {
                            GroupOptions.map((data, key) => {
                                return tmp_groups.push(String(data.value));
                            });
                        }
                    }
                }
                else {
                    if (Array.isArray(Groups)) {
                        tmp_groups = Groups;
                        const g_value = Number(value);
                        const findIndex_g = tmp_groups.findIndex(x => Number(x) === g_value);
                        if (findIndex_g < 0)
                            tmp_groups.push(g_value);
                        else
                            tmp_groups.splice(findIndex_g, 1);
                        tmp_groups.sort((a, b) => a - b);
                    }
                    else {
                        tmp_groups.push(Number(value));
                    }
                }
                Groups = tmp_groups;
                if (this.state.isDevMode)
                    console.log(ReportSettingPropertyNames.Groups + '\n' + JSON.stringify(tmp_groups));
                break;
            case ReportSettingPropertyNames.Classrooms:
                let tmp_classrooms = [];
                if (selectAll !== null) {
                    if (CheckBoolean(selectAll)) {
                        if (Array.isArray(ClassroomOptions)) {
                            ClassroomOptions.map((data, key) => {
                                return tmp_classrooms.push(String(data.value));
                            });
                        }
                    }
                }
                else {
                    if (Array.isArray(Classrooms)) {
                        tmp_classrooms = Classrooms;
                        const c_value = String(value);
                        const findIndex_c = tmp_classrooms.findIndex(x => String(x) === c_value);
                        if (findIndex_c < 0)
                            tmp_classrooms.push(c_value);
                        else
                            tmp_classrooms.splice(findIndex_c, 1);
                        tmp_classrooms.sort((a, b) => String(a).localeCompare(String(b)));
                        // tmp_classrooms.sort((a, b) => {
                        //     if (a < b) { return -1 };
                        //     if (a > b) { return 1 };
                        //     return 0;
                        // });
                    }
                    else {
                        tmp_classrooms.push(String(value));
                    }
                }
                Classrooms = tmp_classrooms;
                if (this.state.isDevMode)
                    console.log(ReportSettingPropertyNames.Classrooms + '\n' + JSON.stringify(tmp_classrooms));
                break;
            case ReportSettingPropertyNames.Subjects:   //new.
                let tmp_subjects = [];
                if (selectAll !== null) {
                    if (CheckBoolean(selectAll)) {
                        if (Array.isArray(SubjectOptions)) {
                            SubjectOptions.map((data, key) => {
                                return tmp_subjects.push(String(data.value));
                            });
                        }
                    }
                }
                else {
                    if (Array.isArray(Subjects)) {
                        tmp_subjects = Subjects;
                        const s_value = Number(value);
                        const findIndex_s = tmp_subjects.findIndex(x => Number(x) === s_value);
                        if (findIndex_s < 0)
                            tmp_subjects.push(s_value);
                        else
                            tmp_subjects.splice(findIndex_s, 1);
                        tmp_subjects.sort((a, b) => a - b);
                    }
                    else {
                        tmp_subjects.push(Number(value));
                    }
                }
                Subjects = tmp_subjects;
                if (this.state.isDevMode)
                    console.log(ReportSettingPropertyNames.Subjects + '\n' + JSON.stringify(tmp_subjects));
                break;
            default: break;
        }
        this.setState({
            NewReportSettings: {
                ...this.state.NewReportSettings,
                Info: {
                    Title: Title,
                    Description: Description,
                    Remark: Remark,
                    DateRangeBegin: DateRangeBegin,
                    DateRangeEnd: DateRangeEnd,
                },
                Groups: Groups,
                Classrooms: Classrooms,
                Subjects: Subjects,
            },
        }, () => {
            this.Populate_NewReportComponents();
        });
    }
    ProcessGenerateNewReport_ViaApi = async () => {
        if (this.state.PA_Create === false)
            return null;

        this.setState({
            NewReportStage: ReportStages.Processing,
        }, () => {
            this.Populate_NewReportComponents();
        });

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);

        if (this.state.isDevMode === false) {
            // await setDoc(doc(firestore, 'ILE_Portal_Status_Logs', uid), { 'status': '', 'log': '', 'uid': uid });
            // await Delay(200);
            // this.ProcessingMessage_Subscribe(uid);
        }

        const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/Organizer/Report/Student/Statistic/Generate';

        const {
            GroupOptions,
            ClassroomOptions,
            SubjectOptions, //new.
            Info,
            Groups,
            Classrooms,
            Subjects,   //new.
        } = this.state.NewReportSettings;

        let selectedGroupOptions = [];
        if (Array.isArray(Groups) && Array.isArray(GroupOptions)) {
            GroupOptions.map((option, key) => {
                const findIndex = Groups.findIndex(x => Number(x) === Number(option.value));
                return selectedGroupOptions.push({
                    Id: option.value,
                    Name: option.label,
                    Selected: findIndex > -1,
                });
            });
        }
        let selectedClassroomOptions = [];
        if (Array.isArray(Classrooms) && Array.isArray(ClassroomOptions)) {
            ClassroomOptions.map((option, key) => {
                const findIndex = Classrooms.findIndex(x => String(x) === String(option.value));
                return selectedClassroomOptions.push({
                    Id: key + 1,
                    Name: option.value,
                    Selected: findIndex > -1,
                });
            });
        }
        let selectedSubjectOptions = [];    //new.
        if (Array.isArray(Subjects) && Array.isArray(SubjectOptions)) {     //new.
            SubjectOptions.map((option, key) => {
                const findIndex = Subjects.findIndex(x => Number(x) === Number(option.value));
                return selectedSubjectOptions.push({
                    Id: option.value,
                    Name: option.label,
                    Selected: findIndex > -1,
                });
            });
        }
        const jsonModal = JSON.stringify({
            OrganizerId: organizerId,
            AuthorId: authorId,
            DateRangeBegin: Info.DateRangeBegin,
            DateRangeEnd: Info.DateRangeEnd,
            Title: Info.Title,
            Description: Info.Description,
            Remark: Info.Remark,
            Settings: {
                DateRangeBegin: Info.DateRangeBegin,
                DateRangeEnd: Info.DateRangeEnd,
                CustomSelectedGroups: selectedGroupOptions,
                CustomSelectedClassrooms: selectedClassroomOptions,
                CustomSelectedSubjects: selectedSubjectOptions,     //new.
            }
        });

        if (this.state.isDevMode)
            console.log(`ProcessGenerateNewReport_ViaApi \n${url}\n${jsonModal}`);

        let success = false;
        let errorMessage = '';
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: jsonModal,
            })
            .then(res => res.json())
            .then(data => {
                success = CheckObjectBoolean(data, 'success');
                if (success) { }
                else {
                    errorMessage = CheckObjectStringEmpty(data, 'message');
                    if (this.state.isDevMode)
                        console.log('Error', 'api - new report - generate (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                if (this.state.isDevMode)
                    console.log('Error', 'api - new report - generate (error)\n' + error.message);
            });

        // if (this.state.isDevMode === false)
        //     this.ProcessingMessage_Unsubscribe();

        this.setState({
            NewReportStage: success ? ReportStages.Success : ReportStages.Error,
        }, () => {
            switch (this.state.NewReportStage) {
                case ReportStages.Success:
                    useAppService.getState().setModal('Success', 'Report has been successfully generated.');
                    this.LoadList_ViaApi();
                    break;
                case ReportStages.Error:
                    useAppService.getState().setModal('Error', 'Failed to generate report.' + (errorMessage === '' ? '' : '<br /><br />' + errorMessage));
                    break;
                default: break;
            }
            this.ToggleNewReportUiModal(true);
        });
    }
    //#endregion === Report - New / Generate ===

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        return (<div className="">
            <table className="table page-header-1">
                <tbody>
                    <tr>
                        <td className="left">
                            <h5>Student Statistic Report</h5>
                        </td>
                        <td className="center"></td>
                        <td className="right">
                            {
                                this.state.IsSuperAdmin || this.state.PA_Search ?
                                    <Button
                                        variant='outline-primary'
                                        onClick={() => this.ToggleSearchUiModal()}
                                        disabled={!this.state.PA_Search}
                                    // disabled={true}
                                    >Search Report</Button>
                                    : null
                            }
                            {
                                this.state.IsSuperAdmin || this.state.PA_Create ?
                                    <Button
                                        variant='outline-primary'
                                        onClick={() => this.ToggleNewReportUiModal()}
                                        disabled={!this.state.PA_Create}
                                    // disabled={true}
                                    >Generate Report</Button>
                                    : null
                            }
                            <Button
                                variant='outline-primary'
                                onClick={() => this.setState({ redirectLink: getMenuLink(LayoutScreen.Dashboard), redirect: true, })}
                            >Back to Dashboard</Button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='table table-hover table-bordered tbStyle' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
                <thead>
                    <tr>
                        <th width='50'>#</th>
                        <th width='275'>Date Range</th>
                        <th className="left">Title</th>
                        <th width='100'>Settings</th>
                        <th width='100'>Description</th>
                        <th width='100'>Remark</th>
                        <th width='115'>Created Date</th>
                        <th width='225'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.isLoading && !this.state.IsListLoaded ?
                            <tr><td colSpan={15} height={63}><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></td></tr>
                            : this.state.List.length > 0 ?
                                this.ListComponents()
                                : <tr><td colSpan={15} align='center'>list is empty</td></tr>
                    }
                    {
                        this.state.List.length === 0 ? null :
                            PagingComponents(15, this.state.TotalRows, this.state.PageIndex, this.state.PageSize, this.CallbackFunctionForPagingComponents_PageSize, this.CallbackFunctionForPagingComponents_PageIndex)
                    }
                </tbody>
            </table>

            {/* Report - Search Report by Params - Modal */}
            <Modal show={this.state.SearchUiModal_Toggle}
                onHide={() => this.state.SearchByCondition_Processing ? DoNothing() : this.ToggleSearchUiModal(true)}
                centered>
                <Modal.Header closeButton={this.state.SearchByCondition_Processing === false}>
                    <Modal.Title>{
                        this.state.SearchByCondition_Processing ? 'Searching...' : 'Search Report'
                    }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.SearchByCondition_Processing ?
                            <ProgressBar animated now={100} className='progressbar1' />
                            :
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {/* {
                                    Object.keys(this.state.SearchConditions).map((name, key) => {
                                        return (<div className="form-group">
                                            <label htmlFor={`search-report-by-${name}`}>{TextSpacing(name)}</label>
                                            <input className='form-control' type="text" style={{ width: '100%' }}
                                                id={`search-report-by-${name}`}
                                                onChange={(e) => {
                                                    let tmp = this.state.SearchConditions;
                                                    tmp[name] = String(e.target.value);
                                                    this.setState({ SearchConditions: tmp });
                                                }}
                                                disabled={this.state.SearchByCondition_Processing}
                                            />
                                        </div>);
                                    })
                                } */}

                                <div className="form-group">
                                    <label htmlFor='search-report-by-Title'>Title</label>
                                    <input className='form-control' type="text" style={{ width: '100%' }}
                                        id='search-report-by-Title'
                                        onChange={(e) => this.SetSearchConditions(SearchConditionOptions.Title, e.target.value)}
                                        disabled={this.state.SearchByCondition_Processing}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor='search-report-by-DateBegin'>Date Begin</label>
                                    <input className='form-control' type="date" style={{ width: '100%' }}
                                        id='search-report-by-DateBegin'
                                        onChange={(e) => this.SetSearchConditions(SearchConditionOptions.DateBegin, e.target.value)}
                                        disabled={this.state.SearchByCondition_Processing}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor='search-report-by-DateEnd'>Date End</label>
                                    <input className='form-control' type="date" style={{ width: '100%' }}
                                        id='search-report-by-DateEnd'
                                        onChange={(e) => this.SetSearchConditions(SearchConditionOptions.DateEnd, e.target.value)}
                                        disabled={this.state.SearchByCondition_Processing}
                                    />
                                </div>

                                <div className="form-group" id="search-report-by-Groups-fg">
                                    <label htmlFor='search-report-by-Groups'>Groups</label>
                                    <ReactSelect
                                        id='search-report-by-Groups'
                                        name='Groups'
                                        className="basic-multi-select"
                                        classNamePrefix='select'
                                        isMulti
                                        closeMenuOnSelect={false}
                                        options={this.state.SearchSettings.GroupOptions}
                                        defaultValue={this.GetSelectedGroups()}
                                        onChange={(option) => this.SetSearchConditions(SearchConditionOptions.Groups, option)}
                                        theme={theme => ({
                                            ...theme,
                                            width: 'max-content',
                                            colors: {
                                                ...theme.colors,
                                                neutral50: 'black',  // placeholder color
                                            }
                                        })}
                                    />
                                </div>

                                <div className="form-group" id="search-report-by-Classrooms-fg">
                                    <label htmlFor='search-report-by-Classrooms'>Classrooms</label>
                                    <ReactSelect
                                        id='search-report-by-Classrooms'
                                        name='Classrooms'
                                        className="basic-multi-select"
                                        classNamePrefix='select'
                                        isMulti
                                        closeMenuOnSelect={false}
                                        options={this.state.SearchSettings.ClassroomOptions}
                                        defaultValue={this.GetSelectedClassrooms()}
                                        onChange={(option) => this.SetSearchConditions(SearchConditionOptions.Classrooms, option)}
                                        theme={theme => ({
                                            ...theme,
                                            width: 'max-content',
                                            colors: {
                                                ...theme.colors,
                                                neutral50: 'black',  // placeholder color
                                            }
                                        })}
                                    />
                                </div>
                                <div className="form-group" id="search-report-by-Classrooms-fg-loading" style={{ display: 'none' }}>
                                    <label htmlFor='search-report-by-Classrooms'>Classrooms</label>
                                    <ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 7 }} />
                                </div>

                            </div>
                    }
                </Modal.Body>
                {
                    !this.state.SearchByCondition_Processing ?
                        <Modal.Footer>
                            <Button variant="secondary"
                                onClick={() => this.ToggleSearchUiModal(true)}
                            >Cancel</Button>
                            <Button variant="secondary"
                                onClick={() => this.ResetSearchParams(true)}
                            >Reset</Button>
                            <Button variant="primary"
                                onClick={() => this.LoadList_ViaApi(true)}
                            // disabled={this.state.SearchConditions !== Default_SearchConditions}
                            >Search</Button>
                        </Modal.Footer>
                        : null
                }
            </Modal>

            {/* Report - View - Modal
            <Modal show={this.state.ViewReportUiModal_Toggle} onHide={() => this.ToggleViewReportUiModal()} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Report {
                        this.state.SelectedItem === null ? '' :
                            '<' + CheckObjectStringEmpty(this.state.SelectedItem, 'Title', '-') + '>'
                    }</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.state.ViewReport_Components}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ToggleViewReportUiModal()}>Close</Button>
                    <Button variant="primary"
                        onClick={() => this.DownloadReportFile(this.state.SelectedItemIndex)}
                        hidden={this.state.SelectedItem === null}
                    >Download Report</Button>
                </Modal.Footer>
            </Modal> */}

            {/* Report - View - Modal */}
            <Modal size="lg" show={this.state.ViewReportUiModal_Toggle} onHide={() => this.ToggleViewReportUiModal()} centered>
                <Modal.Header closeButton><Modal.Title>Report - Student Statistic</Modal.Title></Modal.Header>
                <Modal.Body>{this.state.ViewReport_Components}</Modal.Body>
                <Modal.Footer>{this.ViewReportFooterComponents()}</Modal.Footer>
            </Modal>

            {/* Report - Delete - Modal */}
            <Modal show={this.state.DeleteReportUiModal_Toggle} onHide={() => this.ToggleDeleteReportUiModal()} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Remove Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        Array.isArray(this.state.List) && this.state.SelectedItemIndex > -1 ?
                            <span>
                                Title: <b>{CheckObjectStringEmpty(this.state.List[this.state.SelectedItemIndex], 'title')}</b>
                                <br />Do you sure you want to <b>remove</b> this report ?
                                <br /><b><i>The removal is not reversible.</i></b>
                            </span>
                            :
                            <span>Invalid index, unable to remove this report.</span>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ToggleDeleteReportUiModal()}>Cancel</Button>
                    <Button variant="primary"
                        onClick={() => this.ProcessDeleteReport_ViaApi()}
                        hidden={Array.isArray(this.state.List) && this.state.SelectedItemIndex > -1 ? false : true}
                    >Confirm</Button>
                </Modal.Footer>
            </Modal>

            {/* Report - New / Generate - Modal */}
            <Modal show={this.state.NewReportUiModal_Toggle} onHide={() => this.ToggleNewReportUiModal(true)} centered>
                <Modal.Header closeButton><Modal.Title>New Report</Modal.Title></Modal.Header>
                <Modal.Body>{this.state.NewReport_Components}</Modal.Body>
                <Modal.Footer>{this.NewReportFooterComponents()}</Modal.Footer>
            </Modal>

        </div>);
    }
}