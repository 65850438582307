import React, { createRef } from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";

// import { Locale } from "../utilities/localization/CustomLocalization";
import { Access, GlobalSetting, LayoutScreen, PermissionAccessType, Permissions, SettingMenu } from "../utilities/GlobalSetting";
import { useGlobal } from "../utilities/GlobalVariables";
import { useAppService } from "../services/AppService";
import { AlertMode } from "./AlertComponent";
import { byteConverter, CapitalizeJsonKeys, CheckBoolean, CheckNumber, CheckObjectBoolean, CheckObjectNumber, CheckObjectStringEmpty, CheckStringEmpty, ConsoleLog, Delay, GetPropIds, MAP_Check, PermissionAccess, TriggerDownloadFile } from "../utilities/GlobalFunctions";

import SettingEducationStage from "./Settings/SettingEducationStage";
import SettingCustomGroup from "./Settings/SettingCustomGroup";
import SettingSubject from "./Settings/SettingSubject";
import SettingClassroom from "./Settings/SettingClassroom";
import SettingGroup from "./Settings/SettingGroup";
import SettingMedia from "./Settings/SettingMedia";
// import ManageReportRoomsResult from "./Reports/ManageReportRoomsResult";

export default class ManageSettingScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.

        this.SettingCustomGroup_Ref = createRef();
        this.SettingEducationStage_Ref = createRef();
        this.SettingSubject_Ref = createRef();
        this.SettingClassroom_Ref = createRef();
        this.SettingGroup_Ref = createRef();    //2024.11.05
        this.SettingMedia_Ref = createRef();    //2025.02.21
    }

    getInitState = () => ({
        isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        redirect: false,
        redirectLink: '/',
        isLoading: false,

        isSuperAdmin: false,
        isMasterAdmin: false,
        isAdmin: false,
        isTeacher: false,
        // isAuthor: false,
        screen: LayoutScreen.ManageSetting,
        Option: null,

        popupModal_Toggle: false,
        popupModal: { title: '', content: null },   //2024.08.13

        SelectedGroupId: 0,     //2025.01.17
        SettingGroupOptions: [],    //2025.02.18
    });

    componentWillUnmount = () => { }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        this.setState({
            screen: CheckStringEmpty(useGlobal.getState().screen, LayoutScreen.ManageSetting),
            isSuperAdmin: CheckBoolean(useGlobal.getState().isSuperAdmin),
            isMasterAdmin: CheckBoolean(useGlobal.getState().isMasterAdmin),
            isAdmin: CheckBoolean(useGlobal.getState().isAdmin),
            isTeacher: CheckBoolean(useGlobal.getState().isTeacher),
            // isAuthor: CheckBoolean(useGlobal.getState().isAuthor),
        });
    }

    SetMenuOption = (option = null) => {
        if (option === null)
            return null;
        this.setState({ Option: option, });
        const screen = CheckObjectStringEmpty(option, 'Screen');
        if (screen !== '') {
            useGlobal.getState().setScreen(screen);
            this.setState({ screen: screen, });
            ConsoleLog('SetSettingOption = ' + screen + ' / ' + useGlobal.getState().screen + '\n' + JSON.stringify(option));
            if (typeof useGlobal.getState().refreshListCallbackFn === 'function')
                useGlobal.getState().refreshListCallbackFn();
        }
    }

    //2024.08.09
    GenerateStudentPasswordsFile_ViaApi = async () => {
        useAppService.getState().setModal('', 'generating file...', null, AlertMode.Loading);

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Student/Password/Generate/Excel/${organizerId}/${authorId}/${CheckNumber(this.state.SelectedGroupId)}`;

        let response = null;
        let success = false;
        let msg = '';

        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('GenerateStudentPasswordsFile_ViaApi =\n' + JSON.stringify(data));

                success = CheckObjectBoolean(data, 'success');
                response = data.data;

                if (!success)
                    msg = CheckObjectStringEmpty(data, 'message');
            })
            .catch(error => {
                msg = error.message;
                if (this.state.isDevMode)
                    console.log('Error', 'api - generate (error)\n' + error.message);
            });

        useAppService.getState().setModal();
        if (success) {
            if (response !== null) {
                response = CapitalizeJsonKeys(response);
                const fileName = CheckObjectStringEmpty(response, 'FileName');
                const fileExt = CheckObjectStringEmpty(response, 'FileExt');
                const fileSize = byteConverter(CheckObjectNumber(response, 'FileSize'), 2);
                const url = CheckObjectStringEmpty(response, 'Url');
                this.setState({
                    popupModal_Toggle: true,
                    popupModal: {
                        title: 'Generate Student Passwords File',
                        content: <table cellPadding={5} cellSpacing={5} width={'100%'}><tbody>
                            <tr>
                                <td style={{ width: 85, verticalAlign: 'top' }}>File Name</td>
                                <td style={{ width: 20, verticalAlign: 'top' }}>:</td>
                                <td>{fileName}</td></tr>
                            <tr><td>File Size</td><td>:</td><td>{fileSize}</td></tr>
                        </tbody></table>,
                        footer: <button type="button" className="btn btn-primary" onClick={() => TriggerDownloadFile(url, fileName, fileExt)} >Download File</button>,
                    },
                });
            }
            else {
                this.setState({
                    popupModal_Toggle: true,
                    popupModal: {
                        title: '(Failed) Generate Student Passwords File',
                        content: 'Invalid response.',
                        footer: null,
                    },
                });
            }
        }
        else {
            this.setState({
                popupModal_Toggle: true,
                popupModal: {
                    title: '(Failed) Generate Student Passwords File',
                    content: 'Failed to generate file.<br /><br />' + msg,
                    footer: null,
                },
            });
        }
    }

    //2024.08.13
    GenerateTeacherPasswordsFile_ViaApi = async () => {
        useAppService.getState().setModal('', 'generating file...', null, AlertMode.Loading);

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Teacher/Password/Generate/Excel/${organizerId}/${authorId}/${0}`;

        let response = null;
        let success = false;
        let msg = '';

        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('GenerateTeacherPasswordsFile_ViaApi =\n' + JSON.stringify(data));

                success = CheckObjectBoolean(data, 'success');
                response = data.data;

                if (!success)
                    msg = CheckObjectStringEmpty(data, 'message');
            })
            .catch(error => {
                msg = error.message;
                if (this.state.isDevMode)
                    console.log('Error', 'api - generate (error)\n' + error.message);
            });

        useAppService.getState().setModal();
        if (success) {
            if (response !== null) {
                response = CapitalizeJsonKeys(response);
                const fileName = CheckObjectStringEmpty(response, 'FileName');
                const fileExt = CheckObjectStringEmpty(response, 'FileExt');
                const fileSize = byteConverter(CheckObjectNumber(response, 'FileSize'), 2);
                const url = CheckObjectStringEmpty(response, 'Url');
                this.setState({
                    popupModal_Toggle: true,
                    popupModal: {
                        title: 'Generate Teacher Passwords File',
                        content: <table cellPadding={5} cellSpacing={5} width={'100%'}><tbody>
                            <tr>
                                <td style={{ width: 85, verticalAlign: 'top' }}>File Name</td>
                                <td style={{ width: 20, verticalAlign: 'top' }}>:</td>
                                <td>{fileName}</td></tr>
                            <tr><td>File Size</td><td>:</td><td>{fileSize}</td></tr>
                        </tbody></table>,
                        footer: <button type="button" className="btn btn-primary" onClick={() => TriggerDownloadFile(url, fileName, fileExt)} >Download File</button>,
                    },
                });
            }
            else {
                this.setState({
                    popupModal_Toggle: true,
                    popupModal: {
                        title: '(Failed) Generate Teacher Passwords File',
                        content: 'Invalid response.',
                        footer: null,
                    },
                });
            }
        }
        else {
            this.setState({
                popupModal_Toggle: true,
                popupModal: {
                    title: '(Failed) Generate Teacher Passwords File',
                    content: 'Failed to generate file.<br /><br />' + msg,
                    footer: null,
                },
            });
        }
    }

    //2024.08.13
    GenerateConfirmButton = (fn = null, btnText = null) => {
        return <Button variant="primary"
            onClick={() => this.setState({
                popupModal_Toggle: false,
                popupModal: { title: '', content: null, footer: null, },
            }, () => {
                if (typeof fn === 'function')
                    fn();
            })}
        >{btnText === null ? 'Confirm' : btnText}</Button>;
    }

    MenuButtonComponents = () => {
        let components = [];

        if (this.state.isSuperAdmin || this.state.isMasterAdmin || this.state.isAdmin) {

            //2025.03.10
            if (MAP_Check(LayoutScreen.ManageSetting, Permissions.GenerateStudentPasswordFile)) {
                //2024.08.09
                components.push(<Button key='ms-mp-btn-password-student' variant='warning'
                    onClick={async () => {
                        // const confirm = window.confirm('Confirm to generate file ?');
                        // if (confirm)
                        //     this.GenerateStudentPasswordsFile_ViaApi();
                        this.Populate_SettingGroupOptions();
                        await Delay(0);
                        this.Populate_GenerateStudentPassword_PopupModalComponent();
                    }}
                >Generate<br />Passwords File<br />(Student)</Button>);
            }

            //2025.03.10
            if (MAP_Check(LayoutScreen.ManageSetting, Permissions.GenerateTeacherPasswordFile)) {
                //2024.08.13
                components.push(<Button key='ms-mp-btn-password-teacher' variant='warning'
                    onClick={() => {
                        // const confirm = window.confirm('Confirm to generate file ?');
                        // if (confirm)
                        //     this.GenerateTeacherPasswordsFile_ViaApi();
                        this.setState({
                            popupModal_Toggle: true,
                            popupModal: {
                                title: 'Generate Teacher Passwords File',
                                content: 'Confirm to generate file ?',
                                footer: this.GenerateConfirmButton(this.GenerateTeacherPasswordsFile_ViaApi),
                            },
                        });
                    }}
                >Generate<br />Passwords File<br />(Teacher)</Button>);
            }
        }

        Object.keys(SettingMenu).map((name, key) => {
            const data = SettingMenu[name];
            // if (data.ShowInMenu)
            //     if (data.Show === Access.AdminOnly && this.state.isAdmin)
            if (data.ShowInMenu) {
                let allowAccess = false;
                if (data.Show === Access.ByPermission) {
                    allowAccess = PermissionAccess(data.Screen, PermissionAccessType.View);
                }
                else if (data.Show === Access.All
                    || (data.Show === Access.SuperAdminOnly && this.state.isSuperAdmin)
                    || (data.Show === Access.MasterAdminAndAbove && this.state.isMasterAdmin)
                    || (data.Show === Access.AdminAndAbove && this.state.isAdmin)) {
                    allowAccess = true;
                }
                if (allowAccess) {
                    // if (ShowOrHideMenu(data)) {
                    components.push(<Button
                        key={'ms-mp-btn-' + key}
                        variant={this.state.screen === LayoutScreen.ManageSetting || data.Screen === this.state.screen ? 'primary' : 'secondary'}
                        // onClick={() => data.Disabled || data.Screen === this.state.screen ? DoNothing() : this.SetSettingOption(data)}
                        onClick={() => this.SetMenuOption(data)}
                        style={data.Disabled ? { userSelect: 'none', cursor: 'not-allowed' } : {}}
                    >{data.Label}</Button>);
                }
            }
            return null;
        });
        return (components);
    }

    Populate_SettingGroupOptions = () => {
        let options = JSON.parse(JSON.stringify(useAppService.getState().groupOptions));
        options.unshift({ id: 0, value: '0', label: 'All' });
        this.setState({ SettingGroupOptions: options, });
    }

    //2025.01.17
    Populate_GenerateStudentPassword_PopupModalComponent = () => {
        this.setState({
            popupModal_Toggle: true,
            popupModal: {
                title: 'Generate Student Passwords File',
                content: <>
                    <div className="form-group">
                        <label for='generate-password-student-select-group'>Select Group (optional)</label>
                        <Select
                            id='generate-password-student-select-group'
                            // className='form-control'
                            // options={useAppService.getState().groupOptions}
                            options={this.state.SettingGroupOptions}
                            placeholder={
                                // this.state.SelectedGroupId === 0 ?
                                //     Locale("not-specify-group", this.state.locale) :
                                //     useAppService.getState().groupOptions.find(x => Number(x.id) === Number(this.state.SelectedGroupId))?.label
                                this.state.SettingGroupOptions.find(x => Number(x.id) === CheckNumber(this.state.SelectedGroupId))?.label
                            }
                            value={CheckNumber(this.state.SelectedGroupId)}
                            onChange={(option) => {
                                this.setState({
                                    SelectedGroupId: Number(option.id),
                                }, () => {
                                    this.Populate_GenerateStudentPassword_PopupModalComponent();
                                    if (this.state.isDevMode)
                                        console.log(`Selected Group (${this.state.SelectedGroupId}) = ` + JSON.stringify(option));
                                });
                            }} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 5 }}>{'Confirm to generate file ?'}</div>
                </>,
                footer: this.GenerateConfirmButton(this.GenerateStudentPasswordsFile_ViaApi),
            },
        });
    }

    MainPanelComponent = () => {
        switch (CheckObjectStringEmpty(this.state.Option, 'Screen')) {

            //Manage Custom Group.
            case SettingMenu['customGroup'].Screen:
                return (<SettingCustomGroup ref={ref => ref = this.SettingCustomGroup_Ref} />);

            //Manage Education Stage.
            case SettingMenu['educationStage'].Screen:
                return (<SettingEducationStage ref={ref => ref = this.SettingEducationStage_Ref} />);

            //Manage Custom Subject.
            case SettingMenu['subject'].Screen:
                return (<SettingSubject ref={ref => ref = this.SettingSubject_Ref} />);

            //Manage Classroom.
            case SettingMenu['classroom'].Screen:
                return (<SettingClassroom ref={ref => ref = this.SettingClassroom_Ref} />);

            //Manage Group.
            case SettingMenu['group'].Screen:
                return (<SettingGroup ref={ref => ref = this.SettingGroup_Ref} />);

            //Manage Media.
            case SettingMenu['media'].Screen:
                return (<SettingMedia ref={ref => ref = this.SettingMedia_Ref} />);

            //Manage Settings - buttons.
            default:
                return (<div className="manage-setting-buttons">
                    <h4>Manage Settings</h4>
                    {this.MenuButtonComponents()}
                </div>);
        }
    }

    //2025.02.18
    ClosePopupModal = async () => {
        this.setState({ popupModal_Toggle: false, });
        await Delay(200);
        this.setState({ popupModal: { title: '', content: null }, SelectedGroupId: 0, });
    }

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        return (<>
            <div className="manage-setting">
                {
                    this.state.isAdmin === false ?
                        <span>Invalid permission.</span>
                        :
                        this.state.screen === LayoutScreen.ManageSetting ?
                            this.MainPanelComponent()
                            :
                            <>
                                <div className="left-panel">{this.MenuButtonComponents()}</div>
                                <div className="main-panel">{this.MainPanelComponent()}</div>
                            </>
                }
            </div>

            {/* Popup - Modal */}
            <Modal show={this.state.popupModal_Toggle} onHide={() => this.ClosePopupModal()} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.popupModal?.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        typeof this.state.popupModal?.content === 'string' ?
                            <div dangerouslySetInnerHTML={{ __html: this.state.popupModal?.content }}></div>
                            :
                            this.state.popupModal?.content
                    }
                    {/* <div dangerouslySetInnerHTML={{ __html: this.state.popupModal?.content }}></div> */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ClosePopupModal()}>Close</Button>
                    {this.state.popupModal?.footer}
                </Modal.Footer>
            </Modal>
        </>);
    }
}