import React from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { Button, Col, Dropdown, DropdownButton, Modal, OverlayTrigger, ProgressBar, Row, Tooltip } from "react-bootstrap";
import moment from "moment";
// import Select from "react-select";
// import { collection, count, getDocs, limit, query, where } from "firebase/firestore";
// import { firestore } from "../utilities/Firebase";

import { CheckBoolean, CheckNullValue, CheckNumber, CheckObjectBoolean, CheckObjectNullValue, CheckObjectNumber, CheckObjectStringEmpty, CheckStringEmpty, DecapitalizeJsonKeys, Delay, DelayUntil, GetPropIds, PagingComponents, PermissionAccess, RandomId, ScrollToElement, ToBase64 } from "../utilities/GlobalFunctions";
import { getMenuLink, GlobalSetting, LayoutScreen, PermissionAccessType, SecretKey } from "../utilities/GlobalSetting";
import { useGlobal } from "../utilities/GlobalVariables";
import { useAppService } from "../services/AppService";
import { Lang, Locale } from "../utilities/localization/CustomLocalization";
import { AlertMode } from "./AlertComponent";

const t_dateStart_registrationStart = moment(moment.utc().format('YYYY-MM-DDT00:00')).add(-1, 'month').format('YYYY-MM-DDTHH:mm');
const t_dateStart = moment(moment.utc().format('YYYY-MM-DDT00:00')).add(-1, 'day').format('YYYY-MM-DDTHH:mm');
const t_dateEnd = moment(t_dateStart).add(1, 'month').add(-1, 'day').format('YYYY-MM-DDTHH:mm');
const t_date = moment(t_dateStart).format('MMM DD (ddd)') + ' ~ ' + moment(t_dateEnd).format('MMM DD (ddd)') + ', ' + moment(t_dateStart).format('YYYY');

// const settingTitle = 'Event';
const SearchCondition = {
    // None: 'none',
    Name: 'Name',
    Email: 'Email',
    Grade: 'Grade',
    Classroom: 'Classroom',
    SchoolName: 'School Name',
};
const CertLayoutTypeOptions = [
    { value: 1, label: 'Type 1 (Participant Name only)', name: 'basic', subLabel: 'Type 1' },
    { value: 2, label: 'Type 2 (Participant Name, Serial Number, School Name)', name: 'basic', subLabel: 'Type 2' },
    { value: 3, label: 'Type 3 (Participant Name, Serial Number)', name: 'basic', subLabel: 'Type 3' },
    { value: 4, label: 'Type 4 (Participant Name, School Name)', name: 'basic', subLabel: 'Type 4' }
];
const PublishModeOptions = [
    { value: 0, label: 'Mode 0 - Admin, Testing (Not visible to anyone except Admin)', name: 'admin', subLabel: 'Admin, Testing', role: 'Admin' },
    { value: 1, label: 'Mode 1 - Public (Global, visible in Event list by everyone)', name: 'global', subLabel: 'Public', role: 'Global' },
    { value: 2, label: 'Mode 2 - Private (Visible only in Event list via Organizer domain)', name: 'private', subLabel: 'Private', role: 'Private' }
];
const FileMode = {
    None: 0,
    CertImg: 1,
    PAJSK: 2,
    CertImgTop100: 3,   //2022.09.30
    CertImgHonor: 4,    //2022.09.30
};
const UploadState = {
    None: 0,
    Browse: 1,
    Validation: 2,
    Uploading: 3,
    Failed: 4,
    Success: 5,
};
const DataInput = {
    Id: 'id',
    OrganizerId: 'organizerId',
    AuthorId: 'authorId',

    EventCode: 'eventCode',
    EventName: 'eventName',
    Active: 'active',
    IsPublic: 'isPublic',

    CertDownloadEnabled: 'certDownloadEnabled',
    CertDownloadDelayed: 'certDownloadDelayed',
    CertDownloadDelayedEndDateTime: 'certDownloadDelayedEndDateTime',
    CertImgFileId: 'certImgFileId',
    CertImgUrl: 'certImgUrl',
    CertLayoutTypeId: 'certLayoutTypeId',
    CertSerialPrefix: 'certSerialPrefix',

    Date: 'date',
    DateStart: 'dateStart',
    DateEnd: 'dateEnd',
    TimeStart: 'timeStart',
    TimeEnd: 'timeEnd',
    TimeStartDisplayText: 'timeStartDisplayText',
    TimeEndDisplayText: 'timeEndDisplayText',

    EventSharedReportUrl: 'eventSharedReportUrl',
    GovRecognitionLetterUrl: 'govRecognitionLetterUrl',
    GovRecognitionLetterFileId: 'govRecognitionLetterFileId',

    OrganizerDisplayName: 'organizerDisplayName',
    OrganizerIdentity: 'organizerIdentity',
    Organizer: 'organizer',

    RegistrationStart: 'registrationStart',
    RegistrationStartDisplayName: 'registrationStartDisplayName',
    RegistrationEnd: 'registrationEnd',
    RegistrationEndDisplayName: 'registrationEndDisplayName',
    RegistrationOverloaded: 'registrationOverloaded',

    RoomsHeldOnSelfTime: 'roomsHeldOnSelfTime',
    FacebookInfo: 'facebookInfo',
    ZoomInfo: 'zoomInfo',
    TelegramInfo: 'telegramInfo',

    Role: 'role',
    PublishModeId: 'publishModeId',
    Remark: 'remark',
    TimeStampId: 'timeStampId',

    CreatedDate: 'createdDate',

    //extra.
    Groups: 'groups',
    QuizRooms: 'quizRooms',
};
const ContentInfoDefaults = [
    { content: '', lang: Lang.Chinese, label: 'Chinese' },
    { content: '', lang: Lang.Malay, label: 'Malay' },
    { content: '', lang: Lang.English, label: 'English' },
];
const ContentInfos = [DataInput.FacebookInfo, DataInput.ZoomInfo, DataInput.TelegramInfo];
const RoomDataInput = {
    Id: 'id',
    OrganizerId: 'organizerId',
    AuthorId: 'authorId',

    RoomId: 'roomId',
    RoomCode: 'roomCode',
    Subject: 'subject',
    SubjectId: 'subjectId',
    Group: 'group',
    GroupId: 'groupId',

    Date: 'date',
    DateStart: 'dateStart',
    DateEnd: 'dateEnd',
    TimeStart: 'timeStart',
    TimeEnd: 'timeEnd',
    TimeLimit: 'timeLimit',
    Duration: 'duration',

    ExtraUrl: 'extraUrl',
    MarkAsDeleted: 'markAsDeleted',

    RoomTitle: 'roomTitle',
};

export default class ManageEventScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.

        this.SearchRoom_ByRoomCode_InputRef = React.createRef();
        this.ManageRoomRef = React.createRef();
        this.TooltipRef_1 = React.createRef();
        this.TooltipRef_2 = React.createRef();
    }

    getInitState = () => ({

        isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        redirect: false,
        redirectLink: '/',
        isLoading: false,
        SecretKey: SecretKey.Admin,

        //permissions.
        isSuperAdmin: false,
        gv: null,
        PA_View: false,
        PA_Search: false,
        PA_Create: false,
        PA_Update: false,
        PA_Delete: false,
        PA_Upload: false,
        PA_Download: false,
        PA_Teacher: false,

        //listing.
        List: [],
        TableColumn: 7,
        IsListLoaded: false,
        TotalRows: 0,
        PageIndex: 0,
        PageSize: 10,
        OrderBy: 'DateStart',
        OrderType: 'DESC',

        //edit.
        EditEventUiModal_Toggle: false,
        TargetEventModal_Index: -1,
        TargetEventModal: null,
        TargetEventModal_Cache: null,
        TargetEventIsEnded: false,
        TargetEventRooms: [],
        IsCreateNewEvent: false,
        New_EventCode_isValid: false,
        // TargetEventModal_IsDirty: false,
        TargetEventModal_ContentInfosToggle: ContentInfos.map((info, key) => { return false; }),

        //upload file.
        FileUploadUi_Toggle: false,
        UploadFileMode: FileMode.None,
        UploadState_CertImage: UploadState.Browse,
        UploadState_PAJSK: UploadState.Browse,
        FileToUpload: null,
        UploadErrorMessage: [],

        //edit group(s).
        TargetEventGroups: [],
        ShowEditEventGroupModal: false,
        OnHoverFX_EventGroupRooms: [],

        //edit room(s).
        ShowEditEventRoomModal: false,
        EditEventRoomState: 0,
        SelectedEventRoom: null,
        SelectedQuizRoomGroupId: 0, //new.
        EditEventRoomState_RoomCode_Options: 0,
        SearchRoom_ByRoomCode: '',
        SearchRoom_ResultList: [],
        SearchRoom_ResultList_Completed: false,
        IsEventRoomStateDirty: false,
        EditEventRoomState_Idx: -1,
        EditModal_EventRoom_Subject: false,
        Temp_TargetEventRooms: [],

        //delete.
        DeleteEventUiModal_Toggle: false,
    });

    componentWillUnmount = () => { }

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        useGlobal.getState().setScreen(LayoutScreen.ManageEvent);
        await useAppService.getState().getGroups(true);
        await useAppService.getState().getSubjects(true);
        await Delay(0);
        this.LoadList_ViaApi();
        if (typeof useGlobal.getState().setRefreshListCallbackFn === 'function')
            useGlobal.getState().setRefreshListCallbackFn(this.LoadList_ViaApi);
    }

    GetPostParams = (postData = null, remove = false) => {
        let textTitle = '';
        let textBody = '';
        let text = '';
        let urlParam = '';
        if (postData !== null) {
            if (remove) {
                textTitle = 'Removing';
                textBody = 'removed';
                text = 'remove';
                urlParam = 'Delete';
            }
            else {
                if (CheckObjectNumber(postData, 'id') <= 0) {
                    textTitle = 'Creating';
                    textBody = 'created';
                    text = 'create';
                    urlParam = 'Create';
                }
                else {
                    textTitle = 'Upating';
                    textBody = 'updated';
                    text = 'update';
                    urlParam = 'Update';
                }
            }
        }
        return { textTitle, textBody, text, urlParam };
    }

    GetValue = (obj, property, def = null) => {
        if (obj === undefined || obj === null)
            return def === null ? '' : def;
        if (obj[property] === undefined || obj[property] === null)
            return def === null ? '' : def;
        const groupOptions = useAppService.getState().groupOptions;
        switch (property) {
            case 'Group':
                const group_index = groupOptions.findIndex(x => String(x.value) === String(obj[property]));
                if (group_index > -1)
                    return groupOptions[group_index].label;
                break;
            case 'GroupId':
                const groupId_index = groupOptions.findIndex(x => Number(x.id) === Number(obj[property]));
                if (groupId_index > -1)
                    return groupOptions[groupId_index].label;
                break;
            case 'TimeLimit':
                const splits = String(obj[property]).split(':');
                let hr = Number(splits[0]);
                let min = Number(splits[1]);
                return (hr > 1 ? (hr + ' hrs ') : hr > 0 ? (hr + ' hr ') : '')
                    + (min > 1 ? (min + ' mins') : min > 0 ? (min + ' min') : '');
            default:
                break;
        }
        return String(obj[property]).length > 0 ? obj[property] : '-';
    }

    //#region === list ===
    CheckPermissions = async () => {
        this.setState({
            PA_View: PermissionAccess(LayoutScreen.ManageEvent, PermissionAccessType.View),
            PA_Search: PermissionAccess(LayoutScreen.ManageEvent, PermissionAccessType.Search),
            PA_Create: PermissionAccess(LayoutScreen.ManageEvent, PermissionAccessType.Create),
            PA_Update: PermissionAccess(LayoutScreen.ManageEvent, PermissionAccessType.Update),
            PA_Delete: PermissionAccess(LayoutScreen.ManageEvent, PermissionAccessType.Delete),
            PA_Upload: PermissionAccess(LayoutScreen.ManageEvent, PermissionAccessType.Upload),
            PA_Download: PermissionAccess(LayoutScreen.ManageEvent, PermissionAccessType.Download),

            // PageSize: CheckNumber(localStorage.getItem(`ManageEvent_List_PageSize_${uid}_${organizerId}`), GlobalSetting.PageSize),
            PageSize: 999,
            isSuperAdmin: useGlobal.getState().isSuperAdmin,
            gv: useGlobal.getState(),
        });
        await Delay(0);
        // if (useGlobal.getState() === null || useGlobal.getState().user === null)
        //     this.setState({ redirectLink: getMenuLink(LayoutScreen.Login), redirect: true, });
    }
    LoadList_ViaApi = async () => {

        await this.CheckPermissions();
        if (this.state.PA_View === false)
            return null;

        this.setState({
            isLoading: true,
            List: [],
            TotalRows: 0,
            IsListLoaded: false,
        });
        window.scrollTo(0, 0);

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Organizer/Event/List/${organizerId}/${authorId}/${this.state.PageIndex}/${this.state.PageSize}`;
        //  Api/LearningCentre/Organizer/Event/List/{organizerId}/{authorId}/{pageIndex}/{pageSize}
        let totalRows = 0;
        let _List = [];

        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                // if (this.state.isDevMode)
                //     console.log('LoadList_ViaApi (response) \n' + JSON.stringify(data));
                if (data.success) {
                    // if (data.data !== undefined)
                    // if (Array.isArray(data.data.list)) {
                    _List = DecapitalizeJsonKeys(data.data.list);
                    totalRows = CheckObjectNumber(data.data, 'totalCount', _List.length);
                    // }
                    // else {
                    //     if (this.state.isDevMode)
                    //         console.log(`${settingTitle} list is empty.`);
                    // }
                }
                else {
                    useAppService.getState().setModal('Failed', data.message);
                    // if (this.state.isDevMode)
                    //     console.log('Error', 'api - event - load list (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                if (this.state.isDevMode)
                    console.log('Error', 'api - event - load list (error)\n' + error.message);
            });
        if (Array.isArray(_List) && _List.length > 0) {
            let t_list = [];
            for (let i = 0; i < _List.length; i++) {
                t_list.push(this.PopulateEventModal(_List[i]));
            }
            _List = t_list;
        }
        this.setState({
            isLoading: false,
            List: JSON.parse(JSON.stringify(_List)),
            TotalRows: totalRows,
            IsListLoaded: true,
            // CheckedItems: Array.isArray(_List) ? _List.map((data, key) => { return false; }) : [],
        }, () => {
            if (this.state.isDevMode) {
                console.log('TotalRows', totalRows);
                console.log('List', JSON.stringify(_List));
            }
        });
    }
    ListComponents = () => {
        let components = [];

        if (this.state.IsListLoaded === false)
            return null;

        if (this.state.List.length === 0)
            return (<tr><td colSpan={this.state.TableColumn} align='center'>- list is empty -</td></tr>);

        this.state.List.map((data, key) => {
            // const dateTimeStart = moment(`${data['dateStart']} ${data['timeStart']}`, 'YYYY-MM-DD HH:mm:ss.sss').format('lll');
            // const dateTimeEnd = moment(`${data['dateEnd']} ${data['timeEnd']}`, 'YYYY-MM-DD HH:mm:ss.sss').format('lll');
            const dateTimeStart = moment(`${data['dateStart']}`, 'YYYY-MM-DD HH:mm:ss.sss').format('lll');
            const dateTimeEnd = moment(`${data['dateEnd']}`, 'YYYY-MM-DD HH:mm:ss.sss').format('lll');
            components.push(<tr key={'tbi_' + key}>
                <td>{this.state.PageIndex + key + 1}</td>
                <td className='left'>{CheckObjectStringEmpty(data, DataInput.OrganizerDisplayName, '-')}</td>
                <td className='left'>{CheckObjectStringEmpty(data, DataInput.EventName, '-')}</td>
                {
                    this.state.isSuperAdmin ?
                        <td>{CheckObjectStringEmpty(data, DataInput.OrganizerDisplayName, CheckObjectStringEmpty(data, DataInput.Organizer, '-'))}</td>
                        : null
                }
                <td>{CheckStringEmpty(dateTimeStart, '-')}</td>
                <td>{CheckStringEmpty(dateTimeEnd, '-')}</td>
                <td>
                    <button
                        type='button'
                        className='btn btn-primary'
                        onClick={() => this.ToggleEditEventUiModal(key)}
                    >{this.state.PA_Update ? 'Edit' : 'View'}</button>
                </td>
            </tr>);
            return null;
        });

        return (components);
    }
    //#region === Paging Components
    CallbackFunctionForPagingComponents_PageSize = (pageSize = GlobalSetting.PageSize) => {
        this.setState({
            PageSize: pageSize < GlobalSetting.PageSize ? GlobalSetting.PageSize : pageSize,
        }, () => {
            const { uid, organizerId } = GetPropIds(useGlobal.getState().user);
            localStorage.setItem(`ManageEvent_List_PageSize_${uid}_${organizerId}`, this.state.PageSize);
            setTimeout(() => {
                this.LoadList_ViaApi();
            }, 500);
        });
    }
    CallbackFunctionForPagingComponents_PageIndex = (pageIndex = 0) => {
        this.setState({
            PageIndex: pageIndex,
        }, () => {
            setTimeout(() => {
                this.LoadList_ViaApi();
            }, 500);
        });
    }
    //#endregion === Paging Components
    //#endregion === list ===

    //#region === Populate modal(s) ===
    PopulateEventModal = (target = null) => {
        const { authorId, organizerId, organizerDiplayName, organizerIdentity, organizerName } = GetPropIds(useGlobal.getState().user);
        let modal = {
            Id: CheckObjectNumber(target, DataInput.Id),
            OrganizerId: CheckObjectNumber(target, DataInput.OrganizerId, organizerId),
            AuthorId: CheckObjectNumber(target, DataInput.AuthorId, authorId),

            EventCode: CheckObjectStringEmpty(target, DataInput.EventCode),
            EventName: CheckObjectStringEmpty(target, DataInput.EventName),
            Active: CheckObjectBoolean(target, DataInput.Active),
            IsPublic: CheckObjectBoolean(target, DataInput.IsPublic),

            CertDownloadEnabled: CheckObjectBoolean(target, DataInput.CertDownloadEnabled),
            CertDownloadDelayed: CheckObjectBoolean(target, DataInput.CertDownloadDelayed),
            CertDownloadDelayedEndDateTime: CheckObjectStringEmpty(target, DataInput.CertDownloadDelayedEndDateTime),
            CertImgFileId: CheckObjectNumber(target, DataInput.CertImgFileId),
            CertImgUrl: CheckObjectStringEmpty(target, DataInput.CertImgUrl),
            CertLayoutTypeId: CheckObjectNumber(target, DataInput.CertLayoutTypeId),
            CertSerialPrefix: CheckObjectStringEmpty(target, DataInput.CertSerialPrefix),

            Date: CheckObjectStringEmpty(target, DataInput.Date, t_date),
            DateStart: CheckObjectStringEmpty(target, DataInput.DateStart, t_dateStart),
            DateEnd: CheckObjectStringEmpty(target, DataInput.DateEnd, t_dateEnd),
            TimeStart: CheckObjectStringEmpty(target, DataInput.TimeStart, '00:00:00'),
            TimeEnd: CheckObjectStringEmpty(target, DataInput.TimeEnd, '23:59:00'),
            TimeStartDisplayText: CheckObjectStringEmpty(target, DataInput.TimeStartDisplayText),
            TimeEndDisplayText: CheckObjectStringEmpty(target, DataInput.TimeEndDisplayText),

            EventSharedReportUrl: CheckObjectStringEmpty(target, DataInput.EventSharedReportUrl),
            GovRecognitionLetterUrl: CheckObjectStringEmpty(target, DataInput.GovRecognitionLetterUrl),
            GovRecognitionLetterFileId: CheckObjectNumber(target, DataInput.GovRecognitionLetterFileId),

            RegistrationStart: CheckObjectStringEmpty(target, DataInput.RegistrationStart),
            RegistrationStartDisplayName: CheckObjectStringEmpty(target, DataInput.RegistrationStartDisplayName),
            RegistrationEnd: CheckObjectStringEmpty(target, DataInput.RegistrationEnd, t_dateStart),
            RegistrationEndDisplayName: CheckObjectStringEmpty(target, DataInput.RegistrationEndDisplayName, moment(t_dateStart).format('MMM DD, YYYY (hh:mm A)')),
            RegistrationOverloaded: CheckObjectBoolean(target, DataInput.RegistrationOverloaded),

            RoomsHeldOnSelfTime: CheckObjectBoolean(target, DataInput.RoomsHeldOnSelfTime, true),
            FacebookInfo: CheckObjectNullValue(target, DataInput.FacebookInfo),     //null or [].
            ZoomInfo: CheckObjectNullValue(target, DataInput.ZoomInfo),             //null or [].
            TelegramInfo: CheckObjectNullValue(target, DataInput.TelegramInfo),     //null or [].

            Role: CheckObjectStringEmpty(target, DataInput.Role),
            PublishModeId: CheckObjectNumber(target, DataInput.PublishModeId, 1),
            Remark: CheckObjectStringEmpty(target, DataInput.Remark),
            TimeStampId: CheckObjectNumber(target, DataInput.TimeStampId),

            OrganizerDisplayName: CheckObjectStringEmpty(target, DataInput.OrganizerDisplayName, organizerDiplayName),
            OrganizerIdentity: CheckObjectStringEmpty(target, DataInput.OrganizerIdentity, organizerIdentity),
            Organizer: CheckObjectStringEmpty(target, DataInput.Organizer, organizerName),

            CreatedDate: CheckObjectStringEmpty(target, DataInput.CreatedDate),

            Groups: CheckObjectNullValue(target, DataInput.Groups),             //null or [].
            QuizRooms: CheckObjectNullValue(target, DataInput.QuizRooms),       //null or [].
        };
        // modal = JSON.parse(JSON.stringify(modal));
        modal = DecapitalizeJsonKeys(modal);
        modal = this.PopulateEventContentInfos(modal);
        modal = this.PopulateEventGroups(modal);
        modal = this.PopulateEventRooms(modal);
        modal[DataInput.DateStart] = moment(`${modal[DataInput.DateStart]} ${modal[DataInput.TimeStart]}`, 'YYYY-MM-DD HH:mm:ss.sss').format('YYYY-MM-DDTHH:mm');
        modal[DataInput.DateEnd] = moment(`${modal[DataInput.DateEnd]} ${modal[DataInput.TimeEnd]}`, 'YYYY-MM-DD HH:mm:ss.sss').format('YYYY-MM-DDTHH:mm');
        if (CheckObjectStringEmpty(modal, DataInput.RegistrationStart) === '') {
            const registrationStart = moment(modal[DataInput.DateStart], 'YYYY-MM-DDTHH:mm').add(-1, 'month').format('YYYY-MM-DDTHH:mm');
            modal[DataInput.RegistrationStart] = registrationStart
            modal[DataInput.RegistrationStartDisplayName] = moment(registrationStart, 'YYYY-MM-DDTHH:mm').format('MMM DD, YYYY (hh:mm A)');
        }
        const publishModeId = modal[DataInput.PublishModeId];
        if (publishModeId === 0) {
            modal[DataInput.IsPublic] = false;
            modal[DataInput.Role] = 'Admin';
            modal[DataInput.Remark] = 'Testing';
        }
        else if (publishModeId === 1) {
            modal[DataInput.IsPublic] = true;
            modal[DataInput.Role] = 'Global';
            modal[DataInput.Remark] = '';
        }
        else {
            modal[DataInput.IsPublic] = false;
            modal[DataInput.Role] = 'Private';
            modal[DataInput.Remark] = 'Private';
        }
        if (modal[DataInput.CertDownloadDelayed] === false)
            modal[DataInput.CertDownloadDelayedEndDateTime] = '';
        return modal;
    }
    PopulateEventContentInfos = (modal = null) => {
        ContentInfos.map((info, keyInfo) => {
            let contentInfo = [];
            if (typeof modal[info] === 'string') {
                const contentValues = DecapitalizeJsonKeys(JSON.parse(modal[info]));
                if (Array.isArray(contentValues)) {
                    ContentInfoDefaults.map((data, key) => {
                        const findIndex = contentValues.findIndex(x => String(x.lang) === data.lang);
                        if (findIndex < 0)
                            contentInfo.push({ content: '', lang: data.lang });
                        else
                            contentInfo.push({ content: CheckStringEmpty(contentValues[findIndex].content), lang: data.lang });
                        return null;
                    });
                }
                modal[info] = contentInfo;
            }
            else if (Array.isArray(modal[info])) {
                const contentValues = modal[info];
                if (Array.isArray(contentValues)) {
                    ContentInfoDefaults.map((data, key) => {
                        const findIndex = contentValues.findIndex(x => String(x.lang) === data.lang);
                        if (findIndex < 0)
                            contentInfo.push({ content: '', lang: data.lang });
                        else
                            contentInfo.push({ content: CheckStringEmpty(contentValues[findIndex].content), lang: data.lang });
                        return null;
                    });
                }
                else {
                    ContentInfoDefaults.map((data, key) => {
                        return contentInfo.push({ content: '', lang: data.lang });
                    });
                }
                modal[info] = contentInfo;
            }
            else {
                ContentInfoDefaults.map((data, key) => {
                    return contentInfo.push({ content: '', lang: data.lang });
                });
                modal[info] = contentInfo;
            }
            return null;
        });
        return modal;
    }
    PopulateEventGroups = (modal = null) => {
        let groups = modal[DataInput.Groups];
        // console.log(`PopulateEventGroups (Groups) \n ${JSON.stringify(groups)}`);
        if (modal !== null)
            if (groups === undefined || groups === null || Array.isArray(groups) === false)
                groups = [];
        groups.sort((a, b) => { return a.name.localeCompare(b.name); });
        modal[DataInput.Groups] = groups;
        return modal;
    }
    PopulateEventRooms = (modal = null) => {
        let quizRooms = modal[DataInput.QuizRooms];
        // console.log(`PopulateEventRooms (QuizRooms) \n ${JSON.stringify(quizRooms)}`);
        if (modal !== null) {
            let onHoverFX_EventGroupRooms = [];
            if (quizRooms === undefined || quizRooms === null || Array.isArray(quizRooms) === false) {
                quizRooms = modal[DataInput.Groups].map((group, gkey) => {
                    onHoverFX_EventGroupRooms.push({ groupId: Number(group.id), rooms: [] });
                    return { group: String(group.name), groupId: Number(group.id), rooms: [] };
                });
                modal[DataInput.QuizRooms] = quizRooms;
            }
            for (let i = 0; i < quizRooms.length; i++) {
                let onHoverFX_EventGroupRooms_rooms = [];
                const rooms = quizRooms[i]['rooms'];
                if (Array.isArray(rooms)) {
                    for (let k = 0; k < rooms.length; k++) {
                        if (rooms[k] !== undefined && rooms[k] !== null) {
                            onHoverFX_EventGroupRooms_rooms.push(false);
                            // console.log(`PopulateEventRooms (rooms[k]) \n ${JSON.stringify(rooms[k])}`);
                            const duration = CheckObjectNumber(rooms[k], RoomDataInput.Duration);
                            // console.log(`PopulateEventRooms (duration) \n ${duration} ${typeof duration}`);
                            if (duration > 0)
                                modal[DataInput.QuizRooms][i]['rooms'][k][RoomDataInput.TimeLimit] = this.GetDurationToTimeLimit_HHMM(duration);
                        }
                    }
                }
                else {
                    modal[DataInput.QuizRooms][i]['rooms'] = [];
                }
                onHoverFX_EventGroupRooms.push({ groupId: Number(quizRooms[i].groupId), rooms: onHoverFX_EventGroupRooms_rooms });
            }
            this.setState({
                OnHoverFX_EventGroupRooms: onHoverFX_EventGroupRooms,
            });
        }
        return modal;
    }
    GetDurationToTimeLimit_HHMM = (durationInSeconds = 0) => {
        if (durationInSeconds <= 0)
            return '00:00';
        var hours = Number((durationInSeconds / 3600).toFixed(3).split('.')[0]);
        var minutes = Number((durationInSeconds / 60).toFixed(3).split('.')[0]) - (hours * 60);
        // var seconds = (_totalSeconds % 60);
        // return String((hours > 9 ? '' : '0') + hours + ':' + (minutes - (hours * 60)));
        return CheckStringEmpty((hours > 0 ? (hours > 9 ? '' : '0') + hours + ' hour(s) ' : '') + (minutes > 0 ? (minutes > 9 ? '' : '0') + minutes + ' minute(s)' : ''), '-');
    }
    //#endregion === Populate modal(s) ===

    //#region === search by condition ===
    SearchEventByCondition_ViaAPI = async () => {

    }
    ResetSearchEventParams = (toggleOn = false, search = SearchCondition.Name) => {
        this.setState({
            // SearchUserByName: '',
            // SearchUserByEmail: '',
            // SearchUserByGrade: '',
            // SearchUserByClassroom: '',
            // SearchUserBySchoolName: '',
            // SearchUserByCondition: search,
            SearchByConditionModal_Toggle: toggleOn,
        });
    }
    //#endregion === search by condition ===

    //#region === edit / new ===
    ToggleEditEventUiModal = async (index = -1, create = false, forceClose = false) => {
        let targetModal = null;
        let targetEventIsEnded = false;
        if (index < 0 && create === false) {
            //check isDirty.
            if (forceClose === false && this.state.TargetEventModal !== null && this.state.TargetEventModal_Cache !== null) {
                if (JSON.stringify(this.state.TargetEventModal) !== JSON.stringify(this.state.TargetEventModal_Cache)) {
                    useAppService.getState().setModal('Continue exiting Edit mode ?',
                        'Event settings are modified & will not be save after exiting Edit mode.',
                        [
                            <Button variant="primary" onClick={() => this.ToggleEditEventUiModal(-1, false, true)}>Continue</Button>
                        ]
                    );
                    return null;
                }
            }
            //close ui.
            targetModal = null;
            this.setState({
                EditEventUiModal_Toggle: false,
            });
            await Delay(300);
            if (forceClose)
                useAppService.getState().setModal();
        }
        else {
            if (create) {
                targetModal = this.PopulateEventModal();
            }
            else {
                targetModal = this.PopulateEventModal(this.state.List[index]);
                targetEventIsEnded = moment() > moment(`${targetModal['dateEnd']} ${targetModal['timeEnd']}`, 'YYYY-MM-DD HH:mm:ss.sss');
            }
        }
        this.setState({
            TargetEventModal_Index: index > this.state.List.length ? -1 : index,
            TargetEventModal: targetModal === null ? null : JSON.parse(JSON.stringify(targetModal)),
            TargetEventModal_Cache: targetModal === null ? null : JSON.parse(JSON.stringify(targetModal)),
            TargetEventIsEnded: targetEventIsEnded,
            IsCreateNewEvent: create,
            // TargetEventModal_IsDirty: false,
        });
        if (index < 0 && create === false) { } else {
            //open ui.
            await Delay(500);
            this.setState({
                TargetEventModal_ContentInfosToggle: ContentInfos.map((info, key) => { return false; }),
                EditEventUiModal_Toggle: true,
            });
            if (create)
                this.GenerateRandomEventCode();
            if (this.state.isDevMode) {
                console.log(`ToggleEditEventUiModal (${index}) \n ${JSON.stringify(targetModal)}`);
                console.log(`ToggleEditEventUiModal (${index}) (Groups) \n ${JSON.stringify(targetModal[DataInput.Groups])}`);
                console.log(`ToggleEditEventUiModal (${index}) (QuizRooms) \n ${JSON.stringify(targetModal[DataInput.QuizRooms])}`);
            }
        }
    }
    CreateNewEvent = () => {
        if (this.state.PA_Create === false)
            return null;
        this.ToggleEditEventUiModal(-1, true);
    }
    GenerateRandomEventCode = async () => {
        // Local function to generate UUID v4
        const RandomEventCode_uuid_v4 = () => {
            return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[0-9a-f]/g, () =>
                Math.floor(Math.random() * 16).toString(16)
            );
        };
        this.setState({ New_EventCode_isValid: false });
        let new_RandomEventCode = '';
        let new_EventCode_isValid = false;
        do {
            new_RandomEventCode = String(RandomEventCode_uuid_v4()).replace(/-/g, '');
            new_EventCode_isValid = await this.CheckIfEventCodeIsValid(new_RandomEventCode);
            await new Promise(resolve => setTimeout(resolve, 300)); // Delay rewritten for proper async execution
        } while (!new_EventCode_isValid);

        if (this.state.isDevMode) {
            console.log(`[GenerateRandomEventCode] Event Code: ${new_RandomEventCode}, IsValid: ${new_EventCode_isValid}`);
        }
        const target = { ...this.state.TargetEventModal }; // Creates a shallow copy for safer modifications
        target[DataInput.EventCode] = new_RandomEventCode;
        this.setState({
            TargetEventModal: target,
            New_EventCode_isValid: new_EventCode_isValid
        });
    }
    CheckIfEventCodeIsValid = async (eventCode = '') => {
        let isValid = false;
        if (eventCode.length > 0) {
            const isExist = await this.CheckIfEventExistViaEventCode(eventCode);
            isValid = isExist === false;
        }
        return isValid;
    }
    CheckIfEventExistViaEventCode = async (eventCode = '') => {
        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        let isExist = null;
        let done = false;
        // let success = false;
        await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Organizer/Event/CheckIfExist/'
            + organizerId + '/'
            + authorId + '/'
            + eventCode,
            // Api/LearningCentre/Organizer/Event/CheckIfExist/{organizerId}/{authorId}/{eventCode}
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('GetEventInfoViaEventCode (response)', JSON.stringify(data));
                isExist = CheckBoolean(data.data);
                // success = CheckBoolean(data.success);
                done = true;
            })
            .catch(error => {
                done = true;
                if (this.state.isDevMode)
                    console.log('Error', 'api - event exist (error) (' + eventCode + ') \n' + error.message);
            });
        await DelayUntil(() => done === true);
        return isExist === null ? true : isExist;
    }
    SetTargetValue = (property = '', value = null, extra = null) => {
        let target = this.state.TargetEventModal;
        if (target === null || value === null)
            return null;
        switch (property) {
            case DataInput.Active:
            case DataInput.RoomsHeldOnSelfTime:
            case DataInput.CertDownloadEnabled:
            case DataInput.CertDownloadDelayed:
            case DataInput.RegistrationOverloaded:
                target[property] = CheckBoolean(value); break;

            case DataInput.EventName:
            case DataInput.EventCode:
            case DataInput.OrganizerDisplayName:
            case DataInput.OrganizerIdentity:
            case DataInput.RegistrationStartDisplayName:
            case DataInput.RegistrationEndDisplayName:
            case DataInput.CertSerialPrefix:
            case DataInput.EventSharedReportUrl:
                target[property] = String(value); break;

            case DataInput.CertLayoutTypeId:
                target[property] = CheckNumber(value); break;

            case DataInput.PublishModeId:
                const publishModeId = CheckNumber(value) - 1
                target[property] = publishModeId;
                if (publishModeId === 0) {
                    target[DataInput.IsPublic] = false;
                    target[DataInput.Role] = 'Admin';
                    target[DataInput.Remark] = 'Testing';
                }
                else if (publishModeId === 1) {
                    target[DataInput.IsPublic] = true;
                    target[DataInput.Role] = 'Global';
                    target[DataInput.Remark] = '';
                }
                else {
                    target[DataInput.IsPublic] = false;
                    target[DataInput.Role] = 'Private';
                    target[DataInput.Remark] = 'Private';
                }
                break;

            case DataInput.DateStart:
                const ds_start_date = moment(value, 'YYYY-MM-DDTHH:mm');
                //set DateStart.
                target[DataInput.DateStart] = value;    //ds_start_date.format('YYYY-MM-DD');
                //set TimeStart.
                target[DataInput.TimeStart] = ds_start_date.format('HH:mm:00');
                //set Date.
                target[DataInput.Date] = ds_start_date.format('YYYY-MM-DD') === moment(target[DataInput.DateEnd], 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DD') ? ds_start_date.format('DD MMM YYYY (dddd)')
                    : ds_start_date.format('MMM DD (ddd)') + ' ~ ' + moment(target[DataInput.DateEnd]).format('MMM DD (ddd)') + ', ' + ds_start_date.format('YYYY');
                //set TimeStartDisplayText.
                target[DataInput.TimeStartDisplayText] = `${ds_start_date.format('hh:mm A')} (${ds_start_date.format('DD MMM YYYY')})`;
                //debug.
                if (this.state.isDevMode) {
                    console.log(DataInput.DateStart + ' = ' + String(value) + ' / ' + String(extra));
                    console.log(DataInput.DateStart + ' = ' + target[DataInput.DateStart]
                        + '\n' + DataInput.TimeStart + ' = ' + target[DataInput.TimeStart]
                        + '\n' + DataInput.Date + ' = ' + target[DataInput.Date]
                        + '\n' + DataInput.TimeStartDisplayText + ' = ' + target[DataInput.TimeStartDisplayText]);
                }
                break;

            case DataInput.DateEnd:
                const ds_end_date = moment(value, 'YYYY-MM-DDTHH:mm');
                //set DateEnd.
                target[DataInput.DateEnd] = value;    //ds_end_date.format('YYYY-MM-DD');
                //set TimeEnd.
                target[DataInput.TimeEnd] = ds_end_date.format('HH:mm:00');
                //set Date.
                target[DataInput.Date] = ds_end_date.format('YYYY-MM-DD') === moment(target[DataInput.DateStart], 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DD') ? ds_end_date.format('DD MMM YYYY (dddd)')
                    : moment(target[DataInput.DateStart]).format('MMM DD (ddd)') + ' ~ ' + ds_end_date.format('MMM DD (ddd)') + ', ' + ds_end_date.format('YYYY');
                //set TimeEndDisplayText.
                target[DataInput.TimeEndDisplayText] = `${ds_end_date.format('hh:mm A')} (${ds_end_date.format('DD MMM YYYY')})`;
                //debug.
                if (this.state.isDevMode) {
                    console.log(DataInput.DateEnd + ' = ' + String(value) + ' / ' + String(extra));
                    console.log(DataInput.DateEnd + ' = ' + target[DataInput.DateEnd]
                        + '\n' + DataInput.TimeEnd + ' = ' + target[DataInput.TimeEnd]
                        + '\n' + DataInput.Date + ' = ' + target[DataInput.Date]
                        + '\n' + DataInput.TimeEndDisplayText + ' = ' + target[DataInput.TimeEndDisplayText]);
                }
                break;

            case DataInput.RegistrationStart:
                // console.log(DataInput.RegistrationStart + ' = ' + String(value) + ' / ' + String(extra));
                const reg_start_date = moment(value);
                //set RegistrationStart. (dt)
                target[DataInput.RegistrationStart] = reg_start_date.format('YYYY-MM-DD HH:mm:ss');
                //set RegistrationStartDisplayName. (txt)
                target[DataInput.RegistrationStartDisplayName] = reg_start_date.format('MMM DD, YYYY (hh:mm A)');
                break;

            case DataInput.RegistrationEnd:
                // console.log(DataInput.RegistrationEnd + ' = ' + String(value) + ' / ' + String(extra));
                const reg_end_date = moment(value);
                //set RegistrationEnd. (dt)
                target[DataInput.RegistrationEnd] = reg_end_date.format('YYYY-MM-DD HH:mm:ss');
                //set RegistrationEndDisplayName. (txt)
                target[DataInput.RegistrationEndDisplayName] = reg_end_date.format('MMM DD, YYYY (hh:mm A)');
                break;

            case DataInput.CertDownloadDelayedEndDateTime:
                target[property] = moment(value).format('YYYY-MM-DD HH:mm:00'); break;

            //ContentInfos.
            case DataInput.TelegramInfo:
            case DataInput.FacebookInfo:
            case DataInput.ZoomInfo:
                if (extra === null)
                    return null;
                if (CheckNullValue(target) === null || CheckObjectNullValue(target, property) === null
                    || (CheckObjectNullValue(target, property) !== null && Array.isArray(target[property]) === false)) {
                    target[property] = [];
                    target[property] = ContentInfoDefaults;
                }
                let ti_contents = [];
                ContentInfoDefaults.map((data, key) => {
                    const ti_content_index = target[property].findIndex(x => String(x.lang) === String(extra));
                    if (ti_content_index < 0)
                        ti_contents.push({ content: '', lang: String(extra) });
                    else
                        ti_contents.push({ content: String(value), lang: String(extra) });
                    return null;
                })
                target[property] = ti_contents;
                break;

            default: break;
        }
        this.setState({
            TargetEventModal: DecapitalizeJsonKeys(target),
            // TargetEventModal_IsDirty: true,
        }, () => {
            if (this.state.isDevMode)
                console.log(`SetTargetValue (${property}) (extra: ${extra}) = ` + String(target[property]));
        });
    }
    EditEventUiModalComponents = () => {
        const target = this.state.TargetEventModal;
        if (target === null || target === undefined)
            return null;

        let components = [];

        //CreatedDate. (txt) (read only)
        components.push(<tr hidden={this.state.IsCreateNewEvent}>
            <td style={{ width: 350, }}>Created Date</td>
            <td className="pL10">{CheckObjectStringEmpty(target, 'CreatedDate')}</td>
        </tr>);

        //Active. (bool)
        components.push(<tr>
            <td><label>Active</label></td>
            <td>
                <input type="checkbox" className="form-check-input pointer"
                    onClick={(e) => this.SetTargetValue(DataInput.Active, e.currentTarget.checked)}
                    checked={CheckObjectBoolean(target, DataInput.Active)}
                    readOnly={true}
                />
            </td>
        </tr>);

        //Event Name. (txt)
        components.push(<tr>
            <td><label>Event Name</label></td>
            <td>
                <input type="text" className="form-control width-max"
                    onChange={(e) => this.SetTargetValue(DataInput.EventName, e.target.value)}
                    value={CheckObjectNullValue(target, DataInput.EventName, '')}
                />
            </td>
        </tr>);

        //Event Code. (txt)
        components.push(<tr>
            <td><label>Event Code</label></td>
            <td className="pL10">
                {
                    this.state.IsCreateNewEvent ?
                        this.state.New_EventCode_isValid ?
                            //edit event.
                            <>
                                {CheckObjectStringEmpty(target, DataInput.EventCode, '-')}&nbsp;&nbsp;
                                <button type="button" className="btn btn-outline btn1" onClick={() => this.GenerateRandomEventCode()}><i className="fa fa-refresh icon1"></i></button>
                            </>
                            :
                            <ProgressBar animated now={100} className='progressbar1' />
                        :
                        CheckObjectStringEmpty(target, DataInput.EventCode, '-')
                }
            </td>
        </tr>);

        //Organizer Info. (mixed)
        components.push(<>
            <tr style={{ color: 'gray' }}>
                <td><label>Organizer (Display)</label></td>
                <td>
                    <input type='text' className={'form-control ' + this.IsEditedStyle(DataInput.OrganizerDisplayName)}
                        value={CheckObjectNullValue(target, DataInput.OrganizerDisplayName, '')}
                        onChange={(e) => this.SetTargetValue(DataInput.OrganizerDisplayName, e.target.value)}
                    />
                </td>
            </tr>
            {
                //read only.
                this.state.isSuperAdmin ? null :
                    <tr style={{ color: 'gray' }}>
                        <td><label>Organizer Identity</label></td>
                        <td className="pL10">{CheckObjectStringEmpty(target, DataInput.OrganizerIdentity, '-')}</td>
                    </tr>
            }
        </>);

        components.push(<tr><td colSpan='2' className='row-seperator'></td></tr>);

        //Date.
        components.push(<tr>
            <td><label>Date</label></td>
            <td className="pL10">{CheckObjectStringEmpty(target, DataInput.Date, '-')}</td>
        </tr>);

        //Date Start. (dt)
        const dateStart = CheckObjectStringEmpty(target, DataInput.DateStart, t_dateStart);
        components.push(<tr>
            <td><label>Date/Time (Begin)</label></td>
            <td>
                <input type="datetime-local" className="form-control width-max"
                    onChange={(e) => this.SetTargetValue(DataInput.DateStart, e.target.value)}
                    // value={moment(dateStart).format('YYYY-MM-DDTHH:mm')}
                    value={dateStart}
                />
            </td>
        </tr>);

        //Date End. (dt)
        const dateEnd = CheckObjectStringEmpty(target, DataInput.DateEnd, t_dateEnd);
        components.push(<tr>
            <td><label>Date/Time (End)</label></td>
            <td>
                <input type="datetime-local" className="form-control width-max"
                    onChange={(e) => this.SetTargetValue(DataInput.DateEnd, e.target.value)}
                    // value={moment(dateEnd).format('YYYY-MM-DDTHH:mm')}
                    value={dateEnd}
                />
            </td>
        </tr>);

        //Time Start DisplayText. (txt)
        const timeStartDisplayText = moment(t_dateStart).format('hh:mm A (DD MMM YYYY)');
        components.push(<tr>
            <td><label>Date/Time (Begin) (Display)</label></td>
            <td>
                <input type="text" className="form-control width-max"
                    onChange={(e) => this.SetTargetValue(DataInput.TimeStartDisplayText, e.target.value)}
                    value={CheckObjectNullValue(target, DataInput.TimeStartDisplayText, timeStartDisplayText)}
                />
            </td>
        </tr>);

        //Time End DisplayText. (txt)
        const timeEndDisplayText = moment(t_dateEnd).format('hh:mm A (DD MMM YYYY)');
        components.push(<tr>
            <td><label>Date/Time (End) (Display)</label></td>
            <td>
                <input type="text" className="form-control width-max"
                    onChange={(e) => this.SetTargetValue(DataInput.TimeEndDisplayText, e.target.value)}
                    value={CheckObjectNullValue(target, DataInput.TimeEndDisplayText, timeEndDisplayText)}
                />
            </td>
        </tr>);

        components.push(<tr><td colSpan='2' className='row-seperator'></td></tr>);

        //Registration Start. (dt)
        const registrationStart = CheckObjectStringEmpty(target, DataInput.RegistrationStart, t_dateStart_registrationStart);
        components.push(<tr>
            <td><label>Registration (Begin) (Actual Open Date/Time)</label></td>
            <td>
                <input type="datetime-local" className="form-control width-max"
                    onChange={(e) => this.SetTargetValue(DataInput.RegistrationStart, e.target.value)}
                    value={moment(registrationStart).format('YYYY-MM-DDTHH:mm')}
                />
            </td>
        </tr>);

        //Registration Start DisplayName. (txt)
        components.push(<tr>
            <td><label>Registration (Begin) (Display)</label></td>
            <td>
                <input type="text" className="form-control width-max"
                    onChange={(e) => this.SetTargetValue(DataInput.RegistrationStartDisplayName, e.target.value)}
                    value={CheckObjectNullValue(target, DataInput.RegistrationStartDisplayName, '')}
                />
            </td>
        </tr>);

        //Registration End. (dt)
        const registrationEnd = CheckObjectStringEmpty(target, DataInput.RegistrationEnd, t_dateEnd);
        components.push(<tr>
            <td><label>Registration (End) (Actual Deadline)</label></td>
            <td>
                <input type="datetime-local" className="form-control width-max"
                    onChange={(e) => this.SetTargetValue(DataInput.RegistrationEnd, e.target.value)}
                    value={moment(registrationEnd).format('YYYY-MM-DDTHH:mm')}
                />
            </td>
        </tr>);

        //Registration End DisplayName. (txt)
        components.push(<tr>
            <td><label>Registration (End) (Display)</label></td>
            <td>
                <input type="text" className="form-control width-max"
                    onChange={(e) => this.SetTargetValue(DataInput.RegistrationEndDisplayName, e.target.value)}
                    value={CheckObjectNullValue(target, DataInput.RegistrationEndDisplayName, '')}
                />
            </td>
        </tr>);

        //Registration Overloaded. (bool)
        components.push(<tr>
            <td><label>Registration Overloaded</label></td>
            <td>
                <input type="checkbox" className="form-check-input pointer"
                    onClick={(e) => this.SetTargetValue(DataInput.RegistrationOverloaded, e.currentTarget.checked)}
                    checked={CheckObjectBoolean(target, DataInput.RegistrationOverloaded)}
                    readOnly={true}
                /><span style={{ color: 'gray', fontSize: 14, position: 'absolute', paddingLeft: 10 }}
                >(check this box to stop registration via Login page)</span>
            </td>
        </tr >);

        components.push(<tr><td colSpan='2' className='row-seperator'></td></tr>);

        //CertDownloadEnabled. (bool)
        const certDownloadEnabled = CheckObjectBoolean(target, DataInput.CertDownloadEnabled);
        components.push(<tr>
            <td><label>Enable Certificate</label></td>
            <td>
                <input type="checkbox" className="form-check-input pointer"
                    onClick={(e) => this.SetTargetValue(DataInput.CertDownloadEnabled, e.currentTarget.checked)}
                    checked={certDownloadEnabled}
                    readOnly={true}
                />
            </td>
        </tr>);

        //Cert Info. (mixed)
        if (CheckObjectBoolean(target, DataInput.CertDownloadEnabled)) {

            //CertLayoutTypeId. (select)
            const certLayoutTypeId = CheckObjectNumber(target, DataInput.CertLayoutTypeId);
            components.push(<tr>
                <td><label>Certificate Layout Type</label></td>
                <td><DropdownButton
                    id='edit-cert-layout-type-dropdown-button'
                    title={
                        certLayoutTypeId > 0 ? CertLayoutTypeOptions[certLayoutTypeId - 1].label : 'Select a Layout Type'
                    }
                    drop='down'
                    onSelect={(option) => this.SetTargetValue(DataInput.CertLayoutTypeId, Number(option))}
                    style={{ width: 515 }}
                >
                    <Dropdown.Item disabled={true}>Select a Layout Type</Dropdown.Item>
                    {
                        CertLayoutTypeOptions.map((data, key) => {
                            return (<Dropdown.Item as="button" eventKey={key + 1} value={key + 1}>{data.label}</Dropdown.Item>);
                        })
                    }
                    {/* <Dropdown.Item as="button" eventKey={1} value={1}>{CertLayoutTypeOptions[0].label}</Dropdown.Item>
                    <Dropdown.Item as="button" eventKey={2} value={2}>{CertLayoutTypeOptions[1].label}</Dropdown.Item>
                    <Dropdown.Item as="button" eventKey={3} value={3}>{CertLayoutTypeOptions[2].label}</Dropdown.Item>
                    <Dropdown.Item as="button" eventKey={4} value={4}>{CertLayoutTypeOptions[3].label}</Dropdown.Item> */}
                </DropdownButton></td>
            </tr>);

            //CertSerialPrefix (Serial Number Prefix). (txt)
            if (certLayoutTypeId === 2 || certLayoutTypeId === 3) {
                const certSerialPrefix = CheckObjectStringEmpty(target, DataInput.CertSerialPrefix);
                components.push(<tr>
                    <td><label>Serial Number Prefix</label></td>
                    <td>
                        <input type="text" className="form-control width-max"
                            onChange={(e) => this.SetTargetValue(DataInput.CertSerialPrefix, e.target.value)}
                            value={certSerialPrefix}
                        />{certSerialPrefix.length > 0 ? <span style={{ marginLeft: 12 }}>{'(e.g. ' + certSerialPrefix + '000001)'}</span> : null}
                    </td>
                </tr >);
            }

            //CertImgUrl.
            const certImgUrl = CheckObjectStringEmpty(target, DataInput.CertImgUrl);
            components.push(<tr>
                <td><label>Certificate Image Url</label></td>
                <td>
                    {
                        this.state.IsCreateNewEvent ? <span style={{ color: 'gray' }}>(Available only after save.)</span>
                            :
                            certDownloadEnabled === false ? <span style={{ color: 'gray' }}>(Available only after Certificate is enabled & saved.)</span>
                                :
                                <table cellPadding='0' cellSpacing='0' className='tb-no-padding'>
                                    <tbody>
                                        <tr>
                                            <td style={{ padding: 0, verticalAlign: 'middle', borderWidth: 0, paddingLeft: 10, }}>
                                                <OverlayTrigger overlay={certImgUrl === '' ? <span></span> :
                                                    <Tooltip id='btn-tooltip-certImg'>
                                                        <img src={certImgUrl} alt="" width="100%" />
                                                        <span>{this.Get_FileName_Long(certImgUrl)}</span>
                                                    </Tooltip>
                                                }><span>{this.Get_FileName_Short(certImgUrl)}</span></OverlayTrigger>
                                            </td>
                                            <td style={{ padding: 0, verticalAlign: 'middle', textAlign: 'right', width: 80, border: 'none', }}>
                                                <button className='btn btn-primary' style={{ width: 80 }}
                                                    onClick={() => this.ViewEventMode_Upload_File(FileMode.CertImg)}>upload</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                    }
                </td>
            </tr>);

            //EventSharedReportUrl.
            components.push(<tr hidden={false}>
                <td><label>Shared Report URL by Organizer<br />(for Document Room)</label></td>
                <td>
                    <input type="text" className="form-control width-max"
                        onChange={(e) => this.SetTargetValue(DataInput.EventSharedReportUrl, e.target.value)}
                        value={CheckObjectStringEmpty(target, DataInput.EventSharedReportUrl, '')}
                    />
                </td>
            </tr>);

            //GovRecognitionLetterUrl (PAJSK).
            const govRecognitionLetterUrl = CheckObjectStringEmpty(target, DataInput.GovRecognitionLetterUrl);
            components.push(<tr hidden={false}>
                <td><label>Surat Pengikhtirafan PAJSK</label></td>
                <td>
                    {
                        this.state.IsCreateNewEvent ? <span style={{ color: 'gray' }}>(Available only after save.)</span>
                            :
                            this.state.TargetEventModal['CertDownloadEnabled'] === false ? <span style={{ color: 'gray' }}>(Available only after Certificate is enabled & saved.)</span>
                                :
                                <table cellPadding='0' cellSpacing='0' className='tb-no-padding'>
                                    <tbody>
                                        <tr>
                                            <td style={{ padding: 0, verticalAlign: 'middle', borderWidth: 0, paddingLeft: 10, border: 'none', }}>
                                                {
                                                    govRecognitionLetterUrl === '' ? null :
                                                        <OverlayTrigger overlay={govRecognitionLetterUrl === '' ? <span></span> :
                                                            <Tooltip>{this.Get_FileName_Long(govRecognitionLetterUrl)}</Tooltip>
                                                        }><span>{this.Get_FileName_Short(govRecognitionLetterUrl)}</span>
                                                        </OverlayTrigger>
                                                }
                                            </td>
                                            <td style={{ padding: 0, verticalAlign: 'middle', textAlign: 'right', width: 80, border: 'none', }}>
                                                <button className='btn btn-primary' style={{ width: 80 }}
                                                    onClick={() => this.ViewEventMode_Upload_File(FileMode.PAJSK)}>upload</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                    }
                </td>
            </tr>);

            //CertDownloadDelayed. (bool)
            const certDownloadDelayed = CheckObjectBoolean(target, DataInput.CertDownloadDelayed);
            const certDownloadDelayedEndDateTime = CheckObjectStringEmpty(target, DataInput.CertDownloadDelayedEndDateTime);
            components.push(<tr>
                <td><label>Enable Delayed Download</label></td>
                <td>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input type="checkbox" className="form-check-input pointer"
                            onClick={(e) => this.SetTargetValue(DataInput.CertDownloadDelayed, e.currentTarget.checked)}
                            checked={certDownloadDelayed}
                            readOnly={true}
                        />{
                            certDownloadDelayed ?
                                <div style={{ padding: '0px 0px 0px 10px', display: 'inline-flex', alignItems: 'center', }}>
                                    <span style={{ flex: 1, padding: '0px 10px 0px 0px', }}>Delayed until</span>
                                    <input type='datetime-local' className={'form-control ' + this.IsEditedStyle(DataInput.CertDownloadDelayedEndDateTime)}
                                        style={{ flex: 1, marginRight: 5 }}
                                        value={
                                            certDownloadDelayedEndDateTime === '' ?
                                                moment(dateStart).add(3, 'months').format('YYYY-MM-DDTHH:mm')
                                                :
                                                moment(certDownloadDelayedEndDateTime).format('YYYY-MM-DDTHH:mm')
                                        }
                                        onChange={(e) => this.SetTargetValue(DataInput.CertDownloadDelayedEndDateTime, moment(e.target.value).format('YYYY-MM-DD HH:mm:00'))}
                                    ></input>
                                    <button className='btn-link'
                                        onClick={() => {
                                            let _state = this.state.TargetEventModal;
                                            const _dateStart = CheckObjectStringEmpty(this.state.TargetEventModal_Cache, DataInput.DateStart);
                                            const _cached = CheckObjectStringEmpty(this.state.TargetEventModal_Cache, DataInput.CertDownloadDelayedEndDateTime);
                                            _state[DataInput.CertDownloadDelayedEndDateTime] = _cached === '' ?
                                                moment(_dateStart).add(3, 'months').format('YYYY-MM-DD HH:mm:00')
                                                : moment(_cached).format('YYYY-MM-DD HH:mm:00');
                                            this.setState({ TargetEventModal: _state, });
                                        }}
                                    >reset</button>
                                </div>
                                : null
                        }
                    </div>
                </td>
            </tr>);
        }

        components.push(<tr><td colSpan='2' className='row-seperator'></td></tr>);

        //Publish Mode. (select)
        const publishModeId = CheckObjectNumber(target, DataInput.PublishModeId, (this.state.isSuperAdmin === false ? 1 : 0));
        components.push(<tr>
            <td><label>Publish Mode</label></td>
            <td><DropdownButton
                id='edit-publish-dropdown-button'
                title={
                    publishModeId >= 0 ?
                        this.state.isSuperAdmin && publishModeId === 0 ? PublishModeOptions[0].label
                            :
                            PublishModeOptions[publishModeId].label
                        : 'Select a Publish Mode'
                }
                drop='down'
                onSelect={(option) => this.SetTargetValue(DataInput.PublishModeId, Number(option))}
                style={{ width: 515 }}
            >
                <Dropdown.Item disabled={true}>Select a Publish Mode</Dropdown.Item>
                <Dropdown.Item as="button" eventKey={1} value={1} hidden={this.state.isSuperAdmin === false}>{PublishModeOptions[0].label}</Dropdown.Item>
                <Dropdown.Item as="button" eventKey={2} value={2}>{PublishModeOptions[1].label}</Dropdown.Item>
                <Dropdown.Item as="button" eventKey={3} value={3}>{PublishModeOptions[2].label}</Dropdown.Item>
            </DropdownButton></td>
        </tr>);

        components.push(<tr><td colSpan='2' className='row-seperator'></td></tr>);

        //Groups. (checkbox)
        // const groups = Array.isArray(target[DataInput.Groups]) ? target[DataInput.Groups] : [];
        components.push(<tr className='hide-row-hover'>
            <td style={{ width: 350, }}>
                <label>Groups</label>{
                    // groups.length === 0 ? null : <Button
                    //     variant="primary"
                    //     onClick={() => this.ToggleEditEventGroupModal()}
                    // >Edit Group(s)</Button>
                }
            </td>
            <td>
                <table className='table tbStyle' width={'100%'}>
                    <thead>
                        <tr className='hide-row-hover'>
                            <th width={55}>No</th>
                            <th className="text-left">Group Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.GetTargetEvent_EditGroups_TableRows()}
                    </tbody>
                </table>
            </td>
        </tr>);

        //Rooms.
        if (this.state.IsCreateNewEvent === false) {
            const quizRooms = Array.isArray(target[DataInput.QuizRooms]) ? target[DataInput.QuizRooms] : [];
            components.push(<tr><td colSpan='2' className='row-seperator'></td></tr>);
            components.push(<tr className='hide-row-hover'>
                <td style={{ width: 350, }}>
                    <label>Rooms</label>
                    {/* <Button
                        variant="primary"
                        onClick={() => this.ToggleEditEventRoomModal()}
                        disabled={JSON.stringify(target) !== JSON.stringify(this.state.TargetEventModal_Cache)}
                    >Edit Rooms</Button>
                    {
                        JSON.stringify(target) === JSON.stringify(this.state.TargetEventModal_Cache) ? null :
                            <span style={{ color: 'gray', fontWeight: 'bold', fontSize: 12, }}
                            >* One or more event setting(s) had been modified, it is require to save current event setting(s) before continue to edit any room setting(s).</span>
                    } */}
                </td>
                <td>
                    {
                        quizRooms.length === 0 ? 'no room has been assigned.' :
                            <table className='table tbStyle tb-edit-event-rooms'>
                                <thead>
                                    <tr className='hide-row-hover'>
                                        {/* <td>#</td> */}
                                        <th>Group</th>
                                        <th>Subject</th>
                                        <th>Room Code</th>
                                        <th>Time Start</th>
                                        <th>Time End</th>
                                        {/* {
                                            roomsHeldOnSelfTime === false ? null :
                                                <>
                                                    <th>Time Start</th>
                                                    <th>Time End</th>
                                                </>
                                        } */}
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.GetTargetEvent_Rooms_TableRows()}
                                </tbody>
                            </table>
                    }
                </td>
            </tr>);
        }

        //RoomsHeldOnSelfTime. (bool)
        // const roomsHeldOnSelfTime = CheckObjectBoolean(target, DataInput.RoomsHeldOnSelfTime);
        components.push(<tr>
            <td><label>Rooms held on self time</label></td>
            <td>
                <input type="checkbox" className="form-check-input pointer"
                    onClick={(e) => this.SetTargetValue(DataInput.RoomsHeldOnSelfTime, e.currentTarget.checked)}
                    checked={CheckObjectBoolean(target, DataInput.RoomsHeldOnSelfTime)}
                    readOnly={true}
                /><span style={{ color: 'gray', fontSize: 14, position: 'absolute', paddingLeft: 10 }}
                >(checked = each room follow its own Begin & End time, unchecked = all rooms shared a same universal time)</span>
            </td>
        </tr>);

        components.push(<tr><td colSpan='2' className='row-seperator'></td></tr>);

        const contentInfoToggles = this.state.TargetEventModal_ContentInfosToggle;

        //TelegramInfo. (textarea(s))
        const telegramInfo_index = ContentInfos.indexOf(DataInput.TelegramInfo);
        const telegramInfo_toggle = contentInfoToggles[telegramInfo_index];
        const telegramInfo_contents = Array.isArray(target[DataInput.TelegramInfo]) ? target[DataInput.TelegramInfo] : ContentInfoDefaults;
        components.push(<tr className='hide-row-hover'>
            <td>
                <div style={{ display: 'flex', columnGap: 10 }}>
                    <label>Telegram Info</label>
                    <div className="pointer posAbL300" onClick={() => this.SetContentInfoToggle(telegramInfo_index, !telegramInfo_toggle)}>
                        <input type='checkbox' readOnly={true} checked={telegramInfo_toggle} className="mR5"
                        ></input>{telegramInfo_toggle ? 'hide' : 'show'}
                    </div>
                </div>
            </td>
            <td>
                {
                    telegramInfo_toggle === false ? <span style={{ fontSize: 12, color: 'gray' }}>check toggle to show/hide content.</span> :
                        ContentInfoDefaults.map((data, key) => {
                            const content_index = telegramInfo_contents.findIndex(x => x.lang === data.lang);
                            return <Row className='row-lang'>
                                <Col className='col-1 label'>{data.label}</Col>
                                <Col className='content'>
                                    <textarea rows={5} cols={50} style={{ width: '100%' }}
                                        className={'form-control ' + this.IsEditedStyle_ContentInfo(DataInput.TelegramInfo, data.lang)}
                                        placeholder={'...html tags...'}
                                        onChange={(e) => this.SetTargetValue(DataInput.TelegramInfo, e.target.value, data.lang)}
                                        value={content_index < 0 ? data.content : telegramInfo_contents[content_index].content}
                                    ></textarea>
                                </Col>
                            </Row>
                        })
                }
            </td>
        </tr>);

        //FacebookInfo. (textarea(s))
        const facebookInfo_index = ContentInfos.indexOf(DataInput.FacebookInfo);
        const facebookInfo_toggle = contentInfoToggles[facebookInfo_index];
        const facebookInfo_contents = Array.isArray(target[DataInput.FacebookInfo]) ? target[DataInput.FacebookInfo] : ContentInfoDefaults;
        components.push(<tr className='hide-row-hover'>
            <td>
                <div style={{ display: 'flex', columnGap: 10 }}>
                    <label>Facebook Info</label>
                    <div className="pointer posAbL300" onClick={() => this.SetContentInfoToggle(facebookInfo_index, !facebookInfo_toggle)}>
                        <input type='checkbox' readOnly={true} checked={facebookInfo_toggle} className="mR5"
                        ></input>{facebookInfo_toggle ? 'hide' : 'show'}
                    </div>
                </div>
            </td>
            <td>
                {
                    facebookInfo_toggle === false ? <span style={{ fontSize: 12, color: 'gray' }}>check toggle to show/hide content.</span> :
                        ContentInfoDefaults.map((data, key) => {
                            const content_index = facebookInfo_contents.findIndex(x => x.lang === data.lang);
                            return <Row className='row-lang'>
                                <Col className='col-1 label'>{data.label}</Col>
                                <Col className='content'>
                                    <textarea rows={5} cols={50} style={{ width: '100%' }}
                                        className={'form-control ' + this.IsEditedStyle_ContentInfo(DataInput.FacebookInfo, data.lang)}
                                        placeholder={'...html tags...'}
                                        onChange={(e) => this.SetTargetValue(DataInput.FacebookInfo, e.target.value, data.lang)}
                                        value={content_index < 0 ? data.content : facebookInfo_contents[content_index].content}
                                    ></textarea>
                                </Col>
                            </Row>
                        })
                }
            </td>
        </tr >);

        //FacebookInfo. (textarea(s))
        const zoomInfo_index = ContentInfos.indexOf(DataInput.ZoomInfo);
        const zoomInfo_toggle = contentInfoToggles[zoomInfo_index];
        const zoomInfo_contents = Array.isArray(target[DataInput.ZoomInfo]) ? target[DataInput.ZoomInfo] : ContentInfoDefaults;
        components.push(<tr className='hide-row-hover'>
            <td>
                <div style={{ display: 'flex', columnGap: 10 }}>
                    <label>Zoom Info</label>
                    <div className="pointer posAbL300" onClick={() => this.SetContentInfoToggle(zoomInfo_index, !zoomInfo_toggle)}>
                        <input type='checkbox' readOnly={true} checked={zoomInfo_toggle} className="mR5"
                        ></input>{zoomInfo_toggle ? 'hide' : 'show'}
                    </div>
                </div>
            </td>
            <td>
                {
                    zoomInfo_toggle === false ? <span style={{ fontSize: 12, color: 'gray' }}>check toggle to show/hide content.</span> :
                        ContentInfoDefaults.map((data, key) => {
                            const content_index = zoomInfo_contents.findIndex(x => x.lang === data.lang);
                            return <Row className='row-lang'>
                                <Col className='col-1 label'>{data.label}</Col>
                                <Col className='content'>
                                    <textarea rows={5} cols={50} style={{ width: '100%' }}
                                        className={'form-control ' + this.IsEditedStyle_ContentInfo(DataInput.ZoomInfo, data.lang)}
                                        placeholder={'...html tags...'}
                                        onChange={(e) => this.SetTargetValue(DataInput.ZoomInfo, e.target.value, data.lang)}
                                        value={content_index < 0 ? data.content : zoomInfo_contents[content_index].content}
                                    ></textarea>
                                </Col>
                            </Row>
                        })
                }
            </td>
        </tr>);

        //return.
        return (<table className='table table-hover table-bordered tbStyle1' cellPadding='10' cellSpacing='10' style={{ marginBottom: 0, }}        >
            <tbody className='tbody'>{components}</tbody>
        </table>);
    }
    SetContentInfoToggle = (key = -1, toggle = false) => {
        if (key < 0)
            return null;
        let toggles = this.state.TargetEventModal_ContentInfosToggle;
        toggles[key] = toggle;
        this.setState({
            TargetEventModal_ContentInfosToggle: toggles,
        });
    }
    IsEditedStyle = (property = '') => {
        return JSON.stringify(this.state.TargetEventModal[property])
            === JSON.stringify(this.state.TargetEventModal_Cache[property])
            ? '' : 'edited';
    }
    IsEditedStyle_ContentInfo = (property = '', lang = '') => {
        const idx = this.state.TargetEventModal[property].findIndex(x => String(x.lang) === String(lang));
        if (idx > -1) {
            return this.state.TargetEventModal[property][idx].content
                === this.state.TargetEventModal_Cache[property][idx].content
                ? '' : 'edited'
        }
        return '';
    }
    UpdateEventSettings_ViaApi = async () => {
        useAppService.getState().setModal('', 'Saving Event settings...', null, AlertMode.Loading);

        const target = this.state.TargetEventModal;
        if (target === null || target.hasOwnProperty(DataInput.Id) === false) {
            useAppService.getState().setModal('Invalid Event', 'event settings are not save.');
            return null;
        }
        //populate update modal.
        let modal = this.PopulateEventModal(target);
        // const source = this.state.TargetEventModal_Cache;
        // Object.values(DataInput).map((data, key) => {
        //     // if (this.state.IsCreateNewEvent) {
        //     //     modal[data] = target[data];
        //     // }
        //     // else {
        //     //     if (source[data] !== target[data])
        //     //         modal[data] = target[data];
        //     //     else
        //     //         modal[data] = source[data];
        //     // }
        //     return modal[data] = target[data];
        // });
        modal[DataInput.DateStart] = moment(modal[DataInput.DateStart]).format('YYYY-MM-DD');
        modal[DataInput.DateEnd] = moment(modal[DataInput.DateEnd]).format('YYYY-MM-DD');
        modal[DataInput.RegistrationEnd] = moment(modal[DataInput.RegistrationEnd]).format('YYYY-MM-DD HH:mm:ss');
        if (this.state.isDevMode) {
            console.log('update modal =\n' + JSON.stringify(modal));
            // console.log('eventUpdateModal !== {}\n' + JSON.stringify(!Object.keys(modal).length));
        }

        // const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        // const isNew = this.state.IsCreateNewEvent;
        if (this.state.IsCreateNewEvent) {
            modal[DataInput.TimeStampId] = Number(moment.utc().unix() + '000');
            modal['isNew'] = true;
        }
        const path = GlobalSetting.ApiUrl + 'Api/LearningCentre/Event/Settings/CreateOrUpdate';
        // Api/LearningCentre/Event/Settings/CreateOrUpdate
        if (this.state.isDevMode)
            console.log('UpdateEventSettings_ViaApi \n' + path);

        // useAppService.getState().setModal();
        // return null;

        let errorMessage = '';
        let success = false;
        let done = false;
        await fetch(path,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(modal),
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('api - event create/update (response)\n' + JSON.stringify(data));
                success = CheckObjectBoolean(data, 'success');
                if (!data.success) {
                    errorMessage = CheckObjectStringEmpty(data, 'message');
                    if (this.state.isDevMode)
                        console.log('Error', 'api - event create/update (failed)\n' + data.message);
                }
                done = true;
            })
            .catch(error => {
                errorMessage = CheckObjectStringEmpty(error, 'message', error);
                done = true;
                if (this.state.isDevMode)
                    console.log('Error', 'api - event create/update (error)\n' + error.message);
            });
        await DelayUntil(() => done === true);

        //done.
        if (success) {
            await this.LoadList_ViaApi();     //refresh event list.
            await DelayUntil(() => this.state.IsListLoaded);
            await Delay(500);
            let modal_index = this.state.TargetEventModal_Index;
            this.ToggleEditEventUiModal(-1, false, true);    //close edit ui.
            await Delay(1000);
            useAppService.getState().setModal();

            if (modal_index < 0) {
                if (this.state.List.length > 0)
                    modal_index = this.state.List.findIndex(x => String(x[DataInput.EventCode]) === String(modal[DataInput.EventCode]));
            }
            this.ToggleEditEventUiModal(modal_index);     //reopen edit ui.
            await Delay(1500);
            useAppService.getState().setModal('', 'Event &lt;<b> ' + CheckObjectStringEmpty(target, DataInput.EventName) + ' </b>&gt; has been saved.');
        }
        else {
            useAppService.getState().setModal('', 'Failed to save Event.<br />' + errorMessage);
        }
    }
    // PopulateEventUpdateModal = (source, edited) => {
    //     let modal = {};
    //     // let fieldNames = [
    //     //     'CenterUserId', 'AuthorId',
    //     //     'Active', 'IsPublic', 'CreatedDate', 'Event', 'EventName', 'EventCode',
    //     //     'CertDownloadDelayed', 'CertDownloadEnabled', 'CertImgUrl', 'CertLayoutTypeId', 'CertSerialPrefix',
    //     //     'Date', 'DateEnd', 'DateStart', 'TimeEnd', 'TimeStart', 'EventSharedReportUrl',
    //     //     'RegistrationEnd', 'RegistrationEndDisplayName', 'RegistrationOverloaded', 'RoomsHeldOnSelfTime',
    //     //     'OrganizerId', 'Organizer', 'OrganizerIdentity', 'OrganizerDisplayName',
    //     //     'ZoomInfo', 'FacebookInfo', 'Role', 'PublishModeId', 'Remark',
    //     //     'QuizRooms', 'Groups',
    //     //     'TelegramInfo',
    //     //     'GlobalScheduleTimeEnd', 'GlobalScheduleTimeStart',
    //     //     'CertDownloadDelayedEndDateTime'
    //     // ];
    //     // let field = '';
    //     // for (var ii = 0; ii < fieldNames.length; ii++) {
    //     //     field = fieldNames[ii];
    //     //     if (source[field] !== edited[field])
    //     //         modal[field] = edited[field];
    //     // }
    //     Object.values(DataInput).map((data, key) => {
    //         if (source[data] !== edited[data])
    //             modal[data] = edited[data];
    //     });
    //     return modal;
    // }
    //#endregion === edit / new ===

    //#region === edit group(s) ===
    ToggleEditEventGroupModal = () => {
        if (this.state.PA_Update === false)
            return null;
        this.setState({ ShowEditEventGroupModal: !this.state.ShowEditEventGroupModal });
    }
    GetTargetEvent_EditGroups_TableRows = () => {
        let components = [];
        const modal = this.state.TargetEventModal;
        const groupOptions = useAppService.getState().groupOptions;
        if (Array.isArray(groupOptions) && groupOptions.length > 0) {
            groupOptions.map((option, key) => {
                const group_index = modal === null || modal === undefined || Array.isArray(modal[DataInput.Groups]) === false ? -1
                    : modal[DataInput.Groups].findIndex(x => Number(x.id) === Number(option.id));
                return components.push(<tr key={'grp-op-' + key} onClick={() => this.ToggleGroupCheckbox_TargetEventGroup(option.id)} style={{ cursor: 'pointer' }}>
                    <td><input type='checkbox' className='form-check-input disabled-pointer-events' checked={group_index > -1} readOnly={true}></input></td>
                    <td className="text-left">{option.label}</td>
                </tr>);
            });
        }
        return (components);
    }
    ToggleGroupCheckbox_TargetEventGroup = (id) => {
        let target = this.state.TargetEventModal;
        const groupOptions = useAppService.getState().groupOptions;

        if (Array.isArray(groupOptions) === false || groupOptions.length === 0
            || target === null || target[DataInput.Groups] === undefined)
            return null;

        const default_idx = groupOptions.findIndex(x => Number(x.id) === Number(id));
        if (default_idx < 0)
            return null;

        let rooms = target[DataInput.QuizRooms];
        const room_idx = rooms.findIndex(x => Number(x.groupId) === Number(id));

        let groups = target[DataInput.Groups];
        const group_idx = groups.findIndex(x => Number(x.id) === Number(id));

        // let targetEventRooms = this.state.TargetEventRooms;
        // const target_room_idx = targetEventRooms.findIndex(x => Number(x.groupId) === Number(id));

        if (group_idx < 0) {
            //add into group.
            groups.push({ name: groupOptions[default_idx].value, id: groupOptions[default_idx].id });
            rooms.push({ group: groupOptions[default_idx].label, groupId: groupOptions[default_idx].id, rooms: [] });
            // targetEventRooms.push({
            //     group: String(groupOptions[default_idx].label),
            //     groupId: Number(groupOptions[default_idx].id),
            //     subject: '',
            //     subjectId: 0,
            //     roomCode: 'TBA',
            //     dateStart: 'TBA',
            //     dateEnd: 'TBA',
            //     timeLimit: 'TBA',
            //     timeStart: 'TBA',
            //     timeEnd: 'TBA',
            // });
        }
        else {
            //remove from group.
            if (group_idx > -1)
                groups.splice(group_idx, 1);
            //remove from rooms.
            if (room_idx > -1)
                rooms.splice(room_idx, 1);
            // //remove from target event rooms.
            // if (target_room_idx > -1)
            //     targetEventRooms.splice(target_room_idx, 1);
        }
        //sort items.
        groups.sort((a, b) => {
            if (a.name > b.name) return 1;
            else if (a.name < b.name) return -1;
            else return 0;
        });
        rooms.sort((a, b) => {
            if (a.group > b.group) return 1;
            else if (a.group < b.group) return -1;
            else return 0;
        });
        // targetEventRooms.sort((a, b) => a.groupId - b.groupId);
        if (this.state.isDevMode) {
            console.log(`Event (Groups): \n ${JSON.stringify(groups)}`);
            console.log(`Event (Rooms): \n ${JSON.stringify(rooms)}`);
        }
        // //sort.
        // groups.sort((a, b) => { return a.name.localeCompare(b.name); });
        // rooms.sort((a, b) => { return a.group.localeCompare(b.group); });
        //assign.
        target[DataInput.Groups] = groups;
        target[DataInput.QuizRooms] = rooms;
        this.setState({
            TargetEventModal: { ...target },
            // TargetEventRooms: [...targetEventRooms],
        });
    }
    //#endregion === edit group(s) ===

    //#region === edit room(s) ===
    GetTargetEvent_Rooms_TableRows = () => {
        let components = [];
        const target = this.state.TargetEventModal;
        // const roomsHeldOnSelfTime = CheckObjectBoolean(target, DataInput.RoomsHeldOnSelfTime);
        const groupOptions = useAppService.getState().groupOptions;
        if (Array.isArray(groupOptions) && groupOptions.length > 0) {
            const hovers = this.state.OnHoverFX_EventGroupRooms;
            target[DataInput.Groups].map((group, key) => {
                const quizRooms = target[DataInput.QuizRooms];
                const quizRooms_group_index = quizRooms.findIndex(x => x.groupId === group.id);
                const rooms = quizRooms_group_index < 0 ? [] : quizRooms[quizRooms_group_index]['rooms'];
                const hover_row = quizRooms_group_index < 0 ? false :
                    (quizRooms_group_index <= hovers.length - 1 && hovers[quizRooms_group_index]['rooms'] !== undefined ?
                        hovers[quizRooms_group_index].rooms.find(x => x === true) : false);
                return components.push(<tr key={'grp-room-op-' + key} style={{ cursor: 'pointer' }}>
                    <td width={135}>
                        <div className='egr-style-group'
                            style={{ fontWeight: hover_row ? 'bold' : 'initial', height: rooms.length * 45 }}
                        >{CheckObjectStringEmpty(group, 'name', '-')}{CheckObjectStringEmpty(group, 'remark') === '' ? '' : <><br />{CheckObjectStringEmpty(group, 'remark')}</>}</div>
                    </td>
                    <td width={135}>
                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
                            {
                                quizRooms_group_index > -1 && Array.isArray(rooms) && rooms.length > 0 ?
                                    rooms.map((room, rkey) => {
                                        return <div
                                            id={'egr-' + group.id + '-' + rkey + '-subject'}
                                            className={'egr-style ' + (this.GetGroupHovers(quizRooms_group_index, rkey) ? 'egr-bg-hover' : '')}
                                            onMouseOver={() => this.OnHoverFX_EventGroupRooms(group.id, rkey, true)}
                                            onMouseOut={() => this.OnHoverFX_EventGroupRooms(group.id, rkey, false)}
                                        >
                                            <span>{CheckObjectStringEmpty(room, 'Subject', '-')}</span>
                                        </div>
                                    })
                                    : '-'
                            }
                            {/* <div className='egr-style'>&nbsp;</div> */}
                        </div>
                    </td>
                    <td>
                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
                            {
                                Array.isArray(rooms) && rooms.length > 0 ?
                                    rooms.map((room, rkey) => {
                                        return <>
                                            {
                                                this.GetGroupHovers(quizRooms_group_index, rkey) === false ? null :
                                                    <div style={{ display: 'flex', position: 'absolute', height: 40, alignItems: 'center', fontSize: 24, marginTop: 45 * rkey, marginLeft: 2, color: 'white' }}><i className="fa fa-caret-right"></i></div>
                                            } <div
                                                id={'egr-' + group.id + '-' + rkey + '-rooms'}
                                                className={'egr-style-room '}
                                                // className={'egr-style-room ' + (this.GetGroupHovers(quizRooms_group_index, rkey) ? 'egr-bg-hover' : '')}
                                                onMouseOver={() => this.OnHoverFX_EventGroupRooms(group.id, rkey, true)}
                                                onMouseOut={() => this.OnHoverFX_EventGroupRooms(group.id, rkey, false)}
                                            >
                                                <Button variant='primary' onClick={() => this.EditEventGroupRooms(group.id, room, rkey)}>{CheckObjectStringEmpty(room, 'RoomCode', '-')}</Button>
                                                <Button variant='danger' onClick={() => this.EditEventGroupRooms(group.id, room, rkey, true)}><i className="fa fa-remove" style={{ fontSize: 24, color: 'white' }}></i></Button>
                                            </div>
                                        </>
                                    })
                                    :
                                    null
                            }
                            {/* <div className='egr-style'>
                                <Button variant='warning' onClick={() => this.EditEventGroupRooms(group.id)}>Assign Room</Button>
                            </div> */}
                        </div>
                    </td>
                    <td width={100}>
                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
                            {
                                Array.isArray(rooms) && rooms.length > 0 ?
                                    rooms.map((room, rkey) => {
                                        const timeStart = CheckObjectStringEmpty(room, 'TimeStart');
                                        return <div
                                            id={'egr-' + group.id + '-' + rkey + '-timeStart'}
                                            className={'egr-style ' + (this.GetGroupHovers(quizRooms_group_index, rkey) ? 'egr-bg-hover' : '')}
                                            onMouseOver={() => this.OnHoverFX_EventGroupRooms(group.id, rkey, true)}
                                            onMouseOut={() => this.OnHoverFX_EventGroupRooms(group.id, rkey, false)}
                                        >
                                            <span>{timeStart === '' ? '-' : moment('2024-11-06 ' + timeStart).format('hh:mm A')}</span>
                                        </div>
                                    }) : '-'
                            }
                            {/* <div className='egr-style'>&nbsp;</div> */}
                        </div>
                    </td>
                    <td width={100}>
                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
                            {
                                Array.isArray(rooms) && rooms.length > 0 ?
                                    rooms.map((room, rkey) => {
                                        const timeEnd = CheckObjectStringEmpty(room, 'TimeEnd');
                                        return <div
                                            id={'egr-' + group.id + '-' + rkey + '-timeEnd'}
                                            className={'egr-style ' + (this.GetGroupHovers(quizRooms_group_index, rkey) ? 'egr-bg-hover' : '')}
                                            onMouseOver={() => this.OnHoverFX_EventGroupRooms(group.id, rkey, true)}
                                            onMouseOut={() => this.OnHoverFX_EventGroupRooms(group.id, rkey, false)}
                                        >
                                            <span>{timeEnd === '' ? '-' : moment('2024-11-06 ' + timeEnd).format('hh:mm A')}</span>
                                        </div>
                                    }) : '-'
                            }
                            {/* <div className='egr-style'>&nbsp;</div> */}
                        </div>
                    </td>
                    <td width={135}>
                        <Button variant='warning' onClick={() => this.EditEventGroupRooms(group.id, null, key)}>Assign Room</Button>
                    </td>
                </tr>);
            });
        }
        return (components);
    }
    GetGroupHovers = (group_index = -1, room_index = -1) => {
        if (group_index < 0 || room_index < 0)
            return false;
        const hovers = this.state.OnHoverFX_EventGroupRooms;
        if (hovers === undefined || Array.isArray(hovers) === false)
            return false;
        if (group_index > hovers.length - 1)
            return false;
        if (hovers[group_index].rooms === undefined || Array.isArray(hovers[group_index].rooms) === false)
            return false;
        if (room_index > hovers[group_index].rooms.length - 1)
            return false;
        return hovers[group_index].rooms[room_index];
    }
    OnHoverFX_EventGroupRooms = (groupId = 0, index = -1, hover = false) => {
        console.log(`OnHoverFX_EventGroupRooms (${groupId}) (${index}) (${hover})`)
        if (groupId <= 0 || index < 0)
            return null;
        let hovers = this.state.OnHoverFX_EventGroupRooms;
        // const findIndex = hovers.findIndex(x => x.groupId === groupId);
        // if (findIndex > -1) {
        // hovers[findIndex].rooms[index] = hover;
        for (let i = 0; i < hovers.length; i++) {
            for (let j = 0; j < hovers[i].rooms.length; j++) {
                hovers[i].rooms[j] = hovers[i].groupId === groupId && j === index ? hover : false;
            }
            // if (hovers[i].groupId === groupId) {
            //     for (let j = 0; j < hovers[i].rooms.length; j++) {
            //         hovers[i].rooms[j] = j === index ? hover : false;
            //     }
            // }
            // else {
            //     for (let j = 0; j < hovers[i].rooms.length; j++) {
            //         hovers[i].rooms[j] = false;
            //     }
            // }
        }
        // if (findIndex > -1)
        //     console.log(`OnHoverFX_EventGroupRooms (after) (${findIndex}) (${index}) (${hovers[findIndex].rooms[index]})`)
        this.setState({
            OnHoverFX_EventGroupRooms: hovers,
        });
        // }
    }
    EditEventGroupRooms = (groupId = 0, room = null, idx = -1, remove = false) => {
        this.setState({
            // EditEventRoomState: 1,
            EditEventRoomState_Idx: idx,
            SelectedEventRoom: room,
            SelectedQuizRoomGroupId: groupId,
        });
        if (room === null && idx < 0) {
            //start with empty room.
            this.ToggleEditEventRoomModal();
        }
        else {
            if (remove) {
                //remove room.
                let target = this.state.TargetEventModal;
                const group_index = target[DataInput.QuizRooms].findIndex(x => Number(x.groupId) === groupId);
                if (group_index > -1) {
                    if (target[DataInput.QuizRooms][group_index].rooms.length > 1)
                        target[DataInput.QuizRooms][group_index].rooms.splice(idx, 1);
                    else
                        target[DataInput.QuizRooms][group_index].rooms = [];
                    this.setState({
                        TargetEventModal: target,
                    }, () => {
                        if (this.state.isDevMode)
                            console.log(`EditEventGroupRooms (${groupId}) (${idx}) (Remove) \n ${JSON.stringify(target)}`);
                    });
                }
            }
            else {
                //edit this room.
                this.ToggleEditEventRoomModal();
                if (this.state.isDevMode)
                    console.log(`EditEventGroupRooms (${groupId}) (${idx}) (Edit) \n ${JSON.stringify(room)}`);
            }
        }
    }
    ToggleEditEventRoomModal = async () => {
        if (this.state.PA_Update === false)
            return null;
        const toggle = !this.state.ShowEditEventRoomModal;
        if (toggle) {
            this.ResetEditRoomState();
            await Delay(500);
        }
        this.setState({
            ShowEditEventRoomModal: toggle,
        });
        if (toggle === false) {
            this.setState({
                SelectedEventRoom: null,
                SelectedQuizRoomGroupId: 0,
            });
            this.ResetEditRoomState();
        }
    }
    ResetEditRoomState = () => {
        this.setState({
            // EditEventRoomState: 0,
            EditEventRoomState_Idx: -1,
            EditEventRoomState_RoomCode_Options: 2,
            SearchRoom_ByRoomCode: '',
            SearchRoom_ResultList: [],
            SearchRoom_ResultList_Completed: false,
        });
    }
    GetTimeLimit = (state = null, name = '') => {
        if (state === null)
            return null;
        let splits = String(state[name]).split(':');
        let hr = Number(splits[0]);
        let min = Number(splits[1]);
        return (hr > 1 ? (hr + ' hrs ') : hr > 0 ? (hr + ' hr ') : '')
            + (min > 1 ? (min + ' mins') : min > 0 ? (min + ' min') : '');
    }
    EditEventRoomComponents = () => {
        const target = this.state.TargetEventModal;
        if (target === null)
            return null;

        const selectedRoom = this.state.SelectedEventRoom;
        const dateStart = CheckObjectNullValue(selectedRoom, RoomDataInput.DateStart) === null ? '-' : moment(CheckObjectStringEmpty(selectedRoom, RoomDataInput.DateStart)).format('MMM DD, YYYY');
        const dateEnd = CheckObjectNullValue(selectedRoom, RoomDataInput.DateEnd) === null ? '-' : moment(CheckObjectStringEmpty(selectedRoom, RoomDataInput.DateEnd)).format('MMM DD, YYYY');
        const timeStart = CheckObjectNullValue(selectedRoom, RoomDataInput.TimeStart) === null ? '-' : moment('2024.11.07 ' + CheckObjectStringEmpty(selectedRoom, RoomDataInput.TimeStart)).format('hh:mm A');
        const timeEnd = CheckObjectNullValue(selectedRoom, RoomDataInput.TimeEnd) === null ? '-' : moment('2024.11.07 ' + CheckObjectStringEmpty(selectedRoom, RoomDataInput.TimeEnd)).format('hh:mm A');
        const timeLimit = this.GetDurationToTimeLimit_HHMM(CheckObjectNumber(selectedRoom, RoomDataInput.Duration));

        const group_index = target[DataInput.QuizRooms].findIndex(x => x.groupId === this.state.SelectedQuizRoomGroupId);
        const group = group_index < 0 ? '-' : CheckObjectStringEmpty(target[DataInput.QuizRooms][group_index], RoomDataInput.Group, '-');
        const subject = CheckObjectStringEmpty(selectedRoom, RoomDataInput.Subject, '-');

        return (<table className='tb-edit-event-room' cellPadding='5'>
            <tbody>
                {
                    //#region === event room details.
                    CheckObjectStringEmpty(selectedRoom, RoomDataInput.RoomCode) === '' ? null :
                        <>
                            <tr>
                                <td className='left'>Current Room Code</td>
                                <td>:</td>
                                <td className='right'>{CheckObjectStringEmpty(selectedRoom, RoomDataInput.RoomCode, '-')}</td>
                            </tr>
                            <tr>
                                <td className='left'>Group</td>
                                <td>:</td>
                                <td className='right'>{CheckObjectStringEmpty(selectedRoom, RoomDataInput.Group, '-')}</td>
                            </tr>
                            <tr>
                                <td className='left'>Subject</td>
                                <td>:</td>
                                <td className='right'>{subject}</td>
                            </tr>
                            <tr>
                                <td className='left'>Date (From)</td>
                                <td>:</td>
                                <td className='right'>{dateStart}</td>
                            </tr>
                            <tr>
                                <td className='left'>Date (To)</td>
                                <td>:</td>
                                <td className='right'>{dateEnd}</td>
                            </tr>
                            <tr>
                                <td className='left'>Time (From)</td>
                                <td>:</td>
                                <td className='right'>{timeStart}</td>
                            </tr>
                            <tr>
                                <td className='left'>Time (To)</td>
                                <td>:</td>
                                <td className='right'>{timeEnd}</td>
                            </tr>
                            <tr>
                                <td className='left'>Time Limit</td>
                                <td>:</td>
                                {/* <td className='right'>{this.GetTimeLimit(selectedRoom, RoomDataInput.TimeLimit)}</td> */}
                                {/* <td className='right'>{CheckObjectStringEmpty(selectedRoom, RoomDataInput.TimeLimit, '-')}</td> */}
                                <td className='right'>{timeLimit}</td>
                            </tr>
                            <tr><td colSpan='3'><hr /></td></tr>
                        </>
                    //#endregion
                }
                {/* <tr>
                    <td className='left'>
                        <Button variant={this.state.EditEventRoomState_RoomCode_Options === 1 ? 'secondary' : 'primary'}
                            onClick={() => this.setState({
                                EditEventRoomState_RoomCode_Options: 1, SearchRoom_ByRoomCode: '', SearchRoom_ResultList: [],
                            }, () => {
                                this.ManageRoomRef.current.ToggleCreateRoomModal();
                                // setTimeout(() => {
                                //     this.ManageRoomRef.current.ToggleCreateRoomModal();      //testing response.
                                // }, 2000);
                            })}
                            disabled={this.state.EditEventRoomState_RoomCode_Options === 1}
                        >New Room</Button>
                    </td>
                    <td>&nbsp;</td>
                    <td className='right'>
                        <Button variant={this.state.EditEventRoomState_RoomCode_Options === 2 ? 'secondary' : 'primary'}
                            onClick={() => this.setState({ EditEventRoomState_RoomCode_Options: 2, SearchRoom_ByRoomCode: '', SearchRoom_ResultList: [], })}
                            disabled={this.state.EditEventRoomState_RoomCode_Options === 2}>Search Room</Button>
                    </td>
                </tr> */}
                {
                    //#region === Search Room by Room Code.
                }
                {/* <tr>
                    <td>{group_index}</td>
                    <td>{group}</td>
                    <td>{subject}</td>
                </tr> */}
                {
                    this.state.EditEventRoomState_RoomCode_Options === 2 ?
                        <tr>
                            <td colSpan='3'>
                                <table cellPadding='15' className='tb-search-room'>
                                    <tbody>
                                        <tr className='title'>
                                            <td colSpan='3'>Search Room by Room Code</td>
                                        </tr>
                                        <tr className='item'>
                                            <td align='right'>Room Code</td>
                                            <td>
                                                <input type='text' width='100%' placeholder='(e.g. 82716)'
                                                    ref={this.SearchRoom_ByRoomCode_InputRef}
                                                    onChange={(e) => this.setState({
                                                        SearchRoom_ByRoomCode: e.currentTarget.value.trim(),
                                                        SearchRoom_ResultList: [],
                                                    }, () => {
                                                        this.SearchRoom_ByRoomCode_InputRef.current.value = this.state.SearchRoom_ByRoomCode;
                                                    })} />
                                            </td>
                                            <td>
                                                <Button variant="primary" onClick={() => this.SearchRoomByRoomCode()}
                                                    disabled={this.state.SearchRoom_ByRoomCode === ''}
                                                    style={{ width: 85 }}
                                                >Search</Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        : null
                }
                {
                    this.state.SearchRoom_ByRoomCode === '' ?
                        null
                        :
                        this.state.SearchRoom_ResultList === null ?
                            <tr><td colSpan='3' align='center'><ProgressBar animated now={100} className='progressbar1' /></td></tr>
                            :
                            this.state.SearchRoom_ResultList_Completed ?
                                this.state.SearchRoom_ResultList.length > 0 ?
                                    <tr>
                                        <td colSpan='3'>
                                            <b>Result</b><br />
                                            <table cellPadding='15' className='tb-search-room' border='0'>
                                                <tbody>
                                                    {
                                                        this.state.SearchRoom_ResultList.map((room, key) => {
                                                            if (room === null) {
                                                                return (<tr><td colSpan='3' align='center'>no result</td></tr>);
                                                            }
                                                            else {
                                                                const room_group = CheckObjectStringEmpty(room, RoomDataInput.Group, '-');
                                                                const room_subject = CheckObjectStringEmpty(room, RoomDataInput.Subject, '-');
                                                                const matched_group = group === '-' ? <i className="fa fa-check-circle blue"></i> : (group === room_group ? <i className="fa fa-check-circle blue"></i> : <i className="fa fa-times-circle red"></i>);
                                                                const matched_subject = subject === '-' ? <i className="fa fa-check-circle blue"></i> : (subject === room_subject ? <i className="fa fa-check-circle blue"></i> : <i className="fa fa-times-circle red"></i>);
                                                                return <>
                                                                    <tr className='result'>
                                                                        <td className='title'>Room Code</td>
                                                                        <td>{CheckObjectStringEmpty(room, RoomDataInput.RoomCode, '-')}</td>
                                                                        <td className='action' rowSpan='4' style={{ padding: '0px 4px' }}>
                                                                            <Button
                                                                                variant='primary'
                                                                                onClick={async () => await this.SelectRoomFromSearchResult(room)}
                                                                                style={{ width: 85 }}
                                                                            >Select</Button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr className='result'>
                                                                        <td className='title'>Room Title</td>
                                                                        <td>{CheckObjectStringEmpty(room, RoomDataInput.RoomTitle, '-')}</td>
                                                                    </tr>
                                                                    <tr className='result'>
                                                                        <td className='title'>Group</td>
                                                                        <td className="icon-color">{room_group} {matched_group}</td>
                                                                    </tr>
                                                                    <tr className='result'>
                                                                        <td className='title'>Subject</td>
                                                                        <td className="icon-color">{room_subject} {matched_subject}</td>
                                                                    </tr>
                                                                    {
                                                                        this.CheckIfRoomIsMatched(room) ?
                                                                            <tr className='result-match'><td colSpan='3'>This room's Group & Subject are matched.</td></tr>
                                                                            : <tr className='result-not-match'><td colSpan='3'>This room's Group or Subject is not match.</td></tr>
                                                                    }
                                                                </>
                                                            }
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    : <tr><td colSpan='3' align='center'>- no result -</td></tr>
                                : null
                }
                {
                    //#endregion    === Search Room by Room Code === end
                }
            </tbody>
        </table>);
    }
    SearchRoomByRoomCode = async () => {
        this.setState({
            SearchRoom_ResultList: null,
            SearchRoom_ResultList_Completed: false,
        });

        const roomCode = CheckStringEmpty(this.state.SearchRoom_ByRoomCode);
        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);

        let _room = null;
        let done = false;
        await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Quiz/Room/Get/'
            + organizerId + '/'
            + authorId + '/'
            + roomCode + '/0',
            // Api/LearningCentre/Quiz/Room/Get/{organizerId}/{authorId}/{roomCode}/{roomId}
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('SearchRoomByRoomCode (response)', JSON.stringify(data));
                if (data.success)
                    _room = DecapitalizeJsonKeys(data.data);
                else
                    if (this.state.isDevMode)
                        console.log('Error', 'api - room get (failed) (' + roomCode + ') \n' + JSON.stringify(data));
                done = true;
            })
            .catch(error => {
                done = true;
                if (this.state.isDevMode)
                    console.log('Error', 'api - room get (error) (' + roomCode + ') \n' + error.message);
            });
        await DelayUntil(() => done === true);

        let _resultList = [];
        if (_room !== null)
            _resultList.push(_room);

        this.setState({
            SearchRoom_ResultList: _resultList,
            SearchRoom_ResultList_Completed: true,
        }, () => {
            ScrollToElement('editEventRoomUiBottom');
        });
    }
    CheckIfRoomIsMatched = (room = null) => {
        if (room === null)
            return true;

        if (room[RoomDataInput.GroupId] !== this.state.SelectedQuizRoomGroupId)
            return false;

        const rooms = this.state.TargetEventModal[DataInput.QuizRooms].filter(x => x.groupId === this.state.SelectedQuizRoomGroupId).rooms;
        if (this.state.EditEventRoomState_Idx > -1 && rooms !== undefined)
            if (room[RoomDataInput.SubjectId] !== rooms[this.state.EditEventRoomState_Idx][RoomDataInput.SubjectId])
                return false;

        return true;

        // const target = this.state.TargetEventModal;
        // let quizRoomGroups = target[DataInput.QuizRooms];
        // if (quizRoomGroups === undefined || quizRoomGroups === null || Array.isArray(quizRoomGroups) === false)
        //     quizRoomGroups = [];

        // let matched = true;
        // for (let i = 0; i < quizRoomGroups.length; i++) {
        //     if (Number(quizRoomGroups[i][RoomDataInput.GroupId]) !== Number(room[RoomDataInput.GroupId]))   //not match.
        //     {
        //         matched = false;
        //         break;
        //     }
        //     else {
        //         const rooms = quizRoomGroups[i]['rooms'];
        //         if (Array.isArray(rooms) && rooms.length > 0) {
        //             for (let k = 0; k < rooms.length; k++) {
        //                 if (rooms[k][RoomDataInput.SubjectId] !== room[RoomDataInput.SubjectId])    //not match.
        //                 {
        //                     matched = false;
        //                     break;
        //                 }
        //             }
        //             if (matched === false)
        //                 break;
        //         }
        //     }
        // }
        // return matched;
    }
    SelectRoomFromSearchResult = async (selectedRoom = null) => {

        if (selectedRoom === null)
            return null;

        if (this.state.isDevMode)
            console.log('SelectRoomFromSearchResult =\n' + JSON.stringify(selectedRoom));

        // this.setState({
        //     SearchRoom_Result_SelectedRoom: room,   //set
        // }, ()=> {
        // let eventRooms = this.state.TargetEventRooms;
        // eventRooms.sort((a, b) => a.GroupId - b.GroupId);

        // const selectedEventRoom = this.state.SelectedEventRoom;

        // let _findIndex = eventRooms.findIndex(x => Number(x.GroupId) === Number(selectedEventRoom.GroupId));
        // let _findIndex = eventRooms.findIndex(x => x.GroupId === selectedEventRoom.GroupId && x.Subject === selectedEventRoom.Subject);
        // let _findIndex = eventRooms.findIndex(x => JSON.stringify(x) === JSON.stringify(selectedEventRoom));

        // //2022.10.26
        // if (_findIndex < 0 && this.state.EditEventRoomState_Idx > -1) {
        //     _findIndex = this.state.EditEventRoomState_Idx;
        // }
        // const _findIndex = this.state.EditEventRoomState_Idx

        // if (_findIndex > -1) {
        //     // // if (eventRooms[_findIndex].RoomCode === 'TBA') {
        //     // if (String(eventRooms[_findIndex].RoomCode) === 'TBA'
        //     //     || String(eventRooms[_findIndex].RoomCode).length < 5
        //     //     || String(eventRooms[_findIndex].RoomCode) !== String(room.RoomCode)) {   //2022.06.11
        //     //     eventRooms[_findIndex].RoomCode = room.RoomCode;
        //     //     selectedEventRoom.RoomCode = room.RoomCode;
        //     //     // console.log(JSON.stringify(eventRooms[_findIndex]));

        //     //     //2022.10.26
        //     //     eventRooms[_findIndex].Subject = selectedEventRoom.Subject;
        //     //     eventRooms[_findIndex].SubjectId = selectedEventRoom.SubjectId;
        //     //     eventRooms[_findIndex].DateStart = room.DateStart;
        //     //     eventRooms[_findIndex].DateEnd = room.DateEnd;
        //     //     eventRooms[_findIndex].TimeStart = room.TimeStart;
        //     //     eventRooms[_findIndex].TimeEnd = room.TimeEnd;
        //     //     eventRooms[_findIndex].TimeLimit = room.TimeLimit;
        //     // }

        //     //2022.10.26
        //     if (eventRooms[_findIndex] !== undefined) {
        //         if (String(eventRooms[_findIndex].RoomCode) === 'TBA'
        //             || String(eventRooms[_findIndex].RoomCode).length < 5
        //             || String(eventRooms[_findIndex].RoomCode) !== String(room.RoomCode)) {

        //             eventRooms[_findIndex].RoomCode = room.RoomCode;
        //             selectedEventRoom.roomCode = room.RoomCode;

        //             eventRooms[_findIndex].DateStart = room.DateStart;
        //             eventRooms[_findIndex].DateEnd = room.DateEnd;
        //             eventRooms[_findIndex].TimeStart = room.TimeStart;
        //             eventRooms[_findIndex].TimeEnd = room.TimeEnd;
        //             eventRooms[_findIndex].TimeLimit = room.TimeLimit;
        //         }
        //         if (String(eventRooms[_findIndex].Subject) === 'TBA'
        //             || Number(eventRooms[_findIndex].SubjectId) === 0
        //             || String(eventRooms[_findIndex].Subject) !== String(room.Subject)
        //             || Number(eventRooms[_findIndex].SubjectId) !== Number(room.SubjectId)) {

        //             eventRooms[_findIndex].Subject = room.Subject;
        //             eventRooms[_findIndex].SubjectId = room.SubjectId;
        //         }
        //         eventRooms[_findIndex].EventCode = String(this.state.TargetEventModal.EventCode);   //2022.06.11
        //     }
        // }
        // if (this.state.isDevMode)
        //     console.log('eventRooms =\n' + JSON.stringify(eventRooms));

        // let targetEvent = this.state.TargetEventModal;
        // targetEvent.QuizRooms.map((group, key1) => {
        //     if (Number(group.GroupId) === Number(selectedEventRoom.GroupId)) {
        //         group.Rooms.map((groom, key2) => {
        //             // if (groom.Subject === selectedEventRoom.Subject) {
        //             //     groom.RoomCode = room.RoomCode;
        //             // }

        //             //2022.10.26
        //             if (Number(groom.SubjectId) === 0 || String(groom.Subject).length === 0) {
        //                 groom.Subject = selectedEventRoom.Subject;
        //                 groom.SubjectId = selectedEventRoom.SubjectId;
        //             }
        //             if (String(groom.RoomCode) === 'TBA' || String(groom.RoomCode).length < 5) {
        //                 groom.RoomCode = room.RoomCode;
        //                 groom.DateStart = room.DateStart;
        //                 groom.DateEnd = room.DateEnd;
        //                 groom.TimeStart = room.TimeStart;
        //                 groom.TimeEnd = room.TimeEnd;
        //                 groom.TimeLimit = room.TimeLimit;
        //             }
        //             return null;
        //         });
        //     }
        //     return null;
        // });

        //2022.10.26
        let target = { ...this.state.TargetEventModal };
        target[DataInput.QuizRooms].sort((a, b) => a[RoomDataInput.GroupId] - b[RoomDataInput.GroupId]);

        let errorMessage = '';
        const selectedRoom_RoomCode = String(selectedRoom[RoomDataInput.RoomCode]);
        for (let p = 0; p < target[DataInput.QuizRooms].length; p++) {
            // console.log(JSON.stringify(target[DataInput.QuizRooms][p]));
            if (target[DataInput.QuizRooms][p].rooms.findIndex(x => String(x.roomCode) === selectedRoom_RoomCode) > -1) {
                const group_name = String(target[DataInput.QuizRooms][p].group);
                errorMessage = `<b>Room &#60;${selectedRoom_RoomCode}&#62;</b> has already assigned to <b>Group &#60;${group_name}&#62;</b>.`;
                break;
            }
        }
        if (errorMessage.length > 0) {
            useAppService.getState().setModal('', errorMessage);
            return null;
        }

        const group_index = target[DataInput.QuizRooms].findIndex(x => x.groupId === this.state.SelectedQuizRoomGroupId);
        if (group_index > -1) {
            //set.

            if (this.state.EditEventRoomState_Idx > -1)
                target[DataInput.QuizRooms][group_index].rooms.splice(this.state.EditEventRoomState_Idx, 1);
            // else if (target[DataInput.QuizRooms][group_index].rooms.length === 1)
            //     target[DataInput.QuizRooms][group_index].rooms = [];

            // if (target[DataInput.QuizRooms][group_index].rooms.length > 1)
            //     target[DataInput.QuizRooms][group_index].rooms.splice(this.state.EditEventRoomState_Idx, 1);
            // else
            //     target[DataInput.QuizRooms][group_index].rooms = [];

            target[DataInput.QuizRooms][group_index].rooms.push({
                roomCode: selectedRoom[RoomDataInput.RoomCode],
                group: selectedRoom[RoomDataInput.Group],
                groupId: selectedRoom[RoomDataInput.GroupId],
                subject: selectedRoom[RoomDataInput.Subject],
                subjectId: selectedRoom[RoomDataInput.SubjectId],
                dateStart: selectedRoom[RoomDataInput.DateStart],
                dateEnd: selectedRoom[RoomDataInput.DateEnd],
                date: selectedRoom[RoomDataInput.Date],
                timeStart: selectedRoom[RoomDataInput.TimeStart],
                timeEnd: selectedRoom[RoomDataInput.TimeEnd],
                timeLimit: selectedRoom[RoomDataInput.TimeLimit],
                extraUrl: selectedRoom[RoomDataInput.ExtraUrl],
            });
        }

        // for (let e = 0; e < target[DataInput.QuizRooms].length; e++) {
        //     let group = { ...target[DataInput.QuizRooms][e] };
        //     if (Number(group[RoomDataInput.GroupId]) === Number(room[RoomDataInput.GroupId])) {
        //         if (Array.isArray(group.rooms) && group.rooms.length > 0) {
        //             for (let f = 0; f < group.rooms.length; f++) {
        //                 let groom = { ...group.rooms[f] };
        //                 if (Number(groom[RoomDataInput.SubjectId]) === 0 || String(groom[RoomDataInput.Subject]).length === 0) {
        //                     groom[RoomDataInput.Subject] = room[RoomDataInput.Subject];
        //                     groom[RoomDataInput.SubjectId] = room[RoomDataInput.SubjectId];
        //                 }
        //                 if (String(groom[RoomDataInput.RoomCode]) === 'TBA' || String(groom[RoomDataInput.RoomCode]).length < 5) {
        //                     groom[RoomDataInput.RoomCode] = room[RoomDataInput.RoomCode];
        //                     groom[RoomDataInput.DateStart] = room[RoomDataInput.DateStart];
        //                     groom[RoomDataInput.DateEnd] = room[RoomDataInput.DateEnd];
        //                     groom[RoomDataInput.TimeStart] = room[RoomDataInput.TimeStart];
        //                     groom[RoomDataInput.TimeEnd] = room[RoomDataInput.TimeEnd];
        //                     groom[RoomDataInput.TimeLimit] = room[RoomDataInput.TimeLimit];
        //                 }
        //                 group.rooms[f] = groom;
        //             }
        //         }
        //         else {
        //             group.rooms.push({
        //                 group: room[RoomDataInput.Group],
        //                 groupId: room[RoomDataInput.GroupId],
        //                 subject: room[RoomDataInput.Subject],
        //                 subjectId: room[RoomDataInput.SubjectId],
        //                 roomCode: room[RoomDataInput.RoomCode],
        //                 dateStart: room[RoomDataInput.DateStart],
        //                 dateEnd: room[RoomDataInput.DateEnd],
        //                 date: room[RoomDataInput.Date],
        //                 timeStart: room[RoomDataInput.TimeStart],
        //                 timeEnd: room[RoomDataInput.TimeEnd],
        //                 timeLimit: room[RoomDataInput.TimeLimit],
        //                 extraUrl: room[RoomDataInput.ExtraUrl],
        //             });
        //         }
        //         target[DataInput.QuizRooms][e] = group;
        //     }
        // }

        target = this.PopulateEventRooms(target);   //important for highlight css.
        if (this.state.isDevMode)
            console.log('targetEvent (after) =\n' + JSON.stringify(target));

        // //2021.10.12
        // let _temp = this.state.Temp_TargetEventRooms;
        // if (_temp.length > 0) {
        //     _temp.sort((a, b) => a.GroupId - b.GroupId);
        //     // _temp[_findIndex].matched = this.CheckIfRoomIsMatched(room);
        //     if (_temp[_findIndex] !== undefined) {
        //         if (_temp[_findIndex].GroupId !== undefined
        //             && _temp[_findIndex].Subject !== undefined
        //             && _temp[_findIndex].RoomCode !== undefined) {

        //             _temp[_findIndex].RoomCode = room.RoomCode;

        //             //2022.10.26
        //             if (Number(_temp[_findIndex].SubjectId) === 0
        //                 || String(_temp[_findIndex].Subject) === 'TBA'
        //                 || String(_temp[_findIndex].Subject).length === 0) {

        //                 _temp[_findIndex].Subject = room.Subject;
        //                 _temp[_findIndex].SubjectId = room.SubjectId;
        //                 _temp[_findIndex].DateStart = room.DateStart;
        //                 _temp[_findIndex].DateEnd = room.DateEnd;
        //                 _temp[_findIndex].TimeStart = room.TimeStart;
        //                 _temp[_findIndex].TimeEnd = room.TimeEnd;
        //                 _temp[_findIndex].TimeLimit = room.TimeLimit;

        //                 _temp[_findIndex].matched = true;
        //             }
        //             else {
        //                 if (Number(_temp[_findIndex].GroupId) === Number(room.GroupId)
        //                     && Number(_temp[_findIndex].SubjectId) === Number(room.SubjectId)) {
        //                     _temp[_findIndex].matched = true;
        //                 }
        //                 else {
        //                     _temp[_findIndex].matched = false;
        //                     _temp[_findIndex].room = room;      //extra info to show on Ui for not-match-only.
        //                 }
        //             }
        //         }
        //     }
        // }

        // console.log('selectedEventRoom =\n' + JSON.stringify(selectedEventRoom));
        // console.log('eventRooms =\n' + JSON.stringify(eventRooms));
        // console.log('_findIndex = ' + _findIndex);
        // // console.log(_temp[_findIndex].GroupId + ' vs ' + room.GroupId);
        // // console.log(_temp[_findIndex].Subject + ' vs ' + room.SubjectName);
        // console.log('_temp =\n' + JSON.stringify(_temp));
        // console.log('_temp[_findIndex] =\n' + JSON.stringify(_temp[_findIndex]));
        // console.log('room =\n' + JSON.stringify(room));

        this.setState({
            TargetEventModal: target,
            SelectedEventRoom: selectedRoom,
            // TargetEventRooms: eventRooms,
            // SelectedEventRoom: selectedEventRoom,
            // SearchRoom_Result_SelectedRoom: null,   //reset

            // Temp_TargetEventRooms: _temp,

            IsEventRoomStateDirty: true,    //2021.11.25
        }, async () => {
            this.ToggleEditEventRoomModal();
            await Delay(500);
            this.ResetEditRoomState();
            // this.ToggleViewEventModal();
            // this.ViewEventDetail(this.state.TargetEventIndex);

        });
        // });
    }

    // GetTargetEvent_Rooms_TableRows_original = () => {
    //     const rooms = this.state.TargetEventRooms;
    //     if (Array.isArray(rooms) === false || rooms.length === 0)
    //         return null;

    //     let components = [];

    //     let seperators = [];
    //     let prev = '';
    //     let counter = 0;
    //     for (let p = 0; p < rooms.length; p++) {
    //         const room = rooms[p];
    //         if (room.Group !== prev) {
    //             prev = room.Group;
    //             counter = 1;
    //             seperators.push({ Group: room.Group, Total: counter, Index: p, });
    //         }
    //         else {
    //             counter++;
    //             const idx = seperators.findIndex(x => x.Group === room.Group);
    //             if (idx > -1)
    //                 seperators[idx].Total = counter;
    //         }
    //     }
    //     for (let k = 0; k < rooms.length; k++) {
    //         const room = rooms[k];
    //         let group_idx = -1;
    //         const idx = seperators.findIndex(x => x.Group === room.Group);
    //         if (idx > -1)
    //             group_idx = seperators[idx].Index;

    //         components.push(<tr key={'target_evt_room_' + k}>
    //             {
    //                 group_idx === k ?
    //                     <td rowSpan={seperators[idx].Total} className='hide-column-hover'>{this.GetValue(seperators[idx], 'Group')}</td>
    //                     : null
    //             }
    //             <td>{room.Subject}</td>
    //             <td>
    //                 <Button
    //                     variant={
    //                         room.RoomCode === 'TBA' ? 'primary' :
    //                             this.state.Temp_TargetEventRooms[k] === undefined ? 'primary' :
    //                                 this.state.Temp_TargetEventRooms[k].matched ? 'primary' : 'secondary'
    //                     }
    //                     onClick={() => this.EditEventRoom(room, k)}
    //                 >{room.RoomCode}</Button>
    //                 {
    //                     this.state.Temp_TargetEventRooms[k] === undefined || this.state.Temp_TargetEventRooms[k].length <= 0 ||
    //                         this.state.Temp_TargetEventRooms[k].matched === undefined || this.state.Temp_TargetEventRooms[k].matched === true
    //                         || this.state.Temp_TargetEventRooms[k].RoomCode === undefined || this.state.Temp_TargetEventRooms[k].RoomCode === 'TBA'
    //                         // this.state.Temp_TargetEventRooms[k].matched === true ? null :
    //                         //     this.state.Temp_TargetEventRooms[k].RoomCode === 'TBA' ? null :
    //                         ? null :
    //                         <span style={{ color: 'gray', fontSize: 14, textAlign: 'center', width: '100%', marginTop: 5, }}>
    //                             <br /><i><b>Room not match</b></i><br />
    //                             <table className='tb-no-padding' cellPadding='0' cellSpacing='0' width='100%' border='0' style={{ marginTop: 3, }}>
    //                                 <tbody>
    //                                     <tr><td>Group</td><td>:</td><td>{
    //                                         this.state.Temp_TargetEventRooms[k].room === undefined ? ''
    //                                             : this.GetValue(this.state.Temp_TargetEventRooms[k].room, 'GroupId')
    //                                     }</td></tr>
    //                                     <tr><td>Subject</td><td>:</td><td>{
    //                                         this.state.Temp_TargetEventRooms[k].room === undefined ? ''
    //                                             : this.state.Temp_TargetEventRooms[k].room.Subject
    //                                     }</td></tr>
    //                                     <tr>
    //                                         <td colSpan='3'>
    //                                             <button className='link-button' onClick={() => this.ClearRoomAllocation(room)}>clear</button>
    //                                         </td>
    //                                     </tr>
    //                                 </tbody>
    //                             </table>
    //                         </span>
    //                 }
    //             </td>
    //             {
    //                 this.state.TargetEventModal[DataInput.RoomsHeldOnSelfTime] ?
    //                     <>
    //                         <td>{room['TimeStart']}</td>
    //                         <td>{room['TimeEnd']}</td>
    //                     </>
    //                     : null
    //             }
    //         </tr>);
    //     }
    //     return components;
    // }
    // EditEventRoom = (room, idx = -1) => {
    //     // console.log(JSON.stringify(room));
    //     this.setState({
    //         EditEventRoomState: 1,
    //         EditEventRoomState_Idx: idx,
    //         SelectedEventRoom: room,
    //         EditEventRoomState_RoomCode_Options: 0,
    //         SearchRoom_ResultList_Completed: false,
    //     });
    // }

    // CheckIfAllowToProceed = async () => {
    //     if (this.state.ShowEditEventRoomModal) {
    //         let _temp = this.state.Temp_TargetEventRooms;
    //         if (_temp.length > 0) {
    //             _temp.sort((a, b) => a.GroupId - b.GroupId);
    //             // console.log(JSON.stringify(this.state.Temp_TargetEventRooms));
    //             let isRoomNotMatch = false;
    //             _temp.map((data, key) => {
    //                 if (data.RoomCode !== 'TBA' && data.matched === false)
    //                     isRoomNotMatch = true;
    //                 return null;
    //             });
    //             if (isRoomNotMatch) {
    //                 useAppService.getState().setModal('Attention Needed', 'Please check if all rooms are matched with its Group & Subject before proceed.');
    //                 return false;
    //             }
    //         }
    //     }
    //     return true;
    // }
    // GetModalSize_EditEventRoom = () => {
    //     switch (this.state.EditEventRoomState) {
    //         default: return '';
    //         case 0: return 'lg';
    //         case 1: return '';
    //     }
    // }
    // SaveEventRoomState = async () => {
    //     if (this.state.IsEventRoomStateDirty) {

    //         if (this.state.TargetEventModal.hasOwnProperty('Id') === false)
    //             return null;

    //         useAppService.getState().setModal('', 'saving event rooms...', null, AlertMode.Loading);

    //         const updateModal = {
    //             QuizRooms: this.state.TargetEventModal[DataInput.QuizRooms],
    //         };
    //         if (this.state.isDevMode)
    //             console.log('\nQuizRooms =\n' + JSON.stringify(updateModal));

    //         // await firestore
    //         //     .collection('LiveQuiz_UpcomingEvents')
    //         //     .doc(String(this.state.TargetEventModal.Id))
    //         //     .update(JSON.parse(JSON.stringify(updateModal)))
    //         //     .then(() => { if (this.state.isDevMode) { console.log('update rooms (done)'); } })
    //         //     .catch(error => { if (this.state.isDevMode) { console.log('update rooms (failed) =\n' + error.message); } });

    //         // await getDocs(query(collection(firestore, 'LiveQuiz_UniqueRoomCode'),
    //         //     where('RoomCode', '==', Number(roomCode)), limit(1)))
    //         //     .then((querySnapshot) => {
    //         //         querySnapshot.forEach((doc) => {
    //         //             data.push(doc.data());
    //         //         });
    //         //         success = true;
    //         //         done = true;
    //         //     }).catch(error => {
    //         //         success = false;
    //         //         errorMessage = error;
    //         //         done = true;
    //         //     });

    //         // await Delay(500);

    //         // let syncSuccess = await this.TriggerToSyncQuizEventSettingsViaApi(String(this.state.TargetEventModal.EventCode));
    //         // if (syncSuccess === false) {
    //         //     if (this.state.isDevMode)
    //         //         console.log('Failed to sync event setting.');
    //         // }

    //         //done.
    //         await Delay(1000);
    //         this.ToggleEditEventRoomModal();
    //         this.ToggleViewEventModal();
    //         await Delay(500);
    //         this.ViewEventDetail(this.state.TargetEventModal_Index, true);
    //         useAppService.getState().setModal();
    //         await Delay(500);
    //         useAppService.getState().setModal('', 'Event setting has been updated.');
    //     }
    // }
    //#endregion === edit room(s) ===

    //#region === Upload File (Cert Img / PAJSK pdf) === start ===//
    ToggleFileUploadUi = () => {
        if (this.state.PA_Update === false)
            return null;
        const toggle = !this.state.FileUploadUi_Toggle;
        this.setState({
            FileUploadUi_Toggle: toggle,
        }, () => {
            if (toggle === false) {
                //Reset States.
                if (this.state.UploadState_CertImage === UploadState.Success
                    || this.state.UploadState_PAJSK === UploadState.Success) {
                    //done.
                    this.ActionsAfterUploadSuccess();
                }
            }
            else {
                //Reset All related States.
                this.ResetUploadStates();
            }
            // console.log('ToggleFileUploadUi (' + toggle + ')');
        });
    }
    ResetUploadStates = () => {
        this.setState({
            UploadState_CertImage: UploadState.Browse,
            UploadState_PAJSK: UploadState.Browse,
            FileToUpload: null,
            UploadErrorMessage: [],
        });
    }
    ActionsAfterUploadSuccess = async () => {
        await Delay(500);
        this.ToggleEditEventUiModal();
        await this.LoadList_ViaApi();     //refresh event list.
        await Delay(300);
        useAppService.getState().setModal();
        if (this.state.List.length > 0) {
            if (this.state.EditEventUiModal_Toggle)
                await this.ToggleEditEventUiModal(this.state.TargetEventModal_Index);
            await Delay(1000);
            useAppService.getState().setModal('', Locale("file-upload-success", this.state.locale));
        }
        else {
            useAppService.getState().setModal('', 'Event failed to save.<br />list is empty.');
        }
        //Reset All related States.
        this.ResetUploadStates();
    }
    Get_FileName_Long = (text = '') => {
        const eventCode = CheckObjectStringEmpty(this.state.TargetEventModal, DataInput.EventCode);
        if (text.includes(eventCode))
            text = text.replace('https://ikeynew.blob.core.windows.net/ikeykidz/CERT/' + eventCode + '/', '');
        else
            text = text.replace('https://ikeynew.blob.core.windows.net/ikeykidz/CERT/', '');
        return text;
    }
    Get_FileName_Short = (text = '') => {
        const eventCode = CheckObjectStringEmpty(this.state.TargetEventModal, DataInput.EventCode);
        if (text.includes(eventCode))
            text = text.replace('ikeynew.blob.core.windows.net/ikeykidz/CERT/' + eventCode, '{hidden}');
        else
            text = text.replace('ikeynew.blob.core.windows.net/ikeykidz/CERT', '{hidden}');

        if (text.length > 50) {
            let url = 'https://{hidden}/';
            let ext = '';
            let lowerText = text.toLowerCase();
            if (lowerText.includes('.pdf'))
                ext = '.pdf';
            else if (lowerText.includes('.rtf'))
                ext = '.rtf';
            else if (lowerText.includes('.txt'))
                ext = '.txt';
            else if (lowerText.includes('.doc'))
                ext = '.doc';
            else if (lowerText.includes('.docx'))
                ext = '.docx';
            else if (lowerText.includes('.jpg'))
                ext = '.jpg';
            else if (lowerText.includes('.png'))
                ext = '.png';
            text = text.replace(url, '').replace(ext, '');
            let front = text.substring(0, 15);
            let rear = text.substring(text.length - 10, text.length);
            text = url + front + '...' + rear + ext;
        }
        return text;
    }
    ViewEventMode_Upload_File = (fileMode = FileMode.None) => {
        const target = this.state.TargetEventModal;
        // console.log(JSON.stringify(target));
        if (target === null || target === undefined || CheckObjectNumber(target, DataInput.TimeStampId) === 0) {
            useAppService.getState().setModal('', 'Invalid Event.');
            return null;
        }
        this.setState({
            UploadFileMode: fileMode,
            UploadState_CertImage: UploadState.Browse,
            UploadState_PAJSK: UploadState.Browse,
        }, () => {
            this.ToggleFileUploadUi();
        });
    }
    FileUploadComponentUi = () => {

        let _state = 0;
        if (this.state.UploadFileMode === FileMode.CertImg)
            _state = this.state.UploadState_CertImage;
        else if (this.state.UploadFileMode === FileMode.PAJSK)
            _state = this.state.UploadState_PAJSK;

        //0 = browse file, 1 = wrong file format, 2 = uploading, 3 = upload failed, 4 = upload success.
        switch (_state) {
            default: return null;
            case UploadState.Browse:
                return (<>
                    <span>Browse for a file to upload </span><br />
                    <span style={{ color: 'gray', paddingBottom: 10, }}>(supported file format: <b><i>{this.GetSupportedFileExt_Label(this.state.UploadFileMode)}</i></b> )</span><p />
                    <input
                        type="file"
                        name="file"
                        accept={this.GetSupportedFileExt_Accept(this.state.UploadFileMode)}
                        onChange={this.onUploadFileChange}
                        style={{ width: '100%' }}
                        disabled={this.state.PA_Update === false}
                    />
                </>);
            case UploadState.Validation:
                return (<>
                    <span>Validating file...</span>
                    <ProgressBar animated now={100} style={{ marginTop: 7, }} />
                </>);
            case UploadState.Uploading:
                return (<>
                    <span>Uploading file...</span>
                    <ProgressBar animated now={100} style={{ marginTop: 7, }} />
                </>);
            case UploadState.Failed:
                return (<>
                    {this.state.UploadErrorMessage.map((data, key) => { return <>{data}<br /></>; })}
                    <button type='button btn-primary'
                        onClick={() => this.setState({ FileToUpload: null, FileUploadState: UploadState.Browse, UploadErrorMessage: [], })}>Retry</button>
                </>);
            case UploadState.Success:
                return (<>
                    <span>Updating data...</span>
                    <ProgressBar animated now={100} style={{ marginTop: 7, }} />
                </>);
        }
    }
    onUploadFileChange = (event) => {

        useAppService.getState().setModal('', 'uploading picture...', null, AlertMode.Loading);

        if (event === null) {
            useAppService.getState().setModal('', 'Invalid Operation.');
        }
        else {
            this.setState({
                FileToUpload: event === null ? null : event.target.files[0],
                // ToggleUploadFileUi_ViewMode: event === null ? false : !this.state.ToggleUploadFileUi_ViewMode,
            }, async () => {

                if (this.state.FileToUpload === null || this.state.FileToUpload === undefined) {
                    useAppService.getState().setModal('', 'Invalid file.');
                    return null;
                }

                //Set UploadState.
                this.SetFileModeUploadState(UploadState.Validation);

                //FileType. FileExtension.
                let _fileName = String(this.state.FileToUpload.name);
                let _fileType = this.GetFileType(_fileName.toLowerCase());
                let _fileExt = this.GetFileExt(_fileName.toLowerCase());

                //FileName.
                _fileName = String(this.state.FileToUpload.name)
                    .replace(_fileExt, '')
                    // .replace(/[$-/:-?{-~!"^_`\[\]\@\#\s]/g, '-')     //to replace all possible symbols & spaces to -.
                    .replace(/[^a-zA-Z0-9]/g, '-')
                    + '_' + RandomId();
                // let _fileName = String(this.state.FileToUpload.name)
                //     .replaceAll(/[$-/:-?{-~!"^_`\[\]\@\#\s]/g, '-')     //to replace all possible symbols & spaces to -.
                //     + '_' + this.RandomId();
                // let _fileName = this.RandomId();

                //Convert File to Base64.
                let _base64 = await ToBase64(this.state.FileToUpload);

                //Set property name for api.
                let _propertyName = '';
                if (this.state.UploadFileMode === FileMode.CertImg)
                    _propertyName = 'CertImage';
                else if (this.state.UploadFileMode === FileMode.PAJSK)
                    _propertyName = 'GovRecognitionLetter';
                else if (this.state.UploadFileMode === FileMode.CertImgTop100)  //2022.09.30
                    _propertyName = 'CertImage_Top100';
                else if (this.state.UploadFileMode === FileMode.CertImgHonor)   //2022.09.30
                    _propertyName = 'CertImage_Honor';

                //Set UploadState.
                await Delay(1000);
                this.SetFileModeUploadState(UploadState.Uploading);

                //Upload picture file.
                this.UploadFileForQuizEventSettings(_fileName, _fileExt, _fileType, _base64, _propertyName);
            });
        }
    };
    SetFileModeUploadState = (_state = UploadState.Browse) => {
        switch (this.state.UploadFileMode) {
            case FileMode.CertImg: this.setState({ UploadState_CertImage: _state, }); break;
            case FileMode.PAJSK: this.setState({ UploadState_PAJSK: _state, }); break;
            default: break;
        }
    }
    UploadFileForQuizEventSettings = async (_fileName = '', _fileExt = '', _fileType = '', _base64 = '', _propertyName = '') => {

        if (this.state.isDevMode)
            console.log('\nFileName = ' + _fileName + '\nFileExt = ' + _fileExt + '\nFileType = ' + _fileType + '\nFileSize = ' + _base64.length + '\nPropertyName = ' + _propertyName);

        let file = this.state.FileToUpload;

        let isFetchFailed = false;
        try {
            if (file !== null && file !== undefined && _fileName !== '' && _fileExt !== '' && _fileType !== '' && _base64 !== '' && _propertyName !== '') {
                let isUploadDone = false;
                let errorMessage = '';
                let apiResponse = null;

                //check file size.
                if (this.state.isDevMode)
                    console.log('file_base64 = ' + _base64.length);
                if (_base64.length <= 0) {
                    this.setState({ FileUploadState: UploadState.Failed, UploadErrorMessage: ['file size = ' + _base64.length], });
                    return null;
                }

                const target = this.state.TargetEventModal;
                const { centerUserId, authorId, authorRoleId, uid } = GetPropIds(useGlobal.getState().user);

                //modal.
                let jsonModel = {
                    CenterUserId: centerUserId,
                    AuthorId: authorId,
                    AuthorRoleId: authorRoleId,    //1 = admin, 4 = center, 11 = Author
                    FirebaseUserId: uid,

                    EventCode: CheckObjectStringEmpty(target, DataInput.EventCode),
                    PropertyName: _propertyName,
                    TimeStampId: CheckObjectNumber(target, DataInput.TimeStampId),

                    FileName: _fileName,
                    FileType: _fileType.toLowerCase(),
                    FileExt: _fileExt.toLowerCase(),
                    FileBase64: _base64,
                };
                if (this.state.isDevMode)
                    console.log(JSON.stringify(jsonModel));

                await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Event/Settings/File/Upload',
                    {
                        method: 'POST',                             // *GET, POST, PUT, DELETE, etc.
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(jsonModel), // body data type must match "Content-Type" header
                        //Api/LearningCentre/Event/Settings/File/Upload
                    })
                    .then(res => res.json())
                    .then(data => {
                        isUploadDone = data.success;
                        if (data.success)
                            apiResponse = data;
                        else
                            errorMessage = 'api - event - file upload (failed)\n' + JSON.stringify(data);
                    })
                    .catch(error => {
                        errorMessage = 'Error : ' + error.message;
                    });

                await Delay(1000);
                if (isUploadDone === false) {
                    this.setState({ FileUploadState: UploadState.Failed, UploadErrorMessage: [errorMessage] });
                }
                else {
                    //Set UploadState.
                    this.SetFileModeUploadState(UploadState.Success);

                    //Close Upload File Ui.
                    useAppService.getState().setModal();
                    this.ToggleFileUploadUi();
                }
                if (this.state.isDevMode)
                    console.log(errorMessage.length > 0 ? errorMessage : JSON.stringify(apiResponse));
                if (errorMessage.length > 0)
                    isFetchFailed = true;
            }
            else {
                isFetchFailed = true;
            }
        }
        catch (error) {
            isFetchFailed = true;
            if (this.state.isDevMode)
                console.log(error + '\n' + error.message);
        }

        if (isFetchFailed) {
            //Set UploadState.
            this.SetFileModeUploadState(UploadState.Failed);
            useAppService.getState().setModal();
        }
    }
    GetSupportedFileExt_Accept = (uploadFileMode = FileMode.None) => {
        let fileExts = '';

        let supportedFormats = [];
        if (uploadFileMode === FileMode.CertImg)
            supportedFormats = ['.jpg', '.png'];
        else if (uploadFileMode === FileMode.PAJSK)
            supportedFormats = ['.pdf', '.doc', '.docx', '.rtf'];

        supportedFormats.map((data, key) => {
            if (data.includes('.txt')) {
                fileExts += 'text/plain';
            }
            else if (data.includes('.rtf')) {
                fileExts += 'application/rtf';
            }
            else if (data.includes('.docx')) {
                fileExts += 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            }
            else if (data.includes('.doc')) {
                fileExts += 'application/msword';
            }
            else if (data.includes('.pdf')) {
                fileExts += 'application/pdf';
            }
            else if (data.includes('.jpg')) {
                fileExts += 'image/jpeg';
            }
            else if (data.includes('.png')) {
                fileExts += 'image/png';
            }
            if (key < supportedFormats.length - 1)
                fileExts += ',';
            return null;
        });
        if (fileExts.length > 0)
            return fileExts;

        return 'text/plain,application/rtf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    }
    GetSupportedFileExt_Label = (uploadFileMode = FileMode.None) => {
        let fileExts = '';

        let supportedFormats = [];
        if (uploadFileMode === FileMode.CertImg)
            supportedFormats = ['.jpg', '.png'];
        else if (uploadFileMode === FileMode.PAJSK)
            supportedFormats = ['.pdf', '.doc', '.docx', '.rtf'];

        supportedFormats.map((data, key) => {
            fileExts += data;
            if (key < supportedFormats.length - 1)
                fileExts += ', ';
            return null;
        });
        if (fileExts.length > 0)
            return fileExts;

        return '.txt, .rtf, .doc, .docx, .pdf, .jpg, .png';
    }
    GetFileExt = (fileName) => {
        if (fileName.includes('.txt')) {
            return '.txt';
        }
        else if (fileName.includes('.rtf')) {
            return '.rtf';
        }
        else if (fileName.includes('.docx')) {
            return '.docx';
        }
        else if (fileName.includes('.doc')) {
            return '.doc';
        }
        else if (fileName.includes('.pdf')) {
            return '.pdf';
        }
        else if (fileName.includes('.jpg')) {
            return '.jpg';
        }
        else if (fileName.includes('.png')) {
            return '.png';
        }
        return '';
    }
    GetFileType = (fileName) => {
        if (fileName.includes('.txt')) {
            return 'text/plain';
        }
        else if (fileName.includes('.rtf')) {
            return 'application/rtf';
        }
        else if (fileName.includes('.docx')) {
            return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        }
        else if (fileName.includes('.doc')) {
            return 'application/msword';
        }
        else if (fileName.includes('.pdf')) {
            return 'application/pdf';
        }
        else if (fileName.includes('.jpg')) {
            return 'image/jpeg';
        }
        else if (fileName.includes('.png')) {
            return 'image/png';
        }
        return '';
    }
    //#endregion === Upload File === end ===//

    //#region === remove ===
    ToggleDeleteEventModal = () => {
        if (this.state.PA_Delete === false)
            return null;
        this.setState({ DeleteEventUiModal_Toggle: !this.state.ShowDeleteEventModal });
    }
    DeleteThisEvent = async () => {
        useAppService.getState().setModal('', '<b>Delete Event feature currently not available.</b>');
    }
    //#endregion === remove ===

    //#region === bulk edit ===
    //#endregion === bulk edit ===

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        return (<div className="">
            <table className="table page-header">
                <tbody>
                    <tr>
                        <td className="left">
                            <h5>Event</h5>
                            {/* <div className="form-check">
                                <input
                                    id='formCheck_DeactivatedStudents_Toggle'
                                    className='form-check-input cursor-pointer'
                                    type='checkbox'
                                    defaultChecked={this.state.DeactivatedStudents_Toggle}
                                    readOnly={true}
                                    onClick={() => this.setState({ DeactivatedStudents_Toggle: !this.state.DeactivatedStudents_Toggle })}
                                ></input>
                                <label className='form-check-label cursor-pointer' htmlFor='formCheck_DeactivatedStudents_Toggle'
                                    style={{ color: 'gray', fontSize: 'small' }}> show deactivated students.</label>
                            </div> */}
                        </td>
                        {/* <td className="center"></td> */}
                        <td className="right">
                            {
                                // this.state.PA_Search === false ? null :
                                //     <Button
                                //         variant='outline-primary'
                                //         onClick={() => this.ResetSearchEventParams(true)}
                                //         disabled={this.state.SearchByConditionModal_Toggle}
                                //     >Search Event</Button>
                            }
                            {
                                this.state.PA_Create === false ? null :
                                    <Button
                                        variant='outline-primary'
                                        onClick={() => this.CreateNewEvent()}
                                        disabled={this.state.isLoading}
                                    >New Event</Button>
                            }
                            <Button
                                variant='outline-primary'
                                onClick={() => this.setState({ redirectLink: getMenuLink(LayoutScreen.Dashboard), redirect: true, })}
                            >Back to Dashboard</Button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='table table-hover table-bordered tbStyle' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
                <thead>
                    <tr>
                        <th width='50'>#</th>
                        <th className="left" hidden={this.state.isSuperAdmin}>Organizer</th>
                        <th className="left">Event</th>
                        {this.state.isSuperAdmin ? <th width='200'>Organizer</th> : null}
                        <th width='100'>Begin</th>
                        <th width='100'>End</th>
                        <th width='75'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.isLoading && !this.state.IsListLoaded ?
                            // <tr><td colSpan='15' align='center'><LoadingIndicator /></td></tr>
                            <tr><td colSpan='15' height={63}><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></td></tr>
                            : this.state.List.length > 0 ?
                                this.ListComponents()
                                : <tr><td colSpan='15' align='center'>list is empty</td></tr>
                    }
                    {
                        this.state.List.length === 0 ? null :
                            PagingComponents(15, this.state.TotalRows, this.state.PageIndex, this.state.PageSize, this.CallbackFunctionForPagingComponents_PageSize, this.CallbackFunctionForPagingComponents_PageIndex)
                    }
                </tbody>
            </table>

            {/* Event - Edit Details - Modal */}
            <Modal size='xl' show={this.state.EditEventUiModal_Toggle} onHide={() => this.ToggleEditEventUiModal()} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.IsCreateNewEvent ? 'New Event' : `Edit Event :: ${CheckObjectStringEmpty(this.state.TargetEventModal, 'EventName', '-')}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.EditEventUiModalComponents()}</Modal.Body>
                <Modal.Footer style={{ padding: '10px 0px', }}>
                    <Row style={{ width: '100%', margin: 0, }}>
                        <Col>
                            {
                                this.state.PA_Delete === false || this.state.TargetEventIsEnded ? null :
                                    <Button
                                        variant="secondary"
                                        onClick={this.ToggleDeleteEventModal}
                                    >Delete Event</Button>
                            }
                        </Col>
                        <Col style={{ textAlign: 'center' }}>
                            <span style={{ color: 'gray' }}>{
                                this.state.TargetEventIsEnded ? ' (current event has ended)' : ''
                            }</span>
                        </Col>
                        <Col style={{
                            textAlign: 'end',
                            display: 'flex',
                            justifyContent: 'right',
                            columnGap: 10,
                            paddingRight: 15,
                        }}>
                            <Button variant="secondary" onClick={() => this.ToggleEditEventUiModal()}>Close</Button>
                            {
                                this.state.PA_Update === false ? null :
                                    <Button
                                        variant="success"
                                        onClick={() => this.UpdateEventSettings_ViaApi()}
                                        disabled={this.state.isLoading}
                                    >Save</Button>
                            }
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>

            {/* Event - File Upload - Modal */}
            <Modal show={this.state.FileUploadUi_Toggle} onHide={() => this.ToggleFileUploadUi()} centered
            // className='alert-dialog-bordered'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Upload File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ width: '100%' }}>
                        {this.FileUploadComponentUi()}
                    </div>
                </Modal.Body>
                <Modal.Footer id='editEventRoomUiBottom'>
                    <Button variant="secondary" onClick={() => this.ToggleFileUploadUi()}>Close</Button>
                </Modal.Footer>
            </Modal>

            {/* Event - Edit Room - Modal */}
            <Modal size='lg' show={this.state.ShowEditEventRoomModal} onHide={() => this.ToggleEditEventRoomModal()} centered
                className='alert-dialog-bordered'>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Event Room</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ width: '100%' }}>
                        {this.EditEventRoomComponents()}
                    </div>
                </Modal.Body>
                <Modal.Footer id='editEventRoomUiBottom'>
                    <Button variant="secondary"
                        style={{ width: 100 }}
                        onClick={() => this.ToggleEditEventRoomModal()}
                    >Close</Button>
                    {/* {
                        this.state.EditEventRoomState === 0 ?
                            <Button variant="secondary"
                                style={{ width: 100 }}
                                onClick={() => this.ToggleEditEventRoomModal()}
                            >Close</Button>
                            : null
                    } */}
                    {/* {
                        this.state.EditEventRoomState === 1 ?
                            <Button variant="secondary"
                                style={{ width: 100 }}
                                onClick={() => this.ResetEditRoomState()}
                            >Back</Button>
                            : null
                    } */}
                    {/* {
                        this.state.IsEventRoomStateDirty ?
                            <>
                                &nbsp;&nbsp;
                                <Button variant="primary"
                                    style={{ width: 100 }}
                                    onClick={() => this.UpdateEventSettings_ViaApi()}
                                >Save</Button>
                            </>
                            : null
                    } */}
                </Modal.Footer>
            </Modal>



            {/* Event - Edit Group - Modal */}
            <Modal show={this.state.ShowEditEventGroupModal} onHide={() => this.ToggleEditEventGroupModal()} centered
                // className='alert-dialog-bordered'
                size={'sm'}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Group Selection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ width: '100%' }}>
                        <table className='table tbStyle' width={'100%'}>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Group Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.GetTargetEvent_EditGroups_TableRows()}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer id='editEventRoomUiBottom'>
                    <Button variant="secondary" onClick={() => this.ToggleEditEventGroupModal()}>Close</Button>
                </Modal.Footer>
            </Modal>

            {/* Event - Delete - Modal */}
            <Modal show={this.state.DeleteEventUiModal_Toggle} onHide={() => this.ToggleDeleteEventModal()} centered
                className='alert-dialog-bordered'>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Event</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        Event: <b>{this.state.TargetEventModal === null ? '' : this.state.TargetEventModal['EventName']}</b>
                        <br />Organizer: <b>{this.state.TargetEventModal === null ? '' : this.state.TargetEventModal['OrganizerDisplayName']}</b>
                        <p />Are you sure you want to <b>delete</b> this event ?
                        <br /><b><i>The deletion is not reversible.</i></b>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ToggleDeleteEventModal()}>Cancel</Button>
                    &nbsp;&nbsp;
                    <Button variant="primary" onClick={() => this.DeleteThisEvent()}>Confirm</Button>
                </Modal.Footer>
            </Modal>

        </div>);
    }
}