import React from "react";
// import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { Button, ButtonGroup, Dropdown, DropdownButton, Modal, ProgressBar } from "react-bootstrap";
import moment from "moment";
// import Select from "react-select";

import { GlobalSetting } from "../utilities/GlobalSetting";
import { CheckBoolean, CheckNumber, CheckObjectBoolean, CheckObjectNullValue, CheckObjectNumber, CheckObjectStringEmpty, CheckStringEmpty, DecapitalizeJsonKeys, Delay, DelayUntil, GetPostParams, GetPropIds, TriggerDownloadFile } from "../utilities/GlobalFunctions";
import { useGlobal } from "../utilities/GlobalVariables";
import { useAppService } from "../services/AppService";
import { AlertMode } from "./AlertComponent";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
// import { Locale } from "../../utilities/localization/CustomLocalization";

const SettingInput = {
    OrganizerId: 'organizerId',
    AuthorId: 'authorId',

    Id: 'id',
    SubjectId: 'subjectId',
    GroupId: 'groupId',
    Name: 'name',
    Remark: 'remark',
    EmbedType: 'embedType',
    EmbedCodes: 'embedCodes',
    AlwaysOnTop: 'alwaysOnTop',
    DisplayOrder: 'displayOrder',
    Active: 'active',

    CheckedItem: 'checkedItem',
    None: 'none',
};

const BulkSetting = {
    Subject: 'subject',
    Group: 'group',
    Name: 'name',
    Remark: 'remark',
    EmbedType: 'embedType',
    AlwaysOnTop: 'alwaysOnTop',
    DisplayOrder: 'displayOrder',
    Active: 'active',
};

const embedTypeOptions = [
    { id: 0, value: '', label: '(Select)' },
    { id: 1, value: 'IFrame', label: 'IFrame' },
    { id: 2, value: 'Thumbnail', label: 'Thumbnail' },
    { id: 3, value: 'Small-Icon', label: 'Small Icon' },
];

const settingTitle = 'Wordwall';
const yearStart = moment(moment.utc().format('YYYY-01-01T16:00')).add(-1, 'day').format('YYYY-MM-DDTHH:mm');
const yearEnd = moment(yearStart, 'YYYY-MM-DDTHH:mm').add(1, 'year').add(-1, 'minute').format('YYYY-MM-DDTHH:mm');

//2025.02.21
export default class ManageWordwallScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({

        isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        redirect: false,
        redirectLink: '/',
        isLoading: false,
        PA_View: false,
        PA_Search: false,
        PA_Create: false,
        PA_Update: false,
        PA_Delete: false,
        PA_Upload: false,
        PA_Download: false,

        List: [],
        IsListLoaded: false,
        TotalRows: 0,
        PageIndex: 0,
        PageSize: 10,
        // OrderBy: 'Name',
        // OrderType: 'ASC',
        OrganizerId: 0,
        IsSuperAdmin: false,
        IsMasterAdmin: false,
        SubjectOptions: [],
        GroupOptions: [],
        SearchBySubjectId: 0,
        SearchByGroupId: 0,
        SearchByIsActive: null,

        Toggle_EditSettingModal: false,
        SettingModal_Create: false,
        SettingModal_Index: -1,
        SettingModal: null,
        SettingModal_Cache: null,
        isUpdating: false,

        Toggle_RemoveSettingModal: false,

        //2024.10.21
        BulkEdit_Setting: Object.keys(BulkSetting).map((data, key) => { return { key: data, value: null }; }),
        BulkEdit_Setting_checked: Object.keys(BulkSetting).map(() => { return false; }),
        BulkEdit_Toggle_EditSettingModal: false,
        BulkEdit_Toggle_RemoveSettingModal: false,
        BulkEdit_CheckedItems: [],

        //2025.03.06
        PreviewFileModal_Toggle: false,
        PreviewFileModal_Component: null,
    });

    componentWillUnmount = () => { }

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        // await useAppService.getState().getGroups();
        await Delay(0);
        this.LoadList_ViaApi();
        if (typeof useGlobal.getState().setRefreshListCallbackFn === 'function')
            useGlobal.getState().setRefreshListCallbackFn(this.LoadList_ViaApi);
    }

    //#region === list ===
    CheckPermissions = async () => {
        const user = useGlobal.getState().user;
        if (user === null || user === undefined) {
            this.setState({
                redirect: true,
                redirectLink: '/',
            });
        }
        const { organizerId } = GetPropIds(user);
        const isSuperAdmin = CheckBoolean(useGlobal.getState().isSuperAdmin);
        const isMasterAdmin = CheckBoolean(useGlobal.getState().isMasterAdmin)
        this.setState({
            // PA_View: PermissionAccess(LayoutScreen.ManageWordwall, PermissionAccessType.View, isSuperAdmin || isMasterAdmin),
            // PA_Search: PermissionAccess(LayoutScreen.ManageWordwall, PermissionAccessType.Search, isSuperAdmin || isMasterAdmin),
            // PA_Create: PermissionAccess(LayoutScreen.ManageWordwall, PermissionAccessType.Create, isSuperAdmin || isMasterAdmin),
            // PA_Update: PermissionAccess(LayoutScreen.ManageWordwall, PermissionAccessType.Update, isSuperAdmin || isMasterAdmin),
            // PA_Delete: PermissionAccess(LayoutScreen.ManageWordwall, PermissionAccessType.Delete, isSuperAdmin || isMasterAdmin),
            // PA_Upload: PermissionAccess(LayoutScreen.ManageWordwall, PermissionAccessType.Upload, isSuperAdmin || isMasterAdmin),
            // PA_Download: PermissionAccess(LayoutScreen.ManageWordwall, PermissionAccessType.Download, isSuperAdmin || isMasterAdmin),

            PA_View: true,
            PA_Search: true,
            PA_Create: true,
            PA_Update: true,
            PA_Delete: true,
            PA_Upload: true,
            PA_Download: true,

            PageSize: 999,

            OrganizerId: organizerId,
            IsSuperAdmin: isSuperAdmin,
            IsMasterAdmin: isMasterAdmin,
        });
        await Delay(0);
    }
    LoadList_ViaApi = async () => {

        await this.CheckPermissions();

        // if (this.state.PA_View === false)
        //     return null;

        this.setState({
            isLoading: true,
            IsListLoaded: false,
        });
        window.scrollTo(0, 0);

        // const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Organizer/Wordwall/SubjectAndGroup/List`;
        //  Api/LearningCentre/Organizer/Wordwall/SubjectAndGroup/List

        // let totalRows = 0;
        // let _List = [];
        let subjectOptions = [];
        let groupOptions = [];

        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (data.success && data.data !== undefined) {
                    if (Array.isArray(data.data.subjects)) {
                        const t_subjects = DecapitalizeJsonKeys(JSON.parse(JSON.stringify(data.data.subjects)));
                        t_subjects.forEach(e => {
                            subjectOptions.push({ id: e.id, value: e.id, label: e.name });
                        });
                    }
                    if (Array.isArray(data.data.groups)) {
                        const t_groups = DecapitalizeJsonKeys(JSON.parse(JSON.stringify(data.data.groups)));
                        t_groups.forEach(e => {
                            groupOptions.push({ id: e.id, value: e.id, label: e.name });
                        });
                    }
                }
                else {
                    if (this.state.isDevMode)
                        console.log('Error', 'api - wordwall (subject & group) - load list (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                if (this.state.isDevMode)
                    console.log('Error', 'api - wordwall (subject & group) - load list (error)\n' + error.message);
            });

        this.setState({
            isLoading: false,
            SubjectOptions: subjectOptions,
            GroupOptions: groupOptions,
            IsListLoaded: true,
        }, () => {
            if (this.state.isDevMode) {
                console.log('SubjectOptions \n', JSON.stringify(subjectOptions));
                console.log('GroupOptions \n', JSON.stringify(groupOptions));
            }
        });
    }
    ListTitle = () => {
        let title = null;
        const arrowIcon = <div><i className="fa fa-angle-right"></i><i className="fa fa-angle-right"></i></div>;
        // const titleButton = <button className="btn-link" onClick={() => this.setState({ SearchBySubjectId: 0, SearchByGroupId: 0, List: [] })}>Wordwall</button>;
        const titleName = <span className="start">Wordwall</span>;
        if (this.state.SearchBySubjectId === 0 && this.state.SearchByGroupId === 0) {
            //nothing is selected.
            title = <>{titleName}{arrowIcon}<span>Select Subject</span></>;
        }
        else if (this.state.SearchBySubjectId > 0 && this.state.SearchByGroupId === 0) {
            //selected subject, not group.
            const subjectLabel = this.state.SubjectOptions.find(x => x.id === this.state.SearchBySubjectId).label;
            const titleButton_Subject = <button className="btn-link" title="Back to Select Subject" onClick={() => this.setState({ SearchBySubjectId: 0, SearchByGroupId: 0, List: [] })}>{subjectLabel}</button>;
            title = <>{titleName}{arrowIcon}{titleButton_Subject}{arrowIcon}<span>Select Group</span></>;
        }
        else if (this.state.SearchBySubjectId > 0 && this.state.SearchByGroupId > 0) {
            //selected subject & group.
            const subjectLabel = this.state.SubjectOptions.find(x => x.id === this.state.SearchBySubjectId).label;
            const titleButton_Subject = <button className="btn-link" title="Back to Select Subject" onClick={() => this.setState({ SearchBySubjectId: 0, SearchByGroupId: 0, List: [] })}>{subjectLabel}</button>;
            const groupLabel = this.state.GroupOptions.find(x => x.id === this.state.SearchByGroupId).label;
            const titleButton_Group = <button className="btn-link" title="Back to Select Group" onClick={() => this.setState({ SearchByGroupId: 0, List: [] })}>{groupLabel}</button>;
            title = <>{titleName}{arrowIcon}{titleButton_Subject}{arrowIcon}{titleButton_Group}</>;
        }
        return <div className="settingTitle">{title}</div>;
    }
    ListComponents = () => {
        let components = [];
        let tbodies = [];

        // if (this.state.IsListLoaded === false)
        //     return null;
        // if (this.state.IsListLoaded === false)
        //     return <ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} />;

        //nothing is selected.
        if (this.state.SearchBySubjectId === 0 && this.state.SearchByGroupId === 0) {
            this.state.SubjectOptions.forEach((data, key) => {
                tbodies.push(<tr>
                    <td>{key + 1}</td>
                    <td className="left">{CheckObjectStringEmpty(data, 'label', '-')}</td>
                    <td><Button variant="primary" className="mw65" onClick={() => this.setState({ SearchBySubjectId: CheckObjectNumber(data, 'id') })}>Select</Button></td>
                </tr>)
            });
            components.push(<thead><tr><th width='50'>#</th><th className="left">Group</th><th width='75'>Action</th></tr></thead>);
            components.push(<tbody>{tbodies}</tbody>);
        }
        //selected subject, not group.
        else if (this.state.SearchBySubjectId > 0 && this.state.SearchByGroupId === 0) {
            this.state.GroupOptions.forEach((data, key) => {
                tbodies.push(<tr>
                    <td>{key + 1}</td>
                    <td className="left">{CheckObjectStringEmpty(data, 'label', '-')}</td>
                    <td><Button variant="primary" className="mw65" onClick={() => this.setState({ SearchByGroupId: CheckObjectNumber(data, 'id') }, () => this.LoadWordwallList_ViaApi())}>Select</Button></td>
                </tr>)
            });
            components.push(<thead><tr><th width='50'>#</th><th className="left">Subject</th><th width='75'>Action</th></tr></thead>);
            components.push(<tbody>{tbodies}</tbody>);
        }
        //selected subject & group.
        else if (this.state.SearchBySubjectId > 0 && this.state.SearchByGroupId > 0) {
            //thead.
            components.push(<thead><tr>
                <th width='50'>#</th>
                <th className="left" colSpan={2}>Name</th>
                <th width='125'>Embed Type</th>
                <th width='125'>Embed Codes</th>
                <th width='125'>Remark</th>
                <th width='125'>Always On Top</th>
                <th width='115'>Display Order</th>
                <th width='115'>Active</th>
                <th width='75'>Action</th>
            </tr></thead>);
            //tbody.
            if (this.state.isLoading && this.state.IsListLoaded === false) {
                //loading.
                tbodies.push(<tr><td colSpan='15' height={63}><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></td></tr>);
            }
            else if (this.state.isLoading === false && this.state.IsListLoaded) {
                if (this.state.List.length === 0) {
                    //empty list.
                    tbodies.push(<tr><td colSpan='15' align='center'>list is empty</td></tr>);
                }
                else {
                    //Wordwall list.
                    this.state.List.forEach((item, key) => {
                        tbodies.push(<tr>
                            <td>{this.state.PageIndex + 1}</td>
                            <td className="left">{CheckObjectNullValue(item, SettingInput.Name)}</td>
                            <td width={75}>{
                                CheckObjectNullValue(item, SettingInput.EmbedCodes) === null ? '-' :
                                    <button type="button" className="btn btn-info"
                                        onClick={() => useAppService.getState().setModal(`Preview :: ${CheckObjectNullValue(item, SettingInput.Name, '-')}`, CheckObjectStringEmpty(item, SettingInput.EmbedCodes, '-').replace('margin-bottom:-7px;', ''))}
                                    >Preview</button>
                            }</td>
                            {/* <td>{CheckObjectStringEmpty(item, SettingInput.EmbedType, '-')}</td> */}
                            <td>{embedTypeOptions.find(x => x.value === CheckObjectStringEmpty(item, SettingInput.EmbedType))?.label}</td>
                            <td>{
                                CheckObjectNullValue(item, SettingInput.EmbedCodes) === null ? '-' :
                                    <button type="button" className="btn btn-info"
                                        onClick={() => useAppService.getState().setModal(`Embed Codes :: ${CheckObjectNullValue(item, SettingInput.Name, '-')}`, <div style={{ wordWrap: 'break-word' }}>{CheckObjectStringEmpty(item, SettingInput.EmbedCodes, '-')}</div>)}
                                    >View</button>
                            }</td>
                            <td>{
                                CheckObjectNullValue(item, SettingInput.Remark) === null ? '-' :
                                    <button type="button" className="btn btn-info"
                                        onClick={() => useAppService.getState().setModal(`Remark :: ${CheckObjectNullValue(item, SettingInput.Name, '-')}`, CheckObjectStringEmpty(item, SettingInput.Remark, '-'))}
                                    >View</button>
                            }</td>
                            <td>{CheckObjectBoolean(item, SettingInput.AlwaysOnTop) ? '✔' : '❌'}</td>
                            <td>{CheckObjectNumber(item, SettingInput.DisplayOrder)}</td>
                            <td>{CheckObjectBoolean(item, SettingInput.Active) ? '✔' : '❌'}</td>
                            <td><button type='button' className='btn btn-primary mw65' onClick={() => this.ToggleEditSettingModal(key)}>Edit</button></td>
                        </tr>);
                    });
                }
            }
            components.push(<tbody>{tbodies}</tbody>);
        }
        return (components);
    }
    BackOneLayerButtonComponent = () => {
        //nothing is selected.
        if (this.state.SearchBySubjectId === 0 && this.state.SearchByGroupId === 0) {
            return null;
        }
        //selected subject, not group.
        else if (this.state.SearchBySubjectId > 0 && this.state.SearchByGroupId === 0) {
            // const subjectLabel = this.state.SubjectOptions.find(x => x.id === this.state.SearchBySubjectId).label;
            return <Button variant="secondary" onClick={() => this.setState({ SearchBySubjectId: 0, SearchByGroupId: 0, List: [] })}>Back to Select Subject</Button>;
        }
        //selected subject & group.
        else if (this.state.SearchBySubjectId > 0 && this.state.SearchByGroupId > 0) {
            // const groupLabel = this.state.GroupOptions.find(x => x.id === this.state.SearchByGroupId).label;
            return <Button variant="secondary" onClick={() => this.setState({ SearchByGroupId: 0, List: [] })}>Back to Select Group</Button>;
        }
        return null;
    }
    LoadWordwallList_ViaApi = async () => {

        this.setState({
            isLoading: true,
            List: [],
            TotalRows: 0,
            IsListLoaded: false,
        });
        window.scrollTo(0, 0);

        // const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Portal/Organizer/Wordwall/List`;
        //  Api/LearningCentre/Portal/Organizer/Wordwall/List

        const postData = JSON.stringify({
            // OrganizerId: this.state.IsSuperAdmin || this.state.IsMasterAdmin ? 0 : organizerId,
            // AuthorId: this.state.IsSuperAdmin || this.state.IsMasterAdmin ? 0 : authorId,
            OrganizerId: 0,
            AuthorId: 0,
            SubjectId: this.state.SearchBySubjectId,
            GroupId: this.state.SearchByGroupId,
            IsActive: null,
        });
        if (this.state.isDevMode)
            console.log('LoadWordwallList_ViaApi (postData) \n' + postData);

        let done = false;
        let totalRows = 0;
        let _List = [];

        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: postData,
            })
            .then(res => res.json())
            .then(data => {
                console.log('LoadWordwallList_ViaApi (response)\n' + JSON.stringify(data));
                if (data.success) {
                    const result = JSON.parse(JSON.stringify(data));
                    if (result.data !== undefined && Array.isArray(result.data) && result.data.length > 0) {
                        result.data.forEach((item, key) => {
                            _List.push(this.PopulateSettingModal(item));
                        });
                        // _List = JSON.parse(JSON.stringify(data.data));
                        // _List.sort((a, b) => a.displayOrder - b.displayOrder);
                        totalRows = _List.length;
                    }
                    else {
                        if (this.state.isDevMode)
                            console.log(`${settingTitle} list is empty.`);
                    }
                }
                else {
                    if (this.state.isDevMode)
                        console.log('Error', 'api - wordwall - load list (failed)\n' + JSON.stringify(data));
                }
                done = true;
            })
            .catch(error => {
                done = true;
                if (this.state.isDevMode)
                    console.log('Error', 'api - wordwall - load list (error)\n' + error.message);
            });
        await DelayUntil(() => done === true);
        // console.log('LoadWordwallList_ViaApi (_List)\n' + JSON.stringify(_List));

        this.setState({
            isLoading: false,
            List: _List,
            TotalRows: totalRows,
            IsListLoaded: true,
            // BulkEdit_CheckedItems: Array.isArray(_List) ? _List.map((data, key) => { return false; }) : [],
        }, () => {
            if (this.state.isDevMode) {
                console.log('TotalRows', totalRows);
                console.log('List', JSON.stringify(_List));
            }
        });
    }
    TogglePreviewFileModal = async (data = null) => {
        const toggle = data === null ? false : !this.state.PreviewFileModal_Toggle;
        this.setState({
            PreviewFileModal_Toggle: toggle,
        });
        if (toggle === false)
            await Delay(200);

        let component = null;
        if (data !== null) {
            const mediaType = data[SettingInput.MediaType];
            switch (mediaType) {
                case embedTypeOptions[1].value:
                    const symbolRegex = /[\s.,#!$%^&*;:{}=\-_`~()]/g;
                    const fileName = CheckStringEmpty(data[SettingInput.FileName], data[SettingInput.Name].replace(symbolRegex, ''));
                    const fileExt = CheckStringEmpty(data[SettingInput.FileExt], '.pdf');
                    component = <button type="button" className="btn btn-primary"
                        onClick={() => TriggerDownloadFile(data[SettingInput.FileLocation], fileName, fileExt, this.state.locale, '_blank')}
                        // disabled={!this.state.PA_Download}
                        title="Download Current Report"
                    >{fileName + fileExt}</button>;
                    break;
                case embedTypeOptions[2].value:
                    //youtube.
                    component = <iframe
                        width="640" height="360"
                        src={data[SettingInput.FileLocation]}
                        title={data[SettingInput.Name]}
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                    // allowfullscreen
                    ></iframe>;
                    break;
                case embedTypeOptions[3].value:
                    //vimeo.
                    component = <iframe
                        src={data[SettingInput.FileLocation]}
                        title={data[SettingInput.Name]}
                        width="640"
                        height="360"
                        frameborder="0"
                    // allow="autoplay; fullscreen"
                    // allowfullscreen
                    ></iframe>
                    break;
                default: break;
            }
        }
        this.setState({
            PreviewFileModal_Component: component,
        });
    }
    //#endregion

    //#region === edit / new ===
    ToggleEditSettingModal = async (index = -1, create = false) => {
        // useAppService.getState().setModal('', 'coming soon...');
        if (create) {
            if (this.state.PA_Create === false) {
                useAppService.getState().setModal(`New ${settingTitle}`, 'Invalid permission.');
                return null;
            }
            else {
                const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
                let defaultModal = this.PopulateSettingModal(null, organizerId, authorId);
                defaultModal['subjectId'] = this.state.SearchBySubjectId;
                defaultModal['groupId'] = this.state.SearchByGroupId;
                this.setState({
                    // Toggle_EditSettingModal: true,
                    SettingModal_Create: true,
                    SettingModal_Index: -1,
                    SettingModal: defaultModal,
                    SettingModal_Cache: JSON.parse(JSON.stringify(defaultModal)),
                    isUpdating: false,
                });
                await Delay(200);
                this.setState({
                    Toggle_EditSettingModal: true,
                });
                return null;
            }
        }
        // if (this.state.PA_Update === false) {
        //     useAppService.getState().setModal(`Edit ${settingTitle}`, 'Invalid permission.');
        //     return null;
        // }
        if (index < 0) {
            this.setState({ Toggle_EditSettingModal: false });
            await Delay(200);
        }
        this.setState({
            SettingModal_Create: false,
            SettingModal_Index: index,
            SettingModal: index < 0 ? null : this.PopulateSettingModal(this.state.List[index]),
            SettingModal_Cache: index < 0 ? null : this.PopulateSettingModal(this.state.List[index]),
            isUpdating: false,
        });
        if (index >= 0) {
            await Delay(200);
            this.setState({ Toggle_EditSettingModal: true });
        }
        if (this.state.isDevMode)
            console.log(`ToggleEditSettingModal (${index}) (${create}) =\n ${JSON.stringify(this.state.SettingModal)}`);
    }
    SettingModalComponent = () => {
        let components = [];
        const settingDetails = this.state.SettingModal;
        if (settingDetails !== null) {

            // const { organizerId } = GetPropIds(useGlobal.getState().user);
            // const readOnly = this.state.IsSuperAdmin || this.state.IsMasterAdmin || (organizerId === CheckObjectNumber(settingDetails, SettingInput.OrganizerId) && this.state.PA_Update) ? false : true;
            const readOnly = false;

            //Name.
            components.push(<div className="form-group" key='s-e-name'>
                <label>Name</label>
                <input type="text" className="form-control" style={{ width: '100%' }}
                    onChange={(e) => { if (!readOnly) this.SetSettingValue(SettingInput.Name, e.target.value); }}
                    value={settingDetails[SettingInput.Name]}
                    disabled={readOnly}
                ></input>
            </div>);

            //Remark.
            components.push(<div className="form-group" key='s-e-remark'>
                <label>Remark</label>
                <input type="text" className="form-control" style={{ width: '100%' }}
                    onChange={(e) => { if (!readOnly) this.SetSettingValue(SettingInput.Remark, e.target.value); }}
                    value={settingDetails[SettingInput.Remark]}
                    disabled={readOnly}
                ></input>
            </div>);

            //Embed Type
            const findIndex_mediaType = Array.isArray(embedTypeOptions) && embedTypeOptions.length > 0 ? embedTypeOptions.findIndex(x => String(x.value) === CheckObjectStringEmpty(settingDetails, SettingInput.EmbedType)) : -1;
            components.push(<div className="form-group" key='s-e-embed-type' style={{ display: 'flex', flexDirection: 'column' }}>
                <label>Embed Type</label>
                <DropdownButton
                    style={{ width: 160 }}
                    as={ButtonGroup}
                    title={findIndex_mediaType < 0 ? (embedTypeOptions.length > 0 ? embedTypeOptions[0].label : '') : embedTypeOptions[findIndex_mediaType].label}
                    onSelect={(eventKey) => { if (!readOnly) this.SetSettingValue(SettingInput.EmbedType, embedTypeOptions[eventKey].value); }}
                    disabled={true}
                >{
                        Array.isArray(embedTypeOptions) && embedTypeOptions.length > 0 ?
                            embedTypeOptions.map((option, key) => {
                                return <Dropdown.Item eventKey={option.id}>{option.label}</Dropdown.Item>;
                            })
                            : null
                    }</DropdownButton>
            </div>);

            //Embed Codes.
            components.push(<div className="form-group" key='s-e-embed-codes'>
                <label>Embed Codes</label>
                {/* <input type="text" className="form-control" style={{ width: '100%' }}
                    onChange={(e) => { if (!readOnly) this.SetSettingValue(SettingInput.FileLocation, e.target.value); }}
                    value={settingDetails[SettingInput.FileLocation]}
                    disabled={readOnly}
                ></input> */}
                <textarea name="EmbedCodes" class="form-control" type="text"
                    // disabled={true}
                    placeholder="(...paste codes here)"
                    value={settingDetails[SettingInput.EmbedCodes]}
                    rows="5" wrap="soft"
                    style={{ display: 'block', width: '100%', resize: 'vertical' }}
                    // style={{ minHeight: 'fit-content', resize: 'none', height: 'fit-content', overflowY: 'hidden' }}
                    onChange={(e) => this.SetSettingValue(SettingInput.EmbedCodes, e.target.value)}
                    onClick={(e) => {
                        const settingModal = this.state.SettingModal;
                        const string_value = CheckStringEmpty(e.target.value);
                        //iframe.
                        if (string_value.includes('<iframe') && CheckObjectStringEmpty(settingModal, SettingInput.EmbedType) === embedTypeOptions[1].value) { }
                        //thumbnail
                        else if (string_value.includes('embed-image') && CheckObjectStringEmpty(settingModal, SettingInput.EmbedType) === embedTypeOptions[2].value) { }
                        //small icon
                        else if (string_value.includes('embed-icon') && CheckObjectStringEmpty(settingModal, SettingInput.EmbedType) === embedTypeOptions[3].value) { }
                        else { this.SetSettingValue(SettingInput.EmbedCodes, e.target.value); }
                    }}
                ></textarea>
            </div>);

            //AlwaysOnTop.
            components.push(<div className="form-group" key='s-e-alwaysOnTop'>
                <label>Always On Top</label>
                <input type="checkbox" className="form-check form-check-input"
                    onClick={(e) => { if (!readOnly) this.SetSettingValue(SettingInput.AlwaysOnTop, e.currentTarget.checked); }}
                    checked={CheckObjectBoolean(settingDetails, SettingInput.AlwaysOnTop)}
                    readOnly={true}
                    disabled={this.state.PA_Update === false}
                ></input>
            </div>);

            //DisplayOrder.
            components.push(<div className="form-group" key='s-e-display-order'>
                <label>DisplayOrder</label>
                <input type="number" className="form-control" style={{ width: '100%' }}
                    onChange={(e) => { if (!readOnly) this.SetSettingValue(SettingInput.DisplayOrder, e.target.value); }}
                    value={CheckObjectNumber(settingDetails, SettingInput.DisplayOrder)}
                    disabled={readOnly}
                ></input>
            </div>);

            //Active.
            components.push(<div className="form-group" key='s-e-active'>
                <label>Active</label>
                <input type="checkbox" className="form-check form-check-input"
                    onClick={(e) => { if (!readOnly) this.SetSettingValue(SettingInput.Active, e.currentTarget.checked); }}
                    checked={CheckObjectBoolean(settingDetails, SettingInput.Active)}
                    readOnly={true}
                    disabled={readOnly}
                ></input>
            </div>);
        }
        return (components);
    }
    // ValidateSettingInputs = () => {
    //     let setting = this.state.SettingModal;
    //     if (setting === null || setting === undefined)
    //         return { success: false, message: 'invalid setting modal.' };
    //     let msg = [];
    //     if (CheckObjectStringEmpty(setting, SettingInput.Name) === '') {
    //         msg.push('Please enter the <b>Name</b> of current wordwall item.');
    //     }
    //     const embedCodes = CheckObjectStringEmpty(setting, SettingInput.EmbedCodes);
    //     if (embedCodes === '') {
    //         msg.push('Please copy & paste embed codes of type <b>iFrame</b>.');
    //     }
    //     if (embedCodes.includes('<iframe') === false) {
    //         msg.push('Wrong type of embed codes are entered, please copy & paste embed codes of type <b>iFrame</b> only.');
    //         setting[SettingInput.EmbedCodes] = '';
    //         setting[SettingInput.EmbedType] = '';
    //     }
    //     this.setState({ SettingModal: setting });
    //     // useAppService.getState().setModal('Validation Failed', <ul>{msg.map((item) => { return <li><div dangerouslySetInnerHTML={{ __html: item }} /></li>; })}</ul>);
    //     return { success: msg.length === 0, message: <ul>{msg.map((item) => { return <li><div dangerouslySetInnerHTML={{ __html: item }} /></li>; })}</ul> };
    // }
    SetSettingValue = (property = SettingInput.None, value = null) => {
        let setting = this.state.SettingModal;
        if (setting === null || value === null)
            return null;
        switch (property) {
            case SettingInput.Name: setting[property] = String(value); break;
            case SettingInput.Remark: setting[property] = String(value); break;
            case SettingInput.AlwaysOnTop: setting[property] = CheckBoolean(value); break;
            case SettingInput.DisplayOrder: setting[property] = CheckNumber(value); break;
            case SettingInput.Active: setting[property] = CheckBoolean(value); break;
            case SettingInput.EmbedType: setting[property] = CheckStringEmpty(value); break;
            case SettingInput.EmbedCodes:
                const string_value = String(value);
                setting[property] = string_value;
                if (string_value.includes('<iframe'))
                    setting[SettingInput.EmbedType] = embedTypeOptions[1].value;        //iframe
                else if (string_value.includes('embed-image'))
                    setting[SettingInput.EmbedType] = embedTypeOptions[2].value;        //thumbnail
                else if (string_value.includes('embed-icon'))
                    setting[SettingInput.EmbedType] = embedTypeOptions[3].value;        //small icon
                break;
            default: break;
        }
        this.setState({
            SettingModal: setting,
        }, () => {
            if (this.state.isDevMode)
                console.log(`SetSettingValue (${property})`, JSON.stringify(setting));
        });
    }
    ResetSetting = () => {
        this.setState({
            SettingModal: JSON.parse(JSON.stringify(this.state.SettingModal_Cache)),
        });
    }
    PopulateSettingModal = (modal = null, organizerId = 0, authorId = 0) => {
        return {
            organizerId: CheckObjectNumber(modal, SettingInput.OrganizerId, organizerId),
            authorId: CheckObjectNumber(modal, SettingInput.AuthorId, authorId),

            id: CheckObjectNumber(modal, SettingInput.Id),
            subjectId: CheckObjectNumber(modal, SettingInput.SubjectId),
            groupId: CheckObjectNumber(modal, SettingInput.GroupId),

            name: CheckObjectStringEmpty(modal, SettingInput.Name),
            remark: CheckObjectStringEmpty(modal, SettingInput.Remark),

            embedType: CheckObjectStringEmpty(modal, SettingInput.EmbedType),
            embedCodes: CheckObjectStringEmpty(modal, SettingInput.EmbedCodes),

            alwaysOnTop: CheckObjectBoolean(modal, SettingInput.AlwaysOnTop),
            displayOrder: CheckObjectNumber(modal, SettingInput.DisplayOrder),
            active: CheckObjectBoolean(modal, SettingInput.Active),

            checkedItem: false,
        };
    }
    SettingModalValidation = (postData = null) => {
        if (postData === null)
            return { success: false, message: 'invalid modal.' };

        let messages = [];

        //Name.
        const string_name = CheckObjectStringEmpty(postData, SettingInput.Name);
        if (string_name === '')
            messages.push('Please enter the <b>Name</b> of current wordwall item.');

        //EmbedCodes.
        const string_embedCodes = CheckObjectStringEmpty(postData, SettingInput.EmbedCodes);
        if (string_embedCodes === '') {
            messages.push('Please copy & paste embed codes of type <b>iFrame</b>.');
        }
        else if (string_embedCodes.includes('<iframe') === false) {
            messages.push('Wrong type of embed codes are entered, please copy & paste embed codes of type <b>iFrame</b> only.');
        }
        else {
            //iframe.
            if (string_embedCodes.includes('<iframe') && CheckObjectStringEmpty(postData, SettingInput.EmbedType) === embedTypeOptions[1].value) { }
            //thumbnail
            else if (string_embedCodes.includes('embed-image') && CheckObjectStringEmpty(postData, SettingInput.EmbedType) === embedTypeOptions[2].value) { }
            //small icon
            else if (string_embedCodes.includes('embed-icon') && CheckObjectStringEmpty(postData, SettingInput.EmbedType) === embedTypeOptions[3].value) { }
            else { messages.push('Unmatched Embed-Type & Embed-Codes.'); }
        }

        //result.
        return { success: messages.length === 0, message: <ul>{messages.map((item) => { return <li><div dangerouslySetInnerHTML={{ __html: item }} /></li>; })}</ul> };
    }
    CUD_Setting_ViaApi = async (remove = false) => {

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const postData = this.PopulateSettingModal(this.state.SettingModal);

        const { success: validation_success, message: validation_message } = this.SettingModalValidation(postData);
        if (validation_success === false) {
            useAppService.getState().setModal('Validation Failed', validation_message);
            this.setState({
                isUpdating: false,
            });
            return null;
        }

        const { textTitle, textBody, text, urlParam } = GetPostParams(postData, remove);
        this.setState({
            isUpdating: true,
        });
        useAppService.getState().setModal('', `${textTitle} setting...`, null, AlertMode.Loading);

        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Portal/Organizer/Wordwall/${urlParam}`;
        // Api/LearningCentre/Portal/Organizer/Wordwall/{Create|Update|Delete}

        const {
            id, name, remark, embedType, embedCodes, alwaysOnTop, displayOrder, active, subjectId, groupId
        } = postData;
        const json = JSON.stringify({
            OrganizerId: organizerId,
            AuthorId: authorId,

            Id: CheckNumber(id),
            Name: CheckStringEmpty(name),
            Remark: CheckStringEmpty(remark),

            AlwaysOnTop: CheckBoolean(alwaysOnTop),
            DisplayOrder: CheckNumber(displayOrder),
            Active: CheckBoolean(active),

            EmbedType: CheckStringEmpty(embedType),
            EmbedCodes: CheckStringEmpty(embedCodes),

            SubjectId: CheckNumber(subjectId),
            GroupId: CheckNumber(groupId),

            Remove: remove,
        });
        if (this.state.isDevMode)
            console.log(`CUD_Setting_ViaApi (${text}) (postData) =\n` + json);

        let data = null;
        let success = false;
        let msg = '';
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: json,
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('CUD_Setting_ViaApi =\n' + JSON.stringify(data));

                success = CheckObjectBoolean(data, 'success');
                data = CheckObjectNullValue(data, 'data');

                if (!success)
                    msg = CheckObjectStringEmpty(data, 'message');
            })
            .catch(error => {
                msg = error.message;
                if (this.state.isDevMode)
                    console.log('Error', `api - ${text} (error)\n` + error.message);
            });

        if (success) {
            await this.LoadWordwallList_ViaApi();
            this.ToggleEditSettingModal();
            if (remove)
                this.ToggleRemoveSettingModal();
            await Delay(300);
            useAppService.getState().setModal();
            if (data !== null) {
                const findIndex = this.state.List.findIndex(x => x.id === data.id);
                if (findIndex > -1)
                    this.ToggleEditSettingModal(findIndex);
            }
            await Delay(500);
            useAppService.getState().setModal('', `${settingTitle} has been ${textBody}.`);
        }
        else {
            useAppService.getState().setModal('', `Failed to ${text} setting.<br /><br />` + msg);
        }
        this.setState({
            isUpdating: false,
        });
    }
    //#endregion

    //#region === remove ===
    ToggleRemoveSettingModal = (modal = null) => {
        if (this.state.PA_Delete === false) {
            useAppService.getState().setModal('', 'Invalid permission.');
            return null;
        }
        this.setState({
            Toggle_RemoveSettingModal: modal === null ? false : !this.state.Toggle_RemoveSettingModal,
            isUpdating: false,
        });
    }
    //#endregion

    //#region === bulk edit ===
    ToggleItemChecked = (index, selectAll = null) => {
        if (selectAll !== null) {
            this.setState({
                BulkEdit_CheckedItems: this.state.List.map((data, key) => { return !selectAll; }),
            });
        }
        else {
            if (index < 0)
                return null;
            let checkedItems = this.state.BulkEdit_CheckedItems;
            checkedItems[index] = !checkedItems[index];
            this.setState({
                BulkEdit_CheckedItems: checkedItems,
            });
        }
    }
    BulkEdit_ToggleEditSettingModal = () => {
        if (this.state.PA_Update === false) {
            useAppService.getState().setModal(`Bulk Edit ${settingTitle}(s)`, 'Invalid permission.');
            return null;
        }
        const toggle = !this.state.BulkEdit_Toggle_EditSettingModal;
        this.setState({
            BulkEdit_Toggle_EditSettingModal: toggle,
        });
        this.BulkEdit_ResetSetting();
    }
    BulkEdit_SettingModalComponent = () => {
        let components = [];
        const setting = this.state.BulkEdit_Setting;
        const setting_checked = this.state.BulkEdit_Setting_checked;

        //Display Order.
        const displayOrder_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.DisplayOrder);
        const displayOrder_setting_checked = setting_checked[displayOrder_setting_index];
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (displayOrder_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="form-group width-max">
                <label>Display Order</label>
                <input type="number" className="form-control" style={{ width: '100%' }}
                    value={CheckObjectNumber(setting[displayOrder_setting_index], 'value')}
                    onChange={(e) => this.BulkEdit_SetSetting(SettingInput.DisplayOrder, e.target.value)}
                ></input>
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, displayOrder_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : displayOrder_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        //Active.
        const active_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.Active);
        const active_setting_checked = setting_checked[active_setting_index];
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (active_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="form-group width-max">
                <label>Active</label>
                <input type="checkbox" className="form-check form-check-input"
                    onClick={(e) => this.BulkEdit_SetSetting(SettingInput.Active, e.currentTarget.checked)}
                    checked={CheckObjectBoolean(setting[active_setting_index], 'value')}
                    readOnly={true}
                ></input>
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, active_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : active_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        //Effective Date Start.
        const effectiveDateStart_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.EffectiveDateStart);
        const effectiveDateStart_setting_checked = setting_checked[effectiveDateStart_setting_index];
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (effectiveDateStart_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="form-group width-max">
                <label>Effective Date Start</label>
                <input type="datetime-local" className="form-control" style={{ width: '100%' }}
                    onChange={(e) => this.BulkEdit_SetSetting(SettingInput.EffectiveDateStart, e.target.value)}
                    // value={moment.utc(CheckObjectStringEmpty(setting[effectiveDateStart_setting_index], 'value', yearStart)).local().format('YYYY-MM-DD HH:mm')}
                    value={moment.utc(CheckObjectStringEmpty(setting[effectiveDateStart_setting_index], 'value', yearStart), 'YYYY-MM-DDTHH:mm').local().format('YYYY-MM-DDTHH:mm')}
                ></input>
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, effectiveDateStart_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : effectiveDateStart_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        //Effective Date End.
        const effectiveDateEnd_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.EffectiveDateEnd);
        const effectiveDateEnd_setting_checked = setting_checked[effectiveDateEnd_setting_index];
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (effectiveDateEnd_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="form-group width-max">
                <label>Effective Date End</label>
                <input type="datetime-local" className="form-control" style={{ width: '100%' }}
                    onChange={(e) => this.BulkEdit_SetSetting(SettingInput.EffectiveDateEnd, e.target.value)}
                    // value={moment.utc(CheckObjectStringEmpty(setting[effectiveDateEnd_setting_index], 'value', yearEnd)).local().format('YYYY-MM-DD HH:mm')}
                    value={moment.utc(CheckObjectStringEmpty(setting[effectiveDateEnd_setting_index], 'value', yearEnd), 'YYYY-MM-DDTHH:mm').local().format('YYYY-MM-DDTHH:mm')}
                ></input>
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, effectiveDateEnd_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : effectiveDateEnd_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        //Remark.
        const remark_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.Remark);
        const remark_setting_checked = setting_checked[remark_setting_index];
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (remark_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="form-group width-max">
                <label>Remark</label>
                <input type="text" className="form-control" style={{ width: '100%' }}
                    value={CheckObjectStringEmpty(setting[remark_setting_index], 'value')}
                    onChange={(e) => this.BulkEdit_SetSetting(SettingInput.Remark, e.target.value)}
                ></input>
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, remark_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : remark_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        //Display.
        const display_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.Display);
        const display_setting_checked = setting_checked[display_setting_index];
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (display_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="form-group width-max">
                <label>Display</label>
                <input type="checkbox" className="form-check form-check-input"
                    onClick={(e) => this.BulkEdit_SetSetting(SettingInput.Display, e.currentTarget.checked)}
                    checked={CheckObjectBoolean(setting[display_setting_index], 'value')}
                    readOnly={true}
                ></input>
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, display_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : display_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        return (components);
    }
    BulkEdit_SetSetting = (property = SettingInput.None, value = null) => {
        let setting = this.state.BulkEdit_Setting;
        let setting_checked = this.state.BulkEdit_Setting_checked;
        const setting_index = property === SettingInput.CheckedItem ? 999 : Object.values(BulkSetting).indexOf(property);
        if (this.state.isDevMode)
            console.log(`BulkEdit_SetSetting (setting_index) = ` + JSON.stringify(setting_index));
        if (property === SettingInput.None || setting === null || value === null || setting_index < 0)
            return null;

        switch (property) {
            case SettingInput.DisplayOrder: setting[setting_index].value = CheckNumber(value); break;
            case SettingInput.Active: setting[setting_index].value = CheckBoolean(value); break;
            case SettingInput.EffectiveDateStart: setting[setting_index].value = moment(value, 'YYYY-MM-DDTHH:mm').utc().format('YYYY-MM-DDTHH:mm'); break;
            case SettingInput.EffectiveDateEnd: setting[setting_index].value = moment(value, 'YYYY-MM-DDTHH:mm').utc().format('YYYY-MM-DDTHH:mm'); break;
            case SettingInput.Remark: setting[setting_index].value = String(value); break;
            case SettingInput.Display: setting[setting_index].value = CheckBoolean(value); break;
            case SettingInput.CheckedItem:
                setting_checked[value] = !setting_checked[value];
                if (this.state.isDevMode)
                    console.log(`BulkEdit_SetSetting (checkedItem) = ` + JSON.stringify(setting_checked));
                break;
            default: break;
        }
        this.setState({
            BulkEdit_Setting: setting,
            BulkEdit_Setting_checked: setting_checked,
        }, () => {
            if (this.state.isDevMode) {
                console.log(`BulkEdit_SetSetting (${property}) = ` + JSON.stringify(value));
                console.log(`BulkEdit_SetSetting (setting) = ` + JSON.stringify(setting));
            }
        });
    }
    BulkEdit_ResetSetting = () => {
        this.setState({
            BulkEdit_Setting: [
                { key: BulkSetting.Remark, value: '' },
                { key: BulkSetting.AlwaysOnTop, value: false },
                { key: BulkSetting.DisplayOrder, value: 0 },
                { key: BulkSetting.Display, value: false },
                { key: BulkSetting.Active, value: false },
                { key: BulkSetting.IsPrivate, value: false },
                { key: BulkSetting.EffectiveDateStart, value: yearStart },
                { key: BulkSetting.EffectiveDateEnd, value: yearEnd },
            ],
            BulkEdit_Setting_checked: Object.keys(BulkSetting).map(() => { return false; }),
        });
    }
    BulkEdit_ToggleRemoveSettingModal = () => {
        this.setState({
            BulkEdit_Toggle_RemoveSettingModal: !this.state.BulkEdit_Toggle_RemoveSettingModal,
        });
    }
    BulkEdit_CUD_Setting_ViaApi = async (remove = false) => {

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const { textTitle, textBody, text, urlParam } = GetPostParams({ id: 999 }, remove);
        this.setState({
            isUpdating: true,
        });
        useAppService.getState().setModal('', `${textTitle} setting...`, null, AlertMode.Loading);

        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Organizer/Wordwall/BulkEdit/${urlParam}`;
        // Api/LearningCentre/Organizer/Wordwall/BulkEdit/{Update|Remove}

        let setting_params = [];
        const setting_keys = Object.values(BulkSetting);
        if (remove === false) {
            for (let i = 0; i < this.state.BulkEdit_Setting_checked.length; i++) {
                if (this.state.BulkEdit_Setting_checked[i])
                    setting_params.push({ key: setting_keys[i], value: this.state.BulkEdit_Setting[i].value });
                else
                    setting_params.push({ key: setting_keys[i], value: null });
            }
        }

        let groupIds = [];
        const list = this.state.List;
        const checkedItems = this.state.BulkEdit_CheckedItems;
        for (let n = 0; n < list.length; n++) {
            if (checkedItems[n])
                groupIds.push(CheckObjectNumber(list[n], SettingInput.Id));
        }

        const json = JSON.stringify({
            OrganizerId: organizerId,
            AuthorId: authorId,

            BulkGroupIds: groupIds.join(','),
            Remark: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.Remark)].value,
            // GroupId: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.GroupId)].value,
            DisplayOrder: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.DisplayOrder)].value,
            Active: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.Active)].value,
            EffectiveDateStart: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.EffectiveDateStart)].value,
            EffectiveDateEnd: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.EffectiveDateEnd)].value,
            Display: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.Display)].value,

            Remove: remove,
        });
        if (this.state.isDevMode)
            console.log(`BulkEdit_CUD_Setting_ViaApi (${text}) (postData) =\n` + json);

        // let data = null;
        let success = false;
        let msg = '';
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: json,
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('BulkEdit_CUD_Setting_ViaApi =\n' + JSON.stringify(data));

                success = CheckObjectBoolean(data, 'success');
                // data = CheckObjectNullValue(data, 'data');

                if (!success)
                    msg = CheckObjectStringEmpty(data, 'message');
            })
            .catch(error => {
                msg = error.message;
                if (this.state.isDevMode)
                    console.log('Error', `api - ${text} (error)\n` + error.message);
            });

        if (success) {
            await this.LoadList_ViaApi();
            this.BulkEdit_ToggleEditSettingModal();
            await Delay(500);
            useAppService.getState().setModal();
            await Delay(500);
            useAppService.getState().setModal('', `${settingTitle}(s) have been ${textBody}.`);
        }
        else {
            useAppService.getState().setModal('', `Failed to ${text} ${settingTitle.toLowerCase()}(s).<br /><br />` + msg);
        }
        this.setState({
            isUpdating: false,
        });
    }
    //#endregion === bulk edit ===

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        return (<>
            <table className="table page-header">
                <tbody>
                    <tr>
                        <td className="left">
                            <h5>{this.ListTitle()}</h5>
                            {/* <h5>{settingTitle}</h5> */}
                            {
                                // this.state.SearchBySubjectId > 0 && this.state.SearchByGroupId > 0 && this.state.List.length > 0 ?
                                //     <Button variant="primary"
                                //         onClick={() => this.BulkEdit_ToggleEditSettingModal()}
                                //         disabled={this.state.BulkEdit_CheckedItems.length === 0 ? true : (this.state.BulkEdit_CheckedItems.includes(true) ? false : true)}
                                //     >Bulk Edit</Button>
                                //     : null
                            }
                            {
                                this.state.SearchBySubjectId > 0 && this.state.SearchByGroupId > 0 ?
                                    <button
                                        type="button"
                                        className="btn-link"
                                        onClick={() => this.LoadWordwallList_ViaApi()}
                                        title="Refresh Wordwall list"
                                    ><i className="fa fa-refresh" title="Refresh Wordwall list"></i></button>
                                    : null
                            }
                        </td>
                        <td className="center"></td>
                        <td className="right">
                            {this.BackOneLayerButtonComponent()}
                            {
                                this.state.SearchBySubjectId > 0 && this.state.SearchByGroupId > 0 ?
                                    <Button variant="primary" onClick={() => this.ToggleEditSettingModal(-1, true)} disabled={this.state.PA_Update === false}>Add New</Button>
                                    : null
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='table table-hover table-bordered tbStyle' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
                {this.ListComponents()}
                {/* <thead>
                    <tr>
                        <th width='50' className="pointer" onClick={() => this.ToggleItemChecked(-1, this.state.BulkEdit_CheckedItems.findIndex(x => x === false) < 0)}>
                            <input type='checkbox' className='pointer' readOnly={true}
                                checked={this.state.BulkEdit_CheckedItems.length > 0 ? this.state.BulkEdit_CheckedItems.indexOf(false) < 0 : false}></input>
                        </th>
                        <th width='50'>#</th>
                        <th className="left" colSpan={2}>Name</th>
                        <th width='125'>Remark</th>
                        <th width='115'>Display Order</th>
                        <th width='115'>Active</th>
                        <th width='125'>Display</th>
                        <th width='75'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.isLoading && !this.state.IsListLoaded ?
                            <tr><td colSpan='15' height={63}><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></td></tr>
                            : this.state.List.length > 0 ?
                                this.ListComponents()
                                : <tr><td colSpan='15' align='center'>list is empty</td></tr>
                    }
                    {
                        // this.state.List.length === 0 ? null :
                        //     PagingComponents(15, this.state.TotalRows, this.state.PageIndex, this.state.PageSize, this.CallbackFunctionForPagingComponents_PageSize, this.CallbackFunctionForPagingComponents_PageIndex)
                    }
                </tbody> */}
            </table>

            {/* Setting - Edit / Update - Modal */}
            <Modal show={this.state.Toggle_EditSettingModal}
                onHide={() => this.ToggleEditSettingModal()}
                centered
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>{this.state.SettingModal_Create ? `New ${settingTitle}` : 'Edit :: ' + CheckObjectStringEmpty(this.state.SettingModal, 'name')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.SettingModalComponent()}
                </Modal.Body>
                <Modal.Footer>
                    {
                        this.state.SettingModal_Create ? null :
                            <Button variant="danger"
                                onClick={() => this.ToggleRemoveSettingModal(this.state.SettingModal)}
                                style={{ position: "absolute", left: 0, marginLeft: 15 }}
                            // disabled={this.state.isUpdating || (this.state.IsSuperAdmin ? false : this.state.PA_Delete === false || CheckObjectNumber(this.state.SettingModal, 'authorId') === 1)}
                            // disabled={this.state.isUpdating || !(this.state.IsSuperAdmin || this.state.IsMasterAdmin) || this.state.PA_Delete === false ||
                            //     (CheckObjectNumber(this.state.SettingModal, SettingInput.OrganizerId) > 0 && CheckObjectNumber(this.state.SettingModal, SettingInput.OrganizerId) !== this.state.OrganizerId)}
                            >Remove</Button>
                    }
                    <Button variant="secondary" onClick={() => this.ToggleEditSettingModal()} disabled={this.state.isUpdating}>Cancel</Button>
                    <Button variant="secondary" onClick={() => this.ResetSetting()} disabled={this.state.isUpdating}>Reset</Button>
                    {
                        this.state.SettingModal_Create ?
                            <Button variant="primary"
                                onClick={() => this.CUD_Setting_ViaApi()}
                                disabled={this.state.isUpdating || this.state.PA_Create === false}
                            >Create</Button>
                            :
                            <Button variant="primary"
                                onClick={() => this.CUD_Setting_ViaApi()}
                                disabled={this.state.isUpdating || this.state.PA_Update === false}
                            >Update</Button>
                    }
                </Modal.Footer>
            </Modal >

            {/* Setting - Remove (Confirm) - Modal */}
            < Modal show={this.state.Toggle_RemoveSettingModal}
                onHide={() => this.ToggleRemoveSettingModal()
                }
                centered
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Remove :: {CheckObjectStringEmpty(this.state.SettingModal, 'name')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you sure you want to remove this {settingTitle.toLowerCase()} ?</p>
                    <p>If so, click confirm to continue.</p>
                    <p><i><b>Caution:</b> Once removed, {settingTitle.toLowerCase()} will not be able to recover back.</i></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ToggleRemoveSettingModal()}>Cancel</Button>
                    <Button variant="primary"
                        // onClick={() => this.RemoveSetting_ViaApi()}
                        onClick={() => this.CUD_Setting_ViaApi(true)}
                        disabled={this.state.isUpdating}
                    >Confirm</Button>
                </Modal.Footer>
            </Modal >

            {/* Setting - (BULK) Edit / Update - Modal */}
            < Modal show={this.state.BulkEdit_Toggle_EditSettingModal}
                onHide={() => this.BulkEdit_ToggleEditSettingModal()}
                centered
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Bulk Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body className="setting-bulk-parent">
                    {this.BulkEdit_SettingModalComponent()}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger"
                        onClick={() => this.BulkEdit_ToggleRemoveSettingModal()}
                        style={{ position: "absolute", left: 0, marginLeft: 15 }}
                        disabled={this.state.isUpdating || (this.state.IsSuperAdmin ? false : this.state.PA_Delete === false)}
                    >Bulk Remove</Button>
                    <Button variant="secondary" onClick={() => this.BulkEdit_ToggleEditSettingModal()} disabled={this.state.isUpdating}>Cancel</Button>
                    <Button variant="secondary" onClick={() => this.BulkEdit_ResetSetting()} disabled={this.state.isUpdating}>Reset</Button>
                    <Button variant="primary" onClick={() => this.BulkEdit_CUD_Setting_ViaApi()} disabled={this.state.isUpdating}>Bulk Update</Button>
                </Modal.Footer>
            </Modal >

            {/* Setting - (BULK) Remove (Confirm) - Modal */}
            < Modal show={this.state.BulkEdit_Toggle_RemoveSettingModal}
                onHide={() => this.BulkEdit_ToggleRemoveSettingModal()}
                centered
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Bulk Remove</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you sure you want to remove these items ?</p>
                    <p>If so, click confirm to continue.</p>
                    <p><i><b>Caution:</b> Once removed, they will not be able to recover back.</i></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.BulkEdit_ToggleRemoveSettingModal()}>Cancel</Button>
                    <Button variant="primary"
                        onClick={() => this.BulkEdit_CUD_Setting_ViaApi(true)}
                        disabled={this.state.isUpdating}
                    >Confirm</Button>
                </Modal.Footer>
            </Modal >

            {/* Preview File - Modal */}
            < Modal show={this.state.PreviewFileModal_Toggle}
                onHide={() => this.TogglePreviewFileModal()}
                centered
                size="lg"
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Preview Media</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center' }}>{this.state.PreviewFileModal_Component}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.TogglePreviewFileModal()}>Close</Button>
                </Modal.Footer>
            </Modal >
        </>);
    }
}