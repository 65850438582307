import React from "react";
// import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { Button, ButtonGroup, Dropdown, DropdownButton, Modal, ProgressBar } from "react-bootstrap";
import moment from "moment";
// import Select from "react-select";

import { GlobalSetting, MediaTypeOptions, PlatformOptions } from "../utilities/GlobalSetting";
import { CheckBoolean, CheckNumber, CheckObjectBoolean, CheckObjectNullValue, CheckObjectNumber, CheckObjectStringEmpty, CheckStringEmpty, CheckValueNA, Delay, FormatPropertyNameToTextTitle, GetPostParams, GetPropIds, TriggerDownloadFile } from "../utilities/GlobalFunctions";
import { useGlobal } from "../utilities/GlobalVariables";
import { useAppService } from "../services/AppService";
import { AlertMode } from "./AlertComponent";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
// import { Locale } from "../../utilities/localization/CustomLocalization";

const SettingInput = {
    OrganizerId: 'organizerId',
    AuthorId: 'authorId',
    Id: 'id',
    Platform: 'platform',   //2025.03.19
    Name: 'name',
    Remark: 'remark',
    AlwaysOnTop: 'alwaysOnTop',
    DisplayOrder: 'displayOrder',
    Display: 'display',
    Active: 'active',
    IsPrivate: 'isPrivate',

    MediaType: 'mediaType',
    FileLocation: 'fileLocation',
    FileName: 'fileName',
    FileExt: 'fileExt',

    // EffectiveDateStart: 'effectiveDateStart',
    // EffectiveDateEnd: 'effectiveDateEnd',

    CheckedItem: 'checkedItem',
    None: 'none',
};

const BulkSetting = {
    Remark: 'remark',
    AlwaysOnTop: 'alwaysOnTop',
    DisplayOrder: 'displayOrder',
    Display: 'display',
    Active: 'active',
    IsPrivate: 'isPrivate',
    // EffectiveDateStart: 'effectiveDateStart',
    // EffectiveDateEnd: 'effectiveDateEnd',
};

// const MediaTypeOptions = [
//     { id: 0, value: '', label: '(Select)' },
//     { id: 1, value: 'PDF', label: 'PDF' },
//     { id: 2, value: 'Youtube', label: 'Youtube' },
//     { id: 3, value: 'Vimeo', label: 'Vimeo' },
// ];

const settingTitle = 'Media';
const yearStart = moment(moment.utc().format('YYYY-01-01T16:00')).add(-1, 'day').format('YYYY-MM-DDTHH:mm');
const yearEnd = moment(yearStart, 'YYYY-MM-DDTHH:mm').add(1, 'year').add(-1, 'minute').format('YYYY-MM-DDTHH:mm');

//2025.02.21
export default class ManageMediaScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({

        isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        redirect: false,
        redirectLink: '/',
        isLoading: false,
        PA_View: false,
        PA_Search: false,
        PA_Create: false,
        PA_Update: false,
        PA_Delete: false,
        PA_Upload: false,
        PA_Download: false,

        List: [],
        IsListLoaded: false,
        TotalRows: 0,
        PageIndex: 0,
        PageSize: 10,
        // OrderBy: 'Name',
        // OrderType: 'ASC',
        OrganizerId: 0,
        IsSuperAdmin: false,
        IsMasterAdmin: false,

        Toggle_EditSettingModal: false,
        SettingModal_Create: false,
        SettingModal_Index: -1,
        SettingModal: null,
        SettingModal_Cache: null,
        isUpdating: false,

        Toggle_RemoveSettingModal: false,

        //2024.10.21
        BulkEdit_Setting: Object.keys(BulkSetting).map((data, key) => { return { key: data, value: null }; }),
        BulkEdit_Setting_checked: Object.keys(BulkSetting).map(() => { return false; }),
        BulkEdit_Toggle_EditSettingModal: false,
        BulkEdit_Toggle_RemoveSettingModal: false,
        BulkEdit_CheckedItems: [],

        //2025.03.06
        PreviewFileModal_Toggle: false,
        PreviewFileModal_Component: null,
        PreviewFile_MediaType: '',
    });

    componentWillUnmount = () => { }

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        await useAppService.getState().getGroups();
        await Delay(0);
        this.LoadList_ViaApi();
        if (typeof useGlobal.getState().setRefreshListCallbackFn === 'function')
            useGlobal.getState().setRefreshListCallbackFn(this.LoadList_ViaApi);
    }

    //#region === list ===
    CheckPermissions = async () => {
        const user = useGlobal.getState().user;
        if (user === null || user === undefined) {
            this.setState({
                redirect: true,
                redirectLink: '/',
            });
        }
        const { organizerId } = GetPropIds(user);
        this.setState({
            // PA_View: PermissionAccess(LayoutScreen.ManageMedia, PermissionAccessType.View),
            // PA_Search: PermissionAccess(LayoutScreen.ManageMedia, PermissionAccessType.Search),
            // PA_Create: PermissionAccess(LayoutScreen.ManageMedia, PermissionAccessType.Create),
            // PA_Update: PermissionAccess(LayoutScreen.ManageMedia, PermissionAccessType.Update),
            // PA_Delete: PermissionAccess(LayoutScreen.ManageMedia, PermissionAccessType.Delete),
            // PA_Upload: PermissionAccess(LayoutScreen.ManageMedia, PermissionAccessType.Upload),
            // PA_Download: PermissionAccess(LayoutScreen.ManageMedia, PermissionAccessType.Download),

            PA_View: true,
            PA_Search: true,
            PA_Create: true,
            PA_Update: true,
            PA_Delete: true,
            PA_Upload: true,
            PA_Download: true,

            PageSize: 999,

            OrganizerId: organizerId,
            IsSuperAdmin: CheckBoolean(useGlobal.getState().isSuperAdmin),
            IsMasterAdmin: CheckBoolean(useGlobal.getState().isMasterAdmin),
        });
        await Delay(0);
    }
    LoadList_ViaApi = async () => {

        await this.CheckPermissions();

        // if (this.state.PA_View === false)
        //     return null;

        this.setState({
            isLoading: true,
            List: [],
            TotalRows: 0,
            IsListLoaded: false,
        });
        window.scrollTo(0, 0);

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Portal/Organizer/Media/List`;
        //  Api/LearningCentre/Portal/Organizer/Media/List

        const postData = JSON.stringify({
            OrganizerId: organizerId,
            AuthorId: authorId,
            IsPrivate: null,
            Display: null,
        });
        if (this.state.isDevMode)
            console.log('LoadList_ViaApi (postData) \n' + postData);

        let totalRows = 0;
        let _List = [];
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: postData,
            })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    if (data.data !== undefined)
                        if (Array.isArray(data.data)) {
                            // _List = data.data;
                            _List = JSON.parse(JSON.stringify(data.data));
                            if (Array.isArray(_List)) {
                                _List.sort((a, b) => a.displayOrder - b.displayOrder);
                                totalRows = CheckObjectNumber(data.data, 'totalCount', _List.length);
                            }
                        }
                        else {
                            if (this.state.isDevMode)
                                console.log(`${settingTitle} list is empty.`);
                        }
                }
                else {
                    if (this.state.isDevMode)
                        console.log('Error', 'api - setting - load list (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                if (this.state.isDevMode)
                    console.log('Error', 'api - setting - load list (error)\n' + error.message);
            });

        this.setState({
            isLoading: false,
            // List: JSON.parse(JSON.stringify(_List)),
            List: _List,
            TotalRows: totalRows,
            IsListLoaded: true,
            BulkEdit_CheckedItems: Array.isArray(_List) ? _List.map((data, key) => { return false; }) : [],
        }, () => {
            if (this.state.isDevMode) {
                console.log('TotalRows', totalRows);
                console.log('List', JSON.stringify(_List));
            }
        });
    }
    ListComponents = () => {
        let components = [];

        if (this.state.IsListLoaded === false)
            return null;

        if (this.state.List.length === 0)
            return (<tr><td colSpan={15} align='center'>- list is empty -</td></tr>);

        this.state.List.map((data, key) => {
            components.push(<tr key={'tbi_' + key} className={`${this.state.BulkEdit_CheckedItems[key] ? 'setting-classroom-tr-class' : ''}`}>
                <td className="pointer" onClick={() => this.ToggleItemChecked(key)}><input type='checkbox' className='pointer' checked={this.state.BulkEdit_CheckedItems[key]} readOnly={true}></input></td>
                <td>{this.state.PageIndex + key + 1}</td>
                <td className='left'>{FormatPropertyNameToTextTitle(CheckObjectStringEmpty(data, SettingInput.Platform))}</td>
                <td className='left'>
                    {CheckValueNA(data[SettingInput.Name])}<br />
                    {CheckObjectNumber(data, SettingInput.OrganizerId) > 0 ? '' : <span style={{ color: 'gray', fontStyle: 'italic' }}>(Default)</span>}
                </td>
                <td width={75}>{CheckObjectStringEmpty(data, SettingInput.MediaType)}</td>
                <td width={75}>
                    <Button
                        variant='info'
                        onClick={() => this.TogglePreviewFileModal(data)}>Preview</Button>
                </td>
                <td>{
                    CheckObjectNullValue(data, SettingInput.Remark) === null ? '-' :
                        <Button
                            variant='info'
                            onClick={() => useAppService.getState().setModal(`Remark :: ${CheckValueNA(data[SettingInput.Name])}`, CheckValueNA(data[SettingInput.Remark]))}
                        >View</Button>
                }</td>
                <td>{CheckObjectNumber(data, SettingInput.DisplayOrder)}</td>
                <td>{CheckObjectBoolean(data, SettingInput.Active) ? '✔' : '❌'}</td>
                <td>{CheckObjectBoolean(data, SettingInput.Display) ? '✔' : '❌'}</td>
                <td>
                    {
                        this.state.IsSuperAdmin || this.state.IsMasterAdmin || CheckObjectNumber(data, SettingInput.OrganizerId) === this.state.OrganizerId ?
                            <button
                                type='button'
                                className='btn btn-primary mw65'
                                onClick={() => this.ToggleEditSettingModal(key)}
                            >Edit</button>
                            :
                            CheckObjectBoolean(data, SettingInput.Display) ?
                                <button
                                    type='button'
                                    className='btn btn-secondary mw65'
                                    onClick={() => {
                                        let setting = this.PopulateSettingModal(data);
                                        setting[SettingInput.Display] = false;
                                        this.setState({
                                            SettingModal: setting,
                                        }, () => {
                                            this.CUD_Setting_ViaApi();
                                        });
                                    }}
                                >Hide</button>
                                :
                                <button
                                    type='button'
                                    className='btn btn-warning mw65'
                                    onClick={() => {
                                        let setting = this.PopulateSettingModal(data);
                                        setting[SettingInput.Display] = true;
                                        this.setState({
                                            SettingModal: setting,
                                        }, () => {
                                            this.CUD_Setting_ViaApi();
                                        });
                                    }}
                                >Show</button>
                    }
                </td>
            </tr>);
            return null;
        });

        return (components);
    }
    //2025.03.06
    TogglePreviewFileModal = async (data = null) => {
        const toggle = data === null ? false : !this.state.PreviewFileModal_Toggle;
        this.setState({
            PreviewFileModal_Toggle: toggle,
        });
        if (toggle === false)
            await Delay(200);

        let component = null;
        let mediaType = '';
        if (data !== null) {
            mediaType = data[SettingInput.MediaType];
            switch (mediaType) {

                case MediaTypeOptions[1].value:
                    //pdf.
                    const symbolRegex = /[\s.,#!$%^&*;:{}=\-_`~()]/g;
                    const fileName = CheckStringEmpty(data[SettingInput.FileName], data[SettingInput.Name].replace(symbolRegex, '-'));
                    const fileExt = CheckStringEmpty(data[SettingInput.FileExt], '.pdf');
                    component = <button type="button" className="btn btn-primary"
                        onClick={() => TriggerDownloadFile(data[SettingInput.FileLocation], fileName, fileExt, this.state.locale, '_blank')}
                    // disabled={!this.state.PA_Download}
                    // title="download this file"
                    >{fileName + fileExt}</button>;
                    break;

                case MediaTypeOptions[2].value:
                    //youtube.
                    component = <iframe
                        width="640" height="360"
                        src={data[SettingInput.FileLocation]}
                        title={data[SettingInput.Name]}
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                    // allowfullscreen
                    ></iframe>;
                    break;

                case MediaTypeOptions[3].value:
                    //vimeo.
                    component = <iframe
                        src={data[SettingInput.FileLocation]}
                        title={data[SettingInput.Name]}
                        width="640"
                        height="360"
                        frameborder="0"
                    // allow="autoplay; fullscreen"
                    // allowfullscreen
                    ></iframe>
                    break;

                default: break;
            }
        }
        this.setState({
            PreviewFileModal_Component: component,
            PreviewFile_MediaType: mediaType,
        });
    }
    //#endregion

    //#region === edit / new ===
    ToggleEditSettingModal = async (index = -1, create = false) => {
        // useAppService.getState().setModal('', 'coming soon...');
        if (create) {
            if (this.state.PA_Create === false) {
                useAppService.getState().setModal(`New ${settingTitle}`, 'Invalid permission.');
                return null;
            }
            else {
                const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
                let defaultModal = this.PopulateSettingModal(null, organizerId, authorId);
                // defaultModal['isPrivate'] = true;
                this.setState({
                    // Toggle_EditSettingModal: true,
                    SettingModal_Create: true,
                    SettingModal_Index: -1,
                    SettingModal: defaultModal,
                    SettingModal_Cache: JSON.parse(JSON.stringify(defaultModal)),
                    isUpdating: false,
                });
                await Delay(200);
                this.setState({
                    Toggle_EditSettingModal: true,
                });
                return null;
            }
        }
        // if (this.state.PA_Update === false) {
        //     useAppService.getState().setModal(`Edit ${settingTitle}`, 'Invalid permission.');
        //     return null;
        // }
        if (index < 0) {
            this.setState({ Toggle_EditSettingModal: false });
            await Delay(200);
        }
        this.setState({
            SettingModal_Create: false,
            SettingModal_Index: index,
            SettingModal: index < 0 ? null : this.PopulateSettingModal(this.state.List[index]),
            SettingModal_Cache: index < 0 ? null : this.PopulateSettingModal(this.state.List[index]),
            isUpdating: false,
        });
        if (index >= 0) {
            await Delay(200);
            this.setState({ Toggle_EditSettingModal: true });
        }
        if (this.state.isDevMode)
            console.log(`ToggleEditSettingModal (${index}) (${create}) =\n ${JSON.stringify(this.state.SettingModal)}`);
    }
    SettingModalComponent = () => {
        let components = [];
        const settingDetails = this.state.SettingModal;
        if (settingDetails !== null) {

            const { organizerId } = GetPropIds(useGlobal.getState().user);
            const readOnly = this.state.IsSuperAdmin || this.state.IsMasterAdmin || (organizerId === CheckObjectNumber(settingDetails, SettingInput.OrganizerId) && this.state.PA_Update) ? false : true;

            //Platform
            const findIndex_platform = Array.isArray(PlatformOptions) && PlatformOptions.length > 0 ? PlatformOptions.findIndex(x => String(x.value) === CheckObjectStringEmpty(settingDetails, SettingInput.Platform)) : -1;
            components.push(<div className="form-group" key='s-e-platform' style={{ display: 'flex', flexDirection: 'column' }}>
                <label>Platform</label>
                <DropdownButton
                    style={{ width: 160 }}
                    as={ButtonGroup}
                    title={findIndex_platform < 0 ? (PlatformOptions.length > 0 ? PlatformOptions[0].label : '') : PlatformOptions[findIndex_platform].label}
                    onSelect={(eventKey) => { if (!readOnly) this.SetSettingValue(SettingInput.Platform, PlatformOptions[eventKey].value); }}
                >{
                        Array.isArray(PlatformOptions) && PlatformOptions.length > 0 ?
                            PlatformOptions.map((option, key) => {
                                return <Dropdown.Item eventKey={option.id}>{option.label}</Dropdown.Item>;
                            })
                            : null
                    }</DropdownButton>
            </div>);

            //Name.
            components.push(<div className="form-group" key='s-e-name'>
                <label>Name</label>
                <input type="text" className="form-control" style={{ width: '100%' }}
                    onChange={(e) => { if (!readOnly) this.SetSettingValue(SettingInput.Name, e.target.value); }}
                    value={settingDetails[SettingInput.Name]}
                    disabled={readOnly}
                ></input>
            </div>);

            //Remark.
            components.push(<div className="form-group" key='s-e-remark'>
                <label>Remark</label>
                <input type="text" className="form-control" style={{ width: '100%' }}
                    onChange={(e) => { if (!readOnly) this.SetSettingValue(SettingInput.Remark, e.target.value); }}
                    value={settingDetails[SettingInput.Remark]}
                    disabled={readOnly}
                ></input>
            </div>);

            //Media Type
            const findIndex_mediaType = Array.isArray(MediaTypeOptions) && MediaTypeOptions.length > 0 ? MediaTypeOptions.findIndex(x => String(x.value) === CheckObjectStringEmpty(settingDetails, SettingInput.MediaType)) : -1;
            components.push(<div className="form-group" key='s-e-media-type' style={{ display: 'flex', flexDirection: 'column' }}>
                <label>Media Type</label>
                <DropdownButton
                    style={{ width: 160 }}
                    as={ButtonGroup}
                    title={findIndex_mediaType < 0 ? (MediaTypeOptions.length > 0 ? MediaTypeOptions[0].label : '') : MediaTypeOptions[findIndex_mediaType].label}
                    onSelect={(eventKey) => { if (!readOnly) this.SetSettingValue(SettingInput.MediaType, MediaTypeOptions[eventKey].value); }}
                >{
                        Array.isArray(MediaTypeOptions) && MediaTypeOptions.length > 0 ?
                            MediaTypeOptions.map((option, key) => {
                                return <Dropdown.Item eventKey={option.id}>{option.label}</Dropdown.Item>;
                            })
                            : null
                    }</DropdownButton>
            </div>);

            //File Location.
            components.push(<div className="form-group" key='s-e-file-location'>
                <label>File Location (URL)</label>
                <input type="text" className="form-control" style={{ width: '100%' }}
                    onChange={(e) => { if (!readOnly) this.SetSettingValue(SettingInput.FileLocation, e.target.value); }}
                    value={settingDetails[SettingInput.FileLocation]}
                    disabled={readOnly}
                ></input>
            </div>);

            //DisplayOrder.
            components.push(<div className="form-group" key='s-e-display-order'>
                <label>DisplayOrder</label>
                <input type="number" className="form-control" style={{ width: '100%' }}
                    onChange={(e) => { if (!readOnly) this.SetSettingValue(SettingInput.DisplayOrder, e.target.value); }}
                    value={CheckObjectNumber(settingDetails, SettingInput.DisplayOrder)}
                    disabled={readOnly}
                ></input>
            </div>);

            //Active.
            components.push(<div className="form-group" key='s-e-active'>
                <label>Active</label>
                <input type="checkbox" className="form-check form-check-input"
                    onClick={(e) => { if (!readOnly) this.SetSettingValue(SettingInput.Active, e.currentTarget.checked); }}
                    checked={CheckObjectBoolean(settingDetails, SettingInput.Active)}
                    readOnly={true}
                    disabled={readOnly}
                ></input>
            </div>);

            //Display.
            components.push(<div className="form-group" key='s-e-display'>
                <label>Display</label>
                <input type="checkbox" className="form-check form-check-input"
                    onClick={(e) => { if (!readOnly) this.SetSettingValue(SettingInput.Display, e.currentTarget.checked); }}
                    checked={CheckObjectBoolean(settingDetails, SettingInput.Display)}
                    readOnly={true}
                    disabled={this.state.PA_Update === false}
                ></input>
            </div>);

            //IsPrivate.
            components.push(<div className="form-group" key='s-e-isPrivate'>
                <label>Is Private</label>
                <input type="checkbox" className="form-check form-check-input"
                    onClick={(e) => { if (this.state.IsSuperAdmin || this.state.IsMasterAdmin || !readOnly) this.SetSettingValue(SettingInput.IsPrivate, e.currentTarget.checked); }}
                    checked={CheckObjectBoolean(settingDetails, SettingInput.IsPrivate)}
                    readOnly={true}
                    // disabled={this.state.PA_Update === false || CheckObjectNumber(settingDetails, SettingInput.OrganizerId) !== this.state.OrganizerId}
                    disabled={this.state.IsSuperAdmin || this.state.IsMasterAdmin ? false : this.state.PA_Update === false || readOnly}
                ></input>
            </div>);
        }
        return (components);
    }
    SetSettingValue = (property = SettingInput.None, value = null) => {
        let setting = this.state.SettingModal;
        if (setting === null || value === null)
            return null;
        switch (property) {
            case SettingInput.Platform: setting[property] = CheckStringEmpty(value); break;   //2025.03.19
            case SettingInput.Name: setting[property] = String(value); break;
            case SettingInput.Remark: setting[property] = String(value); break;
            case SettingInput.MediaType: setting[property] = CheckStringEmpty(value); break;
            case SettingInput.DisplayOrder: setting[property] = CheckNumber(value); break;
            case SettingInput.Active: setting[property] = CheckBoolean(value); break;
            case SettingInput.Display: setting[property] = CheckBoolean(value); break;
            case SettingInput.IsPrivate: setting[property] = CheckBoolean(value); break;
            case SettingInput.FileLocation:
                const string_value = String(value);
                setting[property] = string_value;
                if (string_value.toLowerCase().includes('.' + MediaTypeOptions[1].value.toLowerCase())) {
                    //pdf.
                    setting[SettingInput.MediaType] = MediaTypeOptions[1].value;
                }
                else if (string_value.includes(MediaTypeOptions[2].value.toLowerCase())) {
                    //youtube.
                    let t_value = string_value.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/');
                    setting[property] = t_value.split('?')[0];
                    setting[SettingInput.MediaType] = MediaTypeOptions[2].value;
                }
                else if (string_value.includes('youtu.be')) {
                    //youtube.
                    setting[property] = string_value.split('?')[0].replace('https://youtu.be/', 'https://www.youtube.com/embed/');
                    setting[SettingInput.MediaType] = MediaTypeOptions[2].value;
                }
                else if (string_value.includes(MediaTypeOptions[3].value.toLowerCase())) {
                    //vimeo.
                    setting[property] = string_value.replace('https://vimeo.com/', 'https://player.vimeo.com/video/');
                    setting[SettingInput.MediaType] = MediaTypeOptions[3].value;
                }
                break;
            // case SettingInput.EffectiveDateStart: setting[property] = moment(value).utc().format('YYYY-MM-DD HH:mm'); break;
            // case SettingInput.EffectiveDateEnd: setting[property] = moment(value).utc().format('YYYY-MM-DD HH:mm'); break;
            default: break;
        }
        this.setState({
            SettingModal: setting,
        }, () => {
            if (this.state.isDevMode)
                console.log(`SetSettingValue (${property})`, JSON.stringify(setting));
        });
    }
    ResetSetting = () => {
        this.setState({
            SettingModal: JSON.parse(JSON.stringify(this.state.SettingModal_Cache)),
        });
    }
    PopulateSettingModal = (modal = null, organizerId = 0, authorId = 0) => {
        return {
            organizerId: CheckObjectNumber(modal, SettingInput.OrganizerId, organizerId),
            authorId: CheckObjectNumber(modal, SettingInput.AuthorId, authorId),

            id: CheckObjectNumber(modal, SettingInput.Id),
            platform: CheckObjectStringEmpty(modal, SettingInput.Platform), //2025.03.19
            name: CheckObjectStringEmpty(modal, SettingInput.Name),
            remark: CheckObjectStringEmpty(modal, SettingInput.Remark),
            displayOrder: CheckObjectNumber(modal, SettingInput.DisplayOrder),
            active: CheckObjectBoolean(modal, SettingInput.Active),
            display: CheckObjectBoolean(modal, SettingInput.Display),
            // isPrivate: CheckObjectBoolean(modal, SettingInput.IsPrivate, CheckObjectNumber(modal, SettingInput.OrganizerId, organizerId) === this.state.OrganizerId),
            isPrivate: CheckObjectBoolean(modal, SettingInput.IsPrivate),

            mediaType: CheckObjectStringEmpty(modal, SettingInput.MediaType),
            fileLocation: CheckObjectStringEmpty(modal, SettingInput.FileLocation),
            fileName: CheckObjectStringEmpty(modal, SettingInput.FileName),
            fileExt: CheckObjectStringEmpty(modal, SettingInput.FileExt),

            checkedItem: false,
        };
    }
    SettingModalValidation = (postData = null) => {
        if (postData === null)
            return { success: false, message: 'invalid modal.' };

        let messages = [];

        if (CheckObjectNullValue(postData, SettingInput.Platform) === null)
            messages.push('Platform is not selected.');

        if (CheckObjectNullValue(postData, SettingInput.Name) === null)
            messages.push('Name must not be empty.');

        if (CheckObjectNullValue(postData, SettingInput.FileLocation) === null)
            messages.push('File Location (URL) must not be empty.');

        // if (CheckObjectNullValue(postData, 'groupId') === null || CheckObjectNullValue(postData, 'groupName') === null)
        //     messages.push('Group must be selected.');

        return { success: messages.length === 0, message: <ul>{messages.map((item) => { return <li>{item}</li>; })}</ul> };
    }
    CUD_Setting_ViaApi = async (remove = false) => {

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const postData = this.PopulateSettingModal(this.state.SettingModal);

        const { success: validation_success, message: validation_message } = this.SettingModalValidation(postData);
        if (validation_success === false) {
            useAppService.getState().setModal('Validation Failed', validation_message);
            this.setState({
                isUpdating: false,
            });
            return null;
        }

        const { textTitle, textBody, text, urlParam } = GetPostParams(postData, remove);
        this.setState({
            isUpdating: true,
        });
        useAppService.getState().setModal('', `${textTitle} setting...`, null, AlertMode.Loading);

        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Portal/Organizer/Media/${urlParam}`;
        // Api/LearningCentre/Portal/Organizer/Media/{Create|Update|Remove}

        const {
            id, platform, name, remark, displayOrder, active, isPrivate, display, fileLocation, fileName, fileExt, mediaType
        } = postData;
        let backup_fileName = '';
        let backup_fileExt = '';
        if (fileName === '' && fileLocation !== '') {
            const tmp = fileLocation.split('/');
            if (tmp[tmp.length - 1].includes('.pdf')) {
                backup_fileName = tmp[tmp.length - 1].split('.pdf')[0];
                backup_fileExt = '.pdf';
            }
        }
        const json = JSON.stringify({
            OrganizerId: organizerId,
            AuthorId: authorId,

            Id: CheckNumber(id),
            Platform: CheckStringEmpty(platform),   //2025.03.19
            Name: CheckStringEmpty(name),
            Remark: CheckStringEmpty(remark),

            DisplayOrder: CheckNumber(displayOrder),
            Active: CheckBoolean(active),
            IsPrivate: CheckBoolean(isPrivate),
            Display: CheckBoolean(display),

            MediaType: CheckStringEmpty(mediaType),
            FileLocation: CheckStringEmpty(fileLocation),
            FileName: CheckStringEmpty(fileName, backup_fileName),
            FileExt: CheckStringEmpty(fileExt, backup_fileExt),

            Remove: remove,
        });
        if (this.state.isDevMode)
            console.log(`CUD_Setting_ViaApi (${text}) (postData) =\n` + json);

        let data = null;
        let success = false;
        let msg = '';
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: json,
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('CUD_Setting_ViaApi =\n' + JSON.stringify(data));

                success = CheckObjectBoolean(data, 'success');
                data = CheckObjectNullValue(data, 'data');

                if (!success)
                    msg = CheckObjectStringEmpty(data, 'message');
            })
            .catch(error => {
                msg = error.message;
                if (this.state.isDevMode)
                    console.log('Error', `api - ${text} (error)\n` + error.message);
            });

        if (success) {
            await this.LoadList_ViaApi();
            this.ToggleEditSettingModal();
            if (remove)
                this.ToggleRemoveSettingModal();
            await Delay(300);
            useAppService.getState().setModal();
            if (data !== null) {
                const findIndex = this.state.List.findIndex(x => x.id === data.id);
                if (findIndex > -1)
                    this.ToggleEditSettingModal(findIndex);
            }
            await Delay(500);
            useAppService.getState().setModal('', `${settingTitle} has been ${textBody}.`);
        }
        else {
            useAppService.getState().setModal('', `Failed to ${text} setting.<br /><br />` + msg);
        }
        this.setState({
            isUpdating: false,
        });
    }
    //#endregion

    //#region === remove ===
    ToggleRemoveSettingModal = (modal = null) => {
        if (this.state.PA_Delete === false) {
            useAppService.getState().setModal('', 'Invalid permission.');
            return null;
        }
        this.setState({
            Toggle_RemoveSettingModal: modal === null ? false : !this.state.Toggle_RemoveSettingModal,
            isUpdating: false,
        });
    }
    //#endregion

    //#region === bulk edit ===
    ToggleItemChecked = (index, selectAll = null) => {
        if (selectAll !== null) {
            this.setState({
                BulkEdit_CheckedItems: this.state.List.map((data, key) => { return !selectAll; }),
            });
        }
        else {
            if (index < 0)
                return null;
            let checkedItems = this.state.BulkEdit_CheckedItems;
            checkedItems[index] = !checkedItems[index];
            this.setState({
                BulkEdit_CheckedItems: checkedItems,
            });
        }
    }
    BulkEdit_ToggleEditSettingModal = () => {
        if (this.state.PA_Update === false) {
            useAppService.getState().setModal(`Bulk Edit ${settingTitle}(s)`, 'Invalid permission.');
            return null;
        }
        const toggle = !this.state.BulkEdit_Toggle_EditSettingModal;
        this.setState({
            BulkEdit_Toggle_EditSettingModal: toggle,
        });
        this.BulkEdit_ResetSetting();
    }
    BulkEdit_SettingModalComponent = () => {
        let components = [];
        const setting = this.state.BulkEdit_Setting;
        const setting_checked = this.state.BulkEdit_Setting_checked;

        //Display Order.
        const displayOrder_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.DisplayOrder);
        const displayOrder_setting_checked = setting_checked[displayOrder_setting_index];
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (displayOrder_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="form-group width-max">
                <label>Display Order</label>
                <input type="number" className="form-control" style={{ width: '100%' }}
                    value={CheckObjectNumber(setting[displayOrder_setting_index], 'value')}
                    onChange={(e) => this.BulkEdit_SetSetting(SettingInput.DisplayOrder, e.target.value)}
                ></input>
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, displayOrder_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : displayOrder_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        //Active.
        const active_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.Active);
        const active_setting_checked = setting_checked[active_setting_index];
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (active_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="form-group width-max">
                <label>Active</label>
                <input type="checkbox" className="form-check form-check-input"
                    onClick={(e) => this.BulkEdit_SetSetting(SettingInput.Active, e.currentTarget.checked)}
                    checked={CheckObjectBoolean(setting[active_setting_index], 'value')}
                    readOnly={true}
                ></input>
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, active_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : active_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        //Effective Date Start.
        const effectiveDateStart_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.EffectiveDateStart);
        const effectiveDateStart_setting_checked = setting_checked[effectiveDateStart_setting_index];
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (effectiveDateStart_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="form-group width-max">
                <label>Effective Date Start</label>
                <input type="datetime-local" className="form-control" style={{ width: '100%' }}
                    onChange={(e) => this.BulkEdit_SetSetting(SettingInput.EffectiveDateStart, e.target.value)}
                    // value={moment.utc(CheckObjectStringEmpty(setting[effectiveDateStart_setting_index], 'value', yearStart)).local().format('YYYY-MM-DD HH:mm')}
                    value={moment.utc(CheckObjectStringEmpty(setting[effectiveDateStart_setting_index], 'value', yearStart), 'YYYY-MM-DDTHH:mm').local().format('YYYY-MM-DDTHH:mm')}
                ></input>
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, effectiveDateStart_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : effectiveDateStart_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        //Effective Date End.
        const effectiveDateEnd_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.EffectiveDateEnd);
        const effectiveDateEnd_setting_checked = setting_checked[effectiveDateEnd_setting_index];
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (effectiveDateEnd_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="form-group width-max">
                <label>Effective Date End</label>
                <input type="datetime-local" className="form-control" style={{ width: '100%' }}
                    onChange={(e) => this.BulkEdit_SetSetting(SettingInput.EffectiveDateEnd, e.target.value)}
                    // value={moment.utc(CheckObjectStringEmpty(setting[effectiveDateEnd_setting_index], 'value', yearEnd)).local().format('YYYY-MM-DD HH:mm')}
                    value={moment.utc(CheckObjectStringEmpty(setting[effectiveDateEnd_setting_index], 'value', yearEnd), 'YYYY-MM-DDTHH:mm').local().format('YYYY-MM-DDTHH:mm')}
                ></input>
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, effectiveDateEnd_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : effectiveDateEnd_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        //Remark.
        const remark_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.Remark);
        const remark_setting_checked = setting_checked[remark_setting_index];
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (remark_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="form-group width-max">
                <label>Remark</label>
                <input type="text" className="form-control" style={{ width: '100%' }}
                    value={CheckObjectStringEmpty(setting[remark_setting_index], 'value')}
                    onChange={(e) => this.BulkEdit_SetSetting(SettingInput.Remark, e.target.value)}
                ></input>
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, remark_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : remark_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        //Display.
        const display_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.Display);
        const display_setting_checked = setting_checked[display_setting_index];
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (display_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="form-group width-max">
                <label>Display</label>
                <input type="checkbox" className="form-check form-check-input"
                    onClick={(e) => this.BulkEdit_SetSetting(SettingInput.Display, e.currentTarget.checked)}
                    checked={CheckObjectBoolean(setting[display_setting_index], 'value')}
                    readOnly={true}
                ></input>
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, display_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : display_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        return (components);
    }
    BulkEdit_SetSetting = (property = SettingInput.None, value = null) => {
        let setting = this.state.BulkEdit_Setting;
        let setting_checked = this.state.BulkEdit_Setting_checked;
        const setting_index = property === SettingInput.CheckedItem ? 999 : Object.values(BulkSetting).indexOf(property);
        if (this.state.isDevMode)
            console.log(`BulkEdit_SetSetting (setting_index) = ` + JSON.stringify(setting_index));
        if (property === SettingInput.None || setting === null || value === null || setting_index < 0)
            return null;

        switch (property) {
            case SettingInput.DisplayOrder: setting[setting_index].value = CheckNumber(value); break;
            case SettingInput.Active: setting[setting_index].value = CheckBoolean(value); break;
            case SettingInput.EffectiveDateStart: setting[setting_index].value = moment(value, 'YYYY-MM-DDTHH:mm').utc().format('YYYY-MM-DDTHH:mm'); break;
            case SettingInput.EffectiveDateEnd: setting[setting_index].value = moment(value, 'YYYY-MM-DDTHH:mm').utc().format('YYYY-MM-DDTHH:mm'); break;
            case SettingInput.Remark: setting[setting_index].value = String(value); break;
            case SettingInput.Display: setting[setting_index].value = CheckBoolean(value); break;
            case SettingInput.CheckedItem:
                setting_checked[value] = !setting_checked[value];
                if (this.state.isDevMode)
                    console.log(`BulkEdit_SetSetting (checkedItem) = ` + JSON.stringify(setting_checked));
                break;
            default: break;
        }
        this.setState({
            BulkEdit_Setting: setting,
            BulkEdit_Setting_checked: setting_checked,
        }, () => {
            if (this.state.isDevMode) {
                console.log(`BulkEdit_SetSetting (${property}) = ` + JSON.stringify(value));
                console.log(`BulkEdit_SetSetting (setting) = ` + JSON.stringify(setting));
            }
        });
    }
    BulkEdit_ResetSetting = () => {
        this.setState({
            BulkEdit_Setting: [
                { key: BulkSetting.Remark, value: '' },
                { key: BulkSetting.AlwaysOnTop, value: false },
                { key: BulkSetting.DisplayOrder, value: 0 },
                { key: BulkSetting.Display, value: false },
                { key: BulkSetting.Active, value: false },
                { key: BulkSetting.IsPrivate, value: false },
                { key: BulkSetting.EffectiveDateStart, value: yearStart },
                { key: BulkSetting.EffectiveDateEnd, value: yearEnd },
            ],
            BulkEdit_Setting_checked: Object.keys(BulkSetting).map(() => { return false; }),
        });
    }
    BulkEdit_ToggleRemoveSettingModal = () => {
        this.setState({
            BulkEdit_Toggle_RemoveSettingModal: !this.state.BulkEdit_Toggle_RemoveSettingModal,
        });
    }
    BulkEdit_CUD_Setting_ViaApi = async (remove = false) => {

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const { textTitle, textBody, text, urlParam } = GetPostParams({ id: 999 }, remove);
        this.setState({
            isUpdating: true,
        });
        useAppService.getState().setModal('', `${textTitle} setting...`, null, AlertMode.Loading);

        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Organizer/Group/BulkEdit/${urlParam}`;
        // Api/LearningCentre/Organizer/Group/Group/{Update|Remove}

        let setting_params = [];
        const setting_keys = Object.values(BulkSetting);
        if (remove === false) {
            for (let i = 0; i < this.state.BulkEdit_Setting_checked.length; i++) {
                if (this.state.BulkEdit_Setting_checked[i])
                    setting_params.push({ key: setting_keys[i], value: this.state.BulkEdit_Setting[i].value });
                else
                    setting_params.push({ key: setting_keys[i], value: null });
            }
        }

        let groupIds = [];
        const list = this.state.List;
        const checkedItems = this.state.BulkEdit_CheckedItems;
        for (let n = 0; n < list.length; n++) {
            if (checkedItems[n])
                groupIds.push(CheckObjectNumber(list[n], SettingInput.Id));
        }

        const json = JSON.stringify({
            OrganizerId: organizerId,
            AuthorId: authorId,

            BulkGroupIds: groupIds.join(','),
            Remark: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.Remark)].value,
            // GroupId: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.GroupId)].value,
            DisplayOrder: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.DisplayOrder)].value,
            Active: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.Active)].value,
            EffectiveDateStart: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.EffectiveDateStart)].value,
            EffectiveDateEnd: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.EffectiveDateEnd)].value,
            Display: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.Display)].value,

            Remove: remove,
        });
        if (this.state.isDevMode)
            console.log(`BulkEdit_CUD_Setting_ViaApi (${text}) (postData) =\n` + json);

        // let data = null;
        let success = false;
        let msg = '';
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: json,
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('BulkEdit_CUD_Setting_ViaApi =\n' + JSON.stringify(data));

                success = CheckObjectBoolean(data, 'success');
                // data = CheckObjectNullValue(data, 'data');

                if (!success)
                    msg = CheckObjectStringEmpty(data, 'message');
            })
            .catch(error => {
                msg = error.message;
                if (this.state.isDevMode)
                    console.log('Error', `api - ${text} (error)\n` + error.message);
            });

        if (success) {
            await this.LoadList_ViaApi();
            this.BulkEdit_ToggleEditSettingModal();
            await Delay(500);
            useAppService.getState().setModal();
            await Delay(500);
            useAppService.getState().setModal('', `${settingTitle}(s) have been ${textBody}.`);
        }
        else {
            useAppService.getState().setModal('', `Failed to ${text} ${settingTitle.toLowerCase()}(s).<br /><br />` + msg);
        }
        this.setState({
            isUpdating: false,
        });
    }
    //#endregion === bulk edit ===

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        return (<>
            <table className="table page-header">
                <tbody>
                    <tr>
                        <td className="left">
                            <h5>{settingTitle}</h5>
                            <Button variant="primary"
                                onClick={() => this.BulkEdit_ToggleEditSettingModal()}
                                disabled={this.state.BulkEdit_CheckedItems.length === 0 ? true : (this.state.BulkEdit_CheckedItems.includes(true) ? false : true)}
                            >Bulk Edit</Button>
                            <button
                                type="button"
                                className="btn-link"
                                onClick={() => this.LoadList_ViaApi()}
                                title="Refresh Media list"
                            ><i className="fa fa-refresh" title="Refresh Media list"></i></button>
                        </td>
                        <td className="center"></td>
                        <td className="right">
                            <Button variant="primary" onClick={() => this.ToggleEditSettingModal(-1, true)} disabled={this.state.PA_Update === false}>Add New</Button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='table table-hover table-bordered tbStyle' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
                <thead>
                    <tr>
                        <th width='50' className="pointer" onClick={() => this.ToggleItemChecked(-1, this.state.BulkEdit_CheckedItems.findIndex(x => x === false) < 0)}>
                            <input type='checkbox' className='pointer' readOnly={true}
                                checked={this.state.BulkEdit_CheckedItems.length > 0 ? this.state.BulkEdit_CheckedItems.indexOf(false) < 0 : false}></input>
                        </th>
                        <th width='50'>#</th>
                        <th width='125' className="left">Platform</th>
                        <th className="left" colSpan={3}>Name</th>
                        <th width='125'>Remark</th>
                        <th width='115'>Display Order</th>
                        <th width='115'>Active</th>
                        <th width='125'>Display</th>
                        <th width='75'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.isLoading && !this.state.IsListLoaded ?
                            <tr><td colSpan='15' height={63}><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></td></tr>
                            : this.state.List.length > 0 ?
                                this.ListComponents()
                                : <tr><td colSpan='15' align='center'>list is empty</td></tr>
                    }
                    {
                        // this.state.List.length === 0 ? null :
                        //     PagingComponents(15, this.state.TotalRows, this.state.PageIndex, this.state.PageSize, this.CallbackFunctionForPagingComponents_PageSize, this.CallbackFunctionForPagingComponents_PageIndex)
                    }
                </tbody>
            </table>

            {/* Setting - Edit / Update - Modal */}
            <Modal show={this.state.Toggle_EditSettingModal}
                onHide={() => this.ToggleEditSettingModal()}
                centered
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>{this.state.SettingModal_Create ? `New ${settingTitle}` : 'Edit :: ' + CheckObjectStringEmpty(this.state.SettingModal, 'name')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.SettingModalComponent()}
                </Modal.Body>
                <Modal.Footer>
                    {
                        this.state.SettingModal_Create ? null :
                            <Button variant="danger"
                                onClick={() => this.ToggleRemoveSettingModal(this.state.SettingModal)}
                                style={{ position: "absolute", left: 0, marginLeft: 15 }}
                                // disabled={this.state.isUpdating || (this.state.IsSuperAdmin ? false : this.state.PA_Delete === false || CheckObjectNumber(this.state.SettingModal, 'authorId') === 1)}
                                disabled={this.state.isUpdating || !(this.state.IsSuperAdmin || this.state.IsMasterAdmin) || this.state.PA_Delete === false ||
                                    (CheckObjectNumber(this.state.SettingModal, SettingInput.OrganizerId) > 0 && CheckObjectNumber(this.state.SettingModal, SettingInput.OrganizerId) !== this.state.OrganizerId)}
                            >Remove</Button>
                    }
                    <Button variant="secondary" onClick={() => this.ToggleEditSettingModal()} disabled={this.state.isUpdating}>Cancel</Button>
                    <Button variant="secondary" onClick={() => this.ResetSetting()} disabled={this.state.isUpdating}>Reset</Button>
                    {
                        this.state.SettingModal_Create ?
                            <Button variant="primary"
                                onClick={() => this.CUD_Setting_ViaApi()}
                                disabled={this.state.isUpdating || this.state.PA_Create === false}
                            >Create</Button>
                            :
                            <Button variant="primary"
                                onClick={() => this.CUD_Setting_ViaApi()}
                                disabled={this.state.isUpdating || this.state.PA_Update === false}
                            >Update</Button>
                    }
                </Modal.Footer>
            </Modal >

            {/* Setting - Remove (Confirm) - Modal */}
            < Modal show={this.state.Toggle_RemoveSettingModal}
                onHide={() => this.ToggleRemoveSettingModal()
                }
                centered
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Remove :: {CheckObjectStringEmpty(this.state.SettingModal, 'name')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you sure you want to remove this {settingTitle.toLowerCase()} ?</p>
                    <p>If so, click confirm to continue.</p>
                    <p><i><b>Caution:</b> Once removed, {settingTitle.toLowerCase()} will not be able to recover back.</i></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ToggleRemoveSettingModal()}>Cancel</Button>
                    <Button variant="primary"
                        // onClick={() => this.RemoveSetting_ViaApi()}
                        onClick={() => this.CUD_Setting_ViaApi(true)}
                        disabled={this.state.isUpdating}
                    >Confirm</Button>
                </Modal.Footer>
            </Modal >

            {/* Setting - (BULK) Edit / Update - Modal */}
            < Modal show={this.state.BulkEdit_Toggle_EditSettingModal}
                onHide={() => this.BulkEdit_ToggleEditSettingModal()}
                centered
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Bulk Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body className="setting-bulk-parent">
                    {this.BulkEdit_SettingModalComponent()}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger"
                        onClick={() => this.BulkEdit_ToggleRemoveSettingModal()}
                        style={{ position: "absolute", left: 0, marginLeft: 15 }}
                        disabled={this.state.isUpdating || (this.state.IsSuperAdmin ? false : this.state.PA_Delete === false)}
                    >Bulk Remove</Button>
                    <Button variant="secondary" onClick={() => this.BulkEdit_ToggleEditSettingModal()} disabled={this.state.isUpdating}>Cancel</Button>
                    <Button variant="secondary" onClick={() => this.BulkEdit_ResetSetting()} disabled={this.state.isUpdating}>Reset</Button>
                    <Button variant="primary" onClick={() => this.BulkEdit_CUD_Setting_ViaApi()} disabled={this.state.isUpdating}>Bulk Update</Button>
                </Modal.Footer>
            </Modal >

            {/* Setting - (BULK) Remove (Confirm) - Modal */}
            < Modal show={this.state.BulkEdit_Toggle_RemoveSettingModal}
                onHide={() => this.BulkEdit_ToggleRemoveSettingModal()}
                centered
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Bulk Remove</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you sure you want to remove these items ?</p>
                    <p>If so, click confirm to continue.</p>
                    <p><i><b>Caution:</b> Once removed, they will not be able to recover back.</i></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.BulkEdit_ToggleRemoveSettingModal()}>Cancel</Button>
                    <Button variant="primary"
                        onClick={() => this.BulkEdit_CUD_Setting_ViaApi(true)}
                        disabled={this.state.isUpdating}
                    >Confirm</Button>
                </Modal.Footer>
            </Modal >

            {/* Preview File - Modal */}
            < Modal show={this.state.PreviewFileModal_Toggle}
                onHide={() => this.TogglePreviewFileModal()}
                centered
                size={this.state.PreviewFile_MediaType === MediaTypeOptions[1].value ? "" : "lg"}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Preview Media</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center' }}>{this.state.PreviewFileModal_Component}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.TogglePreviewFileModal()}>Close</Button>
                </Modal.Footer>
            </Modal >
        </>);
    }
}