import React from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { Button, Modal, ProgressBar } from "react-bootstrap";
import ReactSelect from "react-select";
import moment from "moment";

import { GlobalSetting, InputType, LayoutScreen, Menu } from "../utilities/GlobalSetting";
import { useGlobal } from "../utilities/GlobalVariables";
import { useAppService } from "../services/AppService";
import { CapitalizeJsonKeys, CheckBoolean, CheckNullValue, CheckNumber, CheckObjectBoolean, CheckObjectNullValue, CheckObjectNumber, CheckObjectStringEmpty, CheckStringEmpty, DecapitalizeJsonKeys, Delay, DoNothing, GetInputComponent, GetPostParams, GetPropIds, PagingComponents, PreviewCategoryListItemComponents, RandomId, SetTempTarget, ToBase64 } from "../utilities/GlobalFunctions";
import { CreateOrganizer_ViaAPI, GetAllOrganizers_ViaAPI, UpdateOrganizer_ViaAPI, UploadPictureFileForOrganizer_ViaAPI } from "../services/OrganizerService";
import { SearchGovernmentSchools_ViaAPI } from "../services/GovernmentSchoolService";
import { AlertMode } from "./AlertComponent";

const DisplaySetting = {
    SchoolCode: 'School Code',
}

const SettingInput = {
    //ordering matters.
    CategoryList: 'CategoryList',
    Active: 'Active',
    CheckedItem: 'CheckedItem',
    None: 'None',   //must at the bottom.
};

const BulkSetting = {
    //ordering matters.
    CategoryList: 'CategoryList',
    Active: 'Active',
};

const SearchOptions = {
    None: 'None',
    SearchList_BySchoolCode: 'SearchList_BySchoolCode',
    SearchList_BySchoolName: 'SearchList_BySchoolName',
    SearchList_ByNationalState: 'SearchList_ByNationalState',
    SearchList_ByDistrictArea: 'SearchList_ByDistrictArea',
};

//2025.03.04
const yearStart = moment(moment.utc().format('YYYY-01-01T00:00')).format('YYYY-MM-DDTHH:mm');
const yearEnd = moment(yearStart, 'YYYY-MM-DDTHH:mm').add(1, 'year').add(-1, 'minute').format('YYYY-MM-DDTHH:mm');

export default class ManageOrganizerScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({

        isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        redirect: false,
        redirectLink: '/',
        isLoading: false,
        isUpdating: false,

        screen: LayoutScreen.None,
        IsSuperAdmin: false,
        IsMasterAdmin: false,
        IsAuthor: false,

        IsLoading: false,
        IsListLoaded: false,
        List: [],
        PageIndex: 0,
        PageSize: GlobalSetting.PageSize,
        TotalRows: 0,

        SearchSchoolByParamsModal_Toggle: false,
        SearchSchoolByParams_Processing: false,
        SearchBySchoolCode: '',
        SearchBySchoolName: '',
        SearchByCountryState: '',
        SearchByDistrictArea: '',
        SearchSchoolResultModal_Toggle: false,
        SchoolList: [],
        SelectedSchool: null,

        EditOrganizerModal_Toggle: false,
        EditOrganizerIndex: -1,
        EditOrganizerObject: null,
        EditOrganizerCache: null,
        EditOrganizerNew: false,
        EditOrganizer_Processing: false,
        AttachedPictureFile: null,

        //2024.12.12
        CategoryOptions: [],
        EditOrganizerCategoryModal_Toggle: false,

        //2024.12.12
        //Bulk Edit.
        BulkEdit_CheckedItems: [],
        BulkEdit_Setting: Object.keys(BulkSetting).map((data, key) => { return { key: data, value: null }; }),
        BulkEdit_Setting_checked: Object.keys(BulkSetting).map(() => { return false; }),
        BulkEdit_Toggle_EditSettingModal: false,
        BulkEdit_Setting_CategoryItems: [],

        //2024.12.12
        //Display Setting.
        DisplaySettingOptions: [],

        //2025.02.26
        //Search.
        ShowSearchListModal: false,
        //Search Params.
        SearchList_BySchoolCode: '',
        SearchList_BySchoolName: '',
        SearchList_ByNationalState: null,
        SearchList_ByDistrictArea: null,
        SearchList_KeepSearchParams: false,
        //Search Options.
        NationalStateOptions: [],
        DistrictAreaOptions: [],
    });

    componentWillUnmount = () => { }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        useGlobal.getState().setScreen(LayoutScreen.ManageOrganizer);
        this.setState({
            screen: CheckStringEmpty(useGlobal.getState().screen, LayoutScreen.Dashboard),
            DisplaySettingOptions: Object.keys(DisplaySetting).map((data, key) => { return true; }),
        });
        // await Delay(200);
        // await this.CheckPermissions();      //2024.12.09
        // if (this.state.IsAuthor === false || this.state.PA_View === false)
        //     return null;
        this.LoadList_ViaApi();
    }

    //#region === List ===
    //2024.12.09
    CheckPermissions = async () => {
        const user = useGlobal.getState().user;
        if (user === null)
            return null;
        const { organizerId, uid } = GetPropIds(user);
        this.setState({
            // PA_View: PermissionAccess(LayoutScreen.ManageOrganizer, PermissionAccessType.View),
            // PA_Search: PermissionAccess(LayoutScreen.ManageOrganizer, PermissionAccessType.Search),
            // PA_Create: PermissionAccess(LayoutScreen.ManageOrganizer, PermissionAccessType.Create),
            // PA_Update: PermissionAccess(LayoutScreen.ManageOrganizer, PermissionAccessType.Update),
            // PA_Delete: PermissionAccess(LayoutScreen.ManageOrganizer, PermissionAccessType.Delete),
            // PA_Upload: PermissionAccess(LayoutScreen.ManageOrganizer, PermissionAccessType.Upload),
            // PA_Download: PermissionAccess(LayoutScreen.ManageOrganizer, PermissionAccessType.Download),

            PageSize: CheckNumber(localStorage.getItem(`ManageOrganizer_PageSize_${uid}_${organizerId}`), GlobalSetting.PageSize),

            IsSuperAdmin: CheckBoolean(useGlobal.getState().isSuperAdmin),
            IsMasterAdmin: CheckBoolean(useGlobal.getState().isMasterAdmin),
            IsAuthor: CheckBoolean(useGlobal.getState().isAuthor),
        });
        await Delay(0);
        const allow = this.state.IsSuperAdmin || this.state.IsMasterAdmin;
        this.setState({
            PA_View: allow,
            PA_Search: allow,
            PA_Create: allow,
            PA_Update: allow,
            PA_Delete: allow,
            PA_Upload: allow,
            PA_Download: allow,
        });
        await Delay(0);
        if (!this.state.PA_View) {
            this.setState({
                redirectLink: Menu['dashboard'].Link,
                redirect: true,
            });
            return null;
        }
    }
    //get all organizers.
    LoadList_ViaApi = async (resetPage = false) => {

        await this.CheckPermissions();

        const { authorId, organizerId, uid } = GetPropIds(useGlobal.getState().user);
        if (resetPage) {
            const pageSize = CheckNumber(localStorage.getItem(`ManageOrganizer_PageSize_${uid}_${organizerId}`), GlobalSetting.PageSize);
            this.setState({
                PageIndex: 0,
                PageSize: pageSize < GlobalSetting.PageSize ? GlobalSetting.PageSize : pageSize,
                // SearchList_KeepSearchParams: false,
            });
            if (this.state.SearchList_KeepSearchParams === false)
                this.ResetSearchListParams();
        }

        this.setState({
            IsLoading: true,
            IsListLoaded: false,
            List: [],
            TotalRows: 0,
        });
        const hasSchoolCode = this.state.DisplaySettingOptions[Object.values(DisplaySetting).indexOf(DisplaySetting.SchoolCode)];
        const { success, errorMessage, data } = await GetAllOrganizers_ViaAPI(authorId, 0,
            hasSchoolCode,
            CheckStringEmpty(this.state.SearchList_BySchoolCode),
            CheckStringEmpty(this.state.SearchList_BySchoolName),
            CheckStringEmpty(this.state.SearchList_ByNationalState?.value),
            CheckStringEmpty(this.state.SearchList_ByDistrictArea?.value),
            '', '', this.state.PageIndex, this.state.PageSize);
        if (!success) {
            useAppService.getState().setModal('', 'Failed to retrieve Organizer list.<br /><br />Error:' + errorMessage);
        }
        else {
            let _List = [];
            if (Array.isArray(data.list)) {
                data.list.forEach((item, key) => {
                    _List.push(this.PopulateOrganizerModal(CapitalizeJsonKeys(item)));
                });
                // _List.sort((a, b) => a.Id - b.Id);
                _List.sort((a, b) => String(a.DisplayName).localeCompare(String(b.DisplayName)));
                // _List.sort((a, b) => {
                //     if (a.DisplayName < b.DisplayName) { return -1; }
                //     if (a.DisplayName > b.DisplayName) { return 1; }
                //     return 0;
                // });
            }
            const totalRows = CheckNumber(data.totalCount);
            // console.log('totalRows = ' + data.totalCount);

            //2024.12.12
            let _categoryOptions = [];
            if (Array.isArray(data.categoryOptions)) {
                _categoryOptions = CapitalizeJsonKeys(data.categoryOptions);
                _categoryOptions.sort((a, b) => a.Id - b.Id);
                if (this.state.isDevMode)
                    console.log('CategoryOptions (From List) \n' + JSON.stringify(_categoryOptions));
            }

            this.setState({
                List: _List,
                CategoryOptions: _categoryOptions,
                BulkEdit_CheckedItems: Array.isArray(_List) ? _List.map((data, key) => { return false; }) : [],
                TotalRows: totalRows,
            });
        }
        this.setState({
            IsLoading: false,
            IsListLoaded: true,
        });
    }
    ListComponents = () => {
        if (this.state.List.length > 0) {
            let listItems = [];

            //2024.12.12
            const checkedItems = this.state.BulkEdit_CheckedItems;
            const displaySettings = Object.keys(DisplaySetting);
            const displayOption = this.state.DisplaySettingOptions;
            let counter = 0;

            const categoryOptions = DecapitalizeJsonKeys(JSON.parse(JSON.stringify(this.state.CategoryOptions)));
            this.state.List.map((data, key) => {
                //2024.12.12
                let hideOrganizer = false;
                for (let d = 0; d < displaySettings.length; d++) {
                    if (displayOption[d]) {
                        hideOrganizer = CheckObjectStringEmpty(data, displaySettings[d]) === '';
                    }
                    if (hideOrganizer)
                        break;
                }
                if (!hideOrganizer)
                    counter++;

                const decap_data = DecapitalizeJsonKeys(JSON.parse(JSON.stringify(data)));
                return listItems.push(
                    <tr key={'list-item-' + key}
                        className={`${checkedItems[key] ? 'setting-classroom-tr-class' : ''}`}
                        hidden={hideOrganizer}
                    >
                        <td align='center' className="pointer" onClick={() => this.ToggleItemChecked(key)}>
                            {/* <span>{key + 1}</span> */}
                            <span>{counter + this.state.PageIndex}</span>
                            <input type='checkbox' className="form-check form-check-input pointer"
                                checked={checkedItems[key]} readOnly={true}
                            ></input>
                        </td>
                        <td>
                            <b>{CheckObjectStringEmpty(data, 'DisplayName', '-')}</b><br />
                            <span style={{ fontSize: 12, color: 'gray' }}>({CheckObjectStringEmpty(data, 'Name', '-')})</span><br />
                            <span style={{ fontSize: 12, color: 'gray' }}>({CheckObjectStringEmpty(data, 'IdentityGuid', '-')})</span>
                        </td>
                        <td>
                            {CheckObjectStringEmpty(data, 'SchoolName', '-')}
                            {CheckObjectNullValue(data, 'SchoolCode') === null ? <></> :
                                <><br />School Code: <b>{CheckObjectStringEmpty(data, 'SchoolCode', '-')}</b></>}
                        </td>
                        <td>
                            {/* {this.PreviewOrganizerCategoryComponent(data)} */}
                            {/* {PreviewCategoryListItemComponents(data, categoryOptions, true)} */}
                            <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>{PreviewCategoryListItemComponents(decap_data, categoryOptions, true)}</div>
                        </td>
                        <td>
                            <div dangerouslySetInnerHTML={{
                                __html: this.FormatSchoolCompleteName(data)
                            }}></div>
                        </td>
                        <td className="icon-color" align="center"><i className={"fa " + (CheckObjectBoolean(data, 'Active') ? 'fa-check-circle blue' : 'fa-times-circle red') + ' fs20'}></i></td>
                        <td>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: 5, justifyContent: 'center' }}>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => this.Toggle_EditOrganizerModal(key)}
                                    style={{ width: '100%' }}
                                    disabled={this.state.IsLoading}
                                >Edit</button>
                                <button
                                    type="button"
                                    className="btn btn-info"
                                    onClick={() => this.Toggle_EditOrganizerCategoryModal(key)}
                                    style={{ width: '100%' }}
                                    disabled={this.state.IsLoading}
                                >Category</button>
                            </div>
                        </td>
                    </tr>);
            });
            return listItems;
        }
        return this.state.isLoading || !this.state.IsListLoaded ?
            <tr><td colSpan='15' height={63}><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></td></tr>
            : <tr><td colSpan='15' align='center'>list is empty</td></tr>;
    }
    PopulateOrganizerModal = (modal = null) => {
        //2024.12.12
        let categoryList = CheckObjectNullValue(modal, 'CategoryList', []);
        if (Array.isArray(categoryList) && categoryList.length > 0)
            categoryList.sort((a, b) => a.CategoryId - b.CategoryId);
        else
            categoryList = [];

        return {
            Id: CheckObjectNumber(modal, 'Id'),
            Name: CheckObjectStringEmpty(modal, 'Name'),
            DisplayName: CheckObjectStringEmpty(modal, 'DisplayName'),
            IdentityGuid: CheckObjectStringEmpty(modal, 'IdentityGuid'),
            Active: CheckObjectBoolean(modal, 'Active'),
            SchoolCode: CheckObjectStringEmpty(modal, 'SchoolCode'),
            NationalState: CheckObjectStringEmpty(modal, 'NationalState'),
            DistrictArea: CheckObjectStringEmpty(modal, 'DistrictArea'),
            SchoolName: CheckObjectStringEmpty(modal, 'SchoolName'),
            SchoolCompleteName: CheckObjectStringEmpty(modal, 'SchoolCompleteName'),
            SchoolAddress: CheckObjectStringEmpty(modal, 'SchoolAddress'),
            LogoId: CheckObjectNumber(modal, 'LogoId'),
            LogoUrl: CheckObjectStringEmpty(modal, 'LogoUrl'),
            IsDemo: CheckObjectBoolean(modal, 'IsDemo'),                //2024.12.09
            CategoryList: categoryList,                                 //2024.12.12

            LinkToCurrentAuthor: CheckObjectBoolean(modal, 'LinkToCurrentAuthor'),      //2025.02.18
        };
    }
    FormatSchoolCompleteName = (data = null) => {
        if (data === null)
            return '';

        if (CheckObjectNullValue(data, 'SchoolCode') === null)
            return '-';

        const name = CheckObjectStringEmpty(data, 'SchoolCompleteName');
        if (name.includes(' - ')) {
            let array = name.split(' - ');
            if (Array.isArray(array) && array.length > 0) {
                array.shift();
                array[0] = '<span style="font-weight: 400;">' + array[0] + '</span>';
                return array.join('<br />');
            }
        }
        return '-';
    }
    //#region === Paging Components
    CallbackFunctionForPagingComponents_PageSize = (pageSize = GlobalSetting.PageSize) => {
        this.setState({
            PageSize: pageSize < GlobalSetting.PageSize ? GlobalSetting.PageSize : pageSize,
        }, () => {
            const { uid, organizerId } = GetPropIds(useGlobal.getState().user);
            localStorage.setItem(`ManageOrganizer_PageSize_${uid}_${organizerId}`, this.state.PageSize);
            setTimeout(() => {
                this.LoadList_ViaApi();
            }, 500);
        });
    }
    CallbackFunctionForPagingComponents_PageIndex = (pageIndex = 0) => {
        this.setState({
            PageIndex: pageIndex,
        }, () => {
            setTimeout(() => {
                this.LoadList_ViaApi();
            }, 500);
        });
    }
    //#endregion === Paging Components
    //#endregion === List ===

    //#region === Organizer - Edit / New ===
    //create new organizer.
    CreateOrganizer = async () => {
        if (this.state.PA_Create === false) {
            useAppService.getState().setModal('New Organizer', 'Invalid permission.');
            return null;
        }
        if (this.state.EditOrganizerObject === null) {
            useAppService.getState().setModal('Creation Failed', 'Invalid organizer object.');
            return null;
        }
        this.setState({
            EditOrganizer_Processing: true,
        });
        useAppService.getState().setModal('', 'creating...', null, AlertMode.Loading);
        const { authorId } = GetPropIds(useGlobal.getState().user);
        const { success, errorMessage, data } = await CreateOrganizer_ViaAPI(authorId, this.state.EditOrganizerObject);
        if (!success) {
            useAppService.getState().setModal('', 'Failed to create new Organizer.<br /><br />Error:' + errorMessage);
        }
        else {
            this.setState({
                List: data,
            });
            useAppService.getState().setModal('', 'Organizer has been created.');
            await this.Toggle_EditOrganizerModal();
            this.LoadList_ViaApi();
        }
        this.setState({
            EditOrganizer_Processing: false,
        });
    }
    //update or remove.
    UpdateOrganizer = async (remove = false) => {
        if (this.state.EditOrganizerObject === null) {
            useAppService.getState().setModal((remove ? 'Removal' : 'Update') + ' Failed', 'Invalid organizer object.');
            return null;
        }
        this.setState({
            EditOrganizer_Processing: true,
        });
        useAppService.getState().setModal('', 'updating...', null, AlertMode.Loading);
        const { authorId } = GetPropIds(useGlobal.getState().user);
        const { success, errorMessage, data } = await UpdateOrganizer_ViaAPI(authorId, remove, this.state.EditOrganizerObject);
        if (!success) {
            useAppService.getState().setModal('', 'Failed to update Organizer.<br /><br />Error:' + errorMessage);
        }
        else {
            let list = this.state.List;
            const t_data = this.PopulateOrganizerModal(data);
            const findIndex = list.findIndex(x => x.Id === CheckObjectNumber(t_data, 'Id'));
            if (findIndex > -1)
                list[findIndex] = t_data;
            this.setState({
                List: list,
            });
            useAppService.getState().setModal('', 'Organizer has been ' + (remove ? 'removed' : 'updated') + '.');
        }
        this.setState({
            EditOrganizer_Processing: false,
        });
    }
    Toggle_EditOrganizerModal = async (index = -1, create = false) => {
        if (this.state.PA_Update === false) {
            useAppService.getState().setModal('Edit Organizer', 'Invalid permission.');
            return null;
        }
        const toggle = !this.state.EditOrganizerModal_Toggle;
        if (!toggle) {
            this.setState({
                EditOrganizerModal_Toggle: toggle,
            });
            await Delay(200);
        }
        this.setState({
            EditOrganizerIndex: index,
            EditOrganizerNew: create,
            AttachedPictureFile: null,
            SelectedCategories: [],         //2024.12.12
        }, async () => {
            let obj = null;
            if (create)
                obj = this.PopulateOrganizerModal();
            else
                obj = !toggle || index < 0 ? null : this.PopulateOrganizerModal(JSON.parse(JSON.stringify(this.state.List[index])));

            await Delay(0);
            SetTempTarget(obj);
            await Delay(0);

            this.setState({
                EditOrganizerObject: obj,
                EditOrganizerCache: obj === null ? null : JSON.parse(JSON.stringify(obj)),
            });
        });
        if (toggle) {
            await Delay(200);
            this.setState({
                EditOrganizerModal_Toggle: toggle,
            });
        }
    }
    ResetEditOrganizerObject = () => {
        this.setState({
            EditOrganizerObject: this.PopulateOrganizerModal(this.state.EditOrganizerCache),
        });
    }
    SaveOrganizerObject = (obj = null) => {
        this.setState({
            EditOrganizerObject: obj,
        });
    }
    EditOrganizerComponents = () => {
        const organizer = this.state.EditOrganizerObject;
        if (organizer === null)
            return null;

        let components = [];

        //Name.
        components.push(<div className="form-group">
            <label>Name</label>
            {
                GetInputComponent(InputType.Text, null,
                    organizer, 'Name', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        //DisplayName.
        components.push(<div className="form-group">
            <label>Display Name</label>
            {
                GetInputComponent(InputType.Text, null,
                    organizer, 'DisplayName', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        //IdentityGuid.
        components.push(<div className="form-group">
            <label>GUID</label>
            {
                GetInputComponent(InputType.Text, null,
                    organizer, 'IdentityGuid', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        //2024.12.12
        //Category.
        components.push(<div className="form-group">
            <label>Category</label>
            {this.EditOrganizerCategoryComponent()}
        </div>);

        //NationalState.
        components.push(<div className="form-group">
            <label>Country State</label>
            {
                GetInputComponent(InputType.Text, null,
                    organizer, 'NationalState', null, '', this.state.locale,
                    this.SaveOrganizerObject, null,
                    true,   //this.state.EditOrganizer_Processing
                )
            }
        </div>);

        //DistrictArea.
        components.push(<div className="form-group">
            <label>District Area</label>
            {
                GetInputComponent(InputType.Text, null,
                    organizer, 'DistrictArea', null, '', this.state.locale,
                    this.SaveOrganizerObject, null,
                    true,    //this.state.EditOrganizer_Processing
                )
            }
        </div>);

        //SchoolCode.
        components.push(<div className="form-group">
            <label>School Code</label>
            {
                GetInputComponent(InputType.Text, null,
                    organizer, 'SchoolCode', null, '', this.state.locale,
                    this.SaveOrganizerObject, null,
                    true,    //this.state.EditOrganizer_Processing
                )
            }
        </div>);

        //SchoolName.
        components.push(<div className="form-group">
            <label>School Name</label>
            {
                GetInputComponent(InputType.Text, null,
                    organizer, 'SchoolName', null, '', this.state.locale,
                    this.SaveOrganizerObject, null,
                    true,    //this.state.EditOrganizer_Processing
                )
            }
        </div>);

        //SchoolCompleteName.
        components.push(<div className="form-group">
            <label>Complete School Name</label>
            <textarea name="SchoolCompleteName" class="form-control" type="text" disabled={true}
                placeholder={CheckObjectStringEmpty(organizer, 'SchoolCompleteName')}
                value={CheckObjectStringEmpty(organizer, 'SchoolCompleteName')}
                style={{ minHeight: 'fit-content', resize: 'none', height: 'fit-content', overflowY: 'hidden' }}
            ></textarea>
            {
                // GetInputComponent(InputType.Text, null,
                //     organizer, 'SchoolCompleteName', null, '', this.state.locale,
                //     this.SaveOrganizerObject, null,
                //     true,    //this.state.EditOrganizer_Processing
                // )
            }
        </div>);

        //SchoolAddress.
        components.push(<div className="form-group">
            <label>School Address</label>
            {
                GetInputComponent(InputType.Text, null,
                    organizer, 'SchoolAddress', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        //Active.
        components.push(<div className="form-group">
            <label>Active</label>
            {
                GetInputComponent(InputType.Checkbox, null,
                    organizer, 'Active', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        //IsDemo.
        components.push(<div className="form-group">
            <label>Demo</label>
            {
                GetInputComponent(InputType.Checkbox, null,
                    organizer, 'IsDemo', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        //Logo.
        if (this.state.EditOrganizerNew || this.state.SelectedSchool !== null) { }
        else {
            const logoUrl = CheckObjectStringEmpty(organizer, 'LogoUrl');
            const logoFileName_splits = logoUrl.split('/');
            const logoFileName = logoUrl.length === 0 ? 'Logo' : logoFileName_splits[logoFileName_splits.length - 1];
            components.push(<div className="form-group">
                <label htmlFor="schoolLogo">Logo image file</label>
                <input
                    type="file"
                    onChange={this.onAttachPictureFile}
                    style={{ width: '100%' }}
                    name='schoolLogo'
                    accept='image/png,image/jpeg'
                />
                {
                    logoUrl === '' ? null :
                        <button type="button" style={{ borderStyle: 'none', backgroundColor: 'transparent', marginTop: 15 }}
                            onClick={() => window.open(logoUrl, '_blank')}
                            title={logoFileName}>
                            <img src={logoUrl} alt={logoFileName} style={{ maxWidth: 155, maxHeight: 155 }} />
                        </button>
                }
            </div>);
        }

        //2025.02.18
        //LinkToCurrentAuthor.
        components.push(<div className="form-group">
            <label>Link this organizer to current logged-in Author</label>
            {
                GetInputComponent(InputType.Checkbox, null,
                    organizer, 'LinkToCurrentAuthor', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        return (components);
    }
    onAttachPictureFile = (event) => {
        this.setState({
            AttachedPictureFile: event === null ? null : event.target.files[0],
        }, () => {
            this.UploadPictureFileForOrganizer();
        });
    }
    UploadPictureFileForOrganizer = async () => {
        const obj = this.state.EditOrganizerObject;
        if (obj === null) {
            useAppService.getState().setModal('Upload Failed', 'Invalid organizer object.');
            return null;
        }

        //Convert Picture to Base64.
        let _base64 = '';
        if (this.state.AttachedPictureFile !== null) {
            // let reader = new FileReader();
            // reader.onload = (evt) => {
            //     // _base64 = btoa(reader.result);
            //     _base64 = btoa(String.fromCharCode(...new Uint8Array(reader.result)));
            // };
            // reader.readAsArrayBuffer(this.state.AttachedPictureFile);

            //2025.02.03
            try {
                _base64 = await ToBase64(this.state.AttachedPictureFile);
            } catch (error) {
                console.log(error);
                return null;
            }
        }
        await Delay(300);
        if (CheckNullValue(_base64) === null) {
            useAppService.getState().setModal('Upload Failed', 'image file conversion error.');
            return null;
        }

        const _fileName = CheckStringEmpty(this.state.AttachedPictureFile.name).toLowerCase();
        let _fileType = '';
        let _fileExt = '';
        if (_fileName.includes('.jpg')) {
            _fileType = 'image/jpeg';
            _fileExt = '.jpg';
        }
        else if (_fileName.includes('.png')) {
            _fileType = 'image/png';
            _fileExt = '.png';
        }
        this.setState({
            EditOrganizer_Processing: true,
        });
        useAppService.getState().setModal('', 'uploading image file...', null, AlertMode.Loading);

        const fileName = this.GetGuid(CheckObjectStringEmpty(obj, 'SchoolName'))
            + '-' + CheckObjectStringEmpty(obj, 'SchoolCode') + '-' + RandomId();

        //2024.12.09
        let t_organizerId = 0;
        if (this.state.EditOrganizerIndex > -1) {
            t_organizerId = CheckObjectNumber(this.state.List[this.state.EditOrganizerIndex], 'Id')
        }

        const { authorId } = GetPropIds(useGlobal.getState().user);
        const { success, message } = await UploadPictureFileForOrganizer_ViaAPI({
            AuthorId: authorId,
            OrganizerId: t_organizerId,
            FileExt: _fileExt,
            FileType: _fileType,
            FileName: fileName,
            Base64: _base64,
        });
        if (!success) {
            useAppService.getState().setModal('', 'Failed to upload image file.<br /><br />Error:' + message);
        }
        else {
            // const obj = this.PopulateOrganizerModal(CapitalizeJsonKeys(data));
            // this.setState({
            //     EditOrganizerObject: obj,
            //     EditOrganizerCache: JSON.parse(JSON.stringify(obj)),
            // });
            const index = this.state.EditOrganizerIndex;
            await this.LoadList_ViaApi();
            await Delay(0);
            await this.Toggle_EditOrganizerModal();
            await Delay(200);
            await this.Toggle_EditOrganizerModal(index);
            await Delay(0);
            useAppService.getState().setModal('', "Organizer's logo has been updated.");
        }
        this.setState({
            EditOrganizer_Processing: false,
        });
    }
    //#endregion === Organizer - Edit / New ===

    //#region === Gov Schools ===
    Toggle_SearchSchoolByParams = (forceClose = false) => {
        this.setState({
            SearchSchoolByParamsModal_Toggle: forceClose ? false : !this.state.SearchSchoolByParamsModal_Toggle,
            SearchSchoolByParams_Processing: false,
            SearchBySchoolCode: '',
            SearchBySchoolName: '',
            SearchByCountryState: '',
            SearchByDistrictArea: '',
        }, () => {
            if (this.state.SearchSchoolByParamsModal_Toggle) {
                this.setState({
                    SchoolList: [],
                    SelectedSchool: null,
                });
            }
        });
    }
    // //get all schools.
    // GetAllGovernmentSchools_ViaAPI = async () => {
    //     this.setState({
    //         SearchBySchoolCode: '',
    //         SearchBySchoolName: '',
    //         SearchByCountryState: '',
    //         SearchByDistrictArea: ''
    //     });
    //     // await this.searchGovernmentSchools_ViaAPI();
    // }
    //get schools by params.
    SearchGovernmentSchools_ViaAPI = async () => {
        this.setState({
            SearchSchoolByParams_Processing: true,
            SchoolList: [],
        });

        let tableList = [];
        const { success, message, list } = await SearchGovernmentSchools_ViaAPI(
            this.state.SearchBySchoolCode, this.state.SearchBySchoolName,
            this.state.SearchByCountryState, this.state.SearchByDistrictArea
        );
        if (success)
            tableList = list;
        else
            useAppService.getState().setModal('Search (Failed)', 'Invalid Operation.<br /><br />Error:<br />' + message);
        if (Array.isArray(tableList) && tableList.length > 0) {
            for (let i = 0; i < tableList.length; i++) {
                tableList[i].DistrictArea = tableList[i].PPD.split(' ').slice(1, 5).join(' ');
            }
        }
        this.setState({
            SchoolList: tableList,
            SearchSchoolByParams_Processing: false,
            SearchBySchoolCode: '',
            SearchBySchoolName: '',
            SearchByCountryState: '',
            SearchByDistrictArea: '',
        }, () => {
            this.Toggle_SeachSchoolResultModal();
        });
    }
    Toggle_SeachSchoolResultModal = () => {
        this.setState({
            SearchSchoolResultModal_Toggle: !this.state.SearchSchoolResultModal_Toggle,
        }, () => {
            if (!this.state.SearchSchoolByParamsModal_Toggle) {
                this.setState({
                    SchoolList: [],
                });
            }
        });
    }
    ConfirmSelectedSchoolResult = async () => {
        const selectedSchool = this.state.SelectedSchool;
        if (selectedSchool === null)
            return null;

        let modal = this.state.EditOrganizerObject;
        if (modal === null)
            modal = this.PopulateOrganizerModal();

        const schoolName = CheckObjectStringEmpty(selectedSchool, 'SchoolName');
        const guid = this.GetGuid(schoolName);

        modal['Id'] = CheckObjectNumber(modal, 'Id');
        modal['Name'] = guid.toUpperCase();
        modal['DisplayName'] = schoolName;
        modal['IdentityGuid'] = guid.toLowerCase();
        modal['Active'] = true;
        modal['SchoolCode'] = CheckObjectStringEmpty(selectedSchool, 'SchoolCode');
        modal['NationalState'] = CheckObjectStringEmpty(selectedSchool, 'CountryState');
        modal['DistrictArea'] = CheckObjectStringEmpty(selectedSchool, 'PPD');
        modal['SchoolName'] = schoolName;
        modal['SchoolCompleteName'] = CheckObjectStringEmpty(selectedSchool, 'CompleteSchoolName');
        modal['SchoolAddress'] = CheckObjectStringEmpty(selectedSchool, 'Address');

        // modal['LogoId'] = CheckObjectNumber(modal, 'LogoId');
        // modal['LogoUrl'] = CheckObjectStringEmpty(modal, 'LogoUrl');

        await Delay(0);
        SetTempTarget(modal);
        this.SaveOrganizerObject(modal);
        await Delay(0);
        this.Toggle_SeachSchoolResultModal();
        await Delay(0);
        this.Toggle_SearchSchoolByParams(true);
    }
    GetGuid = (schoolName = '') => {

        //#region   

        // let guid = '';

        // if (schoolName.includes('KEBANGSAAN') && schoolName.includes('MENENGAH') === false) {
        //     if (schoolName.includes('(CINA)')) {
        //         if (schoolName.includes('JENIS'))
        //             guid = 'SJKC' + schoolName.split('(CINA)')[1].replace(/\s/g, '');
        //         else
        //             guid = 'SKC' + schoolName.split('(CINA)')[1].replace(/\s/g, '');
        //     }
        //     else if (schoolName.includes('(FELDA)'))
        //         guid = 'SKFELDA' + schoolName.split('(FELDA)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(TAMIL)'))
        //         guid = 'SJKT' + schoolName.split('(TAMIL)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(TAMIL & TELUGU)'))
        //         guid = 'SJKTT' + schoolName.split('(TAMIL & TELUGU)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('SEKOLAH KEBANGSAAN (1)'))
        //         guid = 'SK1' + schoolName.split('SEKOLAH KEBANGSAAN (1)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('SEKOLAH KEBANGSAAN (2)'))
        //         guid = 'SK2' + schoolName.split('SEKOLAH KEBANGSAAN (2)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(A)'))
        //         guid = 'SKA' + schoolName.split('(A)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(AGAMA)'))
        //         guid = 'SKAG' + schoolName.split('(AGAMA)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(ASLI)'))
        //         guid = 'SKASLI' + schoolName.split('(ASLI)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(L)'))
        //         guid = 'SKL' + schoolName.split('(L)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(LKTP)'))
        //         guid = 'SKLKTP' + schoolName.split('(LKTP)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(P)'))
        //         guid = 'SKP' + schoolName.split('(P)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(RTBK)'))
        //         guid = 'SKRTBK' + schoolName.split('(RTBK)')[1].replace(/\s/g, '');

        //     else
        //         guid = 'SK' + schoolName.replace('SEKOLAH KEBANGSAAN', '').replace(/[\s.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');
        // }

        // else if (schoolName.includes('MENENGAH') && schoolName.includes('KEBANGSAAN') === false) {
        //     if (schoolName.includes('(ARAB)'))
        //         guid = 'SMAA' + schoolName.split('(ARAB)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(') === false && schoolName.includes('AGAMA'))
        //         guid = 'SMAG' + schoolName.split('AGAMA')[1].replace(/\s/g, '');
        //     else
        //         guid = 'SM' + schoolName.replace('SEKOLAH MENENGAH', '').replace(/[\s.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');
        // }

        // else if (schoolName.includes('MENENGAH') && schoolName.includes('KEBANGSAAN')) {

        // }

        // else if (schoolName.includes('SEKOLAH KHAS') === false)
        //     guid = 'SKHAS' + schoolName.replace('SEKOLAH KHAS').replace(/\s/g, '');




        // if (schoolName.includes('SEKOLAH MENENGAH KEBANGSAAN')) {
        //     guid = 'SMK' + schoolName.replace('SEKOLAH MENENGAH KEBANGSAAN', '').replace(symbolRegex, '');
        // }
        // else if (schoolName.includes('SEKOLAH JENIS KEBANGSAAN')) {
        //     if (schoolName.includes('(CINA)'))
        //         guid = 'SJKC' + schoolName.replace('SEKOLAH JENIS KEBANGSAAN (CINA)', '').replace(symbolRegex, '');
        //     else if (schoolName.includes('(TAMIL)'))
        //         guid = 'SMK' + schoolName.replace('SEKOLAH JENIS KEBANGSAAN (TAMIL)', '').replace(symbolRegex, '');
        //     if (schoolName.includes('(CINA)'))
        //         guid = 'SMK' + schoolName.replace('SEKOLAH JENIS KEBANGSAAN (CINA)', '').replace(symbolRegex, '');
        //     else
        //         guid = 'SJK' + schoolName.replace('SEKOLAH JENIS KEBANGSAAN', '').replace(symbolRegex, '');
        // }

        //#endregion

        const symbolRegex = /[\s.,#!$%^&*;:{}=\-_`~()]/g;

        let guid = schoolName.replace(symbolRegex, '');
        guid = guid.replace('SEKOLAH', 'S').replace('JENIS', 'J').replace('KEBANGSAAN', 'K').replace('MENENGAH', 'M');
        guid = guid.replace('CINA', 'C').replace('TAMIL', 'T').replace('TAMIL & TELUGU', 'TT').replace('FELDA', 'F');
        // guid = guid.split(' ').map(word => { return word.charAt(0); }).join('');

        if (CheckNullValue(guid) === null)
            guid = schoolName.replace(symbolRegex, '');

        if (this.state.isDevMode)
            console.log('GetGuid = ' + guid);

        return guid;
    }
    //#endregion === Gov Schools ===

    //#region === Category - Add/Remove ===
    //2024.12.12
    Toggle_EditOrganizerCategoryModal = async (index = -1) => {
        if (this.state.PA_Update === false) {
            useAppService.getState().setModal('Edit Organizer Category', 'Invalid permission.');
            return null;
        }
        const toggle = !this.state.EditOrganizerCategoryModal_Toggle;
        if (!toggle) {
            this.setState({
                EditOrganizerCategoryModal_Toggle: false,
            });
            await Delay(200);
        }
        const target = index < 0 ? null : this.PopulateOrganizerModal(JSON.parse(JSON.stringify(this.state.List[index])));
        this.setState({
            EditOrganizerIndex: index,
            EditOrganizerObject: target,
            EditOrganizerCache: index < 0 ? null : JSON.parse(JSON.stringify(target)),
        });
        if (toggle) {
            if (index > -1)
                SetTempTarget(target);
            this.setState({
                EditOrganizerCategoryModal_Toggle: true,
            });
            if (this.state.isDevMode)
                console.log(`Toggle_EditOrganizerCategoryModal (${index}) \n ${JSON.stringify(target)}`);
        }
    }
    //2024.12.12
    EditOrganizerCategoryComponent = () => {
        let components = [];
        // if (this.state.EditOrganizer_Processing)
        //     return (<ProgressBar animated now={100} className='progressbar1' style={{ padding: 10 }} />);
        const categoryOptions = CheckNullValue(CapitalizeJsonKeys(this.state.CategoryOptions), []);
        if (Array.isArray(categoryOptions) && categoryOptions.length > 0) {
            let selectedCategories = [];
            let target = CapitalizeJsonKeys(this.state.EditOrganizerObject);
            if (target !== null)
                selectedCategories = Array.isArray(target['CategoryList']) ? target['CategoryList'] : [];
            let table_categoryOptions = [];
            categoryOptions.forEach((option, key) => {
                const findIndex_selectedCategory = selectedCategories.findIndex(x => x.CategoryId === option.Id);
                const isChecked = selectedCategories.length > 0 ? findIndex_selectedCategory > -1 : false;
                table_categoryOptions.push(<tr
                    onClick={async () => {
                        if (target !== null) {
                            const findIndex = selectedCategories.findIndex(x => x.CategoryId === option.Id);
                            if (findIndex < 0)
                                selectedCategories.push({ CategoryId: option.Id, Name: option.Label });
                            else
                                selectedCategories.splice(findIndex, 1);
                            selectedCategories.sort((a, b) => a.CategoryId - b.CategoryId);
                            target['CategoryList'] = selectedCategories;
                            SetTempTarget(target);
                            this.SaveOrganizerObject(target);
                            await Delay(0);
                            // if (this.state.isDevMode)
                            //     console.log(JSON.stringify(GetTempTarget()));
                        }
                    }}
                    style={{ cursor: 'pointer' }}
                >
                    <td width='42'>
                        <input type='checkbox' className="form-check form-check-input" readOnly={true} checked={isChecked} style={{ margin: 0 }}></input>
                    </td>
                    <td>{option.Label}</td>
                </tr>);

                //2025.03.04
                if (isChecked) {
                    // const categoryId = option.Id;
                    // const effectiveDateStart = findIndex_selectedCategory < 0 ? '' : CheckObjectStringEmpty(selectedCategories[findIndex_selectedCategory], 'EffectiveDateStart');
                    // const effectiveDateEnd = findIndex_selectedCategory < 0 ? '' : CheckObjectStringEmpty(selectedCategories[findIndex_selectedCategory], 'EffectiveDateEnd');
                    table_categoryOptions.push(<tr className="no-hover"><td></td><td style={{ padding: 0 }}>
                        {this.CategoryEffectiveDateComponents(option.Id, findIndex_selectedCategory < 0 ? null : selectedCategories[findIndex_selectedCategory])}
                        {/* <div style={{ alignSelf: 'start', display: 'flex', flexDirection: 'row', border: '1px solid gray', borderRadius: 5, padding: 5, marginLeft: 36, backgroundColor: 'white' }}>
                            <table style={{ textAlign: 'center' }} cellPadding={2}>
                                <thead>
                                    <tr><th>Effective Start Date/Time</th><th></th><th>Effective To Date/Time</th></tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div style={{ flex: 2 }}><input type="datetime-local" className="form-control width-max"
                                                onChange={(e) => this.SetEffectiveDate(categoryId, 'effectiveDateStart', e.target.value)}
                                                value={moment.utc(effectiveDateStart, 'YYYY-MM-DDTHH:mm').local().format('YYYY-MM-DDTHH:mm')}
                                                style={{ borderColor: 'gray' }}
                                            /></div>
                                        </td>
                                        <td>~</td>
                                        <td>
                                            <div style={{ flex: 2 }}><input type="datetime-local" className="form-control width-max"
                                                onChange={(e) => this.SetEffectiveDate(categoryId, 'effectiveDateEnd', e.target.value)}
                                                value={moment.utc(effectiveDateEnd, 'YYYY-MM-DDTHH:mm').local().format('YYYY-MM-DDTHH:mm')}
                                                style={{ borderColor: 'gray' }}
                                            /></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ display: 'flex', width: 60, fontSize: 12, alignItems: 'center' }}>
                                <button type="button" className="btn-link"
                                    onClick={async () => {
                                        this.SetEffectiveDate(categoryId, 'effectiveDateStart', yearStart);
                                        await Delay(0);
                                        this.SetEffectiveDate(categoryId, 'effectiveDateEnd', yearEnd);
                                        await Delay(0);
                                    }}
                                >apply default date</button>
                            </div>
                        </div> */}
                    </td></tr>);
                }
            });
            components.push(<table className="table table-hover tb-no-border-no-margin"><tbody>
                {table_categoryOptions}
            </tbody></table>);
        }
        return (components);
    }
    //2025.03.04
    CategoryEffectiveDateComponents = (categoryId = 0, categoryItem = null) => {
        const effectiveDateStart = CheckObjectStringEmpty(categoryItem, 'EffectiveDateStart');
        const effectiveDateEnd = CheckObjectStringEmpty(categoryItem, 'EffectiveDateEnd');
        return (<div style={{ alignSelf: 'start', display: 'flex', flexDirection: 'row', border: '1px solid gray', borderRadius: 5, padding: 5, marginLeft: 6, backgroundColor: 'white', width: 'fit-content' }}>
            <table style={{ textAlign: 'center' }} cellPadding={2}>
                <thead>
                    <tr><th>Effective Start Date/Time</th><th></th><th>Effective To Date/Time</th></tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div style={{ flex: 2 }}><input type="datetime-local" className="form-control width-max"
                                onChange={(e) => this.SetEffectiveDate(categoryId, 'EffectiveDateStart', e.target.value)}
                                value={moment.utc(effectiveDateStart, 'YYYY-MM-DDTHH:mm').local().format('YYYY-MM-DDTHH:mm')}
                                style={{ borderColor: 'gray' }}
                            /></div>
                        </td>
                        <td>~</td>
                        <td>
                            <div style={{ flex: 2 }}><input type="datetime-local" className="form-control width-max"
                                onChange={(e) => this.SetEffectiveDate(categoryId, 'EffectiveDateEnd', e.target.value)}
                                value={moment.utc(effectiveDateEnd, 'YYYY-MM-DDTHH:mm').local().format('YYYY-MM-DDTHH:mm')}
                                style={{ borderColor: 'gray' }}
                            /></div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={{ display: 'flex', width: 60, fontSize: 12, alignItems: 'center' }}>
                <button type="button" className="btn-link"
                    onClick={async () => {
                        this.SetEffectiveDate(categoryId, 'EffectiveDateStart', yearStart);
                        await Delay(0);
                        this.SetEffectiveDate(categoryId, 'EffectiveDateEnd', yearEnd);
                        await Delay(0);
                    }}
                >apply default date</button>
            </div>
        </div>);
    }
    //2025.03.04
    SetEffectiveDate = (categoryId = 0, propertyName = '', value = null) => {
        let target = JSON.parse(JSON.stringify(this.state.EditOrganizerObject));
        let categoryList = Array.isArray(target['CategoryList']) ? target['CategoryList'] : [];
        const findIndex = categoryList.findIndex(x => Number(x.CategoryId) === Number(categoryId));
        if (findIndex > -1) {
            let updatedItem = JSON.parse(JSON.stringify(categoryList[findIndex]));
            updatedItem[propertyName] = moment(value, 'YYYY-MM-DDTHH:mm').utc().format('YYYY-MM-DDTHH:mm');
            categoryList[findIndex] = updatedItem;
            target['CategoryList'] = categoryList;
            SetTempTarget(target);
            this.SaveOrganizerObject(target);
            if (this.state.isDevMode)
                console.log(`SetEffectiveDate (${categoryId}) (${propertyName}) (${value}) \n ${JSON.stringify(target)}`);
        }
    }
    //#endregion === Category - Add/Remove ===

    //#region === bulk edit ===
    ToggleItemChecked = (index, selectAll = null) => {
        if (selectAll !== null) {
            this.setState({
                BulkEdit_CheckedItems: this.state.List.map((data, key) => { return !selectAll; }),
            });
        }
        else {
            if (index < 0)
                return null;
            let checkedItems = this.state.BulkEdit_CheckedItems;
            checkedItems[index] = !checkedItems[index];
            this.setState({
                BulkEdit_CheckedItems: checkedItems,
            });
        }
    }
    BulkEdit_ToggleEditSettingModal = () => {
        if (this.state.PA_Update === false) {
            useAppService.getState().setModal('Bulk Edit Organizer(s)', 'Invalid permission.');
            return null;
        }
        this.BulkEdit_ResetSetting();
        const toggle = !this.state.BulkEdit_Toggle_EditSettingModal;
        this.setState({
            BulkEdit_Toggle_EditSettingModal: toggle,
        });
    }
    BulkEdit_SettingModalComponent = () => {
        let components = [];
        const setting = this.state.BulkEdit_Setting;
        const setting_checked = this.state.BulkEdit_Setting_checked;
        const setting_categoryItems = this.state.BulkEdit_Setting_CategoryItems;

        //Category List.
        const categoryOptions = CheckNullValue(this.state.CategoryOptions, []);
        if (Array.isArray(categoryOptions) && categoryOptions.length > 0) {
            const categoryList_setting_index = Object.values(SettingInput).indexOf(SettingInput.CategoryList);
            const categoryList_setting_checked = setting_checked[categoryList_setting_index];
            components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (categoryList_setting_checked ? 'bg-lightskyblue' : '')}`}>
                <div className="form-group width-max" style={{ padding: '10px 15px 15px' }}>
                    <label>Category</label>
                    <table className="table table-hover tb-no-border-no-margin" style={{ userSelect: 'none' }}><tbody>
                        {
                            categoryOptions.map((option, key) => {
                                const findIndex_categoryItem = setting_categoryItems.findIndex(x => x.categoryId === option.Id);
                                const isChecked = Array.isArray(setting[categoryList_setting_index].value)
                                    // && key < setting[categoryList_setting_index].value.length
                                    ? setting[categoryList_setting_index].value.findIndex(x => x.CategoryId === option.Id) > -1 : false;
                                return <>
                                    <tr
                                        onClick={() => {
                                            if (categoryList_setting_checked === false)
                                                DoNothing();
                                            else
                                                this.BulkEdit_SetSetting(SettingInput.CategoryList, option);
                                        }}
                                        style={categoryList_setting_checked ? { cursor: 'pointer' } : {}}
                                    >
                                        <td width='42'>
                                            <input type='checkbox' className="form-check form-check-input" readOnly={true} style={{ margin: 0 }}
                                                checked={isChecked} disabled={categoryList_setting_checked === false}></input>
                                        </td>
                                        <td><span style={categoryList_setting_checked ? {} : { color: 'lightgray' }}>{option.Label}</span></td>
                                    </tr>
                                    {
                                        isChecked ?
                                            <tr className="no-hover"><td></td><td style={{ padding: 0 }}>
                                                {this.BulkEdit_CategoryEffectiveDateComponents(option.Id, findIndex_categoryItem < 0 ? null : setting_categoryItems[findIndex_categoryItem])}
                                            </td></tr>
                                            : null
                                    }
                                </>;
                            })
                        }
                    </tbody></table>
                </div>
                <div className="select-checkbox">
                    <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, categoryList_setting_index)}>
                        <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : categoryList_setting_checked} readOnly={true} />
                    </div>
                </div>
            </div>);
        }

        //Active.
        const active_setting_index = Object.values(BulkSetting).indexOf(BulkSetting.Active);
        const active_setting_checked = setting_checked[active_setting_index];
        components.push(<div className={`setting-bulk-item-setting ${setting_checked === null ? '' : (active_setting_checked ? 'bg-lightskyblue' : '')}`}>
            <div className="form-group width-max" style={{ padding: '10px 15px 15px' }}>
                <label>Active</label>
                <input type="checkbox" className="form-check form-check-input"
                    onClick={(e) => {
                        if (active_setting_checked === false)
                            DoNothing();
                        else
                            this.BulkEdit_SetSetting(SettingInput.Active, e.currentTarget.checked);
                    }}
                    checked={CheckBoolean(setting[active_setting_index].value)}
                    readOnly={true}
                    disabled={active_setting_checked === false}
                    style={{ marginLeft: 7, cursor: 'pointer' }}
                ></input>
            </div>
            <div className="select-checkbox">
                <div className="form-check" onChange={() => this.BulkEdit_SetSetting(SettingInput.CheckedItem, active_setting_index)}>
                    <input className="form-check-input" type="checkbox" checked={setting_checked === null ? false : active_setting_checked} readOnly={true} />
                </div>
            </div>
        </div>);

        return (components);
    }
    BulkEdit_SetSetting = (property = SettingInput.None, value = null, index = -1) => {
        let setting_categoryItems = this.state.BulkEdit_Setting_CategoryItems;  //2025.03.04
        let setting = this.state.BulkEdit_Setting;
        let setting_checked = this.state.BulkEdit_Setting_checked;
        const setting_index = property === SettingInput.CheckedItem ? 999 : Object.values(SettingInput).indexOf(property);
        if (this.state.isDevMode)
            console.log(`BulkEdit_SetSetting (setting_index) = ` + JSON.stringify(setting_index));
        if (property === SettingInput.None || setting === null || value === null || setting_index < 0)
            return null;

        switch (property) {
            //CategoryList.
            case SettingInput.CategoryList:
                if (Array.isArray(setting[setting_index].value) === false)
                    setting[setting_index].value = [];
                const findIndex_category = setting[setting_index].value.findIndex(x => x.CategoryId === value.Id);
                if (findIndex_category < 0) {
                    //add.
                    setting[setting_index].value.push({ CategoryId: value.Id, Name: value.Label, EffectiveDateStart: '', EffectiveDateEnd: '' });
                }
                else {
                    //remove.
                    setting[setting_index].value.splice(findIndex_category, 1);

                    //2025.03.04
                    const findIndex_categoryItem = setting_categoryItems.findIndex(x => x.categoryId === value.Id);
                    if (findIndex_categoryItem > -1)
                        setting_categoryItems.splice(findIndex_categoryItem, 1);
                }
                break;
            //Active.
            case SettingInput.Active:
                setting[setting_index].value = CheckBoolean(value);
                break;
            //CheckedItem.
            case SettingInput.CheckedItem:
                const checkedItem_setting_index = Object.values(SettingInput).indexOf(SettingInput.CheckedItem);
                const toggle = !setting_checked[value];
                setting[checkedItem_setting_index].value[value] = toggle;
                setting_checked[value] = toggle;
                if (toggle === false) {
                    //reset value(s).
                    switch (value) {
                        //CategoryList.
                        case Object.values(SettingInput).indexOf(SettingInput.CategoryList):
                            setting[value].value = [];
                            setting_categoryItems = []; //2025.03.04
                            break;
                        //Active.
                        case Object.values(SettingInput).indexOf(SettingInput.Active):
                            setting[value].value = false; break;
                        default: break;
                    }
                }
                if (this.state.isDevMode)
                    console.log(`BulkEdit_SetSetting (BulkEdit_Setting_checked) = ` + JSON.stringify(setting_checked));
                break;
            default: break;
        }
        this.setState({
            BulkEdit_Setting: setting,
            BulkEdit_Setting_checked: setting_checked,
            BulkEdit_Setting_CategoryItems: setting_categoryItems,  //2025.03.04
        }, () => {
            if (this.state.isDevMode) {
                console.log(`BulkEdit_SetSetting (${property}) = ` + JSON.stringify(value));
                console.log(`BulkEdit_SetSetting (BulkEdit_Setting) = ` + JSON.stringify(setting));
                console.log(`BulkEdit_SetSetting (BulkEdit_Setting_CategoryItems) = ` + JSON.stringify(setting_categoryItems));
            }
        });
    }
    BulkEdit_ResetSetting = () => {
        this.setState({
            BulkEdit_Setting: [
                { key: SettingInput.CategoryList, value: [] },
                { key: SettingInput.Active, value: false },
                { key: SettingInput.CheckedItem, value: Object.keys(BulkSetting).map((data, key) => { return false; }) },
            ],
            BulkEdit_Setting_checked: Object.keys(BulkSetting).map(() => { return false; }),
            BulkEdit_Setting_CategoryItems: [],
        });
    }
    //2025.03.04
    BulkEdit_CategoryEffectiveDateComponents = (categoryId = 0, categoryItem = null) => {
        const effectiveDateStart = CheckObjectStringEmpty(categoryItem, 'EffectiveDateStart');
        const effectiveDateEnd = CheckObjectStringEmpty(categoryItem, 'EffectiveDateEnd');
        return (<div style={{ alignSelf: 'start', display: 'flex', flexDirection: 'row', border: '1px solid gray', borderRadius: 5, padding: 5, marginLeft: 6, backgroundColor: 'white', width: 'fit-content' }}>
            <table style={{ textAlign: 'center' }} cellPadding={2}>
                <thead>
                    <tr><th>Effective Start Date/Time</th><th></th><th>Effective To Date/Time</th></tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div style={{ flex: 2 }}><input type="datetime-local" className="form-control width-max"
                                onChange={(e) => this.BulkEdit_SetCategoryItemEffectiveDate(categoryId, 'effectiveDateStart', e.target.value)}
                                value={moment.utc(effectiveDateStart, 'YYYY-MM-DDTHH:mm').local().format('YYYY-MM-DDTHH:mm')}
                                style={{ borderColor: 'gray' }}
                            /></div>
                        </td>
                        <td>~</td>
                        <td>
                            <div style={{ flex: 2 }}><input type="datetime-local" className="form-control width-max"
                                onChange={(e) => this.BulkEdit_SetCategoryItemEffectiveDate(categoryId, 'effectiveDateEnd', e.target.value)}
                                value={moment.utc(effectiveDateEnd, 'YYYY-MM-DDTHH:mm').local().format('YYYY-MM-DDTHH:mm')}
                                style={{ borderColor: 'gray' }}
                            /></div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style={{ display: 'flex', width: 60, fontSize: 12, alignItems: 'center' }}>
                <button type="button" className="btn-link"
                    onClick={async () => {
                        this.BulkEdit_SetCategoryItemEffectiveDate(categoryId, 'effectiveDateStart', yearStart);
                        await Delay(0);
                        this.BulkEdit_SetCategoryItemEffectiveDate(categoryId, 'effectiveDateEnd', yearEnd);
                        await Delay(0);
                    }}
                >apply default date</button>
            </div>
        </div>);
    }
    //2025.03.04
    BulkEdit_SetCategoryItemEffectiveDate = (categoryId = 0, propertyName = '', value = null) => {
        let setting_categoryItems = JSON.parse(JSON.stringify(this.state.BulkEdit_Setting_CategoryItems));
        let findIndex_categoryItem = setting_categoryItems.findIndex(x => x.categoryId === categoryId);
        if (findIndex_categoryItem < 0) {
            //add.
            setting_categoryItems.push({ categoryId: categoryId, effectiveDateStart: '', effectiveDateEnd: '' });
            findIndex_categoryItem = setting_categoryItems.length - 1;
            setting_categoryItems[findIndex_categoryItem][propertyName] = moment(value, 'YYYY-MM-DDTHH:mm').utc().format('YYYY-MM-DDTHH:mm');
        }
        else {
            //update.
            let updatedItem = JSON.parse(JSON.stringify(setting_categoryItems[findIndex_categoryItem]));
            updatedItem[propertyName] = moment(value, 'YYYY-MM-DDTHH:mm').utc().format('YYYY-MM-DDTHH:mm');
            setting_categoryItems[findIndex_categoryItem] = updatedItem;
        }
        if (this.state.isDevMode)
            console.log(`BulkEdit_SetCategoryItemEffectiveDate (${categoryId}) (${propertyName}) (${value}) \n ${JSON.stringify(setting_categoryItems)}`);
        this.setState({
            BulkEdit_Setting_CategoryItems: setting_categoryItems,
        });
    }
    BulkEdit_CUD_Setting_ViaApi = async (remove = false) => {

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const { textTitle, textBody, text, urlParam } = GetPostParams({ id: 999 }, remove);
        this.setState({
            isUpdating: true,
        });
        useAppService.getState().setModal('', `${textTitle} setting...`, null, AlertMode.Loading);

        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Organizer/Category/BulkEdit/${urlParam}`;
        // Api/LearningCentre/Organizer/Category/BulkEdit/{Update|Remove}

        const setting_keys = Object.values(BulkSetting);
        let setting_params = [];
        if (remove === false) {
            for (let i = 0; i < this.state.BulkEdit_Setting_checked.length; i++) {
                if (this.state.BulkEdit_Setting_checked[i]) {
                    setting_params.push({
                        key: setting_keys[i],
                        value: this.state.BulkEdit_Setting[Object.values(SettingInput).indexOf(setting_keys[i])].value,
                    });
                }
                else {
                    setting_params.push({ key: setting_keys[i], value: null });
                }
            }

            //2025.03.19
            if (this.state.BulkEdit_Setting_checked[setting_keys.indexOf(BulkSetting.CategoryList)]) {
                const setting_categoryItems = this.state.BulkEdit_Setting_CategoryItems;
                if (Array.isArray(setting_categoryItems) && Array.isArray(setting_params[setting_keys.indexOf(BulkSetting.CategoryList)].value)) {
                    setting_categoryItems.forEach((item, key) => {
                        const findIndex = setting_params[setting_keys.indexOf(BulkSetting.CategoryList)].value.findIndex(x => x.CategoryId === item.categoryId);
                        if (findIndex > -1) {
                            setting_params[setting_keys.indexOf(BulkSetting.CategoryList)].value[findIndex]['EffectiveDateStart'] = item['effectiveDateStart'];
                            setting_params[setting_keys.indexOf(BulkSetting.CategoryList)].value[findIndex]['EffectiveDateEnd'] = item['effectiveDateEnd'];
                        }
                    });
                }
            }
        }

        let organizerIds = [];
        const list = this.state.List;
        const checkedItems = this.state.BulkEdit_CheckedItems;
        for (let n = 0; n < list.length; n++) {
            if (checkedItems[n])
                organizerIds.push(CheckObjectNumber(list[n], 'Id'));
        }

        const json = JSON.stringify({
            OrganizerId: organizerId,
            AuthorId: authorId,

            BulkOrganizerIds: organizerIds.join(','),

            CategoryList: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.CategoryList)].value,         //list.
            UpdateCategoryList: this.state.BulkEdit_Setting_checked[setting_keys.indexOf(BulkSetting.CategoryList)],    //bool.

            Active: remove ? null : setting_params[setting_keys.indexOf(BulkSetting.Active)].value,

            Remove: remove,
        });
        if (this.state.isDevMode)
            console.log(`BulkEdit_CUD_Setting_ViaApi (${text}) (postData) =\n` + json);

        // let data = null;
        let success = false;
        let msg = '';
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: json,
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('CUD_Setting_ViaApi (response) =\n' + JSON.stringify(data));
                success = CheckObjectBoolean(data, 'success');
                // data = CheckObjectNullValue(data, 'data');
                if (!success)
                    msg = CheckObjectStringEmpty(data, 'message');
            })
            .catch(error => {
                msg = error.message;
                if (this.state.isDevMode)
                    console.log('Error', `api - ${text} (error)\n` + error.message);
            });

        if (success) {
            await this.LoadList_ViaApi();
            this.BulkEdit_ToggleEditSettingModal();
            await Delay(500);
            useAppService.getState().setModal();
            await Delay(500);
            useAppService.getState().setModal('', `All selected Organizer(s) have been ${textBody}.`);
        }
        else {
            useAppService.getState().setModal('', `Failed to ${text} selected organizer(s).<br /><br />` + msg);
        }
        this.setState({
            isUpdating: false,
        });
    }
    //#endregion === bulk edit ===

    //2025.02.26
    //#region === List === Search === start ===
    Toggle_SearchList_Modal = (resetCache = true) => {
        if (this.state.PA_Search === false) {
            useAppService.getState().setModal('Search Organizer(s)', 'Invalid permission.');
            return null;
        }
        const toggle = !this.state.ShowSearchListModal;
        this.setState({
            ShowSearchListModal: toggle,
        }, async () => {
            if (toggle) {
                //reset
                this.ResetSearchListParams();
                await Delay(200);
                //reset options.
                await useAppService.getState().getNationalStateOptions();
                // await Delay(200);
                // this.setState({ NationalStateOptions: useAppService.getState().nationalStateOptions, });
                // await Delay(0);
            }
            //reset by condition.
            if (resetCache)
                this.ResetSearchListParams();
        });
    }
    ResetSearchListParams = () => {
        //Search Options.
        this.setState({
            SearchList_BySchoolCode: '',
            SearchList_BySchoolName: '',
            SearchList_ByNationalState: null,
            SearchList_ByDistrictArea: null,
            SearchList_KeepSearchParams: false,
            NationalStateOptions: [],
            DistrictAreaOptions: [],
        });
    }
    SaveSearchOptions = (inputType = SearchOptions.None, value) => {
        let selected = null;
        switch (inputType) {
            case SearchOptions.SearchList_BySchoolCode:
                this.setState({ SearchList_BySchoolCode: String(value) });
                break;
            case SearchOptions.SearchList_BySchoolName:
                this.setState({ SearchList_BySchoolName: String(value) });
                break;
            case SearchOptions.SearchList_ByNationalState:
                const nationalStateOptions = useAppService.getState().nationalStateOptions;
                const findIndex_ns = nationalStateOptions.findIndex(x => value.value === x.value && value.id === x.id);
                selected = findIndex_ns < 0 ? null : nationalStateOptions[findIndex_ns];
                this.setState({
                    SearchList_ByNationalState: selected
                }, async () => {
                    if (selected !== null) {
                        await useAppService.getState().getDistrictAreaOptions(selected.value);
                        await Delay(200);
                        this.setState({ DistrictAreaOptions: useAppService.getState().districtAreaOptions, });
                    }
                });
                break;
            case SearchOptions.SearchList_ByDistrictArea:
                const districtAreaOptions = useAppService.getState().districtAreaOptions;
                const findIndex_da = districtAreaOptions.findIndex(x => value.value === x.value && value.id === x.id);
                selected = findIndex_da < 0 ? null : districtAreaOptions[findIndex_da];
                this.setState({ SearchList_ByDistrictArea: selected });
                break;
            default: break;
        }
    }
    SearchListByConditions = async () => {
        if (!this.state.IsSuperAdmin && !this.state.IsMasterAdmin)
            return null;
        this.setState({
            ShowSearchListModal: false,      //close search ui.
            SearchList_KeepSearchParams: true,
        });
        // this.Toggle_SearchList_Modal();
        await this.LoadList_ViaApi(true);
    }
    //#endregion === List === Search === end ===

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        return (<div className="">
            <table className="table page-header">
                <tbody>
                    <tr>
                        <td className="left">
                            <h5>Organizer</h5>
                            {
                                Object.keys(DisplaySetting).map((setting, key) => {
                                    const values = Object.values(DisplaySetting);
                                    return (<div className="form-check">
                                        <input
                                            id={'formCheck_Toggle_' + setting}
                                            type='checkbox' className='form-check-input cursor-pointer'
                                            readOnly={true} checked={this.state.DisplaySettingOptions[key]}
                                            onClick={() => {
                                                let option = this.state.DisplaySettingOptions;
                                                option[key] = !option[key];
                                                this.setState({
                                                    DisplaySettingOptions: option
                                                }, () => {
                                                    this.LoadList_ViaApi();
                                                });
                                                //unselect selected organizers if SchoolCode is checked.
                                                if (values[key] === DisplaySetting.SchoolCode && option[key]) {
                                                    const list = this.state.List;
                                                    let checkedItems = this.state.BulkEdit_CheckedItems;
                                                    for (let i = 0; i < checkedItems.length; i++) {
                                                        if (CheckObjectStringEmpty(list[i], 'SchoolCode') === '')
                                                            checkedItems[i] = false;
                                                    }
                                                    this.setState({ BulkEdit_CheckedItems: checkedItems });
                                                }
                                            }}
                                        ></input>
                                        <label className='form-check-label cursor-pointer' htmlFor={'formCheck_Toggle_' + setting}
                                            style={{ color: 'gray', fontSize: 'small', userSelect: 'none', }}>{values[key]}</label>
                                    </div>);
                                })
                            }
                            <Button variant="primary"
                                onClick={() => this.BulkEdit_ToggleEditSettingModal()}
                                disabled={this.state.isUpdating || this.state.BulkEdit_CheckedItems.length === 0 ? true : (this.state.BulkEdit_CheckedItems.includes(true) ? false : true)}
                                // hidden={!this.state.PA_Update}
                                hidden={this.state.PA_Update === false}
                            >Bulk Edit</Button>
                            <button
                                type="button"
                                className="btn-link"
                                onClick={() => {
                                    this.setState({
                                        SearchList_KeepSearchParams: false,
                                    }, () => {
                                        this.LoadList_ViaApi(true);
                                    });
                                }}
                                title="Refresh Organizer list"
                            ><i className="fa fa-refresh" title="Refresh Organizer list"></i></button>
                        </td>
                        <td className="center"></td>
                        <td className="right">
                            <Button
                                variant='outline-primary'
                                onClick={() => this.Toggle_SearchList_Modal()}
                            // onClick={() => this.setState({ ShowSearchListModal: true, SearchQsSet_Processing: false, SearchQuestionSet_Result: null, })}
                            >Search School</Button>
                            <Button
                                variant='outline-primary'
                                onClick={() => this.Toggle_EditOrganizerModal(-1, true)}
                                disabled={this.state.isUpdating}
                                hidden={this.state.PA_Create === false}
                            >New Organizer</Button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='table table-bordered table-hover' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
                <thead>
                    <tr>
                        <th width='50' className="pointer" onClick={() => this.state.isLoading || this.state.List.length === 0 ? DoNothing() : this.ToggleItemChecked(-1, this.state.BulkEdit_CheckedItems.findIndex(x => x === false) < 0)}
                            style={{ textAlign: 'center' }}>
                            <input type='checkbox' className={this.state.isLoading || this.state.List.length === 0 ? '' : 'pointer'}
                                checked={this.state.isLoading || this.state.List.length === 0 ? false : !(this.state.BulkEdit_CheckedItems.findIndex(x => x === false) > -1)}
                                readOnly={true} disabled={this.state.isLoading || this.state.List.length === 0}></input>
                        </th>
                        <th style={{ textAlign: 'left', }}>Organizer</th>
                        <th style={{ textAlign: 'left', width: 'auto', }}>School Name</th>
                        <th style={{ textAlign: 'left', width: 245, }}>Category</th>
                        <th style={{ textAlign: 'left', width: 350, }}>State & District Area</th>
                        <th style={{ textAlign: 'center', width: 75, }}>Active</th>
                        <th style={{ textAlign: 'center', width: 105, }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {this.ListComponents()}
                    {
                        this.state.List.length === 0 ? null :
                            PagingComponents(15, this.state.TotalRows, this.state.PageIndex, this.state.PageSize, this.CallbackFunctionForPagingComponents_PageSize, this.CallbackFunctionForPagingComponents_PageIndex)
                    }
                </tbody>
            </table>

            {/* Organizer - Edit / Create - Modal */}
            <Modal show={this.state.EditOrganizerModal_Toggle}
                onHide={() => {
                    if (this.state.EditOrganizer_Processing)
                        DoNothing()
                    else
                        this.Toggle_EditOrganizerModal()
                }}
                centered
                dialogClassName="modal-w650"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.EditOrganizerNew ? 'New' : 'Edit'} Organizer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body-buttons">
                        <Button
                            variant='primary'
                            onClick={() => this.Toggle_SearchSchoolByParams()}
                        >Search for School</Button>
                    </div>
                    {this.state.EditOrganizerNew ? null : <span>Id: {CheckObjectStringEmpty(this.state.EditOrganizerObject, 'Id')}</span>}
                    {this.EditOrganizerComponents()}
                    {/* {this.state.EditOrganizerNew || this.state.SelectedSchool !== null ? null : <label>Logo image file
                        <input
                            type="file"
                            onChange={this.onAttachPictureFile}
                            style={{ width: '100%' }}
                            name='imageLogo'
                            accept='image/png,image/jpeg'
                        />
                    </label>} */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.Toggle_EditOrganizerModal()}>Cancel</Button>
                    &nbsp;&nbsp;
                    {
                        this.state.EditOrganizerNew ?
                            <Button variant="primary" onClick={() => this.CreateOrganizer()} disabled={this.state.EditOrganizer_Processing}>Create</Button>
                            :
                            <Button variant="primary" onClick={() => this.UpdateOrganizer()} disabled={this.state.EditOrganizer_Processing}>Update</Button>
                    }
                </Modal.Footer>
            </Modal>

            {/* Gov School - Search School(s) by Params - Modal */}
            <Modal
                show={this.state.SearchSchoolByParamsModal_Toggle}
                onHide={() => {
                    if (this.state.SearchSchoolByParams_Processing)
                        DoNothing();
                    else
                        this.Toggle_SearchSchoolByParams(true)
                }}
                centered
            >
                <Modal.Header closeButton={this.state.SearchSchoolByParams_Processing === false}>
                    <Modal.Title>{
                        this.state.SearchSchoolByParams_Processing ? 'Searching...' : 'Search School(s)'
                    }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.SearchSchoolByParams_Processing ?
                            <ProgressBar animated now={100} className='progressbar1' />
                            :
                            <table className="table tb-no-border" cellPadding={5} cellSpacing={0} width='100%'>
                                <thead>
                                    <tr><th colSpan={2}><h5>Search By:</h5></th></tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="120px">School Code</td>
                                        <td>
                                            <input className='form-control' type="text" style={{ width: '100%' }}
                                                placeholder='(enter school code here)'
                                                onChange={(e) => this.setState({ SearchBySchoolCode: String(e.target.value) })}
                                            // value={CheckStringEmpty(this.state.SearchBySchoolCode)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>School Name</td>
                                        <td>
                                            <input className='form-control' type="text" style={{ width: '100%' }}
                                                placeholder='(enter school name here)'
                                                onChange={(e) => this.setState({ SearchBySchoolName: String(e.target.value) })}
                                            // value={CheckStringEmpty(this.state.SearchBySchoolName)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Country State</td>
                                        <td>
                                            <input className='form-control' type="text" style={{ width: '100%' }}
                                                placeholder='(enter country state here)'
                                                onChange={(e) => this.setState({ SearchByCountryState: String(e.target.value) })}
                                            // value={CheckStringEmpty(this.state.SearchByCountryState)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>District Area</td>
                                        <td>
                                            <input className='form-control' type="text" style={{ width: '100%' }}
                                                placeholder='(enter district area here)'
                                                onChange={(e) => this.setState({ SearchByDistrictArea: String(e.target.value) })}
                                            // value={CheckStringEmpty(this.state.SearchByDistrictArea)}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                    }
                </Modal.Body>
                {
                    !this.state.SearchSchoolByParams_Processing ?
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.Toggle_SearchSchoolByParams()}>Cancel</Button>
                            &nbsp;&nbsp;
                            <Button variant="primary" onClick={() => this.SearchGovernmentSchools_ViaAPI()}>Search</Button>
                        </Modal.Footer>
                        : null
                }
            </Modal>

            {/* Gov School - Search School(s) Result - Modal */}
            <Modal show={this.state.SearchSchoolResultModal_Toggle}
                onHide={() => this.Toggle_SeachSchoolResultModal()}
                centered
                size='xl'
            >
                <Modal.Header closeButton>
                    <Modal.Title>School(s)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className="table table-bordered table-hover" cellPadding={10} cellSpacing={10} width='100%'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Code</th>
                                <th>Name</th>
                                <th>Country State</th>
                                <th>District Area</th>
                                <th style={{ textAlign: 'center', width: 100 }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.SchoolList.length === 0 ?
                                    <td colSpan={15} style={{ textAlign: 'center' }}>- no school is found - </td>
                                    :
                                    this.state.SchoolList.map((data, key) => {
                                        return (<tr className={this.state.SelectedSchool !== null && this.state.SelectedSchool === data ? 'tr-selected' : ''}>
                                            <td>{key + 1}</td>
                                            <td>{CheckObjectStringEmpty(data, 'SchoolCode')}</td>
                                            <td>{CheckObjectStringEmpty(data, 'SchoolName')}</td>
                                            <td>{CheckObjectStringEmpty(data, 'CountryState')}</td>
                                            <td>{CheckObjectStringEmpty(data, 'DistrictArea')}</td>
                                            <td align="center"
                                                onClick={() => this.setState({
                                                    SelectedSchool: data,
                                                }, () => {
                                                    if (this.state.isDevMode)
                                                        console.log(`Selected School \n ${JSON.stringify(data)}`)
                                                })}
                                                style={{ cursor: 'pointer' }}>
                                                {/* {
                                                    this.state.SelectedSchool !== null && this.state.SelectedSchool === data ?
                                                        <Button
                                                            variant='secondary'
                                                            onClick={() => this.setState({
                                                                SelectedSchool: null,
                                                            })}
                                                        >Cancel</Button>
                                                        :
                                                        <Button
                                                            variant='primary'
                                                            onClick={() => this.setState({
                                                                SelectedSchool: data,
                                                            })}
                                                        >Select</Button>
                                                } */}
                                                {
                                                    this.state.SelectedSchool !== null && this.state.SelectedSchool === data ?
                                                        <input type='radio' name='r-school' id='r-school-1' value='1' checked={true} style={{ cursor: 'pointer' }} />
                                                        :
                                                        <input type='radio' name='r-school' id='r-school-2' value='2' checked={false} style={{ cursor: 'pointer' }} />
                                                }
                                            </td>
                                        </tr>);
                                    })
                            }
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.Toggle_SeachSchoolResultModal()}>Cancel</Button>
                    &nbsp;&nbsp;
                    <Button variant="primary" onClick={() => this.ConfirmSelectedSchoolResult()}>Confirm</Button>
                </Modal.Footer>
            </Modal>

            {/* Organizer - Edit (Category) - Modal */}
            <Modal
                show={this.state.EditOrganizerCategoryModal_Toggle}
                onHide={() => {
                    if (this.state.EditOrganizer_Processing)
                        DoNothing();
                    else
                        this.Toggle_EditOrganizerCategoryModal()
                }}
                centered
                dialogClassName="modal-w650"
            >
                <Modal.Header closeButton={this.state.EditOrganizer_Processing === false}>
                    <Modal.Title>{CheckObjectStringEmpty(this.state.EditOrganizerObject, 'DisplayName') + ' (Category)'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.EditOrganizerCategoryComponent()}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.Toggle_EditOrganizerCategoryModal()} disabled={this.state.EditOrganizer_Processing}>Cancel</Button>
                    &nbsp;&nbsp;
                    <Button variant="primary" onClick={() => this.UpdateOrganizer()} disabled={this.state.EditOrganizer_Processing}>Update</Button>
                </Modal.Footer>
            </Modal>

            {/* Setting - (BULK) Edit / Update - Modal */}
            <Modal show={this.state.BulkEdit_Toggle_EditSettingModal}
                onHide={() => this.BulkEdit_ToggleEditSettingModal()}
                centered
                dialogClassName="modal-w750"
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Bulk Edit {`(${this.state.BulkEdit_CheckedItems.filter(x => x === true).length}/${this.state.BulkEdit_CheckedItems.length})`}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="setting-bulk-parent">
                    {this.BulkEdit_SettingModalComponent()}
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="danger"
                        onClick={() => this.BulkEdit_ToggleRemoveSettingModal()}
                        style={{ position: "absolute", left: 0, marginLeft: 15 }}
                        disabled={this.state.isUpdating || (this.state.IsSuperAdmin ? false : this.state.PA_Delete === false)}
                    >Bulk Remove</Button> */}
                    <Button variant="secondary" onClick={() => this.BulkEdit_ToggleEditSettingModal()} disabled={this.state.isUpdating}>Cancel</Button>
                    <Button variant="secondary" onClick={() => this.BulkEdit_ResetSetting()} disabled={this.state.isUpdating}>Reset</Button>
                    <Button variant="primary" onClick={() => this.BulkEdit_CUD_Setting_ViaApi()} disabled={this.state.isUpdating}>Bulk Update</Button>
                </Modal.Footer>
            </Modal >

            {/* Organizer - Search List - Modal */}
            <Modal
                show={this.state.ShowSearchListModal}
                onHide={() => this.state.IsLoading ? DoNothing() : this.Toggle_SearchList_Modal()}
                centered
            >
                <Modal.Header closeButton={this.state.IsLoading === false}>
                    <Modal.Title>Search Organizer(s)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table width='100%' cellPadding='5' cellSpacing='5' border='0' style={{ borderColor: 'grey', marginBottom: 0, }}>
                        <tbody>
                            <tr>
                                <td>School Code</td>
                                <td>
                                    <input type="text" className='form-control' style={{ width: '100%' }}
                                        value={this.state.SearchList_BySchoolCode}
                                        onChange={(e) => this.SaveSearchOptions(SearchOptions.SearchList_BySchoolCode, e.target.value)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>School Name</td>
                                <td>
                                    <input type="text" className='form-control' style={{ width: '100%' }}
                                        value={this.state.SearchList_BySchoolName}
                                        onChange={(e) => this.SaveSearchOptions(SearchOptions.SearchList_BySchoolName, e.target.value)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Country State</td>
                                <td>
                                    <ReactSelect
                                        // options={this.state.NationalStateOptions}
                                        options={useAppService.getState().nationalStateOptions}
                                        placeholder={
                                            this.state.SearchList_ByNationalState?.label ?? '(Select Country State...)'}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                neutral50: 'gray',  // placeholder color
                                            },
                                        })}
                                        value={this.state.SearchList_ByNationalState?.value}
                                        onChange={(option) => this.SaveSearchOptions(SearchOptions.SearchList_ByNationalState, option)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>District Area</td>
                                <td>
                                    <ReactSelect
                                        options={this.state.DistrictAreaOptions}
                                        // options={useAppService.getState().districtAreaOptions}
                                        placeholder={this.state.SearchList_ByDistrictArea?.label ?? '(Select District Area...)'}
                                        theme={theme => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                neutral50: 'gray',  // placeholder color
                                            },
                                        })}
                                        value={this.state.SearchList_ByDistrictArea?.value}
                                        onChange={(option) => this.SaveSearchOptions(SearchOptions.SearchList_ByDistrictArea, option)}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.Toggle_SearchList_Modal()}>Cancel</Button>
                    &nbsp;
                    <Button variant="secondary" onClick={() => this.ResetSearchListParams()}>Reset</Button>
                    &nbsp;
                    <Button variant="primary" onClick={() => this.SearchListByConditions()} disabled={this.state.IsSearchQsSetConditionsValid === false}>Search</Button>
                </Modal.Footer>
            </Modal>
        </div>);
    }
}