import React from 'react';
import { Col, Row, Button, ProgressBar, Badge, OverlayTrigger, Tooltip, Modal, Dropdown, DropdownButton } from 'react-bootstrap';
import ReactTags from 'react-tag-autocomplete'

import { Delay, DelayUntil, ScrollToElement, GetPropIds, CheckNullValue, CheckStringEmpty, CheckObjectNumber, CheckObjectStringEmpty, DoNothing, RandomId, ToBase64 } from '../utilities/GlobalFunctions';
import { AtoZ, GlobalSetting, QuizType } from '../utilities/GlobalSetting';
import { useAppService } from '../services/AppService';

import '../css/PageStyle.scss';
import { useGlobal } from '../utilities/GlobalVariables';
import { get, ref, set, update } from 'firebase/database';
import { AlertMode } from './AlertComponent';

const QsDataInput = {
    None: 0,
    Content: 1,
    Answer: 2,
    Hints: 3,
    Tags: 4,
    PictureUrl: 5,
    Selection: 6,
    ComprehensionPassage: 7,
};

export default class EditQuestionObjective extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
        // QsDataInput = this.props.QsDataInput;

        this.reactTags = React.createRef();
        this.EditQs_Content_Ref = React.createRef();
        this.EditQs_Answer_Ref = React.createRef();
        this.EditQs_Hints_Ref = React.createRef();
        this.EditQs_Tags_Ref = React.createRef();
        this.EditQs_Selection_Ref = React.createRef();
        this.EditQs_Picture_Ref = React.createRef();
        this.EditQs_Comprehension_Passage_Ref = React.createRef();

        this.EditQs_AnswerOptions_Ref = [];
        this.EditQs_AnswerOptionRadios_Ref = [];
        // this.EditQs_AnswerOptionAlertTexts_Ref = [];
        // this.SuggestionTagList_Ref = [];

        this.EditQs_QuizType_Comprehension_Start_Ref = React.createRef();
        this.EditQs_QuizType_Comprehension_End_Ref = React.createRef();
    }

    getInitState = () => ({

        QuestionModal: null,
        CachedQuestionModal: null,
        IsEdit: false,
        AllowToSaveChanges: false,
        SavingChangesOnQuestion: false,
        Update_Question_Success: null,      //null, true, false.

        AvailableTagList: [],
        TagList: [],

        ToggleEditAnswerOptions: false,
        EditedAnswerOptions: '',
        EditedAnswer: '',

        ToggleComprehensionPassage: false,
        IsLoaded_ComprehensionPassage: false,
        // ComprehensionPassage: [],
        CachedComprehensionPassage: [],
        ToggleEditComprehensionPassage: false,
        SavingChangesOnComprehensionPassage: false,
        Update_ComprehensionPassage_Success: null,      //null, true, false.
        EditedComprehensionPassage: '',

        PictureFileToUpload: null,
        ToggleUploadPictureUi: false,
        Upload_Picture_State: 0,                //0 = default, 1 = uploading, 2 = failed, retry.

        ToggleUploadContentPictureUi: false,
        Upload_PictureForContent_State: 0,      //0 = default, 1 = uploading, 2 = failed, retry.
        SelectedContentPictureIndex: -1,
        ContentPictureFileName: '',
        ToggleContentPictureUploadPictureUiModal: false,

        ToggleAnswerOptionUploadPictureUiModal: false,
        AnswerOption_Upload_Picture_State: 0,       //0 = default, 1 = uploading, 2 = failed (retry), 3 = success
        SelectedAnswerOptionIndex: -1,
        SelectedAnswerOptionPictureName: '',

        RefreshPreview: false,
        RefreshAnswerOptionsUi: false,

        //2021.10.04
        DisplayMode: 3, //Mode 1 = flex: left/right (1); Mode 2 = flex: left (2), right (1); Mode 3 = flex: left (1), right (2)

        //2021.12.07
        ToggleEditQuestionType: false,
        EditQuestionType_State: 0,
        EditQuestionType_QuizType: QuizType[0],
        EditQuestionType_QuizType_Start: 0,
        EditQuestionType_QuizType_End: 0,
    });

    componentWillUnmount = () => { }

    componentDidMount = () => {
        this.InitState();
    }

    InitState = () => {
        let _questionModal = this.props.QuestionModal;
        _questionModal.Content = this.FilterQuestionContent_Edit(_questionModal.Content);
        this.setState({
            QuestionModal: _questionModal,  //this.props.QuestionModal,
            CachedQuestionModal: JSON.parse(JSON.stringify(_questionModal)),    //this.props.QuestionModal)),
            EditQuestionType_QuizType: null,
        }, () => {
            this.PopulateTagList();
            this.PopulateFormUiData();
            if (this.props.isDevMode)
                console.log(JSON.stringify(this.state.QuestionModal));
        });
    }


    //#region       //=== Quiz Tags === start ===//
    PopulateTagList = () => {
        //Tags from AvailableTags.
        let _availableTags = [];
        if (this.props.AvailableTags !== undefined && this.props.AvailableTags !== null && Array.isArray(this.props.AvailableTags)) {
            if (this.props.AvailableTags.length > 0) {
                this.props.AvailableTags.map((data, key) => {
                    return _availableTags.push({
                        id: data.Id,
                        name: data.Name,
                        uniqueId: data.UniqueId,
                        remark: data.Remark,

                        //2021.10.01
                        type: data.Type,
                    });
                });
                this.setState({ AvailableTagList: _availableTags, }, () => {
                    if (this.props.isDevMode)
                        console.log('AvailableTagList = \n' + JSON.stringify(_availableTags));
                });
            }
        }

        //Tags from Question.
        if (this.state.QuestionModal !== null) {
            if (this.state.QuestionModal.hasOwnProperty('Tags')) {
                let _tagList = [];
                let _tags = CheckObjectStringEmpty(this.state.QuestionModal, 'Tags');
                if (_tags.length > 0) {
                    if (_tags.includes(',')) {
                        let _Tags = _tags.split(',');
                        _Tags.map((data, key) => {
                            let _tagIndex = _availableTags.findIndex(x => x.name === data);
                            // return _tagList.push({
                            //     id: _tagIndex < 0 ? '' : _availableTags[_tagIndex].id,
                            //     name: data,

                            //     //2021.09.27
                            //     uniqueId: _tagIndex < 0 ? '' : _availableTags[_tagIndex].uniqueId,
                            //     remark: _tagIndex < 0 ? '' : _availableTags[_tagIndex].remark,

                            //     //2021.10.01
                            //     type: _tagIndex < 0 ? 0 : _availableTags[_tagIndex].type,
                            // });

                            //2023.08.30
                            if (_tagIndex > -1) {
                                _tagList.push({
                                    id: CheckObjectNumber(_availableTags[_tagIndex], 'id'),
                                    name: data,
                                    uniqueId: CheckObjectStringEmpty(_availableTags[_tagIndex], 'uniqueId'),
                                    remark: CheckObjectStringEmpty(_availableTags[_tagIndex], 'remark'),
                                    type: CheckObjectNumber(_availableTags[_tagIndex], 'type'),
                                });
                            }
                            return null;
                        });
                    }
                    else {
                        let _tagIndex = _availableTags.findIndex(x => x.name === _tags);
                        // _tagList.push({
                        //     id: _availableTags[_tagIndex].id,
                        //     name: _tags,

                        //     //2021.10.01
                        //     uniqueId: _tagIndex < 0 ? '' : _availableTags[_tagIndex].uniqueId,
                        //     remark: _tagIndex < 0 ? '' : _availableTags[_tagIndex].remark,
                        //     type: _tagIndex < 0 ? 0 : _availableTags[_tagIndex].type,
                        // });

                        //2023.08.30
                        if (_tagIndex > -1) {
                            _tagList.push({
                                id: CheckObjectNumber(_availableTags[_tagIndex], 'id'),
                                name: _tags,
                                uniqueId: CheckObjectStringEmpty(_availableTags[_tagIndex], 'uniqueId'),
                                remark: CheckObjectStringEmpty(_availableTags[_tagIndex], 'remark'),
                                type: CheckObjectNumber(_availableTags[_tagIndex], 'type'),
                            });
                        }
                    }
                }
                this.setState({ TagList: _tagList, }, () => {
                    if (this.props.isDevMode)
                        console.log('TagList = \n' + JSON.stringify(_tagList));
                });
            }
        }
    }
    OnAddTag = (tag) => {
        //2021.10.01
        var _tagIndex = this.state.TagList.findIndex(x => x.type === 1);
        if (_tagIndex > -1)
            return null;
        // let tags = [...this.state.TagList, tag];
        let _findIndex = this.state.TagList.findIndex(x => x.name === tag.name);
        if (_findIndex < 0) {
            let _tags = [].concat(this.state.TagList, tag);
            let _question = this.state.QuestionModal;
            // console.log(JSON.stringify(_tags));
            let _tagsText = [];
            _tags.map((data, key) => {
                // _tagsText += data.name;
                if (key < _tags.length - 1)
                    _tagsText += ',';
                //2021.10.01
                var _tagIndex = this.state.AvailableTagList.findIndex(x => x.name === tag.name);
                if (_tagIndex > -1) {
                    data.id = this.state.AvailableTagList[_tagIndex].id;
                    data.uniqueId = this.state.AvailableTagList[_tagIndex].uniqueId;
                    data.remark = this.state.AvailableTagList[_tagIndex].remark;
                    data.type = this.state.AvailableTagList[_tagIndex].type;
                }
                return null;
            });
            // _question.Tags = _tagsText;

            //2021.10.04
            //sort for Subject Tag ordered at front.
            _tags.sort((a, b) => b.type - a.type);
            //join tags.
            _tags.map((data, key) => {
                return _tagsText.push(String(data.name));
            });
            _question.Tags = _tagsText.join(',');

            //save state.
            this.setState({
                TagList: _tags,
                QuestionModal: _question,
            }, () => {
                this.CheckIfQuestionAllowedToSave();
            });
        }
    }
    OnRemoveTag = (index) => {
        let _tags = this.state.TagList.slice(0);
        _tags.splice(index, 1);
        let _question = this.state.QuestionModal;
        let _tagsText = '';
        _tags.map((data, key) => {
            _tagsText += data.name;
            if (key < _tags.length - 1)
                _tagsText += ',';
            return null;
        })
        _question.Tags = _tagsText;
        this.setState({
            TagList: _tags,
            QuestionModal: _question,
        }, () => {
            this.CheckIfQuestionAllowedToSave();
        });
    }
    TagComponent = ({ tag, removeButtonText, onDelete }) => {
        return (
            <button
                id={tag.id}
                type='button'
                title={removeButtonText}
                onClick={onDelete}
                className={tag.type === 1 ? 'selectedTag subjectTag' : 'selectedTag'}
            >{tag.name}</button>
        );
    }
    SuggestionTagComponent = ({ item, query }) => {
        return (
            this.state.TagList.findIndex(x => x.name === item.name) > -1 ? null :
                <OverlayTrigger
                    // ref={this.SetSuggestionTagCallbackRef}
                    rootClose={true}
                    trigger='click'
                    placement='left'
                    overlay={<Tooltip className='tooltip customTooltipStyle'> A Subject Tag has already been added.<br />Only the first subject tag will be set as question's tag.</Tooltip>}>
                    <span
                        id={item.id}
                        className={
                            item.type === 1 ?
                                item.name === query ?
                                    'suggestionTag match subjectTag'
                                    : 'suggestionTag no-match subjectTag'
                                :
                                item.name === query ?
                                    'suggestionTag match'
                                    : 'suggestionTag no-match'
                        }
                    // hidden={this.state.TagList.findIndex(x => x.name === item.name) > -1}
                    // onClick={() => {
                    //     setTimeout(() => {
                    //         console.log('hiding tag tooltip = ' + item.name + '(' + item.id + ')');
                    //         this.SuggestionTagList_Ref[0].current.rootClose = true;
                    //     }, 1000);
                    // }}
                    > {item.name}</span >
                </OverlayTrigger>
        );
    }
    // SetSuggestionTagCallbackRef = (ref) => {
    //     this.SuggestionTagList_Ref.push(ref);
    // }
    //#endregion    //=== Tags === end ===//


    //#region       //=== Quiz Question - Edit - Form data === start ===//
    PopulateFormUiData = () => {
        if (this.state.QuestionModal !== null) {
            this.EditQs_Content_Ref.current.value = this.state.QuestionModal.Content;
            this.EditQs_Answer_Ref.current.value = this.state.QuestionModal.Answer;
            this.EditQs_Hints_Ref.current.value = CheckNullValue(this.state.QuestionModal.Hints);
            this.EditQs_Tags_Ref.current.value = CheckNullValue(this.state.QuestionModal.Tags);
            this.EditQs_Selection_Ref.current.value = this.state.QuestionModal.Selection;
            // this.EditQs_Picture_Ref.current.value = this.state.QuestionModal.PictureUrl;
            this.EditQs_Picture_Ref.current.value = String(this.state.QuestionModal.PictureUrl).replace(this.state.QuestionModal.UniqueId + '_pk_', '');
            // if (this.state.IsEdit)
            //     this.CheckIfQuestionAllowedToSave();
        }
    }
    SaveDataInput = (_value, _inputType) => {
        let _question = this.state.QuestionModal;
        // let _passages = this.state.ComprehensionPassage;
        let _passages = this.props.ComprehensionPassage;
        switch (_inputType) {
            default: break;
            case QsDataInput.Content: _question.Content = _value; break;
            case QsDataInput.Hints: _question.Hints = CheckNullValue(_value); break;
            case QsDataInput.Tags: _question.Tags = CheckNullValue(_value); break;
            case QsDataInput.Answer: _question.Answer = _value; break;
            case QsDataInput.Selection: _question.Selection = _value; break;
            case QsDataInput.PictureUrl: _question.PictureUrl = _value; break;
            case QsDataInput.ComprehensionPassage:
                let _passageKey = String(this.state.QuestionModal.SpecialMode).split(';')[3];
                let _findIndex = Object.keys(_passages).indexOf(_passageKey);
                if (_findIndex > -1) {
                    _passages[_findIndex] = _value;
                    this.EditQs_Comprehension_Passage_Ref.current.value = _value;
                }
                break;
        }
        this.setState({
            QuestionModal: _question,
            // ComprehensionPassage: _passages,
            IsEdit: true,
        }, () => {
            if (_inputType !== QsDataInput.ComprehensionPassage)
                this.CheckIfQuestionAllowedToSave();

            this.props.UpdateComprehensionPassageArray(_passages);
            if (this.props.isDevMode)
                console.log(JSON.stringify(this.state.QuestionModal));
        });
    }
    GetDataValue = (_inputType) => {
        if (this.state.IsDetailLoadingDone) {
            if (this.state.QuestionModal !== null) {
                let _value = '';
                switch (_inputType) {
                    default: _value = ''; break;
                    case QsDataInput.Content: _value = this.state.QuestionModal.Content; break;
                    case QsDataInput.Hints: _value = CheckNullValue(this.state.QuestionModal.Hints); break;
                    case QsDataInput.Tags: _value = CheckNullValue(this.state.QuestionModal.Tags); break;
                    case QsDataInput.Answer: _value = this.state.QuestionModal.Answer; break;
                    case QsDataInput.Selection: _value = this.state.QuestionModal.Selection; break;
                    case QsDataInput.PictureUrl: _value = this.state.QuestionModal.PictureUrl; break;
                    case QsDataInput.ComprehensionPassage:
                        // let _passages = this.state.ComprehensionPassage;
                        let _passages = this.props.ComprehensionPassage;
                        let _passageKey = String(this.state.QuestionModal.SpecialMode).split(';')[3];
                        let _findIndex = Object.keys(_passages).indexOf(_passageKey);
                        if (_findIndex > -1)
                            _value = _passages[_findIndex];
                        else
                            _value = '';
                        break;
                }
                if (_value !== undefined)
                    return _value;
            }
        }
        return '';
    }
    ResetForm = () => {
        this.setState({
            QuestionModal: JSON.parse(JSON.stringify(this.state.CachedQuestionModal)),
            IsEdit: false,
            AllowToSaveChanges: false,
        }, () => {
            this.PopulateFormUiData();
            // this.CheckIfQuestionAllowedToSave();
            if (this.props.isDevMode)
                console.log('Form reseted.');
        });
    }
    CheckIfQuestionAllowedToSave = () => {
        let _allowed = false;
        if (this.state.QuestionModal !== null) {
            if (
                (
                    //differential checking.
                    JSON.stringify(this.state.QuestionModal) !== JSON.stringify(this.state.CachedQuestionModal)
                )
                &&
                (
                    //normal checking. compulsory fields.
                    this.state.QuestionModal.UniqueId !== ''
                    && this.state.QuestionModal.Answer !== ''
                    && this.state.QuestionModal.Selection !== ''
                    && this.state.QuestionModal.Content !== ''
                )
            ) {
                _allowed = true;
            }
            else {
                _allowed = false;
            }
        }
        this.setState({ AllowToSaveChanges: _allowed, });
    }
    SaveQuestionViaAPI = async () => {
        if (this.state.AllowToSaveChanges) {
            this.setState({
                SavingChangesOnQuestion: true,
                Update_Question_Success: null,
            });
            useAppService.getState().setModal('Update', 'Applying changes...', null, AlertMode.Loading);

            // let _sourceOptions = this.state.QuestionModal.Selection.split(';');
            // let _answerOptions = [];
            // _sourceOptions.map((data, key) => {
            //     let _splits = String(data).split(':');
            //     return _answerOptions.push({
            //         OrderNo: key + 1,
            //         // MarkAsDeleted: false,
            //         Name: _splits[0],                                           //e.g. A / B / C / D...
            //         Value: _splits[1].includes('_pk_') ? null : _splits[1],     //actual content of answer options.
            //         Remark: null,
            //         ImageId: 0,
            //         ImageUrl: _splits[1].includes('_pk_') ? _splits[1] : null,  //if content is a picture. picture name.
            //         IsCorrectAnswer: _splits[0] === this.state.QuestionModal.Answer,
            //     });
            // });

            let _answerOptions = this.GetAnswerOptions();
            if (this.props.isDevMode)
                console.log('answerOptions =\n' + JSON.stringify(_answerOptions));

            //Init.
            let isUpdateSuccess = false;
            let message = '';
            let done = false;

            //2023.09.21
            const { uid, centerUserId, authorId, authorRoleId, organizerId } = GetPropIds(useGlobal.getState().user);

            //Call Api to update changes.
            const jsonModel = {
                OrganizerId: organizerId,
                CenterUserId: centerUserId,
                AuthorId: authorId,
                AuthorRoleId: authorRoleId,    //1 = admin, 4 = center, 11 = Author
                FirebaseUserId: uid,
                // Credential: JSON.stringify(this.props.credential),  //aka Firebase Login Credential     //2021.10.23

                // QuestionSetUniqueId: String(this.props.QuestionSetUniqueId),        //for update to RTDB. standby for ref.
                // QuestionUniqueId: String(this.state.QuestionModal.UniqueId),        //for update to CMS DB > QuizComprehension.
                // QuestionModal: JSON.stringify(this.state.QuestionModal),
                QSetUniqueId: String(this.props.QuestionSetUniqueId),
                UniqueId: CheckObjectStringEmpty(this.state.QuestionModal, 'UniqueId'),
                QuizType: CheckObjectStringEmpty(this.state.QuestionModal, 'QuizType'),
                // Content: this.FilterQuestionContent_Preview(this.state.QuestionModal.Content, true),
                Content: String(this.state.QuestionModal.Content),
                Answer: String(this.state.QuestionModal.Answer),
                Selection: String(this.state.QuestionModal.Selection),
                Hints: CheckObjectStringEmpty(this.state.QuestionModal, 'Hints'),
                Tags: CheckObjectStringEmpty(this.state.QuestionModal, 'Tags'),
                PictureUrl: CheckObjectStringEmpty(this.state.QuestionModal, 'PictureUrl'),
                AnswerOptions: _answerOptions,
                QsNo: CheckObjectNumber(this.state.QuestionModal, 'No'),
                Remark: '',
            };
            if (this.props.isDevMode)
                console.log('question modal =\n' + JSON.stringify(jsonModel));

            await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/QuizBank/Question/Edit/Update',
                {
                    method: 'POST',                             // *GET, POST, PUT, DELETE, etc.
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(jsonModel), // body data type must match "Content-Type" header
                })
                .then(res => res.json())
                .then(data => {
                    // console.log(JSON.stringify(data));
                    isUpdateSuccess = data.success;
                    message = 'api - save - question ('
                        + (data.success ? 'success' : 'failed')
                        + ').\n' + JSON.stringify(data);
                    done = true;
                })
                .catch(error => {
                    message = 'Error : ' + error.message;   //JSON.stringify(error);
                    done = true;
                });
            await DelayUntil(() => done === true);

            if (this.props.isDevMode)
                console.log('api (response) =\n' + message);

            this.setState({
                // CachedQuestionModal: JSON.parse(JSON.stringify(this.state.QuestionModal)),
                SavingChangesOnQuestion: false,
                Update_Question_Success: isUpdateSuccess,
            }, async () => {
                // await this.props.UpdateQuestionListArrayItem(this.state.CachedQuestionModal);
                // this.PopulateFormUiData();
                // this.CheckIfQuestionAllowedToSave();
                // this.props.Callback_ReloadQuestionList(this.state.QuestionModal.No);
                if (isUpdateSuccess) {
                    this.setState({
                        CachedQuestionModal: JSON.parse(JSON.stringify(this.state.QuestionModal)),
                    });
                    useAppService.getState().setModal('', 'Changes to current Question has been updated.');
                    setTimeout(() => {
                        this.props.Callback_ReloadQuestionList(this.state.QuestionModal.No);
                    }, 700);
                }
                else {
                    useAppService.getState().setModal('', 'Changes Update Failed.<br />Please try again.<br />' + message);
                }
            });
        }
    }
    //#endregion    //=== Quiz Question - Edit - Form data === end ===//


    //#region       //=== Answer Options Ui === start ===//
    ShowAnswerOptionsEditMode = () => {
        // let _questionModal = JSON.parse(JSON.stringify(this.state.QuestionModal));
        this.setState({
            EditedAnswerOptions: '',
            ToggleEditAnswerOptions: true,
        });
    }
    SetQuestionAnswer = (_value) => {
        if (this.state.QuestionModal !== null) {
            // let _question = this.state.QuestionModal;
            // _question.Answer = _value;
            this.setState({
                // QuestionModal: _question,
                EditedAnswer: _value,
                // IsEdit: true,
            }, () => {
                // this.PopulateFormUiData();
                this.EditQs_Answer_Ref.current.value = _value; //this.state.QuestionModal.Answer;
            });
        }
    }
    ApplyEditedAnswerOptions = (editedModal = null) => {
        if (editedModal !== null) {
            // editedModal.array[editedModal.index] = editedModal.name + ':' + editedModal.value;

            if (this.props.isDevMode)
                console.log(JSON.stringify(editedModal));

            let alert_component = document.getElementById('AlertText_' + editedModal.name);
            let input_component = document.getElementById('Input_' + editedModal.name);
            let _value = String(editedModal.value);

            // EditQs_AnswerOptionAlertTexts_Ref
            let _isInvalidInput = _value.includes(':') || _value.includes(';') || CheckNullValue(_value) === null;
            if (_isInvalidInput) {
                let alertSymbol = '';
                if (_value.includes(':')) {
                    _value = _value.replaceAll(':', '');
                    alertSymbol += ' < : >';
                }
                if (_value.includes(';')) {
                    _value = _value.replaceAll(';', '');
                    alertSymbol += ' < ; >';
                }
                // this.EditQs_AnswerOptionAlertTexts_Ref[editedModal.index].hidden = false;
                // this.EditQs_AnswerOptionAlertTexts_Ref[editedModal.index].innerHTML =
                //     '* Symbol<b>' + alertSymbol + '</b> is not allowed.';
                // this.EditQs_AnswerOptions_Ref[editedModal.index].value = editedModal.value;     //reset input.

                if (input_component !== null) {
                    input_component.value = CheckStringEmpty(_value, '-');     //reset input.
                }

                //AlertText.
                if (alert_component !== null) {
                    alert_component.hidden = false;
                    if (CheckNullValue(_value) === null)
                        alert_component.innerHTML = "* Empty space is not allowed. At least a symbol <b>-</b> is required.";
                    else
                        alert_component.innerHTML = '* Symbol<b>' + alertSymbol + '</b> is not allowed.';
                }
            }
            else {
                // this.EditQs_AnswerOptionAlertTexts_Ref[editedModal.index].hidden = true;
                // this.EditQs_AnswerOptionAlertTexts_Ref[editedModal.index].innerHTML = '';

                //AlertText.
                if (alert_component !== null) {
                    alert_component.hidden = true;
                    alert_component.innerHTML = '';
                }
            }
            editedModal.array[editedModal.index] = editedModal.name + ':' + (_value.length > 0 ? _value : '-');

            //save input.
            let _editedSelectionText = '';
            editedModal.array.map((data, key) => {
                if (data !== '')
                    _editedSelectionText += data + ';';
                return null;
            });

            // let _questionModal = JSON.parse(JSON.stringify(this.state.QuestionModal));
            // _questionModal.Selection = _editedSelectionText;
            this.setState({
                // QuestionModal: _questionModal,
                EditedAnswerOptions: _editedSelectionText,
                // IsEdit: true,
            }, () => {
                if (this.props.isDevMode)
                    console.log(this.state.EditedAnswerOptions);
                // this.PopulateFormUiData();
                // this.CheckIfQuestionAllowedToSave();
            });
        }
        else {
            let _isEdited = false;
            let _questionModal = JSON.parse(JSON.stringify(this.state.QuestionModal));
            if (this.state.EditedAnswerOptions !== '') {
                _questionModal.Selection = this.state.EditedAnswerOptions;
                if (this.state.CachedQuestionModal.Selection !== _questionModal.Selection)
                    _isEdited = true;
            }
            if (this.state.EditedAnswer !== '') {
                _questionModal.Answer = this.state.EditedAnswer;
                if (this.state.CachedQuestionModal.Answer !== _questionModal.Answer)
                    _isEdited = true;
            }
            if (this.state.IsEdit)
                _isEdited = true;
            // let _isEdited = this.state.QuestionModal.Selection !== _questionModal.Selection;

            // console.log(_isEdited + '\n' + _questionModal.Selection + '\n');

            this.setState({
                QuestionModal: _questionModal,
                EditedAnswerOptions: '',
                EditedAnswer: '',
                ToggleEditAnswerOptions: false,
                IsEdit: _isEdited,
            }, () => {
                this.PopulateFormUiData();
                this.CheckIfQuestionAllowedToSave();
            });
        }
        // this.PopulateFormUiData();
        // this.CheckIfQuestionAllowedToSave();
    }
    Reset_EditedAnswerOptions = () => {
        if (this.state.QuestionModal !== null) {
            // let _questionModal = JSON.parse(JSON.stringify(this.state.QuestionModal));
            // _questionModal.Selection = this.state.CachedQuestionModal.Selection;
            // _questionModal.Selection = this.state.EditedAnswerOptions !== '' ?
            //     this.state.EditedAnswerOptions : this.state.CachedQuestionModal.Selection;
            // _questionModal.Answer = this.state.CachedQuestionModal.Answer;
            // let _editedQsModal = JSON.parse(JSON.stringify(_questionModal));
            this.setState({
                // QuestionModal: _questionModal,
                EditedAnswerOptions: '',
                EditedAnswer: '',
                // IsEdit: false,
                IsEdit: this.state.QuestionModal !== this.state.CachedQuestionModal,
            }, () => {
                this.PopulateFormUiData();

                //reset inputs value.
                if (this.EditQs_AnswerOptions_Ref.length > 0) {
                    this.EditQs_AnswerOptions_Ref.forEach(element => {
                        if (element !== null)
                            element.value = null;
                    });
                }
                //reset radio selection.
                if (this.EditQs_AnswerOptionRadios_Ref.length > 0) {
                    this.EditQs_AnswerOptionRadios_Ref.forEach(element => {
                        if (element !== null)
                            if (element.value === this.state.CachedQuestionModal.Answer)
                                element.checked = true;
                    });
                }
            });
        }
    }
    ResetAndClose_EditedAnswerOptions = () => {
        this.setState({
            // EditedAnswerOptions: '',
            ToggleEditAnswerOptions: false,
        }, () => {
            this.Reset_EditedAnswerOptions();
            this.CheckIfQuestionAllowedToSave();
        });
    }
    Refresh_AnswerOptionsUi = () => {
        this.setState({ RefreshAnswerOptionsUi: true, }, () => {
            setTimeout(() => {
                this.setState({ RefreshAnswerOptionsUi: false, });
            }, 0);
        })
    }
    Increase_Answer_EditedAnswerOptions = () => {
        //2021.08.16
        let _questionModal = JSON.parse(JSON.stringify(this.state.QuestionModal));
        let _selections = this.state.EditedAnswerOptions !== '' ? this.state.EditedAnswerOptions : String(_questionModal.Selection);
        // let _selections = String(_questionModal.Selection);
        // let _selections = this.state.EditedAnswerOptions;

        let answerOptions = _selections.split(';');
        answerOptions = answerOptions.filter((el) => { return (el !== ''); });    //remove empty values.

        if (CheckNullValue(answerOptions) === null) {
            //2023.11.24
            _selections = AtoZ[0] + ':-;';      // 'A:-;'
        }
        else {
            // let answerOptions = String(_questionModal.Selection).split(';');
            let lastAnswer = answerOptions[answerOptions.length - 1].split(':');
            let lastAnswerIndex = AtoZ.findIndex(x => x === lastAnswer[0]);
            if (lastAnswerIndex < 0)
                lastAnswerIndex = answerOptions.length;
            // _questionModal.Selection += AtoZ[lastAnswerIndex + 1] + ':-;';
            _selections += AtoZ[lastAnswerIndex + 1] + ':-;';
        }

        if (this.props.isDevMode)
            console.log(_selections);

        // _questionModal.Selection = _selections;

        this.setState({
            EditedAnswerOptions: _selections,
            // QuestionModal: _questionModal,
            // EditedAnswerOptions: '',
            // EditedAnswer: '',
            // ToggleEditAnswerOptions: false,
            IsEdit: true,
        }, () => {
            this.PopulateFormUiData();
            this.CheckIfQuestionAllowedToSave();
            // this.Refresh_AnswerOptionsUi();
        });
    }
    OpenPicker_ReduceAnswerOptions = () => {
        //2021.08.16
    }
    Reduce_Answer_EditedAnswerOptions = (_index = undefined) => {
        //2021.08.16
        let _questionModal = JSON.parse(JSON.stringify(this.state.QuestionModal));
        let _selections = this.state.EditedAnswerOptions !== '' ? this.state.EditedAnswerOptions : String(_questionModal.Selection);
        // let _selections = String(_questionModal.Selection);
        // let _selections = this.state.EditedAnswerOptions;

        let answerOptions = _selections.split(';');
        answerOptions = answerOptions.filter((el) => { return (el !== ''); });    //remove empty values.

        let newAnswerOptions = [];
        let newAnswerOptionsText = '';

        if (_index === undefined)
            _index = answerOptions.length - 1;

        answerOptions.map((data, key) => {
            if (key !== _index)
                newAnswerOptions.push(data);
            return null;
        });
        newAnswerOptions.map((data, key) => {
            let answer = String(data).split(':');
            data = AtoZ[key] + ':' + answer[1] + ';';
            newAnswerOptionsText += data;
            return null;
        });

        if (this.props.isDevMode)
            console.log(newAnswerOptionsText);

        _questionModal.Selection = newAnswerOptionsText;

        this.setState({
            EditedAnswerOptions: newAnswerOptionsText,
            // QuestionModal: _questionModal,
            // EditedAnswerOptions: '',
            // EditedAnswer: '',
            // ToggleEditAnswerOptions: false,
            IsEdit: true,
        }, () => {
            this.PopulateFormUiData();
            this.CheckIfQuestionAllowedToSave();
            // this.Refresh_AnswerOptionsUi();
        });
    }
    AnswerOptionsUi = () => {
        if (this.state.QuestionModal !== null) {
            let _optionModals = [];
            let _selection = String(this.state.EditedAnswerOptions === '' ? this.state.QuestionModal.Selection : this.state.EditedAnswerOptions);
            // let _selection = String(this.state.QuestionModal.Selection);
            if (_selection.includes(';')) {
                let _options = _selection.split(';');
                let _flag = false;
                _options.map((data, key) => {
                    if (data.includes(':')) {
                        let _values = data.split(':');
                        if (this.state.ToggleEditAnswerOptions) {
                            //Edit Mode.
                            let _isEdited = false;
                            let _sourceValue = '';
                            // if (this.state.EditedAnswerOptions !== '') {
                            let _sourceOptions = this.state.QuestionModal.Selection.split(';');
                            // let _sourceOptions = this.state.EditedAnswerOptions.split(';');
                            if (!_flag && this.props.isDevMode) {
                                console.log('<' + this.state.QuestionModal.Selection + '> vs <' + this.state.EditedAnswerOptions + '>');
                                _flag = true;
                            }
                            _sourceValue = String(_sourceOptions[key]).split(':')[1];
                            _isEdited = _sourceValue !== undefined ? _values[1] !== _sourceValue : false;
                            // }
                            _optionModals.push(<tr key={'option' + key}>
                                {/* <label htmlFor={_values[0]} onClick={() => this.SetQuestionAnswer(_values[0])}> */}
                                <td width='40' valign='middle'>
                                    <input
                                        id={_values[0]}
                                        key={'Answer_' + _values[0]}
                                        // ref={this.SetAnswerOptionRadiosCallbackRef}
                                        type="radio"
                                        radioGroup="answerOptions"
                                        name="answerOptions"
                                        value={_values[0]}
                                        style={{ marginTop: 7, cursor: 'pointer' }}
                                        checked={
                                            this.state.EditedAnswer !== '' ?
                                                this.state.EditedAnswer === _values[0]
                                                :
                                                this.state.QuestionModal.Answer === _values[0]
                                        }
                                        onChange={() => this.SetQuestionAnswer(_values[0])}
                                    />
                                </td>
                                <td width='40' onClick={() => this.SetQuestionAnswer(_values[0])} style={{ cursor: 'pointer' }} valign='middle'>
                                    <b>{_values[0]}</b>
                                </td>
                                {/* </label> */}
                                <td width='100%'>
                                    <OverlayTrigger overlay={_values[1].includes('_pk_') === false ? <></> :
                                        <Tooltip><img src={'https://ikeynew.blob.core.windows.net/ikeykidz/JPG/' + _values[1] + '.jpg'} alt="" width="100%" /></Tooltip>}>
                                        <input
                                            id={'Input_' + _values[0]}
                                            ref={this.SetAnswerOptionsCallbackRef}
                                            type="text"
                                            style={{ width: '94%', height: 36, marginBottom: 5 }}
                                            placeholder={_values[1].replace(this.state.QuestionModal.UniqueId + '_pk_', '')}
                                            value={_values[1].includes('_pk_') ? '' : _values[1]}
                                            onChange={(e) => this.ApplyEditedAnswerOptions({
                                                index: key, name: _values[0], value: e.target.value,
                                                array: _options
                                            })}
                                            disabled={_values[1].includes('_pk_')}
                                        />
                                    </OverlayTrigger>
                                    <OverlayTrigger overlay={<Tooltip><span>Attach Picture</span></Tooltip>}>
                                        <Button
                                            variant="primary"
                                            onClick={() => this.ToggleModalUi_AnswerOption_PictureUpload(key)}
                                            // style={{ padding: 5, position: 'absolute', right: 25, width: 34, textAlign: 'center', }}
                                            style={{ padding: 5, position: 'absolute', marginLeft: 10, width: 34, textAlign: 'center', }}
                                            hidden={_values[1].includes('_pk_')}
                                        ><i className="fa fa-picture-o" style={{ fontSize: "20px", color: "white" }}></i></Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger overlay={<Tooltip><span>Remove Picture</span></Tooltip>}>
                                        <Button
                                            variant="secondary"
                                            onClick={() => this.RemovePicture_AnswerOption(key)}
                                            // style={{ padding: 5, position: 'absolute', right: 25, width: 34, textAlign: 'center', }}
                                            style={{ padding: 5, position: 'absolute', marginLeft: 10, width: 34, textAlign: 'center', }}
                                            hidden={_values[1].includes('_pk_') === false}
                                        ><i className="fa fa-remove" style={{ fontSize: "20px", color: "white", }}></i></Button>
                                    </OverlayTrigger>
                                    <br />{
                                        _isEdited ?
                                            <span style={{ fontSize: 14, color: 'gray' }}>{_sourceValue}</span>
                                            : null
                                    }
                                    <div
                                        id={'AlertText_' + _values[0]}
                                        // ref={this.SetAnswerOptionAlertTextsCallbackRef}
                                        // ref={this.EditQs_AnswerOptionAlertTexts_Ref}
                                        className='blink-fast'
                                        style={{ fontSize: 14, color: 'blue' }}
                                    ></div>
                                </td>
                            </tr>);
                        }
                        else {
                            //View Mode.
                            _optionModals.push(<tr key={'option' + key}>
                                <td width='40' align='center'>{
                                    this.state.EditedAnswer !== '' ?
                                        this.state.EditedAnswer === _values[0] ?
                                            <Badge variant='primary' style={{ fontSize: 14 }}>{_values[0]}</Badge>
                                            :
                                            <b>{_values[0]}</b>
                                        :
                                        this.state.QuestionModal.Answer === _values[0] ?
                                            <Badge variant='primary' style={{ fontSize: 14 }}>{_values[0]}</Badge>
                                            :
                                            <b>{_values[0]}</b>
                                }</td>
                                <td>{
                                    _values[1].includes('_pk_') ?
                                        <OverlayTrigger overlay={<Tooltip><img src={'https://ikeynew.blob.core.windows.net/ikeykidz/JPG/' + _values[1] + '.jpg'} alt="" width="100%" /></Tooltip>}>
                                            <table className='tb-no-padding'>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: 20 }}>
                                                            <i className="fa fa-picture-o" style={{ fontSize: "15px", color: "blue" }}></i>
                                                        </td>
                                                        <td>&nbsp;&nbsp;</td>
                                                        <td style={{ width: '100%' }}>
                                                            <div dangerouslySetInnerHTML={{ __html: _values[1].replace(this.state.QuestionModal.UniqueId + '_pk_', '') }}
                                                                style={{ width: '100%' }}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </OverlayTrigger>
                                        // <br />
                                        // <img src={'https://ikeynew.blob.core.windows.net/ikeykidz/JPG/' + _values[1] + '.jpg'} alt='' width='100%'></img>
                                        : _values[1]
                                }</td>
                            </tr>);
                        }
                    }
                    return null;
                });
            }
            return _optionModals;
        }
        return null;
    }
    SetAnswerOptionsCallbackRef = (ref) => {
        this.EditQs_AnswerOptions_Ref.push(ref);
    }
    SetAnswerOptionRadiosCallbackRef = (ref) => {
        this.EditQs_AnswerOptionRadios_Ref.push(ref);
    }
    // SetAnswerOptionAlertTextsCallbackRef = (ref) => {
    //     this.EditQs_AnswerOptionAlertTexts_Ref.push(ref);
    // }
    GetAnswerOptions = () => {
        let _answerOptions = [];
        if (this.state.QuestionModal !== null) {
            let _sourceOptions = String(this.state.QuestionModal.Selection).split(';');
            _sourceOptions = _sourceOptions.filter((el) => { return (el !== ''); });    //remove empty values.
            _sourceOptions.map((data, key) => {
                if (data !== undefined) {
                    let _splits = data.split(':');
                    _answerOptions.push({
                        OrderNo: key + 1,
                        // MarkAsDeleted: false,
                        Name: _splits[0],                                           //e.g. A / B / C / D...
                        Value: String(_splits[1]).includes('_pk_') ? '' : _splits[1],     //actual content of answer options.
                        Remark: '',
                        ImageId: 0,
                        ImageUrl: String(_splits[1]).includes('_pk_') ? _splits[1] : '',  //if content is a picture. picture name.
                        IsCorrectAnswer: _splits[0] === this.state.QuestionModal.Answer,
                    });
                }
                return null;
            });
        }
        return _answerOptions;
    }
    //#endregion    //=== Answer Options Ui === end ===//


    //#region       //=== Comprehension Ui === start ===//
    LoadComprehensionPassage = async () => {
        this.setState({
            ToggleComprehensionPassage: true,
            IsLoaded_ComprehensionPassage: false,
        });

        //Init.
        let _passageKey = String(this.state.QuestionModal.SpecialMode).split(';')[3];
        // let _passages = this.state.ComprehensionPassage;
        let _passages = this.props.ComprehensionPassage;
        // _passages.push({ Content_16_20: 'this is passage sample.' });   //demo.

        let _findIndex = Object.keys(_passages).indexOf(_passageKey);
        // // console.log(JSON.stringify(_passages));
        // console.log('(1)\nkey = ' + _passageKey + '\npassages = ' + _passages.length + '\nfindIndex = ' + _findIndex);

        if (_findIndex < 0) {
            let _tempArray = [];
            //Load Passage from RTDB.
            await get(ref(this.props.dbQuizBank, String(this.props.QuestionSetUniqueId) + '/ExtraContent'))
                .then(snapshot => {
                    if (snapshot.exists())
                        _tempArray = snapshot.val();
                });
            // await this.props.dbQuizBank
            //     .ref(this.props.QuestionSetUniqueId + '/ExtraContent')
            //     .once('value', snapshot => {
            //         // handle read data.
            //         if (snapshot.exists()) {
            //             _tempArray = snapshot.val();
            //         }
            //     });
            _passages = _tempArray;
            // console.log(JSON.stringify(_tempArray));
            // console.log(JSON.stringify(_passages));
            // _findIndex = Object.keys(_passages).indexOf(_passageKey);
            // // console.log(JSON.stringify(_tempArray));
            // console.log('(2)\nkey = ' + _passageKey + '\npassages = ' + _passages.length + '\nfindIndex = ' + _findIndex);
            await Delay(1000);
            this.setState({
                CachedComprehensionPassage: JSON.parse(JSON.stringify(_tempArray)),
            });
        }
        // await Delay(1500);
        this.setState({
            IsLoaded_ComprehensionPassage: true,
            // ComprehensionPassage: _passages,
        }, () => {
            this.props.UpdateComprehensionPassageArray(_passages);
        });
    }
    ShowComprehensionPassageEditMode = () => {
        // let _passages = this.state.ComprehensionPassage;
        let _passages = this.props.ComprehensionPassage;
        let _passageKey = String(this.state.QuestionModal.SpecialMode).split(';')[3];
        let _passage = _passages[_passageKey];
        // let _findIndex = Object.keys(_passages).indexOf(_passageKey);
        // let _passage = _findIndex > -1 ? _passages[_findIndex] : '';
        this.EditQs_Comprehension_Passage_Ref.current.value = _passage;
        this.setState({
            ToggleEditComprehensionPassage: true
        }, () => {
            ScrollToElement('EditComprehension', 0);
        });
    }
    ApplyChangesOnComprehensionPassageViaAPI = async () => {
        this.setState({
            ToggleEditComprehensionPassage: false,
            SavingChangesOnComprehensionPassage: true,
            Update_ComprehensionPassage_Success: null,
        });
        useAppService.getState().setModal('Update', 'Applying changes...', null, AlertMode.Loading);

        //Assign value.
        let _value = this.EditQs_Comprehension_Passage_Ref.current.value;
        // let _passages = this.state.ComprehensionPassage;
        let _passages = this.props.ComprehensionPassage;
        let _passageKey = String(this.state.QuestionModal.SpecialMode).split(';')[3];

        let isUpdateSuccess = false;
        let message = '';

        if (_value !== '' || _value !== undefined) {
            _passages[_passageKey] = _value;

            if (this.props.isDevMode)
                console.log(_passages);
            // await Delay(2000);

            const { uid, centerUserId, authorId, authorRoleId } = GetPropIds(useGlobal.getState().user);

            //Call Api to update changes.
            let jsonModel = {
                CenterUserId: centerUserId,
                AuthorId: authorId,
                AuthorRoleId: authorRoleId,    //1 = admin, 4 = center, 11 = Author
                FirebaseUserId: uid,

                QuestionSetUniqueId: String(this.props.QuestionSetUniqueId),        //for update to RTDB. standby for ref.
                QuestionUniqueId: String(this.state.QuestionModal.UniqueId),        //for update to CMS DB > QuizComprehension.
                ComprehensionIdentity: String(_passageKey),                         //e.g. Content_16_20
                ComprehensionPassage: String(_value),                               //passge content to be updated.
            };
            await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/QuizBank/Comprehension/Passage/Update',
                {
                    method: 'POST',                             // *GET, POST, PUT, DELETE, etc.
                    // mode: 'cors',                            // no-cors, *cors, same-origin
                    // cache: 'no-cache',                          // *default, no-cache, reload, force-cache, only-if-cached
                    // credentials: 'same-origin',                 // include, *same-origin, omit
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    // redirect: 'follow',                         // manual, *follow, error
                    // referrerPolicy: 'no-referrer',              // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    body: JSON.stringify(jsonModel), // body data type must match "Content-Type" header
                })
                .then(res => res.json())
                .then(data => {
                    isUpdateSuccess = data.success;
                    message = 'api upload - comprehension ('
                        + (data.success ? 'success' : 'failed')
                        + ').\n' + JSON.stringify(data);
                })
                .catch(error => {
                    message = 'Error : ' + error.message;   //JSON.stringify(error);
                });

            if (this.props.isDevMode)
                console.log('api (response) =\n' + message);
        }
        this.setState({
            // ComprehensionPassage: _passages,
            CachedComprehensionPassage: JSON.parse(JSON.stringify(_passages)),
            ToggleEditComprehensionPassage: false,
            SavingChangesOnComprehensionPassage: false,
            Update_ComprehensionPassage_Success: isUpdateSuccess,
        }, () => {
            this.props.UpdateComprehensionPassageArray(_passages);
            if (isUpdateSuccess)
                useAppService.getState().setModal('', 'Changes to current Comprehension Passage has been updated.');
            else
                useAppService.getState().setModal('', 'Changes Update Failed.<br />Please try again.<br />' + message);
        });
    }
    ResetComprehensionPassage = () => {
        this.setState({
            EditedComprehensionPassage: ''
        }, () => {
            // let _passages = this.state.ComprehensionPassage;
            let _passages = this.props.ComprehensionPassage;
            let _passageKey = String(this.state.QuestionModal.SpecialMode).split(';')[3];
            let _passage = _passages[_passageKey];
            // let _findIndex = Object.keys(_passages).indexOf(_passageKey);
            // let _passage = _findIndex > -1 ? _passages[_findIndex] : '';
            this.EditQs_Comprehension_Passage_Ref.current.value = _passage;
        });
    }
    ResetAndClose_EditComprehensionPassage = () => {
        // // let _passages = this.state.ComprehensionPassage;
        // let _passages = this.props.ComprehensionPassage;
        // let _passageKey = String(this.state.QuestionModal.SpecialMode).split(';')[3];
        // let _passage = _passages[_passageKey];
        // // let _findIndex = Object.keys(_passages).indexOf(_passageKey);
        // // let _passage = _findIndex > -1 ? _passages[_findIndex] : '';
        // this.EditQs_Comprehension_Passage_Ref.current.value = _passage;
        this.setState({
            // EditedComprehensionPassage: '',
            ToggleEditComprehensionPassage: false,
        }, () => {
            this.ResetComprehensionPassage();
            ScrollToElement('EditComprehension', 0);
        });
    }
    ComprehensionPassageHtmlUi = () => {
        // console.log(JSON.stringify(this.state.ComprehensionPassage));
        // console.log(this.state.ComprehensionPassage !== []);
        // if (this.state.ComprehensionPassage !== []) {
        if (Array.isArray(this.props.ComprehensionPassage)) {
            if (this.props.ComprehensionPassage.length > 0) {
                if (this.state.IsLoaded_ComprehensionPassage) {
                    let _passage = '';
                    if (this.state.EditedComprehensionPassage === '') {
                        // let _passages = this.state.ComprehensionPassage;
                        let _passages = this.props.ComprehensionPassage;
                        let _passageKey = String(this.state.QuestionModal.SpecialMode).split(';')[3];
                        _passage = _passages[_passageKey];
                    }
                    else {
                        _passage = this.state.EditedComprehensionPassage;
                    }
                    // let _findIndex = Object.keys(_passages).indexOf(_passageKey);
                    // let _passage = _findIndex > -1 ? String(_passages[_passageKey]) : '';
                    // console.log(JSON.stringify(_passages));
                    // console.log(_passageKey + '\n' + _findIndex + '\n' + _passages[_passageKey] + '\n' + _passage);
                    return (<div style={{ border: '1px solid blue', borderRadius: 5, padding: 10, }}>
                        <div hidden={this.state.ToggleEditComprehensionPassage === true}>
                            <Button
                                variant="primary"
                                onClick={() => this.ShowComprehensionPassageEditMode()}
                            >Edit Passage</Button>&nbsp;&nbsp;
                            <Button
                                variant="secondary"
                                onClick={() => this.setState({ ToggleComprehensionPassage: false })}
                            >Hide Passage</Button>
                        </div>
                        <div hidden={this.state.ToggleEditComprehensionPassage === false}>
                            <Button
                                variant="primary"
                                onClick={() => this.ApplyChangesOnComprehensionPassageViaAPI()}
                            >Apply changes</Button>&nbsp;&nbsp;
                            <Button
                                variant="secondary"
                                onClick={() => this.ResetComprehensionPassage()}
                            >Reset</Button>&nbsp;&nbsp;
                            <Button
                                variant="secondary"
                                onClick={() => this.ResetAndClose_EditComprehensionPassage()}
                            >Reset & Close Edit</Button>
                        </div>
                        <br />
                        <div hidden={this.state.SavingChangesOnComprehensionPassage === false}>
                            {/* <LoadingIndicator /> */}
                            <ProgressBar animated now={100} className='progressbar1' />
                            <br />
                        </div>
                        <div hidden={this.state.Update_ComprehensionPassage_Success !== false}>
                            <Button
                                variant="primary"
                                onClick={() => this.ApplyChangesOnComprehensionPassageViaAPI()}
                            >Retry</Button>&nbsp;&nbsp;
                            <br />
                        </div>
                        <div
                            // hidden={this.state.SavingChangesOnComprehensionPassage === true}
                            dangerouslySetInnerHTML={{ __html: _passage }}
                        />
                        <div id='EditComprehension' hidden={this.state.ToggleEditComprehensionPassage === false}>
                            <br />
                            <textarea
                                ref={this.EditQs_Comprehension_Passage_Ref}
                                rows="10"
                                // cols="35"
                                style={{ width: '100%' }}
                                // onChange={(e) => this.SaveDataInput(e.target.value, QsDataInput.ComprehensionPassage)}
                                onChange={(e) => this.setState({ EditedComprehensionPassage: e.target.value })}
                            ></textarea>
                            {/* <br /> */}
                        </div>
                    </div >);
                }
                else {
                    return <ProgressBar animated now={100} className='progressbar1' />
                }
            }
        }
        return null;
    }
    //#endregion    //=== Comprehension Ui === end ===//


    // //2021.09.23 === 5 alphanumeric letters
    // RandomId = () => {
    //     var text = "";
    //     var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    //     for (var i = 0; i < 5; i++) {
    //         text += possible.charAt(Math.floor(Math.random() * possible.length));
    //     }
    //     return text;
    // }


    //#region       //=== Upload Picture (Content) === start ===//  2021.08.20
    ContentPictureComponentUi = () => {
        switch (this.state.Upload_PictureForContent_State) {
            default:
                return (
                    this.state.ToggleUploadContentPictureUi ?
                        <>
                            <input
                                type="file"
                                onChange={this.onUploadContentPictureFileChange}
                                style={{ width: '100%' }}
                                hidden={!this.state.ToggleUploadContentPictureUi}
                                name='image'
                                accept='.jpg'
                            />&nbsp;
                            <Button
                                variant="secondary"
                                onClick={() => this.setState({ ToggleUploadContentPictureUi: !this.state.ToggleUploadContentPictureUi, })}
                            >Cancel</Button>
                            <br />
                            {
                                this.state.QuestionModal !== null ?
                                    this.state.QuestionModal.hasOwnProperty('ContentPictures') ?
                                        this.state.QuestionModal.ContentPictures !== undefined ?
                                            this.state.QuestionModal.ContentPictures.length > 0 ?
                                                this.ContentPicturesItemUi()
                                                : null
                                            : null
                                        : null
                                    : null
                            }
                        </>
                        :
                        <>
                            <Button
                                variant="primary"
                                onClick={() => this.setState({ ToggleUploadContentPictureUi: !this.state.ToggleUploadContentPictureUi, })}
                            >Upload Picture</Button>
                            <br />
                            {
                                this.state.QuestionModal !== null ?
                                    this.state.QuestionModal.hasOwnProperty('ContentPictures') ?
                                        this.state.QuestionModal.ContentPictures !== undefined ?
                                            this.state.QuestionModal.ContentPictures.length > 0 ?
                                                this.ContentPicturesItemUi()
                                                : null
                                            : null
                                        : null
                                    : null
                            }
                        </>
                );
            case 1: return <ProgressBar animated now={100} className='progressbar1' />;
            case 2: return (<>
                <Button
                    variant="primary"
                    onClick={() => this.RetryUploadContentPicture()}
                >Retry</Button>&nbsp;
                <Button
                    variant="secondary"
                    onClick={() => {
                        this.onUploadContentPictureFileChange(null);
                        this.setState({
                            ToggleUploadContentPictureUi: false,
                            Upload_PictureForContent_State: 0,
                        });
                    }}
                >Cancel</Button>
            </>);
        }
    }
    ContentPicture_PictureUpload_ModalComponent = () => {
        if (this.state.ToggleContentPictureUploadPictureUiModal) {
            let modalBodyComponent = () => {
                switch (this.state.Upload_PictureForContent_State) {
                    //0, pick index.
                    default: return (<>
                        <input
                            type="file"
                            onChange={this.onUploadAnswerPictureFileChange}
                            style={{ width: '100%' }}
                            name='image'
                            accept='.jpg'
                        />
                    </>);

                    //processing.
                    case 1: return (<>Uploading Picture...<br /><ProgressBar animated now={100} className='progressbar1' /></>);

                    //failed.
                    case 2: return (<>Picture upload had failed.<br />Please try again.</>);

                    //success.
                    case 3: return (<>Picture has been uploaded successfully.</>);
                }
            };
            return (
                <Modal show={this.state.ToggleContentPictureUploadPictureUiModal}
                    onHide={
                        this.state.Upload_PictureForContent_State === 0 ||
                            this.state.Upload_PictureForContent_State === 3
                            ? this.ToggleModalUi_AnswerOption_PictureUpload : DoNothing
                    }
                    centered>
                    <Modal.Header
                        closeButton={
                            this.state.Upload_PictureForContent_State === 0
                            || this.state.Upload_PictureForContent_State === 3
                        }
                    >
                        <Modal.Title>Upload Picture for Question [<b>#{this.state.QuestionModal.No}</b>]<br />Content Picture [<b>#{this.state.SelectedContentPictureIndex + 1}</b>]</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {modalBodyComponent()}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => this.ToggleModalUi_ContentPicture_PictureUpload()}
                            hidden={this.state.Upload_PictureForContent_State === 0
                                || this.state.Upload_PictureForContent_State === 3
                                ? false : true}
                        >Close</Button>
                        <div hidden={this.state.Upload_PictureForContent_State !== 2}>
                            <Button
                                variant="primary"
                                onClick={() => this.RetryUploadPicture_AnswerOption()}
                            >Retry</Button>&nbsp;
                            <Button
                                variant="secondary"
                                // onClick={this.RetryUploadPicture_AnswerOption}
                                onClick={() => this.ToggleModalUi_ContentPicture_PictureUpload()}
                            >Cancel</Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            );
        }
        return null;
    }
    ContentPicturesItemUi = () => {
        let components = [];
        this.state.QuestionModal.ContentPictures.map((data, key) => {
            if (data !== '') {
                components.push(<div className="col">
                    <table className='table tbLite' cellPadding='0' cellSpacing='0' width='100%' border='1' style={{ marginBottom: 10, }}>
                        <tbody>
                            <tr>
                                <td rowSpan='2' valign='top'>
                                    <img src={this.GetPictureUrl(data)} width='100%' alt='' title={String(data)}></img>
                                </td>
                                <td className='qs-content-picture-td'>
                                    <OverlayTrigger overlay={<Tooltip><span>Attach Picture</span></Tooltip>}>
                                        <Button
                                            variant="primary"
                                            onClick={() => this.ToggleModalUi_ContentPicture_PictureUpload(key)}
                                            style={{ padding: 5, width: 34, textAlign: 'center', }}
                                        // hidden={data.includes('_pk_')}
                                        ><i className="fa fa-picture-o" style={{ fontSize: "20px", color: "white" }}></i></Button>
                                    </OverlayTrigger>
                                </td>
                            </tr>
                            <tr>
                                <td className='qs-content-picture-td'>
                                    <OverlayTrigger overlay={<Tooltip><span>Remove Picture</span></Tooltip>}>
                                        <Button
                                            variant="secondary"
                                            onClick={() => this.RemovePicture_ContentPicture(key)}
                                            style={{ padding: 5, width: 34, textAlign: 'center', }}
                                        // hidden={data.includes('_pk_') === false}
                                        ><i className="fa fa-remove" style={{ fontSize: "20px", color: "white", }}></i></Button>
                                    </OverlayTrigger>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>);
                if (key > 0 && ((key + 1) % 2) === 0)
                    components.push(<div className="w-100"></div>);
            }
            return null;
        });
        if (this.state.QuestionModal.ContentPictures.length % 2 > 0) {
            components.push(<div className='col'>&nbsp;</div>);
        }
        return <div className="row" style={{ padding: 0, marginTop: 10 }}>{components}</div>;
    }
    ToggleModalUi_ContentPicture_PictureUpload = (_index = -1) => {
        // console.log(_index);
        this.setState({ ToggleContentPictureUploadPictureUiModal: !this.state.ToggleContentPictureUploadPictureUiModal, });
        if (_index > -1) {
            this.setState({
                SelectedContentPictureIndex: _index,
                Upload_PictureForContent_State: 0,
            }, () => {
                this.AppendPictureAtEndOfContent();
            });
        }
    }
    AppendPictureAtEndOfContent = () => {
        if (this.state.QuestionModal !== undefined) {
            let qsModal = this.state.QuestionModal;
            let contentPictures = this.state.QuestionModal.ContentPictures;
            qsModal.Content += '\n\n<img>' + contentPictures[this.state.SelectedContentPictureIndex] + '</img>';
            this.setState({
                QuestionModal: qsModal,
            }, () => {
                this.PopulateFormUiData();
                this.CheckIfQuestionAllowedToSave();
            });
        }
    }
    onUploadContentPictureFileChange = (event) => {
        this.setState({
            PictureFileToUpload: event === null ? null : event.target.files[0],
            ToggleUploadContentPictureUi: event === null ? false : !this.state.ToggleUploadContentPictureUi,
            Upload_PictureForContent_State: 1,

            //2021.09.27
            //Picture FileName.
            ContentPictureFileName: event === null ? ''
                : this.state.QuestionModal.UniqueId + '_pk_' + RandomId(),

            //Picture FileName.
            // ContentPictureFileName: event === null ? ''
            //     : this.state.QuestionModal.UniqueId + '_pk_' + this.state.QuestionModal.No + '_' + RandomId(),
            // + (this.state.QuestionModal.ContentPictures !== undefined ? this.state.QuestionModal.ContentPictures.length + 1 : 1),
        }, async () => {
            //Upload picture file.
            if (this.state.PictureFileToUpload !== null) {
                await this.UploadContentPictureForQuizQuestion(event === null);
            }
            else {
                this.setState({
                    PictureFileToUpload: null,
                    ContentPictureFileName: '',
                });
            }
        });
    }
    UploadContentPictureForQuizQuestion = async (_removePicture = false) => {
        // this.props.SetLoading('', 'saving image...', false);
        useAppService.getState().setModal('', 'updating picture...', null, AlertMode.Loading);

        await this.UploadPictureForQuizQuestion(_removePicture, false, true);
    }
    RetryUploadContentPicture = () => {
        this.setState({
            ToggleUploadContentPictureUi: false,
            Upload_PictureForContent_State: 1,
        }, () => {
            this.UploadContentPictureForQuizQuestion();
        });
    }
    RemovePicture_ContentPicture = (_index) => {
        this.setState({
            ToggleContentPictureUploadPictureUiModal: true,
            Upload_PictureForContent_State: 1,
            SelectedContentPictureIndex: _index,
            ContentPictureFileName: this.state.QuestionModal.ContentPictures[_index],   //2021.09.27
        }, () => {
            // this.onUploadContentPictureFileChange(null);
            this.UploadContentPictureForQuizQuestion(true);     //2021.09.27
        });
    }
    //#endregion    //=== Upload Picture (Content) === end ===//


    //#region       //=== Upload Picture (Question) === start ===//
    PictureComponentUi = () => {
        switch (this.state.Upload_Picture_State) {
            default:
                return (
                    this.state.ToggleUploadPictureUi ?
                        <>
                            <input
                                type="file"
                                onChange={this.onUploadPictureFileChange}
                                style={{ width: '100%' }}
                                hidden={!this.state.ToggleUploadPictureUi}
                                name='image'
                                accept='.jpg'
                            />&nbsp;
                            <Button
                                variant="secondary"
                                onClick={() => this.setState({ ToggleUploadPictureUi: !this.state.ToggleUploadPictureUi, })}
                            >Cancel</Button>
                        </>
                        :
                        this.state.QuestionModal !== null ?
                            this.state.QuestionModal.PictureUrl === '' ?
                                <Button
                                    variant="primary"
                                    onClick={() => this.setState({ ToggleUploadPictureUi: !this.state.ToggleUploadPictureUi, })}
                                >Attach Picture</Button>
                                :
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        this.onUploadPictureFileChange(null);
                                    }}
                                >Remove Picture</Button>
                            : null
                );
            case 1: return <ProgressBar animated now={100} className='progressbar1' />;
            case 2: return (<>
                <Button
                    variant="primary"
                    onClick={() => this.RetryUploadPicture()}
                >Retry</Button>&nbsp;
                <Button
                    variant="secondary"
                    onClick={() => {
                        this.onUploadPictureFileChange(null);
                        this.setState({
                            ToggleUploadPictureUi: false,
                            Upload_Picture_State: 0,
                        });
                    }}
                >Cancel</Button>
            </>);
        }
    }
    onUploadPictureFileChange = (event) => {
        this.setState({
            PictureFileToUpload: event === null ? null : event.target.files[0],
            ToggleUploadPictureUi: event === null ? false : !this.state.ToggleUploadPictureUi,
            Upload_Picture_State: 1,
        }, () => {
            //Picture FileName.
            var _fileName = event === null ? '' : this.state.QuestionModal.UniqueId + '_pk_' + RandomId();     //2021.09.27
            // var _fileName = event === null ? '' : this.state.QuestionModal.UniqueId + '_pk_' + this.state.QuestionModal.No + '_' + RandomId();
            this.SaveDataInput(_fileName, QsDataInput.PictureUrl);

            useAppService.getState().setModal('', 'updating picture...', null, AlertMode.Loading);

            //Upload picture file.
            // if (this.state.PictureFileToUpload !== null) {
            this.UploadPictureForQuizQuestion(event === null);
            // }
        });
    };
    UploadPictureForQuizQuestion = async (_removePicture = false, _isAnswerOption = false, _isContentPicture = false) => {

        // this.props.SetAlertWithProgressBar('', 'updating picture...', true);
        // let success = false;
        await Delay(500);

        let isUpdateSuccess = false;
        let message = '';

        //Convert Picture to Base64.
        let _base64 = '';
        if (this.state.PictureFileToUpload !== null) {
            // let reader = new FileReader();
            // reader.onload = (evt) => {
            //     let binaryString = evt.target.result;
            //     _base64 = btoa(binaryString);
            //     // console.log('inner : ' + _base64);
            // };
            // reader.readAsArrayBuffer(this.state.PictureFileToUpload);

            //2025.02.03
            try {
                _base64 = await ToBase64(this.state.PictureFileToUpload);
            } catch (error) {
                window.alert(error);
                return null;
            }
        }
        // await Delay(1000);
        // console.log(_base64);


        //=== answer options === start ===//
        let _answerOptions = this.GetAnswerOptions();
        await Delay(500);
        let _pictureUrl = String(this.state.QuestionModal.PictureUrl);
        if (_isAnswerOption === false && _base64 === '') {
            _pictureUrl = String(this.state.CachedQuestionModal.PictureUrl);
        }
        let _answerPictureName = _isAnswerOption ? this.state.SelectedAnswerOptionPictureName : '';
        //=== answer options === end ===//


        //2021.09.09
        //=== content picture === start ===//
        let _contentPictures = [];
        if (_isContentPicture) {
            if (this.state.QuestionModal.hasOwnProperty('ContentPictures')) {
                if (_removePicture === false) {
                    //add picture.
                    var findIndex = this.state.QuestionModal.ContentPictures.findIndex(x => x === this.state.ContentPictureFileName);
                    if (findIndex < 0)
                        _contentPictures.push(this.state.ContentPictureFileName);
                }
                else {
                    //remove picture.
                    _contentPictures = _contentPictures.filter(x => { return x !== this.state.ContentPictureFileName; });

                    //remove picture tag from content.
                    let _question = this.state.QuestionModal;
                    _question.Content = String(_question.Content).replace('<img>' + this.state.ContentPictureFileName + '</img>', '');
                    this.setState({ QuestionModal: _question });
                    await Delay(0);
                }
            }
            else {
                if (_removePicture === false)
                    _contentPictures.push(this.state.ContentPictureFileName);
            }
        }
        //=== content picture === end ===//

        //2023.09.21
        const { uid, centerUserId, authorId, authorRoleId, organizerId } = GetPropIds(useGlobal.getState().user);

        //EditQuestionModel.
        let _editQuestionJsonModel = {
            OrganizerId: organizerId,
            CenterUserId: centerUserId,
            AuthorId: authorId,
            AuthorRoleId: authorRoleId,    //1 = admin, 4 = center, 11 = Author
            FirebaseUserId: uid,

            QSetUniqueId: String(this.props.QuestionSetUniqueId),
            UniqueId: String(this.state.QuestionModal.UniqueId),
            QuizType: String(this.state.QuestionModal.QuizType),
            Content: String(this.state.QuestionModal.Content),
            Answer: String(this.state.QuestionModal.Answer),
            Selection: String(this.state.QuestionModal.Selection),
            Hints: CheckNullValue(this.state.QuestionModal.Hints),
            Tags: CheckNullValue(this.state.QuestionModal.Tags),
            PictureUrl: _isAnswerOption ? _pictureUrl : (_removePicture ? '' : _pictureUrl),        //String(this.state.QuestionModal.PictureUrl),
            AnswerOptions: _answerOptions,
            QsNo: Number(this.state.QuestionModal.No),

            ContentPictures: _isContentPicture ? _contentPictures : [],  //2021.09.02
        };

        //Call Api to update changes.
        let jsonModel = {
            OrganizerId: organizerId,
            CenterUserId: centerUserId,
            AuthorId: authorId,
            AuthorRoleId: authorRoleId,    //1 = admin, 4 = center, 11 = Author
            FirebaseUserId: uid,

            QuestionSetUniqueId: CheckStringEmpty(this.props.QuestionSetUniqueId),        //for update to RTDB. standby for ref.
            QuestionUniqueId: CheckObjectStringEmpty(this.state.QuestionModal, 'UniqueId'),        //for update to CMS DB > QuizComprehension.
            // FileName: _isAnswerOption ? _answerPictureName : _pictureUrl,                //e.g. 98e0efb0093c4480bcf43cf6f2545aa5_pk_6
            FileName:
                _isAnswerOption ?
                    _answerPictureName
                    : _isContentPicture ?
                        this.state.ContentPictureFileName
                        : _pictureUrl,   //2021.09.02
            Base64: _base64,                                                             //picture file converted to base64 string.
            EditQuestionModel: _editQuestionJsonModel,
            RemovePicture: _removePicture,
            IsAnswerOptionPicture: _isAnswerOption,
            IsContentPictures: _isContentPicture,    //2021.09.02
        };
        await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/QuizBank/QuizQuestion/Picture/Upload',
            {
                method: 'POST',                             // *GET, POST, PUT, DELETE, etc.
                // mode: 'cors',                            // no-cors, *cors, same-origin
                // cache: 'no-cache',                          // *default, no-cache, reload, force-cache, only-if-cached
                // credentials: 'same-origin',                 // include, *same-origin, omit
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                // redirect: 'follow',                         // manual, *follow, error
                // referrerPolicy: 'no-referrer',              // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(jsonModel), // body data type must match "Content-Type" header
            })
            .then(res => res.json())
            .then(data => {
                isUpdateSuccess = data.success;
                message = 'api upload - question picture ('
                    + (data.success ? 'success' : 'failed')
                    + ').\n' + JSON.stringify(data);
            })
            .catch(error => {
                message = 'Error : ' + error.message;   //JSON.stringify(error);
            });

        if (this.props.isDevMode)
            console.log('api (response) =\n' + message);

        //update modal.
        let _modal = this.state.QuestionModal;
        if (isUpdateSuccess) {

            //Answer Option Picture.
            if (_isAnswerOption === false && _removePicture)
                _modal.PictureUrl = '';
            else
                _modal.PictureUrl = _editQuestionJsonModel.PictureUrl;

            //Content Pictures.
            if (_isContentPicture === false && _removePicture)
                _modal.ContentPictures = [];
            else
                _modal.ContentPictures = _editQuestionJsonModel.ContentPictures;


            // if (_isAnswerOption === false && _isContentPicture === false) {
            //     if (_removePicture)
            //         _modal.PictureUrl = '';
            //     else
            //         _modal.PictureUrl = _editQuestionJsonModel.PictureUrl;
            // }
            // else {

            // }
        }
        this.setState({
            QuestionModal: _modal,
            CachedQuestionModal: JSON.parse(JSON.stringify(_modal)),
            ToggleUploadPictureUi: false,
            Upload_Picture_State: _isAnswerOption ? 0 : (isUpdateSuccess ? 0 : 2),                  // 0 = default, 1 = uploading, 2 = failed.
            AnswerOption_Upload_Picture_State: !_isAnswerOption ? 0 : (isUpdateSuccess ? 3 : 2),    // 0 = default, 1 = uploading, 2 = failed, 3 = success.
            Upload_PictureForContent_State: !_isContentPicture ? 0 : (isUpdateSuccess ? 0 : 2),     // 0 = default, 1 = uploading, 2 = failed.
        }, async () => {
            useAppService.getState().setModal();    //off loading.

            if (this.props.isDevMode)
                console.log('Upload picture = ' + (isUpdateSuccess ? 'Success' : 'Failed'));
            // await this.props.UpdateQuestionListArrayItem(this.state.CachedQuestionModal);
            // this.PopulateFormUiData();
            // this.CheckIfQuestionAllowedToSave();
            // this.RefreshPreviewContent();
            this.props.Callback_ReloadQuestionList(this.state.QuestionModal.No);   //2021.09.23
        });
    }
    RetryUploadPicture = () => {
        this.setState({
            ToggleUploadPictureUi: false,
            Upload_Picture_State: 1,
        }, () => {
            this.UploadPictureForQuizQuestion(false, false, CheckNullValue(this.state.ContentPictureFileName) !== null);
        });
    }
    //#endregion    //=== Upload Picture (Question) === end ===//


    //#region       //=== Upload Picture (Answer) === start ===//
    AnswerOption_PictureUpload_ModalComponent = () => {
        if (this.state.ToggleAnswerOptionUploadPictureUiModal) {
            let modalBodyComponent = () => {
                switch (this.state.AnswerOption_Upload_Picture_State) {
                    //0, pick index.
                    default: return (<>
                        <input
                            type="file"
                            onChange={this.onUploadAnswerPictureFileChange}
                            style={{ width: '100%' }}
                            name='image'
                            accept='.jpg'
                        />
                    </>);

                    //processing.
                    case 1: return (<>Updating...<br /><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></>);

                    //failed.
                    case 2: return (<>Picture update failed.<br />Please try again.</>);

                    //success.
                    case 3: return (<>Picture has been updated.</>);
                }
            };
            return (
                <Modal size='lg' show={this.state.ToggleAnswerOptionUploadPictureUiModal}
                    onHide={
                        this.state.AnswerOption_Upload_Picture_State === 0 ||
                            this.state.AnswerOption_Upload_Picture_State === 3
                            ? this.ToggleModalUi_AnswerOption_PictureUpload : DoNothing
                    }
                    centered>
                    <Modal.Header
                        closeButton={
                            this.state.AnswerOption_Upload_Picture_State === 0
                            || this.state.AnswerOption_Upload_Picture_State === 3
                        }
                    >
                        <Modal.Title>Picture for Question [<b>#{this.state.QuestionModal.No}</b>] Answer Option [<b>{AtoZ[this.state.SelectedAnswerOptionIndex]}</b>]</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {modalBodyComponent()}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => this.ToggleModalUi_AnswerOption_PictureUpload()}
                            hidden={this.state.AnswerOption_Upload_Picture_State === 0
                                || this.state.AnswerOption_Upload_Picture_State === 3
                                ? false : true}
                        >Close</Button>
                        <div hidden={this.state.AnswerOption_Upload_Picture_State !== 2}>
                            <Button
                                variant="primary"
                                onClick={() => this.RetryUploadPicture_AnswerOption()}
                            >Retry</Button>&nbsp;
                            <Button
                                variant="secondary"
                                // onClick={this.RetryUploadPicture_AnswerOption}
                                onClick={() => this.ToggleModalUi_AnswerOption_PictureUpload()}
                            >Cancel</Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            );
        }
        return null;
    }
    ToggleModalUi_AnswerOption_PictureUpload = (_index = -1) => {
        // console.log(_index);
        this.setState({ ToggleAnswerOptionUploadPictureUiModal: !this.state.ToggleAnswerOptionUploadPictureUiModal, });
        if (_index > -1) {
            this.setState({
                SelectedAnswerOptionIndex: _index,
                AnswerOption_Upload_Picture_State: 0,
            });
        }
    }
    RetryUploadPicture_AnswerOption = () => {
        this.setState({
            ToggleAnswerOptionUploadPictureUiModal: true,
            AnswerOption_Upload_Picture_State: 1,
        }, () => {
            this.UploadPictureForQuizQuestion(false, true);
        });
    }
    // GetAnswerOptionPictureName = (_index) => {
    //     let _pictureName = '';
    //     if (this.state.QuestionModal !== null) {
    //         let answerOptions = String(this.state.QuestionModal.Selection).split(';');
    //         let optionKey = answerOptions[_index].split(':')[0];
    //         _pictureName = this.state.QuestionModal.UniqueId + '_pk_' + this.state.QuestionModal.No + '_' + optionKey;
    //         if (this.props.isDevMode)
    //             console.log(optionKey);
    //     }
    //     return _pictureName
    // }
    RemovePicture_AnswerOption = (_index) => {
        this.setState({
            ToggleAnswerOptionUploadPictureUiModal: true,
            AnswerOption_Upload_Picture_State: 1,
            SelectedAnswerOptionIndex: _index,
        }, () => {
            this.onUploadAnswerPictureFileChange(null);
        });
    }
    onUploadAnswerPictureFileChange = (event) => {
        if (this.state.QuestionModal !== null) {

            const _removePicture = event === null;
            let answerOptions = String(this.state.QuestionModal.Selection).split(';');
            const optionKey = answerOptions[this.state.SelectedAnswerOptionIndex].split(':')[0];
            const optionValue = answerOptions[this.state.SelectedAnswerOptionIndex].split(':')[1];
            // let _pictureName = this.state.QuestionModal.UniqueId + '_pk_' + this.state.QuestionModal.No + '_' + optionKey + '_' + RandomId();
            const _pictureName = this.state.QuestionModal.UniqueId + '_pk_' + optionKey + '_' + RandomId();  //2021.09.27
            let _editedSelectionText = '';

            answerOptions = answerOptions.filter((el) => { return (el !== ''); });    //remove empty values.
            answerOptions.map((data, key) => {
                if (key === this.state.SelectedAnswerOptionIndex) {
                    if (_removePicture) {
                        // let cachedAnswerOptions = String(this.state.CachedQuestionModal.Selection).split(';');
                        // let cachedAnswerOptionValue = cachedAnswerOptions[this.state.SelectedAnswerOptionIndex].split(':')[1];
                        // _editedSelectionText += optionKey + ':' + cachedAnswerOptionValue + ';';
                        _editedSelectionText += optionKey + ':;';
                    }
                    else {
                        _editedSelectionText += optionKey + ':' + _pictureName + ';';
                    }
                }
                else {
                    _editedSelectionText += data + ';';
                }
                return null;
            });

            let _modal = this.state.QuestionModal;
            _modal.Selection = _editedSelectionText;

            this.setState({
                SelectedAnswerOptionPictureName: _removePicture ? optionValue : _pictureName,
                QuestionModal: JSON.parse(JSON.stringify(_modal)),
                PictureFileToUpload: event === null ? null : event.target.files[0],
                // ToggleAnswerOptionUploadPictureUiModal: event === null ? false : !this.state.ToggleAnswerOptionUploadPictureUiModal,
                AnswerOption_Upload_Picture_State: 1,
            }, () => {
                //Upload picture file.
                this.UploadPictureForQuizQuestion(_removePicture, true);
            });
        }
    };
    //#endregion    //=== Upload Picture (Answer) === end ===//


    GetPictureUrl = (_pictureName) => {
        return 'https://ikeynew.blob.core.windows.net/ikeykidz/JPG/' + _pictureName + '.jpg';
    }

    //#region //=== Preview Mode / Content === start ===//
    //2021.08.20
    FilterQuestionContent_Edit = (_content = '') => {
        //new line tag.
        _content = _content.replace(new RegExp("<br />", "g"), "\n");

        // //img tag.
        // _content = _content.replace(new RegExp("<img src='https://ikeynew.blob.core.windows.net/ikeykidz/JPG/", "g"), "<img>");
        // _content = _content.replace(new RegExp(".jpg' />", "g"), "</img>");

        return _content;
    }

    //2021.08.20
    FilterQuestionContent_Preview = (_content = '', _save = false) => {
        //new line tag.
        // _content = _content.replace(new RegExp("\n", "g"), "<br />");
        _content = _content.replaceAll("\n", "<br />");

        //img tag.
        if (_save === false) {
            _content = _content.replace(new RegExp("<img>", "g"), "<img src='https://ikeynew.blob.core.windows.net/ikeykidz/JPG/");
            _content = _content.replace(new RegExp("</img>", "g"), ".jpg' alt='' width='100%' />");
        }

        return _content;
    }

    PreviewContentComponents = () => {
        if (this.state.QuestionModal !== null) {
            if (this.state.RefreshPreview === false) {
                let _selections = this.state.EditedAnswerOptions !== '' ? this.state.EditedAnswerOptions : String(this.state.QuestionModal.Selection);
                return <table style={{ width: '100%', cellPadding: 0, cellSpacing: 0, }}>
                    <tbody>
                        <tr>
                            <td>
                                <div dangerouslySetInnerHTML={{ __html: this.FilterQuestionContent_Preview(this.state.QuestionModal.Content, false) }}></div>
                                {/* <br /> */}
                            </td>
                        </tr>
                        {/* <tr>
                            <td>
                                <hr />
                            </td>
                        </tr> */}
                        {/* <tr>
                            <td>
                                {
                                    this.state.QuestionModal.PictureUrl !== '' ?
                                        <>
                                            <img src={this.GetPictureUrl(this.state.QuestionModal.PictureUrl)} alt='' width='100%'></img>
                                            <br />
                                        </>
                                        : null
                                }
                            </td>
                        </tr> */}
                        {
                            this.state.QuestionModal.PictureUrl !== '' ?
                                <tr>
                                    <td>
                                        <br />
                                        <img src={this.GetPictureUrl(this.state.QuestionModal.PictureUrl)} alt='' width='100%'></img>
                                        <br />
                                    </td>
                                </tr>
                                : null
                        }
                        <tr>
                            <td align='left'>
                                {/* <hr /> */}
                                {/* Options:<br /> */}
                                <table className='table table-borderless' cellSpacing='0' cellPadding='5' border='0'>
                                    <tbody>
                                        {
                                            // this.state.QuestionModal !== null ?
                                            //     String(this.state.QuestionModal.Selection).split(';').map((data, key) => {
                                            _selections !== null && _selections !== undefined && _selections !== '' ?
                                                _selections.split(';').map((data, key) => {
                                                    if (data !== '') {
                                                        let value = data.split(':');
                                                        return (<tr key={'ao' + key}><td width='40' align='center'>{
                                                            value[0] === this.state.QuestionModal.Answer ?
                                                                <Badge variant='primary' style={{ fontSize: 14 }}>{value[0]}</Badge>
                                                                : <b>{value[0]}</b>
                                                        }</td><td>{
                                                            value[1] === undefined ? '' :
                                                                value[1].includes('_pk_') ?
                                                                    <>
                                                                        <img src={this.GetPictureUrl(value[1])} alt='' width='100%'></img>
                                                                        <br />
                                                                    </>
                                                                    : <div dangerouslySetInnerHTML={{ __html: value[1] }}></div>
                                                        }</td></tr>);
                                                    }
                                                    return null;
                                                })
                                                : <tr><td>No Option is added.</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>;
            }
            else {
                return <ProgressBar animated now={100} className='progressbar1' />;
            }
        }
        return null;
    }

    RefreshPreviewContent = () => {
        this.setState({ RefreshPreview: true, }, () => {
            setTimeout(() => {
                this.setState({ RefreshPreview: false, });
            }, 500);
        })
    }

    QsTitleEtc = () => {
        if (this.state.QuestionModal.QuizType === 'Comprehension' || this.state.QuestionModal.QuizType === 'FillInTheBlanks') {
            let mode = String(this.state.QuestionModal.SpecialMode).split(';');
            let qsNum = Array.isArray(mode) && mode.length > 0 ? mode[1].split(',') : [];
            if (qsNum.length === 0)
                return null;
            return (<span style={{ fontSize: 18, color: 'gray' }}>{' #' + qsNum[0] + '~' + qsNum[1]}</span>);
        }
        return null;
    }

    //2021.10.04
    LeftPanel_DisplayMode = () => {
        switch (this.state.DisplayMode) {
            default: return 1;
            case 2: return 2;
        }
    }
    RightPanel_DisplayMode = () => {
        switch (this.state.DisplayMode) {
            default: return 1;
            case 3: return 2;
        }
    }
    //#endregion

    //2021.12.07
    //#region //=== Edit Question Type (QuizType) === start ===//
    ToggleEditQuestionType = () => {
        this.setState({ ToggleEditQuestionType: !this.state.ToggleEditQuestionType, });
    }
    EditQuestionTypeComponentUi = () => {
        let _components = <></>;
        if (this.state.ToggleEditQuestionType) {
            let _title = <>Question <b>#{String(this.state.QuestionModal.No)}</b> (Edit Question Type)</>;
            let _primaryComponent =
                <DropdownButton
                    id='edit-question-type-selection-dropdown-button'
                    title={
                        this.state.EditQuestionType_QuizType !== null ?
                            this.state.EditQuestionType_QuizType.Index > 0 ?
                                String(QuizType[this.state.EditQuestionType_QuizType.Index - 1].Name)
                                : '- Select Question Type -'
                            : '- Select Question Type -'
                    }
                    drop='down'
                    onSelect={(option) => this.setState({
                        EditQuestionType_QuizType: QuizType[Number(option) - 1],
                        EditQuestionType_State: QuizType[Number(option) - 1].Index,
                    }, () => {
                        if (this.props.isDevMode)
                            console.log('option = ' + option + '\n' + JSON.stringify(this.state.EditQuestionType_QuizType));

                        //2021.12.13
                        if (this.state.EditQuestionType_QuizType.Name === QuizType[2].Name)   //Comprehension
                        {
                            if (this.state.QuestionModal !== null) {
                                let _qsNo = Number(this.state.QuestionModal.No);
                                this.EditQs_QuizType_Comprehension_Start_Ref.current.value = _qsNo;
                                this.EditQs_QuizType_Comprehension_End_Ref.current.value = _qsNo;
                                this.setState({
                                    EditQuestionType_QuizType_Start: _qsNo,
                                    EditQuestionType_QuizType_End: _qsNo,
                                });
                            }
                        }
                    })}
                    disabled={this.state.EditQuestionType_QuizType === null ? false : true}
                    style={{ width: 350, }}
                >
                    <Dropdown.Item disabled={true}>- Select Question Type -</Dropdown.Item>
                    {
                        QuizType.map((data, key) => {
                            return <Dropdown.Item as="button" eventKey={String(data.Index)} value={key + 1}
                                hidden={
                                    (this.state.QuestionModal === null ? false
                                        : String(this.state.QuestionModal.QuizType) === QuizType[key].Name)
                                    || data.Hidden
                                }
                                disabled={
                                    this.state.QuestionModal === null ? false
                                        : String(this.state.QuestionModal.QuizType) === QuizType[key].Name
                                }>{data.Name}</Dropdown.Item>
                        })
                    }
                </DropdownButton>;
            let _secondaryComponent = null;

            switch (this.state.EditQuestionType_State) {
                default: break;
                case 0:
                    //primary component.
                    break;
                case 1:
                    //Objective === Dropdown Item is Selected.
                    if (this.state.QuestionModal !== null)
                        if (this.state.EditQuestionType_QuizType !== null)
                            if (this.state.QuestionModal.QuizType !== this.state.EditQuestionType_QuizType.Name)
                                if (this.state.EditQuestionType_QuizType.Index === QuizType[0].Index) {
                                    _secondaryComponent = <>
                                        <hr />
                                        {this.state.EditQuestionType_QuizType.Name}
                                    </>;
                                }
                    break;
                case 2:
                    //Subjective === Dropdown Item is Selected.
                    break;
                case 3:
                    //Comprehension === Dropdown Item is Selected.
                    if (this.state.QuestionModal !== null)
                        if (this.state.EditQuestionType_QuizType !== null)
                            if (this.state.QuestionModal.QuizType !== this.state.EditQuestionType_QuizType.Name)
                                if (this.state.EditQuestionType_QuizType.Index === QuizType[2].Index) {
                                    _secondaryComponent = <>
                                        <hr style={{ marginBottom: 0, }} />
                                        <table className='table tb-no-border' cellPadding='0' cellSpacing='0' width='80%' border='0'
                                            style={{ borderTop: 'hidden', marginBottom: 0, textAlign: 'left' }}
                                        >
                                            <tr>
                                                <td style={{ verticalAlign: 'middle' }}>Question <b>Begin</b></td>
                                                <td><input type='text' className='form-control' defaultValue={this.state.QuestionModal.No}
                                                    ref={this.EditQs_QuizType_Comprehension_Start_Ref}
                                                    onChange={(e) => {
                                                        let _test = Number(e.target.value);
                                                        let _number = String(_test) !== 'NaN' ? _test
                                                            : this.state.EditQuestionType_QuizType_Start > 0 ?
                                                                this.state.EditQuestionType_QuizType_Start
                                                                : this.state.QuestionModal.No;
                                                        this.setState({
                                                            EditQuestionType_QuizType_Start: _number,
                                                        }, () => {
                                                            this.EditQs_QuizType_Comprehension_Start_Ref.current.value = _number;
                                                            if (this.props.isDevMode)
                                                                console.log('begin = ' + _number);
                                                        });
                                                    }}
                                                ></input></td>
                                            </tr>
                                            <tr>
                                                <td style={{ verticalAlign: 'middle' }}>Question <b>End</b></td>
                                                <td><input type='text' className='form-control' defaultValue={this.state.QuestionModal.No}
                                                    ref={this.EditQs_QuizType_Comprehension_End_Ref}
                                                    onChange={(e) => {
                                                        let _test = Number(e.target.value);
                                                        let _number = String(_test) !== 'NaN' ? _test
                                                            : this.state.EditQuestionType_QuizType_End > 0 ?
                                                                this.state.EditQuestionType_QuizType_End
                                                                : this.state.QuestionModal.No;
                                                        this.setState({
                                                            EditQuestionType_QuizType_End: _number,
                                                        }, () => {
                                                            this.EditQs_QuizType_Comprehension_End_Ref.current.value = _number;
                                                            if (this.props.isDevMode)
                                                                console.log('end = ' + _number);
                                                        });
                                                    }}
                                                ></input></td>
                                            </tr>
                                        </table>
                                        <hr style={{ marginTop: 0, }} />
                                        <span className='blink-fast' style={{ color: 'red', fontWeight: 'bold' }}>* Question Type is not reversible after switched.</span>
                                    </>;
                                }
                    break;
                case 4:
                    //FillInTheBlanks === Dropdown Item is Selected.
                    if (this.state.QuestionModal !== null)
                        if (this.state.EditQuestionType_QuizType !== null)
                            if (this.state.QuestionModal.QuizType !== this.state.EditQuestionType_QuizType.Name)
                                if (this.state.EditQuestionType_QuizType.Index === QuizType[3].Index) {
                                    _secondaryComponent = <>
                                        <hr />
                                        {this.state.EditQuestionType_QuizType.Name}
                                    </>;
                                }
                    break;
            }
            _components =
                <Modal show={this.state.ToggleEditQuestionType}
                    onHide={this.state.EditQuestionType_State < 1 ? this.CloseAndResetState : DoNothing}
                    centered>
                    <Modal.Header closeButton={false}>
                        <Modal.Title>{_title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center', alignSelf: 'center', }}>
                        {_primaryComponent}
                        {_secondaryComponent}
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            this.state.EditQuestionType_State === 0 ?
                                <Button
                                    variant="secondary"
                                    onClick={() => this.CloseAndResetState()}
                                >Close</Button>
                                :
                                <>
                                    <Button
                                        variant="secondary"
                                        onClick={() => this.CancelAndResetState()}
                                    >Cancel</Button>&nbsp;
                                    <Button
                                        variant="primary"
                                        onClick={() => this.ValidateOnAppliedConfig()}
                                    >Apply</Button>
                                </>
                        }
                    </Modal.Footer>
                </Modal>;
        }
        return _components;
    }
    CloseAndResetState = () => {
        this.CancelAndResetState();
        this.ToggleEditQuestionType();
    }
    CancelAndResetState = () => {
        this.setState({
            EditQuestionType_State: 0,
            EditQuestionType_QuizType: null,
            EditQuestionType_QuizType_Start: 0,
            EditQuestionType_QuizType_End: 0,
        });
    }
    //2021.12.13
    ValidateOnAppliedConfig = () => {
        if (this.props.isDevMode)
            console.log(
                '\n(Original) Quiz Type = ' + String(this.state.QuestionModal.QuizType)
                + '\n(Target) Quiz Type = ' + this.state.EditQuestionType_QuizType.Name
                + '\nStart = ' + this.state.EditQuestionType_QuizType_Start
                + '\nEnd = ' + this.state.EditQuestionType_QuizType_End
            );

        let errors = [];

        //normally wont trigger these conditions, unless somewhere bugged.
        if (this.state.QuestionModal === null)
            errors.push('Invalid question credential.');
        if (this.state.EditQuestionType_QuizType === null)
            errors.push('Invalid quiz type.');
        if (this.state.EditQuestionType_QuizType_Start <= 0)
            errors.push('Invalid question start number.');
        if (this.state.EditQuestionType_QuizType_End <= 0)
            errors.push('Invalid question end number.');
        if (String(this.state.QuestionModal.QuizType) === this.state.EditQuestionType_QuizType.Name)
            errors.push('Same quiz type.');

        //possible condition.
        if (this.state.EditQuestionType_QuizType_Start > this.state.EditQuestionType_QuizType_End)
            errors.push('Question <b>Start</b> number must be <b>lower</b> than <b>End</b> number.');

        //Result.
        if (errors.length > 0) {
            if (this.props.isDevMode)
                console.log('Error :\n' + errors.join('\n'));
            useAppService.getState().setModal('Error', 'Invalid Operation : Update halted.<br ><br />' + errors.join('<br />'));
        }
        else {
            this.Trigger_UpdateToDatabase_ChangeQuizType();
        }
    }
    //2021.12.13
    Trigger_UpdateToDatabase_ChangeQuizType = async () => {

        useAppService.getState().setModal('Saving settings...',
            '<span style="font-size:18px;font-weight:bold;">Changing Quiz Type</span><br />from <b><u>' + String(this.state.QuestionModal.QuizType)
            + '</u></b> to <b><u>' + this.state.EditQuestionType_QuizType.Name + '</u></b><br />'
            + (this.state.EditQuestionType_QuizType_Start === this.state.EditQuestionType_QuizType_End ?
                'for Question <b>#' + this.state.EditQuestionType_QuizType_Start + '</b>'
                :
                'for Questions <b>#' + this.state.EditQuestionType_QuizType_Start + ' ~ #' + this.state.EditQuestionType_QuizType_End) + '</b>'
            , null, AlertMode.Loading);

        //testing. no update.
        if (this.props.isDevMode === false) {
            await Delay(5000);
            useAppService.getState().setModal();
            this.props.Callback_ReloadQuestionList(this.state.QuestionModal.No);
            await Delay(1000);
            useAppService.getState().setModal('', 'Update feature has been disabled in Live Build.');
            return null;
        }

        //2021.12.17
        let questionList = this.props.QuestionList;
        let _selectedQuestions = [];
        if (questionList !== undefined) {
            if (questionList.length > 0) {
                questionList.map((data, key) => {
                    if (data.hasOwnProperty('No')) {
                        if (Number(data.No) >= this.state.EditQuestionType_QuizType_Start && Number(data.No) <= this.state.EditQuestionType_QuizType_End)
                            _selectedQuestions.push(data);
                    }
                    return null;
                });
            }
        }

        const { uid, centerUserId, authorId, authorRoleId } = GetPropIds(useGlobal.getState().user);

        //model
        let jsonModel = {
            CenterUserId: centerUserId,
            AuthorId: authorId,
            AuthorRoleId: authorRoleId,    //1 = admin, 4 = center, 11 = Author
            FirebaseUserId: uid,

            QuestionSetUniqueId: CheckStringEmpty(this.props.QuestionSetUniqueId),        //for update to RTDB. standby for ref.
            QuestionUniqueId: CheckObjectStringEmpty(this.state.QuestionModal, 'UniqueId'),        //for update to CMS DB > QuizComprehension.

            QsNoStart: this.state.EditQuestionType_QuizType_Start,
            QsNoEnd: this.state.EditQuestionType_QuizType_End,
            QuizTypeOriginal: String(this.state.QuestionModal.QuizType),
            QuizTypeTarget: this.state.EditQuestionType_QuizType.Name,

            SelectedQuestions: _selectedQuestions,  //2021.12.17
        };
        if (this.props.isDevMode)
            console.log('json modal =\n' + JSON.stringify(jsonModel));

        let isUpdateSuccess = false;
        let message = '';
        await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/QuizBank/Question/Edit/QuizType/Update',
            {
                method: 'POST',                             // *GET, POST, PUT, DELETE, etc.
                // mode: 'cors',                            // no-cors, *cors, same-origin
                // cache: 'no-cache',                          // *default, no-cache, reload, force-cache, only-if-cached
                // credentials: 'same-origin',                 // include, *same-origin, omit
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                // redirect: 'follow',                         // manual, *follow, error
                // referrerPolicy: 'no-referrer',              // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(jsonModel), // body data type must match "Content-Type" header
            })
            .then(res => res.json())
            .then(data => {
                isUpdateSuccess = data.success;
                message = 'api - change quiz type - qs (' + this.state.EditQuestionType_QuizType_Start + ') (' + this.state.EditQuestionType_QuizType_End + ') ('
                    + (data.success ? 'success' : 'failed')
                    + ') (' + jsonModel.QuizTypeOriginal + '-to-' + jsonModel.QuizTypeTarget + ')\n' + JSON.stringify(data);
            })
            .catch(error => {
                message = 'Error : ' + error.message;
            });

        if (this.props.isDevMode)
            console.log(message);

        if (isUpdateSuccess) {
            this.UpdateQuestionSetAfterEditQuizType(jsonModel);
        }
        //done.
    }
    //2021.12.16
    UpdateQuestionSetAfterEditQuizType = async (_model = null) => {
        if (_model !== null) {
            //Switching from Objective to Comprehension.
            if (String(_model.QuizTypeOriginal) === 'Objective' && String(_model.QuizTypeTarget) === 'Comprehension') {
                let totalQuestion = Number(_model.QsNoEnd) - Number(_model.QsNoStart) + 1;
                let _content = 'Content_' + String(_model.QsNoStart) + '_' + String(_model.QsNoEnd) + ';';

                //update each question in question set.
                for (var i = 0; i < totalQuestion; i++) {
                    await update(ref(this.props.dbQuizBank, String(this.props.QuestionSetUniqueId) + '/Questions/' + String(Number(_model.QsNoStart) + i)),
                        {
                            QuizType: String(_model.QuizTypeTarget),
                            SpecialMode: String(_model.QuizTypeTarget) + ';' + String(_model.QsNoStart) + ',' + String(_model.QsNoEnd) + ';' + totalQuestion
                                + ';' + _content,
                        });
                    // await this.props.dbQuizBank
                    //     .ref(String(this.props.QuestionSetUniqueId) + '/Questions/' + String(Number(_model.QsNoStart) + i))
                    //     .update({
                    //         QuizType: String(_model.QuizTypeTarget),
                    //         SpecialMode: String(_model.QuizTypeTarget) + ';' + String(_model.QsNoStart) + ',' + String(_model.QsNoEnd) + ';' + totalQuestion
                    //             + ';' + _content,
                    //     });
                }
                // for (var i = 0; i < _model.SelectedQuestions.length; i++) {
                //     await this.props.dbQuizBank
                //         .ref(String(this.props.QuestionSetUniqueId) + '/Questions/' + String(_model.SelectedQuestions[i].No))
                //         .update({
                //             QuizType: String(_model.QuizTypeTarget),
                //             SpecialMode: String(_model.QuizTypeTarget) + ';' + String(_model.QsNoStart) + ',' + String(_model.QsNoEnd) + ';' + totalQuestion
                //                 + ';' + _content,
                //         });
                // }

                //ExtraContent.
                await set(ref(this.props.dbQuizBank, String(this.props.QuestionSetUniqueId) + '/ExtraContent/' + _content),
                    'comprehension passage');
                // await this.props.dbQuizBank
                //     .ref(String(this.props.QuestionSetUniqueId) + '/ExtraContent/' + _content)
                //     .set('comprehension passage');

                //Reload.
                this.props.Callback_ReloadQuestionList(this.state.QuestionModal.No);
            }
        }
    }
    //#endregion //=== Edit Question Type (QuizType) === end ===//


    render = () => {
        return (<>
            <Row><Col><hr /></Col></Row>
            <Row>
                <Col style={{ textAlign: 'center', height: 75, }}>{
                    this.state.QuestionModal !== null ?
                        <>
                            <span style={{ fontSize: 20 }}>Question <b>#{this.state.QuestionModal.No}</b> ({this.state.QuestionModal.QuizType}{this.QsTitleEtc()})</span>
                            &nbsp;<OverlayTrigger overlay={<Tooltip><span>Change Question Type</span></Tooltip>}>
                                <Button
                                    variant="danger"
                                    onClick={() => this.ToggleEditQuestionType()}
                                    style={{ padding: '1px 5px 1px 5px', position: 'absolute', textAlign: 'center', top: 4, }}
                                    // hidden={String(this.state.QuestionModal.QuizType) !== 'Objective' || this.props.isDevMode === false}
                                    hidden
                                ><i className="fa fa-cog" style={{ fontSize: "20px", color: "white", }}></i></Button>
                            </OverlayTrigger>
                            <br /><span style={{ fontSize: 12, color: 'gray' }}>({this.state.QuestionModal.UniqueId})</span>
                        </>
                        : null
                }
                    <div style={{ position: 'absolute', right: 41, marginTop: -50, }}>
                        <table className='tb-display-mode'>
                            <tbody>
                                <tr><td colSpan='3'>Display Mode</td></tr>
                                <tr>
                                    <td>
                                        <Button
                                            variant={this.state.DisplayMode === 1 ? "primary" : "secondary"}
                                            onClick={() => { this.setState({ DisplayMode: 1 }) }}
                                        >1</Button>
                                    </td>
                                    <td>
                                        <Button
                                            variant={this.state.DisplayMode === 2 ? "primary" : "secondary"}
                                            onClick={() => { this.setState({ DisplayMode: 2 }) }}
                                        >2</Button>
                                    </td>
                                    <td>
                                        <Button
                                            variant={this.state.DisplayMode === 3 ? "primary" : "secondary"}
                                            onClick={() => { this.setState({ DisplayMode: 3 }) }}
                                        >3</Button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col style={{ flex: this.LeftPanel_DisplayMode(), }}>
                    <table className='tb-no-padding'>
                        <tbody>
                            <tr>
                                <td width='190'>
                                    <Button
                                        variant="secondary"
                                        onClick={() => this.ResetForm()}
                                        style={{ width: '100%' }}
                                    >Reset whole question back to original state</Button>
                                </td>
                                <td>&nbsp;</td>
                                <td align='right' width='190'>
                                    <Button
                                        variant="primary"
                                        onClick={() => this.SaveQuestionViaAPI()}
                                        style={{ width: '100%' }}
                                        disabled={this.state.AllowToSaveChanges === false}
                                    >Save Question{this.state.AllowToSaveChanges === false ? <><br />(disabled)</> : <><br />(changes made)</>}</Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    {
                        this.state.QuestionModal !== null ?
                            this.state.QuestionModal.QuizType === 'Comprehension' ?
                                <>
                                    {
                                        this.state.ToggleComprehensionPassage ?
                                            this.ComprehensionPassageHtmlUi()
                                            :
                                            <>
                                                <Button
                                                    variant="primary"
                                                    onClick={() => this.LoadComprehensionPassage()}
                                                >Show Passage</Button>
                                                <br />
                                            </>
                                    }
                                    <br id='ComprehensionBottom' />
                                </>
                                : null
                            : null
                    }
                    <span id='Preview' style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 10, }}>Preview</span>
                    <br />
                    <div style={{ border: '1px solid blue', borderRadius: 5, padding: 10, }}>
                        {/* <div dangerouslySetInnerHTML={
                                    this.state.QuestionModal !== null ?
                                        this.state.QuestionModal.Content
                                        : null
                                } /> */}
                        {this.PreviewContentComponents()}
                    </div>
                </Col>
                <Col style={{
                    flex: this.RightPanel_DisplayMode(),
                    border: '1px solid blue',
                    borderRadius: 5,
                    padding: '5px 2px 5px 4px',
                    marginRight: 10,
                }}>
                    <table className='table table-borderless tbStyle2' cellPadding='10'>
                        <tbody>
                            <tr>
                                <td width='80'>
                                    {/* <span>Content</span> */}
                                    <table className='tb-no-padding'>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span>Content</span>&nbsp;&nbsp;
                                                </td>
                                                <td>
                                                    <i className="fa fa-picture-o" style={{
                                                        fontSize: "15px", color: "blue",
                                                        visibility: this.state.QuestionModal !== null && this.state.QuestionModal.hasOwnProperty('ContentPictures') ?
                                                            (this.state.QuestionModal.ContentPictures.length > 0 ? 'visible' : 'hidden') : 'hidden'
                                                    }}
                                                    ></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td>
                                    <textarea
                                        ref={this.EditQs_Content_Ref}
                                        rows="10"
                                        // rows={this.state.QuestionModal !== null ? Math.round(String(this.state.QuestionModal.Content).length / 20) : 5}
                                        // cols="35"
                                        style={{ width: '100%', minHeight: 150 }}
                                        // placeholder={this.GetDataValue(QsDataInput.Content)}
                                        onChange={(e) => this.SaveDataInput(e.target.value, QsDataInput.Content)}
                                    ></textarea>
                                    {this.ContentPictureComponentUi()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table className='tb-no-padding'>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span>Picture</span>&nbsp;&nbsp;
                                                </td>
                                                <td>
                                                    <i className="fa fa-picture-o" style={{
                                                        fontSize: "15px", color: "blue",
                                                        visibility: this.state.QuestionModal !== null && this.state.QuestionModal.PictureUrl !== '' ? 'visible' : 'hidden',
                                                    }}
                                                    ></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td>
                                    <OverlayTrigger overlay={this.state.QuestionModal === null ? <></> : (this.state.QuestionModal.PictureUrl === '' ? <></> :
                                        <Tooltip className='tooltip-style'><img src={'https://ikeynew.blob.core.windows.net/ikeykidz/JPG/' + this.state.QuestionModal.PictureUrl + '.jpg'} alt="" width="100%" /></Tooltip>)}>
                                        <input
                                            ref={this.EditQs_Picture_Ref}
                                            type="text"
                                            style={{ width: '100%', fontSize: 14 }}
                                            placeholder={
                                                this.state.QuestionModal !== null ?
                                                    this.state.QuestionModal.PictureUrl !== '' ?
                                                        String(this.state.QuestionModal.PictureUrl).replace(this.state.QuestionModal.UniqueId + '_pk_', '')
                                                        : 'No Picture'
                                                    : null
                                            }
                                            // onChange={(e) => this.SaveDataInput(e.target.value, QsDataInput.PictureUrl)}
                                            disabled={true}
                                        />
                                    </OverlayTrigger>
                                    {this.PictureComponentUi()}
                                    {/* *btn = click to upload picture. auto rename picture. */}

                                </td>
                            </tr>
                            <tr hidden={true}>
                                <td><span>Answer</span></td>
                                <td>
                                    <input
                                        ref={this.EditQs_Answer_Ref}
                                        type="text"
                                        style={{ width: '100%' }}
                                        // placeholder={this.GetDataValue(QsDataInput.Answer)}
                                        placeholder={
                                            this.GetDataValue(QsDataInput.Answer) !== '' ?
                                                this.GetDataValue(QsDataInput.Answer)
                                                : 'No Answer is selected'
                                        }
                                        // value={this.GetDataValue(QsDataInput.Answer) !== undefined ? this.GetDataValue(QsDataInput.Answer) : null}
                                        // onChange={(e) => this.SaveDataInput(e.target.value, QsDataInput.Answer)}
                                        disabled={true}
                                    />
                                </td>
                            </tr>
                            <tr hidden={true}>
                                <td><span>Options</span></td>
                                <td>
                                    <input
                                        ref={this.EditQs_Selection_Ref}
                                        type="text"
                                        style={{ width: '100%' }}
                                        // placeholder={this.GetDataValue(QsDataInput.Selection)}
                                        placeholder={
                                            this.GetDataValue(QsDataInput.Selection) !== '' ?
                                                this.GetDataValue(QsDataInput.Selection)
                                                : 'No Option is added'
                                        }
                                        // onChange={(e) => this.SaveDataInput(e.target.value, QsDataInput.Selection)}
                                        disabled={true}
                                    />
                                </td>
                            </tr>
                            {
                                this.state.QuestionModal !== null ?
                                    this.state.QuestionModal.hasOwnProperty('Selection') ?
                                        <tr>
                                            <td colSpan='2'>
                                                {
                                                    this.state.ToggleEditAnswerOptions === false ?
                                                        <Button
                                                            variant="primary"
                                                            onClick={() => this.ShowAnswerOptionsEditMode()}
                                                        >Edit Answer & Options</Button>
                                                        :
                                                        <>
                                                            <Button
                                                                variant="primary"
                                                                onClick={() => this.ApplyEditedAnswerOptions(null)}
                                                            >Apply Changes</Button>&nbsp;
                                                            <Button
                                                                variant="secondary"
                                                                onClick={() => this.Reset_EditedAnswerOptions()}
                                                            >Reset</Button>&nbsp;
                                                            <Button
                                                                variant="secondary"
                                                                onClick={() => this.ResetAndClose_EditedAnswerOptions()}
                                                            >Reset All & Close Edit</Button>&nbsp;
                                                            <OverlayTrigger overlay={<Tooltip><span>Add Option</span></Tooltip>}>
                                                                <Button
                                                                    variant="secondary"
                                                                    onClick={() => this.Increase_Answer_EditedAnswerOptions()}
                                                                    style={{ width: 38, height: 38, padding: '2px 0px 0px' }}
                                                                ><i className="fa fa-plus" style={{ fontSize: "20px", color: "white", }}></i></Button>
                                                            </OverlayTrigger>&nbsp;
                                                            <OverlayTrigger overlay={<Tooltip><span>Remove Option</span></Tooltip>}>
                                                                <Button
                                                                    variant="secondary"
                                                                    onClick={() => this.Reduce_Answer_EditedAnswerOptions(undefined)}
                                                                    style={{ width: 38, height: 38, padding: '1px 0px 0px' }}
                                                                ><i className="fa fa-minus" style={{ fontSize: "20px", color: "white", }}></i></Button>
                                                            </OverlayTrigger>
                                                        </>
                                                }
                                                <br />
                                                <table className='table table-hover' width='100%' cellPadding='0' cellSpacing='0'>
                                                    <tbody>
                                                        {
                                                            this.state.RefreshAnswerOptionsUi ?
                                                                null : this.AnswerOptionsUi()
                                                        }
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        : null
                                    : null
                            }
                            <tr style={{ display: 'none' }}>
                                <td><span>Hints</span></td>
                                <td>
                                    <input
                                        ref={this.EditQs_Hints_Ref}
                                        type="text"
                                        style={{ width: '100%' }}
                                        placeholder={
                                            this.GetDataValue(QsDataInput.Hints) !== '' ?
                                                this.GetDataValue(QsDataInput.Hints)
                                                : 'Enter Hints...'
                                        }
                                        onChange={(e) => this.SaveDataInput(e.target.value, QsDataInput.Hints)}
                                    />
                                    {
                                        this.state.QuestionModal !== null ?
                                            this.state.CachedQuestionModal !== null ?
                                                this.state.QuestionModal.Hints !== this.state.CachedQuestionModal.Hints ?
                                                    <>
                                                        <br />
                                                        <span style={{ fontSize: 14, color: 'gray' }}>{this.state.CachedQuestionModal.Hints}</span>
                                                    </>
                                                    : null
                                                : null
                                            : null
                                    }
                                </td>
                            </tr>
                            <tr style={{ display: 'none' }}>
                                <td><span>Tags</span></td>
                                <td>
                                    <ReactTags
                                        // ref={this.reactTags}
                                        ref={this.EditQs_Tags_Ref}
                                        tags={this.state.TagList}
                                        suggestions={this.state.AvailableTagList}
                                        // onDelete={this.OnRemoveTag.bind(this)}
                                        // onAddition={this.OnAddTag.bind(this)}
                                        onDelete={this.OnRemoveTag}
                                        onAddition={this.OnAddTag}
                                        autoresize={false}
                                        minQueryLength={0}
                                        maxSuggestionsLength={this.state.AvailableTagList.length}

                                        //2021.10.01
                                        tagComponent={this.TagComponent}
                                        suggestionComponent={this.SuggestionTagComponent}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
            </Row>
            {this.AnswerOption_PictureUpload_ModalComponent()}
            {this.EditQuestionTypeComponentUi()}
        </>);
    }
}