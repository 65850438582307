import React from 'react';
import { Button, Modal, ProgressBar } from 'react-bootstrap';
import { CheckBoolean, CheckNullValue, CheckNumber, CheckObjectStringEmpty, CheckStringEmpty, Delay } from '../utilities/GlobalFunctions';
import { useAppService } from '../services/AppService';
import { useGlobal } from '../utilities/GlobalVariables';

export const AlertMode = {
    Alert: 0,
    Loading: 1,
    Confirm: 2,
    Debug: 3,
}

export default class AlertModal extends React.Component {
    // constructor(props) {
    //     super(props);
    // }
    state = {
        mode: AlertMode.Alert,
        show: false,
        title: null,
        body: null,
        buttons: null,
        index: 0,
        showProgressBar: false,
        size: '',
    }

    componentDidMount = () => {
        setTimeout(() => {
            if (CheckNullValue(this.state.title) === null && CheckNullValue(this.state.body) === null)
                this.setState({ show: false });
        }, 150);
    }

    //#region singleton
    closeModal = async () => {
        this.setState({ show: false, index: -1, });
        await Delay(200);
        useGlobal.getState().setIsLoading(false);
        this.setState({
            title: null,
            body: null,
            buttons: null,
            extraComponent: null,
            showProgressBar: false,
            mode: AlertMode.Alert,
        });
    }
    setModal = async (title = null, body = null, buttons = null, mode = AlertMode.Alert, size = '') => {
        const show = title === null && body === null ? false : true;
        if (show === false) {
            this.setState({
                show: false,
            });
            await Delay(300);
        }
        // if (title === null && body === null && buttons === null && mode === AlertMode.Alert) {
        //     this.setState({
        //         show: false,
        //     });
        //     await Delay(500);
        // }
        useGlobal.getState().setIsLoading(mode === AlertMode.Loading);
        this.setState({
            show: show,
            title: title,
            body: body,
            buttons: buttons,
            index: -1,
            showProgressBar: mode === AlertMode.Loading ? true : false,
            mode: mode,
            size: size,
        });
        // console.log('setAlert ' + this.state.index);
    }
    setLoading = (title = null, body = null) => {
        const index = this.state.index < 0 ? -1 : useAppService.getState().alertLevelIndex;
        useGlobal.getState().setIsLoading(true);
        this.setState({
            show: true,
            title: title,
            body: body,
            buttons: null,
            extraComponent: null,
            index: index,
            showProgressBar: true,
            mode: AlertMode.Loading,
        });
        // console.log('setLoading ' + this.state.index);
    }
    //#endregion

    //#region instance
    closeAlert = async () => {
        // console.log('closeAlert');
        const index = Number(useAppService.getState().alertLevelIndex);
        // console.log('closeAlert ' + this.state.index + ' ' + index);
        useGlobal.getState().setIsLoading(false);
        if (this.state.index === index) {
            this.setState({ show: false, });
            await Delay(100);
            this.setState({
                title: null,
                body: null,
                buttons: null,
                extraComponent: null,
                index: index < 0 ? 0 : index,
                showProgressBar: false,
                mode: AlertMode.Alert,
            });
            // console.log('closeAlert ' + this.state.index);
        }
        else {
            useAppService.getState().closeAlert();
        }
    }
    setAlert = (title = null, body = null, buttons = null) => {
        const index = useAppService.getState().alertLevelIndex;
        this.setState({
            show: true,
            title: title,
            body: body,
            buttons: buttons,
            index: index,
            showProgressBar: false,
            mode: AlertMode.Alert,
        });
        // console.log('setAlert ' + this.state.index);
    }
    //#endregion

    render() {
        return (<ModalComponent mode={this.state.mode} title={this.state.title} body={this.state.body} buttons={this.state.buttons} index={this.state.index} show={this.state.show} closeModal={this.closeModal} closeAlert={this.closeAlert} showProgressBar={this.state.showProgressBar} size={this.state.size} />);
    }
}

export function ModalComponent(props) {
    const isLoadingMode = CheckNumber(props.mode) === AlertMode.Loading;
    const closeFunction = CheckNumber(props.index, -1) < 0 ? props.closeModal : props.closeAlert;
    return (<Modal
        className='alert-modal'
        id={'alert-' + props.index}
        show={props.show}
        onHide={isLoadingMode ? () => { } : closeFunction}
        size={String(props.size) !== '' ? String(props.size) : props.mode === AlertMode.Debug ? 'xl' : ''}
        centered
    >
        <Modal.Header closeButton={!isLoadingMode} hidden={CheckObjectStringEmpty(props, 'title') === ''}>
            <Modal.Title>{CheckObjectStringEmpty(props, 'title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {
                CheckNullValue(props.body) === null ? null :
                    (typeof (props.body) === 'string' ? <div dangerouslySetInnerHTML={
                        props.mode === AlertMode.Debug ?
                            { __html: `<textarea rows="20" wrap="soft" style="display:block;width:100%;resize:vertical;">${CheckStringEmpty(props.body)}</textarea>` }
                            :
                            { __html: CheckStringEmpty(props.body) }

                    } />
                        : props.body)
            }
            {CheckBoolean(props.showProgressBar) ? <ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /> : null}
        </Modal.Body>
        <Modal.Footer hidden={isLoadingMode}>
            <Button variant='secondary' onClick={closeFunction}>Close</Button>
            {CheckNullValue(props.buttons)}
        </Modal.Footer>
    </Modal>);
}